import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

// contants
import { COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS, COLLECTION_ITEM_RARITY_NAME } from '../../../../../constants/award-admin/collectionItemRarity';

// utils
import { formatNumber } from '../../../../../utils/number';

const RowData = ({ className, item }) => {
  // -- item --
  const { imageUrl, name, collectionCategoryName, price, quantity, rarity } = item

  return (
    <tr>
      {/* image */}
      <td className="t-w-[150px]" style={{ verticalAlign: 'middle' }}>
        <img className="t-w-[150px] t-h-[150px] t-object-contain" src={imageUrl} />
      </td>

      {/* name */}
      <td className="text-center !t-align-middle">
        {name}
      </td>

      {/* collectionCategoryName */}
      <td className="text-center !t-align-middle">
        {collectionCategoryName}
      </td>

      {/* price */}
      <td className="text-center !t-align-middle">
        {`⭐ ${formatNumber(price)}`}
      </td>

      {/* quantity */}
      <td className="text-center !t-align-middle">
        {formatNumber(quantity)}
      </td>

      {/* rarity */}
      <td className="text-center !t-align-middle">
        <Badge
          color={COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS[rarity]}
          pill
        >
          {COLLECTION_ITEM_RARITY_NAME[rarity]}
        </Badge>
      </td>
    </tr>
  )
}

RowData.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default memo(RowData)