import React from 'react';
import { connect } from 'react-redux';
import './style.scss';
import ViewDragAndDrop from './ViewDragAndDrop';
import ViewMatchThePair from './ViewMatchThePair';
import ViewMcQuiz from './ViewMcQuiz';
import { squareQuizType } from '../../../reducers/templates';
import ViewWordInSentence from './ViewWordInSentence';
import ViewToneOfWord from './ViewToneOfWord';
import ViewElementOfWords from './ViewElementOfWords';

class ViewQuiz extends React.Component {
  renderTypeQuiz = () => {
    const { learningCurrent, lesson } = this.props;
    if (learningCurrent.type === 'quiz:blank_quiz') {
      return <ViewDragAndDrop learningCurrent={learningCurrent} lesson={lesson} />;
    }
    if (learningCurrent.type === 'quiz:mc_quiz') {
      return <ViewMcQuiz learningCurrent={learningCurrent} lesson={lesson} />;
    }
    if (learningCurrent.type === 'quiz:pair_quiz') {
      return <ViewMatchThePair learningCurrent={learningCurrent} lesson={lesson} />;
    }
    if (learningCurrent.type === 'quiz:square_quiz' && learningCurrent.squareQuizType === squareQuizType[0]) {
      return <ViewWordInSentence learningCurrent={learningCurrent} lesson={lesson} />;
    }
    if (learningCurrent.type === 'quiz:square_quiz' && learningCurrent.squareQuizType === squareQuizType[1]) {
      return <ViewToneOfWord learningCurrent={learningCurrent} lesson={lesson} />;
    }
    if (learningCurrent.type === 'quiz:square_quiz' && learningCurrent.squareQuizType === squareQuizType[2]) {
      return <ViewElementOfWords learningCurrent={learningCurrent} lesson={lesson} />;
    }
    return null;
  };
  render() {
    const { learningCurrent, lesson } = this.props;
    return (
      <div className="container_quiz_app">
        <div className="wrapper_button">
          <button type="button" className="btn btn-app">
            Gợi ý
          </button>
        </div>
        <div className="">{this.renderTypeQuiz()}</div>
        <div className="wrapper_button wrapper__btn_check">
          <button type="button" className="btn btn-app">
            Làm lại câu hỏi
          </button>
          <button type="button" className="btn btn-app">
            Kiểm tra kết quả
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewQuiz);
