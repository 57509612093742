import React from 'react';
import { connect } from 'react-redux';
import GetMediaQuiz from './GetMediaQuiz';

class ViewMcQuiz extends React.Component {
  render() {
    const { learningCurrent, lesson } = this.props;
    const quizPartsChecked =
      learningCurrent && learningCurrent.quizParts ? learningCurrent.quizParts.filter((part) => part.correctness) : [];
    const dataContent = {
      imageUrl: learningCurrent.questionImageUrl,
      videoUrl: learningCurrent.questionVideoUrl,
      type: (() => {
        if (learningCurrent.questionImageUrl) {
          return 'image';
        }
        if (learningCurrent.questionVideoUrl) {
          return 'video';
        }
        return null;
      })(),
    };
    return (
      <div style={{ padding: '20px 0' }} className="text-center view_mc_quiz">
        <div className="text-bold fs-25 text-question">{learningCurrent.question || ''}?</div>
        {learningCurrent.questionImageUrl || learningCurrent.questionVideoUrl ? (
          <div className="preview_image pd-20">
            <GetMediaQuiz dataContent={dataContent} />
          </div>
        ) : null}
        <div
          style={{ padding: '20px 40px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
          className=""
        >
          {learningCurrent.quizParts.map((part, index) => (
            <div className="fl item_mc" key={index}>
              <div
                style={{
                  borderRadius: quizPartsChecked.length > 1 ? '' : '50%',
                  height: '15px',
                  width: '15px',
                  border: '2px solid gray',
                  margin: 'auto 0 auto 40px',
                }}
              ></div>
              <div style={{ margin: 'auto' }} className="">
                <GetMediaQuiz dataContent={part.content} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewMcQuiz);
