// import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1));
};

export const generateId = (localState) => {
  if (!localState || !localState.entities || !localState.entities.mediaObjects) return getRndInteger(0, 999999);
  const arr = Object.keys(localState.entities.mediaObjects);

  while (true) {
    const genId = getRndInteger(0, 999999);
    if (arr.indexOf(genId) === -1) return genId;
  }
};

export const generateUUID = () => {
  return uuidv4();
};
