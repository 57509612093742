import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, getOne, upsert } from '../../apis/award-admin/collectionItem';
import { uploadOneFile, uploadOneUrl } from '../../apis/file-storage/fileStorage';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false,
  isSaving: false,
};

// slice
const collectionItem = createSlice({
  name: 'collectionItem',
  initialState,
  reducers: {
    // list collection items
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMetadata(state, action) {
      state.metadata = action.payload;
    },
    setCollectionItemIds(state, action) {
      state.ids = action.payload;
    },
    setCollectionItems(state, action) {
      state.data = action.payload;
    },
    // collection item
    setSaving(state, action) {
      state.isSaving = action.payload;
    },
    setCollectionItem(state, action) {
      const { isNew = false, ...restPayload } = action.payload;

      // append to first index new item's id if isNew = true
      if (isNew) state.ids.unshift(restPayload.id);

      // modify data with new item
      state.data[restPayload.id] = { ...state.data[restPayload.id], ...restPayload };
    },
  },
});

// actions
export const {
  // list collection items
  setLoading,
  setMetadata,
  setCollectionItemIds,
  setCollectionItems,
  // collection item
  setSaving,
  setCollectionItem,
} = collectionItem.actions;

// get all collection items
export const getAllCollectionItems =
  ({
    page,
    limit,
    keyword,
    sort,
    collectionCategoryId,
    status,
    rarities,
    shouldPopulateCollectionCategory = true,
    includeIds,
  } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({
        page,
        limit,
        keyword,
        sort,
        collectionCategoryId,
        status,
        rarities,
        shouldPopulateCollectionCategory,
        includeIds,
      });

      if (!res.result) toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.metadata));
        dispatch(setCollectionItemIds(_map(res.data, 'id')));
        dispatch(setCollectionItems(_keyBy(res.data, 'id')));
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata));
      dispatch(setCollectionItemIds(initialState.ids));
      dispatch(setCollectionItems(initialState.data));
    }

    dispatch(setLoading(false));
  };

// upsert collection item
export const upsertCollectionItem =
  ({
    _id,
    name,
    nameVoiceUrl,
    description,
    descriptionVoiceUrl,
    collectionCategoryId,
    rarity,
    status,
    publishQuantity,
    price,
    image,
    imageUrl,
    forceNotUploadImage = false,
    forceNotUploadNameVoiceUrl = false,
    forceNotUploadDescriptionVoiceUrl = false,
  } = {}) =>
  async (dispatch) => {
    let result = false;

    dispatch(setSaving(true));

    try {
      let newImageUrl = imageUrl;

      // upload image
      if (image && !forceNotUploadImage) {
        const formData = new FormData();
        formData.append('file', image);

        const uploadImageRes = await uploadOneFile(formData);

        if (!uploadImageRes.result) toast.error(`Lỗi: ${JSON.stringify(uploadImageRes.message)}`);
        else newImageUrl = uploadImageRes.data.fileAccessLink;
      }

      // upload nameVoiceUrl
      if (nameVoiceUrl && !forceNotUploadNameVoiceUrl) {
        const uploadNameVoiceUrlRes = await uploadOneUrl({ url: nameVoiceUrl });

        if (!uploadNameVoiceUrlRes.result) toast.error(`Lỗi: ${JSON.stringify(uploadNameVoiceUrlRes.message)}`);
        else nameVoiceUrl = uploadNameVoiceUrlRes.data.fileAccessLink;
      }

      // upload descriptionVoiceUrl
      if (descriptionVoiceUrl && !forceNotUploadDescriptionVoiceUrl) {
        const uploadDescriptionVoiceUrlRes = await uploadOneUrl({ url: descriptionVoiceUrl });

        if (!uploadDescriptionVoiceUrlRes.result)
          toast.error(`Lỗi: ${JSON.stringify(uploadDescriptionVoiceUrlRes.message)}`);
        else descriptionVoiceUrl = uploadDescriptionVoiceUrlRes.data.fileAccessLink;
      }

      const res = await upsert({
        _id,
        name,
        nameVoiceUrl,
        description,
        descriptionVoiceUrl,
        collectionCategoryId,
        rarity,
        status,
        publishQuantity: publishQuantity && Number(publishQuantity),
        price: price && Number(price),
        imageUrl: newImageUrl,
      });

      if (!res.result) toast.error(`Lỗi: ${JSON.stringify(res.message)}`);
      else {
        dispatch(
          setCollectionItem({
            ...res.data,
            ...(!_id && { isNew: true }), // flag to determine adding new
          }),
        );

        toast.success(`${_id ? 'Lưu' : 'Thêm'} vật phẩm thành công`);

        result = true;
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${JSON.stringify(error.message)}`);
    }

    dispatch(setSaving(false));

    return result;
  };

export default collectionItem.reducer;
