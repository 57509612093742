import React, { useCallback, memo } from 'react'
import { Input } from 'reactstrap'
import ReactPaginate from 'react-paginate'

// local
import './index.scss'

// Number of items in one page
const ITEMS_PER_PAGE = [10, 20, 50]

const Pagination = ({ total, limit, page, onPerPageChange, onPageChange }) => {
  // -- on select per page --
  const onSelectPerPage = useCallback((e) => {
    const perPage = parseInt(e.target.value, 10)
    onPerPageChange(perPage)
  }, [onPerPageChange])

  return (
    <div className="t-flex t-justify-between t-items-center">
      {/* select per page */}
      <div className="t-flex t-justify-center t-items-center">
        <p className="t-text-[12px] mb-0 t-text-black">Per Page</p>
        <Input
          type="select"
          value={limit}
          className="ml-2"
          style={{ width: '66px' }}
          bsSize="sm"
          onChange={onSelectPerPage}
        >
          {ITEMS_PER_PAGE.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Input>
      </div>

      {/* Pagination */}
      <ReactPaginate
        pageCount={Math.ceil(total / limit)}
        onPageChange={onPageChange}
        forcePage={page - 1}
        nextLabel={''}
        breakLabel={'...'}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeClassName={'active'}
        pageClassName={'page-item'}
        previousLabel={''}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate mb-0 mt-2'}
      />
    </div>
  )
}

export default memo(Pagination);