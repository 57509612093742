import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { getFullUrl } from '../../../utils/imageProcessing';

class ComponentUpImage extends React.Component {
  render() {
    const { changeData, dataTest, readImg, textTypeChapter, typeContent, thisVal } = this.props;
    return (
      <>
        <FormGroup>
          <Label htmlFor="Title">Tên {textTypeChapter}</Label>
          <Input
            value={dataTest.title || ''}
            onChange={(e) => changeData({ ...dataTest, title: e.target.value })}
            type="text"
            name="title"
            id="Title"
            placeholder={`Nhập tên ${textTypeChapter}`}
          />
        </FormGroup>
        <div className="fl">
          <div style={{ width: '50%' }}>
            <FormGroup>
              <div>
                <Label htmlFor="File">
                  <span>Ảnh bìa</span>
                </Label>
              </div>
              <button
                type="button"
                style={{ border: '1px solid #ced4da' }}
                className="btn"
                onClick={() => {
                  if (this.dropzoneRefAvatar) {
                    this.dropzoneRefAvatar.click();
                  }
                }}
              >
                Chọn ảnh bìa từ máy
                <input
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={(e) => readImg(e, 'img_avatar')}
                  ref={(ref) => {
                    this.dropzoneRefAvatar = ref;
                  }}
                />
              </button>
            </FormGroup>
            <div className=" wapper__img_modal">
              <img src={dataTest.imageUrl ? getFullUrl(dataTest.imageUrl) : ''} id="img_avatar" />
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <FormGroup>
              <div>
                <Label htmlFor="File">
                  <span>Ảnh thumbnail</span>
                  <span className="text-guid">{` (ảnh thu nhỏ ${textTypeChapter})`}</span>
                </Label>
              </div>
              <button
                type="button"
                style={{ border: '1px solid #ced4da' }}
                className="btn"
                onClick={() => {
                  if (this.dropzoneRefThumb) {
                    this.dropzoneRefThumb.click();
                  }
                }}
              >
                Chọn thumbnail từ máy
                <input
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={(e) => readImg(e, 'img_thumbnail')}
                  ref={(ref) => {
                    this.dropzoneRefThumb = ref;
                  }}
                />
              </button>
            </FormGroup>  
            <div className=" wapper__img_modal">
              <img src={dataTest.thumbnailImageUrl ? getFullUrl(dataTest.thumbnailImageUrl) : ''} id="img_thumbnail" />
            </div>
          </div>
        </div>
        {typeContent === 'test' && (
              <FormGroup>
                <Label>Loại bài kiểm tra</Label>
                <br />
                <Label style={{ marginLeft: '40px' }}>
                  <Input
                    type="radio"
                    name="staticRadio"
                    id="typeTest static"
                    checked={thisVal.state.data.testType === 'static' ? true : false}
                    onChange={(e) => thisVal.setState({ data: { ...thisVal.state.data, testType: 'static' } })}
                  />{' '}
                  Cố định
                </Label>
                <Label style={{ marginLeft: '80px' }}>
                  <Input
                    type="radio"
                    name="randomRadio"
                    id="typeTest random"
                    checked={thisVal.state.data.testType === 'random' ? true : false}
                    onChange={(e) => thisVal.setState({ data: { ...thisVal.state.data, testType: 'random' } })}
                  />{' '}
                  Ngẫu nhiên
                </Label>
              </FormGroup>
            )}
      </>
    );
  }
}

export default ComponentUpImage;
