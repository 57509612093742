import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { getAllCollectionItems, upsertCollectionItem } from '../../../../reducers/award-admin/collectionItem';

const useCollectionItemDataTableCtrl = () => {
  // -- collection item --
  const { isLoading, metadata = {}, ids = [] } = useSelector((state) => state.collectionItems);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- table filters --
  const [tableFilters, setTableFilters] = useState({ page: 1, limit: 10 });

  // -- handle change per page --
  const handleChangePerPage = useCallback(
    (perPage) => setTableFilters((prevState) => ({ ...prevState, page: 1, limit: perPage })),
    [],
  );

  // -- handle change page --
  const handleChangePage = useCallback(
    ({ selected }) => setTableFilters((prevState) => ({ ...prevState, page: selected + 1 })),
    [],
  );

  // -- handle change filters --
  const handleChangeFilters = useCallback(
    ({ keyword, collectionCategoryId, status, rarities }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        keyword,
        collectionCategoryId: collectionCategoryId !== '' ? collectionCategoryId : undefined,
        status: status !== '' ? status : undefined,
        rarities: rarities?.length > 0 ? rarities : undefined,
      })),
    [],
  );

  // -- handle change sort state --
  const handleChangeSortState = useCallback(
    ({ sort }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        sort,
      })),
    [],
  );

  // -- handle change status --
  const handleChangeStatus = useCallback(({ id, newStatus }) => {
    dispatch(
      upsertCollectionItem({
        _id: id,
        status: newStatus,
        forceNotUploadImage: true,
        forceNotUploadNameVoiceUrl: true,
        forceNotUploadDescriptionVoiceUrl: true,
      }),
    );
  }, []);

  // -- is modal open --
  const [isModalOpen, setIsModalOpen] = useState(false);

  // -- toggle modal --
  const toggleModal = useCallback(() => setIsModalOpen((prevState) => !prevState), [setIsModalOpen]);

  // -- modal item id --
  const [modalItemId, setModalItemId] = useState();

  // watching tableFilters change then refetch data
  useEffect(() => {
    dispatch(getAllCollectionItems(tableFilters));
  }, [tableFilters]);

  return {
    // collection items
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
    // handle change filters
    handleChangeFilters,
    // handle change sort state
    handleChangeSortState,
    // handle change status
    handleChangeStatus,
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
  };
};

export default createContainer(useCollectionItemDataTableCtrl);
