import { getFullUrl } from '../utils/imageProcessing';
import { denormalizeLearningPoints, normalizeLearningPoints } from '../utils/normalize';

export const getLessonLayout = ({ layout, preType, quizType }) => {
  if (preType === 'theory') {
    let type = 'custom';
    switch (layout) {
      case 1:
        type = 'left-right';
        break;
  
      case 2:
        type = 'up-down';
        break;
  
      case 3:
        type = 'up-twodown';
        break;
  
      case 4:
        type = 'twoup-down';
        break;
  
      case 5:
        type = 'twoup-twodown';
        break;
  
      default:
        type = 'custom';
        break;
    }
    return `${preType}:${type}`;
  }
  if (preType === 'quiz') {
    return `${preType}:${quizType}`;
  }
  return `${preType}:default`;
}

export const convertDataLearningPoint = (lesson) => {
  const learningPoints = lesson.learningPoints || [];
  const newLearningPoints =
    learningPoints.length > 0
      ? learningPoints.map((learning) => {
          let objTemp = {};

          if (learning.type === 'quiz' && !learning.inTestBank && learning.learningPoint) {
            objTemp = {
              ...learning.learningPoint,
              type: `${learning.type}:${learning.learningPoint.type}`,
            };
          }

          if (learning.type === 'theory' && learning.learningPoint) {
            let layoutType = '';
            switch (learning.learningPoint.layout) {
              case 1:
                layoutType = 'left-right';
                break;

              case 2:
                layoutType = 'up-down';
                break;

              case 3:
                layoutType = 'up-twodown';
                break;

              case 4:
                layoutType = 'twoup-down';
                break;

              case 5:
                layoutType = 'twoup-twodown';
                break;

              default:
                layoutType = 'custom';
                break;
            }
            objTemp = {
              ...learning.learningPoint,
              type: `${learning.type}:${layoutType}`,
              checkpoint: learning.checkpoint,
            };
          }

          if (learning.type === 'overview' && learning.learningPoint) {
            objTemp = {
              ...learning.learningPoint,
              type: 'overview:default',
            };
          }

          if (learning.type === 'checkpoint' && learning.learningPoint) {
            objTemp = {
              ...learning.learningPoint,
              type: 'checkpoint:default',
            };
          }

          if (!learning.type && learning.learningPoint) {
            objTemp = {
              ...learning.learningPoint,
              type: 'default:default',
            };
          }

          return objTemp;
        })
      : [];
  return newLearningPoints;
};

/**
 * Refine LearningPoints để cbi cho hiển thị ra
 * @param {Array<LearningPoint>} rawLearningPoints raw learningPoints before refine
 * @returns learningPoints, learningPointIds, entities (bộ ba luôn đi với nhau)
 */
export const refineLearningPoints = (rawLearningPoints) => {
  const fixedLearningPoints = rawLearningPoints.map(element => {
    if(element.type.split(":")[0] === 'checkpoint') {
      return {
        ...element,
        soundUrl: getFullUrl(element.soundUrl)
      };
    } 
    else {
      return element;
    }
  });
  const { learningPointIds, entities } = normalizeLearningPoints(fixedLearningPoints);
  if (!entities.mediaObjects) return { learningPoints: fixedLearningPoints, learningPointIds, entities };

  Object.keys(entities.mediaObjects).map((mO) => {
    if (entities.mediaObjects[mO].type !== 'text') return;
    const { audioUrl } = entities.mediaObjects[mO];
    entities.mediaObjects[mO].media = audioUrl ? new Audio(getFullUrl(audioUrl)) : null;
  });
  
  const learningPoints = denormalizeLearningPoints(learningPointIds, entities);
  return { learningPoints, learningPointIds, entities };
};
