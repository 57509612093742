import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListPath } from '../../../actions/ActionPublishPath';
import Header from '../../../components/Common/Header';
import { RequestFilter } from '../../../components/PublishManagement/RequestFilter';
import { RequestList } from '../../../components/PublishManagement/RequestList';
import './style.scss';
import Loading from '../../../components/Common/Loading';

export const PublishPathManagement = () => {
    const dispatch = useDispatch();
    const { syncing, listPath, filter, search } = useSelector(state => state.publishPath);
    useEffect(() => {
      dispatch(getListPath());
    }, []);

    if(syncing) return <Loading/>

    return (
        <>
            <Header />
            <div className="publish-path-management">
                <div className="publish-path-management-title">
                    Trang quản lý lộ trình học chờ duyệt
                </div>
                <RequestFilter filter={filter} search={search} />
                <div className="request-list-container">
                 <RequestList data={listPath} />
                </div>
            </div>
        </>
    );
}