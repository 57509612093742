/**
 * curState = undefined -> next state is descending, return -1
 * curState = descending (-1) -> next state is ascending, return 1
 * curState = ascending (1) -> next state is undefined, return undefined
 *
 * @return nextState
 */
export const toggleSortBy = (curState) => {
  // curState = undefined -> next state is descending, return -1
  if (curState === undefined) {
    return -1;
  }

  // curState = descending (-1) -> next state is ascending, return 1
  if (curState === -1) {
    return 1;
  }

  // curState = ascending (1) -> next state is undefined, return undefined
  return undefined;
};
