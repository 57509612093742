const initState = {
  error: null, // user-data
  loading: false,
};

export const TroubleshootActionTypes = {
  SET_ERROR: '@@troubleshoot/SET_ERROR',
  RESET: '@@troubleshoot/RESET',
};

const troubleshoot = (state = initState, action) => {
  switch (action.type) {
    case TroubleshootActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.data,
      };

    case TroubleshootActionTypes.RESET:
      return initState;
    default:
      return state;
  }
};

export default troubleshoot;
