import React from 'react';
export const Status = (props) => {
    const {status, changeStatus, colorText} = props;
    // const dispatch = useDispatch();
    return (
      <div>
        <span>Trạng thái: {status}</span>
        <br/>
        <span
          style={{
            fontSize: '15px',
            color: colorText ? colorText : 'blue',
            cursor: 'pointer'
          }}
          onClick={changeStatus}
        >Kiểm duyệt</span>
      </div>
    );
};
  