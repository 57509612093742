import React from 'react';
import { connect } from 'react-redux';
import Shape from '../../Common/Shape';

class ViewElementOfWords extends React.Component {
  render() {
    const { learningCurrent } = this.props;

    return (
      <div style={{ padding: '20px 0' }} className="text-center view_match_the_pair_quiz">
        <div className="text-bold fs-25">{learningCurrent.question || ''}?</div>
        <div className="fl" style={{ justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ justifyContent: 'center' }} className="list__text fl_w">
            {learningCurrent.quizParts.map((data, index) => {
              return (
                <div key={index}>
                  <div style={{ fontSize: '60px' }}>{data.content.text}</div>

                  <div
                    style={{ margin: '10px 20px 20px 0', border: '2px solid #2b429d' }}
                    className="passcodeContainer"
                  >
                    {data.square.map((elem, idx) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                      >
                        <div
                          className="fs-25 text-bold element_word"
                          style={{
                            height: '60px',
                            width: '60px',
                            borderRight: (() => {
                              if (idx === 0) {
                                return '4px solid #2b429d';
                              }
                              if (idx === 3) {
                                return 'none';
                              }
                              return '2px solid #2b429d';
                            })(),
                          }}
                        >
                          <div style={{ paddingTop: '15px' }}>{elem.text}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewElementOfWords);
