import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, getOne, upsert } from '../../apis/award-admin/systemCollection';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false,
};

// slice
const systemCollection = createSlice({
  name: 'systemCollection',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    setMetadata(state, action) {
      state.metadata = action.payload
    },
    setSystemCollectionIds(state, action) {
      state.ids = action.payload
    },
    setSystemCollections(state, action) {
      state.data = action.payload
    },
  }
})

// actions
export const {
  setLoading,
  setMetadata,
  setSystemCollectionIds,
  setSystemCollections,
} = systemCollection.actions

export const getAllSystemCollections = ({ page, limit, keyword, sort } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({ page, limit, keyword, sort });

      if (!res.result)
        toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.metadata))
        dispatch(setSystemCollectionIds(_map(res.data, 'id')))
        dispatch(setSystemCollections(_keyBy(res.data, 'id')))
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata))
      dispatch(setSystemCollectionIds(initialState.ids))
      dispatch(setSystemCollections(initialState.data))
    }

    dispatch(setLoading(false));
  };

export default systemCollection.reducer;
