import React from 'react';
import { connect } from 'react-redux';
import BackgroundQuiz from './common/BackgroundQuiz';
import InputTitleQuiz from './common/InputTitleQuiz';
import ScoreQuiz from './common/ScoreQuiz';
import DragAndDrop from './DragAndDrop';
import MatchThePair from './MatchThePair';
import MultiChoice from './MultiChoice';
import { SpeakingQuiz } from './SpeakingQuiz';
import SquareQuiz from './SquareQuiz';
import WritingQuiz from './WritingQuiz';

class ComponentQuiz extends React.Component {
  renderQuiz = () => {
    const { currentSlide, learningPoints, datas, currentSlidePreview, data } = this.props;
    const learningCurrent = data ? {...data, type: `quiz:${data.type}`} :
      (currentSlidePreview || learningPoints.find((learning) => learning.id === currentSlide) || {});
    if (learningCurrent.type === 'quiz:mc_quiz') {
      return <MultiChoice data={data} currentSlidePreview={data || currentSlidePreview} />;
    }
    if (learningCurrent.type === 'quiz:blank_quiz') {
      return <DragAndDrop data={data} currentSlidePreview={data || currentSlidePreview} />;
    }
    if (learningCurrent.type === 'quiz:pair_quiz') {
      return <MatchThePair data={data} currentSlidePreview={data || currentSlidePreview} />;
    }
    if (learningCurrent.type === 'quiz:square_quiz') {
      return <SquareQuiz data={data} currentSlidePreview={data || currentSlidePreview} />;
    }
    if (learningCurrent.type === 'quiz:writing_quiz') {
      return <WritingQuiz data={data} currentSlidePreview={data || currentSlidePreview} />;
    }
    if (learningCurrent.type === 'quiz:speaking_quiz') {
      return <SpeakingQuiz currentSlidePreview={data || currentSlidePreview} />
    }
    return null;
  };
  render() {
    const { currentSlidePreview } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <InputTitleQuiz id={currentSlidePreview ? "" : "tour-lesson-quiz-2"} currentSlidePreview={currentSlidePreview} />
        <div id={currentSlidePreview ? "" : "tour-lesson-quiz-3"} className="fl_b">
          <BackgroundQuiz currentSlidePreview={currentSlidePreview} />
          <ScoreQuiz currentSlidePreview={currentSlidePreview} />
        </div>
        <div id={currentSlidePreview ? "" : "tour-lesson-quiz-4"}>
          {this.renderQuiz()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {})(ComponentQuiz);
