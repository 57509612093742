export const generateWelcomeLesson = (contentText, lpPartTitles, templateName) => {
    let newContent = contentText.trim();
    if(newContent.slice(-1) != '.' && newContent !== '') newContent = newContent.concat('.');
    if (!lpPartTitles || !lpPartTitles[0].title || !lpPartTitles[1].title) {
        return '';
    }
    else {
        const len = lpPartTitles.length;
        const customLpPartTitles = lpPartTitles.map((element, index) => {
            let newElement = '';
            if(index === 0) {
                newElement = ` Nội dung đầu tiên của bài học là phần ${element.title}.`;
            }
            else if(index === len-1) {
                newElement = ` Và nội dung cuối cùng sẽ là phần ${element.title}.`;
            }
            else {
                newElement = ` Nội dung thứ ${index+1} là phần ${element.title}.`;
            }
            return newElement;
        });
        const initIntroLesson = `${newContent} Chủ đề hôm nay của chúng ta sẽ là ${templateName}. Tổng cộng sẽ có ${lpPartTitles.length} phần.`;
        const introLesson = customLpPartTitles.reduce((introLessonText, currentElement, index) => {
            return introLessonText.concat(currentElement);
        }, initIntroLesson);
        return introLesson;
    }
};

// export const convertWelcome = (welcomeText, template, titles) => {
//         let regexString = /\/~TITLE-\d~\//g;
//         const regexStringArray = welcomeText.match(regexString);
//         let newWelcomeText = welcomeText;
//         for(let i=0; i<regexStringArray.length; i++) {
//             let re = new RegExp(regexStringArray[i] , 'g');
//             let title = titles.find((element) => element.id === i + 1).title;
//             newWelcomeText = newWelcomeText.replaceAll(re, `${template} ${title}`);
//         }
//         return newWelcomeText;
// };

