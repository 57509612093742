import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';
import TitleVoiceBox from '../../Theory/TitleVoiceBox';
import TestAudio from './TestAudio';

class InputQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.handleVoice = this.handleVoice.bind(this);
  }
  handleVoice = (data) => {
    const {titleAudioUrl, titleVoice, rateVoice, indexQuiz} = data;
    const { currentSlide, learningPoints, audioUrl, learningCurrent, changeQuizAction } = this.props;
    const learningCurrentLocal =
      learningPoints.find((learning) => learning.id === currentSlide) || learningCurrent ||  {};
    if(titleVoice) {
        this.props.changeQuizAction('voice', titleVoice, 'quiz', learningCurrentLocal.id);
    }
    if(titleAudioUrl) {
      this.props.changeQuizAction('audioUrl', titleAudioUrl, 'quiz', learningCurrentLocal.id);
    }
    if(rateVoice) {
      this.props.changeQuizAction('rateVoice', rateVoice, 'quiz', learningCurrentLocal.id);
    }
  };
  render() {
    const { currentSlide, learningPoints, audioUrl, learningCurrent, changeQuizAction } = this.props;
    const learningCurrentLocal =
      learningPoints.find((learning) => learning.id === currentSlide) || learningCurrent ||  {};
    return (
      <>
        <div style={{ paddingBottom: '10px', float: 'right' }}>
          {/* <TestAudio audioUrl={audioUrl} /> */}
          {/* <TitleVoiceBox 
            lpID={currentSlide}
            title={learningCurrentLocal.question}
            titleVoice={learningCurrentLocal.voice}
            rateVoice={learningCurrentLocal.rateVoice}
            titleAudioUrl={learningCurrentLocal.audioUrl}
            handleChangeLP={this.handleVoice}
          /> */}
        </div>
        <textarea
          value={learningCurrentLocal.question || ''}
          placeholder="Nhập câu hỏi"
          className="next-input border-input-none form__type"
          onChange={(e) => changeQuizAction('question', e.target.value, 'quiz', currentSlide)}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuizAction: changeQuiz })(InputQuestion);

