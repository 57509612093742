import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import InputNumber from '../../components/Chapter/common/InputNumber';
import { TYPE_CHAPTER } from '../../reducers/test';
import SelectChapter from './SelectChapter';
const objClearNumber = {
  numberOfDragAndDropQuizzes: null,
  numberOfElementInWordQuizzes: null,
  numberOfMatchThePairQuizzes: null,
  numberOfMcQuizzes: null,
  numberOfWordsInSentenceQuizzes: null,
  numberOfToneOfWordQuizzes: null,
};
class ComponentTestRandom extends React.Component {
  state = {
    totalNumber: null,
  };
  componentDidMount() {
    this.setState({
      totalNumber: this.getTotal(this.props.dataTest.chapters, 'numberOfQuizzes'),
    });
  }
  fomartNumber = (e) => {
    return parseInt(e.target.value) || null;
  };
  getNumberMax = (type) => {
    const { listQuizzes } = this.props;
    if (type === 'mc_quiz' || type === 'pair_quiz' || type === 'blank_quiz') {
      const datas = listQuizzes.filter((quiz) => quiz.type === type);
      return datas.length;
    }
    if (type === 'word_in_sentence' || type === 'tone_of_word' || type === 'elements_in_word') {
      const datas = listQuizzes.filter((quiz) => quiz.type === 'square_quiz' && quiz.squareQuizType === type);
      return datas.length;
    }
    return 0;
  };
  getTotal = (arr, key) => {
    const total = arr.reduce((prev, cur) => {
      return parseInt(prev) + parseInt(cur[key] || 0);
    }, 0);
    return total;
  };
  changeNumberTypeQuiz = ({ e, element, sumQuizInChapter }) => {
    const { dataTest } = this.props;
    const {
      numberOfMcQuizzes,
      numberOfDragAndDropQuizzes,
      numberOfElementInWordQuizzes,
      numberOfMatchThePairQuizzes,
      numberOfToneOfWordQuizzes,
      numberOfWordsInSentenceQuizzes,
    } = dataTest;
    const valueInput = this.fomartNumber(e);

    const sumTypeQuiz =
      parseInt(element.numberType !== 'numberOfMcQuizzes' ? numberOfMcQuizzes || 0 : valueInput) +
      parseInt(element.numberType !== 'numberOfDragAndDropQuizzes' ? numberOfDragAndDropQuizzes || 0 : valueInput) +
      parseInt(element.numberType !== 'numberOfElementInWordQuizzes' ? numberOfElementInWordQuizzes || 0 : valueInput) +
      parseInt(element.numberType !== 'numberOfMatchThePairQuizzes' ? numberOfMatchThePairQuizzes || 0 : valueInput) +
      parseInt(
        element.numberType !== 'numberOfWordsInSentenceQuizzes' ? numberOfWordsInSentenceQuizzes || 0 : valueInput,
      ) +
      parseInt(element.numberType !== 'numberOfToneOfWordQuizzes' ? numberOfToneOfWordQuizzes || 0 : valueInput);
    if (
      (valueInput && valueInput > element.maxNumber) ||
      (valueInput && valueInput > sumQuizInChapter) ||
      sumTypeQuiz > sumQuizInChapter
    ) {
      return;
    }
    if (valueInput) {
      return element.changeData({
        ...dataTest,
        [element.numberType]: valueInput <= element.maxNumber ? valueInput : dataTest[element.numberType],
      });
    }
    return element.changeData({ ...dataTest, [element.numberType]: null });
  };
  changeTotalNumber = (e) => {
    this.setState({
      totalNumber: e.target.value,
    });
    this.props.changeData({
      ...this.props.dataTest,
      ...objClearNumber,
      chapters: this.props.dataTest.chapters.map((chapter) => {
        return {
          ...chapter,
          numberOfQuizzes: null,
        };
      }),
    });
  };
  render() {
    const { dataTest, changeData, course, courseDetail, chapterCurrent } = this.props;
    const listChapters = dataTest.testScope === TYPE_CHAPTER ? [{ ...chapterCurrent }] : courseDetail.chapterIds;
    const sumQuizInChapter = this.getTotal(dataTest.chapters, 'numberOfQuizzes');
    const sumTypeQuiz =
      parseInt(dataTest.numberOfMcQuizzes || 0) +
      parseInt(dataTest.numberOfDragAndDropQuizzes || 0) +
      parseInt(dataTest.numberOfElementInWordQuizzes || 0) +
      parseInt(dataTest.numberOfMatchThePairQuizzes || 0) +
      parseInt(dataTest.numberOfWordsInSentenceQuizzes || 0) +
      parseInt(dataTest.numberOfToneOfWordQuizzes || 0);
    const listTypeQuizzes = [
      {
        id: 'mc_quiz',
        name: 'Bài tập lựa chọn',
        value: dataTest.numberOfMcQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfMcQuizzes',
        maxNumber: 10,
      },
      {
        id: 'blank_quiz',
        name: 'Bài tập điền vào chỗ trống',
        value: dataTest.numberOfDragAndDropQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfDragAndDropQuizzes',
        maxNumber: 4,
      },
      {
        id: 'pair_quiz',
        name: 'Bài tập nối cặp',
        value: dataTest.numberOfMatchThePairQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfMatchThePairQuizzes',
        maxNumber: 0,
      },
      {
        id: 'word_in_sentence',
        name: 'Bài tập tiếng trong câu',
        value: dataTest.numberOfWordsInSentenceQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfWordsInSentenceQuizzes',
        maxNumber: 5,
      },
      {
        id: 'tone_of_word',
        name: 'Bài tập thanh điệu',
        value: dataTest.numberOfToneOfWordQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfToneOfWordQuizzes',
        maxNumber: 5,
      },
      {
        id: 'elements_in_word',
        name: 'Bài tập thành phần của tiếng',
        value: dataTest.numberOfElementInWordQuizzes || null,
        disabled: false,
        changeData: changeData,
        placeholder: '',
        numberType: 'numberOfElementInWordQuizzes',
        maxNumber: 3,
      },
    ];
    return (
      <div>
        <div className="fl total_test" tyle={{ marginTop: '20px' }}>
          <div className="text-bold title">Số lượng câu hỏi trong bài kiểm tra</div>
          <InputNumber
            // disabled={true}
            changeData={(e) => this.changeTotalNumber(e)}
            value={this.state.totalNumber || ''}
            placeholder={'Số lượng'}
          />
        </div>
        <div>
          <SelectChapter
            dataTest={dataTest}
            chaptersCurrent={dataTest.chapters || []}
            chapters={course?.chapterIds || []}
            changeData={changeData}
            objClearNumber={objClearNumber}
            totalNumber={this.state.totalNumber}
            getTotal={this.getTotal}
          />
        </div>
        {dataTest.chapters && dataTest.chapters.length > 0 ? (
          <FormGroup>
            <div style={{ marginTop: '20px' }} className="ft-w">
              Số lượng từng loại câu hỏi {sumTypeQuiz} / {sumQuizInChapter}
            </div>
            <div className="row fl_w list_quiz_random">
              {listTypeQuizzes.map((element, index) => (
                <div className="col-5 item" key={element.id}>
                  <div className="title"> {element.name}</div>
                  <div className="input-random" style={{ position: 'relative' }}>
                    <InputNumber
                      // disabled={this.getNumberMax(element.id) === 0}
                      disabled={index === 2}
                      value={element.value}
                      placeholder={element.placeholder}
                      changeData={(e) => this.changeNumberTypeQuiz({ e, element, sumQuizInChapter })}
                    />
                    {element.maxNumber ? (
                      <div
                        style={{
                          background: '#FFF',
                          position: 'absolute',
                          top: '1px',
                          right: '1px',
                          height: '36px',
                          width: '45px',
                          fontSize: '20px',
                          opacity: '0.6',
                        }}
                      >
                        <div style={{ marginTop: '5px' }}>| {element.maxNumber}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </FormGroup>
        ) : null}
        {/* </div>
        ))} */}
      </div>
    );
  }
}

export default ComponentTestRandom;
