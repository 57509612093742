import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { getQuizzesByCourseId, getCourse } from '../../apis/course';
import { getQuizzesByChapterId } from '../../apis/chapter';
import ComponentTestFixed from './ComponentTestFixed';
import ComponentTestRandom from './ComponentTestRandom';
import OptionTest from './OptionTest';
import './style.scss';
import { TYPE_RANDOM, TYPE_CHAPTER } from '../../reducers/test';

class ComponentTest extends React.Component {
  state = {
    quizzesC: [],
    quizzesT: [],
    courseDetail: {},
  };
  async componentDidMount() {
    const { courseId, chapterId,} = this.props;
    const quizzesByCate = await getQuizzesByCourseId(courseId);
    const quizzesByChapter = await getQuizzesByChapterId(chapterId);
    const cateDetail = await getCourse(courseId);
    const quizzesC =
      quizzesByCate && quizzesByCate.result && quizzesByCate.result.quiz ? quizzesByCate.result.quiz : [];
    const quizzesT =
      quizzesByChapter && quizzesByChapter.result && quizzesByChapter.result.quiz ? quizzesByChapter.result.quiz : [];
    this.setState({
      quizzesC: quizzesC,
      quizzesT: quizzesT,
      courseDetail: cateDetail && cateDetail.result && cateDetail.result.course ? cateDetail.result.course : {},
    });
  }
  render() {
    const { dataTest, changeData, chapterCurrent, idTest, course } = this.props;
    const { quizzesC, quizzesT, courseDetail } = this.state;
    const listQuizzes = dataTest.testScope === TYPE_CHAPTER ? quizzesT : quizzesC;
    return (
      <div className="component__test">
        <FormGroup>
          <Label className="ft-w" htmlFor="Title">
            Tên bài kiểm tra
          </Label>
          <Input
            value={dataTest.title || ''}
            onChange={(e) => changeData({ ...dataTest, title: e.target.value })}
            type="text"
            name="title"
            id="Title"
            placeholder={`Nhập tên bài kiểm tra`}
          />
        </FormGroup>
        <OptionTest dataTest={dataTest} changeData={changeData} />
        {dataTest.testType === TYPE_RANDOM ? (
          <ComponentTestRandom
            dataTest={dataTest}
            changeData={changeData}
            listQuizzes={listQuizzes}
            courseDetail={courseDetail}
            chapterCurrent={chapterCurrent}
            course={course}
          />
        ) : (
          <ComponentTestFixed dataTest={dataTest} changeData={changeData} listQuizzes={listQuizzes} idTest={idTest} />
        )}
      </div>
    );
  }
}

export default ComponentTest;
