export const SPLIT_RHYME = [
    // i
    {
        id: 0,
        rhyme: "i",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: ""
    },
    {
        id: 1,
        rhyme: "uy",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: ""
    },
    {
        id: 2,
        rhyme: "ich",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "ch"
    },
    {
        id: 3,
        rhyme: "uych",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "ch"
    },
    {
        id: 4,
        rhyme: "inh",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "nh"
    },
    {
        id: 5,
        rhyme: "uynh",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "nh"
    },
    {
        id: 6,
        rhyme: "it",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "t"
    },
    {
        id: 7,
        rhyme: "uyt",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "t"
    },
    {
        id: 8,
        rhyme: "in",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "n"
    },
    {
        id: 9,
        rhyme: "uyn",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "n"
    },
    {
        id: 10,
        rhyme: "ip",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "p"
    },
    {
        id:11,
        rhyme: "uyp",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "p"
    },
    {
        id: 12,
        rhyme: "im",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "m"
    },
    {
        id: 13,
        rhyme: "uym",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "m"
    },
    {
        id: 14,
        rhyme: "iu",
        startRhyme: "",
        mainRhyme: "i",
        finishRhyme: "u"
    },
    {
        id: 15,
        rhyme: "uyu",
        startRhyme: "u",
        mainRhyme: "y",
        finishRhyme: "u"
    },
    // ------------------------------------------ ê
    {
        id: 16,
        rhyme: "ê",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: ""
    },
    {
        id: 17,
        rhyme: "uê",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "u"
    },
    {
        id: 18,
        rhyme: "êch",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "ch"
    },
    {
        id: 19,
        rhyme: "uêch",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "ch"
    },
    {
        id: 20,
        rhyme: "ênh",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "nh"
    },
    {
        id: 21,
        rhyme: "uênh",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "nh"
    },
    {
        id: 22,
        rhyme: "êt",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "t"
    },
    {
        id: 23,
        rhyme: "uêt",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "t"
    },
    {
        id: 24,
        rhyme: "ên",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "n"
    },
    {
        id: 25,
        rhyme: "uên",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "n"
    },
    {
        id: 26,
        rhyme: "êp",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "p"
    },
    {
        id: 27,
        rhyme: "uêp",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "p"
    },
    {
        id: 28,
        rhyme: "êm",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "m"
    },
    {
        id: 29,
        rhyme: "uêm",
        startRhyme: "u",
        mainRhyme: "ê",
        finishRhyme: "m"
    },
    {
        id: 30,
        rhyme: "êu",
        startRhyme: "",
        mainRhyme: "ê",
        finishRhyme: "u"
    },
    // ------------------------------------- e
    {
        id: 31,
        rhyme: "e",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: ""
    },
    {
        id: 32,
        rhyme: "oe",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: ""
    },
    {
        id: 33,
        rhyme: "ec",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "c"
    },
    {
        id: 34,
        rhyme: "oec",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "c"
    },
    {
        id: 35,
        rhyme: "eng",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "ng"
    },
    {
        id: 36,
        rhyme: "oeng",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "ng"
    },
    {
        id: 37,
        rhyme: "et",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "t"
    },
    {
        id: 38,
        rhyme: "oet",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "t"
    },
    {
        id: 39,
        rhyme: "en",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "n"
    },
    {
        id: 40,
        rhyme: "oen",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "n"
    },
    {
        id: 41,
        rhyme: "ep",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "p"
    },
    {
        id: 42,
        rhyme: "oep",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "p"
    },
    {
        id: 43,
        rhyme: "em",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "m"
    },
    {
        id: 44,
        rhyme: "oem",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "m"
    },
    {
        id: 45,
        rhyme: "eo",
        startRhyme: "",
        mainRhyme: "e",
        finishRhyme: "o"
    },
    {
        id: 46,
        rhyme: "oeo",
        startRhyme: "o",
        mainRhyme: "e",
        finishRhyme: "o"
    },
    // ---------------------------------- ach
    {
        id: 47,
        rhyme: "ach",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "ch"
    },
    {
        id: 48,
        rhyme: "oach",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "ch"
    },
    {
        id: 49,
        rhyme: "anh",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "nh"
    },
    {
        id: 50,
        rhyme: "oanh",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "nh"
    },
    // ----------------------------------- iê
    {
        id: 51,
        rhyme: "ia",
        startRhyme: "",
        mainRhyme: "ia",
        finishRhyme: ""
    },
    {
        id: 52,
        rhyme: "uya",
        startRhyme: "u",
        mainRhyme: "ya",
        finishRhyme: ""
    },
    {
        id: 53,
        rhyme: "iêc",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "c"
    },
    {
        id: 54,
        rhyme: "iêng",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "ng"
    },
    {
        id: 55,
        rhyme: "iêt",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "t"
    },
    {
        id: 56,
        rhyme: "iên",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "n"
    },
    {
        id: 57,
        rhyme: "uyên",
        startRhyme: "u",
        mainRhyme: "yê",
        finishRhyme: "n"
    },
    {
        id: 58,
        rhyme: "iêp",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "p"
    },
    {
        id: 59,
        rhyme: "uyêp",
        startRhyme: "u",
        mainRhyme: "yê",
        finishRhyme: "p"
    },
    {
        id: 60,
        rhyme: "iêm",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "m"
    },
    {
        id: 61,
        rhyme: "uyêm",
        startRhyme: "u",
        mainRhyme: "yê",
        finishRhyme: "m"
    },
    {
        id: 62,
        rhyme: "iêu",
        startRhyme: "",
        mainRhyme: "iê",
        finishRhyme: "u"
    },
    {
        id: 63,
        rhyme: "ya",
        startRhyme: "",
        mainRhyme: "ya",
        finishRhyme: ""
    },
    {
        id: 64,
        rhyme: "yêc",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "c"
    },
    {
        id: 65,
        rhyme: "yêng",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "ng"
    },
    {
        id: 66,
        rhyme: "yêt",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "t"
    },
    {
        id: 67,
        rhyme: "yên",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "n"
    },
    {
        id: 68,
        rhyme: "yêp",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "p"
    },
    {
        id: 69,
        rhyme: "yêm",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "m"
    },
    {
        id: 70,
        rhyme: "yêu",
        startRhyme: "",
        mainRhyme: "yê",
        finishRhyme: "u"
    },
    // ------------------------------ ư
    {
        id: 71,
        rhyme: "ư",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: ""
    },
    {
        id: 72,
        rhyme: "ưc",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "c"
    },
    {
        id: 73,
        rhyme: "ưng",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "ng"
    },
    {
        id: 74,
        rhyme: "ưt",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "t"
    },
    {
        id: 75,
        rhyme: "ưn",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "n"
    },
    {
        id: 76,
        rhyme: "ưp",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "p"
    },
    {
        id: 77,
        rhyme: "ưm",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "m"
    },
    {
        id: 78,
        rhyme: "ưi",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "i"
    },
    {
        id: 79,
        rhyme: "ưu",
        startRhyme: "",
        mainRhyme: "ư",
        finishRhyme: "u"
    },
    //------------------------------------ ơ
    // thiếu uơ --> bổ sung cuối
    {
        id: 80,
        rhyme: "ơ",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: ""
    },
    {
        id: 81,
        rhyme: "ơng",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "ng"
    },
    {
        id: 82,
        rhyme: "ơt",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "t"
    },
    {
        id: 83,
        rhyme: "ơn",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "n"
    },
    {
        id: 84,
        rhyme: "ơp",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "p"
    },
    {
        id: 85,
        rhyme: "ơm",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "m"
    },
    {
        id: 86,
        rhyme: "ơi",
        startRhyme: "",
        mainRhyme: "ơ",
        finishRhyme: "i"
    },
    // ----------------- â
    {
        id: 87,
        rhyme: "âc",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "c"
    },
    {
        id: 88,
        rhyme: "âng",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "ng"
    },
    {
        id: 89,
        rhyme: "ât",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "t"
    },
    {
        id: 90,
        rhyme: "ân",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "n"
    },
    {
        id: 91,
        rhyme: "âp",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "p"
    },
    {
        id: 92,
        rhyme: "âm",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "m"
    },
    {
        id: 93,
        rhyme: "ây",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "y"
    },
    {
        id: 94,
        rhyme: "âu",
        startRhyme: "",
        mainRhyme: "â",
        finishRhyme: "u"
    },
    {
        id: 95,
        rhyme: "uâng",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "ng"
    },
    {
        id: 96,
        rhyme: "uât",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "t"
    },
    {
        id: 97,
        rhyme: "uân",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "n"
    },
    {
        id: 98,
        rhyme: "uâp",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "p"
    },
    {
        id: 99,
        rhyme: "uâm",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "m"
    },
    {
        id: 100,
        rhyme: "uây",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "y"
    },
    {
        id: 101,
        rhyme: "uâu",
        startRhyme: "u",
        mainRhyme: "â",
        finishRhyme: "u"
    },
    // ---------------------- u bảng 2
    {
        id: 102,
        rhyme: "u",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: ""
    },
    {
        id: 103,
        rhyme: "uc",
        startRhyme: "",
        mainRhyme: "uc",
        finishRhyme: ""
    },
    {
        id: 104,
        rhyme: "ung",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "ng"
    },
    {
        id: 105,
        rhyme: "ut",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "t"
    },
    {
        id: 106,
        rhyme: "un",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "n"
    },
    {
        id: 107,
        rhyme: "up",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "p"
    },
    {
        id: 108,
        rhyme: "um",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "m"
    },
    {
        id: 109,
        rhyme: "ui",
        startRhyme: "",
        mainRhyme: "u",
        finishRhyme: "i"
    },
    // --------------------- ô
    // thiếu ô -> bổ sung ở cuối
    {
        id: 110,
        rhyme: "ôc",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "c"
    },
    {
        id: 111,
        rhyme: "ông",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "ng"
    },
    {
        id: 112,
        rhyme: "ôt",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "t"
    },
    {
        id: 113,
        rhyme: "ôn",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "n"
    },
    {
        id: 114,
        rhyme: "ôp",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "p"
    },
    {
        id: 115,
        rhyme: "ôm",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "m"
    },
    {
        id: 116,
        rhyme: "ôi",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: "i"
    },
    // ---------------- o
    {
        id: 117,
        rhyme: "o",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: ""
    },
    {
        id: 118,
        rhyme: "ooc",
        startRhyme: "",
        mainRhyme: "ooc",
        finishRhyme: ""
    },
    {
        id: 119,
        rhyme: "oong",
        startRhyme: "",
        mainRhyme: "oo",
        finishRhyme: "ng"
    },
    {
        id: 120,
        rhyme: "ot",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "t"
    },
    {
        id: 121,
        rhyme: "on",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "n"
    },
    {
        id: 122,
        rhyme: "op",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "p"
    },
    {
        id: 123,
        rhyme: "om",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "m"
    },
    {
        id: 124,
        rhyme: "oi",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "i"
    },
    // --- oc
    {
        id: 125,
        rhyme: "oc",
        startRhyme: "",
        mainRhyme: "oc",
        finishRhyme: ""
    },
    {
        id: 126,
        rhyme: "ong",
        startRhyme: "",
        mainRhyme: "o",
        finishRhyme: "ng"
    },
    // ----------------------- uô
    {
        id: 127,
        rhyme: "ua",
        startRhyme: "",
        mainRhyme: "ua",
        finishRhyme: ""
    },
    {
        id: 128,
        rhyme: "uôc",
        startRhyme: "",
        mainRhyme: "uôc",
        finishRhyme: ""
    },
    {
        id: 129,
        rhyme: "uông",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "ng"
    },
    {
        id: 130,
        rhyme: "uôt",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "t"
    },
    {
        id: 131,
        rhyme: "uôn",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "n"
    },
    {
        id: 132,
        rhyme: "uôp",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "p"
    },
    {
        id: 133,
        rhyme: "uôm",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "m"
    },
    {
        id: 134,
        rhyme: "uôi",
        startRhyme: "",
        mainRhyme: "uô",
        finishRhyme: "i"
    },
    // ----------------- ươ
    {
        id: 135,
        rhyme: "ưa",
        startRhyme: "",
        mainRhyme: "ưa",
        finishRhyme: ""
    },
    {
        id: 136,
        rhyme: "ươc",
        startRhyme: "",
        mainRhyme: "ươc",
        finishRhyme: ""
    },
    {
        id: 137,
        rhyme: "ương",
        startRhyme: "",
        mainRhyme: "ươ",
        finishRhyme: "ng"
    },
    {
        id: 138,
        rhyme: "ươt",
        startRhyme: "",
        mainRhyme: "ươ",
        finishRhyme: "t"
    },
    {
        id: 139,
        rhyme: "ươn",
        startRhyme: "",
        mainRhyme: "ươ",
        finishRhyme: "n"
    },
    {
        id: 140,
        rhyme: "ươp",
        startRhyme: "",
        mainRhyme: "ưo",
        finishRhyme: "p"
    },
    {
        id: 141,
        rhyme: "ươm",
        startRhyme: "",
        mainRhyme: "ươ",
        finishRhyme: "m"
    },
    {
        id: 142,
        rhyme: "ươi",
        startRhyme: "",
        mainRhyme: "ươ",
        finishRhyme: "i"
    },
    // ----------------------- a
    {
        id: 143,
        rhyme: "a",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: ""
    },
    {
        id: 144,
        rhyme: "ac",
        startRhyme: "",
        mainRhyme: "ac",
        finishRhyme: ""
    },
    {
        id: 145,
        rhyme: "ang",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "ng"
    },
    {
        id: 146,
        rhyme: "at",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "t"
    },
    {
        id: 147,
        rhyme: "an",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "n"
    },
    {
        id: 148,
        rhyme: "ap",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "p"
    },
    {
        id: 149,
        rhyme: "am",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "m"
    },
    {
        id: 150,
        rhyme: "ai",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "i"
    },
    {
        id: 151,
        rhyme: "ao",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "o"
    },
    {
        id: 152,
        rhyme: "oa",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: ""
    },
    {
        id: 153,
        rhyme: "oac",
        startRhyme: "o",
        mainRhyme: "ac",
        finishRhyme: ""
    },
    {
        id: 154,
        rhyme: "oang",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "ng"
    },
    {
        id: 155,
        rhyme: "oat",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "t"
    },
    {
        id: 156,
        rhyme: "oan",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "n"
    },
    {
        id: 157,
        rhyme: "oap",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "p"
    },
    {
        id: 158,
        rhyme: "oam",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "m"
    },
    {
        id: 159,
        rhyme: "oai",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "i"
    },
    {
        id: 160,
        rhyme: "oao",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "o"
    },
    // ----------------- ă
    // thiếu ăp -> bổ sung ở cuối
    {
        id: 161,
        rhyme: "ăc",
        startRhyme: "",
        mainRhyme: "ăc",
        finishRhyme: ""
    },
    {
        id: 162,
        rhyme: "ăng",
        startRhyme: "",
        mainRhyme: "ăng",
        finishRhyme: ""
    },
    {
        id: 163,
        rhyme: "ăt",
        startRhyme: "",
        mainRhyme: "ă",
        finishRhyme: "t"
    },
    {
        id: 164,
        rhyme: "ăn",
        startRhyme: "",
        mainRhyme: "ă",
        finishRhyme: "n"
    },
    {
        id: 165,
        rhyme: "ăm",
        startRhyme: "",
        mainRhyme: "ă",
        finishRhyme: "m"
    },
    {
        id: 166,
        rhyme: "ay",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "y"
    },
    {
        id: 167,
        rhyme: "au",
        startRhyme: "",
        mainRhyme: "a",
        finishRhyme: "u"
    },
    {
        id: 168,
        rhyme: "oăc",
        startRhyme: "o",
        mainRhyme: "ăc",
        finishRhyme: ""
    },
    {
        id: 169,
        rhyme: "oăng",
        startRhyme: "o",
        mainRhyme: "ăng",
        finishRhyme: ""
    },
    {
        id: 170,
        rhyme: "oăt",
        startRhyme: "o",
        mainRhyme: "ă",
        finishRhyme: "t"
    },
    {
        id: 171,
        rhyme: "oăn",
        startRhyme: "o",
        mainRhyme: "ă",
        finishRhyme: "n"
    },
    {
        id: 172,
        rhyme: "oăp",
        startRhyme: "o",
        mainRhyme: "ă",
        finishRhyme: "p"
    },
    {
        id: 173,
        rhyme: "oăm",
        startRhyme: "o",
        mainRhyme: "ă",
        finishRhyme: "m"
    },
    {
        id: 174,
        rhyme: "oay",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "y"
    },
    {
        id: 175,
        rhyme: "oau",
        startRhyme: "o",
        mainRhyme: "a",
        finishRhyme: "u"
    },
    // ------------uyêt
    {
        id: 176,
        rhyme: "uyêt",
        startRhyme: "u",
        mainRhyme: "yê",
        finishRhyme: "t"
    },
    // bổ sung
    {
        id: 177,
        rhyme: "ô",
        startRhyme: "",
        mainRhyme: "ô",
        finishRhyme: ""
    },
    {
        id: 178,
        rhyme: "uơ",
        startRhyme: "u",
        mainRhyme: "ơ",
        finishRhyme: ""
    },
    {
        id: 179,
        rhyme: "ăp",
        startRhyme: "",
        mainRhyme: "ă",
        finishRhyme: "p"
    },
];