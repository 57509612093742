import React from 'react';
import { connect } from 'react-redux';
import PopoverCustom from '../../components/Popover/PopoverCustom';
import { IconCheck, IconSelect, IconDelete } from '../../icons/IconSvg';
import { addSlideQuiz, deleteSlide } from '../../reducers/lesson';
import InputNumber from '../../components/Chapter/common/InputNumber';

class SelectChapter extends React.Component {
  state = {
    popoverOpen: false,
    chaptersSelected: [],
  };

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };
  selectChapter = async (chapter) => {
    const { changeData, dataTest } = this.props;
    if (this.checkQuizSelected(chapter)) {
      changeData({ ...dataTest, chapters: dataTest.chapters.filter((tp) => tp.id !== chapter.id) });
    } else {
      changeData({ ...dataTest, chapters: dataTest.chapters.concat({ id: chapter.id, numberOfQuizzes: null }) });
    }
  };
  checkQuizSelected = (chapter) => {
    const { chaptersCurrent } = this.props;
    return chaptersCurrent.map((lrn) => lrn.id).includes(chapter.id);
  };
  removeChapter = (idChapter) => {
    const { changeData, dataTest } = this.props;
    changeData({
      ...dataTest,
      ...this.props.objClearNumber,
      chapters: dataTest.chapters.filter((tp) => tp.id !== idChapter),
    });
  };
  getValueChapter = (id, key) => {
    const { chapters } = this.props;
    const data = chapters.find((tp) => tp.id === id) || {};
    return data[key];
  };
  render() {
    const { chapters, chaptersCurrent, changeData, dataTest, objClearNumber, totalNumber } = this.props;
    const { popoverOpen } = this.state;
    const chaptersSelected = chapters.filter((chapter) => chaptersCurrent.map((tp) => tp.id).includes(chapter.id)) || [];
    return (
      <div>
        <div className="">
          <div className="ft-w">Chọn chủ đề </div>
          <button
            onClick={this.toggle}
            style={{ border: '1px solid #c2c2c2', width: '100%' }}
            id="add_quiz"
            className="btn"
          >
            <div className="fl_b">
              <div> Chọn chủ đề</div>
              <div>
                <IconSelect />
              </div>
            </div>
          </button>
          <PopoverCustom
            maxHeight="400px"
            width={`${document.getElementById('add_quiz') ? document.getElementById('add_quiz').offsetWidth : 300}px`}
            id="add_quiz"
            toggle={this.toggle}
            popoverOpen={popoverOpen}
          >
            {chapters.length > 0 ? (
              chapters.map((chapter) => (
                <div onClick={async () => this.selectChapter(chapter)} key={chapter.id} className="div_item fl">
                  {this.checkQuizSelected(chapter) ? (
                    <div style={{ marginRight: '5px' }}>
                      <IconCheck />
                    </div>
                  ) : null}
                  <div>
                    <div>{chapter.title || ''}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center pd-20">Không có chủ đề</div>
            )}
          </PopoverCustom>
        </div>
        <div>
          {chaptersCurrent.length > 0 ? (
            <div style={{ marginTop: '20px' }} className="fl_b">
              <div className="ft-w">Tên chủ đề</div>
              <div style={{ width: '220px', textAlign: 'left' }} className="ft-w">
                Số lượng câu hỏi
              </div>
            </div>
          ) : null}
          {chaptersCurrent.map((chapter, index) => (
            <div
              key={chapter.id}
              style={{ marginTop: '10px', padding: '10px 0', borderBottom: '1px solid #E5E5E5' }}
              className="fl_b"
            >
              <div>
                {index + 1}. {this.getValueChapter(chapter.id, 'title')}
              </div>
              <div className="fl" style={{ width: '220px' }}>
                <div className="input-random" style={{ position: 'relative' }}>
                  <InputNumber
                    // disabled={true}
                    changeData={(e) => {
                      const sumQuizInChapter = this.props.getTotal(
                        dataTest.chapters.filter((tp) => tp.id !== chapter.id),
                        'numberOfQuizzes',
                      );
                      return totalNumber
                        ? changeData({
                            ...dataTest,
                            ...objClearNumber,
                            chapters: chaptersCurrent.map((tp) => {
                              return {
                                id: tp.id,
                                numberOfQuizzes:
                                  chapter.id === tp.id
                                    ? parseInt(e.target.value)
                                      ? sumQuizInChapter + parseInt(e.target.value) <= totalNumber
                                        ? parseInt(e.target.value)
                                        : tp.numberOfQuizzes
                                      : null
                                    : tp.numberOfQuizzes,
                              };
                            }),
                          })
                        : null;
                    }}
                    value={chapter.numberOfQuizzes || ''}
                    placeholder={'Số lượng'}
                  />
                  <div
                    style={{
                      background: '#FFF',
                      position: 'absolute',
                      top: '1px',
                      right: '1px',
                      height: '36px',
                      width: '45px',
                      fontSize: '20px',
                      opacity: '0.6',
                    }}
                  >
                    <div style={{ marginTop: '5px' }}>| 3</div>
                  </div>
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => this.removeChapter(chapter.id)}>
                  <IconDelete />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
});
export default connect(mapStateToProps, { addSlideQuiz, deleteSlide })(SelectChapter);
