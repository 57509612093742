import React, { useRef, useState }  from 'react';
import { IconVideocam } from '../../icons/IconSvg';
import './style.scss';
// import { fileToBase64 } from '../../utils/imageProcessing';
import { getFullUrl } from '../../utils/imageProcessing';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const _OptionVideo = (props) => {
  const [initSrc, setIntSrc] = useState(getFullUrl(props.url));
  const { icon, handleChangeVideo } = props;
  const inputRef = useRef(null);
  const videoTagRef = useRef(null);
  const videoSourceRef = useRef(null);
  const [videoFile, setVideoFile] = useState();

  const  readVideo = async (event, videoT, videoS) => {
    if (event.target.files[0]) {
      const ListFileSupport = ['video/mp4'];
      if (!ListFileSupport.includes(event.target.files[0].type)) {
        return toast.error('Chúng tôi không hỗ trợ định dạng này !');
      }
      if (event.target.files[0].size > 33000000) {
        return toast.error('Kích thước của video không được vượt quá 33MB');
      }
      if (event.target.files) {
        // const videoSrc = document.querySelector(`#${videoS}`);
        const videoTag = document.querySelector(`#${videoT}`);
        const url = URL.createObjectURL(event.target.files[0]);
        videoTagRef.current.src = url;
        videoTag.src = url;
        setVideoFile(event.target.files[0]);
      }
    }
  }
  useEffect(() => {
    let timer = setTimeout(() => handleChangeVideo(videoFile), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [videoFile]);

  return (
    <div className="ui__option__theory">
      <div className="ui__wraper_option">
        <div className="option__video">
          <div>{icon && icon === 'none' ? null : <IconVideocam />}</div>
          <div className="div__wraper">
            <div className="div__wraper_content">
              <div className="form_video">
                <div
                  className="media"
                  onClick={() => inputRef.current.click()}
                >
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={['video/mp4']}
                    multiple
                    onChange={(e) => readVideo(e, `video-tag-${props.id}`, `video-source-${props.id}`)}
                    ref={inputRef}
                  />
                  <video controls id={`video-tag-${props.id}`} src={initSrc} ref={videoTagRef}></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const OptionVideo = React.memo(_OptionVideo);
export default OptionVideo;
