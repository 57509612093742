import React, { memo } from 'react';
import { Spinner, Table } from 'reactstrap';

// components
import Loading from '../../../../components/Common/Loading';
import Pagination from '../../../../components/Common/Pagination';

// local
import Actions from './Actions'
import Header from './Header'
import RowData from './RowData'
import CollectionTemplateDataTableCtrl from './CollectionTemplateDataTable.ctrl';

const headers = ["Ảnh nền", "Nhạc nền", "Thể loại", ""]

const CollectionTemplateDataTable = memo(({ className }) => {
  // -- ctrl --
  const {
    // collection templates
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
  } = CollectionTemplateDataTableCtrl.useContainer()

  return (
    <div className={className}>
      <div className="t-flex t-justify-end t-items-center">
        {/* actions */}
        <Actions className="pt-3" />
      </div>

      {/* table */}
      <Table bordered responsive className="mt-3">
        {/* header */}
        <Header headers={headers} />

        {/* body */}
        <tbody>
          {/* loading */}
          {isLoading && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={4}><Spinner color="primary" /></td>
            </tr>
          )}

          {/* empty data */}
          {!isLoading && ids.length === 0 && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={4}>Không có dữ liệu</td>
            </tr>
          )}

          {/* data */}
          {!isLoading && ids.length > 0 && ids.map(id => (
            <RowData key={id} id={id} />
          ))}
        </tbody>
      </Table>

      {/* pagination */}
      <Pagination
        total={metadata.total}
        limit={tableFilters.limit}
        page={tableFilters.page}
        onPerPageChange={handleChangePerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
})

export default ({ ...props }) => (
  <CollectionTemplateDataTableCtrl.Provider>
    <CollectionTemplateDataTable {...props} />
  </CollectionTemplateDataTableCtrl.Provider>
);