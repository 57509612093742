import apiV2 from '../apiV2';

export const getAll = async ({ page = 1, limit = 10, keyword, sort = { createdAt: -1 }, type }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/order/all`,
    data: { page, limit, keyword, sort, type }
  });
}

export const getOne = async ({ _id }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/order/one`,
    data: { _id }
  });
}