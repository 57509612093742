import React, { useState } from 'react';
import { IconImage } from '../../icons/IconSvg';
import './style.scss';
import { imageCompress } from '../../utils/imageProcessing';
import { getFullUrl } from '../../utils/imageProcessing';
import { toast } from 'react-toastify';

class OptionImage extends React.Component {
  async readImg(event, type) {
    const listImgsSupport = ['image/jpeg', 'image/png'];
    if (event.target.files && event.target.files[0].size > 1048576) {
      return toast.error('Kích thước của ảnh không được vượt quá 1MB');
    }
    if (!listImgsSupport.includes(event.target.files[0].type)) {
      return toast.error('Chúng tôi không hỗ trợ định dạng này !');
    }
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      const compressedFile = await imageCompress(event.target.files[0]);
      this.props.handleChangeImage(compressedFile);
    }
  }
  render() {
    const { icon } = this.props;
    return (
      <div className="ui__option">
        <div className="ui__wraper_option">
          <div className="option__image">
            {/* <div>{icon && icon === 'none' ? null : <IconImage />}</div> */}
            <div className="div__wraper">
              <div className="div__wraper_content">
                <div className="form_img">
                  <div
                    className="media"
                    onClick={() => {
                      if (this.uploadRef) {
                        this.uploadRef.click();
                      }
                    }}
                  >
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      multiple
                      accept={['image/jpeg', 'image/png']}
                      onChange={(e) => this.readImg(e, this.props.id || 'abc')}
                      ref={(ref) => {
                        this.uploadRef = ref;
                      }}
                    />
                    <img className="item_avatar" src={getFullUrl(this.props.url || '')} id={this.props.id || 'abc'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionImage;
