import { getPaths } from '../apis/path';
import { searchPath, getFromQueryPath, publishPath } from '../apis/publishPath';
import { commentPath } from '../apis/path';
import { PublishPathAction } from '../reducers/publishPath';
import { ActionTypes } from '../reducers/paths'
import { toast } from "react-toastify";

export const loadingAction = (loading) => (dispatch) => {
  dispatch({
    type: PublishPathAction.LOADING,
    data: loading,
  });
};

export const getListPath = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPaths();
  const oldestDate = data && data.result.paths.length > 0 ? new Date(data.result.paths[0].createdAt) : new Date();
  dispatch({
    type: PublishPathAction.GET_LIST_PATH,
    data: data.result.paths,
  });
  dispatch({
    type: PublishPathAction.SET_FILTER,
    data: {
      date: {
        from: oldestDate,
        to: new Date(),
        oldestDate,
      },
    },
  });
  dispatch(loadingAction(false));
};

export const searchPathAction = (title, data) => async (dispatch) => {
  const res = await searchPath(title, data);
  dispatch({
    type: PublishPathAction.GET_LIST_PATH,
    data: res.paths,
  });
};

export const filterPath = (data) => async (dispatch) => {
  const res = await getFromQueryPath(data);
  dispatch({
    type: PublishPathAction.GET_LIST_PATH,
    data: res,
  });
};

export const openFilterPath = () => (dispatch) => {
  dispatch({ type: PublishPathAction.OPEN_FILTER });
};

export const closeFilterPath = () => (dispatch) => {
  dispatch({ type: PublishPathAction.CLOSE_FILTER });
};

export const changeFilterPath = (data) => (dispatch) => {
  dispatch({ type: PublishPathAction.SET_FILTER, data });
};

export const changeSearch = (data) => (dispatch) => {
  dispatch({ type: PublishPathAction.SET_SEARCH, data });
};

export const commentPathAction = (pathId, comment) => async (dispatch) => {
  const result = await commentPath(pathId, comment);
  if (!result) {
    toast.error('Có lỗi xảy ra');
  }
};

export const updateStatusAction = (id, token) => async (dispatch, getState) => {
  const { diff } = getState().paths;
  const res = await publishPath(id, diff.statusDiff, token);
  if (!res || res.status !== 1) toast.error('Cập nhật trạng thái thất bại');
  else {
    dispatch({
      type: ActionTypes.CLEAR_STATUS_DIFF
    })
    dispatch({
      type: ActionTypes.SET_DATA,
      data: [res.result],
    });
    toast.success('Cập nhật trạng thái thành công');
  }
};
