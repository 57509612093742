import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateScopeCourse } from '../../../actions/ActionCourse';
import { IconFree, IconSale } from '../../../icons/IconSvg';

import './style.scss';

export const ScopeCourse = () => {
    const dispatch = useDispatch();
    const {course} = useSelector(state => state.chapter);
    const {checkLogin, scopeType, trialStudy, trialLessonNum} = course.scope;

    return (
        <div>
            <div className="scope-element">
                <div className="scope-type">
                <IconFree /><input type="radio" 
                        onChange={() => dispatch(updateScopeCourse('scopeType', 'PUBLIC'))} 
                        checked={scopeType === 'PUBLIC' ? true : false}
                    />
                </div>
                <div className="scope-type">
                    <IconSale/><input type="radio" 
                        onChange={() => {
                            dispatch(updateScopeCourse('scopeType', 'PROTECT'));
                            dispatch(updateScopeCourse('checkLogin', true));
                        }}
                        checked={scopeType === 'PROTECT' ? true : false}
                    />
                </div>
            </div>
            <div className="scope-element">
                <div className="scope-label">
                    Cần đăng nhập ban đầu
                </div>
                <div>
                    <input  type="checkbox" 
                            onChange={(e) => dispatch(updateScopeCourse('checkLogin', e.target.checked))}
                            checked={scopeType === 'PROTECT' ? true : checkLogin}      
                    />
                </div>
            </div>      
            <div className="scope-element">
                <div className="scope-label">
                    Học thử
                </div>
                <div>
                    <input  type="checkbox" 
                            onChange={(e) => {
                                const check = e.target.checked;
                                dispatch(updateScopeCourse('trialStudy', check));
                                check ? dispatch(updateScopeCourse('trialLessonNum', 1)) : dispatch(updateScopeCourse('trialLessonNum', 0));
                            }}
                            defaultChecked={trialStudy}
                    />
                </div>
            </div>
            {trialStudy && <div className="scope-element" style={{color: 'blue', fontSize: '11px'}}>Học sinh chỉ có thể học thử được số bài nhất định</div>}
            {trialStudy && (<div className="scope-element">
                    <div className="scope-label">
                        Số bài giới hạn
                    </div>
                    <div>
                        <input  type="number" defaultValue={trialLessonNum} 
                                onChange={(e) => dispatch(updateScopeCourse('trialLessonNum', e.target.value))} 
                                title="Vui lòng điền số bài học thử"
                        />
                    </div>
                  </div>)
            }
        </div>
    );

};