import { TONES, removeVietnameseTones } from '../../constants/index';
import { TONE } from '../../constants/index';
import { SPLIT_RHYME } from '../../utils/VnDictionary/ryhme';
import {SPLIT_SYLLABLE, SPLIT_SPECIAL_SYLLABLE} from '../../utils/VnDictionary/syllable';
import {
  LISTFIRSTSOUND,
  LISTFIRSTSOUNDNGH,
  LISTFIRSTSOUNDQ,
  TONEBROKEN,
  TONECURVE,
  TONEFALLING,
  TONEDROP,
  objSplitSound,
  TONERISING,
  LISTSOUNDY,
} from './quizUtils';
import { listRhyme } from './rhyme';

export const getQuizPart = (lesson) => {
  const { currentSlide } = lesson;
  const { learningPoints } = lesson.localState;
  const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};
  const quizParts = learningCurrent.quizParts || [];
  return quizParts;
};

export const getContentQuestionblankQuiz = (quizParts) => {
  const contentQ = quizParts
    .map((quiz) => {
      let text = '';
      if (quiz.content && quiz.content.text && quiz.isHidden) {
        text = '___';
      } else {
        text = quiz.content && quiz.content.text ? quiz.content.text : '';
      }
      return text;
    })
    .join(' ');
  return contentQ;
};

const checkTwoArray = (array1, value) => {
  if (array1.includes(value.toLowerCase())) {
    return true;
  }
  return false;
};
export const splitTone = (str) => {
  const toneFalling = TONEFALLING;
  const toneRising = TONERISING;
  const toneCurve = TONECURVE;
  const toneBroken = TONEBROKEN;
  const toneDrop = TONEDROP;
  const data =
    str.split('').find((element) => {
      return (
        checkTwoArray(toneFalling, element) ||
        checkTwoArray(toneRising, element) ||
        checkTwoArray(toneCurve, element) ||
        checkTwoArray(toneBroken, element) ||
        checkTwoArray(toneDrop, element)
      );
    }) || '';
  if (checkTwoArray(toneFalling, data)) {
    return TONES[1].key;
  }
  if (checkTwoArray(toneRising, data)) {
    return TONES[2].key;
  }
  if (checkTwoArray(toneCurve, data)) {
    return TONES[3].key;
  }
  if (checkTwoArray(toneBroken, data)) {
    return TONES[4].key;
  }
  if (checkTwoArray(toneDrop, data)) {
    return TONES[5].key;
  }
  return TONES[0].key;
};
const checkIE = (str) => {
  if (str.startsWith('i') || str.startsWith('e') || str.startsWith('ê')) {
    return true;
  }
  return false;
};
const checkQ = (str) => {
  if (str.length > 1 && str.startsWith('u')) {
    return true;
  }
  return false;
};
const getDataNGH = (element) => {
  let newArrays = [];
  if (checkIE(`${element.w}${element.V}${element.c}`)) {
    newArrays = newArrays.concat(
      LISTFIRSTSOUNDNGH.reduce((arrays, sound) => {
        const objN = {
          text: [sound, element.w, element.V, element.c].join(''),
          splitText: [sound, element.w, element.V, element.c],
        };
        arrays = [...arrays, objN];
        return arrays;
      }, []),
    );
  }
  return newArrays;
};
const getDataQ = (element) => {
  let newArrays = [];
  if (checkQ(`${element.w}${element.V}${element.c}`)) {
    newArrays = newArrays.concat(
      LISTFIRSTSOUNDQ.reduce((arrays, sound) => {
        const objN = {
          text: [sound, element.w, element.V, element.c].join(''),
          splitText: [sound, element.w, element.V, element.c],
        };
        arrays = [...arrays, objN];
        return arrays;
      }, []),
    );
  }
  return newArrays;
};
const getDataSound = (element) => {
  let newArrays = [];
  newArrays = newArrays.concat(
    LISTFIRSTSOUND.reduce((arrays, sound) => {
      const objN = {
        text: [sound, element.w, element.V, element.c].join(''),
        splitText: [sound, element.w, element.V, element.c],
      };
      arrays = [...arrays, objN];
      return arrays;
    }, []),
  );
  return newArrays;
};
const getDataRhyme = () => {
  let datas = [];
  listRhyme.map((element) => {
    element.arrays.map((elm) => {
      const objN = {
        text: ['', elm.w, elm.v, elm.c].join(''),
        splitText: ['', elm.w, elm.v, elm.c],
      };
      datas = [...datas, objN];
    });
  });
  return datas;
};
const getDataY = () => {
  let newArrays = [];
  newArrays = newArrays.concat(
    LISTSOUNDY.reduce((arrays, sound) => {
      const objN = {
        text: ['', '', sound.v, sound.c].join(''),
        splitText: ['', '', sound.v, sound.c],
      };
      arrays = [...arrays, objN];
      return arrays;
    }, []),
  );
  return newArrays;
};
const getListWords = () => {
  let words = [];
  let arraysV = [];
  listRhyme.forEach((rhyme) => {
    arraysV = arraysV.concat(
      rhyme.arrays.reduce((newArrays, element) => {
        // cac tu ghep voi ngh/gh/k
        newArrays = newArrays.concat(getDataNGH({ ...objSplitSound, w: element.w, V: element.v, c: element.c }));
        return newArrays;
      }, []),
    );
    // cac ty ghep voi Q
    arraysV = arraysV.concat(
      rhyme.arrays.reduce((newArrays, element) => {
        newArrays = newArrays.concat(getDataQ({ ...objSplitSound, w: element.w, V: element.v, c: element.c }));
        return newArrays;
      }, []),
    );
    // ghep toan bo am dau vs van
    arraysV = arraysV.concat(
      rhyme.arrays.reduce((newArrays, element) => {
        newArrays = newArrays.concat(getDataSound({ ...objSplitSound, w: element.w, V: element.v, c: element.c }));
        return newArrays;
      }, []),
    );
    // chi co van
    arraysV = arraysV.concat(getDataRhyme());
  });
  arraysV = arraysV.concat(getDataY());
  words = words.concat(arraysV);
  return words;
};
const getOnlyText = (str) => {
  const arrays = ['.', ',', ';', ':', '/',
  '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_',  '-', '+', '=',
  '{', '}', '[', ']', '|', '\\', '"', `'`, '/', '?', '<', '>', '~'
];
  
  if (arrays.includes(str.slice(-1))) return str.substring(0, str.length - 1);
  if (arrays.includes(str[0])) return str.substring(1, str.length);
  return str;
};
export const getElementOfWord = (str) => {
  const convertText = getOnlyText(removeVietnameseTones(str));

  const data =
    getListWords().find((elm) => {
      if (elm.text === convertText.toLowerCase()) {
        return elm;
      }
    }) || {};
  const lists = data.splitText
    ? data.splitText.map((element) => {
        return { text: element };
      })
    : [];
  return lists;
};

//
const tones = ["\u0300", "\u0301", "\u0303", "\u0309", "\u0323"];
export const findAndRemoveTone = (str) => {
  str = str.normalize('NFD');
  let tone = '';
  let index = -1;
  for (let tone of tones) {
    index = str.indexOf(tone);
    if (index !== -1) break;
  }
  if (index !== -1) {
    tone = str.charAt(index);
    str = str.replace(new RegExp(tone, 'g'), '');
  }
  return {
    syllable: str,
    tone,
    index,
  }
};

export const getStructureOfWord = (str) => {
  str = getOnlyText(str.toLowerCase());
  const strConvertedObj = findAndRemoveTone(str);

  const specialSyllableIndex = SPLIT_SPECIAL_SYLLABLE.findIndex(element => element.syllable.normalize('NFD') === strConvertedObj.syllable.normalize('NFD'));
  let syllableIndex = -2;
  let consonant = '';
  let rhyme = '';
  if (specialSyllableIndex === -1) {
    syllableIndex = SPLIT_SYLLABLE.findIndex(element => element.syllable.normalize('NFD') === strConvertedObj.syllable.normalize('NFD'));
    if (syllableIndex !== -2 && syllableIndex !== -1) {
      consonant = SPLIT_SYLLABLE[syllableIndex].consonant;
      rhyme = SPLIT_SYLLABLE[syllableIndex].rhyme;
    }
  } else {
    consonant = SPLIT_SPECIAL_SYLLABLE[specialSyllableIndex].consonant;
    rhyme = SPLIT_SPECIAL_SYLLABLE[specialSyllableIndex].rhyme;
  }

  if ((syllableIndex !== -1 && syllableIndex !== -2) || specialSyllableIndex !== -1) {
    return {
      syllable: str,
      consonant,
      rhyme,
      tone: convertToneToString(strConvertedObj.tone),
    }
  }

  return {};
};

export const getStructureOfRhyme = rhyme => {
  if (!rhyme) return null;
  const data = SPLIT_RHYME.find(item => item.rhyme.normalize('NFD') === rhyme.normalize('NFD'));
  if (data) return data;
  return null;
}

export const convertToneToComponentByTone = (tone, icon = 'v1') => {
  let index = 0;
  switch(tone) {
    case TONE.FALLING.text:
      index = 1;
      break;
    case TONE.RISING.text:
      index = 2;
      break;
    case TONE.CURVE.text:
      index = 3;
      break;
    case TONE.BROKEN.text:
      index = 4;
      break;
    case TONE.DROP.text:
      index = 5;
      break;
    default:
      index = 0;
      break;
  }
  return icon === 'v1' ? TONES[index].ICON : TONES[index].ICONV2;
};

export const convertToneToString = tone => {
  let result;
  switch (tone) {
    case TONE.FALLING.unicode:
      result = TONE.FALLING.text;
      break;
    case TONE.RISING.unicode:
      result = TONE.RISING.text;
      break;
    case TONE.CURVE.unicode:
      result = TONE.CURVE.text;
      break;
    case TONE.BROKEN.unicode:
      result = TONE.BROKEN.text;
      break;
    case TONE.DROP.unicode:
      result = TONE.DROP.text;
      break;
    case TONE.LEVEL.unicode:
      result = TONE.LEVEL.text;
      break;
    default:
      result = '';
      break;
  }
  return result;
}