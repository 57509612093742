import React from 'react';
import { getFullUrl } from '../../../utils/imageProcessing';

class TestAudio extends React.Component {
  render() {
    const { audioUrl } = this.props;
    return audioUrl ? (
      <div className="div__select_void fl_b">
        <audio style={{ height: '30px' }} controls>
          <source src={getFullUrl(audioUrl)} />
          Your browser does not support the audio element.
        </audio>
      </div>
    ) : null;
  }
}

export default TestAudio;
