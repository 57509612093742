import React from 'react';
import { Container } from 'reactstrap';

// components
import AwardAdminHeader from '../../../components/Common/AwardAdminHeader';

// local
import LuckyBoxDataTable from './LuckyBoxDataTable/LuckyBoxDataTable'

const LuckyBoxContainer = () => (
  <>
    {/* header */}
    <AwardAdminHeader />

    {/* body */}
    <Container className="t-pt-[100px] t-w-[80%] t-mx-auto">
      {/* page title */}
      <h1 className="mb-0">Hộp may mắn</h1>

      {/* lucky box data table */}
      <LuckyBoxDataTable className="t-mt-20" />
    </Container>
  </>
);

export default LuckyBoxContainer;
