import React from 'react';
import { IconVideocam, IconImage, IconTextField, IconSpeak, IconSquare } from '../../icons/IconSvg';
import { MEDIA_TYPE } from '../../utils/constants';

const datas = [
  {
    type: MEDIA_TYPE.TEXT,
    ComponentItem: (() => <IconTextField />)(),
  },
  {
    type: MEDIA_TYPE.VIDEO,
    ComponentItem: (() => <IconVideocam />)(),
  },
  {
    type: MEDIA_TYPE.IMAGE,
    ComponentItem: (() => <IconImage />)(),
  },
  {
    type: MEDIA_TYPE.SQUARE,
    ComponentItem: (() => <IconSquare />)(),
  },
];
const _ComponentOption = ({ versionPreview, type, changeOption, mediaQ, speak, style }) => {
  const listDatas = datas.filter((data) => data.type !== mediaQ && data.type !== speak);
  const selectedData = listDatas.find((data) => data.type === type);
  return (
    <div className="ui_component_option" style={style || {}}>
      {selectedData && (
        <div className="component__option__label">
          <div className="fl icon">
            <button
              key={selectedData.type}
              className={`btn_option${selectedData.type === type ? ' btn_option_active' : ''}`}
              type="button"
              onClick={() => changeOption(selectedData.type)}
            >
              {selectedData.ComponentItem}
            </button>
          </div>
        </div>
      )}
      {!versionPreview && (
        <div className="component__option">
          <div className="fl icon">
            {listDatas.map((data) => (
              <button
                key={data.type}
                className={`btn_option${data.type === type ? ' btn_option_active' : ''}`}
                type="button"
                onClick={() => changeOption(data.type)}
              >
                {data.ComponentItem}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
const ComponentOption = React.memo(_ComponentOption);
export default ComponentOption;
