import React from 'react';
import { connect } from 'react-redux';
import { TONES } from '../../../constants/index';
import { formatQuizpartEnter } from '../../Quiz/quizUtils';

class ViewToneOfWord extends React.Component {
  render() {
    const { learningCurrent } = this.props;
    const quizpartsEnter = formatQuizpartEnter(learningCurrent.quizParts) || [];
    return (
      <div style={{ padding: '20px 0' }} className="text-center view_match_the_pair_quiz">
        <div style={{ marginBottom: '50px' }} className="text-bold fs-25 text-question">
          {learningCurrent.question || ''}?
        </div>
        {quizpartsEnter.map((partEnter, idx) => {
          return (
            <div className="fl" style={{ justifyContent: 'center', marginTop: '10px' }}>
              <div key={`e-${idx}`} className="list__text fl_w">
                {Object.values(partEnter).map((data, index) => (
                  <div key={index} id={`div_tone_${index}`}>
                    <div>
                      <div className="fs-25">{data.content.text}</div>
                      <div style={{ margin: '10px 20px 0', position: 'relative' }}>
                        {(() => {
                          const toneCurrent =
                            TONES.find((tone) => data.square && data.square[0] && tone.key === data.square[0].tone) ||
                            null;
                          if (toneCurrent && toneCurrent.ICON) {
                            return toneCurrent.ICON;
                          }
                          return null;
                        })()}
                        <span style={{ position: 'absolute', top: '15px', right: '10px' }}>
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.85156 0.148437C7.95052 0.247396 8 0.364583 8 0.5C8 0.635416 7.95052 0.752604 7.85156 0.851562L4.35156 4.35156C4.2526 4.45052 4.13542 4.5 4 4.5C3.86458 4.5 3.7474 4.45052 3.64844 4.35156L0.148438 0.851562C0.0494792 0.752604 0 0.635416 0 0.5C0 0.364583 0.0494792 0.247396 0.148438 0.148437C0.247396 0.049479 0.364583 0 0.5 0H7.5C7.63542 0 7.7526 0.049479 7.85156 0.148437Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(null, {})(ViewToneOfWord);
