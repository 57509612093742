import { toast } from 'react-toastify';
import { createUser, deleteUser, getUsers } from '../apis/user';

const initState = {
  data: [],
  syncing: false,
  users: []
};

export const UserAction = {
  SET_DATA: '@@users/SET_DATA',
  SET_SYNCING: '@@users/SET_SYNCING',
  SET_USERS: '@@auth/SET_USERS',
  ADD_USER: '@@auth/ADD_USER',
  DELETE_USER: '@@auth/DELETE_USER'
};

export const fetchUserAction = () => async (dispatch) => {
  dispatch({ type: UserAction.SET_SYNCING, data: true });
  try {
    const res = await getUsers();
    dispatch({ type: UserAction.SET_DATA, data: res.result.users });
  } catch (error) {
    console.log('error', error);
    return toast.error(`Bạn phải là admin mới được cấp quyền để vào trang này`);
  }

  dispatch({ type: UserAction.SET_SYNCING, data: false });
};

export const createUserAction = ({ email, name, password }) => async (dispatch, getState) => {
  const { data } = getState().users;
  const res = await createUser({ email, name, password });
  if (res.status === 0) return toast.error(`Error ${res.code}: ${res.message}`);
  dispatch({ type: UserAction.SET_DATA, data: [res.result.user, ...data] });
  toast.success(`Đã tạo người dùng: ${name}. Hãy yêu cầu người dùng check email để truy cập`);
};

export const deleteUserAction = (user) => async (dispatch, getState) => {
  const { data } = getState().users;
  const res = await deleteUser(user.id);
  if (res.status === 0) return toast.error(`Error ${res.code}: ${res.message}`);
  dispatch({ type: UserAction.SET_DATA, data: data.filter(({ id }) => id !== user.id) });
  toast.success(`Đã xóa người dùng: ${user.name}`);
};

export const users = (state = initState, action) => {
  switch (action.type) {
    case UserAction.SET_SYNCING:
      return {
        ...state,
        syncing: action.data,
      };

    case UserAction.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case UserAction.SET_USERS:
      return {
        ...state,
        users: action.data
      }
    case UserAction.ADD_USER:
      return {
        ...state,
        users: [...state.users, action.data]
      }
    case UserAction.DELETE_USER:
      return {
        ...state,
        users: state.users.filter(e => e.id !== action.data)
      }
    default:
      return state;
  }
};

export default users;
