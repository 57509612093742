import React, { memo, useMemo } from 'react';
import cls from 'classnames';
import { Form, FormGroup, Label } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BiHelpCircle } from 'react-icons/bi';
import _reduce from 'lodash/reduce';

// components
import InputField from '../../../../../components/Common/InputField';
import InputNumberField from '../../../../../components/Common/InputNumberField';
import CollectionCategorySelectField from '../../../../../components/award-admin/CollectionCategorySelectField';
import CollectionItemSelectField from '../../../../../components/award-admin/CollectionItemSelectField';

// constants
import { LUCKY_BOX_TYPES } from '../../../../../constants/award-admin/luckyBoxType';
import { LUCKY_BOX_RARITY, LUCKY_BOX_RARITY_TOOLTIP } from '../../../../../constants/award-admin/luckyBoxRarity';
import {
  JS_FIELD_TO_COLLECTION_ITEM_RARITY_MAPPING,
  LUCKY_BOX_RARITY_TO_COLLECTION_ITEM_RARITY_MAPPING,
} from '../../../../../constants/award-admin/collectionItemRarity';

// form validation schema
export const validationFormSchema = yup.object().shape(
  {
    morePublishQuantity: yup
      .string()
      .required('Bạn cần nhập số lượng phát hành thêm của hộp may mắn')
      .test('is-valid', 'Số lượng phát hành thêm phải lớn hơn 0', (value) => Number(value) > 0)
      .label('Số lượng phát hành thêm'),
    collectionCategoryIds: yup
      .array()
      .of(yup.string())
      .label('Thể loại chứa vật phẩm có thể có trong hộp may mắn')
      .when('collectionItemIds', {
        is: (val) => !!val && val.length > 0,
        then: yup
          .array()
          .of(yup.string())
          .max(
            0,
            'Bạn đã chọn vật phẩm có thể có trong hộp may mắn nên không thể chọn thêm thể loại chứa vật phẩm có thể có trong hộp may mắn nữa!',
          ),
        otherwise: yup.array().of(yup.string()),
      }),
    collectionItemIds: yup
      .array()
      .of(yup.string())
      .label('Vật phẩm có thể có trong hộp may mắn')
      .when('collectionCategoryIds', {
        is: (val) => !!val && val.length > 0,
        then: yup
          .array()
          .of(yup.string())
          .max(
            0,
            'Bạn đã chọn thể loại chứa vật phẩm có thể có trong hộp may mắn nên không thể chọn thêm vật phẩm có thể có trong hộp may mắn nữa!',
          ),
        otherwise: yup.array().of(yup.string()),
      }),
  },
  ['collectionCategoryIds', 'collectionItemIds'],
);

const DEFAULT_LUCKY_BOX_TOOLTIP =
  'Bạn chỉ có thể chọn vật phẩm hoặc thể loại chứa vật phẩm cho hộp may mắn.\nNếu bạn không chọn hệ thống sẽ tự động chọn thay bạn.';

const GenerateMoreLuckyBoxForm = memo(({ id, open, toggle, isEdit = false }) => {
  // -- form state --
  const { watch } = useFormContext();
  const type = watch('type');
  const rarity = watch('rarity');
  const itemSpawnRateByRarity = watch('itemSpawnRateByRarity');
  const collectionCategoryIds = watch('collectionCategoryIds');
  const collectionItemIds = watch('collectionItemIds');

  // -- is default lucky box --
  const isDefaultLuckyBox = useMemo(() => type === LUCKY_BOX_TYPES.DEFAULT, [type]);

  // -- allowed item rarities --
  const allowedItemRarities = useMemo(
    () =>
      _reduce(
        itemSpawnRateByRarity,
        (memo, spawnRate, itemRarityJSField) => {
          if (spawnRate > 0) {
            memo.push(JS_FIELD_TO_COLLECTION_ITEM_RARITY_MAPPING[itemRarityJSField]);
          }

          return memo;
        },
        [],
      ),
    [itemSpawnRateByRarity],
  );

  // -- lucky box tooltip --
  const luckyBoxTooltip = useMemo(
    () =>
      isDefaultLuckyBox && rarity
        ? `${DEFAULT_LUCKY_BOX_TOOLTIP}\n${LUCKY_BOX_RARITY_TOOLTIP[rarity]}`
        : DEFAULT_LUCKY_BOX_TOOLTIP,
    [rarity],
  );

  // -- is selected collection category ids --
  const isSelectedCollectionCategoryIds = useMemo(() => collectionCategoryIds?.length > 0, [collectionCategoryIds]);

  // -- is selected collection item ids --
  const isSelectedCollectionItemIds = useMemo(() => collectionItemIds?.length > 0, [collectionItemIds]);

  return (
    <Form onSubmit={() => {}}>
      {/* name */}
      <FormGroup>
        <Label htmlFor="name">Tên hộp may mắn</Label>
        <InputField type="text" name="name" id="name" disabled={true} />
      </FormGroup>

      {/* more publish quantity */}
      <FormGroup>
        <Label htmlFor="morePublishQuantity">Số lượng phát hành thêm</Label>
        <InputNumberField
          name="morePublishQuantity"
          id="morePublishQuantity"
          thousandSeparator={true}
          allowNegative={false}
          min={0}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            if (floatValue == null) {
              return formattedValue === '';
            } else {
              return floatValue >= 0;
            }
          }}
          placeholder="Nhập số lượng phát hành thêm"
        />
      </FormGroup>

      {/* collection category ids */}
      <FormGroup>
        <Label htmlFor="collectionCategoryIds">
          Danh sách thể loại chứa vật phẩm có thể có trong hộp may mắn
          <BiHelpCircle className="ml-1" title={luckyBoxTooltip} />
        </Label>
        <CollectionCategorySelectField
          name="collectionCategoryIds"
          id="collectionCategoryIds"
          requestParams={{ itemRarities: allowedItemRarities }}
          selectProps={{
            placeholder: 'Chọn thể loại',
            isMulti: true,
            isClearable: true,
            isDisabled: isSelectedCollectionItemIds,
          }}
        />
      </FormGroup>

      {/* collection item ids */}
      <FormGroup>
        <Label htmlFor="collectionItemIds">
          Danh sách vật phẩm có thể có trong hộp may mắn
          <BiHelpCircle className="ml-1" title={luckyBoxTooltip} />
        </Label>
        <CollectionItemSelectField
          name="collectionItemIds"
          id="collectionItemIds"
          requestParams={{ rarities: allowedItemRarities }}
          selectProps={{
            placeholder: 'Chọn vật phẩm',
            isMulti: true,
            isClearable: true,
            isDisabled: isSelectedCollectionCategoryIds,
          }}
        />
      </FormGroup>
    </Form>
  );
});

export default GenerateMoreLuckyBoxForm;
