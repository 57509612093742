import { toast } from 'react-toastify';
import { commentCourse, getCourse } from '../apis/course';
import {
  getFrameContent,
  getVersionFrameContent,
  submitFrameContent,
  updateStatusFrameContent,
  updateTitleFrameContent,
} from '../apis/publishManagement';
import { updatePathAction } from '../reducers/paths';
import { commentChapter } from '../apis/chapter';
import { commentLesson } from '../apis/lesson';
import { commentTest } from '../apis/test';
import { FrameContentAction } from '../reducers/frameContent';
import { diff } from 'deep-object-diff';
import { FRAME_CONTENT_STATUS } from '../constants';
import { setChapterLoading } from '../reducers/chapter';
import { SECTION_TYPE } from '../utils/constants';
import { ActionTypes } from '../reducers/courses';

export const updateFrameContent = (data) => (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.UPDATE_FRAME_CONTENT,
    data,
  });
};

export const getFrameContentAction = (courseId) => async (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.LOADING,
    data: { loading: true },
  });
  const res = await getFrameContent({ courseId });
  if (res && res.status === 1 && res.result) {
    dispatch(updateFrameContent(res.result));
    dispatch({
      type: FrameContentAction.UPDATE_ORIGIN,
      data: res.result,
    });
    dispatch(getVerFrameContentAction(res.result.id));
  }
  dispatch({
    type: FrameContentAction.LOADING,
    data: { loading: false },
  });
};

export const commentCourseAction = (courseId, comment) => async (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.ADD_COMMENT_COURSE,
    data: { comment },
  });
  const result = await commentCourse(courseId, comment);
  if (!result) {
    toast.error('Có lỗi xảy ra');
  }
};

export const commentChapterAction = (chapterId, comment) => async (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.ADD_COMMENT_CHAPTER,
    data: { id: chapterId, comment },
  });
  const result = await commentChapter(chapterId, comment);
  if (!result) {
    toast.error('Có lỗi xảy ra');
  }
};

export const commentLessonAction = (lessonId, comment) => async (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.ADD_COMMENT_LESSON,
    data: { id: lessonId, comment },
  });
  const result = await commentLesson(lessonId, comment);
  if (!result) {
    toast.error('Có lỗi xảy ra');
  }
};

export const commentTestAction = (testId, comment) => async (dispatch, getState) => {
  dispatch({
    type: FrameContentAction.ADD_COMMENT_TEST,
    data: { id: testId, comment },
  });
  const result = await commentTest(testId, comment);
  if (!result) {
    toast.error('Có lỗi xảy ra');
  }
};

export const updateCourseV2 =
  ({ id, title, status }) =>
  (dispatch, getState) => {
    dispatch({
      type: FrameContentAction.EDIT_LESSON_NAME,
      data: { id, title, status },
    });
  };

export const updateFieldCourse =
  ({ id, key, value, isUpdateChapter = false }) =>
  (dispatch, getState) => {
    const { originFrameContent, chapters } = getState().frameContent;
    if (key === 'status' && value !== originFrameContent.course.status) {
      dispatch({
        type: FrameContentAction.UPDATE_STATUS_DIFF,
        data: { id, type: 'course', status: value },
      });
      if (value === FRAME_CONTENT_STATUS.PUBLISH && isUpdateChapter) {
        chapters.forEach((chapter) => {
          dispatch(
            updateFieldChapter({
              id: chapter.chapterId,
              key: 'status',
              value: FRAME_CONTENT_STATUS.PUBLISH,
            }),
          );
        });
      }
    }
    if (key === 'courseName' && value !== originFrameContent.course.courseName) {
      dispatch({
        type: FrameContentAction.UPDATE_NAME_DIFF,
        data: { id, type: 'course', title: value },
      });
    }
    dispatch({
      type: FrameContentAction.UPDATE_FIELD_COURSE,
      data: { id, key, value },
    });
  };

export const updateFieldTest =
  ({ id, key, value }) =>
  (dispatch, getState) => {
    const { originFrameContent } = getState().frameContent;
    if (key === 'status') {
      const oldChapter = originFrameContent.chapters.find(
        (chapter) => !!chapter.sections.find((section) => section.sectionId === id),
      );
      const oldTest = oldChapter.sections.find((section) => section.sectionId === id);
      if (oldTest.status !== value) {
        dispatch({
          type: FrameContentAction.UPDATE_STATUS_DIFF,
          data: { id, type: SECTION_TYPE.TEST, status: value },
        });
      }
    }
    if (key === 'chapterName') {
      const oldChapter = originFrameContent.chapters.find((e) => e.chapterId === id);
      if (oldChapter.chapterName !== value) {
        dispatch({
          type: FrameContentAction.UPDATE_NAME_DIFF,
          data: { id, type: 'chapter', title: value },
        });
      }
    }
    dispatch({
      type: FrameContentAction.UPDATE_FIELD_CHAPTER,
      data: { id, key, value },
    });
  };

export const updateFieldChapter =
  ({ id, key, value }) =>
  (dispatch, getState) => {
    const { originFrameContent } = getState().frameContent;
    if (key === 'status') {
      const oldChapter = originFrameContent.chapters.find((e) => e.chapterId === id);
      if (oldChapter.status !== value) {
        dispatch({
          type: FrameContentAction.UPDATE_STATUS_DIFF,
          data: { id, type: 'chapter', status: value },
        });
      }
    }
    if (key === 'chapterName') {
      const oldChapter = originFrameContent.chapters.find((e) => e.chapterId === id);
      if (oldChapter.chapterName !== value) {
        dispatch({
          type: FrameContentAction.UPDATE_NAME_DIFF,
          data: { id, type: 'chapter', title: value },
        });
      }
    }
    dispatch({
      type: FrameContentAction.UPDATE_FIELD_CHAPTER,
      data: { id, key, value },
    });
  };

export const updateFieldSection =
  ({ id, key, value, type }) =>
  (dispatch, getState) => {
    const { originFrameContent } = getState().frameContent;
    const sections = originFrameContent.sections
    if (key === 'sectionName') {
      for(let i=0; i<sections.length; i++) {
        if(sections[i].lessonId ? sections[i].lessonId.id === id : sections[i].testId.id === id) {
          if(sections[i].lessonId ? sections[i].lessonId.title !== value : sections[i].testId.title !== value) {
            dispatch({
              type: FrameContentAction.UPDATE_NAME_DIFF,
              data: { id, type, title: value },
            });
          }
        }
      }
      /*originFrameContent.sections.every((section) => {
        console.log(section, id)
        if (!!section) {
          if (section.sectionName !== value) {
            dispatch({
              type: FrameContentAction.UPDATE_NAME_DIFF,
              data: { id, type: section.type, title: value },
            });
          }
          return false;
        }
      });*/
    }

    if (key === 'status') {
      const oldSection = originFrameContent.sections.find((section) => type === SECTION_TYPE.LESSON ? section?.lessonId?.id === id 
                                                                                                    : section?.testId?.id === id );
      if (oldSection.status !== value) {
        dispatch({
          type: FrameContentAction.UPDATE_STATUS_DIFF,
          data: { id, type: oldSection.type, status: value },
        });
      }
    }

    dispatch({
      type: FrameContentAction.UPDATE_FIELD_SECTION,
      data: { id, key, value, type },
    });
  };

export const updateStatusAction = (courseId, token) => async (dispatch, getState) => {
  const { diff, course, comments, sections } = getState().frameContent;
  const res = await updateStatusFrameContent(courseId, diff.statusDiff, token);
  if (!res || res.status !== 1) toast.error('Cập nhật trạng thái thất bại');
  else {
    dispatch({
      type: FrameContentAction.UPDATE_ORIGIN,
      data: {
        course,
        sections,
        comments,
      },
    });
    toast.success('Cập nhật trạng thái thành công');
  }
};

export const updateTitleAction = () => async (dispatch, getState) => {
  const { diff, course, chapters, comments, sections } = getState().frameContent;
  const res = await updateTitleFrameContent(diff.nameDiff);
  if (!res || res.status !== 1) toast.error('Cập nhật trạng thái thất bại');
  else {
    dispatch({
      type: FrameContentAction.UPDATE_ORIGIN,
      data: {
        course,
        chapters,
        comments,
        sections
      },
    });
    toast.success('Cập nhật trạng thái thành công');
  }
};

export const getVerFrameContentAction = (originId) => async (dispatch, getState) => {
  const res = await getVersionFrameContent(originId);
  if (!res || res.status !== 1) {
  } else {
    dispatch({
      type: FrameContentAction.GET_VERSION_FRAME_CONTENT,
      data: res.result,
    });
  }
};

export const submitFrameContentAction = (course) => async (dispatch, getState) => {
  const { data: courses } = getState().courses;

  try {
    const res = await submitFrameContent(course.id);
    if (!res || res.status !== 1) return toast.error('Yêu cầu thất bại');
    const coursesAfterSubmit = courses.map((element) => {
      if (element.id === course.id) element.status = 'SUBMITTED';
      return element;
    });
    dispatch({ type: ActionTypes.SET_DATA, data: coursesAfterSubmit });
    //dispatch({ type: ActionTypes.SET_VERSION_PATH, data: })
    toast.success(`Đã nộp khóa học: ${course.title}`);
  } catch (error) {
    //console.log('error', error);
  }
};
