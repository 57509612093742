import React, { useState, useEffect } from 'react';
import TestAudio from './TestAudio';

function _InputTextarea(props) {
  const { value, onChange, audioUrl, placeholder, limitText } = props;
  return (
    <div className="input-textarea-container">
      <TestAudio audioUrl={audioUrl} />
      <textarea
        maxLength={limitText || null}
        defaultValue={value}
        placeholder={placeholder || 'Nhập nội dung'}
        className="next-input border-input-none form__type"
        onChange={(e) => {
          if (limitText && e.target.value.length > limitText) return;
          else onChange(e.target.value);
        }}
      />
      {limitText && <p>{value.length || 0}/{limitText}</p>}
    </div>
  );
}
const InputTextarea = React.memo(_InputTextarea);
export default InputTextarea;
