import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Fade from '../../components/Common/Fade';
import ListTemplate from '../../components/Lesson-v2/UITemplate/common/ListTemplate';
import TabTemplate from '../../components/Lesson-v2/UITemplate/TabTemplate';
import LessonLeft from '../../components/Lesson-v2/LessonLeft/index';
import LessonRight from '../../components/Lesson-v2/LessonRight/index';
import { fetchCourseAction } from '../../reducers/courses';
import '../Lesson/style.scss';
import ComponentSelect from './ComponentSelect';

class QuizTestFixed extends React.Component {
  render() {
    return (
      <Fade>
        <div className="container wrapper_lesson">
          <div className="wrapper_quiz">
            <div className="content">
              <div className="wrapper_template">
                <div className="lesson__template fl">
                  <div style={{ width: '300px' }} className="ui__tab_function">
                  <div className="wraper_tab_function">
                      <ComponentSelect id={this.props.id}/>
                      {/* <WraperIconFunction inTestBank="true" /> */}
                    </div>
                  </div>
                  <TabTemplate />
                  <ListTemplate />
                </div>
              </div>
              <div style={{ marginTop: '20px' }} className="fl div__content">
                <LessonLeft quizTest="true" checkWelcome='false'/>
                <LessonRight viewQuiz="true" checkWelcome='false'/>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip effect="solid"/>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.lesson,
});

export default connect(mapStateToProps, {
  fetchCourseAction,
})(QuizTestFixed);
