import React from 'react';
import { connect } from 'react-redux';
import Fade from '../../components/Common/Fade';
import Header from '../../components/Common/Header';
import ComponentRight from './ComponentRight';
import ComponetLeft from './ComponetLeft';
import './style.scss';
// import {findChapterIdBySectionId} from '../../actions/ActionChapter'
class ComponentTest extends React.Component {

  // componentDidMount() {
  //   this.props.findChapterIdBySectionId()
  // }

  render() {
    const { id } = this.props;
    return (
      <Fade>
        <div>
          <Header
            type="test"
            courseName={this.props.stateProps?.course?.title || ''}
            name="Tạo mới bài kiểm tra"
            link={`/course/${this.props.stateProps?.course?.id}`}
          />
          <div className="">
            <div>
              <div className="fl wraper_test">
                <ComponetLeft course={this.props.stateProps?.course} chapterCurrent={this.props.stateProps?.chapterCurrent} />
                <ComponentRight course={this.props.stateProps?.course} chapterCurrent={this.props.stateProps?.chapterCurrent} id={id} />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default connect(null, {})(ComponentTest);
