import React, { useState } from 'react';
import { Popover, PopoverBody, UncontrolledPopover } from 'reactstrap';

export default function PopoverCustom(props) {
  return (
    <Popover
      style={{ maxWidth: props.maxWidth || '500px', width: props.width ,left: '0px !important', maxHeight: props.maxHeight || '200px', overflowY: 'auto', overflowX: 'hidden' }}
      placement="bottom"
      isOpen={props.popoverOpen}
      target={props.id}
      toggle={props.toggle}
      className={props.className || ''}
      trigger="legacy"
    >
      <PopoverBody>{props.children}</PopoverBody>
    </Popover>
  );
}

export const PopoverCustomUseUnController = (props) => {
  return (
    <UncontrolledPopover 
      trigger="legacy" 
      style={{ maxWidth: props.maxWidth || '500px', width: props.width ,left: '0px !important', maxHeight: props.maxHeight || '200px', overflowY: 'auto', overflowX: 'hidden' }}
      placement="bottom"
      target={props.id}
      className={props.className || ''}
    >
      <PopoverBody>{props.children}</PopoverBody>
    </UncontrolledPopover>
  );

};