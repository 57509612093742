import { toast } from "react-toastify";
import { updateCourse } from "../apis/course";

export const updateStatusCourse = (id, status) => (dispatch, getState) => {
    dispatch({
        type: 'CHANGE_STATUS_COURSE',
        data: {
            id,
            status
        }
    });
};

export const updateScopeCourse = (type, value) => (dispatch, getState) => {
    const {course} = getState().chapter;
    dispatch({
        type: 'SET_COURSE_IN_CHAPTER',
        data: {
            ...course,
            scope: {
                ...course.scope,
                [type]: value,
            }
        }
    })
    dispatch({
        type: 'CHANGE_SCOPE_COURSE',
        data: {
            type,
            value,
        }
    });
};

export const updateCouponCourse = (type, value) => (dispatch, getState) => {
    const {course} = getState().chapter;
    dispatch({
        type: 'SET_COURSE_IN_CHAPTER',
        data: {
            ...course,
            coupon: {
                ...course.coupon,
                [type]: value,
            }
        }
    })
    dispatch({
        type: 'CHANGE_COUPON_COURSE',
        data: {
            type,
            value,
        }
    });
};

// export const saveFeeAndCouponAction = () => async (dispatch, getState) => {
//     dispatch({ type: 'SET_SYNCING', data: true });
//     const {course} = getState().chapter;
//     if(course.fee.feeType === 'SALE' && course.fee.price == 0) {
//         toast.error("Cập nhật không thành công");
//     } else {
//         const res = await updateCourse(course.id, course);
//         (res && res.status === 1) ? toast.success('Cập nhật thành công') : toast.error('Có lỗi xảy ra')
//     }
//     dispatch({ type: 'SET_SYNCING', data: false });
// }