import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';
import { SlidesActions } from '../../../reducers/lesson';
import InputQuestion from '../common/InputQuestion';
import { getFullUrl, imageCompress, getDirectoryUrlFromFile2 } from '../../../utils/imageProcessing';
import { deleteFile } from '../../../apis/upload';
import './style.scss';
class WritingQuiz extends React.Component {
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  readImg = async (event, learningCurrent, currentSlide) => {
    const compressedFile = await imageCompress(event.target.files[0]);
    if (learningCurrent.questionImageUrl !== '') await deleteFile(learningCurrent.questionImageUrl);
    const questionImageUrl = await getDirectoryUrlFromFile2(compressedFile);
    this.props.changeQuiz('questionImageUrl', questionImageUrl, 'quiz', currentSlide);
    return { ...learningCurrent, questionImageUrl };
  };
  render() {
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();

    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    return (
      <div className="writing_quiz">
        <div>Tạo câu hỏi</div>
        <div className="wrapper_quiz_textarea">
          <InputQuestion learningCurrent={learningCurrent} audioUrl={learningCurrent.questionAudioUrl || ''} />
        </div>
        <div className="fl">
          <div style={{ margin: 'auto 10px auto 0' }}>Hình ảnh hướng dẫn</div>
          <button
            style={{ border: '1px solid #ced4da' }}
            className="btn"
            onClick={() => {
              if (this.dropzoneRefGuid) {
                this.dropzoneRefGuid.click();
              }
            }}
          >
            Tải ảnh
            <input
              style={{ display: 'none' }}
              type="file"
              multiple
              onChange={(e) => this.readImg(e, learningCurrent, currentSlide)}
              ref={(ref) => {
                this.dropzoneRefGuid = ref;
              }}
            />
          </button>
        </div>
        {learningCurrent.questionImageUrl ? (
          <div className="wapper__img_modal">
            <img
              src={learningCurrent.questionImageUrl ? getFullUrl(learningCurrent.questionImageUrl) : ''}
              id="background_test"
            />
            {/* <span onClick={() => this.props.changeQuiz('questionImageUrl', null, 'quiz', currentSlide)}>
              <IconDelete />
            </span> */}
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuiz, updateCurrentLayout: SlidesActions.updateCurrentLayout })(
  WritingQuiz,
);
