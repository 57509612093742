import Keycloak from 'keycloak-js';

export const KeycloakInstance = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
  url: `${process.env.REACT_APP_KEYCLOAK_DOMAIN}`,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

// export const KeycloakInstance = new Keycloak({
//   realm: "VietStudy",
//   url: `https://dev-accounts.vbee.vn/auth`,
//   clientId: "cms-fe",
// });