import api from './api';
import { getDirectoryUrlFromFile2 } from '../utils/imageProcessing';
import { formatLearningPoints } from '../utils/normalize';

export const getLessonId = async (lessonId) =>
  api({
    method: 'GET',
    url: `/lessons/${lessonId}`,
  });

export const getLessons = async () =>
  api({
    method: 'GET',
    url: '/lessons',
  });

export const createLesson = async ({ title, imageUrl, learningPoints, thumbnailImageUrl }) => {
  const imageURLFile = await getDirectoryUrlFromFile2(imageUrl);
  const thumbnailImageUrlFile = await getDirectoryUrlFromFile2(thumbnailImageUrl);
  return api({
    method: 'POST',
    url: `/lessons`,
    data: {
      title,
      imageURL: imageURLFile,
      thumbnailImageUrl: thumbnailImageUrlFile,
      learningPoints: formatLearningPoints(learningPoints),
    },
  }).then((res) => res);
};

export const updateLesson = async (input) => {
  const data = input;
  if (input.learningPoints) {
    data.learningPoints = formatLearningPoints(input.learningPoints);
  }

  if (input.approvedBy) data.approvedBy = input.approvedBy.id;
  if (input.createdBy) data.createdBy = input.createdBy.id;

  return api({
    method: 'PUT',
    url: `/lessons/${data.id}`,
    data,
  });
};

export const deleteLesson = async (lessonId) =>
  api({
    method: 'DELETE',
    url: `/lessons/${lessonId}`,
  });

export const commentLesson = async (lessonId, comment) => {
  const res = await api({
    method: 'POST',
    url: `/lessons/comment/${lessonId}`,
    data: { comment },
  });
  return res;
};
