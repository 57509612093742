import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompareLpAction, updateCompareLp } from '../../../../actions/ActionPublishManagement';
import { IconClose } from '../../../../icons/IconSvg';
import { ButtonAction } from '../../../Common/Button/ButtonAction';
import { LearningPointView } from './compareLp';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from '../../../Common/Loading';
import { LEARNING_POINT_TYPE } from '../../../../utils/constants';

export const CompareLp = ({ learningPoint = null }) => {
  const dispatch = useDispatch();
  const { compareLearningPoint } = useSelector(state => state.publishManagement);
  const { localState } = useSelector(state => state.lesson);

  useEffect(() => {
    dispatch(getCompareLpAction({
      lessonId: localState.id,
      id: compareLearningPoint.learningPointId,
      type: compareLearningPoint.type }));
  }, [])

  const getName = () => {
    const type = compareLearningPoint.type;
    if (type && type.includes(LEARNING_POINT_TYPE.THEORY)) return 'Slide lý thuyết phiên bản gần nhất';
    else if (type && type.includes(LEARNING_POINT_TYPE.QUIZ)) return 'Slide bài tập phiên bản gần nhất';
    else if (type && type.includes(LEARNING_POINT_TYPE.CHECKPOINT)) return 'Slide phần thưởng phiên bản gần nhất';
    return '';
  }

  return (
    <div className="compare-learning-point">
      <div className="compare-learning-point-header">
        <div className="learning-point-title">{getName()}</div>
        <div className="close" onClick={() => dispatch(updateCompareLp({ isOpen: false, data: null }))}>
          <IconClose />
        </div>
      </div>
      <div className="content">
        {learningPoint && Object.keys(learningPoint).length > 0 && (
          <LearningPointView
            data={learningPoint}
            preType={compareLearningPoint.type}
            quizType={compareLearningPoint.data.type}
            lessonLayout={compareLearningPoint.data.layout || 0}
          />
        )}
        {!learningPoint && (
          <div className="compare-learning-point-loading">
            <CircularProgress />
          </div>
        )}
        {learningPoint && Object.keys(learningPoint).length === 0 && (
          <div style={{ height: '100px', margin: 'auto'}}>Không có slide tương ứng ở phiên bản gần nhất</div>
        )}
        {/* <div className="comment">
          <div className="comment-label">Bình luận:</div>
          <div className="comment-container">
            <div className="comment-element">
              <div className="comment-user">
                <span>{'Hoàng Đỗ'}</span>
              </div>
              <div className="comment-date">10-10-2022</div>
              <div className="comment-content">{'Bài này chưa  bạn cần phải sửa lại rất nhiều đó nhé'}</div>
            </div>
          </div>
          <div className="comment-action">
            <input type="text" className="comment-input" />
            <ButtonAction className="btn-comment" size="small" text="Bình luận" />
          </div>
        </div> */}
      </div>
    </div>
  );
};
