import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lessonTemplates } from '../UITemplate/data';
import { checkBeforeSaving, saveUpdateLesson } from '../../../actions/ActionLesson';
// import ModalPreviewApp from '../../previewApp/index';
import './style.scss';
import { saveQuizzesBank } from '../../../actions/ActionQuestionBank';
import { saveQuizzesTest } from '../../../actions/ActionTest';
import { Welcome } from './welcome';
import { Button } from '@mui/material';
import { ButtonAction } from '../../Common/Button/ButtonAction';
import { SECTION_STATUS } from '../../../constants';
import {TourContainer} from "../../Common/Tour/TourContainer";
import {getStorageItem, setStorageItem} from "../../../utils/localStorage";

const _LessonRight = ({
  isShowAction = true,
  versionPreview,
  preview,
  previewLP,
  inTestBank,
  viewQuiz,
  checkWelcome,
  isPublish = false
}) => {
  const theorySteps = [
    {
      selector: '#tour-lesson-layout',
      content: 'Sử dụng để thay đổi bố cục với slide lý thuyết hoặc thay đổi loại bài tập với slide bài tập.',
      position: 'left',
      title: "Lựa chọn kiểu slide"
    },
    {
      selector: '#tour-lesson-theory-2',
      content: 'Nhập tiêu đề cho slide',
      position: 'bottom',
      title: "Tiêu đề slide"
    },
    {
      selector: '#tour-lesson-theory-3',
      content: 'Bạn có thể chuyển tên tiêu đề sang âm thanh ở đây.',
      position: 'bottom',
      title: "Chuyển đổi sang âm thanh"
    },
    {
      selector: '#tour-lesson-theory-4',
      content: 'Bạn có thể click vào đây để mở ra khung tạo nội dung.',
      position: 'bottom',
      title: "Tạo nội dung"
    },
    {
      selector: '#tour-lesson-save',
      content: 'Hãy nhớ lưu lại nội dung trước khi rời đi',
      position: 'bottom',
      title: "Lưu trữ"
    },
  ];
  const quizSteps = [
    {
      selector: '#tour-lesson-layout',
      content: 'Sử dụng để thay đổi bố cục với slide lý thuyết hoặc thay đổi loại bài tập với slide bài tập.',
      position: 'left',
      title: "Lựa chọn kiểu slide"
    },
    {
      selector: '#tour-lesson-quiz-2',
      content: 'Nhập tiêu đề cho slide',
      position: 'bottom',
      title: "Tiêu đề slide"
    },
    {
      selector: '#tour-lesson-quiz-3',
      content: 'Tạo hình nền cho slide bài tập và số điểm nếu hoàn thành.',
      position: 'bottom',
      title: "Hình nền và điểm số"
    },
    {
      selector: '#tour-lesson-quiz-4',
      content: 'Tuỳ vào loại bài tập phần này sẽ có các cách tạo nội dung khác nhau.',
      position: 'bottom',
      title: "Tạo nội dung"
    },
    {
      selector: '#tour-lesson-save',
      content: 'Hãy nhớ lưu lại nội dung trước khi rời đi',
      position: 'bottom',
      title: "Lưu trữ"
    },
  ];
  const [steps, setSteps] = useState([]);
  const [isTourTabAction, setTourTabAction] = useState(false);
  const closeTour = () => {
    setTourTabAction(false);
    setStorageItem({
      key: "tour",
      value: JSON.stringify({
        leftAction: false,
        lessonRight: {
          isTour: false,
          type: ""
        }
      }),
    });
  };

  const dispatch = useDispatch();
  const { localState, currentSlide, syncing, welcome } = useSelector((state) => state.lesson);
  const idCurrent = currentSlide
    ? currentSlide
    : localState.learningPoints.length > 0
    ? localState.learningPoints[0].id
    : welcome.id;

  const currentLP =
    idCurrent === welcome.id
      ? welcome
      : localState.learningPoints.find((learning) => learning.id === idCurrent) || localState.learningPoints[0];

  const onClickSave = () => {
    if (inTestBank) {
      return dispatch(saveQuizzesBank());
    }
    if (viewQuiz) {
      return dispatch(saveQuizzesTest());
    }
    if (localState.version && checkCanSave(localState.version.status)) {
      if (checkBeforeSaving(localState.learningPoints)) return dispatch(saveUpdateLesson());
    }
  };

  const checkCanSave = (status) => {
    if (isPublish && status !== SECTION_STATUS.IN_REVIEW) {
      return false;
    }
    if (!isPublish && status === SECTION_STATUS.IN_REVIEW) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let tourData = getStorageItem("tour");
    tourData = tourData ? JSON.parse(tourData) : null;
    if (tourData && tourData.lessonRight && tourData.lessonRight.isTour && tourData.lessonRight.type) {
      setTourTabAction(true);
      setSteps(tourData.lessonRight.type === "theory" ? theorySteps : quizSteps);
    }

    return () => {
      let tourData = getStorageItem("tour");
      tourData = tourData ? JSON.parse(tourData) : null;
      if (tourData) {
        setStorageItem({
          key: "tour",
          value: JSON.stringify({
            leftAction: false,
            lessonRight: {
              isTour: tourData.lessonRight.isTour,
              type: ""
            }
          })
        });
      }
    }
  }, []);

  return (
      <>
        <div className={`lesson__right${preview ? ' preview_mode' : ''}`}>
          <div
              className={`content grid${preview ? ' no_border' : ''}`}
              style={{ overflowY: `${preview ? 'hidden' : 'auto'}` }}
          >
            {previewLP && <div className="guardclick" />}

            {previewLP &&
                lessonTemplates
                    .find(({ name }) => name === previewLP.type.split(':')[0])
                    .layouts.find(({ name }) => name === previewLP.type)
                    .layoutPreview(previewLP)}
            {!previewLP &&
                (idCurrent === welcome.id ? (
                    checkWelcome !== 'false' ? (
                        <Welcome />
                    ) : (
                        <div className="grid-item-center">Hãy bắt đầu nào. Ấn vào biểu tượng cộng để thêm slide mới bạn nhé!</div>
                    )
                ) : (
                    lessonTemplates
                        .find(({ name }) => name === currentLP.type.split(':')[0])
                        .layouts.find(({ name }) => name === currentLP.type)
                        ?.layout(versionPreview)
                ))}
            {/* {viewQuiz ? null : !preview && <ModalPreviewApp />} */}
          </div>
          {isShowAction && idCurrent !== welcome.id && (
              <ButtonAction
                  id="tour-lesson-save"
                  text={
                    syncing
                        ? 'đang xử lý...'
                        : (() => {
                          if (inTestBank) {
                            return 'Lưu câu hỏi';
                          }
                          if (viewQuiz) {
                            return 'Lưu';
                          }
                          return 'Lưu bài học';
                        })()
                  }
                  onClick={onClickSave}
                  className={`btn-save-lesson ${
                      inTestBank || 
                      viewQuiz || 
                      (localState.version && checkCanSave(localState.version.status))
                        ? ''
                        : 'btn-disable'
                    }`
                  }
              />
          )}
        </div>
        {isTourTabAction && (
            <TourContainer steps={steps} isOpen={isTourTabAction} onCloseTour={closeTour} />
        )}
      </>
  );
};

const LessonRight = React.memo(_LessonRight);
export default LessonRight;
