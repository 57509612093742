import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// constants
import { LUCKY_BOX_TYPES } from '../../../../constants/award-admin/luckyBoxType';

// reducers
import { generateMoreLuckyBox } from '../../../../reducers/award-admin/luckyBox';

// local
import { validationFormSchema as generateMoreLuckyBoxValidationFormSchema } from './GenerateMoreLuckyBoxForm';

const useGenerateMoreLuckyBoxModalCtrl = ({ id, toggle } = {}) => {
  // -- lucky box --
  const { type, rarity, name, itemSpawnRateByRarity } = useSelector((state) => state.luckyBoxes.data[id]) || {};

  // -- form state --
  const generateMoreLuckyBoxForm = useForm({
    resolver: yupResolver(generateMoreLuckyBoxValidationFormSchema),
    defaultValues: {
      id,
      name,
      type,
      rarity,
      itemSpawnRateByRarity,
      morePublishQuantity: 0,
      collectionCategoryIds: [],
      collectionItemIds: [],
    },
  });
  const { handleSubmit } = generateMoreLuckyBoxForm;

  // -- dispatch --
  const dispatch = useDispatch();

  // -- on submit --
  const onSubmit = handleSubmit(({ morePublishQuantity, collectionCategoryIds, collectionItemIds }) => {
    dispatch(
      generateMoreLuckyBox({
        _id: id,
        morePublishQuantity,
        collectionCategoryIds,
        collectionItemIds,
      }),
    );
    toggle();
  });

  return {
    // form state
    generateMoreLuckyBoxForm,
    // on submit
    onSubmit,
  };
};

export default createContainer(useGenerateMoreLuckyBoxModalCtrl);
