import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addQuizPart, changeQuizPart, removeQuizPart } from '../../../actions/ActionQuiz';
import { IconAdd, IconDelete} from '../../../icons/IconSvg';
import TitleVoiceBox from '../../Theory/TitleVoiceBox';
import { InputSpeakingQuiz } from '../common/InputSpeakingQuiz';
import InputQuestion from '../common/InputQuestion';
import './style.scss';
import { getFullUrl } from '../../../utils/imageProcessing';

export const SpeakingQuiz = ({currentSlidePreview}) => {
    
    const dispatch = useDispatch();
    const {currentSlide, localState} = useSelector(state => state.lesson);
    let currSpeakingQuiz = currentSlidePreview ? currentSlidePreview : localState.learningPoints.find(item => item.id === currentSlide);
    const {quizParts} = currSpeakingQuiz;

    const handleClickAdd = () => {
        dispatch(addQuizPart('quiz', currentSlide, 'speaking_quiz'));
    };
    const handleClickDelete = (e, index) => {
        dispatch(removeQuizPart(currSpeakingQuiz, index));
    };
    const handleVoice = (data) => {
        const {titleAudioUrl, titleVoice, rateVoice, indexQuiz} = data;
        if(titleVoice) {
            dispatch(changeQuizPart('content', {...quizParts[indexQuiz].content, 'voice': titleVoice}, currSpeakingQuiz, indexQuiz));
        }
        if(titleAudioUrl) {
            dispatch(changeQuizPart('content', {...quizParts[indexQuiz].content, 'audioUrl': titleAudioUrl}, currSpeakingQuiz, indexQuiz));
        }
        if(rateVoice) {
            dispatch(changeQuizPart('content', {...quizParts[indexQuiz].content, 'rateVoice': Number(rateVoice)}, currSpeakingQuiz, indexQuiz));
        }
    };

    const handleInputSpeaking = (data, index) => {
        dispatch(changeQuizPart('content', {...quizParts[index].content, 'text': data}, currSpeakingQuiz, index));
    }
    const handleInputCorrectPercent = (e, index) => {
        const value = Number(e.target.value);
        if (value >= 0 && value <= 100) dispatch(changeQuizPart('minCorrectPercent', value, currSpeakingQuiz, index));
    }

    return (
        <div className="speaking_quiz">
            <span>Tạo câu hỏi</span>
            <div>
                <InputQuestion 
                    learningCurrent={currSpeakingQuiz} 
                    audioUrl={currSpeakingQuiz.questionAudioUrl || ''}
                />
            </div>
            { quizParts && 
                quizParts.map((element, index) => {

                    return (
                        <div className="speaking_quiz_element" key={index+1}>
                            <div className="input_title fl">
                                <span><b>Câu {index+1}</b></span>
                                <TitleVoiceBox 
                                    lpID = {currentSlide}
                                    title = {element.content.text}
                                    titleVoice = {element.content.voice} 
                                    rateVoice = {element.content.rateVoice}
                                    titleAudioUrl = {getFullUrl(element.content.audioUrl)}
                                    handleChangeLP = {handleVoice}
                                    indexQuiz={index}
                                />
                            </div>
                            <div className="content_quiz fl">
                                <InputSpeakingQuiz
                                    quizPart = {element}
                                    audioUrl = {element.content.audioUrl}
                                    index = {index}
                                    changeQuizPart = {handleInputSpeaking}
                                />
                                <div onClick={(e) => handleClickDelete(e, index)} style={{cursor: 'pointer'}}>
                                    <IconDelete />
                                </div>
                            </div>
                            <div className="correct_percent">
                                <span>Mức độ yêu cầu<input type="number" title="% để qua câu hỏi" value={element.minCorrectPercent} onChange={(e) => handleInputCorrectPercent(e, index)} /> %</span>
                            </div>
                        </div>
                    );
                })
            }
            <div className="div__btn_add" style={{marginTop: '10px'}} >
                <button className="btn btn_add" type="button" onClick={handleClickAdd}>
                    <div>
                        <IconAdd />
                    </div>
                    <div className="fs-20 text">Thêm nội dung bài học</div>
                </button>
            </div>
            {/* <div className="add_content_quiz fl" >
                <button onClick={handleClickAdd}>
                    <IconAdd /><span>Thêm nội dung bài học</span>
                </button>
            </div> */}
        </div>
    );
};