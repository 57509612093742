import React from 'react';
import { Filter } from './Filter';
import { Search } from './Search';
import './style.scss';

export const RequestFilter = ({ filter, search }) => {
    return (
        <div className="request-filter"> 
            <Search filter={filter}/>
            <Filter filter={filter} search={search}/>
        </div>
    );
}