import React from 'react';

const Header = ({ headers }) => (
  <thead>
    <tr>
      {headers.map((header, idx) => (
        <th key={idx} className="text-center !t-align-middle" style={header.style}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

export default Header;
