import React from 'react';
import { connect } from 'react-redux';
import RadioButton from '../../Common/RadioButton';
import { IconAdd, IconDelete } from '../../../icons/IconSvg';
import './style.scss';
import Result from './Result';
import {
  changeQuiz,
  addQuizPart,
  clearCheckQuizPart,
  removeQuizPart,
  clearMediaMCQuiz,
} from '../../../actions/ActionQuiz';
import InputQuestion from '../common/InputQuestion';
import ToggleSlider from '../../Common/toggleSlider/toggleSlider';
import OptionMediaQuestion from './OptionMediaQuestion';
const options = [
  {
    id: 1,
    name: 'Một lựa chọn đúng',
    key: false,
  },
  {
    id: 2,
    name: 'Nhiều lựa chọn đúng',
    key: true,
  },
];
class MultiChoice extends React.Component {
  state = {
    optionMultiChoice: false,
    optionMediaQuestion: false,
  };
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  componentDidMount() {
    const { learningPoints, data } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    const quizPartsChecked =
      learningCurrent && learningCurrent.quizParts ? learningCurrent.quizParts.filter((part) => part.correctness) : [];
    if (quizPartsChecked.length > 1) {
      this.setState({ optionMultiChoice: true });
    }
    if (learningCurrent.questionImageUrl || learningCurrent.questionVideoUrl) {
      this.setState({ optionMediaQuestion: true });
    }
  }
  componentDidUpdate(prevProps) {
    const { currentSlide } = this.props;
    if (prevProps.currentSlide !== currentSlide) {
      const learningCurrent = this.props.learningPoints.find((learning) => learning.id === currentSlide) || {};
      if (learningCurrent.questionImageUrl || learningCurrent.questionVideoUrl) {
        this.setState({ optionMediaQuestion: true });
      }
    }
  }
  changeOptionMediaQuestion = (learningCurrent) => {
    this.setState({ optionMediaQuestion: !this.state.optionMediaQuestion });
    this.props.clearMediaMCQuiz(learningCurrent);
  };
  render() {
    const { learningPoints, data, currentSlidePreview } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    const quizParts = learningCurrent.quizParts || [];

    return (
      <div className="ui__multi_choice">
        <div>
          <div>Tạo câu hỏi</div>
          <div className="wrapper_quiz_textarea">
            <InputQuestion learningCurrent={learningCurrent} audioUrl={learningCurrent.questionAudioUrl || ''} />
          </div>
        </div>
        <div>
          <div style={{ marginTop: '10px' }} className="fl">
            <div style={{ marginRight: '10px' }}>Hình ảnh , video mô tả câu hỏi</div>
            <div>
              <ToggleSlider
                value={this.state.optionMediaQuestion}
                onChange={(event) => {
                  this.setState({ optionMediaQuestion: !this.state.optionMediaQuestion });
                  this.changeOptionMediaQuestion(learningCurrent);
                }}
              />
            </div>
          </div>
          {this.state.optionMediaQuestion ? (
            <OptionMediaQuestion currentSlide={currentSlide} learningCurrent={learningCurrent} />
          ) : null}
        </div>
        <div className="wraper__select">
          <div className="fl">
            <div style={{ marginRight: '10px' }}>Một lựa chọn đúng</div>
            <div>
              <ToggleSlider
                value={!this.state.optionMultiChoice}
                onChange={(event) => {
                  this.setState({ optionMultiChoice: !this.state.optionMultiChoice });
                  this.props.clearCheckQuizPart(learningCurrent);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          {quizParts.map((data, index) => (
            <div key={index} className="fl m-20 wrap__result">
              <Result
                index={index}
                data={data}
                optionMultiChoice={this.state.optionMultiChoice}
                currentSlide={currentSlide}
                learningCurrent={learningCurrent}
              />
              {quizParts.length > 1 ? (
                <div
                  onClick={() => this.props.removeQuizPart(learningCurrent, index)}
                  style={{ marginLeft: '20px' }}
                  className="cursor"
                >
                  <IconDelete />
                </div>
              ) : null}
            </div>
          ))}
          <div className="div__btn_add">
            <button className="btn btn_add" type="button" onClick={() => this.props.addQuizPart('quiz', currentSlide, 'mc_quiz')}>
              <div>
                <IconAdd />
              </div>
              <div className="fs-20 text">Thêm câu trả lời</div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {
  changeQuiz,
  addQuizPart,
  clearCheckQuizPart,
  removeQuizPart,
  clearMediaMCQuiz,
})(MultiChoice);
