import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next'

// reducers
import { getAllOrders } from '../../../../reducers/award-admin/order';

const useOrderDataTableCtrl = () => {
  // -- orders --
  const { isLoading, metadata = {}, ids = [] } = useSelector((state) => state.orders);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- table filters --
  const [tableFilters, setTableFilters] = useState({ page: 1, limit: 10 })

  // -- handle change per page --
  const handleChangePerPage = useCallback(
    (perPage) =>
      setTableFilters((prevState) => ({ ...prevState, page: 1, limit: perPage })),
    []
  )

  // -- handle change page --
  const handleChangePage = useCallback(
    ({ selected }) =>
      setTableFilters((prevState) => ({ ...prevState, page: selected + 1 })),
    []
  )

  // -- handle change filters --
  // const handleChangeFilters = useCallback(
  //   ({ keyword, type }) =>
  //     setTableFilters((prevState) => ({
  //       ...prevState,
  //       keyword,
  //       type: type !== '' ? type : undefined,
  //     })),
  //   []
  // )
  const handleChangeFilters = useCallback(
    ({ keyword }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        keyword,
      })),
    []
  )

  // -- handle change sort state --
  const handleChangeSortState = useCallback(
    ({ sort }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        sort,
      })),
    [],
  );

  // -- is modal open --
  const [isModalOpen, setIsModalOpen] = useState(false)

  // -- toggle modal --
  const toggleModal = useCallback(() => setIsModalOpen((prevState) => !prevState), [setIsModalOpen])

  // -- modal item id --
  const [modalItemId, setModalItemId] = useState()

  // watching tableFilters change then refetch data
  useEffect(() => {
    dispatch(getAllOrders(tableFilters));
  }, [tableFilters]);

  return {
    // orders
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
    // handle change filters
    handleChangeFilters,
    // handle change sort state
    handleChangeSortState,
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
  }
}

export default createContainer(useOrderDataTableCtrl)
