import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// constants
import { LUCKY_BOX_TYPES } from '../../../../constants/award-admin/luckyBoxType';

// reducers
import { upsertLuckyBox } from '../../../../reducers/award-admin/luckyBox';

// local
import { validationFormSchema as luckyBoxValidationFormSchema } from './LuckyBoxForm/LuckyBoxForm';

const useLuckyBoxModalCtrl = ({ id, toggle } = {}) => {
  // -- lucky box --
  const {
    type = LUCKY_BOX_TYPES.CUSTOM,
    rarity,
    imageUrl,
    status,
    name,
    nameVoiceUrl,
    availableQuantity,
    publishQuantity,
    price,
    expiredAt,
    itemSpawnRateByRarity = { normal: 100, rare: 100, extremelyRare: 100 },
  } = useSelector((state) => state.luckyBoxes.data[id]) || {};

  // -- form state --
  const luckyBoxForm = useForm({
    resolver: yupResolver(luckyBoxValidationFormSchema),
    defaultValues: {
      id,
      previewImageUrl: imageUrl,
      image: null,
      type,
      rarity,
      status,
      name,
      nameVoiceUrl,
      availableQuantity,
      publishQuantity,
      morePublishQuantity: 0,
      price,
      expiredAt,
      itemSpawnRateByRarity,
      collectionCategoryIds: [],
      collectionItemIds: [],
    },
  });
  const { handleSubmit } = luckyBoxForm;

  // -- dispatch --
  const dispatch = useDispatch();

  // -- on submit --
  const onSubmit = handleSubmit(
    async ({
      name,
      nameVoiceUrl,
      type = LUCKY_BOX_TYPES.CUSTOM,
      status,
      publishQuantity,
      price,
      expiredAt,
      itemSpawnRateByRarity = { normal: 100, rare: 100, extremelyRare: 100 },
      collectionCategoryIds,
      collectionItemIds,
      image,
    }) => {
      if (
        await dispatch(
          upsertLuckyBox({
            _id: id,
            name,
            nameVoiceUrl,
            type,
            status,
            publishQuantity,
            price,
            expiredAt,
            itemSpawnRateByRarity,
            collectionCategoryIds,
            collectionItemIds,
            image: image && image[0],
            imageUrl,
          }),
        )
      )
        toggle();
    },
  );

  return {
    // form state
    luckyBoxForm,
    // on submit
    onSubmit,
  };
};

export default createContainer(useLuckyBoxModalCtrl);
