import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../../../components/Common/Header';
import { CommentIcon, CompareIcon, IconCaretLeft, CourseIcon } from '../../../../icons/IconSvg';
import { fetchPathById, fetchVersionPath } from '../../../../reducers/paths';
import Loading from '../../../../components/Common/Loading';
import Comment from '../Comment';
import { GiMountainRoad } from 'react-icons/gi';
import { getPathStatus, getFrameContentStatus } from '../../../../utils/publishManagement';
import PublishPopup from '../PublishPopup';
import './style.scss';

const PublishPathDetails = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const paths = useSelector((state) => state.paths);
  const [path, setPath] = useState();
  const [versionPath, setVersionPath] = useState();
  const [isOpenPublishModal, setOpenPublishModal] = useState(false)
  const [activeTab, setActiveTab] = useState('comment');
  const location = useLocation();
  const history = useHistory();
  const pathId = Number(location.pathname.slice(14, location.pathname.length));
  const [selected, setSelected] = useState({
    name: '',
    type: '',
    id: '',
  });
  const [currentComment, setCurrentComment] = useState([]);
  const addComment = (comment) => {
    setCurrentComment([...currentComment, comment]);
  };

  useEffect(() => {
    if (!paths.data.find((element) => element.id === pathId)) {
      dispatch(fetchVersionPath(pathId));
      dispatch(fetchPathById(pathId));
    }
  }, []);

  useEffect(() => {
    if (!paths.syncing) {
      setVersionPath(paths.versionPath);
      setPath(paths.data.find((element) => element.id === pathId));
      setSelected({
        name: '',
        type: 'path',
        id: pathId,
      });
    }
  }, [paths.syncing]);

  useEffect(() => {
    if (selected.type === 'path' && !selected.name) {
      setSelected({
        name: path.title,
        type: 'path',
        id: pathId,
      });
      setCurrentComment(path?.comments);
    }
  }, [path]);

  useEffect(() => {
    const { type, id } = selected;
    let tempComments = [];
    if (type === 'course') {
      tempComments = path?.coursesInfo.find((element) => element.courseId.id === id).courseId.comments;
    } else {
      tempComments = path?.comments;
    }
    setCurrentComment(tempComments);
  }, [selected]);

  const handleClickActionRight = (value) => {
    if (activeTab === value) return;
    setActiveTab(value);
  };

  const handlePublishPath = () => {
    setOpenPublishModal(!isOpenPublishModal)
  };

  const onSelected = (e, { type, id, name }) => {
    setSelected({
      type,
      id,
      name,
    });
  };
  
  if (!path || !versionPath) return <Loading />;

  return (
    <>
      <Header />
      <div className="path-action">
        <div className="path-action__left">
          <div className="path-action__back" onClick={() => history.goBack()}>
            <IconCaretLeft />
          </div>
          <div className="path-action__viewPath" onClick={() => history.push(`/path/${pathId}`)}>
            {path ? path.title : ''}
          </div>
          <div className="path-action__btn" onClick={handlePublishPath}>
            {isAdmin ? 'Kiểm duyệt' : 'Yêu cầu xuất bản'}
          </div>
        </div>
        <div className="path-action__right">
          <div
            className={`compare-action ${activeTab === 'compare' ? 'active' : ''}`}
            onClick={(e) => handleClickActionRight('compare')}
          >
            <CompareIcon />
            So sánh
          </div>
          <div
            className={`comment-action ${activeTab === 'comment' ? 'active' : ''}`}
            onClick={(e) => handleClickActionRight('comment')}
          >
            <CommentIcon />
            Bình luận
          </div>
        </div>
      </div>
      <div className="path-content">
        <div className="path-content__left">
          <h4>Khung lộ trình học</h4>
          <div
            style={selected.id === path.id ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : {}}
            className="path-row"
            onClick={() => {
              setSelected({
                name: path.title,
                type: 'path',
                id: path.id,
              });
            }}
          >
            <div className="path-title">
              <GiMountainRoad size={18} />
              <p>{path.title}</p>
            </div>
            <div className="path-status">
              <p>{getPathStatus(path.status)}</p>
            </div>
          </div>
          <div className="course-list">
            {path.coursesInfo.map((element) => {
              return (
                <div
                  className="course-row"
                  style={selected.id === element.courseId.id ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : {}}
                  onClick={() => {
                    setSelected({
                      name: element.courseId.title,
                      type: 'course',
                      id: element.courseId.id,
                    });
                  }}
                >
                  <div className="course-item">
                    <CourseIcon />
                    <p>{element.courseId.title}</p>
                  </div>
                  <div className="course-status">
                    <p>{getFrameContentStatus(element.courseId.status)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="path-content__right">
          {activeTab === 'compare' ? (
            <div className="compare-wrapper">
              {versionPath.length >= 2 ? (
                <>
                  <h1>Phiên bản chỉnh sửa gần nhất</h1>
                  <h4>Khung lộ trình học</h4>
                  <div className="path-row">
                    <div className="path-title">
                      <GiMountainRoad size={18} />
                      <p>{versionPath[versionPath.length - 2].title}</p>
                    </div>
                    <div className="path-status">
                      <p>{getPathStatus(versionPath[versionPath.length - 2].status)}</p>
                    </div>
                  </div>
                  <div className="course-list">
                    {versionPath[versionPath.length - 2].coursesInfo.map((element) => {
                      return (
                        <div
                          className="course-row"
                        >
                          <div className="course-item">
                            <CourseIcon />
                            <p>{element.title}</p>
                          </div>
                          <div className="course-status">
                            <p>{getFrameContentStatus(element.status)}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <h1>Phiên bản chỉnh sửa gần nhất</h1>
                  <h4>Lộ trình học hiện chưa có phiên bản gần nhất nào.</h4>
                </>
              )}
            </div>
          ) : (
            <Comment comments={currentComment} selected={selected} addComment={addComment} />
          )}
        </div>
      </div>
      {isOpenPublishModal && (
          <PublishPopup
            isOpen={isOpenPublishModal}
            toggle={() => setOpenPublishModal(!isOpenPublishModal)}
            path={path}
            setPath={setPath}
            courses={path.coursesInfo}
            defaultCollapseIcon={''}
            defaultExpandIcon={''}
            onSelected={onSelected}
            onClose={() => setOpenPublishModal(false)}
          />
        )}
    </>
  );
};

export default PublishPathDetails;
