import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Common/Loading';
import Header from '../../../components/Common/Header';
import Photo from '../../../components/Common/Photo';
import PopoverCustom from '../../../components/Popover/PopoverCustom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconAdd, IconDelete, IconMove } from '../../../icons/IconSvg';
import EmptyState from '../../../assets/images/empty_state.png'
import ModalUpdateInfo from './ModalUpdateInfo/modalUpdateInfo';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { GiHighlighter } from 'react-icons/gi';
import { IoSettingsSharp } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import { createCourseAction } from '../../../reducers/courses';
import { BsTrash } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import './style.scss';
import { fetchPathById, deletePathAction, updatePathAction, moveCourse, submitPathAction } from '../../../reducers/paths';
import ModalChooseCourse from './ModalChooseCourse';

const PathDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [path, setPath] = useState();
  const paths = useSelector((state) => state.paths);
  const [openAddCourse, setOpenAddCourse] = useState(false);
  const [openChooseCourse, setOpenChooseCourse] = useState(false);
  const [openDeletePath, setOpenDeletePath] = useState(false);
  const [openUpdatePath, setOpenUpdatePath] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false);
  const location = useLocation();
  const listAction = [
    {
      id: 'create',
      name: 'Tạo mới khóa học',
    },
    {
      id: 'choose',
      name: 'Chọn khóa học đã tạo',
    },
  ];
  const pathId = Number(location.pathname.slice(6, location.pathname.length));

  useEffect(() => {
    if (!paths.data.find((element) => element.id === pathId)) {
      dispatch(fetchPathById(pathId));
    }
  }, []);

  useEffect(() => {
    if (!paths.syncing) setPath(paths.data.find((element) => element.id === pathId));
  }, [paths.syncing]);

  const deleteCourseFromPath = (courseId) => {
    const updateData = {
      coursesInfo: path.coursesInfo.filter((element) => element.courseId.id !== courseId).map(element => {
        return {
          courseId: element.courseId.id
        }
      }),
    };
    dispatch(updatePathAction(pathId, updateData));
  };

  const onDragEnd = ({ draggableId, source, destination }) => {
    if(destination) {
      const result = [...path.coursesInfo];
      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);
      const updateData = {
        coursesInfo: result.map(element => {
          return {
            courseId: element.courseId.id
          }
        }),
      };
      dispatch(moveCourse(draggableId, source.index, destination.index, pathId));
      dispatch(updatePathAction(pathId, updateData));
    }
  };

  const handleSubmitPath = () => {
    if(path.status !== 'PUBLISH') {
      dispatch(submitPathAction(path))
    }
  }

  const okDelete = () => {
    dispatch(
      deletePathAction(path, () => {
        history.push('/path');
      }),
    );
  };

  if (!path) return <Loading />;

  return (
    <>
      <Header type="detail" entity='path' name={path.title} status={path.status} />
      <div className="path-details-wrapper">
        <div className="left-side">
          <div className="left-side__info">
            <h2>Thông tin lộ trình học</h2>
            <div className="path-details-title">
              <GiHighlighter color="white" />
              <p>Tiêu đề:</p>
            </div>
            <div className="path-details-content">
              <p>{path.title}</p>
            </div>
            <div className="path-details-title">
              <GiHighlighter color="white" />
              <p>Mô tả:</p>
            </div>
            <div className="path-details-content">
              <p>{path.description}</p>
            </div>
            <div className="path-details-title">
              <GiHighlighter color="white" />
              <p>Ảnh bìa:</p>
            </div>
            <div className="path-details-content">
              <Photo alt={path.title} src={path.imageUrl} />
            </div>
            <div className="path-details-title">
              <GiHighlighter color="white" />
              <p>Phạm vi:</p>
            </div>
            <div className="path-details-content">
              <p>{path.scope.scopeType === 'PUBLIC' ? 'Công khai' : 'Giới hạn'}</p>
            </div>
          </div>
          <div className='left-side__button'>
            <button onClick={() => setOpenUpdatePath(true)} className='button_activated'>
              <IoSettingsSharp/>
              <p>Sửa thông tin lộ trình học</p>
            </button>
            <button onClick={() => handleSubmitPath()} className={path.status === 'PUBLISH' ? 'button_disabled' : 'button_activated'}>
              <IoSettingsSharp/>
              <p>Nộp lộ trình học</p>
            </button>
          </div>
        </div>
        <div className="right-side">
          <div className="right-side__header">
            <h2>Danh sách khóa học</h2>
            <div className="icon-wrapper">
              <div
                className="icon-delete"
                data-tip="Xóa lộ trình học"
                data-for="delete-path"
                onClick={() => setOpenDeletePath(true)}
              >
                <BsTrash color="white" size={18} strokeWidth={0.5} />
              </div>
              <ReactTooltip id="delete-path" effect="solid" />
              <div className="icon-add" id="add-course" data-tip="Thêm khóa học" data-for="add-course">
                <IconAdd />
              </div>
              <PopoverCustom
                width="250px"
                id="add-course"
                toggle={() => setPopoverOpen(!popoverOpen)}
                popoverOpen={popoverOpen}
              >
                {listAction.map((element, index) => {
                  if (element.id === 'create') {
                    return (
                      <div
                        key={index}
                        className="div_item"
                        onClick={() => {
                          setOpenAddCourse(true);
                          setPopoverOpen(false);
                        }}
                      >
                        {element.name}
                      </div>
                    );
                  }
                  return (
                    <div
                      key={element.id}
                      className="div_item"
                      onClick={() => {
                        setOpenChooseCourse(true);
                        setPopoverOpen(false);
                      }}
                    >
                      {element.name}
                    </div>
                  );
                })}
              </PopoverCustom>
              <ReactTooltip id="add-course" effect="solid" />
            </div>
          </div>
          <div className="right-side__body">
            {path.coursesInfo.length === 0 ? (
              <div className='empty-wrapper'>
                <img src={EmptyState}></img>
                <p>Lộ trình học hiện chưa có khóa học nào!</p>
              </div>
            ) : (<DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`list-courses-${pathId}`}>
                {(provided, _) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="div__scroll">
                    {path.coursesInfo &&
                      path.coursesInfo.map((course, index) => {
                        return (
                          <Draggable key={course.id} draggableId={course.id} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} className="item_course">
                                <div className="fl_b">
                                  <div className="fl content">
                                    <div {...provided.dragHandleProps} className="icon-move">
                                      <IconMove />
                                    </div>
                                    <div className="img_course br-50">
                                      <Photo style={{ cursor: 'pointer' }} className="img_course" src={course.courseId?.imageUrl ? course.courseId.imageUrl : ''} onClick={() => {
                                        window.open(`${window.location.origin}/course/${course.courseId.id}`, '_blank')
                                      }}/>
                                    </div>
                                    <div className="info_course">
                                      <a
                                        className="text-bold fs-20"
                                        aria-hidden="true"
                                        style={{ cursor: 'pointer' }}
                                        href={`${window.location.origin}/course/${course.courseId.id}`}
                                        target='_blank'
                                      >
                                        {course.courseId.title || ''}
                                      </a>
                                      <div className="text-note fs-15">
                                        Chỉnh sửa cuối cùng: {moment(course.courseId.updatedAt || 0).format('DD/MM/YYYY')}
                                      </div>
                                    </div>
                                  </div>
                                  <div onClick={() => deleteCourseFromPath(course.courseId.id)} className="icon-right">
                                    <IconDelete />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>)}
          </div>
        </div>
      </div>
      <NewCourseForm open={openAddCourse} toggle={() => setOpenAddCourse(!openAddCourse)} path={path} />
      <ModalDelete
        isOpen={openDeletePath}
        toggle={() => setOpenDeletePath(!openDeletePath)}
        title={`Xóa lộ trình học`}
        titleT={path.title}
        okDelete={okDelete}
      />
      {openChooseCourse && (
        <ModalChooseCourse
          isOpen={openChooseCourse}
          toggle={() => setOpenChooseCourse(!openChooseCourse)}
          pathData={path}
        />
      )}
      {openUpdatePath && (
        <ModalUpdateInfo
          path={path}
          open={openUpdatePath}
          toggle={() => setOpenUpdatePath(!openUpdatePath)}
        />
      )}
    </>
  );
};

const NewCourseForm = ({ open, toggle, path }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const submitHandler = async (data) => {
    const { title, description, thumbnail } = data;
    dispatch(createCourseAction({ title, description, file: thumbnail[0], type })).then(res => {
      const newCourse = res.result.course
      const courseInfo = {
        courseId: newCourse.id,
      };
      const newCoursesInfo = path.coursesInfo
      newCoursesInfo.push(courseInfo)
      dispatch(updatePathAction(path.id, { coursesInfo: newCoursesInfo }))
    })
    toggle();
  };
  const [type, setType] = useState('PUBLIC');

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader className='path-add-course'>Tạo khóa học mới</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <FormGroup>
            <Label htmlFor="Title">Tên khóa học</Label>
            <Input
              type="text"
              name="title"
              id="Title"
              placeholder="Tên khóa học này..."
              innerRef={register({ required: { value: true, message: 'Bạn cần điền tên của khóa học' } })}
            />
            {errors.title && <FormText color="danger">{errors.title.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Description">Miêu tả</Label>
            <Input
              type="textarea"
              name="description"
              id="Description"
              placeholder="Miêu tả về khóa học này..."
              innerRef={register({
                required: { value: true, message: 'Bạn cần điền một miêu tả ngắn về khóa học' },
              })}
            />
            {errors.description && <FormText color="danger">{errors.description.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label>Phạm vi khóa học</Label>
            <br />
            <Label style={{ marginLeft: '40px' }}>
              <Input
                type="radio"
                name="publicRadio"
                id="typeCourse public"
                checked={type === 'PUBLIC' ? true : false}
                onChange={(e) => setType('PUBLIC')}
              />{' '}
              Công khai
            </Label>
            <Label style={{ marginLeft: '80px' }}>
              <Input
                type="radio"
                name="protectRadio"
                id="typeCourse protect"
                checked={type === 'PROTECT' ? true : false}
                onChange={(e) => setType('PROTECT')}
              />{' '}
              Giới hạn
            </Label>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="File">Ảnh Thumbnail</Label>
            <Input
              type="file"
              name="thumbnail"
              id="File"
              innerRef={register({ required: { value: true, message: 'Bạn cần upload một ảnh thumbnail' } })}
            />
            <FormText color="muted">Thumbnail là ảnh đại diện thu nhỏ của khóa học</FormText>
            {errors.thumbnail && <FormText color="danger">{errors.thumbnail.message}</FormText>}
          </FormGroup>

          <Button>Tạo khóa học mới</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

const ModalDelete = ({ isOpen, toggle, title, okDelete, titleT }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader style={{fontSize: 20}}>{title || ''}</ModalHeader>
      <ModalBody>
        <div>Bạn có chắc chắn muốn xoá lộ trình học: {titleT}?</div>
      </ModalBody>
      <ModalFooter>
        <button onClick={okDelete} style={{ float: 'right' }} className="btn btn-danger">
          Xác nhận xóa
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PathDetails;
