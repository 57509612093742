import React from 'react';

import './toggleSlider.scss';

const ToggleSlider = ({ value, onChange, disabled }) => (
  <label className={'switch'}>
    <input type="checkbox" checked={value} onChange={onChange} disabled={disabled || false} />
    <span style={value ? {} : { cursor: 'default' }} className={`slider round`}></span>
  </label>
);

export default ToggleSlider;
