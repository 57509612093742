import React, { memo } from 'react';
import { Spinner, Table } from 'reactstrap';

// components
import Loading from '../../../../components/Common/Loading';
import Pagination from '../../../../components/Common/Pagination';

// local
import Filters from './Filters'
import Actions from './Actions'
import Header from './Header'
import RowData from './RowData'
import LuckyBoxDataTableCtrl from './LuckyBoxDataTable.ctrl';

const headers = [
  { id: 'status', label: 'Trạng thái', allowSorting: true },
  { id: 'imageUrl', label: 'Ảnh' },
  { id: 'name', label: 'Tên', allowSorting: true },
  { id: 'price', label: 'Số sao quy đổi', allowSorting: true },
  { id: 'availableQuantity', label: 'Số lượng', allowSorting: true },
  { id: 'expiredAt', label: 'Ngày hết hạn', allowSorting: true },
  { id: 'actions', label: '' },
];

const LuckyBoxDataTable = memo(({ className }) => {
  // -- ctrl --
  const {
    // lucky boxes
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
  } = LuckyBoxDataTableCtrl.useContainer()

  return (
    <div className={className}>
      <div className="t-flex t-justify-start t-items-center">
        {/* filters */}
        <div className="t-w-full">
          <Filters />
        </div>

        {/* actions */}
        <Actions className="ml-4 pt-3" />
      </div>

      {/* table */}
      <Table bordered responsive>
        {/* header */}
        <Header headers={headers} />

        {/* body */}
        <tbody>
          {/* loading */}
          {isLoading && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={7}><Spinner color="primary" /></td>
            </tr>
          )}

          {/* empty data */}
          {!isLoading && ids.length === 0 && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={7}>Không có dữ liệu</td>
            </tr>
          )}

          {/* data */}
          {!isLoading && ids.length > 0 && ids.map(id => (
            <RowData key={id} id={id} />
          ))}
        </tbody>
      </Table>

      {/* pagination */}
      <Pagination
        total={metadata.total}
        limit={tableFilters.limit}
        page={tableFilters.page}
        onPerPageChange={handleChangePerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
})

export default ({ ...props }) => (
  <LuckyBoxDataTableCtrl.Provider>
    <LuckyBoxDataTable {...props} />
  </LuckyBoxDataTableCtrl.Provider>
);