import { SECTION_STATUS } from '../constants';
import api from './api';

export const getAllCourse = async () => {
    const response = await api({
        method: 'GET',
        url: '/version/course'
    });
    return response?.result;
}

export const getFromQueryCourse = async (data) => {
    if (data.status && data.status.length === 0) {
        data.status = Object.values(SECTION_STATUS);
    }
    const response = await api({
        method: 'POST',
        url: '/version/course/filter',
        data
    });
    return response?.result;
}

export const searchCourse = async (title, data) => {
    const response = await api({
        method: 'POST',
        url: `/version/course/search?title=${title}`,
        data
    });
    return response?.result;
}

//

export const submitLesson = async ({ id, comment }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/submit/lesson/${id}`,
        data: { comment }
    });
    return response;
}

export const approveLesson = async ({ id, comment }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/approve/lesson/${id}`,
        data: { comment }
    });
    return response;
}

export const rejectLesson = async ({ id, comment }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/reject/lesson/${id}`,
        data: { comment }
    });
    return response;
}

export const suspendLesson = async ({ id, comment }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/suspend/lesson/${id}`,
        data: { comment }
    });
    return response;
}

export const requestToChangeLesson = async ({ id, comment }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/request-to-change/lesson/${id}`,
        data: { comment }
    });
    return response;
}

export const inReviewLesson = async ({ id }) => {
    const response = await api({
        method: 'POST',
        url: `/publish/in-review/lesson/${id}`,
    });
    return response;
}

//

export const getFrameContent = async ({ courseId }) => {
    const response = await api({
        method: 'GET',
        url: `frame-content/course/${courseId}`,
    });
    return response;
}

export const updateStatusFrameContent = async (courseId, data, token) => {
    const response = await api({
        method: 'PUT',
        url: `frame-content/status/${courseId}`,
        data: {
            updateData: data,
            accessToken: token
        }
    });
    return response;
}

export const updateTitleFrameContent = async (data) => {
    const response = await api({
        method: 'PUT',
        url: `frame-content/title/`,
        data
    });
    return response;
}

//

export const getAllLessonVersion = async (lessonId) => {
    const response = await api({
        method: 'GET',
        url: `/version/all/lesson/${lessonId}`,
    });
    return response;
}

export const getLessonVersion = async (lessonId) => {
    const response = await api({
        method: 'GET',
        url: `/version/lesson/${lessonId}`,
    });
    return response;
}

export const getCompareLp = async ({ lessonId, id, type }) => {
    const response = await api({
        method: 'GET',
        url: `/version/lesson/compare-lp?lessonId=${lessonId}&&id=${id}&&type=${type}`,
    });
    return response;
}

export const getVersionFrameContent = async (originId) => {
    const response = await api({
        method: 'GET',
        url: `/ver-frame-content/${originId}`,
    });
    return response;
}

export const submitFrameContent = async (courseId) => {
    const response = await api({
        method: 'POST',
        url: `/frame-content/submit/${courseId}`,
    });
    return response;
}