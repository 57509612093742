import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';
import 'moment/locale/vi';

import './styles/global.scss';
import './styles/bootstrap-customized.css';
import './styles/tailwind.generated.css';
import * as serviceWorker from './serviceWorker';
import './languages';
import Router from './router';
import { persistor, store } from './utils/reduxStore';

moment.locale('vi');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
