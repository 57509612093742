const initState = {
  syncing: false,
  listPath: [],
  filter: {
    isOpen: false,
    status: [],
    date: {
      from: new Date(),
      to: new Date(),
      oldestDate: '',
    },
  },
  search: '',
};

export const PublishPathAction = {
  LOADING: '@@publishPath/LOADING',
  GET_LIST_PATH: '@@publishPath/GET_LIST_PATH',
  OPEN_FILTER: '@@publishPath/OPEN_FILTER',
  CLOSE_FILTER: '@@publishPath/CLOSE_FILTER',
  SET_FILTER: '@@publishPath/SET_FILTER',
  SET_SEARCH: '@@publishPath/SET_SEARCH',
};

export const publishPath = (state = initState, action) => {
  switch (action.type) {
    case PublishPathAction.LOADING:
      return {
        ...state,
        syncing: action.data,
      };
    case PublishPathAction.GET_LIST_PATH:
      return {
        ...state,
        listPath: action.data,
      };
    case PublishPathAction.OPEN_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          isOpen: true,
        },
      };
    case PublishPathAction.CLOSE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          isOpen: false,
        },
      };
    case PublishPathAction.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data,
        },
      };
    case PublishPathAction.SET_SEARCH:
      return {
        ...state,
        search: action.data,
      };
    default:
      return state;
  }
};

export default publishPath;
