import React, { useMemo, memo } from 'react'
import { FormProvider } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup } from 'reactstrap';

// local
import LuckyBoxModalCtrl from './LuckyBoxModal.ctrl';
import LuckyBoxForm from './LuckyBoxForm';

const LuckyBoxModal = memo(({ id, open, toggle, setIsGenerateMoreModalOpen }) => {
  // -- ctrl --
  const {
    // form state
    luckyBoxForm,
    // on submit
    onSubmit,
  } = LuckyBoxModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} size="lg" keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>
        {id ? 'Chỉnh sửa hộp may mắn' : 'Thêm hộp may mắn mới'}
      </ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormProvider {...luckyBoxForm}>
          <LuckyBoxForm isEdit={!!id} setIsGenerateMoreModalOpen={setIsGenerateMoreModalOpen} />
        </FormProvider>
      </ModalBody>

      {/* modal footer */}
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {id ? 'Lưu' : 'Thêm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ({ ...props }) => (
  <LuckyBoxModalCtrl.Provider initialState={props}>
    <LuckyBoxModal {...props} />
  </LuckyBoxModalCtrl.Provider>
);