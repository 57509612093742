import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next'

// reducers
import { getAllCollectionTemplates } from '../../../../reducers/award-admin/collectionTemplate';

const useCollectionTemplateDataTableCtrl = () => {
  // -- collection template --
  const { isLoading, metadata = {}, ids = [] } = useSelector((state) => state.collectionTemplates);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- table filters --
  const [tableFilters, setTableFilters] = useState({ page: 1, limit: 10 })

  // -- handle change per page --
  const handleChangePerPage = useCallback(
    (perPage) =>
      setTableFilters((prevState) => ({ ...prevState, page: 1, limit: perPage })),
    []
  )

  // -- handle change page --
  const handleChangePage = useCallback(
    ({ selected }) =>
      setTableFilters((prevState) => ({ ...prevState, page: selected + 1 })),
    []
  )

  // -- handle change filters --
  const handleChangeFilters = useCallback(
    ({ keyword }) =>
      setTableFilters((prevState) => ({ ...prevState, keyword })),
    []
  )

  // -- is modal open --
  const [isModalOpen, setIsModalOpen] = useState(false)

  // -- toggle modal --
  const toggleModal = useCallback(() => setIsModalOpen((prevState) => !prevState), [setIsModalOpen])

  // -- modal item id --
  const [modalItemId, setModalItemId] = useState()

  // watching tableFilters change then refetch data
  useEffect(() => {
    dispatch(getAllCollectionTemplates(tableFilters));
  }, [tableFilters]);

  return {
    // collection templates
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
    // handle change filters
    handleChangeFilters,
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
  }
}

export default createContainer(useCollectionTemplateDataTableCtrl)
