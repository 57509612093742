import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { IconPauseCircle, IconPlayCircle } from '../../../../../icons/IconSvg';
import { getSoundUrl } from '../../../../../apis/voice';
import { useDispatch, useSelector } from 'react-redux';
import { OverviewActions } from '../../../../../actions/Overview';

const VoiceSpeaker = (props) => {
  const dispatch = useDispatch();
  const { localState } = useSelector((state) => state.lesson);
  const { overview } = localState;
  const { localSpeaker, text, audioUrl, type } = props;

  let typeOverviewProperty = '';
  if (type === 'contentOverview') {
    typeOverviewProperty = 'content';
  } else if (type === 'welcomeOverview') {
    typeOverviewProperty = 'welcome';
  } else if (type === 'welcomeScreen') {
    typeOverviewProperty = 'welcomeScreen';
  }

  const initAudioState = audioUrl ? new Audio(audioUrl) : '';
  const [audio, setAudio] = useState(initAudioState);
  const [audioState, setAudioState] = useState('paused');
  const [lastCallTTS, setLastCallTTS] = useState({
    localSpeaker: localSpeaker,
    audioUrl: audioUrl,
    text: text,
  });
  const callAudioApi = () => {
    getSoundUrl(localSpeaker, text)
      .then(function (response) {
        setLastCallTTS({ localSpeaker, audioUrl: response.data.wav_path, text });
        setAudio(new Audio(response.data.wav_path));
        setAudioState('paused');
      })
      .catch((error) => {
        console.log(error);
        setAudioState('paused');
      });
  };

  useEffect(() => {
    const newOverview = {
      ...overview,
      [typeOverviewProperty]: {
        ...overview[typeOverviewProperty],
        audioUrl: lastCallTTS.audioUrl,
      },
    };
    if (typeOverviewProperty !== 'welcomeScreen') {
      dispatch(OverviewActions(newOverview));
    }
  }, [lastCallTTS]);
  useEffect(() => {
    if (!localSpeaker || !text) return () => null;
    setAudioState('loading');
    const timeOutTyping = setTimeout(() => {
      if (lastCallTTS.audioUrl && lastCallTTS.text === text && lastCallTTS.localSpeaker === localSpeaker)
        return () => clearTimeout(timeOutTyping);
      callAudioApi();
    }, 1000);
    return () => clearTimeout(timeOutTyping);
  }, [localSpeaker, text]);

  useEffect(() => {
    let isCancelled = false;
    if (!audio || !audio.src) {
      setAudioState('loading');
      return () => {
        isCancelled = true;
      };
    }
    if (isCancelled == false) {
      audio.onplaying = () => {
        setAudioState('playing');
      };
      audio.onpause = () => {
        setAudioState('paused');
      };
      audio.onerror = (e) => {
        setAudioState('paused');
      };

      audio.onloadeddata = () => {
        setAudioState('paused');
      };
    }
    return () => {
      isCancelled = true;
    };
  }, [audio]);

  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, []);

  const renderAudioIcon = () => {
    switch (audioState) {
      case 'loading':
        return <Spinner size="sm" color="dark" />;

      case 'playing':
        return <IconPauseCircle />;

      case 'paused':
        return <IconPlayCircle />;

      default:
        return <IconPlayCircle />;
    }
  };

  return (
    <div
      aria-hidden="true"
      className="cursor"
      onClick={() => {
        if (audio) {
          if (audio.paused) audio.play();
          else audio.pause();
        }
      }}
    >
      {renderAudioIcon()}
    </div>
  );
};

export default VoiceSpeaker;
