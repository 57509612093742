import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Collapse, Input, Spinner } from 'reactstrap';
import { getSoundUrl } from '../../../apis/voice';
import { IconPauseCircle, IconPlayCircle, IconSetting } from '../../../icons/IconSvg';
import { getVoiceLabel, voices } from '../../../utils/constants';
import { generateUUID } from '../../../utils/generate';
import PopoverCustom, { PopoverCustomUseUnController } from '../../Popover/PopoverCustom';
import { InputRangeVoice } from '../../Quiz/common/InputRangeVoice';
import './style.scss';

function _TitleVoiceBox({
  versionPreview,
  lpID,
  title,
  titleVoice,
  titleAudioUrl,
  rateVoice,
  handleChangeLP,
  indexQuiz,
  previewLP, id
}) {
  const [popoverOpenVoice, setPopoverOpenVoice] = useState(false);
  const toggleVoice = () => setPopoverOpenVoice(!popoverOpenVoice);

  const [lastCallTTS, setLastCallTTS] = useState({ titleVoice, title, lpID, rateVoice });
  const [lastLP, setlastLP] = useState(lpID);
  const [audioState, setaudioState] = useState('pause');
  const [audio, setaudio] = useState(null);
  const [callingApi, setcallingApi] = useState(false);
  const [idText] = useState(generateUUID().substring(0, 4));

  const callAudioApi = () => {
    if (!versionPreview) {
      getSoundUrl(titleVoice, title, rateVoice)
        .then(function (response) {
          setLastCallTTS({ titleVoice, title, rateVoice });
          setaudio(new Audio(response.data.wav_path));
          handleChangeLP({ titleAudioUrl: response.data.wav_path, indexQuiz });
          setaudioState('loading');
          setcallingApi(false);
        })
        .catch(function (error) {
          console.error(error);
          setcallingApi(false);
        });
    }
  };
  // Trigger get new audio when title or sound changed
  useEffect(() => {
    if (!previewLP) {
      if (!titleVoice || !title) return () => null;

      // if change slide, avoid recallAPI
      if (lastLP !== lpID) {
        setlastLP(lpID);
        return () => null;
      }

      // After 1000ms if this still alive then run
      const timeOutTyping = setTimeout(() => {
        if (lastCallTTS.title === title && lastCallTTS.titleVoice === titleVoice && lastCallTTS.rateVoice === rateVoice)
          return () => clearTimeout(timeOutTyping);
        setcallingApi(true);
        callAudioApi();
      }, 1000);

      return () => clearTimeout(timeOutTyping);
    }
  }, [titleVoice, title, lpID, rateVoice]);
  useEffect(() => {
    if (!previewLP) {
      let isCancelled = false;

      if (!audio || !audio.src) {
        setaudioState('loading');
        return () => {
          isCancelled = true;
        };
      }

      audio.onplaying = () => {
        setaudioState('playing');
      };
      audio.onpause = () => {
        setaudioState('paused');
      };
      audio.onerror = () => {
        if (isCancelled) return;
        setaudioState('paused');
      };
      audio.onloadeddata = () => {
        if (isCancelled) return;
        setaudioState('paused');
      };
      return () => {
        isCancelled = true;
      };
    }
  }, [audio]);
  useEffect(() => {
    if (!previewLP) setaudio(titleAudioUrl ? new Audio(titleAudioUrl) : null);
  }, [titleAudioUrl]);

  const renderAudioIcon = () => {
    if (previewLP) {
      return <IconPlayCircle />;
    } else {
      switch (audioState) {
        case 'loading':
          return <Spinner size="sm" color="dark" />;

        case 'playing':
          return <IconPauseCircle />;

        case 'paused':
          return <IconPlayCircle />;

        default:
          return <IconPlayCircle />;
      }
    }
  };
  const ref = useRef(null);
  return (
    <div id={id} className="TitleVoiceBox fl_b">
      <span
        id={`titleVoice-${idText}`}
        onClick={toggleVoice}
        onBlur={toggleVoice}
        aria-hidden="true"
        className="cursor"
        style={{ margin: '0 0 0 2px', alignSelf: 'center' }}
      >
        {getVoiceLabel(titleVoice)}
        {!versionPreview && (
          <PopoverCustomUseUnController
            id={`titleVoice-${idText}`}
            width="160px"
            toggle={toggleVoice}
            popoverOpen={popoverOpenVoice}
          >
            {voices.map((v) => (
              <div
                ref={ref}
                aria-hidden="true"
                key={v.speaker}
                className="div_item"
                onClick={() => {
                  handleChangeLP({ titleVoice: v.speaker, indexQuiz });
                }}
                style={v.speaker === titleVoice ? { fontWeight: 'bold' } : null}
              >
                {v.label}
              </div>
            ))}
            <InputRangeVoice handleChangeLP={handleChangeLP} indexQuiz={indexQuiz} rateVoice={rateVoice} />
          </PopoverCustomUseUnController>
        )}
      </span>
      {!versionPreview && ((audio && titleAudioUrl) || titleVoice) && callingApi === false && (
        <span
          aria-hidden="true"
          className="cursor"
          onClick={() => {
            if (audio) {
              if (audio.paused) audio.play();
              else audio.pause();
            }
          }}
        >
          {renderAudioIcon()}
        </span>
      )}
      {callingApi && <Spinner size="sm" color="dark" />}
    </div>
  );
}
const TitleVoiceBox = React.memo(_TitleVoiceBox);
export default TitleVoiceBox;
