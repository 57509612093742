import React, { useCallback, useEffect, memo } from 'react';
import { Label, Input, FormText } from 'reactstrap';
import { useFormContext, useController } from 'react-hook-form';
import cls from 'classnames';

// components
import InputField from './InputField';

const InputImageField = ({
  className,
  name = 'image',
  id = 'image',
  previewImageUrlName = 'previewImageUrl',
  previewImageUrl,
  setPreviewImageUrl,
  previewImageProps = {},
  disabled = false,
  maxFileSizeInBytes = 5000000, // default limit to 5MB
  ...props
}) => {
  // -- form state --
  const { control, errors, setError, clearErrors } = useFormContext();

  // -- field state --
  const {
    field: { ref, onChange: defaultOnChange, value, ...field },
    meta,
  } = useController({ name, control });
  const { invalid } = meta;

  // -- on custom change --
  const onCustomChange = useCallback(
    (e) => {
      // cleanup current preview image url
      URL.revokeObjectURL(previewImageUrl);

      // check file size limit
      if (e.target.files[0]?.size > maxFileSizeInBytes) {
        setError(name, { message: `Ảnh phải có kích thước nhỏ hơn ${maxFileSizeInBytes / 1000000}MB` });
        setPreviewImageUrl('');
        return;
      }

      // clear error
      clearErrors(name);

      // set new object url to imageUrl
      setPreviewImageUrl(URL.createObjectURL(e.target.files[0]));

      defaultOnChange(e.target.files);
    },
    [defaultOnChange, previewImageUrl, setPreviewImageUrl],
  );

  useEffect(() => {
    return () => {
      // cleanup preview image url
      URL.revokeObjectURL(previewImageUrl);
    };
  });
  console.log(errors);
  return (
    <>
      {!disabled && (
        <>
          <Label htmlFor={name} className="d-block btn btn-secondary t-w-[fit-content] mb-0">
            Chọn ảnh
          </Label>
          <Input
            className="d-none"
            type="file"
            name={name}
            id={id}
            accept="image/*"
            {...field}
            onChange={onCustomChange}
            innerRef={ref}
            {...props}
          />
        </>
      )}

      {/* preview image */}
      {previewImageUrl && <img className="mt-3 t-w-full" src={previewImageUrl} {...previewImageProps} />}

      <InputField className="d-none" name={previewImageUrlName} id={previewImageUrlName} />

      {errors[name] && <FormText color="danger">{errors[name].message}</FormText>}
    </>
  );
};

export default memo(InputImageField);
