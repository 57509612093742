import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from '../utils/cookie';

export default function PublicRoute({ Component, restricted, ...rest }) {
  const accessToken = getCookie('accessToken');

  if (accessToken && restricted) {
    return <Redirect to="/course" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
