import React, { memo } from 'react'
import { FormProvider } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

// local
import CollectionCategoryModalCtrl from './CollectionCategoryModal.ctrl';
import CollectionCategoryForm from './CollectionCategoryForm';

const CollectionCategoryModal = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // form state
    collectionCategoryForm,
    // on submit
    onSubmit,
  } = CollectionCategoryModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>
        {id ? 'Chỉnh sửa thể  loại' : 'Thêm thể loại mới'}
      </ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormProvider {...collectionCategoryForm}>
          <CollectionCategoryForm />
        </FormProvider>
      </ModalBody>

      {/* modal footer */}
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {id ? 'Lưu' : 'Thêm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ({ ...props }) => (
  <CollectionCategoryModalCtrl.Provider initialState={props}>
    <CollectionCategoryModal {...props} />
  </CollectionCategoryModalCtrl.Provider>
);