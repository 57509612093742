import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { imageCompress } from '../../utils/imageProcessing';
import ComponentUpImage from '../../components/Chapter/common/ComponentUpImage';
import { TYPE_RANDOM, TYPE_STATIC, dataFix, chageTest } from '../../reducers/test';
import { createActionLesson } from '../../reducers/chapter';
import ComponentTest from './ComponentTest';
import { getTestId } from '../../apis/test';
import { toast } from 'react-toastify';
import { createActionTest, saveQuizzesTest, addTestIntoChapter } from '../../actions/ActionTest';
import ComponentUploadImg from './ComponentUploadImg';

class ComponentRight extends React.Component {
  state = {
    redirect: false,
    redirectQuizTest: false,
    idTest: '',
  };
  async componentDidMount() {
    if (this.props.id) {
      let datas = await getTestId(this.props.id);
      if (datas && datas.status === 1) {
        datas = {
          ...datas.result.test,
          quizIds:
            datas.result.test.quizIds && datas.result.test.quizIds.length > 0
              ? datas.result.test.quizIds.map((quiz) => quiz.id)
              : [],
        };
        this.props.chageTest(datas);
      } else {
        this.setState({ redirect: true });
        toast.error('Có lỗi xảy ra khi xem chi tiết bài kiểm tra');
      }
    } else {
      const { course, chapterCurrent } = this.props;

      this.props.chageTest({ ...dataFix, courseId: course?.id, chapterId: chapterCurrent?.id });
    }
  }
  chageImg = (base64) => {
    this.setState({ base64: base64 });
  };
  readImg = async (event, type) => {
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      const compressedFile = await imageCompress(event.target.files[0]);
      if (type === 'img_avatar_test') {
        this.props.chageTest({ ...this.props.dataTest, fileImage: compressedFile, imageUrl: null });
      } else {
        this.props.chageTest({ ...this.props.dataTest, fileThumb: compressedFile, thumbnailImageUrl: null });
      }
    }
  };
  changeData = (data) => {
    this.props.chageTest(data);
  };
  onSubmit = async (chapterCurrent, typeChapter) => {
    if (this.props.id) {
      this.props.saveQuizzesTest(this.props.dataTest);
      this.setState({ redirect: true });
    } else {
      const data = await createActionTest(this.props.dataTest);
      if (data && data.status === 1) {
        addTestIntoChapter(data.result.test, chapterCurrent);
        if (this.props.dataTest.testType === TYPE_STATIC) {
          this.setState({ idTest: data.result.test.id }, () => {
            this.setState({ redirectQuizTest: true });
          });
        } else {
          this.setState({ redirect: true });
        }
      }
    }
  };
  checkDisable = () => {
    const { dataTest } = this.props;
    const check =
      !dataTest.title ||
      (!dataTest.fileImage && !dataTest.imageUrl) ||
      (!dataTest.thumbnailImageUrl && !dataTest.fileThumb);
    return check;
  };
  render() {
    const { course, chapterCurrent } = this.props;
    const disable = this.checkDisable();
    if (this.state.redirect) {
      return <Redirect to={`/course/${course?.id}`} />;
    }
    if (this.state.redirectQuizTest) {
      return <Redirect to={`/quizzes-test/${this.state.idTest}`} />;
    }
    return (
      <div className="fl wrapper_content-test">
        <div className="container_test container_test_right">
          <ComponentTest
            courseId={course?.id}
            course={course}
            chapterId={chapterCurrent?.id}
            dataTest={this.props.dataTest}
            changeData={this.changeData}
            chapterCurrent={chapterCurrent}
            idTest={this.props.id}
          />
        </div>
        <div className="test_right container_test_right">
          <ComponentUploadImg
            textTypeChapter="bài kiểm tra"
            dataTest={this.props.dataTest}
            readImg={this.readImg}
            changeData={this.changeData}
          />
        </div>
        <button
          disabled={disable}
          onClick={() => this.onSubmit(chapterCurrent)}
          style={{ right: '20px', bottom: '20px', position: 'fixed' }}
          className="btn btn-primary"
        >
          Xác nhận
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataTest: state.test.dataTest,
});
export default connect(mapStateToProps, {
  chageTest,
  saveQuizzesTest,
  createActionLesson,
})(ComponentRight);
