import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import RadioButton from '../../components/Common/RadioButton';
import { TYPE_STATIC, TYPE_RANDOM } from '../../reducers/test';

class OptionTest extends React.Component {
  render() {
    const { dataTest, changeData } = this.props;
    return (
      <div className="fl">
        <div style={{ width: '100%' }}>
          <div className="ft-w" htmlFor="Title">
            Loại bài kiểm tra
          </div>
          <div
            style={{ padding: '10px 20px', background: '#F5F6F9', borderRadius: '3px', margin: '10px 0' }}
            className="fl_b"
          >
            <Option
              dataTest={dataTest}
              dataChange={{ ...dataTest, testType: TYPE_STATIC, numberOfQuizzes: null }}
              changeData={changeData}
              checked={dataTest.testType === TYPE_STATIC}
              title="Cố định"
            />
            <Option
              dataTest={dataTest}
              dataChange={{ ...dataTest, testType: TYPE_RANDOM, quizIds: [] }}
              changeData={changeData}
              checked={dataTest.testType === TYPE_RANDOM}
              title="Ngẫu nhiên"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OptionTest;

const Option = ({ changeData, title, dataChange, checked }) => {
  return (
    <div style={{ width: '50%' }} className="fl">
      <RadioButton changeRadioButton={() => changeData(dataChange)} checked={checked} />
      <span style={{ marginLeft: '20px' }}>{title}</span>
    </div>
  );
};
