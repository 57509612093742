import { combineReducers } from 'redux';
import Auth from './auth';
import Lesson from './lesson';
import Users from './users';
import Courses from './courses';
import Paths from './paths';
import Chapter from './chapter';
import Voice from './voice';
import QuestionBank from './questionBank';
import Test from './test';
import LessonNavigationTemplate from './lessonNavigationTemplate';
import Troubleshoot from './troubleshoot';
import PublishManagement from './publishManagement';
import PublishPath from './publishPath'
import { frameContent } from './frameContent';
// award-admin
import collectionCategories from './award-admin/collectionCategory';
import collectionTemplates from './award-admin/collectionTemplate';
import collectionItems from './award-admin/collectionItem';
import systemCollections from './award-admin/systemCollection';
import orders from './award-admin/order';
import luckyBoxes from './award-admin/luckyBox';

const rootReducer = combineReducers({
  auth: Auth,
  lesson: Lesson,
  users: Users,
  paths: Paths,
  courses: Courses,
  chapter: Chapter,
  voice: Voice,
  questionBank: QuestionBank,
  test: Test,
  lessonNavigationTemplate: LessonNavigationTemplate,
  troubleshoot: Troubleshoot,
  publishManagement: PublishManagement,
  publishPath: PublishPath,
  frameContent,
  // award-admin
  collectionCategories,
  collectionTemplates,
  collectionItems,
  systemCollections,
  orders,
  luckyBoxes,
});
export default rootReducer;
