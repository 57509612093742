/* eslint-disable no-case-declarations */
import { toast } from 'react-toastify';
import { addCheckpoint } from '../actions/ActionCheckpoint';
import { OverviewActions } from '../actions/Overview';
import { getOverview } from '../apis/overview';
import { getLessonId, getLessons, createLesson } from '../apis/lesson';
import { deleteFile } from '../apis/upload';
import { addIDRecursively, addIDRecursivelyV2 } from '../utils/array';
import { generateId, getRndInteger } from '../utils/generate';
import { getDirectoryUrlFromFile2, getFullUrl } from '../utils/imageProcessing';
import { denormalizeLearningPoints, normalizeLearningPoints } from '../utils/normalize';
import { convertDataLearningPoint, refineLearningPoints } from './convertLesson';
import { Quiz, Theory, Lesson, Welcome, Reward, Overview } from './templates';
import { getStorageItem, setStorageItem } from '../utils/localStorage';

// import { ToastContainer, toast } from 'react-toastify';
const id = getRndInteger(1000, 9999);
const initState = {
  currentSlide: id, // Current active slide
  localState: Lesson, // Temporary Lesson Object on user PC
  oldLocalState: Lesson,
  versionLesson: [],
  lastUploadState: {}, // user can use this to revert to latest state on cloud; call api on init
  lastSyncTime: new Date(), // last Sync Time with db
  syncing: true, // is Syncing

  changes: [], // advanced feature: to undo changes
  layoutOption: 'theory',
  welcome: { ...Welcome, id: id },
  data: [],
};

export const findIndexId = (datas, id) => datas.findIndex((data) => data.id === id);
// ------------------------------
export const fetchLessonAction = () => async (dispatch) => {
  dispatch({ type: '@@lesson/SYNCING', value: true });
  try {
    const res = await getLessons();
    if (res.code) return toast.error(`Error found: ${res.code}`)
    dispatch({
      type: '@@lesson/DATA',
      data: res.result.lessons.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    });
  } catch (error) {
    //console.error(error);
  }
  dispatch({ type: '@@lesson/SYNCING', value: false });
};

export const createLessonAction = (lesson) => async (dispatch, getState) => {
  const { title, imageUrl, thumbnailImageUrl, learningPoints } = lesson;
  const { data } = getState().lesson;
  try {
    const res = await createLesson({ title, imageUrl, thumbnailImageUrl, learningPoints });
    if (res.code) return toast.error(res.message);
    dispatch({ type: '@@lesson/DATA', data: [res.result.lesson, ...data] });
    return toast.success(`Đã tạo bài học: ${title}`);
  }
  catch (error) {
    //console.error(error);
  }
};

export const deleteSlide = (data) => async (dispatch, getState) => {
  const { localState } = getState().lesson;

  const newLps = localState.learningPoints.filter((lrn) => !(lrn.type === data.type && lrn.id === data.id)) || [];

  dispatch({ type: '@@lesson/LOCALSTATE', data: { learningPoints: newLps, ...normalizeLearningPoints(newLps) } });
  dispatch({ type: 'SLIDE_SET_CURRENT_SLIDE', id: newLps[0] ? newLps[0].id : null });
  dispatch({ type: 'SLIDE_UPDATE_LAYOUT_OPTION', layout: 'quiz' });
};

export const addSlideQuiz = (quiz) => async (dispatch, getState) => {
  const { currentSlide, localState } = getState().lesson;

  const index = findIndexId(localState.learningPoints, currentSlide);
  const newLps =
    index === -1
      ? [...localState.learningPoints, quiz]
      : [...localState.learningPoints.slice(0, index + 1), quiz, ...localState.learningPoints.slice(index + 1)];
  dispatch({ type: '@@lesson/LOCALSTATE', data: { learningPoints: newLps, ...normalizeLearningPoints(newLps) } });
  dispatch({ type: 'SLIDE_SET_CURRENT_SLIDE', id: quiz.id });
  dispatch({ type: 'SLIDE_UPDATE_LAYOUT_OPTION', layout: 'quiz' });
};

const createSlide = (value) => async (dispatch, getState) => {
  const { currentSlide, localState, welcome } = getState().lesson;
  let obj =
    value === 'checkpoint'
      ? { ...Reward }
      : value === 'quiz'
      ? { ...Quiz }
      : { ...Theory, customLayout: { id: generateId(localState) } };

  if (value === `checkpoint`) {
    const { learningPointPartTitles, learningPointParts } = localState.overview;
    if (
      learningPointParts === 2 &&
      learningPointPartTitles[0].title === '' &&
      learningPointPartTitles[1].title === ''
    ) {
      obj = {
        ...obj,
        title: 'bài học',
        congratulationText: `Chúc mừng bạn đã vượt qua bài học`,
      };
    }
    if (learningPointPartTitles[0].title !== '' && learningPointPartTitles[1].title !== '') {
      const checkpointNum = localState.learningPoints.filter((e) => e.type.split(':')[0] === 'checkpoint').length;
      obj = {
        ...obj,
        title: learningPointPartTitles[checkpointNum].title,
        congratulationText: `Chúc mừng bạn đã vượt qua phần ${learningPointPartTitles[checkpointNum].title}`,
      };
    }
  }

  const newLP = { ...obj, id: generateId(localState) };
  var newLps = [];
  const checkpointNum = localState.learningPoints.filter((e) => e.type.split(':')[0] === 'checkpoint').length;

  if (currentSlide !== welcome.id) {
    const index = findIndexId(localState.learningPoints, currentSlide);
    newLps =
      index === -1 || (checkpointNum === 0 && value === `checkpoint`)
        ? [...localState.learningPoints, newLP]
        : [...localState.learningPoints.slice(0, index + 1), newLP, ...localState.learningPoints.slice(index + 1)];
  } else if (currentSlide === welcome.id) {
    newLps = [newLP, ...localState.learningPoints];
    // dispatch({type: 'SLIDE_OVERVIEW', data: {click: 'learningPoint'}});
  }
  dispatch({ type: '@@lesson/LOCALSTATE', data: { learningPoints: newLps, ...normalizeLearningPoints(newLps) } });
  dispatch({ type: 'SLIDE_SET_CURRENT_SLIDE', id: newLP.id });
  dispatch({ type: 'SLIDE_UPDATE_LAYOUT_OPTION', layout: value });
  if (value === 'checkpoint') {
    dispatch(
      OverviewActions({
        ...localState.overview,
        usedStars: localState.overview.usedStars + 1,
      }),
    );
  }
  let tourData = getStorageItem('tour');
  tourData = tourData ? tourData : null;
  if (tourData && tourData.lessonRight && tourData.lessonRight.isTour)
    setStorageItem({
      key: 'tour',
      value: JSON.stringify({
        leftAction: false,
        lessonRight: {
          isTour: true,
          type: value,
        },
      }),
    });
};

const copySlide = (learningPoint) => (dispatch, getState) => {
  const { localState, currentSlide } = getState().lesson;
  const { learningPoints, overview } = localState;

  const index = learningPoints.findIndex((e) => e.id === learningPoint.id);
  const temp = learningPoint;
  let newLp = {
    ...temp,
    id: generateId(),
    createdAt: null,
    updatedAt: null,
    isCopy: true,
  };

  const value = newLp.type.split(':')[0];
  const newLps =
    index === -1 || index === learningPoints.length - 1
      ? [...learningPoints, newLp]
      : index === 0
      ? [newLp, ...learningPoints]
      : [...learningPoints.slice(0, index + 1), newLp, ...learningPoints.slice(index + 1)];

  newLp = addIDRecursivelyV2(newLp);
  if (value !== 'checkpoint' || addCheckpoint(learningPoints, overview)) {
    dispatch({ type: '@@lesson/LOCALSTATE', data: { learningPoints: newLps, ...normalizeLearningPoints(newLps) } });
    dispatch({ type: 'SLIDE_SET_CURRENT_SLIDE', id: newLp.id });
    dispatch({ type: 'SLIDE_UPDATE_LAYOUT_OPTION', layout: value });
    toast.success('Copy slide thành công !');
  }
};

// FOR THEORIES
export const addMediaObjectToCustomLayout = (customLayoutId, mediaObject) => (dispatch, getState) => {
  const { localState } = getState().lesson;
  const { learningPoints } = localState;
  const { entities, learningPointIds } = normalizeLearningPoints(learningPoints);

  const mediaObjectID = generateId(localState);
  entities.customLayouts[customLayoutId].mediaObject = mediaObjectID;
  if (!entities.mediaObjects) {
    entities.mediaObjects = { [mediaObjectID]: { ...mediaObject, id: mediaObjectID } };
  } else {
    entities.mediaObjects[mediaObjectID] = { ...mediaObject, id: mediaObjectID };
  }
  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: { entities, learningPoints: denormalizeLearningPoints(learningPointIds, entities) },
  });
};

export const clearCustomLayout = (customLayoutId) => (dispatch, getState) => {
  const { learningPoints } = getState().lesson.localState;
  const { entities, learningPointIds } = normalizeLearningPoints(learningPoints);

  delete entities.customLayouts[customLayoutId].mediaObject;

  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: { entities, learningPoints: denormalizeLearningPoints(learningPointIds, entities) },
  });
};

export const addVerticalToCustomLayout = (customLayoutId, customLayout) => (dispatch, getState) => {
  const { localState } = getState().lesson;
  const { learningPoints } = localState;
  const { entities, learningPointIds } = normalizeLearningPoints(learningPoints);

  // Also have to check type here:
  // blank => vertical
  // vertical to existed vertical (advanced)

  const upID = generateId(localState);
  const downID = generateId(localState);

  entities.customLayouts[customLayoutId] = { id: customLayoutId, ...customLayout, children: [upID, downID] };
  entities.customLayouts[upID] = { id: upID };
  entities.customLayouts[downID] = { id: downID };
  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: { entities, learningPoints: denormalizeLearningPoints(learningPointIds, entities) },
  });
};

export const addHorizontalToCustomLayout = (customLayoutId, customLayout) => (dispatch, getState) => {
  const { localState } = getState().lesson;
  const { learningPoints } = localState;
  const { entities, learningPointIds } = normalizeLearningPoints(learningPoints);

  // Also have to check type here:
  // blank => horizontal
  // horizontal to existed horizontal (advanced)

  const leftID = generateId(localState);
  const rightID = generateId(localState);

  entities.customLayouts[customLayoutId] = { id: customLayoutId, ...customLayout, children: [leftID, rightID] };
  entities.customLayouts[leftID] = { id: leftID };
  entities.customLayouts[rightID] = { id: rightID };
  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: { entities, learningPoints: denormalizeLearningPoints(learningPointIds, entities) },
  });
};

const getNewMediaObjectAfterUploadFile = async (oldMediaObject, newFile) => {
  switch (oldMediaObject.type) {
    case 'image':
      if (oldMediaObject.imageUrl !== '') await deleteFile(oldMediaObject.imageUrl);
      const imageUrl = await getDirectoryUrlFromFile2(newFile);
      return { ...oldMediaObject, imageUrl };

    case 'video':
      if (oldMediaObject.videoUrl !== '') await deleteFile(oldMediaObject.videoUrl);
      const videoUrl = await getDirectoryUrlFromFile2(newFile);
      return { ...oldMediaObject, videoUrl };

    default:
      return oldMediaObject;
  }
};

export const updateMediaObject = (id, value) => async (dispatch, getState) => {
  const { entities, learningPointIds, learningPoints } = getState().lesson.localState;

  if (value.file) {
    entities.mediaObjects[id] = await getNewMediaObjectAfterUploadFile(entities.mediaObjects[id], value.file);
  } else {
    entities.mediaObjects[id] = { ...entities.mediaObjects[id], ...value };
  }
  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: { entities, learningPoints: denormalizeLearningPoints(learningPointIds, entities) },
  });
};

export const createSlideV2 = (value) => (dispatch, getState) => {
  dispatch(SlidesActions.create(value));
};

export const getLesson = (id) => async (dispatch, getState) => {
  dispatch({
    type: '@@lesson/SYNCING',
    value: true,
  });
  const res = await getLessonId(id);
  if (res && res.status === 1) {
    const lpArray = convertDataLearningPoint(res.result.lesson);
    const checkpointArray = lpArray.filter((element) => element.type.split(':')[0] === 'checkpoint');
    const usedStars = checkpointArray.reduce((stars, currElement) => {
      return stars + currElement.stars;
    }, 0);
    const learningPoints_addStarLocationInCheckpoints = lpArray.map((element, index) => {
      if (element.type.split(':')[0] === 'checkpoint') {
        return {
          ...element,
          starLocation: {
            id: getRndInteger(1000, 9999),
            star1: element.stars > 0 ? true : false,
            star2: element.stars > 1 ? true : false,
            star3: element.stars > 2 ? true : false,
          },
        };
      }
      return element;
    });
    const { learningPoints, learningPointIds, entities } = refineLearningPoints(
      learningPoints_addStarLocationInCheckpoints,
    );
    let lessonData = { ...res.result.lesson };

    dispatch({
      type: 'RECEIVE__LESSON',
      data: {
        ...lessonData,
        overview: {
          ...lessonData.overview,
          usedStars: usedStars,
          content: {
            ...lessonData.overview.content,
            audioUrl: getFullUrl(lessonData.overview.content.audioUrl),
          },
          welcome: {
            ...lessonData.overview.welcome,
            audioUrl: getFullUrl(lessonData.overview.welcome.audioUrl),
          },
          background: getFullUrl(lessonData.overview.background),
        },
        learningPoints: learningPoints,
        learningPointIds,
        entities,
      },
    });
  }

  dispatch({
    type: '@@lesson/SYNCING',
    value: false,
  });
};

export default (state = initState, action) => {
  switch (action.type) {
    case '@@lesson/SYNCING':
      return {
        ...state,
        syncing: action.value,
      };

    case '@@lesson/DATA':
      return {
        ...state,
        data: action.data,
      };

    case '@@lesson/LOCALSTATE':
      return {
        ...state,
        localState: { ...state.localState, ...action.data },
      };

    case 'ADD_CHOICE_QUIZ':
      return {
        ...state,
        localState: {
          ...state.localState,
          learningPoints: action.learningPoints,
        },
      };
    case 'CHANGE_QUIZ':
      return {
        ...state,
        localState: {
          ...state.localState,
          learningPoints: action.learningPoints,
        },
      };

    case 'CHANGE_LEARNING_POINTS':
      return {
        ...state,
        localState: {
          ...state.localState,
          learningPoints: action.learningPoints,
        },
      };
    case 'RECEIVE__LESSON':
      if (action.data.learningPoints.length === 0)
        return {
          ...state,
          currentSlide: null,
          localState: {
            ...action.data,
          },
          oldLocalState: { ...action.data },
          syncing: false,
        };
      let previousSelectedSlide = '';
      if (state.currentSlide)
        previousSelectedSlide = action.data.learningPoints.find(({ id }) => id == state.currentSlide);
      const currId = previousSelectedSlide
        ? previousSelectedSlide.id
        : action.data.learningPoints && action.data.learningPoints.length > 0
        ? action.data.learningPoints[0].id
        : null;
      const currLp = action.data.learningPoints.find(({ id }) => id === currId);
      const layoutOption = currLp ? currLp.type.split(':')[0] : 'welcome';
      return {
        ...state,
        currentSlide: currId,
        localState: { ...action.data },
        oldLocalState: { ...action.data },
        layoutOption: layoutOption,
        syncing: false,
      };
    case 'SLIDE_UPDATE_LAYOUT_OPTION':
      return {
        ...state,
        layoutOption: action.layout,
      };

    case 'SLIDE_SET_CURRENT_SLIDE':
      return { ...state, currentSlide: action.id };
    case 'SLIDE_DELETE':
      const newLearningPoints = state.localState.learningPoints.filter(
        (learning) => !(learning.id === state.currentSlide && learning.type.split(':')[0] === state.layoutOption),
      );
      return {
        ...state,
        layoutOption: newLearningPoints.length > 0 ? newLearningPoints[0].type.split(':')[0] : state.layoutOption,
        currentSlide: newLearningPoints.length > 0 ? newLearningPoints[0].id : 0,
        localState: {
          ...state.localState,
          learningPoints: newLearningPoints,
        },
        oldLocalState: {
          ...state.oldLocalState,
          learningPoints: state.oldLocalState.learningPoints.filter((learning) => learning.id !== state.currentSlide),
        },
      };
    case 'UPDATE_CURRENT_LAYOUT':
      const currentLP = findIndexId(state.localState.learningPoints, state.currentSlide);
      if (currentLP === -1) return state;

      let newLPs;

      // handling generated customlayout learningPoints
      if (action.data.customLayout) {
        const generatedIdCustomLayout = JSON.parse(JSON.stringify(action.data.customLayout));
        addIDRecursively(generatedIdCustomLayout);
        newLPs = {
          ...state.localState.learningPoints,
          [currentLP]: {
            ...action.data,
            customLayout: generatedIdCustomLayout,
            quizParts: [],
          },
        };
      } else {
        newLPs = {
          ...state.localState.learningPoints,
          [currentLP]: {
            ...action.data,
            quizParts: [],
          },
        };
      }

      return {
        ...state,
        localState: {
          ...state.localState,
          learningPoints: Object.values(newLPs),
          ...normalizeLearningPoints(Object.values(newLPs)),
        },
      };

    case 'CHANGE_DATA_LESSON':
      return {
        ...state,
        localState: {
          ...state.localState,
          [action.key]: action.value,
        },
      };

    case 'CHANGE_OVERVIEW':
      return {
        ...state,
        localState: {
          ...state.localState,
          overview: action.data,
        },
      };

    case 'CHANGE_STAR_CHECKPOINT':
      let index_1 = findIndexId(state.localState.learningPoints, action.data.id);
      return {
        ...state,
        localState: {
          ...state.localState,
          overview: {
            ...state.localState.overview,
            usedStars: action.data.usedStars,
          },
          learningPoints: [
            ...state.localState.learningPoints.slice(0, index_1),
            {
              ...state.localState.learningPoints[index_1],
              stars: action.data.stars,
              starLocation: action.data.starLocation,
            },
            ...state.localState.learningPoints.slice(index_1 + 1),
          ],
        },
      };
    case 'CHANGE_CHECKPOINT':
      return {
        ...state,
        localState: {
          ...state.localState,
          learningPoints: [
            ...state.localState.learningPoints.slice(0, action.data.index),
            {
              ...state.localState.learningPoints[action.data.index],
              [action.data.type]: action.data.content,
            },
            ...state.localState.learningPoints.slice(action.data.index + 1),
          ],
        },
      };
    case 'UPDATE_VERSION_LESSON':
      return {
        ...state,
        versionLesson: action.data,
      };
    default:
      return state;
  }
};

export const SlidesActions = {
  updateLayoutOption: (layout) => ({
    type: 'SLIDE_UPDATE_LAYOUT_OPTION',
    layout,
  }),
  create: createSlide,
  copy: copySlide,
  update: () => ({ type: 'SLIDE_UPDATE' }),
  delete: () => ({ type: 'SLIDE_DELETE' }),
  setCurrentSlide: (id) => ({ type: 'SLIDE_SET_CURRENT_SLIDE', id }),
  updateCurrentLayout: (data) => ({ type: 'UPDATE_CURRENT_LAYOUT', data }),
  changeLesson: (key, value) => ({ type: 'CHANGE_DATA_LESSON', key, value }),
};
