import React from 'react';
import { SQUARE_OBJECT_TYPE } from '../../constants';
import {getStructureOfRhyme, getStructureOfWord } from '../Quiz/getData';
import { splitDataEnter } from '../Quiz/quizUtils';
import {
  ToneOfWord,
  WordInSentence,
  SquareContainerInterface,
  ElementOfWordChildren,
  PhonemeOfRhymeChildren,
  PhonemeInRhymeChildren,
  ElementInWordChildren
} from './SquareUI';

// Template:
// const squares = [
//   { text: 'text of square', textPosition: 'top/inside', tone: 'TONES_CONSTANTS.name', visibility: 'hidden/show' },
// ];
const enterObject = {
  syllable: '\n',
  consonant: '',
  rhyme: '',
  tone: '',
};
const position = {
  textPosition: 'inside',
  visibility: 'show',
};
export const handleTextSquareQuiz = (str) => {
  if (!str) return [];
  const arraySplit = splitDataEnter(str);
  let result = [];

  arraySplit.forEach(element => {
    if (element === '\n') result.push(enterObject);
    else {
      const syllable = getStructureOfWord(element);
      const rhyme = getStructureOfRhyme(syllable.rhyme);
      const temp = {
        ...syllable,
        rhymeObj: rhyme,
      };
      result.push(temp);
    };
  });
  return result;
};
export const handleElementInWord = (str) => {
  if (!str) return [];
  const arraySplit = splitDataEnter(str);
  let result = [];

  arraySplit.forEach(element => {
    if (element === '\n') result.push(enterObject);
    else {
      const syllable = getStructureOfWord(element);
      const rhyme = getStructureOfRhyme(syllable.rhyme);
      const temp = {
        ...syllable,
        rhymeObj: rhyme,
      };
      result.push(temp);
    };
  });
  return result;
};
export const SQUARE_TEXT_LIMIT = [
  {
    name: SQUARE_OBJECT_TYPE.ELEMENTS_IN_WORD,
    limit: 25
  },
  {
    name: SQUARE_OBJECT_TYPE.WORD_IN_SENTENCE,
    limit: 30
  },
  {
    name: SQUARE_OBJECT_TYPE.TONE_OF_WORD,
    limit: 30
  },
  {
    name: SQUARE_OBJECT_TYPE.ELEMENTS_OF_WORD,
    limit: 25
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_OF_SYLLABLE,
    limit: 15
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_IN_SYLLABLE,
    limit: 15
  },
]
export const SQUARE_TYPES_CONSTANTS = [
  {
    name: SQUARE_OBJECT_TYPE.WORD_IN_SENTENCE,
    title: 'Tiếng trong câu',
    info: 'Mỗi ô vuông sẽ biểu thị một từ trong một câu.',
    handleTextToObject: handleElementInWord,
  },
    {
    name: SQUARE_OBJECT_TYPE.TONE_OF_WORD,
    title: 'Thanh điệu của tiếng',
    info: 'Một ô vuông hiển thị một từ kèm thanh điệu của từ đó',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.ELEMENTS_OF_WORD,
    title: 'Thành phần của tiếng (có thanh điệu)',
    info: 'Một ô vuông hiển thị các thành phần của tiếng',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.ELEMENTS_IN_WORD,
    title: 'Thành phần trong tiếng (không thanh điệu)',
    info: 'Mỗi ô vuông sẽ biểu thị một âm hoặc vần của 1 tiếng.',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_OF_SYLLABLE,
    title: 'Âm vị của tiếng (có thanh điệu)',
    info: 'Một ô vuông hiển thị các thành phần và âm vị của tiếng',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_IN_SYLLABLE,
    title: 'Âm vị trong tiếng (không thanh điệu)',
    info: 'Một ô vuông hiển thị các thành phần và âm vị của tiếng',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_OF_RHYME,
    title: 'Thành phần của vần (có thanh điệu)',
    info: 'Một ô vuông hiển thị các thành phần của vần',
    handleTextToObject: handleElementInWord,
  },
  {
    name: SQUARE_OBJECT_TYPE.PHONEME_IN_RHYME,
    title: 'Thành phần trong vần (không thanh điệu)',
    info: 'Một ô vuông hiển thị các thành phần của vần',
    handleTextToObject: handleElementInWord,
  },
];

export const TONES_CONSTANTS = [
  {
    name: 'level',
    info: 'ngang',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
      </svg>
    ),
  },
  {
    name: 'falling',
    info: 'huyền',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
        <line
          x1="10.049"
          y1="2.45096"
          x2="21.0064"
          y2="8.7772"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    name: 'rising',
    info: 'sắc',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
        <line
          x1="21.451"
          y1="3.04904"
          x2="10.4936"
          y2="9.37528"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    name: 'curve',
    info: 'hỏi',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.7673 10C13.7841 9.22755 14.0105 8.5 14.4465 7.81737L14.9497 7.06287C15.4193 6.34431 15.6541 5.80539 15.6541 5.44611C15.6541 4.56587 15.0839 4.12575 13.9434 4.12575C13.4235 4.12575 12.7862 4.26048 12.0314 4.52994L11 1.72754C12.2243 1.24251 13.3564 1 14.3962 1C15.805 1 16.9203 1.34132 17.7421 2.02395C18.5807 2.68862 19 3.58683 19 4.71856C19 5.5988 18.7317 6.48802 18.195 7.38623L17.3899 8.73353C17.1551 9.12874 17.0377 9.5509 17.0377 10H13.7673Z"
          fill="black"
        />
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
      </svg>
    ),
  },
  {
    name: 'broken',
    info: 'ngã',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 4.52683C20.8995 5.63252 20.5548 6.47805 19.9659 7.06341C19.377 7.63577 18.5655 7.92195 17.5314 7.92195C16.7702 7.92195 15.7792 7.66829 14.5583 7.16098C13.6104 6.75772 12.9713 6.5561 12.6409 6.5561C12.2962 6.5561 12.0377 6.66016 11.8653 6.86829C11.7074 7.06341 11.5781 7.44065 11.4776 8L9 7.45366C9.1149 6.4 9.48115 5.57398 10.0987 4.97561C10.7307 4.36423 11.5278 4.05854 12.4901 4.05854C13.1652 4.05854 14.1921 4.34472 15.5709 4.91707C16.4039 5.25528 17.0215 5.42439 17.4237 5.42439C17.7971 5.42439 18.0485 5.32683 18.1777 5.13171C18.3214 4.93659 18.4219 4.55935 18.4794 4L21 4.52683Z"
          fill="black"
        />
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
      </svg>
    ),
  },
  {
    name: 'drop',
    info: 'nặng',
    component: () => (
      <svg width="30" height="58" viewBox="0 0 30 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="16.5" width="27" height="27" rx="3.5" stroke="black" strokeWidth="3" strokeLinecap="round" />
        <circle cx="15" cy="50" r="2" fill="black" />
      </svg>
    ),
  },
];


export const getSquareUI = (
  type,
  index,
  syllableContent,
  rhymeContent,
  consonant,
  tone,
  startRhyme,
  mainRhyme,
  finishRhyme,
  previewLP
) => {
  let squareUI = null;
  if (type === SQUARE_OBJECT_TYPE.WORD_IN_SENTENCE)
    return <WordInSentence
      key={`WIS-${index}`}
      syllable={syllableContent.text}
      index={index}
      previewLP={previewLP}
    />
  if (type === SQUARE_OBJECT_TYPE.TONE_OF_WORD)
    return <ToneOfWord 
      key={`TOW-${index}`}
      syllable={syllableContent.text}
      tone={tone}
      index={index}
      previewLP={previewLP}
    />
  if (type === SQUARE_OBJECT_TYPE.ELEMENTS_OF_WORD)
    return SquareContainerInterface({
      className: 'elements-of-word',
      squareText: syllableContent.text,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: ElementOfWordChildren,
      previewLP
    });
  if (type === SQUARE_OBJECT_TYPE.ELEMENTS_IN_WORD)
    return SquareContainerInterface({
      className: 'elements-in-word',
      squareText: syllableContent.text,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: ElementInWordChildren,
      previewLP
    });
  if (type === SQUARE_OBJECT_TYPE.PHONEME_OF_SYLLABLE)
    return SquareContainerInterface({
      className: 'phoneme-of-syllable',
      squareText: syllableContent.text,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: PhonemeOfRhymeChildren,
      previewLP
    });
  if (type === SQUARE_OBJECT_TYPE.PHONEME_IN_SYLLABLE)
    return SquareContainerInterface({
      className: 'phoneme-in-syllable',
      squareText: syllableContent.text,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: PhonemeInRhymeChildren,
      previewLP
    });
  if (type === SQUARE_OBJECT_TYPE.PHONEME_IN_RHYME)
    return SquareContainerInterface({
      className: 'phoneme-of-rhyme',
      squareText: `${syllableContent.text} - ${rhymeContent.text}`,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: PhonemeOfRhymeChildren,
      hiddenConsonant: true,
      previewLP
    });
  if (type === SQUARE_OBJECT_TYPE.PHONEME_OF_RHYME)
    return SquareContainerInterface({
      className: 'phoneme-in-rhyme',
      squareText: `${syllableContent.text} - ${rhymeContent.text}`,
      consonant,
      rhyme: rhymeContent.text,
      startRhyme,
      mainRhyme,
      finishRhyme,
      tone,
      index,
      rhymeChildren: PhonemeInRhymeChildren,
      hiddenConsonant: true,
      previewLP
    });
  return squareUI;
}