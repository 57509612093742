import React, { memo } from 'react'
import { Form, FormGroup, Label } from 'reactstrap';
import * as yup from 'yup'

// components
import CollectionCategorySelectField from '../../../../../components/award-admin/CollectionCategorySelectField';
import InputField from '../../../../../components/Common/InputField';
import InputImageField from '../../../../../components/Common/InputImageField';
import InputSoundField from '../../../../../components/Common/InputSoundField';

// local
import CollectionTemplateFormCtrl from './CollectionTemplateForm.ctrl';

// form validation schema
export const formValidationSchema = yup.object().shape({
  collectionCategoryIds: yup
    .array()
    .of(yup.string())
    .min(1, 'Bạn cần chọn thể  loại áp dụng')
    .label('Thể loại áp dụng'),
  previewBgImageUrl: yup.string().required('Bạn cần upload một ảnh').label('Ảnh'),
  previewBgSoundUrl: yup.string().required('Bạn cần upload một file nhạc').label('Nhạc'),
})

const CollectionTemplateForm = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // collection categories
    setCollectionCategories,
    // preview bg image url
    previewBgImageUrl,
    setPreviewBgImageUrl,
    // preview bg sound url
    previewBgSoundUrl,
    setPreviewBgSoundUrl,
  } = CollectionTemplateFormCtrl.useContainer()

  return (
    <Form onSubmit={() => { }}>
      {/* applied collection categories */}
      <FormGroup>
        <Label htmlFor="collectionCategoryId">Thể  loại áp dụng</Label>
        <CollectionCategorySelectField
          name="collectionCategoryIds"
          id="collectionCategoryIds"
          shouldFetchOnMount={true}
          selectProps={{
            placeholder: 'Chọn thể loại áp dụng',
            isMulti: true,
            isClearable: true,
            onChange: (_, { collectionCategories }) => {
              setCollectionCategories(collectionCategories)
            },
          }}
        />
        <InputField
          className="d-none"
          name="collectionCategories"
          id="collectionCategories"
        />
      </FormGroup>

      {/* bg image */}
      <FormGroup>
        <Label>Ảnh nền</Label>
        <InputImageField
          name="bgImage"
          id="bgImage"
          accept="image/*"
          previewImageUrlName="previewBgImageUrl"
          previewImageUrl={previewBgImageUrl}
          setPreviewImageUrl={setPreviewBgImageUrl}
        />
      </FormGroup>

      {/* bg sound */}
      <FormGroup>
        <Label>Nhạc nền</Label>
        <InputSoundField
          name="bgSound"
          id="bgSound"
          accept="audio/*"
          previewSoundUrlName="previewBgSoundUrl"
          previewSoundUrl={previewBgSoundUrl}
          setPreviewSoundUrl={setPreviewBgSoundUrl}
        />
      </FormGroup>
    </Form>
  );
});

export default () => (
  <CollectionTemplateFormCtrl.Provider>
    <CollectionTemplateForm />
  </CollectionTemplateFormCtrl.Provider>
);