import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';

class InputTitleQuiz extends React.Component {
  render() {
    const { currentSlide, learningPoints, currentSlidePreview, id } = this.props;
    const learningCurrent =
      currentSlidePreview || learningPoints.find((learning) => learning.id === currentSlide) || {};
    return (
      <div id={id} style={{ padding: '0 20px 20px 20px', marginTop: '10px' }}>
        <input
          style={{
            border: 'none',
            width: 'calc( 100% - 20px)',
            fontWeight: 'bold',
            background: '#233B98',
            padding: '6px 10px 6px 10px',
            borderRadius: '5px',
            color: '#FFF',
          }}
          value={learningCurrent.title || ''}
          placeholder="Nhập tiêu đề"
          className="next-input"
          onChange={(e) => this.props.changeQuiz('title', e.target.value, 'quiz', currentSlide)}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuiz })(InputTitleQuiz);
