// This one use for reference from back-end
export const USER_ROLES = {
  ROLE_ADMIN: 1,
  ROLE_TEACHER: 2,
  ROLE_STUDENT: 3,
};

export const USER_ROLES_NAME = {
  1: 'Admin',
  2: 'Giáo viên',
  3: 'Học sinh',
};

export const COURSE_STATUS = {
  PENDING: 'pending',
  RELEASED: 'released',
};

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  TEXT: 'text',
  SQUARE: 'square',
};

export const ALIGNMENT = {
  TOP_LEFT: 'top_left',
  TOP_CENTER: 'top_center',
  TOP_RIGHT: 'top_right',
  CENTER_LEFT: 'center_left',
  CENTER: 'center',
  CENTER_RIGHT: 'center_right',
  BOTTOM_LEFT: 'bottom_left',
  BOTTOM_CENTER: 'bottom_center',
  BOTTOM_RIGHT: 'bottom_right',
};

export const CUSTOM_LAYOUT_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const TEXT_FORMAT = {
  THO_LUC_BAT: 'tho_luc_bat',
  DOAN_VAN_THUONG: 'doan_van_thuong',
};

export const REALISED_ACOUST_PARAM_TYPE = {
  PARAGRAPH: 'paragraph',
  SENTENCE: 'sentence',
  PHRASE: 'phrase',
  WORD: 'word',
  MTU: 'mtu',
  BOUNDARY: 'boundary',
  SYLLABLE: 'syllable',
  PHONEME: 'phoneme',
};

export const SQUARE_TEXT_POSITION = {
  // text nằm trên ô vuông
  TOP: 'top',

  // text nằm dưới ô vuông
  BOTTOM: 'bottom',

  // text nằm trong ô vuông
  INSIDE: 'inside',
};

export const SQUARE_VISIBILITY = {
  // hiển thị
  SHOW: 'show',

  // ẩn
  HIDDEN: 'hidden',
};

export const QUIZ_TYPE = {
  MC_QUIZ: 'mc_quiz',
  PAIR_QUIZ: 'pair_quiz',
  BLANK_QUIZ: 'blank_quiz',
};

export const QUIZ_LEVEL = {
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard',
};

export const LEARNING_POINT_TYPE = {
  THEORY: 'theory',
  QUIZ: 'quiz',
  CHECKPOINT: 'checkpoint'
};

export const SECTION_TYPE = {
  LESSON: 'lesson',
  TEST: 'test',
};

export const getVoiceLabel = (voiceCode) => {
  switch (voiceCode) {
    case 'ngochuyen':
      return 'Ngọc Huyền';

    case 'huonggiang':
      return 'Hương Giang';

    default:
      return 'Chọn giọng nói';
  }
};

export const voices = [
  { speaker: 'ngochuyen', label: 'Ngọc Huyền' },
  { speaker: 'huonggiang', label: 'Hương Giang' },
];
