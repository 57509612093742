import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import PopoverCustom from '../../components/Popover/PopoverCustom';
import { IconCheck } from '../../icons/IconSvg';
import { addSlideQuiz, deleteSlide } from '../../reducers/lesson';

class SelectQuiz extends React.Component {
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };
  selectQuiz = (quiz) => {
    if (this.checkQuizSelected(quiz)) {
      this.props.deleteSlide(quiz);
    } else {
      this.props.addSlideQuiz(quiz);
    }
  };
  checkQuizSelected = (learningPoint) => {
    const { oldLearningPoints } = this.props;
    return oldLearningPoints.map((lrn) => lrn.id).includes(learningPoint.id);
  };

  render() {
    const { learningPoints, oldLearningPoints, learningPointsSearch, query } = this.props;
    const { popoverOpen } = this.state;
    return (
      <div>
        <button
          onClick={this.toggle}
          style={{ border: '1px solid #c2c2c2', width: '100%' }}
          id="add_quiz"
          className="btn"
        >
          Chọn bài tập
        </button>
        <PopoverCustom maxHeight="400px" width="300px" id="add_quiz" toggle={this.toggle} popoverOpen={popoverOpen}>
          <Input
            value={query}
            onChange={(e) => this.props.handleChange(e)}
            type="text"
            name="title"
            id="Title"
            placeholder="Tìm kiếm bài học"
          />
          {learningPointsSearch.length > 0 ? (
            learningPointsSearch.map((learningPoint) => (
              <div onClick={() => this.selectQuiz(learningPoint)} key={learningPoint.id} className="div_item fl">
                {this.checkQuizSelected(learningPoint) ? (
                  <div style={{ marginRight: '5px' }}>
                    <IconCheck />
                  </div>
                ) : null}
                <div>
                  <div>{learningPoint.title || ''}</div>
                  <div className="text-guid">{learningPoint.question || ''}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center pd-20">Không có kết quả phù hợp</div>
          )}
        </PopoverCustom>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  oldLearningPoints: state.lesson.localState.learningPoints,
});
export default connect(mapStateToProps, { addSlideQuiz, deleteSlide })(SelectQuiz);
