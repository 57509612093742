import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { CustomInput } from 'reactstrap';

// constants
import {
  LUCKY_BOX_STATUSES,
  LUCKY_BOX_STATUS_COLOR_SUFFIX_CLASS,
  LUCKY_BOX_STATUS_NAME,
} from '../../../../constants/award-admin/luckyBoxStatus';
import { LUCKY_BOX_TYPES } from '../../../../constants/award-admin/luckyBoxType';

// components
import VoiceSpeaker from '../../../../components/Lesson-v2/UITemplate/Overview/common/voiceSpeaker.jsx';

// utils
import { formatDate } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/number';

// local
import LuckyBoxDataTableCtrl from './LuckyBoxDataTable.ctrl';

const RowData = ({ className, id }) => {
  // -- ctrl --
  const {
    // handle change status
    handleChangeStatus,
    // modal
    setIsModalOpen,
    setModalItemId,
    // generate more modal
    setIsGenerateMoreModalOpen,
    setGenerateMoreModalItemId,
  } = LuckyBoxDataTableCtrl.useContainer();

  // -- lucky box --
  const { type, imageUrl, status, name, nameVoiceUrl, availableQuantity, publishQuantity, price, expiredAt } =
    useSelector((state) => state.luckyBoxes.data[id]) || {};

  // -- handle edit --
  const handleEdit = useCallback(() => {
    setModalItemId(id);
    setIsModalOpen(true);

    // temporarily set generate more modal item id
    setGenerateMoreModalItemId(id);
  }, []);

  return (
    <tr className={cls('', { 'bg-light': type === LUCKY_BOX_TYPES.DEFAULT })}>
      {/* status */}
      <td className="text-center !t-align-middle">
        {status !== LUCKY_BOX_STATUSES.OUT_OF_STOCK && (
          <CustomInput
            id={`${id}-status`}
            className={`custom-switch-${LUCKY_BOX_STATUS_COLOR_SUFFIX_CLASS[status]} t-cursor-pointer`}
            type="switch"
            checked={status === LUCKY_BOX_STATUSES.ACTIVE}
            onChange={(e) =>
              handleChangeStatus({
                id,
                type,
                newStatus: e.target.checked ? LUCKY_BOX_STATUSES.ACTIVE : LUCKY_BOX_STATUSES.INACTIVE,
              })
            }
          />
        )}

        <p className={`mb-0 text-${LUCKY_BOX_STATUS_COLOR_SUFFIX_CLASS[status]}`}>{LUCKY_BOX_STATUS_NAME[status]}</p>
      </td>

      {/* image */}
      <td className="t-w-[150px]" style={{ verticalAlign: 'middle' }}>
        <img className="t-w-[150px] t-h-[150px] t-object-contain" src={imageUrl} />
      </td>

      {/* name */}
      <td className="text-center !t-align-middle">
        <div className="t-flex">
          {nameVoiceUrl && <VoiceSpeaker audioUrl={nameVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{name}</span>
        </div>
      </td>

      {/* price */}
      <td className="text-center !t-align-middle">{`⭐ ${formatNumber(price)}` || 'Chưa có giá'}</td>

      {/* availableQuantity/publishQuantity */}
      <td className="text-center !t-align-middle">
        {availableQuantity}/{publishQuantity}
        <AiFillPlusCircle
          color="#007bff"
          className="t-cursor-pointer ml-1 t-mt-[-0.25rem]"
          onClick={() => {
            setGenerateMoreModalItemId(id);
            setIsGenerateMoreModalOpen(true);
          }}
          title="Tạo thêm"
        />
      </td>

      {/* expiredAt */}
      <td className="text-center !t-align-middle">
        {type !== LUCKY_BOX_TYPES.DEFAULT && expiredAt && formatDate(expiredAt)}
      </td>

      {/* actions */}
      <td className="text-center !t-align-middle">
        <BiEdit className="t-cursor-pointer" onClick={handleEdit} />
      </td>
    </tr>
  );
};

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData);
