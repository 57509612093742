import { getTests } from '../apis/test';
export const TYPE_COURSE = 'course';
export const TYPE_CHAPTER = 'chapter';
export const TYPE_RANDOM = 'random';
export const TYPE_STATIC = 'static';
export const dataFix = {
  base64: '',
  title: '',
  fileThumb: null,
  fileImage: null,
  thumbnailImageUrl: '',
  imageUrl: '',
  quizIds: [],
  chapters: [],
  testType: TYPE_STATIC,
  testScope: 'course',
  chapterId: '',
  numberOfMcQuizzes: null,
  numberOfDragAndDropQuizzes: null,
  numberOfMatchThePairQuizzes: null,
  numberOfWordsInSentenceQuizzes: null,
  numberOfToneOfWordQuizzes: null,
  numberOfElementInWordQuizzes: null,
};
export const objTest = dataFix;

const initState = {
  dataTest: objTest,
  data: [],
  syncing: true
};
const ActionTypes = {
  CHANGE_TEST: '@@test/CHANGE_TEST',
  GET_TESTS: '@@test/GET_TESTS',
  SET_SYNCING: '@@test/SET_SYNCING',
};

export const fetchTestAction = () => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_SYNCING, value: true });
  try {
    const res = await getTests();
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: '@@test/GET_TESTS',
      data: res.result.tests.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    });
  } catch (error) {
    //console.error(error);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, value: false });
};

export const chageTest = (data) => ({ type: ActionTypes.CHANGE_TEST, data });
const test = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_TEST:
      return {
        ...state,
        dataTest: action.data
      };
    case ActionTypes.SET_SYNCING:
      return {
        ...state,
        syncing: action.value,
      };
    case ActionTypes.GET_TESTS:
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
};

export default test;
