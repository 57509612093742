import apiV2 from '../apiV2';

export const getAll = async ({
  page = 1,
  limit = 10,
  keyword,
  sort = { createdAt: -1 },
  collectionCategoryId,
  status,
  rarities,
  shouldPopulateCollectionCategory,
  includeIds,
}) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionItem/all`,
    data: {
      page,
      limit,
      keyword,
      sort,
      collectionCategoryId,
      status,
      rarities,
      shouldPopulateCollectionCategory,
      includeIds,
    }
  });
}

export const getOne = async ({ _id }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionItem/one`,
    data: { _id }
  });
}

export const upsert = async (dataToUpdate) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionItem/upsert`,
    data: dataToUpdate
  });
}