import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BiEdit } from 'react-icons/bi';

// components
import VoiceSpeaker from '../../../../components/Lesson-v2/UITemplate/Overview/common/voiceSpeaker.jsx';

// local
import CollectionCategoryDataTableCtrl from './CollectionCategoryDataTable.ctrl';

const RowData = ({ className, id }) => {
  // -- ctrl --
  const {
    // modal
    setIsModalOpen,
    setModalItemId,
  } = CollectionCategoryDataTableCtrl.useContainer();

  // -- collection category --
  const {
    imageUrl,
    name,
    nameVoiceUrl,
    description,
    descriptionVoiceUrl,
    availableItemsQuantity = 0,
    totalItemsQuantity = 0,
  } = useSelector((state) => state.collectionCategories.data[id]) || {};

  // -- handle edit --
  const handleEdit = useCallback(() => {
    setModalItemId(id);
    setIsModalOpen(true);
  }, []);

  return (
    <tr>
      {/* image */}
      <td className="t-w-[150px]" style={{ verticalAlign: 'middle' }}>
        <img className="t-w-[150px] t-h-[150px] t-object-contain" src={imageUrl} />
      </td>

      {/* name */}
      <td className="text-center !t-align-middle">
        <div className="t-flex">
          {nameVoiceUrl && <VoiceSpeaker audioUrl={nameVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{name}</span>
        </div>
      </td>

      {/* description */}
      <td className="!t-align-middle">
        <div className="t-flex">
          {descriptionVoiceUrl && <VoiceSpeaker audioUrl={descriptionVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{description}</span>
        </div>
      </td>

      {/* category's items quantity */}
      {/* <td className="t-w-[200px] text-center !t-align-middle">
        {availableItemsQuantity}/{totalItemsQuantity}
      </td> */}

      {/* actions */}
      <td className="text-center !t-align-middle">
        <BiEdit className="t-cursor-pointer" onClick={handleEdit} />
      </td>
    </tr>
  );
};

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData);
