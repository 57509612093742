import React, { useState } from 'react';
import './style.scss';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../utils/date';
import { getFrameContentStatus } from '../../../utils/publishManagement';
import { getPathStatus } from '../../../utils/publishManagement';
import TablePagination from '../../../containers/Path/PathDetails/TablePagination';

export const RequestList = ({ data }) => {
  const { syncing: courseSyncing } = useSelector(state => state.publishManagement);
  const { syncing: pathSyncing } = useSelector(state => state.publishPath)
  const location = useLocation()
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)

  const publishType = location.pathname.includes('path') ? 'path' : 'course'
  const syncing = location.pathname === 'path' ? pathSyncing : courseSyncing

  const RowItem = (item, index) => {
    return (
      <>
        <tr className={`request-list-row row-${index % 2 === 0 ? 'even' : 'odd'}`} key={`row-${index}`}>
          <td className={`col-index`}>{index + 1}</td>
          <td className={`col-date`}>{publishType === 'path' ? formatDate(item.updatedAt) : formatDate(item.statusTime)}</td>
          <td className={`col-course-name`} data-tip data-for={`course-name-${index}`}>
            {item.title}
            <ReactTooltip id={`course-name ${index}`}>{item.title}</ReactTooltip>
          </td>
          <td className="col-description" data-tip data-for={`description-${index}`}>
            {item.description}
            <ReactTooltip className="tooltip" id={`description-${index}`}>
              {item.description}
            </ReactTooltip>
          </td>
          <td className="col-creditor">{item?.createdBy?.username || '--'}</td>
          <td className="col-status">{publishType === 'path' ? getPathStatus(item.status) : getFrameContentStatus(item.status)}</td>
          {item.status === 'DRAFT' ? (<td
            className="col-action"
          >
          </td>) : (<td
            className="col-action"
            onClick={() => {
              if(publishType === 'path') history.push(`/publish/path/${item.id}`)
              else history.push(`/publish/frame-content/course/${item.id}`);
            }}
          >
            Đánh giá
          </td>)}
        </tr>
        <tr className="tr-space"></tr>
      </>
    );
  };

  return (
    <div className="request-list">
      <table className="request-list-table">
        <thead>
          <tr className="title">
            <th className="title-index">STT</th>
            <th className="title-date">{publishType === 'path' ? 'Ngày cập nhật': 'Ngày nộp'}</th>
            <th className="title-course-name">Tên lộ trình học</th>
            <th className="title-description">Mô tả</th>
            <th className="title-creditor">Người tạo</th>
            <th className="title-status">Trạng thái</th>
            <th className="title-action">Hành động</th>
          </tr>
        </thead>
        <tbody>
          <tr className="tr-space"></tr>
          {!syncing && data && data.slice(10 * (currentPage - 1), 10 * currentPage).map((item, index) => RowItem(item, index))}
          {syncing && (
            <>
              <tr className="tr-space"></tr>
              <tr className="tr-space"></tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: 'center' }}>Đang tải dữ liệu ...</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {!syncing && data && data.length > 10 && (<TablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={data}/>)}
    </div>
  );
};
