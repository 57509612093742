import apiV2 from './apiV2';

export const deleteFile = async ({ fileAccessLink }) =>
  apiV2({
    method: 'POST',
    url: `/file-storage/fileStorage/deleteOneFile`,
    data: { fileAccessLink },
  });

export const uploadFile = async (formData) =>
  apiV2({
    method: 'POST',
    url: `/file-storage/fileStorage/uploadOneFile`,
    data: formData,
  });

export const uploadUrl = async (formData) =>
  apiV2({
    method: 'POST',
    url: `/file-storage/fileStorage/uploadOneUrl`,
    data: formData,
  });
