import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Fade from '../../components/Common/Fade';
import { Link } from 'react-router-dom';

import Header from '../../components/Common/Header';
import Loading from '../../components/Common/Loading';
import { IconAdd } from '../../icons/IconSvg';
import { createCourseAction, fetchCourseAction } from '../../reducers/courses';
import Curriculum from './Curriculum';
import './style.scss';

const CourseContainer = () => {
  const courses = useSelector((state) => state.courses);
  const auth = useSelector((state) => state.auth);
  const roles = auth && auth.authData && auth.authData.realm_access && auth.authData.realm_access.roles ? auth.authData.realm_access.roles : [];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCourseAction());
  }, []);

  return (
    <>
      {courses.syncing && <Loading />}
      <Header />
      <div className="container">
        <div className="wrapper_content_course">
          <div className="text-chapter m-20 fs-30">Trang quản lý</div>
          <div className="ui-chapter_current">
            <div className="d-flex align-item-center justify-content-between">
              <div className="text-chapter fs-20 m-20">Các khóa học của bạn</div>
              {
                roles.includes("cms-admin") && (
                  <div className="m-20">
                    <Link to="/publish/request-list">Duyệt khóa học</Link>
                  </div>
                )
              }
            </div>
            <div className="list_chapter_limit">
              <NewCourse />
              {courses.data.map((data) => (
                <div className="wrapper_curri" key={data.id}>
                  <Curriculum data={data} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewCourse = () => {
  const [open, setopen] = useState(false);
  const addCourse = () => setopen(true);
  return (
    <div className="wrapper_curri">
      <div className="component__curri_create component__curri">
        <div className="div__curri fl flex-column h-100 align-items-center">
          <div className="flex-grow-1" />
          <div aria-hidden="true" className="cursor fl flex-column align-items-center" onClick={addCourse}>
            <IconAdd />
            <div className="text-bold text-link fs-20">Tạo khóa học mới</div>
          </div>
          <div className="flex-grow-1" />
          <div className="splitter" />
        </div>
      </div>
      {open && (<NewCourseForm open={open} toggle={() => setopen(!open)} />)}
    </div>
  );
};

const NewCourseForm = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const submitHandler = async (data) => {
    const { title, description, thumbnail} = data;
    dispatch(createCourseAction({ title, description, file: thumbnail[0], type}));
    toggle();
  };
  const [type, setType] = useState('PUBLIC');

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader style={{ fontSize: 20 }}>Tạo khóa học mới</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <FormGroup>
            <Label htmlFor="Title">Tên khóa học</Label>
            <Input
              type="text"
              name="title"
              id="Title"
              placeholder="Tên khóa học này..."
              innerRef={register({ required: { value: true, message: 'Bạn cần điền tên của khóa học' } })}
            />
            {errors.title && <FormText color="danger">{errors.title.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Description">Miêu tả</Label>
            <Input
              type="textarea"
              name="description"
              id="Description"
              placeholder="Miêu tả về khóa học này..."
              innerRef={register({
                required: { value: true, message: 'Bạn cần điền một miêu tả ngắn về khóa học' },
              })}
            />
            {errors.description && <FormText color="danger">{errors.description.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label>Phạm vi khóa học</Label>
            <br/>
            <Label style={{marginLeft: '40px'}}>
              <Input 
                type="radio"  
                name="publicRadio" 
                id="typeCourse public"
                checked={type === 'PUBLIC' ? true : false}
                onChange={(e) => setType('PUBLIC')}
              />{' '}Công khai
            </Label>
            <Label style={{marginLeft: '80px'}}>
              <Input 
                type="radio"  
                name="protectRadio" 
                id="typeCourse protect"
                checked={type === 'PROTECT' ? true : false}
                onChange={(e) => setType('PROTECT') }
              />{' '}Giới hạn
            </Label>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="File">Ảnh Thumbnail</Label>
            <Input
              type="file"
              name="thumbnail"
              id="File"
              innerRef={register({ required: { value: true, message: 'Bạn cần upload một ảnh thumbnail' } })}
            />
            <FormText color="muted">Thumbnail là ảnh đại diện thu nhỏ của khóa học</FormText>
            {errors.thumbnail && <FormText color="danger">{errors.thumbnail.message}</FormText>}
          </FormGroup>

          <Button>Tạo khóa học mới</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CourseContainer;
