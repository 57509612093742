import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconAdd, IconDelete, IconMove } from '../../icons/IconSvg';
import ModalDelete from './ModalDelete';
import { deleteCourseAction, createActionContent, moveSection, updateCourseProfileAction, deleteActionContent } from '../../reducers/courses';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import ModalCreateTypeContent from './ModalCreateTypeContent';
import Photo from '../Common/Photo';
import PopoverCustom from '../Popover/PopoverCustom';
import { useHistory } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip';
import ModalChooseLesson from '../../containers/CourseDetails/ModalChooseLesson'
import ModalChooseTest from '../../containers/CourseDetails/ModalChooseTest'
import EmptyState from '../../assets/images/empty_state.png'
const listTypeContent = [
  {
    id: 'lesson',
    name: 'Tạo mới bài học',
  },
  {
    id: 'test',
    name: 'Tạo mới bài kiểm tra',
  },
  {
    id: 'lesson-choose',
    name: 'Chọn bài học đã tạo'
  },
  {
    id: 'test-choose',
    name: 'Chọn bài kiểm tra đã tạo'
  }
];
const ChapterRight = ({ course }) => {
  const [isOpenDeleteLesson, setIsOpenDeleteLesson] = useState(false);
  const [isOpenDeleteCourse, setIsOpenDeleteCourse] = useState(false)
  const [isOpenCreateContent, setIsOpenCreateContent] = useState(false)
  const [selectedItem, setSelectedItem] = useState('');
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [typeContent, setTypeContent] = useState()
  const [openChooseLesson, setOpenChooseLesson] = useState(false)
  const [openChooseTest, setOpenChooseTest] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch()

  const toggleCreateContent = () => {
    setIsOpenCreateContent(!isOpenCreateContent)
  };

  const toggleTypeContent = () => {
    setPopoverOpen(!popoverOpen);
  };

  const toggleDeleteLesson = () => {
    setIsOpenDeleteLesson(!isOpenDeleteLesson);
  };

  const okCreateContent = (content, course, typeContent) => {
    toggleCreateContent();
    dispatch(createActionContent(content, course, typeContent))
  };

  const deleteLesson = () => {
    if (selectedItem.type === 'test') {
      dispatch(deleteActionContent(selectedItem.id, course.id, 'test'))
    } else {
      dispatch(deleteActionContent(selectedItem.id, course.id, 'lesson'))
    }
    toggleDeleteLesson();
  };

  const onDragEnd = ({ draggableId, source, destination }) => {
    if(destination) {
      const result = [...course.sections];
      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);
      const updateData = {
        sections: result,
      };
      dispatch(moveSection(draggableId, source.index, destination.index, course.id));
      dispatch(updateCourseProfileAction(course.id, updateData));
    }
  };

  const onClickItem = (section) => {
    if (section.type === 'test') {
      window.open(`${window.location.origin}/test/${section.testId.id}`)
    } else {
      window.open(`${window.location.origin}/lesson/${section.lessonId.id}`)
    }
  };

  const okDelete = () => {
    dispatch(
      deleteCourseAction(course, () => {
        history.push('/course');
      }),
    );
  };

  const sections = course.sections || [];

  return (
    <div className="chapter__right">
      <div className="content">
        <div className="fl_b div_title">
          <div className="fl fs-30">
            <div className="text-bold">Danh sách nội dung học</div>
          </div>
          <div className="fl-icon">
            {/*<div style={{ margin: 'auto 40px' }}>
              <Link to="/question-bank">Ngân hàng câu hỏi</Link>
            </div>*/}
            <div
              className="icon-delete"
              data-tip="Xóa khóa học"
              data-for="delete-path"
              onClick={() => setIsOpenDeleteCourse(true)}
            >
              <BsTrash color="white" size={18} strokeWidth={0.5} />
            </div>
            <ReactTooltip id="delete-path" effect="solid" />
            <div id="add_type_chapter" className="icon-add" data-tip="Tạo bài học, bài kiểm tra">
              <IconAdd />
            </div>
          </div>
          <PopoverCustom width="250px" id="add_type_chapter" toggle={toggleTypeContent} popoverOpen={popoverOpen}>
            {listTypeContent.map((element, index) => {
              if (element.id === 'test') {
                return (
                  <div
                    key={index}
                    className="div_item"
                    onClick={() => {
                      setTypeContent('test')
                      toggleCreateContent();
                      toggleTypeContent();
                    }}
                  >
                    {element.name}
                  </div>
                );
              }
              else if(element.id === 'lesson') {
                return (
                  <div
                    key={element.id}
                    className="div_item"
                    onClick={() => {
                      setTypeContent('lesson')
                      toggleCreateContent();
                      toggleTypeContent();
                    }}
                  >
                    {element.name}
                  </div>
                );
              }
              else if(element.id === 'lesson-choose') {
                return (
                  <div
                    key={element.id}
                    className="div_item"
                    onClick={() => {
                      setOpenChooseLesson(true)
                      setPopoverOpen(false);
                    }}
                  >
                    {element.name}
                  </div>
                );
              }
              else {
                return (
                  <div
                    key={element.id}
                    className="div_item"
                    onClick={() => {
                      setOpenChooseTest(true)
                      setPopoverOpen(false);
                    }}
                  >
                    {element.name}
                  </div>
                );
              }
            })}
          </PopoverCustom>
        </div>
        {course.sections.length === 0 ? (
              <div className='empty-wrapper'>
                <img src={EmptyState}></img>
                <p>Khóa học hiện chưa có nội dung học nào!</p>
              </div>
            ) : (<DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`section-${course.id}`}>
            {(provided, _) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="div__scroll">
                {sections &&
                  sections.map((section, index) => {
                    const objType = section.type === 'test' ? section.testId || {} : section.lessonId || {};
                    return (
                      <Draggable key={section.id} draggableId={section.id} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} className="item_chapter">
                            <div className="fl_b">
                              <div className="fl content">
                                <div {...provided.dragHandleProps} className="icon-move">
                                  <IconMove />
                                </div>
                                <div className="img_chapter br-50">
                                  <Photo
                                    className="img_chapter"
                                    src={objType && objType.thumbnailImageUrl ? objType.thumbnailImageUrl : ''}
                                    onClick={() => onClickItem(section, course)}
                                    style={{ cursor: 'pointer'}}
                                  />
                                </div>
                                <div className="info_chapter">
                                  <a
                                    className="text-bold fs-20"
                                    aria-hidden="true"
                                    onClick={() => onClickItem(section)}
                                  >
                                    {objType.title || ''}
                                  </a>
                                  <div className="text-note fs-15" style={{ cursor: 'default' }}>
                                    Chỉnh sửa cuối cùng: {moment(objType.updatedAt || 0).format('DD/MM/YYYY')}
                                  </div>
                                  <div className="text-note fs-15" style={{ cursor: 'default' }}>
                                    {section.type === 'test' ? 'Bài kiểm tra' : 'Bài học'}
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={(e) => {
                                  setSelectedItem({ ...objType, type: section.type })
                                  toggleDeleteLesson()
                                }}
                                className="icon-right"
                              >
                                <IconDelete />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>)}
      </div>

      <ModalDelete
        isOpen={isOpenDeleteLesson}
        toggle={toggleDeleteLesson}
        titleT={selectedItem.type === 'test' ? `bài kiểm tra: ${selectedItem.title}` : `bài tập: ${selectedItem.title}`}
        title={`Xoá ${selectedItem.type === 'test' ? 'bài kiểm tra' : 'bài tập'}`}
        okDelete={deleteLesson}
      />
      <ModalCreateTypeContent
        isOpen={isOpenCreateContent}
        toggle={toggleCreateContent}
        typeContent={typeContent}
        okCreateContent={(content) => okCreateContent(content, course, typeContent)}
      />
      <ModalDeleteCourse
        isOpen={isOpenDeleteCourse}
        toggle={() => setIsOpenDeleteCourse(!isOpenDeleteCourse)}
        title={`Xóa khóa học`}
        titleT={course.title}
        okDelete={okDelete}
      />
      {openChooseLesson && (<ModalChooseLesson
        isOpen={openChooseLesson}
        toggle={() => setOpenChooseLesson(!openChooseLesson)}
        course={course}
      />)}
      {openChooseTest && (<ModalChooseTest
        isOpen={openChooseTest}
        toggle={() => setOpenChooseTest(!openChooseTest)}
        course={course}
      />)}
    </div>
  );
};

const ModalDeleteCourse = ({ isOpen, toggle, title, okDelete, titleT }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader style={{fontSize: 20}}>{title || ''}</ModalHeader>
      <ModalBody>
        <div>Bạn có chắc chắn muốn xóa khóa học: {titleT}?</div>
      </ModalBody>
      <ModalFooter>
        <button onClick={okDelete} style={{ float: 'right' }} className="btn btn-danger">
          Xác nhận xóa
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ChapterRight;
