import React from 'react';
import { FormGroup, Label, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../containers/CourseDetails/style.scss';
import { createActionChapter } from '../../../../reducers/chapter';
import { connect } from 'react-redux';
import { imageCompress, getFullUrl } from '../../../../utils/imageProcessing';
import { SlidesActions, getLesson } from '../../../../reducers/lesson';
import { saveLessonProfile } from '../../../../actions/ActionTheory';
import { IconPen } from '../../../../icons/IconSvg';
import IconClose from '../../../Lesson-v2/icons/close.svg';
import ProcessOverview from './common/process';
import { approveLesson } from '../../../../apis/publishManagement';
import { _approveLesson } from '../../../../apis/publishProcessLesson';
class ModalUpdateLesson_Step1 extends React.Component {
  state = {
    base64: '',
    title: '',
    thumbnailImageUrl: '',
    imageUrl: '',
    isClose: false,
  };

  async readImg(event, type) {
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      const compressedFile = await imageCompress(event.target.files[0]);
      if (type === 'img_avatar') {
        this.setState({ fileImg: compressedFile });
      } else {
        this.setState({ fileThumbImg: compressedFile });
      }
    }
  }

  onOpened = () => {
    const { localState } = this.props;
    this.setState({
      title: localState.title || '',
    });
  };

  onClickPublish = async () => {
    await _approveLesson({
      lessonId: this.props.localState.id,
      chapterId: this.props.idChapterActive,
      comment: 'approve',
    });
  }

  render() {
    const { localState, processFunction, openState,  } = this.props;
    return (
      <Modal
        isOpen={openState.step1}
        toggle={(e) => processFunction(e, 1)}
        onOpened={this.onOpened}
        style={{
          minWidth: '1200px',
        }}
      >
        <div className="modal-overview-header">
          <div style={{ marginLeft: '437px'}}>
            <IconPen /> Chỉnh sửa bài học
          </div>
          <div className="close_modal_update" onClick={(e) => this.props.processFunction(e, 1)}>
            <img src={IconClose} alt="icon_close" /> Đóng
          </div>
        </div>
        <ModalBody className="modal-body">
          <ProcessOverview processFunction={processFunction} openState={openState} />
          <div className="form-main">
            <div className="publish-lesson" onClick={this.onClickPublish}>Kiểm duyệt bài học</div>
            <FormGroup className="form-group process-step">
              <Label htmlFor="Title">Tên bài học</Label>
              <Input
                value={this.state.title || ''}
                onChange={(e) => this.setState({ title: e.target.value })}
                type="text"
                name="title"
                id="Title"
                placeholder="Nhập tên bài học"
                style={{
                  fontSize: '17px',
                  lineHeight: '1.3',
                }}
              />
            </FormGroup>
            <FormGroup className="form-group process-step">
              <div htmlFor="File">Ảnh avatar</div>
              <div className="text-guid">Ảnh avatar là ảnh bìa cho một bài học</div>
              <div className=" wapper__img_modal">
                <img src={getFullUrl(localState.imageUrl) || ''} id="img_avatar" />
              </div>
              <button
                style={{
                  backgroundColor: '#484848',
                  color: 'white',
                  fontSize: '18px',
                  lineHeight: '1.3',
                }}
                className="btn"
                onClick={() => {
                  if (this.dropzoneRefAvatar) {
                    this.dropzoneRefAvatar.click();
                  }
                }}
              >
                Tải ảnh đại diện
                <input
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={(e) => this.readImg(e, 'img_avatar')}
                  ref={(ref) => {
                    this.dropzoneRefAvatar = ref;
                  }}
                />
              </button>
            </FormGroup>
            <FormGroup className="form-group process-step">
              <div htmlFor="File">Ảnh Thumbnail</div>
              <div className="text-guid">Thumbnail là ảnh đại diện của một bài học</div>
              <div className=" wapper__img_modal">
                <img src={getFullUrl(localState.thumbnailImageUrl) || ''} id="img_thumbnail" />
              </div>
              <button
                style={{
                  backgroundColor: '#484848',
                  color: 'white',
                  fontSize: '18px',
                  lineHeight: '1.3',
                }}
                className="btn"
                onClick={() => {
                  if (this.dropzoneRefThumb) {
                    this.dropzoneRefThumb.click();
                  }
                }}
              >
                Tải ảnh bìa
                <input
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={(e) => this.readImg(e, 'img_thumbnail')}
                  ref={(ref) => {
                    this.dropzoneRefThumb = ref;
                  }}
                />
              </button>
            </FormGroup>
          </div>
          <div className="div_btn">
            <button
              onClick={(e) => {
                this.props.changeLesson('title', this.state.title);
                this.props.changeLesson('fileImg', this.state.fileImg || null);
                this.props.changeLesson('fileThumbImg', this.state.fileThumbImg || null);
                this.props.saveLessonProfile();
                // toggle();
                processFunction(e, 2);
              }}
              className="btn  next"
              style={{
                backgroundColor: '#233b98',
                color: 'white',
              }}
            >
              Lưu
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  localState: state.lesson.localState || {},
  idChapterActive: state.chapter.idChapterActive,
});
export default connect(mapStateToProps, {
  createActionChapter,
  changeLesson: SlidesActions.changeLesson,
  saveLessonProfile: saveLessonProfile,
})(ModalUpdateLesson_Step1);
