import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentOption from '../../Common/ComponentOption';
import { changeQuiz } from '../../../actions/ActionQuiz';
import OptionImage from '../../OptionQuiz/OptionImage';
import OptionVideo from '../../OptionQuiz/OptionVideo';
import { generateId } from '../../../utils/generate';

export default function OptionMediaQuestion({ learningCurrent, currentSlide }) {
  const dispatch = useDispatch();
  const [optionType, setOptionType] = useState('image');
  useEffect(() => {
    if (learningCurrent.questionVideoUrl || learningCurrent.typeQuestion === 'video') {
      setOptionType('video');
    }
  }, []);
  return (
    <div className="fl_b" style={{ width: '100%', background: '#f3f3f3' }}>
      <div className="fl pd-20">
        {(() => {
          if (optionType === 'video') {
            return (
              <OptionVideo
                url={learningCurrent.questionVideoUrl || ''}
                id={`quiz-video-question-${generateId()}`}
                handleChangeVideo={(value) => dispatch(changeQuiz('file', value, 'quiz', currentSlide))}
              />
            );
          }
          return (
            <OptionImage
              url={learningCurrent.questionImageUrl || ''}
              id={`quiz-img-question-${generateId()}`}
              handleChangeImage={(value) => dispatch(changeQuiz('file', value, 'quiz', currentSlide))}
            />
          );
        })()}
      </div>
      <div style={{ margin: 'auto 0', paddingRight: '60px' }} className="fl_b pd-20">
        <ComponentOption
          mediaQ="text"
          speak="square"
          type={(() => {
            if (optionType === 'video') {
              return 'video';
            }
            return 'image';
          })()}
          changeOption={(e) => {
            dispatch(changeQuiz('typeQuestion', e, 'quiz', currentSlide));
            setOptionType(e);
          }}
          style={{ marginRight: '30px' }}
        />
      </div>
    </div>
  );
}
