import React from 'react';
import { clearCustomLayout, updateMediaObject } from '../../reducers/lesson';
import { IconDelete, IconHiddenView, IconView } from '../../icons/IconSvg';
import PlayAudio from './TitleVoiceBox/playAudio';
import { useDispatch } from 'react-redux';
import { getFullUrl } from '../../utils/imageProcessing';

const _MediaObjectRight = ({mediaObject, customLayoutID, previewLP}) => {
    const { type, squareObject, hiddenText, audioUrl } = mediaObject;
    const _audioUrl  = getFullUrl(audioUrl)
    const dispatch = useDispatch();
    return (
    <div aria-hidden="true" className="top-right-icon" >
        {/* {type === 'square' && 
            <PlayAudio 
                audioUrl={previewLP ? null : _audioUrl}
                className="square-audio"
            />} */}
        {mediaObject.type === 'text' && mediaObject.text && (
            <>
            <div style={{height: '5px'}}></div>
            {
                !hiddenText 
                ?  (
                    <div className='view-text' onClick={() => dispatch(updateMediaObject(mediaObject.id, {hiddenText: !hiddenText}))}>
                    <IconView />
                    </div>
                    )
                : (
                    <div className='hidden-text' onClick={() => dispatch(updateMediaObject(mediaObject.id, {hiddenText: !hiddenText}))}>
                    <IconHiddenView />
                    </div>
                    )
            }
            <div style={{height: '10px'}}></div>
            </> )}
        <div className="delete" onClick={() => dispatch(clearCustomLayout(customLayoutID))}>
        <IconDelete />
        </div>
    </div>
    );
};

export const MediaObjectRight = React.memo(_MediaObjectRight);
 