import React from 'react';

export const InputSpeakingQuiz = (props) => {
    const { quizPart, audioUrl, changeQuizPart, index } = props;

    return (
      <>
        {/* <div style={{ paddingBottom: '10px' }}>
          <TestAudio audioUrl={audioUrl} />
        </div> */}
        <textarea
          value={quizPart.content.text}
          placeholder="Nhập câu"
          className="next-input border-input-none form__type"
          onChange={(e) => changeQuizPart(e.target.value, index)}
        />
      </>
    );
};
