import React, { useState, useMemo, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import Select from 'react-select';
import { useDebounceCallback } from '@react-hook/debounce';
import { createContainer } from 'unstated-next';

// components
import SelectField from '../Common/SelectField';

// reducers
import { getAllCollectionItems } from '../../reducers/award-admin/collectionItem';

const CollectionItemSelectField = ({
  name,
  id,
  defaultKeywordFieldName = '',
  shouldFetchOnMount = false,
  requestParams = {},
  selectProps,
  ...props
}) => {
  // -- form state --
  const { watch } = useFormContext();

  // -- select props --
  const { isMulti, onChange } = selectProps;

  // -- keyword --
  const [keyword, setKeyword] = useState(watch(defaultKeywordFieldName));

  // -- selected ids --
  const selectedIds = watch(name);

  // -- collection items --
  const { isLoading, data = {} } = useSelector((state) => state.collectionItems);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- generate options for select --
  const collectionItemOptions = useMemo(
    () =>
      _map(data, ({ id, name }) => ({
        label: name,
        value: id,
        id,
        name,
      })),
    [data],
  );

  // -- query --
  const query = useCallback(
    () =>
      dispatch(
        getAllCollectionItems({
          page: 1,
          limit: 10,
          keyword,
          ...(isMulti && {
            includeIds: selectedIds,
          }),
          ...requestParams,
        }),
      ),
    [keyword, isMulti, selectedIds, requestParams],
  );

  // -- refetch collection items on keyword change --
  const debounceGetCollectionItems = useDebounceCallback(() => query(), 300);
  useEffect(() => {
    if (keyword) {
      debounceGetCollectionItems();
    }
  }, [keyword]);

  // -- fetch on mount --
  useEffect(() => {
    if (shouldFetchOnMount) query();
  }, []);

  return (
    <SelectField
      name={name}
      id={name}
      {...props}
      options={collectionItemOptions}
      selectProps={{
        ...selectProps,
        isMulti,
        isLoading,
        onInputChange: (input, { action }) => {
          if (action === 'input-change') {
            debounceGetCollectionItems();
            setKeyword(input);
          }
        },
        onChange: (selected, defaultOnChange, { action }) => {
          if (onChange) {
            if (isMulti) {
              const selectedCollectionItems = _filter(collectionItemOptions, ({ value }) => selected.includes(value));

              onChange(selected, { action, collectionItems: selectedCollectionItems });
            } else {
              const selectedCollectionItem = _find(collectionItemOptions, { value: selected });

              onChange(selected, { action, collectionItem: selectedCollectionItem });
            }
          }

          defaultOnChange(selected);

          // clear
          if (action === 'clear') setKeyword('');
        },
        filterOption: () => true,
        onFocus: () => debounceGetCollectionItems(),
      }}
    />
  );
};
export default memo(CollectionItemSelectField);
