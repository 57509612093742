import api from './api';

export const getUsers = async () =>
  api({
    method: 'GET',
    url: '/users',
  });

export const getUser = async (userId) =>
  api({
    method: 'GET',
    url: `/users/${userId}`,
  });

export const deleteUser = async (userId) =>
  api({
    method: 'DELETE',
    url: `/users/${userId}`,
  });

export const createUser = async ({ name, email, password }) =>
  api({
    method: 'POST',
    url: `/users/`,
    data: {
      name,
      email,
      password,
    },
  });
