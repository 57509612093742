import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSoundUrl } from '../../apis/voice';
import { TroubleshootActionTypes } from '../../reducers/troubleshoot';
import './troubleshooting.style.scss';

export const Troubleshooting = () => {
  const history = useHistory();
  const troubleshoot = useSelector((state) => state.troubleshoot);
  const dispatch = useDispatch();
  const [isPathLesson, setIsPathLesson] = useState(false);

  const [currentPath, setcurrentPath] = useState('/');

  // const runTTS_test = (path) => {
  //   if (!path.includes('lesson')) return;
  //   getSoundUrl('ngochuyen', 'thử nghiệm').catch((error) => {
  //     dispatch({ type: TroubleshootActionTypes.SET_ERROR, data: error.message });
  //   });
  // };
  const callApiTTS = () => {
    getSoundUrl('ngochuyen', 'Kiểm tra xem api TTS').catch((error) => {
      dispatch({ type: TroubleshootActionTypes.SET_ERROR, data: error.message });
    });
  };
  useEffect(() => {
    setcurrentPath(history.location.pathname);
    // runTTS_test(history.location.pathname);
    if (location.pathname.includes('lesson')) setIsPathLesson(true);
    
    return history.listen((location) => {
      setcurrentPath(location.pathname);
      // runTTS_test(location.pathname);
      if (location.pathname.includes('lesson')) setIsPathLesson(true);
    });
  }, [history]);

  useEffect(() => {
    let timer;
    if (isPathLesson) timer = setTimeout(callApiTTS, 30 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [isPathLesson]);

  // Dont need to show this on landing-page
  if (!currentPath.includes('lesson')) return <></>;

  if (!troubleshoot.error)
    return (
      <div id="troubleshooting_ok">
        <b>Vietstudy CMS (Phiên bản alpha 0.0.1): Hệ thống đã sãn sàng 🥰</b>
      </div>
    );

  return (
    <div id="troubleshooting">
      <h3>🤕 Ôi không, hệ thống hiện đang gặp sự cố...</h3>
      {/* (Hiện tại, các thầy cô sẽ tạm thời chỉ xem được nôi dung bài học thôi nhé) <br />
      Chúng tôi sẽ cố gắng hết sức để khắc phục sự cố này. Cảm ơn các thầy cô vì đã thông cảm 🥰 */}
    </div>
  );
};
