import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useSlide = (slideId) => {
  const {
    localState: { learningPoints },
  } = useSelector((state) => state.lesson);
  const dispatch = useDispatch();

  // set timeout to reduce laggy
  const [localLPs, setlocalLPs] = useState({});

  const slide = learningPoints.find((learning) => learning.id === slideId);

  const updateSlide = (data) => {
    const newLPs = learningPoints.map((lp) => {
      if (lp.id === slideId) {
        return { ...lp, ...data };
      }
      return lp;
    });
    setlocalLPs(newLPs);
    // dispatch({ type: 'CHANGE_LEARNING_POINTS', learningPoints: newLPs });
  };

  return { slide, updateSlide };
};
