import React, { useState } from 'react';
import TestAudio from '../Quiz/common/TestAudio';
import './style.scss';

class OptionText extends React.Component {
  render() {
    const { handleChangeText, value, idOpt, audioUrl } = this.props;
    return (
      <div className="ui__option">
        <div className="ui__wraper_option">
          <div className="option__text">
            <div className="div__wraper">
              <div className="div__wraper_content">
                <div className="div__void">
                  <div>
                    <TestAudio audioUrl={audioUrl} />
                    <div className="">
                      <textarea
                        value={value}
                        onChange={(e) => handleChangeText(e.target.value)}
                        placeholder="Nhập nội dung vào đây ...."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionText;
