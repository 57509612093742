import React, {useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

import { IconPen, IconPlayArrow} from '../../../../icons/IconSvg';
import IconClose from '../../icons/close.svg';
import { Modal, ModalBody } from 'reactstrap';
import ProcessOverview from './common/process';
import { convertWelcome, generateWelcomeLesson } from './utils';
import { OverviewActions, saveOverview } from '../../../../actions/Overview';
import PopoverCustom from '../../../Popover/PopoverCustom';
import VoiceSpeaker from './common/voiceSpeaker';

import { TemplateList } from './common/templateList';
import { handleOverviewChange } from '../../../../actions/ActionCheckpoint';
import { deleteCheckpoint } from '../../../../apis/checkpoint';
import { PartTitles } from './common/partTitles';

const ModalUpdateLesson_Step3 = (props) => {
  const dispatch = useDispatch();
  const { localState, syncing, oldLocalState } = useSelector((state) => state.lesson);
  const { overview, learningPoints } = localState;
  const { learningPointPartTitles, template, welcome, learningPointParts } = overview;
  const speakerArray = [
    {
      speaker: 'ngochuyen',
      text: 'Nữ - Hà Nội - Ngọc Huyền',
    },
    {
      speaker: 'huonggiang',
      text: 'Nữ - Sài Gòn - Hương Giang',
    },
  ];
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [idCheckpoint, setIdCheckpoint] = useState([]);
  const handlePopover = () => {
    setPopoverOpen(!popoverOpen);
  };
  
  const { processFunction, openState } = props;
  return (
    <Modal isOpen={openState.step3} toggle={(e) => processFunction(e, 3)} style={{ minWidth: '1200px' }}>
      <div className="modal-overview-header">
        <div style={{ marginLeft: '437px' }}>
          <IconPen /> Chỉnh sửa bài học
        </div>
        <div className="close_modal_update" onClick={(e) => processFunction(e, 3)}>
          <img src={IconClose} alt="icon_close" /> Đóng
        </div>
      </div>
      <ModalBody className="modal-body">
        <ProcessOverview processFunction={processFunction} openState={openState} />
        <div className="main step3">
          <TemplateList />
          <PartTitles />
          <p style={{marginTop:"5px"}}>Giới thiệu các phần</p>
          <div className="voice-overview" style={{ alignSelf: 'flex-end' }}>
            <div id="popover-speaker-step3" onClick={handlePopover} style={{ cursor: 'pointer' }}>
              <IconPlayArrow />
              <PopoverCustom
                width="260px"
                maxHeight="120px"
                popoverOpen={popoverOpen}
                id="popover-speaker-step3"
                toggle={handlePopover}
              >
                {speakerArray.map((element, index) => {
                  return (
                    <div
                      aria-hidden="true"
                      key={`voice speaker step 3 ` + index}
                      className="div_item"
                      onClick={() => {            
                        dispatch(OverviewActions({
                          ...overview,
                          welcome: {
                            ...overview.welcome,
                            speaker: element.speaker
                          }
                        }))
                      }}
                    >
                      {element.text}
                    </div>
                  );
                })}
              </PopoverCustom>
            </div>
            <div className="speaker">
              {welcome.speaker
                ? speakerArray.find((element) => element.speaker === welcome.speaker).text
                : 'Chọn giọng đọc'}
            </div>
            <div>
              {welcome.speaker && welcome.text && (
                <VoiceSpeaker
                  localSpeaker={welcome.speaker}
                  text={welcome.text}
                  audioUrl={welcome.audioUrl}
                  type="welcomeOverview"
                />
              )}
            </div>
          </div>
          <div className="template_preview">
            <span>{learningPointPartTitles[0].title && learningPointPartTitles[1].title && welcome.text}</span>
            {(learningPointPartTitles[0].title === '' || learningPointPartTitles[1].title === '') && (
              <p style={{ color: '#C4C4C4' }}>
                Đây là phần preview cho Welcome screen và Giới thiệu bài học. Bạn cần nhập đủ nội dung ở phần "Nội dung
                chính".
              </p>
            )}
          </div>
        </div>
        <div className="div_btn">
          <button
            onClick={async () => {
              dispatch(handleOverviewChange({
                  newLearningPointPartTitles: learningPointPartTitles,
              }));
              const newIdCheckpoint = await Promise.all(idCheckpoint.map(async id => {
                  await deleteCheckpoint(id);
                  return null;
              }));
              setIdCheckpoint(newIdCheckpoint);
              dispatch(saveOverview(overview));
              }}
            className="btn next"
            style={{ backgroundColor: '#233b98', color: 'white' }}
          >
            {
              syncing ? "Đang xử lý" : "Lưu"
            }
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalUpdateLesson_Step3;
