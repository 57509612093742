import React, { memo, useMemo } from 'react';
import cls from 'classnames';
import { Form, FormGroup, Label } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BiHelpCircle } from 'react-icons/bi';
import _reduce from 'lodash/reduce';

// components
import InputField from '../../../../../components/Common/InputField';
import SelectField from '../../../../../components/Common/SelectField';
import InputNumberField from '../../../../../components/Common/InputNumberField';
import InputImageField from '../../../../../components/Common/InputImageField';
import CollectionCategorySelectField from '../../../../../components/award-admin/CollectionCategorySelectField';
import CollectionItemSelectField from '../../../../../components/award-admin/CollectionItemSelectField';
import InputDateField from '../../../../../components/Common/InputDateField';
import InputVoiceField from '../../../../../components/Common/InputVoiceField';

// constants
import {
  JS_FIELD_TO_COLLECTION_ITEM_RARITY_MAPPING,
  LUCKY_BOX_RARITY_TO_COLLECTION_ITEM_RARITY_MAPPING,
} from '../../../../../constants/award-admin/collectionItemRarity';
import { LUCKY_BOX_TYPES } from '../../../../../constants/award-admin/luckyBoxType';
import { LUCKY_BOX_RARITY, LUCKY_BOX_RARITY_TOOLTIP } from '../../../../../constants/award-admin/luckyBoxRarity';
import { LUCKY_BOX_STATUSES, LUCKY_BOX_STATUS_OPTIONS } from '../../../../../constants/award-admin/luckyBoxStatus';

// local
import LuckyBoxFormCtrl from './LuckyBoxForm.ctrl';

// form validation schema
export const validationFormSchema = yup.object().shape(
  {
    name: yup.string().required('Bạn cần nhập tên của hộp may mắn').label('Tên'),
    nameVoiceUrl: yup
      .string()
      .typeError('Bạn cần chọn giọng đọc cho tên của hộp may mắn')
      .required('Bạn cần chọn giọng đọc cho tên của hộp may mắn')
      .label('Giọng đọc tên'),
    status: yup
      .string()
      .oneOf(Object.values(LUCKY_BOX_STATUSES))
      .required('Bạn cần chọn trạng thái của hộp may mắn')
      .label('Trạng thái'),
    publishQuantity: yup
      .string()
      .required('Bạn cần nhập số lượng phát hành của hộp may mắn')
      .test('is-valid', 'Số lượng phát hành phải lớn hơn 0', (value) => Number(value) > 0)
      .label('Số lượng phát hành'),
    collectionCategoryIds: yup
      .array()
      .of(yup.string())
      .label('Thể loại chứa vật phẩm có thể có trong hộp may mắn')
      .when('collectionItemIds', {
        is: (val) => !!val && val.length > 0,
        then: yup
          .array()
          .of(yup.string())
          .max(
            0,
            'Bạn đã chọn vật phẩm có thể có trong hộp may mắn nên không thể chọn thêm thể loại chứa vật phẩm có thể có trong hộp may mắn nữa!',
          ),
        otherwise: yup.array().of(yup.string()),
      }),
    collectionItemIds: yup
      .array()
      .of(yup.string())
      .label('Vật phẩm có thể có trong hộp may mắn')
      .when('collectionCategoryIds', {
        is: (val) => !!val && val.length > 0,
        then: yup
          .array()
          .of(yup.string())
          .max(
            0,
            'Bạn đã chọn thể loại chứa vật phẩm có thể có trong hộp may mắn nên không thể chọn thêm vật phẩm có thể có trong hộp may mắn nữa!',
          ),
        otherwise: yup.array().of(yup.string()),
      }),
    price: yup
      .string()
      .required('Bạn cần nhập số sao quy đổi của hộp may mắn')
      .test('is-valid', 'Số sao quy đổi phải lớn hơn 0', (value) => Number(value) > 0)
      .label('Số sao quy đổi'),
    previewImageUrl: yup.string().required('Bạn cần upload một ảnh').label('Ảnh'),
  },
  ['collectionCategoryIds', 'collectionItemIds'],
);

const DEFAULT_LUCKY_BOX_TOOLTIP =
  'Bạn chỉ có thể chọn vật phẩm hoặc thể loại chứa vật phẩm cho hộp may mắn.\nNếu bạn không chọn hệ thống sẽ tự động chọn thay bạn.';

const LuckyBoxForm = memo(({ id, open, toggle, isEdit = false, setIsGenerateMoreModalOpen }) => {
  // -- ctrl --
  const {
    // preview image url
    previewImageUrl,
    setPreviewImageUrl,
  } = LuckyBoxFormCtrl.useContainer();

  // -- form state --
  const { watch } = useFormContext();
  const type = watch('type');
  const rarity = watch('rarity');
  const itemSpawnRateByRarity = watch('itemSpawnRateByRarity');
  const collectionCategoryIds = watch('collectionCategoryIds');
  const collectionItemIds = watch('collectionItemIds');

  // -- is default lucky box --
  const isDefaultLuckyBox = useMemo(() => type === LUCKY_BOX_TYPES.DEFAULT, [type]);

  // -- allowed item rarities --
  const allowedItemRarities = useMemo(
    () =>
      _reduce(
        itemSpawnRateByRarity,
        (memo, spawnRate, itemRarityJSField) => {
          if (spawnRate > 0) {
            memo.push(JS_FIELD_TO_COLLECTION_ITEM_RARITY_MAPPING[itemRarityJSField]);
          }

          return memo;
        },
        [],
      ),
    [itemSpawnRateByRarity],
  );
  // -- lucky box tooltip --
  const luckyBoxTooltip = useMemo(
    () => (rarity ? `${DEFAULT_LUCKY_BOX_TOOLTIP}\n${LUCKY_BOX_RARITY_TOOLTIP[rarity]}` : DEFAULT_LUCKY_BOX_TOOLTIP),
    [rarity],
  );

  // -- is selected collection category ids --
  const isSelectedCollectionCategoryIds = useMemo(() => collectionCategoryIds?.length > 0, [collectionCategoryIds]);

  // -- is selected collection item ids --
  const isSelectedCollectionItemIds = useMemo(() => collectionItemIds?.length > 0, [collectionItemIds]);

  return (
    <Form onSubmit={() => {}}>
      {/* name */}
      <FormGroup>
        <Label htmlFor="name" className="t-flex">
          <span>Tên hộp may mắn</span>

          {/* name voice */}
          <InputVoiceField fieldName="name" fieldVoiceUrlName="nameVoiceUrl" fieldVoiceUrlId="nameVoiceUrl" />
        </Label>

        <InputField type="text" name="name" id="name" placeholder="Nhập tên hộp may mắn" disabled={isDefaultLuckyBox} />
      </FormGroup>

      <div className="t-flex">
        {/* price */}
        <FormGroup className="t-flex-1 mr-2">
          <Label htmlFor="price">Số sao quy đổi</Label>
          <InputNumberField
            name="price"
            id="price"
            thousandSeparator={true}
            allowNegative={false}
            prefix="⭐ "
            placeholder="Nhập số sao quy đổi"
          />
        </FormGroup>

        <div className="t-flex-1 ml-2 t-flex">
          {/* publish quantity */}
          <FormGroup className={cls('t-flex-1', { 'mr-2': isEdit })}>
            <Label htmlFor="publishQuantity">Số lượng phát hành</Label>
            <InputNumberField
              name="publishQuantity"
              id="publishQuantity"
              thousandSeparator={true}
              allowNegative={false}
              min={0}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (floatValue == null) {
                  return formattedValue === '';
                } else {
                  return floatValue >= 0;
                }
              }}
              placeholder={isDefaultLuckyBox ? '' : 'Nhập số lượng phát hành'}
              disabled={isEdit}
            />
          </FormGroup>

          {/* available quantity */}
          {isEdit && (
            <FormGroup className="t-flex-1 ml-2">
              <Label htmlFor="availableQuantity">
                Số lượng còn
                <AiFillPlusCircle
                  color="#007bff"
                  className="t-cursor-pointer ml-1"
                  onClick={() => setIsGenerateMoreModalOpen(true)}
                  title="Tạo thêm"
                />
              </Label>
              <InputNumberField
                name="availableQuantity"
                id="availableQuantity"
                thousandSeparator={true}
                allowNegative={false}
                disabled
              />
            </FormGroup>
          )}
        </div>
      </div>

      <div className="t-flex">
        {/* status */}
        <FormGroup className="t-flex-1 mr-2">
          <Label htmlFor="status">Trạng thái</Label>
          <SelectField
            name="status"
            id="status"
            options={LUCKY_BOX_STATUS_OPTIONS}
            selectProps={{ placeholder: 'Chọn trạng thái' }}
          />
        </FormGroup>

        {/* expiredAt */}
        {!isDefaultLuckyBox && (
          <FormGroup className="t-flex-1 ml-2">
            <Label htmlFor="expiredAt">Ngày hết hạn</Label>
            <InputDateField name="expiredAt" id="expiredAt" inputProps={{ placeholder: 'Chọn ngày hết hạn' }} />
          </FormGroup>
        )}
      </div>

      {!isEdit && (
        <>
          {/* collection category ids */}
          <FormGroup>
            <Label htmlFor="collectionCategoryIds">
              Danh sách thể loại chứa vật phẩm có thể có trong hộp may mắn
              <BiHelpCircle className="ml-1" title={luckyBoxTooltip} />
            </Label>
            <CollectionCategorySelectField
              name="collectionCategoryIds"
              id="collectionCategoryIds"
              requestParams={{ itemRarities: allowedItemRarities }}
              selectProps={{
                placeholder: 'Chọn thể loại',
                isMulti: true,
                isClearable: true,
                isDisabled: isSelectedCollectionItemIds,
              }}
            />
          </FormGroup>

          {/* collection item ids */}
          <FormGroup>
            <Label htmlFor="collectionItemIds">
              Danh sách vật phẩm có thể có trong hộp may mắn
              <BiHelpCircle className="ml-1" title={luckyBoxTooltip} />
            </Label>
            <CollectionItemSelectField
              name="collectionItemIds"
              id="collectionItemIds"
              requestParams={{ rarities: allowedItemRarities }}
              selectProps={{
                placeholder: 'Chọn vật phẩm',
                isMulti: true,
                isClearable: true,
                isDisabled: isSelectedCollectionCategoryIds,
              }}
            />
          </FormGroup>
        </>
      )}

      {/* itemSpawnRateByRarity */}
      <div>
        <Label>Tỉ lệ xuất hiện vật phẩm theo độ hiếm</Label>

        <div className="t-flex">
          {/* itemSpawnRateByRarity.normal */}
          <FormGroup className="t-flex-1 mr-2">
            <Label htmlFor="itemSpawnRateByRarity.normal" className="t-italic">
              Thường
            </Label>
            <InputNumberField
              name="itemSpawnRateByRarity.normal"
              id="itemSpawnRateByRarity.normal"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={3}
              defaultValue={0}
              min={0}
              max={100}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (floatValue == null) {
                  return formattedValue === '';
                } else {
                  return floatValue <= 100 && floatValue >= 0;
                }
              }}
              suffix="%"
              disabled={isDefaultLuckyBox}
            />
          </FormGroup>

          {/* itemSpawnRateByRarity.rare */}
          <FormGroup className="t-flex-1 mr-2">
            <Label htmlFor="itemSpawnRateByRarity.rare" className="t-italic">
              Hiếm
            </Label>
            <InputNumberField
              name="itemSpawnRateByRarity.rare"
              id="itemSpawnRateByRarity.rare"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={3}
              defaultValue={0}
              min={0}
              max={100}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (floatValue == null) {
                  return formattedValue === '';
                } else {
                  return floatValue <= 100 && floatValue >= 0;
                }
              }}
              suffix="%"
              disabled={isDefaultLuckyBox}
            />
          </FormGroup>

          {/* itemSpawnRateByRarity.extremelyRare */}
          <FormGroup className="t-flex-1 mr-2">
            <Label htmlFor="itemSpawnRateByRarity.extremelyRare" className="t-italic">
              Cực hiếm
            </Label>
            <InputNumberField
              name="itemSpawnRateByRarity.extremelyRare"
              id="itemSpawnRateByRarity.extremelyRare"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              defaultValue={0}
              min={0}
              max={100}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (floatValue == null) {
                  return formattedValue === '';
                } else {
                  return floatValue <= 100 && floatValue >= 0;
                }
              }}
              suffix="%"
              disabled={isDefaultLuckyBox}
            />
          </FormGroup>
        </div>
      </div>

      {/* image */}
      <FormGroup>
        <Label>Ảnh</Label>
        <InputImageField
          name="image"
          id="image"
          accept="image/*"
          previewImageUrl={previewImageUrl}
          setPreviewImageUrl={setPreviewImageUrl}
          previewImageProps={{
            className: 'mt-3 d-block t-w-[200px]',
          }}
          disabled={isDefaultLuckyBox}
        />
      </FormGroup>
    </Form>
  );
});

export default (props) => (
  <LuckyBoxFormCtrl.Provider initialState={props}>
    <LuckyBoxForm {...props} />
  </LuckyBoxFormCtrl.Provider>
);
