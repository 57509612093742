import { 
    approveLesson,
    getAllCourse,
    getAllPaths,
    getAllLessonVersion,
    getCompareLp,
    getFrameContent,
    getFromQueryCourse,
    getLessonVersion,
    inReviewLesson,
    rejectLesson,
    requestToChangeLesson,
    searchCourse,
    submitLesson,
    suspendLesson
} from "../apis/publishManagement";
import { PublishManagementAction } from "../reducers/publishManagement";
import { toast } from "react-toastify";
import { getLesson } from "../reducers/lesson";
import { convertDataLearningPoint, refineLearningPoints } from "../reducers/convertLesson";

export const loadingAction = (loading) => (dispatch, getState) => {
    dispatch({
        type: PublishManagementAction.LOADING,
        data: loading
    });
}

export const updateCompareLp = (data) => (dispatch, getState) => {
    dispatch({
        type: PublishManagementAction.UPDATE_COMPARE,
        data
    });
}

export const getListCourse = () => async (dispatch, getState) => {
    const data = await getAllCourse();
    const oldestDate = data && data.length > 0 ? new Date(data[0].createdAt) : new Date();
    dispatch({
        type: PublishManagementAction.GET_LIST_COURSE,
        data
    });
    dispatch({
        type: PublishManagementAction.SET_FILTER,
        data: {
            date: {
                from: oldestDate,
                to: new Date(),
                oldestDate,
            }
        }
    });
}

export const openFilter = () => (dispatch, getState) => {
    dispatch({ type: PublishManagementAction.OPEN_FILTER });
}

export const closeFilter = () => (dispatch, getState) => {
    dispatch({ type: PublishManagementAction.CLOSE_FILTER });
}

export const changeFilter = (data) => (dispatch, getState) => {
    dispatch({ type: PublishManagementAction.SET_FILTER, data });
}

export const changeCourseSearch = (data) => (dispatch) => {
    dispatch({ type: PublishManagementAction.SET_SEARCH, data });
}

export const filterCourse = (data) => async (dispatch, getState) => {
    const res = await getFromQueryCourse(data);
    dispatch({
        type: PublishManagementAction.GET_LIST_COURSE,
        data: res
    });
}

export const searchCourseAction = (title, data) => async (dispatch, getState) => {
    dispatch(loadingAction(true));
    const res = await searchCourse(title, data);
    dispatch({
        type: PublishManagementAction.GET_LIST_COURSE,
        data: res.courses
    });
    dispatch(loadingAction(false));
}

//
export const submitLessonAction = (comment) => async (dispatch, getState) => {
    const { lesson, auth } = getState();
    const { sid, preferred_username } = auth.authData;
    comment = {
        content: {
            text: comment
        },
        sendBy: {
            userId: sid,
            username: preferred_username
        }
    }
    // const comments = localState.version && localState.version.comment ?  localState.version.comments : [];
    const res = await submitLesson({ id: lesson.localState.id, comment });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại")
    else {
        toast.success("Nộp bài học thành công");
        dispatch(getLesson(lesson.localState.id));
    }
}

export const approveLessonAction = (comment) => async (dispatch, getState) => {
    const { lesson, auth } = getState();
    const { id, name } = auth.data;
    comment = {
        content: {
            text: comment
        },
        sendBy: {
            userId: id,
            username: name
        }
    }
    // const comments = localState.version && localState.version.comment ?  localState.version.comments : [];
    const res = await approveLesson({ id: lesson.localState.id, comment });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại")
    else {
        toast.success("Duyệt bài học thành công");
        dispatch(getLesson(lesson.localState.id));
    }
}

export const rejectLessonAction = (comment) => async (dispatch, getState) => {
    const { lesson, auth } = getState();
    const { id, name } = auth.data;
    comment = comment 
        ? {
            content: {
                text: comment
            },
            sendBy: {
                userId: id,
                username: name
            }
            } 
        : null;
    // const comments = localState.version && localState.version.comment ?  localState.version.comments : [];
    const res = await rejectLesson({ id: lesson.localState.id, comment });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại")
    else {
        toast.success("Từ chối bài học thành công");
        dispatch(getLesson(lesson.localState.id));
    }
}

export const suspendLessonAction = (comment) => async (dispatch, getState) => {
    const { lesson, auth } = getState();
    const { id, name } = auth.data;
    comment = comment 
        ? {
            content: {
                text: comment
            },
            sendBy: {
                userId: id,
                username: name
            }
            } 
        : null;
    // const comments = localState.version && localState.version.comment ?  localState.version.comments : [];
    const res = await suspendLesson({ id: lesson.localState.id, comment });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại")
    else {
        toast.success("Đình chỉ bài học thành công");
        dispatch(getLesson(lesson.localState.id));
    }
}

export const requestToChangeLessonAction = (comment) => async (dispatch, getState) => {
    const { lesson, auth } = getState();
    const { id, name } = auth.data;
    comment = comment 
        ? {
            content: {
                text: comment
            },
            sendBy: {
                userId: id,
                username: name
            }
            } 
        : null;
    // const comments = localState.version && localState.version.comment ?  localState.version.comments : [];
    const res = await requestToChangeLesson({ id: lesson.localState.id, comment });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại")
    else {
        toast.success("Yêu cầu thay đổi bài học thành công");
        dispatch(getLesson(lesson.localState.id));
    }
}

export const inReviewLessonAction = (id) => async (dispatch, getState) => {
    const res = await inReviewLesson({ id });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại");
    dispatch(getLesson(id));
}

//

export const getAllLessonVersionAction = () => async (dispatch, getState) => {
    const { lesson } = getState();
    const res = await getAllLessonVersion(lesson.localState.id);
    if (!res || res.status !== 1) toast.error("Thao tác thất bại");
    else {
        dispatch({
            type: 'UPDATE_VERSION_LESSON',
            data: res.result.lessons
        })
    }
}

export const getLessonVersionAction = (id) => async (dispatch, getState) => {
    dispatch({
        type: '@@lesson/SYNCING',
        value: true,
    });
    const res = await getLessonVersion(id);
    if (!res || res.status !== 1) toast.error("Thao tác thất bại");
    else {
        const lpArray = convertDataLearningPoint(res.result.lesson);
        let lessonData = { ...res.result.lesson };
        const { learningPoints, learningPointIds, entities } = refineLearningPoints(lpArray);
        dispatch({
            type: 'RECEIVE__LESSON',
            data: {
              ...lessonData,
              learningPoints: learningPoints,
              learningPointIds,
              entities,
            },
          });
    }
    dispatch({
        type: '@@lesson/SYNCING',
        value: false,
    });
}

export const getCompareLpAction = ({ lessonId, id, type }) => async (dispatch, getState) => {
    const res = await getCompareLp({ lessonId, id, type });
    if (!res || res.status !== 1) toast.error("Thao tác thất bại");
    else {
        dispatch({
            type: PublishManagementAction.GET_COMPARE,
            data: res.result.learningPoint || {}
        });
    }
}