import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './style.scss';

const LessonNavTemplateLeft = () => {
    const [tooltipRotX, setTooltipRotX] = useState(false);
    const [tooltipRotY, setTooltipRotY] = useState(false);
    const [tooltipW, setTooltipW] = useState(false);
    const [tooltipH, setTooltipH] = useState(false);

    return (
        <div className="LessonNavTemplate_left">
            <div className="Design_Icon">
                <div style={{height: '10px'}}></div>
                <span>Design Icon</span>
                <div className="Design_Icon_Element">
                    <span id="tooltipRotX">RotX</span><input type="text" />
                    <Tooltip placement="top" isOpen={tooltipRotX} target="tooltipRotX" toggle={() => setTooltipRotX(!tooltipRotX)}>
                        Rotation X
                    </Tooltip>
                    <span id="tooltipRotY">RotY</span><input type="text" />
                    <Tooltip placement="top" isOpen={tooltipRotY} target="tooltipRotY" toggle={() => setTooltipRotY(!tooltipRotY)}>
                        Rotation Y
                    </Tooltip>
                </div>
                <div className="Design_Icon_Element">
                    <span id="width" style={{marginRight: "30px"}}>W</span><input type="text" />
                    <Tooltip placement="top" isOpen={tooltipW} target="width" toggle={() => setTooltipW(!tooltipW)}>
                        Width
                    </Tooltip>
                    <span id="height" style={{marginRight: "30px"}}>H</span><input type="text" />
                    <Tooltip placement="top" isOpen={tooltipH} target="height" toggle={() => setTooltipH(!tooltipH)}>
                        Height
                    </Tooltip>
                </div>
                <div className="Design_Icon_Element" >
                    <span>Stoke</span><div className="color_square"></div><input type="text" style={{marginLeft: "50px"}}/>
                </div>
                <div className="Design_Icon_Element" >
                    <div className="color_circle"></div><span>Pick color</span>
                </div>
            </div>
            <div className="Icon">
                <div>
                    <div className="Icon_Element">Thêm icon</div>
                    <div className="Icon_Element">Đổi icon</div>
                    <div className="Icon_Element">Xóa icon</div>
                </div>
                <div className="Icon_Image">
                    <div style={{
                        cursor: 'move',
                        width: '80px',
                        height: '80px',
                        backgroundColor: '#C4C4C4',
                        border: '1px solid #C4C4C4',
                        borderRadius: '5px',
                        margin: '20px auto'
                    }}>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonNavTemplateLeft;