/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */

import { getArrayDifferences } from '../utils/array';
import { getDirectoryUrlFromFile } from '../utils/imageProcessing';
import { uploadAudio } from './AudioQuiz';

/**
 *
 * @param {object} learningPoint
 * @returns check if input learningPoint is a theory? true/false
 */
export const isTheory = (learningPoint) => {
  if (!learningPoint || !learningPoint.type) return false;
  return learningPoint.type.split(':')[0] === 'theory';
};

/**
 *
 * @param {object} learningPoint
 * @returns check if input learningPoint is a theory? true/false
 */
export const isQuiz = (learningPoint) => {
  if (!learningPoint || !learningPoint.type) return false;
  return learningPoint.type.split(':')[0] === 'quiz';
};
/**
 *
 * @param {object} learningPoint
 * @returns check if input learningPoint is a theory? true/false
 */
export const isCheckpoint = (learningPoint) => {
  if(!learningPoint || !learningPoint.type) return false;
  return learningPoint.type.split(':')[0] === 'checkpoint';
}
export const isExistedIn = (oldLearningPoints) => (learningPoint) => {
  return oldLearningPoints.filter(({ id }) => id === learningPoint.id).length > 0;
};

export const isNotExistedIn = (oldLearningPoints) => (learningPoint) => {
  const notexisted = oldLearningPoints.filter(({ id }) => id === learningPoint.id);
  return notexisted.length === 0;
};

/**
 * Clasify the updated & newly untracked learning Points from oldLPs and currentLPs
 * @param {Array<learningPoint>} oldLearningPoints oldlearning points
 * @param {Array<learningPoint>} learningPoints current learning points
 * @returns the updated and newly untracked learning Points
 */
export const clasifyTheories = (oldLearningPoints, learningPoints) => {
  const oldTheories = oldLearningPoints.filter(isTheory);

  const newTheories = learningPoints.filter(isTheory);

  const diffTheories = getArrayDifferences(newTheories, oldTheories);

  // Need to classify new lesson vs update lesson
  // Ham viet tat
  const toBeUpdatedTheories = diffTheories.filter(isExistedIn(oldTheories));
  const toBeCreatedTheories = diffTheories.filter(isNotExistedIn(oldTheories));
  return { toBeUpdatedTheories, toBeCreatedTheories };
};

/**
 * Clasify the updated & newly untracked learning Points from oldLPs and currentLPs
 * @param {Array<learningPoint>} oldLearningPoints oldlearning points
 * @param {Array<learningPoint>} learningPoints current learning points
 * @returns the updated and newly untracked learning Points
 */
export const clasifyLearningPoints = (oldLearningPoints, learningPoints) => {
  // Theory **************************
  const oldTheories = oldLearningPoints.filter(isTheory);
  const currentTheories = learningPoints.filter(isTheory);
  const diffTheories = getArrayDifferences(currentTheories, oldTheories);

  const toBeUpdatedTheories = diffTheories.filter(isExistedIn(oldTheories));
  const toBeCreatedTheories = diffTheories.filter(isNotExistedIn(oldTheories));

  // Quiz **************************
  const oldQuizzes = oldLearningPoints.filter(isQuiz);
  const currentQuizzes = learningPoints.filter(isQuiz);
  const diffQuizzes = getArrayDifferences(currentQuizzes, oldQuizzes);

  const newQuizzes = diffQuizzes.filter(isNotExistedIn(oldQuizzes));
  const fixQuizzes = diffQuizzes.filter(isExistedIn(oldQuizzes));

  // Checkpoint ***********************
  const oldCheckpoints = oldLearningPoints.filter(isCheckpoint);
  const currentCheckpoints = learningPoints.filter(isCheckpoint);
  const diffCheckpoints = getArrayDifferences(currentCheckpoints, oldCheckpoints);

  const newCheckpoints = diffCheckpoints.filter(isNotExistedIn(oldCheckpoints));
  const fixCheckpoints = diffCheckpoints.filter(isExistedIn(oldCheckpoints));

  // Returns **************************
  return { toBeUpdatedTheories, toBeCreatedTheories, newQuizzes, fixQuizzes, newCheckpoints, fixCheckpoints };
};

// Function for handle quiz images and videos
const changeUrl = async (part, key, data) => {
  if (part[key].type === 'video' && part[key].file) {
    await getDirectoryUrlFromFile(part[key].file)
      .then((res) => {
        if (res) {
          data = {
            ...data,
            [key]: {
              ...data[key],
              videoUrl: res,
              type: 'video',
            },
          };
        }
      })
      .catch((e) => {
        return data;
      });
  }
  if (part[key].type === 'image' && part[key].file) {
    await getDirectoryUrlFromFile(part[key].file)
      .then((res) => {
        if (res) {
          data = {
            ...data,
            [key]: {
              ...data[key],
              imageUrl: res,
              type: 'image',
            },
          };
        }
      })
      .catch((e) => {
        return data;
      });
  }
  return {
    ...data,
    [key]: {
      ...data[key],
      type: data[key].type || 'text',
    },
  };
};

export const formatQuizPart = async (learningPoint, oldQuizParts, speaker) => {
  let datas = [];
  const { quizParts } = learningPoint;
  for (let i = 0; i < quizParts.length; i++) {
    const part = quizParts[i];
    let data = { ...part };
    data = await changeUrl(part, 'content', data);    
    data = await uploadAudio(oldQuizParts, data, 'content', speaker);
    if (data.pairContent) {
      data = await changeUrl(part, 'pairContent', data);
      data = await uploadAudio(oldQuizParts, data, 'pairContent', speaker);
    }
    if (data.wrongAnswer) {
      data = await changeUrl(part, 'wrongAnswer', {
        ...data,
      });
      data = await uploadAudio(oldQuizParts, data, 'wrongAnswer', speaker);
    }
    // delete data.content.file;
    datas = [...datas, data];
  }
  return datas;
};

export const addImageUrlIntoLesson = async (lesson) => {
  const newLesson = JSON.parse(JSON.stringify(lesson));
  if (lesson.fileImg) {
    const imageUrl = await getDirectoryUrlFromFile(lesson.fileImg);
    newLesson.imageUrl = imageUrl;
  }
  if (lesson.fileThumbImg) {
    const thumbnailImageUrl = await getDirectoryUrlFromFile(lesson.fileThumbImg);
    newLesson.thumbnailImageUrl = thumbnailImageUrl;
  }
  return newLesson;
};
