import React from 'react';
import { getFullUrl } from '../../utils/imageProcessing';

function Photo({ src, ...rest }) {
  const defaultThumbnail = '/components/curriculum_placeholder.svg';
  const fallbackDefaultImg = (e) => {
    e.target.src = defaultThumbnail;
  };

  return (
    <img alt="this is an alt" src={src ? getFullUrl(src) : defaultThumbnail} onError={fallbackDefaultImg} {...rest} />
  );
}

export default Photo;
