import React from 'react';
import { FillInTheBlankIcon, MatchThePairIcon, MultiChoiceIcon, SquareQuizIcon, IconWriting, IconSpeaking } from './QuizIcons';
import {
  IconUpDown,
  IconTwoUpDown,
  IconLefRight,
  IconTwoUpTwoDown,
  IonUpTwoDown,
  IconCustomLayout,
} from './TheoryIcons';
import {
  IconDelete,
  IconMatchToPair,
  IconMoveSlideDown,
  IconMoveSlideUp,
  IconSmallStar,
  IconSpeak,
} from '../../../icons/IconSvg';
import ComponentAdd from './ComponentAdd';
import { TheoryIcon } from '../icons/TheoryIcon';
import { QuizIcon } from '../icons/QuizIcon';
import {deleteLearningPointer, shiftSlideDown, shiftSlideUp } from '../../../actions/ActionLesson';

import CustomObject from '../../Theory/CustomObject';
import ComponentQuiz from '../../Quiz/index';
// import { saveQuizzesBank } from '../../../actions/ActionQuestionBank';
import { Welcome } from '../LessonRight/welcome';
import { OverviewIcon } from '../icons/OverviewIcon';
import Reward from '../../Checkpoint';
// import Checkpoint from '../../Checkpoint';

export const initialState = {
  tab: 'theory',
  layout: 'theory:left-right',
};

export const lessonTemplates = [
  {
    name: 'theory',
    icon: <TheoryIcon />,
    layouts: [
      {
        id: 0,
        name: 'theory:custom',
        icon: <IconCustomLayout />,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: { ratio: 1 },
        style: {
          maxWidth: '700px',
          maxHeight: '400px',
        }
      },
      {
        id: 1,
        name: 'theory:left-right',
        icon: <IconUpDown /> ,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: {
          children: [
            { 
              ratio: 1,
              style: {
                maxWidth: '600px',
                maxHeight: '400px',
                width: '50%'
              }
            },
            {
              ratio: 1,
              style: {
                maxWidth: '600px',
                maxHeight: '400px',
                width: '50%'
              }
            }
          ],
          layout: 'horizontal',
          ratio: 1,
        },
      },
      {
        id: 2,
        name: 'theory:up-down',
        icon: <IconLefRight />,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: {
          children: [
            {
              ratio: 1,
              style: {
                maxWidth: '700px',
                maxHeight: '300px',
              },
            },
            {
              ratio: 1,
              style: {
                maxWidth: '700px',
                maxHeight: '300px',
              },
            },
          ],
          layout: 'vertical',
          ratio: 1,
        },
      },
      {
        id: 3,
        name: 'theory:up-twodown',
        icon: <IconTwoUpDown /> ,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: {
          children: [
            {
              ratio: 1,
              style: {
                maxWidth: '700px',
                maxHeight: '300px'
              }
            },
            {
              children: [
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                },
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                }
              ],
              layout: 'horizontal',
              ratio: 1,
            },
          ],
          layout: 'vertical',
          ratio: 1,
        },
      },
      {
        id: 4,
        name: 'theory:twoup-down',
        icon: <IonUpTwoDown />,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: {
          children: [
            {
              children: [
                {
                  ratio: 1,
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                },
                {
                  ratio: 1 ,
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                }
              ],
              layout: 'horizontal',
              ratio: 1,
            },
            {
              ratio: 1,
              style: {
                maxWidth: '700px',
                maxHeight: '300px'
              }
            },
          ],
          layout: 'vertical',
          ratio: 1,
        },
      },
      {
        id: 5,
        name: 'theory:twoup-twodown',
        icon: <IconTwoUpTwoDown />,
        layout: (versionPreview = false, data = null) => (<CustomObject versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <CustomObject previewLP={previewLP} />,
        customLayout: {
          children: [
            {
              children: [
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                },
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                }
              ],
              layout: 'horizontal',
              ratio: 1,
            },
            {
              children: [
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                },
                {
                  style: {
                    maxWidth: '600px',
                    maxHeight: '300px',
                    width: '50%'
                  }
                }
              ],
              layout: 'horizontal',
              ratio: 1,
            },
          ],
          layout: 'vertical',
          ratio: 1,
        },
      },
    ],
  },
  {
    name: 'quiz',
    icon: <QuizIcon />,
    layouts: [
      {
        name: 'quiz:mc_quiz',
        icon: <MultiChoiceIcon />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} datas={[]} />,
      },
      {
        name: 'quiz:blank_quiz',
        icon: <FillInTheBlankIcon />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} />,
      },
      {
        name: 'quiz:pair_quiz',
        icon: <MatchThePairIcon />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} />,
      },
      {
        name: 'quiz:square_quiz',
        icon: <SquareQuizIcon />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} />,
      },
      {
        name: 'quiz:writing_quiz',
        icon: <IconWriting />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} />,
      },
      {
        name: 'quiz:speaking_quiz',
        icon: <IconSpeaking />,
        layout:  (versionPreview = false, data = null) => (<ComponentQuiz datas={[]} versionPreview={versionPreview} data={data} />),
        layoutPreview: (previewLP) => <ComponentQuiz currentSlidePreview={previewLP} />,
      },
    ],
  },
  // {
  //   name: 'checkpoint',
  //   icon: <TheoryIcon />,
  //   layouts: [
  //     {
  //       name: 'checkpoint:default',
  //       icon: <IconWriting />,
  //       layout: <Checkpoint />,
  //       layoutPreview: () => <div>Trang này đang được phát triển</div>,
  //     },
  //   ],
  // },
  // {
  //   name: 'overview',
  //   icon: <TheoryIcon />,
  //   layouts: [
  //     {
  //       name: 'overview:default',
  //       icon: <IconWriting />,
  //       layout: <ComponentQuiz />,
  //       layoutPreview: () => <div>Trang này đang được phát triển</div>,
  //     },
  //   ],
  // },
  {
    name: 'default',
    icon: <TheoryIcon />,
    layouts: [
      {
        name: 'default:default',
        icon: <IconWriting />,
        layout: (versionPreview = false) => <ComponentQuiz versionPreview={versionPreview} />,
        layoutPreview: () => <div>Trang này đang được phát triển</div>,
      },
    ],
  },
  {
    name: 'welcome',
    icon: <OverviewIcon />,
    component: <Welcome />,
  },
  {
    name: 'checkpoint',
    icon: <IconSmallStar />,
    layouts: [
      {
        id: 0,
        name: 'checkpoint:default',
        icon: <IconSmallStar />,
        layout: (versionPreview = false, data = null) => <Reward versionPreview={versionPreview} data={data} />,
        layoutPreview: (previewLP) => <Reward previewLP={previewLP}/>,
      },
      // {
      //   id: 1,
      //   name: 'checkpoint:custom1',
      //   icon: <IconSmallStar color='red'/>,
      //   layout: <Reward />,
      //   layoutPreview: (previewLP) => <Reward previewLP={previewLP}/>,
      // },
      // {
      //   id: 2,
      //   name: 'checkpoint:custom2',
      //   icon: <IconSmallStar color='blue'/>,
      //   layout: <Reward />,
      //   layoutPreview: (previewLP) => <Reward previewLP={previewLP}/>,
      // },
      // {
      //   id: 3,
      //   name: 'checkpoint:custom3',
      //   icon: <IconSmallStar color='green'/>,
      //   layout: <Reward />,
      //   layoutPreview: (previewLP) => <Reward previewLP={previewLP}/>,
      // },
    ],
  },
];
export const ListTabFunction = [
  {
    tooltip: 'Thêm slide mới ở dưới slide đang chọn',
    icon: (inTestBank, quizTest) => <ComponentAdd quizTest={quizTest} inTestBank={inTestBank} />,
    handleClick: () => console.log(''),
  },
  {
    tooltip: 'Xoá slide đang chọn',
    icon: (inTestBank, quizTest) => <div id="tour-lesson-v3"><IconDelete /></div>,
    handleClick: deleteLearningPointer(),
  },
  {
    tooltip: 'Di chuyển slide hiện tại xuống dưới',
    icon: (inTestBank, quizTest) => <div id="tour-lesson-v4"><IconMoveSlideDown /></div>,
    handleClick: shiftSlideDown(),
  },
  {
    tooltip: 'Di chuyển slide hiện tại lên trên',
    icon: (inTestBank, quizTest) => <div id="tour-lesson-v5"><IconMoveSlideUp /></div>,
    handleClick: shiftSlideUp(),
  },
];
