import React, { memo } from 'react';
import { Table } from 'reactstrap';

// local
import Header from './Header'
import RowData from './RowData'

const headers = ["Ảnh", "Tên", "Số sao quy đổi", "Số lượng", "Tỉ lệ xuất hiện vật phẩm theo độ hiếm", ""]

const OrderLuckyBoxDataTable = ({ className, luckyBoxes = [] }) => (
  <Table className={className} bordered responsive>
    {/* header */}
    <Header headers={headers} />

    {/* body */}
    <tbody>
      {luckyBoxes.map(luckyBox => (
        <RowData key={luckyBox.id} luckyBox={luckyBox} />
      ))}
    </tbody>
  </Table>
)

export default memo(OrderLuckyBoxDataTable)