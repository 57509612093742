import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';;
import { IconPauseCircle, IconPlayCircle,} from '../../../icons/IconSvg';
import './style.scss';

const _PlayAudio = ({audioUrl, className}) => {

    const [audioState, setAudioState] = useState('pause');
    const [audio, setAudio] = useState(audioUrl ? new Audio(audioUrl) : null);
    useEffect(() => {
        setAudio(new Audio(audioUrl));
    }, [audioUrl])

    useEffect(() => {
      let isCancelled = false;
  
      if (!audio || !audio.src) {
        setAudioState('loading');
        return () => {
          isCancelled = true;
        };
      }
  
      audio.onplaying = () => {
        setAudioState('playing');
      };
      audio.onpause = () => {
        setAudioState('paused');
      };
      audio.onerror = () => {
        if (isCancelled) return;
        setAudioState('paused');
      };
      audio.onloadeddata = () => {
        if (isCancelled) return;
        setAudioState('paused');
      };
      return () => {
        isCancelled = true;
      };
    }, [audio]);
  
    const renderAudioIcon = () => {
      switch (audioState) {
        case 'loading':
          return <Spinner size="sm" color="dark" />;
  
        case 'playing':
          return <IconPauseCircle />;
  
        case 'paused':
          return <IconPlayCircle />;
  
        default:
          return <IconPlayCircle />;
      }
    };
    
    if(!audioUrl) return null;
    return (
        <>
            <span
                aria-hidden="true"
                className={`cursor ${className}`}
                onClick={() => {
                  if (audio) {
                    if (audio.paused) audio.play();
                    else audio.pause();
                  }
                }}
            >
                {renderAudioIcon()}
            </span>
            <div style={{height: '5px'}}></div>
        </>
    );
};
const PlayAudio = React.memo(_PlayAudio); 
export default PlayAudio;