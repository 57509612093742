import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Link, Redirect } from 'react-router-dom';
import { IconCaretLeft } from '../../icons/IconSvg';
import { SlidesActions } from '../../reducers/lesson';
import { fetchCourseAction } from '../../reducers/courses';
import { getTestId } from '../../apis/test';
import { changeCourse, changeChapter } from '../../reducers/questionBank';
import { getQuizzTestFixed } from '../../actions/ActionsQuizTestFixed';
import { TYPE_STATIC, TYPE_CHAPTER, chageTest } from '../../reducers/test';
import { toast } from 'react-toastify';
import { formartQuizInTestBank } from '../../actions/ActionQuestionBank';
import SelectQuiz from './SelectQuiz';
class ComponentSelect extends React.Component {
  state = {
    courseId: '',
    courses: [],
    redirect: false,
    listQuizInBank: [],
    learningPointsSearch: [],
    query: '',
  };
  async componentDidMount() {
    if (this.props.id) {
      let datas = await getTestId(this.props.id);
      if (datas && datas.status === 1 && datas.result.test.testType === TYPE_STATIC) {
        const { test } = datas.result;
        this.props.getQuizzTestFixed(datas);
        const listQuizInBank = await formartQuizInTestBank({
          courseId: test.courseId,
          chapterId: test.testScope === TYPE_CHAPTER ? test.chapterId : null,
        });
        this.setState({
          listQuizInBank: listQuizInBank.learningPoints,
          learningPointsSearch: listQuizInBank.learningPoints,
        });
        const dataT = {
          ...test,
          quizIds: test.quizIds && test.quizIds.length > 0 ? test.quizIds.map((quiz) => quiz.id) : [],
        };
        this.props.chageTest(dataT);
      } else {
        this.setState({ redirect: true });
        toast.error('Có lỗi xảy ra khi xem danh sách bài tập');
      }
    }
  }
  debouncedQuery = debounce((value) => {
    const { listQuizInBank } = this.state;
    this.setState({
      learningPointsSearch: listQuizInBank.filter(
        (learning) =>
          learning.title.toLowerCase().includes(value.toLowerCase()) ||
          learning.question.toLowerCase().includes(value.toLowerCase()),
      ),
    });
  }, 300);
  handleChange = (e) => {
    this.setState({ query: e.target.value });
    this.debouncedQuery(e.target.value);
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={`/course`} />;
    }
    return (
      <div>
        <Link className="fl text-dc div__back" to={`/course/${this.props.test.dataTest.courseId}`}>
          <IconCaretLeft />
          <div style={{ marginTop: '1px' }} className="title">
            Quay lại
          </div>
        </Link>
        {/* <div style={{ margin: '5px 10px 0', height: '20px' }}>Chọn bài tập vào bài kiểm tra</div> */}
        <SelectQuiz
          query={this.state.query}
          learningPoints={this.state.listQuizInBank}
          learningPointsSearch={this.state.learningPointsSearch}
          handleChange={this.handleChange}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  test: state.test,
});
export default connect(mapStateToProps, {
  updateLayoutOption: SlidesActions.updateLayoutOption,
  fetchCourseAction,
  changeCourse,
  changeChapter,
  getQuizzTestFixed,
  chageTest,
})(ComponentSelect);
