import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CourseIcon, SectionIcon } from '../../icons/IconSvg';
import './style.scss';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { ButtonAction } from '../Common/Button/ButtonAction';
import { useDispatch } from 'react-redux';
import {
  updateFieldCourse,
  updateFieldChapter,
  updateStatusAction,
  updateFieldSection,
} from '../../actions/ActionFrameContent';
import { FRAME_CONTENT_STATUS, SECTION_STATUS } from '../../constants';
import { SECTION_TYPE } from '../../utils/constants';
import { getCookie } from '../../utils/accessToken';

const COURSE_ITEM = 'course-item';
const SECTION_ITEM = 'section-item';

export const FrameContentPublish = ({ course, sections, className, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const onChangeItem = (e, id, type) => {
    if (type === 'course') {
      dispatch(
        updateFieldCourse({
          id,
          key: 'status',
          value: e.target.checked ? FRAME_CONTENT_STATUS.PUBLISH : FRAME_CONTENT_STATUS.NOT_PUBLISH,
          isUpdateChapter: true,
        }),
      );
    } else if (type === 'chapter') {
      dispatch(
        updateFieldChapter({
          id,
          key: 'status',
          value: e.target.checked ? FRAME_CONTENT_STATUS.PUBLISH : FRAME_CONTENT_STATUS.NOT_PUBLISH,
        }),
      );
      if (e.target.checked) {
        dispatch(
          updateFieldCourse({
            key: 'status',
            value: e.target.checked ? FRAME_CONTENT_STATUS.PUBLISH : FRAME_CONTENT_STATUS.NOT_PUBLISH,
          }),
        );
      }
    } else if (type === SECTION_TYPE.TEST || type === SECTION_TYPE.LESSON) {
      dispatch(
        updateFieldSection({
          id,
          key: 'status',
          value: e.target.checked ? SECTION_STATUS.APPROVED : SECTION_STATUS.SUSPENDED,
          type
        }),
      );
    }
  };

  const Item = ({ id, type, status, label, startIcon, className }) => {
    return (
      <div className={`frame-content-item ${className}`}>
        <div className="frame-content-item-content">
          <span className="start-icon">{startIcon}</span>
          <span className="label">{label}</span>
        </div>
        <div className="checkbox">
          <Checkbox
            style={{ color: '#2B429D' }}
            onChange={(e) => onChangeItem(e, id, type)}
            checked={
              status ===
              (type !== 'chapter' && type !== 'course' ? SECTION_STATUS.APPROVED : FRAME_CONTENT_STATUS.PUBLISH)
            }
          />
        </div>
      </div>
    );
  };

  const onClick = (e) => {
    const token = getCookie('accessToken')
    dispatch(updateStatusAction(course.courseId, token));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="choose-lesson-header">
        <h5>Kiểm duyệt khóa học</h5>
      </ModalHeader>
      <ModalBody>
        <div className="publish-modal">
          <div className="publish-tip">Tích vào ô tương ứng với phần mà bạn muốn xuất bản hoặc ngược lại</div>
          <div className="publish-modal-body">
            <div className={`frame-content-publish ${className}`}>
              <Item
                id={course.courseId}
                type="course"
                status={course.status}
                startIcon={<CourseIcon />}
                label={course.courseName}
                className={COURSE_ITEM}
              />
              {sections
                .map((section, _index) => {
                  return (
                    <Item
                      id={section.lessonId ? section.lessonId.id : section.testId.id}
                      startIcon={<SectionIcon />}
                      type={section.lessonId ? SECTION_TYPE.LESSON : SECTION_TYPE.TEST}
                      label={section.lessonId ? section.lessonId.title : section.testId.title}
                      className={SECTION_ITEM}
                      status={section.lessonId ? section.lessonId.status : section.testId.status}
                    />
                  );
                })}
              {/*chapters.map((chapter, index) => {
                return (
                  <>
                    <Item
                      id={chapter.chapterId}
                      type="chapter"
                      status={chapter.status}
                      startIcon={<ChapterIcon />}
                      label={chapter.chapterName}
                      className={CHAPTER_ITEM}
                    />
                    {chapter.sections
                      .filter((section) => section.type === SECTION_TYPE.TEST)
                      .map((section, _index) => {
                        return (
                          <Item
                            id={section.sectionId}
                            startIcon={<SectionIcon />}
                            type={SECTION_TYPE.TEST}
                            label={section.sectionName}
                            className={SECTION_ITEM}
                            status={section.status}
                          />
                        );
                      })}
                  </>
                );
              })*/}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="publish-modal-footer">
          {/* <ButtonAction size="small" className="btn-inactive" text="Từ chối" /> */}
          <ButtonAction size="small" text="Gửi" onClick={onClick} />
          {/* <ButtonAction size="small" text="Duyệt" /> */}
        </div>
      </ModalFooter>
    </Modal>
  );
};
