import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';
import ToggleSlider from '../../Common/toggleSlider/toggleSlider';
import { getFullUrl, imageCompress, getDirectoryUrlFromFile2 } from '../../../utils/imageProcessing';
import { deleteFile } from '../../../apis/upload';
import { IconDelete } from '../../../icons/IconSvg';

class BackgroundQuiz extends React.Component {
  state = {
    optionMultiChoice: false,
    optionMediaQuestion: false,
  };
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  readImg = async (event, learningCurrent, currentSlide) => {
    const compressedFile = await imageCompress(event.target.files[0]);
    if (learningCurrent.backgroundUrl !== '') await deleteFile(learningCurrent.backgroundUrl);
    const backgroundUrl = await getDirectoryUrlFromFile2(compressedFile);
    this.props.changeQuiz('backgroundUrl', backgroundUrl, 'quiz', currentSlide);
    return { ...learningCurrent, backgroundUrl };
  };
  render() {
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    return (
      <div style={{ padding: '0 20px 20px 20px', position: 'relative' }}>
        <div className="fl">
          <div style={{ margin: 'auto 10px auto 0' }}>Thiết lập ảnh nền cho bài tập</div>
          <button
            style={{ border: '1px solid #ced4da' }}
            className="btn"
            onClick={() => {
              if (this.dropzoneRefAvatar) {
                this.dropzoneRefAvatar.click();
              }
            }}
          >
            Tải ảnh
            <input
              style={{ display: 'none' }}
              type="file"
              multiple
              onChange={(e) => this.readImg(e, learningCurrent, currentSlide)}
              ref={(ref) => {
                this.dropzoneRefAvatar = ref;
              }}
            />
          </button>
        </div>
        {learningCurrent.backgroundUrl ? (
          <div className="wapper__img_modal">
            <img
              src={learningCurrent.backgroundUrl ? getFullUrl(learningCurrent.backgroundUrl) : ''}
              id="background_test"
            />
            <span onClick={() => this.props.changeQuiz('backgroundUrl', null, 'quiz', currentSlide)}>
              <IconDelete />
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {
  changeQuiz,
})(BackgroundQuiz);
