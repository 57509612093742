import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Fade from '../../components/Common/Fade';
import Loading from '../../components/Common/Loading';
import LessonLeft from '../../components/Lesson-v2/LessonLeft/index';
import LessonRight from '../../components/Lesson-v2/LessonRight/index';
import '../Lesson/style.scss';
import TabTemplate from '../../components/Lesson-v2/UITemplate/TabTemplate';
import ListTemplate from '../../components/Lesson-v2/UITemplate/common/ListTemplate';
import WraperIconFunction from '../../components/Lesson-v2/UITemplate/common/WraperIconFunction';
import { fetchCourseAction } from '../../reducers/courses';
import ComponentSelect from './ComponentSelect';
import './style.scss';
class QuestionBank extends React.Component {
  render() {
    return (
      <Fade>
        <div className="container wrapper_lesson div_question_bank">
          <div className="wrapper_quiz">
            <div className="content">
              <div className="wrapper_template">
                <div className="lesson__template fl">
                  <div style={{ width: '300px' }} className="ui__tab_function">
                    <div className="wraper_tab_function">
                      <ComponentSelect />
                      <WraperIconFunction inTestBank="true" />
                    </div>
                  </div>
                  <TabTemplate />
                  <ListTemplate />
                </div>
              </div>
              <div style={{ marginTop: '25px' }} className="fl div__content">
                <LessonLeft checkWelcome='false' inTestBank="true" />
                <LessonRight checkWelcome='false' inTestBank="true" />
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip effect="solid"/>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.lesson,
});

export default connect(mapStateToProps, {
  fetchCourseAction,
})(QuestionBank);
