import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlidesActions } from '../../../reducers/lesson';
import { ListTabFunction, lessonTemplates } from '../UITemplate/data';
import LessonRight from '../LessonRight';
import { FlagComponent } from './FlagComponent';
import './style.scss';
import backgroundMap from '../icons/Background map/test.svg';
import { Welcome } from '../LessonRight/welcome';
import { ListRightIcon } from './ListRightIcon';
import {TourContainer} from "../../Common/Tour/TourContainer";
import {getStorageItem, setStorageItem} from "../../../utils/localStorage";

export default function LessonLeft({ 
  versionPreview, 
  inTestBank, 
  quizTest,
  checkWelcome,
  isVersionAction = false
}) {
  const steps = [
    {
      selector: '#tour-lesson-v1',
      content: 'Sử dụng để tạo, chuyển vị trí hoặc xoá các slide',
      position: 'right',
      title: "Thanh công cụ"
    },
    {
      selector: '#tour-lesson-v2',
      content: 'Tạo một slide mới. Slide có thể là lý thuyết, bài tập hoặc điểm dừng.',
      position: 'bottom',
      title: "Nút tạo"
    },
    {
      selector: '#tour-lesson-v3',
      content: 'Xoá slide đang được chọn.',
      position: 'bottom',
      title: "Nút xoá"
    },
    {
      selector: '#tour-lesson-v4',
      content: 'Di chuyển slide được chọn lên trên',
      position: 'bottom',
      title: "Nút lên"
    },
    {
      selector: '#tour-lesson-v5',
      content: 'Di chuyển slide được chọn xuống dưới',
      position: 'bottom',
      title: "Nút xuống"
    },
  ];
  const [isTourTabAction, setTourTabAction] = useState(false);
  const { localState, currentSlide, syncing, welcome } = useSelector((state) => state.lesson);
  const { learningPoints, overview } = localState;
  const dispatch = useDispatch();

  const closeTour = () => {
    setStorageItem({
      key: "tour",
      value: JSON.stringify({
        leftAction: false,
        lessonRight: {
          isTour: true,
          type: ""
        }
      })
    });
    setTourTabAction(false);
  }
  useEffect(() => {
    let tourData = getStorageItem("tour");
    tourData = tourData ? JSON.parse(tourData) : null;
    if (tourData && tourData.leftAction) setTourTabAction(true);
  }, []);

  useEffect(() => {
    let x = document.querySelector('.lesson__left').scrollTop;
    document.querySelector('.lesson__left').scrollTop = x + 150;
  }, [learningPoints.length]);

  return (
    <>
      <div className="lesson__left">
        {!versionPreview &&
        (
          <div className="pd-10 title" id="tour-lesson-v1">
            <div className="tag_wrapper">
              {localState.learningPoints.length > 0
                ? localState.learningPoints.findIndex((data) => data.id === currentSlide) + 1
                : 0}
              /{localState.learningPoints.length}
            </div>
            {ListTabFunction.map((data, index) => (
              <div
                key={index}
                aria-hidden="true"
                onClick={() => (syncing ? null : dispatch(data.handleClick))}
                className="icon icon-lesson-left"
                data-tip={data.tooltip}
              >
                {data.icon(inTestBank, quizTest)}
              </div>
            ))}
          </div>
        )}
        <div style={{ height: '.5rem' }} />

        {/* ------------------------------------------------for learningPoint items -------------------------------------*/}
        {localState.learningPoints.map((data, index) => (
          <div
            aria-hidden="true"
            key={data.id}
            className={`item_slide ${currentSlide === data.id ? 'item_slide_active' : ''}`}
            onClick={() => {
              dispatch(SlidesActions.setCurrentSlide(data.id));
              dispatch(SlidesActions.updateLayoutOption(data.type.split(':')[0]));
            }}
            style={{ position: 'relative' }}
          >
            <div style={{ width: '1rem' }} />
            <div className="text-bold">
              <div className="m-auto">{index + 1}</div>
              <div className="icon__lesson">
                {(() => {
                  const tempalate = lessonTemplates.find((tab) => data.type.split(':')[0] === tab.name);
                  return tempalate.icon;
                })()}
              </div>
            </div>
            <div className="view">
              <LessonRight preview="learningPoint" previewLP={data} isShowAction={false}/>
            </div>
            <div style={{ width: '0.5rem' }} />
            {/* <FlagComponent flagId={data.id} /> */}
             {!versionPreview && (<ListRightIcon learningPoint={data} isVersionAction={isVersionAction} />)} 
          </div>
        ))}
      </div>
      {isTourTabAction && (
          <TourContainer steps={steps} isOpen={isTourTabAction} onCloseTour={closeTour} />
      )}
    </>
  );
}
