import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commentCourseAction, commentLessonAction, commentTestAction } from '../../actions/ActionFrameContent';
import { formatDate } from '../../utils/date';
import { ButtonAction } from '../Common/Button/ButtonAction';
import './style.scss';

export const CommentFrameContent = ({ comments = [], selected, addComment }) => {
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.auth);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const commentRef = useRef(null);

  const onChangeInput = (e) => {
    if (e.target.value) setInputValue(e.target.value);
  };

  const onClickComment = () => {
    const { type, id } = selected;
    const comment = {
        sendBy: {
            userId: authData.sid,
            username: authData.preferred_username
        },
        content: {
            text: inputValue
        }
    };
    if (type === 'course') {
      dispatch(commentCourseAction(id, comment));
    } else if (type === 'lesson') {
      dispatch(commentLessonAction(id, comment));
    } else {
      dispatch(commentTestAction(id, comment));
    }
    addComment(comment);
    if (inputRef) inputRef.current.value = '';
  };

  useEffect(() => {
    commentRef.current.scrollTop  = commentRef.current.scrollHeight;
  }, [comments])

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onClickComment();
    }
  }

  return (
    <div className="comment-container">
      <div className="comment-list" ref={commentRef}>
        {comments.map((comment, index) => {
          return (
            <div className="comment-element" key={`comment-${index}`}>
              <div className="comment-user">
                {/* <img /> */}
                <span>{comment.sendBy.username}</span>
              </div>
              <div className="comment-date">{formatDate(comment.createdAt)}</div>
              <div className="comment-content">{comment.content.text}</div>
            </div>
          );
        })}
      </div>
      <div className="comment-action-container">
        <input ref={inputRef} type="text" onChange={onChangeInput} onKeyPress={onKeyPress} />
        <ButtonAction text="Bình luận" onClick={onClickComment} />
      </div>
    </div>
  );
};
