import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';
import InputNumber from '../../Chapter/common/InputNumber';

class ScoreQuiz extends React.Component {
  render() {
    const { currentSlide, changeQuizAction, currentSlidePreview, learningPoints } = this.props;
    const learningCurrent =
      currentSlidePreview || learningPoints.find((learning) => learning.id === currentSlide) || {};
    return (
      <div className="fl" style={{ padding: '0 20px 20px 20px' }}>
        <div style={{ margin: '8px 10px' }}>Điểm bài tập:</div>
        <div style={{ width: '200px' }}>
          <InputNumber
            disabled={false}
            value={learningCurrent.score || ''}
            placeholder={'Nhập điểm'}
            changeData={(e) => {
              changeQuizAction('score', e.target.value ? parseFloat(e.target.value) : '', 'quiz', currentSlide);
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuizAction: changeQuiz })(ScoreQuiz);
