import React, {useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect'
import './theory.scss';
import { Components } from './Components';
import TitleVoiceBox from './TitleVoiceBox';
import { getFullUrl } from '../../utils/imageProcessing';

const _CustomObject = ({ previewLP, versionPreview, data }) => {
  const lessonSelector = state => state.lesson;
  const currentSlideSelector = createSelector(lessonSelector, ({ currentSlide }) => currentSlide);
  const lpSelector = createSelector(lessonSelector, ({ localState }) => localState.learningPoints);
  const currentSlide = useSelector(currentSlideSelector);
  const learningPoints = useSelector(lpSelector);
  const { customLayout, id } = useMemo(
    () => data || previewLP || learningPoints.find(({ id }) => id === currentSlide),
    [data, previewLP, currentSlide, learningPoints]
  );
  const dispatch = useDispatch();

  const handleChangeLP = (data) => {
    const { rateVoice } = data;
    let newData = data;
    if (rateVoice) newData = {...data, rateVoice: Number(rateVoice)}
    const newLPs = learningPoints.map((lp) => {
      if (lp.id === id) {
        return { ...lp, ...newData };
      }
      return lp;
    });
    dispatch({ type: 'CHANGE_LEARNING_POINTS', learningPoints: newLPs });
  };

  const titleComponent = useMemo(() => {
    const { title, id, titleVoice, rateVoice, titleAudioUrl } =
    previewLP || learningPoints.find(({ id }) => id === currentSlide);
    return (
      <div id={!previewLP ? "tour-lesson-theory-2" : "" } className="slide_title">
        <input
          className="title__input"
          type="text"
          placeholder="Nhập tiêu đề slide"
          value={title}
          onChange={(e) => handleChangeLP({ title: e.target.value })}
        />
        <TitleVoiceBox
          id={!previewLP ? "tour-lesson-theory-3" : "" }
          lpID={id}
          title={title}
          titleVoice={titleVoice}
          rateVoice={rateVoice}
          titleAudioUrl={getFullUrl(titleAudioUrl)}
          handleChangeLP={handleChangeLP}
          previewLP={previewLP}
          versionPreview={versionPreview}
        />
      </div>
    );
  }, [learningPoints, previewLP, currentSlide])

  return (
    <>
      {titleComponent}
      <Components
        components={[customLayout]}
        layout="horizontal"
        previewLP={previewLP}
        versionPreview={versionPreview}
      />
    </>
  );
};
const CustomObject = React.memo(_CustomObject);
export default CustomObject;

// <div className="splitter" />
