import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDate } from '../../../../../utils/date';
import { getFullUrl } from '../../../../../utils/imageProcessing';
import '../style.scss';

export const ListSection = ({ chapters }) => {
  const history = useHistory();
  const { idChapterActive } = useSelector(state => state.chapter);
  const currChapter = chapters ? chapters.find((e) => e.id === idChapterActive) : null;
  if (!currChapter) return null;

  return (
    <div className="list-sub-chapter">
      <div className="chapter-name">
        Chủ đề: <span>{currChapter?.title || ''}</span>
      </div>
      <div className="sub-chapters">
        {currChapter &&
          currChapter.sections &&
          currChapter.sections.map((section, index) => {
            return (
              <div className="general-box sub-chapter-item" key={`sub-chapter-${index}`}>
                <div className="sub-chapter-thumbnail">
                  <img src={getFullUrl(section?.lessonId?.imageUrl)} />
                </div>
                <div className="sub-chapter-content">
                  <div
                    className="sub-chapter-name"
                    onClick={() => {
                      history.push(`/admin/publish-management/lesson/${section?.lessonId?.id}`);
                    }}
                  >
                    {section?.lessonId?.title || ''}
                  </div>
                  <div className="sub-chapter-status">Trạng thái: {section?.lessonId?.status || ''}</div>
                  <div className="sub-chapter-modified">
                    <span>
                      Chỉnh sửa lần cuối cùng vào: <b>{formatDate(section?.lessonId?.updatedAt, 'DD/MM/YYYY')}</b>
                    </span>
                    <span>
                      Bởi: <b>Hoàng Đỗ</b>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
