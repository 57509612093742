import React, { useMemo, memo } from 'react'
import { Modal, ModalHeader, ModalBody, FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';

// constants
import { ORDER_COLLECTION_TYPES } from '../../../../constants/award-admin/orderCollectionType'

// utils
import { formatDate } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/number';

// local
import OrderCollectionDataTable from './OrderCollectionDataTable';
import OrderLuckyBoxDataTable from './OrderLuckyBoxDataTable';
import OrderItemDataTable from './OrderItemDataTable';

const OrderModal = ({ id, open, toggle }) => {
  // -- order --
  const {
    createdAt,
    user = {},
    type,
    totalPrice,
    collections = [],
    luckyBoxes = [],
    items = [],
  } = useSelector((state) => state.orders.data[id]) || {};

  // -- system collections & user collections --
  const { systemCollections, userCollections } = useMemo(
    () => {
      const systemCollections = []
      const userCollections = []

      collections.forEach(collection => {
        if (collection.type === ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION)
          systemCollections.push(collection)

        if (collection.type === ORDER_COLLECTION_TYPES.USER_COLLECTION)
          userCollections.push(collection)
      })

      return { systemCollections, userCollections }
    },
    [collections]
  )

  return (
    <Modal isOpen={open} toggle={toggle} size="xl">
      {/* modal header */}
      <ModalHeader toggle={toggle}>Chi tiết giao dịch</ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormGroup>
          {/* createdAt */}
          <div className="t-flex">
            <div className="t-w-[50%]">
              <p className="t-mb-[0.5rem]">Thời gian tạo:</p>
            </div>

            <div className="t-w-[50%] text-right">
              <p className="t-mb-[0.5rem]">{formatDate(createdAt)}</p>
            </div>
          </div>

          {/* totalPrice */}
          <div className="t-flex">
            <div className="t-w-[50%]">
              <p className="t-mb-[0.5rem]">Tổng giá trị giao dịch:</p>
            </div>

            <div className="t-w-[50%] text-right">
              <p className="t-mb-[0.5rem]">{`⭐ ${formatNumber(totalPrice)}`}</p>
            </div>
          </div>
        </FormGroup>

        {/* user */}
        <FormGroup>
          <p className='t-font-bold t-underline text-center t-mb-[0.75rem] t-text-[20px]'>Thông tin người mua</p>

          {/* full name */}
          <div className="t-flex">
            <div className="t-w-[50%]">
              <p className="t-mb-[0.5rem]">Họ tên:</p>
            </div>

            <div className="t-w-[50%] text-right">
              <p className="t-mb-[0.5rem]">{`${user.name || ''}`}</p>
            </div>
          </div>

          {/* phone number */}
          {/* <div className="t-flex">
            <div className="t-w-[50%]">
              <p className="t-mb-[0.5rem]">Số điện thoại:</p>
            </div>

            <div className="t-w-[50%] text-right">
              <p className="t-mb-[0.5rem]">{user.phone}</p>
            </div>
          </div> */}

          {/* email */}
          <div className="t-flex">
            <div className="t-w-[50%]">
              <p className="t-mb-[0.5rem]">Email:</p>
            </div>

            <div className="t-w-[50%] text-right">
              <p className="t-mb-[0.5rem]">{user.email}</p>
            </div>
          </div>
        </FormGroup>

        {/* products */}
        <FormGroup>
          <p className='t-font-bold t-underline text-center t-mb-[0.75rem] t-text-[20px]'>Thông tin sản phẩm</p>

          {/* systemCollections */}
          {systemCollections?.length > 0 && (
            <div className="mb-2 last:!t-mb-0">
              <p className='t-font-semibold'>Bộ sưu tập hệ thống:</p>

              <OrderCollectionDataTable collections={systemCollections} />
            </div>
          )}

          {/* userCollections */}
          {userCollections?.length > 0 && (
            <div className="mb-2 last:!t-mb-0">
              <p className='t-font-semibold'>Bộ sưu tập người dùng:</p>

              <OrderCollectionDataTable collections={userCollections} />
            </div>
          )}

          {/* luckyBoxes */}
          {luckyBoxes?.length > 0 && (
            <div className="mb-2 last:!t-mb-0">
              <p className='t-font-semibold'>Hộp may mắn:</p>

              <OrderLuckyBoxDataTable luckyBoxes={luckyBoxes} />
            </div>
          )}

          {/* items */}
          {items?.length > 0 && (
            <div className="mb-2 last:!t-mb-0">
              <p className='t-font-semibold'>Vật phẩm:</p>

              <OrderItemDataTable items={items} />
            </div>
          )}
        </FormGroup>
      </ModalBody>
    </Modal>
  );
};

export default memo(OrderModal);