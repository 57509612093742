import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { generateId } from '../../utils/generate';
import { getFullUrl } from '../../utils/imageProcessing';
import InputTextareaV2 from '../Quiz/common/InputTextAreaV2';
import { formatSquare } from '../Quiz/quizUtils';
import TitleVoiceBox from '../Theory/TitleVoiceBox';
import { getSquareUI, SQUARE_TEXT_LIMIT, SQUARE_TYPES_CONSTANTS } from './SquareUtils';

export const OptionSquare = React.memo(({ versionPreview, squareObject, handleChange, mediaObject, updateMediaObject, previewLP }) => {
  const [openModel, setOpenModel] = useState(false);
  const { localState, currentSlide } = useSelector((state) => state.lesson);
  const squareId = generateId(localState);

  const toggleModel = () => setOpenModel(!openModel);
  const { fullText, squares, type } = squareObject;
  const { text, rateVoice, voice, audioUrl } = mediaObject;
  const handleSquareChange = (value) => {
    const newSquares = SQUARE_TYPES_CONSTANTS.find(({ name }) => name === type).handleTextToObject(value);
    handleChange({ squares: formatSquare(newSquares) });
  };

  const handleChangeType = (e) => {
    handleChange({ type: e.target.value, fullText: fullText });
  };

  const handleChangeVoice = (data) => {
    const {titleAudioUrl, titleVoice, rateVoice} = data;
    if  (titleAudioUrl) {
      handleChange({audioUrl: titleAudioUrl});
      updateMediaObject(titleAudioUrl, 'audioUrl');
    }
    if (titleVoice) {
      handleChange({voice: titleVoice});
      updateMediaObject(titleVoice, 'voice');
    }
    if(rateVoice) {
      handleChange({rateVoice: rateVoice})
      updateMediaObject(rateVoice, 'rateVoice');
    }
  };

  useEffect(() => {
    // const renderSquare = setTimeout(() => {
      handleSquareChange(fullText);
    // }, 1000);

    // return () => clearTimeout(renderSquare);
  }, [fullText]);

  const renderSquareUI = () => {
    if (!squares || squares.length === 0)
      return (
        <div aria-hidden="true" onClick={toggleModel} className="cursor">
          {fullText ? 'Tiếng chưa chính xác' : 'Bài học trống'}
        </div>
      );

    return (
      <div className="fl_w flex-row">
        {squares && squares.map((element, index) => {     
          if(!element) return;
          if(!element.structure || !element.structure.rhyme || !element.structure.rhyme.structure) return null; 
          if(element.content.text === '\n') return <div key={index} style={{flexBasis: '100%'}}></div>
  
          const { content, structure } = element;
          const { consonant, rhyme, tone } = structure;
          const { startRhyme, mainRhyme, finishRhyme } = rhyme.structure;

          return getSquareUI(
            type,
            index,
            content,
            rhyme.content,
            consonant,
            tone,
            startRhyme,
            mainRhyme,
            finishRhyme,
            previewLP
          );
        }
        )}
      </div>
    );
  };

  const renderInfo = () => {
    return `Mô tả: ${SQUARE_TYPES_CONSTANTS.find(({ name }) => name === type).info}`;
  };

  const renderSquareDetail = () => (
    <Modal isOpen={openModel} toggle={toggleModel}>
      <ModalHeader>Chi tiết hình vuông</ModalHeader>
      <ModalBody>
        Đây là nơi để bạn tạo/sửa/xóa bài học hình vuông
        <hr />
        <div className="fl" style={{ alignItems: 'center' }}>
          <div className="text-bold">Bước 1: Chọn loại bài tập</div>
          <select
            value={type}
            className="btn"
            onChange={handleChangeType}
            style={{ border: '1px solid #c2c2c2', padding: '5px 5px', marginLeft: '10px' }}
          >
            {SQUARE_TYPES_CONSTANTS.map((sType, index) => (
              <option value={sType.name} className="div_item" key={index+1} style={{ textAlign: 'left'}}>
                {sType.title}
              </option>
            ))}
          </select>
        </div>
        {renderInfo()}
        <hr />
        <div style={{ height: '5px' }} />  
        <div className="fl">
          <div style={{marginRight: '20px'}} className="text-bold ">
            Bước 2: Chọn giọng đọc
          </div>
          <div>
            <TitleVoiceBox 
              lpID={currentSlide || ''}
              title={fullText || ''} 
              titleVoice={voice || ''}
              titleAudioUrl={getFullUrl(audioUrl) || ''}
              handleChangeLP={handleChangeVoice || ''}
              rateVoice={rateVoice}
            />
          </div>
        </div>
        <div style={{ height: '20px' }} />  
        <div className="text-bold">Bước 3: Nhập nội dung</div>
        <div style={{ height: '5px' }} />
        <InputTextareaV2
          value={fullText} 
          onChange={(value) => {
            handleChange({ fullText: value });
            updateMediaObject(value, 'text');
          }}
          limitText={SQUARE_TEXT_LIMIT.find(item => item.name === type)?.limit || 30}
        />
        <div style={{ height: '5px' }} />
        {renderSquareUI()}
      </ModalBody>
    </Modal>
  );

  return (
    <>
      {!versionPreview && renderSquareDetail()}
      <div
        aria-hidden="true"
        onClick={toggleModel}
        key={squareObject.id ? squareObject.id : squareId}
        className="fl flex-column cursor"
      >
        {renderSquareUI()}
      </div>
    </>
  );
});

OptionSquare.propTypes = {};

export default OptionSquare;
