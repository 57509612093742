/* eslint-disable no-loop-func */
// eslint-disable-next-line import/no-extraneous-dependencies
import { find } from 'lodash';
import { toast } from 'react-toastify';
import { deleteQuiz } from '../apis/quiz';
import { deleteTheory } from '../apis/theory';
import { updateLesson } from '../apis/lesson';
import { handleCreateQuiz, handleUpdateQuiz } from './ActionQuiz';
import { clasifyLearningPoints } from './LessonUtils';
import { oldLearningPointsCurrent } from './AudioQuiz';
import { handleCreateTheory, handleUpdateTheory } from './ActionTheory';
import { findIndexId, getLesson, SlidesActions } from '../reducers/lesson';
import { handleCreateCheckpoint, handleUpdateCheckpoint } from './ActionCheckpoint';
import { normalizeLearningPoints } from '../utils/normalize';
import { deleteCheckpoint } from '../apis/checkpoint';
import { getChapters } from '../apis/chapter';

const checkInCustomLayout = (customLayout) => {
  if ( !customLayout.mediaObject
    || (customLayout.mediaObject.type === 'text' && !customLayout.mediaObject.text)
    // || (customLayout.mediaObject.type === 'square' && !customLayout.mediaObject.squareObject.fullText)
  ) {
    toast.error("Bạn cần nhập đủ nội dung");
    return false;
  }
  else if (customLayout.mediaObject.type === 'image' && !customLayout.mediaObject.imageUrl) {
    toast.error("Bạn cần tải ảnh lên");
    return false;
  }
  else if (customLayout.mediaObject.type === 'video' && !customLayout.mediaObject.videoUrl) {
    toast.error("Bạn cần video lên");
    return false;
  }
  return true;
};

const recursiveChildren = (children) => {
  for(let child of children) {
    if (child.children && !recursiveChildren(child.children)) return false;
    if (!child.children && !checkInCustomLayout(child)) return false;
  }
  return true;
};

export const checkBeforeSaving = (learningPoints) => {
  if (learningPoints.length === 0) return true;
  for (let item of learningPoints) {
    const [learningPointType,  type] = item.type.split(':');

    if (learningPointType === 'theory') {
      if (!item.customLayout.children) {
        return checkInCustomLayout(item.customLayout);
      } else {
        return recursiveChildren(item.customLayout.children);
      }
    }
    else if (learningPointType === 'quiz') {
      if (!item.question) {
        toast.error("Bạn cần nhập câu hỏi");
        return false;
      }
      else if (item.quizParts.length === 0 && type !== 'writing_quiz') {
        toast.error("Bạn cần nhập nội dung cho bài tập");
        return false;
      }
    }
    else if (learningPointType === 'checkpoint') {

    }
  };
  return true;
};

export const saveUpdateLesson = (isTest = false, callback = () => {}) => async (dispatch, getState) => {
  dispatch({
    type: '@@lesson/SYNCING',
    value: true,
  });
  findChapterIdOfLesson()
  const courseId = getState().chapter.course.id;
  const chapterId = getState().chapter.idChapterActive;

  const currentLessonState = getState().lesson.localState;
  const learningPointsCurrent = getState().lesson.localState.learningPoints || [];
  const oldLearningPoints = getState().lesson.oldLocalState.learningPoints || [];
  const { speaker } = getState().voice;
  const { newQuizzes, fixQuizzes, toBeCreatedTheories, toBeUpdatedTheories, newCheckpoints, fixCheckpoints } =
    clasifyLearningPoints(oldLearningPoints, learningPointsCurrent);

  let idArray = learningPointsCurrent.map(element => {
    return {
      id: element.id,
      type: element.type
    }
  });
  const newLearningPoints = await Promise.all(
    learningPointsCurrent.map(async (learningPoint) => {
      // Theory: update
      if (find(toBeUpdatedTheories, learningPoint)) {
        const updatedTheory = await handleUpdateTheory(learningPoint);
        if (!updatedTheory || !updatedTheory.result || !updatedTheory.result.theory || !updatedTheory.result.theory.id) toast.error("Lỗi sửa lý thuyết");
        else return { ...learningPoint, id: updatedTheory.result.theory.id };
      }

      // Theory: create
      if (find(toBeCreatedTheories, learningPoint)) {
        const createdTheory = await handleCreateTheory(learningPoint, idArray);
        idArray.push({
          id: createdTheory.result.theory.id,
          type: learningPoint.type
        });
        if (!createdTheory || !createdTheory.result || !createdTheory.result.theory || !createdTheory.result.theory.id) toast.error("Lỗi tạo lý thuyết");
        else return { ...learningPoint, id: createdTheory.result.theory.id };
      }
      // Quiz: update
      if (find(fixQuizzes, learningPoint)) {
        const updateQuiz = await handleUpdateQuiz(
          learningPoint,
          oldLearningPointsCurrent(oldLearningPoints, learningPoint.id),
          speaker,
          chapterId,
          courseId,
        );
        if (!updateQuiz || !updateQuiz.result || !updateQuiz.result.quiz || !updateQuiz.result.quiz.id) toast.error("Lỗi sửa bài tập");
        else return { ...learningPoint, id: updateQuiz ? updateQuiz.result.quiz.id : learningPoint.id };
      }

      // Quiz: create
      if (find(newQuizzes, learningPoint)) {
        const createdQuiz = await handleCreateQuiz(learningPoint, speaker, chapterId, courseId, idArray);
        idArray.push({
          id: createdQuiz.result.quiz.id,
          type: learningPoint.type
        });
        if (!createdQuiz || !createdQuiz.result || !createdQuiz.result.quiz || !createdQuiz.result.quiz.id) toast.error("Lỗi tạp bài tập");
        else return {
          ...learningPoint,
          id:
            createdQuiz && createdQuiz.result && createdQuiz.result.quiz && createdQuiz.result.quiz.id
              ? createdQuiz.result.quiz.id
              : learningPoint.id,
        };
      }

      // Checkpoint : create
      if (find(newCheckpoints, learningPoint)) {
        const checkpoint = await handleCreateCheckpoint(learningPoint, idArray); // xu ly newCheckpoint
        idArray.push({
          id: checkpoint.result.checkpoint.id,
          type: learningPoint.type
        });
        return { ...learningPoint, id: checkpoint.result.checkpoint.id };
      }

      // Checkpoint : update
      if (find(fixCheckpoints, learningPoint)) {
        const updatedCheckpoint = await handleUpdateCheckpoint(learningPoint);
        return {
          ...learningPoint,
          id: updatedCheckpoint.result.checkpoint.id,
        };
      }

      // Else: default
      return learningPoint;
    }),
  );
  
  if (!isTest) {
    await updateLesson({ ...currentLessonState, learningPoints: newLearningPoints });

    dispatch(getLesson(currentLessonState.id));

    dispatch({
      type: '@@lesson/SYNCING',
      value: false,
    });
  
    toast.success('Bài học đã được update thành công');
  } else {
    dispatch({
      type: 'RECEIVE__LESSON',
      data: {
        ...currentLessonState,
        learningPoints: newLearningPoints,
      },
    });
  }
  callback(newLearningPoints);
};

export const deleteLearningPointer = (id) => async (dispatch, getState) => {
  const {localState, currentSlide, oldLocalState, layoutOption, welcome} = getState().lesson;
  const {learningPoints} = localState;
  const index = findIndexId(learningPoints, currentSlide);
  const Id = id ? id : currentSlide;
  const preId = learningPoints.length > 1 
                ? (index === 0 ? learningPoints[index + 1].id : learningPoints[index - 1].id) 
                : welcome.id;

  const newLearningPoints = learningPoints.filter(element => element.id !== Id);
  const {entities, learningPointIds} = normalizeLearningPoints(newLearningPoints);
  dispatch(SlidesActions.setCurrentSlide(Id === currentSlide ? preId : currentSlide));
  dispatch({
    type: '@@lesson/LOCALSTATE',
    data: {
      entities,
      learningPointIds,
      learningPoints: newLearningPoints,
    }
  });
  if(id === undefined) {
    const oldLearningPoints = oldLocalState.learningPoints;
    const dataCurrent =
      oldLearningPoints.length > 0
        ? oldLearningPoints.filter((lp) => lp.id === currentSlide && lp.type.split(':')[0] === layoutOption)
        : [];
    if (dataCurrent.length > 0) {
      let res = {};
      switch (layoutOption) {
        case 'quiz':
          res = await deleteQuiz(currentSlide);
          break;
        case 'theory':
          res = await deleteTheory(currentSlide);
          break;
        case 'checkpoint':
          res = await deleteCheckpoint(currentSlide);
      }
      if (res && res.status === 1) {
        toast.success('Xoá bài học thành công');
      }
    }
    // dispatch({
    //   type: 'SLIDE_DELETE',
    // });
  };
};

export const findChapterIdOfLesson = (lessonId) => async (dispatch, getState) => {
  const {data, idChapterActive} = getState().chapter;
  let chapterId = -1;
  if(idChapterActive !== "" && data !== []) {
    chapterId = idChapterActive;
  } else {
    const res = await getChapters();
    const listChapters = res.result.chapters;
    const chapterActive = listChapters.find(element => {
      const {sections} = element;
      const index = sections.findIndex(ele => ele.type === 'lesson' && ele.lessonId.id == lessonId);
      if(index !== -1) return element;
    });
    chapterId = chapterActive.id;
  }
  if(chapterId !== -1) {
    dispatch({
      type: '@@chapters/SET_ACTIVE_CHAPTER',
      id: chapterId
    });
  }else {
    toast.error("chapter error");
  }
}

const swapLearningPoint = (learningPoints, currIndex, changeIndex) => {
  const temp = learningPoints[currIndex];
  learningPoints[currIndex] = learningPoints[changeIndex];
  learningPoints[changeIndex] = temp;
  return learningPoints;
}

export const shiftSlideUp = () => (dispatch, getState) => {
  const { currentSlide, localState } = getState().lesson;
  const { learningPoints } = localState;

  const index = findIndexId(learningPoints, currentSlide);
  if (index <= 0) toast.error('Không thể thao tác');
  else if (index >= 1) {
    const newLearningPoints = swapLearningPoint(learningPoints, index, index - 1);
    dispatch({type: 'CHANGE_LEARNING_POINTS', learningPoints: newLearningPoints});
  }
};

export const shiftSlideDown = () => (dispatch, getState) => {
  const { currentSlide, localState } = getState().lesson;
  const { learningPoints } = localState;

  const index = findIndexId(learningPoints, currentSlide);
  if (index === -1 || index === learningPoints.length - 1) toast.error('Không thể thao tác');
  else if (index >= 0 && index < learningPoints.length - 1) {
    const newLearningPoints = swapLearningPoint(learningPoints, index, index + 1);
    dispatch({type: 'CHANGE_LEARNING_POINTS', learningPoints: newLearningPoints});
  }
};