import React, { useState, forwardRef, useRef, useEffect, useMemo } from 'react'
import { FormText } from 'reactstrap'
import { BiCalendarAlt } from 'react-icons/bi'
import { useFormContext, useController } from 'react-hook-form'
import cls from 'classnames'
import DatePicker from 'react-datepicker'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { formatInTimeZone } from 'date-fns-tz'
import NumberFormat from 'react-number-format'
import usePrevious from '@react-hook/previous'
import ObjectId from 'bson-objectid'
// utils
import useClickOutside from '../../hooks/useClickOutside'
import { formatDate, isValidDateString, zonedDateToLocalDate } from '../../utils/date'
// const
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MONTH_FULL_YEAR_FORMAT,
  DEFAULT_FULL_YEAR_FORMAT,
} from '../../constants/date'
// styles
import 'react-datepicker/dist/react-datepicker.css'

// Define custom input types
export const INPUT_TYPES = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
}

// input number format options
const dateOptions = { placeholder: DEFAULT_DATE_FORMAT, format: '##/##/####', mask: '_' }
const monthOptions = { placeholder: DEFAULT_MONTH_FULL_YEAR_FORMAT, format: '##/####', mask: '_' }
const yearOptions = { placeholder: DEFAULT_FULL_YEAR_FORMAT, format: '####', mask: '_' }

// transform onChanges
const transformOnChangeForDateInput =
  ({ defaultOnChange, setDate, setInnerValue, dateFormat, setInnerError, fixedTimezone }) =>
    (e) => {
      const { value: dateString, shouldUpdateFormValue = true } = e.target

      // set inner value
      setInnerValue(dateString)

      if (dateString) {
        // check date string is a valid date format
        if (isValidDateString(dateString, dateFormat)) {
          // clear inner error
          setInnerError(null)

          let selectedDate = parse(dateString, dateFormat, new Date())
          // convert zoned date to local date
          if (fixedTimezone) {
            selectedDate = zonedDateToLocalDate(selectedDate, fixedTimezone)
          }

          if (shouldUpdateFormValue) {
            if (INPUT_TYPES.DAY) defaultOnChange(selectedDate?.toISOString())
            else defaultOnChange(dateString)
          }

          setDate(selectedDate)
        }
        // set error when date string is not valid date format
        else setInnerError({ message: 'Ngày không hợp lệ' })
      } else {
        // clear inner error
        setInnerError(null)

        shouldUpdateFormValue && defaultOnChange(null)
        setDate(null)
      }
    }

// get date picker format
const getDatePickerOptions = (type) => {
  switch (type) {
    case INPUT_TYPES.DAY:
      return { dateFormat: DEFAULT_DATE_FORMAT }
    case INPUT_TYPES.MONTH:
      return { dateFormat: DEFAULT_MONTH_FULL_YEAR_FORMAT }
    case INPUT_TYPES.YEAR:
      return { dateFormat: DEFAULT_FULL_YEAR_FORMAT }
    default:
      return { dateFormat: DEFAULT_DATE_FORMAT }
  }
}

// get input options and onChange
const getDateInputOptions = ({
  type,
  defaultOnChange,
  setDate,
  setInnerValue,
  dateFormat,
  setInnerError,
  fixedTimezone,
}) => {
  switch (type) {
    case INPUT_TYPES.DAY:
      return {
        ...dateOptions,
        onChange: transformOnChangeForDateInput({
          defaultOnChange,
          setDate,
          setInnerValue,
          dateFormat,
          setInnerError,
          fixedTimezone,
        }),
      }
    case INPUT_TYPES.MONTH:
      return {
        ...monthOptions,
        onChange: transformOnChangeForDateInput({
          defaultOnChange,
          setDate,
          setInnerValue,
          dateFormat,
          setInnerError,
          fixedTimezone,
        }),
      }
    case INPUT_TYPES.YEAR:
      return {
        ...yearOptions,
        onChange: transformOnChangeForDateInput({
          defaultOnChange,
          setDate,
          setInnerValue,
          dateFormat,
          setInnerError,
          fixedTimezone,
        }),
      }
    default:
      throw new Error("Unknown InputDateField type")
      // return { options: dateOptions, onChange: defaultOnChange }
  }
}

// get formatted date for read only
const getFormattedDateForReadOnly = (type, dateString) => {
  try {
    switch (type) {
      case INPUT_TYPES.DAY:
        return formatDate(new Date(dateString))
      case INPUT_TYPES.MONTH:
        return formatDate(new Date(dateString), DEFAULT_MONTH_FULL_YEAR_FORMAT)
      case INPUT_TYPES.YEAR:
        return formatDate(new Date(dateString), DEFAULT_FULL_YEAR_FORMAT)
      default:
        return formatDate(new Date(dateString))
    }
  } catch (error) {
    return ''
  }
}

const EmptyInput = forwardRef((props, ref) => <input ref={ref} {...props} className="t-hidden" />)

const InputDateField = ({
  className,
  name,
  id,
  type = INPUT_TYPES.DAY,
  inputProps = {},
  datePickerProps: inputDateProps = {},
  readOnly,
  fixedTimezone,
}) => {
  // -- form object --
  const { control, errors } = useFormContext()

  // -- field state --
  const {
    field: { ref, onChange: defaultOnChange, ...field },
    meta,
  } = useController({ name, control })
  const { invalid } = meta
  const prevFieldValue = usePrevious(field.value)

  // -- date format --
  const { dateFormat } = getDatePickerOptions(type)

  // -- state --
  const [date, setDate] = useState(null)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [innerValue, setInnerValue] = useState(() => {
    const dateString = field.value || ''
    // empty date string
    if (!dateString) return ''
    // if timezone is provided, show date in custom timezone instead of local timezone
    if (fixedTimezone) return formatInTimeZone(new Date(dateString), fixedTimezone, DEFAULT_DATE_FORMAT)
    // return date in local timezone
    return format(new Date(dateString), dateFormat)
  })
  const [innerError, setInnerError] = useState(null)

  // -- has error --
  const hasError = !!innerError || !!invalid

  const dateContainerRef = useRef()
  useClickOutside(dateContainerRef, () => setOpenDatePicker(false)) // hide date picker

  // -- input options --
  const options = getDateInputOptions({
    type,
    defaultOnChange,
    setDate,
    setInnerValue,
    dateFormat,
    setInnerError,
    fixedTimezone,
  })
  const { onChange } = options
  const onDateInputFocus = () => {
    if (inputProps?.disabled) return

    // show dater picker
    setOpenDatePicker(true)
  }

  // -- date picker --
  const [datePickerKey] = useState(ObjectId().toString())
  const onDateChange = (selectedDate) => {
    const dateString = format(selectedDate, dateFormat)
    onChange({ target: { value: dateString } })
    setOpenDatePicker(false) // hide date picker
  }

  // reset inner value
  useEffect(() => {
    if (prevFieldValue !== field.value) {
      const dateString = field.value ? format(new Date(field.value), dateFormat) : ''
      onChange({ target: { value: dateString, shouldUpdateFormValue: false } })
    }
  }, [field.value])

  // readonly
  if (readOnly) {
    const formattedDate = getFormattedDateForReadOnly(type, field.value)
    return (
      <>
        <p className="font-weight-bold mb-0">{formattedDate || '---'}</p>
      </>
    )
  }

  return (
    <>
      {/* date picker and date input */}
      <div className="t-relative t-min-w-[140px]" ref={dateContainerRef}>
        <NumberFormat
          name={name}
          id={id}
          className={cls('form-control', { 'is-invalid': hasError }, inputProps?.className)}
          {...field}
          getInputRef={ref}
          {...options}
          value={innerValue || ''}
          onChange={onChange}
          onFocus={onDateInputFocus}
          {...inputProps}
          data-type="date-input"
        />
        <DatePicker
          key={`${datePickerKey}-${date}`}
          open={openDatePicker}
          dateFormat={dateFormat}
          selected={date}
          onChange={onDateChange}
          customInput={<EmptyInput />}
          popperPlacement="bottom-start"
          popperModifiers={[{ name: 'flip', enabled: false }]}
          showMonthYearPicker={type === INPUT_TYPES.MONTH}
          showYearPicker={type === INPUT_TYPES.YEAR}
          {...inputDateProps}
        />

        {/* show calendar icon if no error */}
        {!hasError && (
          <BiCalendarAlt
            className={cls('t-absolute t-top-10 t-right-9', { 't-cursor-pointer': !inputProps?.disabled })}
            size={18}
            onClick={onDateInputFocus}
          />
        )}
      </div>

      {/* error  */}
      {(innerError || errors[name]) && <FormText color="danger">{innerError ? innerError.message : errors[name].message}</FormText>}
    </>
  )
}

export default InputDateField
