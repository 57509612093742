import React, { useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import cls from 'classnames';

// local
import InputField from './InputField';
import TitleVoiceBox2 from '../Theory/TitleVoiceBox2';

const InputVoiceField = ({ className, fieldName, fieldVoiceUrlName, fieldVoiceUrlId, ...props }) => {
  // -- form state --
  const { watch, setValue } = useFormContext();

  // -- field value --
  const fieldValue = watch(fieldName);
  const fieldVoiceUrl = watch(fieldVoiceUrlName);

  // -- voice state --
  const [titleVoice, setTitleVoice] = useState();
  const [rateVoice, setRateVoice] = useState();

  return (
    <>
      {fieldValue?.trim().length > 0 && (
        <TitleVoiceBox2
          style={{ width: 'fit-content', marginLeft: '10px' }}
          lpID={`title_voice_box_${fieldName}`}
          title={fieldValue}
          titleVoice={titleVoice}
          rateVoice={rateVoice}
          titleAudioUrl={fieldVoiceUrl}
          handleChangeLP={({ titleVoice: newTitleVoice, rateVoice: newRateVoice, titleAudioUrl }) => {
            if (titleAudioUrl) {
              setValue(fieldVoiceUrlName, titleAudioUrl);
            }
            if (newTitleVoice) {
              setTitleVoice(newTitleVoice);
            }
            if (newRateVoice) {
              setRateVoice(newRateVoice);
            }
          }}
        />
      )}

      <InputField type="text" name={fieldVoiceUrlName} id={fieldVoiceUrlId} className="d-none" />
    </>
  );
};

export default InputVoiceField;
