import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import Fade from '../Fade';

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #000000cb;
  z-index: 5;
`;

const loadingDatas = [
  'Đang tải bài học, đợi chút xong ngay đây :D',
  'Nếu ngồi lâu quá, nhớ đứng dậy chút cho đỡ mỏi lưng nhé :)',
  'Vươn vai cái nào :D',
  'Chúc bạn một ngày tốt lành :P',
  'Đang tải bài học, load mấy tí nữa thôi :P',
];

function Loading({ notText = false }) {
  return (
    <LoadingWrapper>
      <Fade>
        <Spinner size="sm" color="light" />{' '}
        {!notText && <span style={{ color: '#ffffff' }}>{loadingDatas[Math.floor(Math.random() * loadingDatas.length)]}</span>}
      </Fade>
    </LoadingWrapper>
  );
}

export default Loading;
