import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addHorizontalToCustomLayout,
  addMediaObjectToCustomLayout,
  addVerticalToCustomLayout,
} from '../../reducers/lesson';
import { generateId } from '../../utils/generate';
import { MediaObject } from './MediaObject';
import { TheoryPlaceholder } from './TheoryPlaceholder';

export const Components = React.memo(({ versionPreview, layout, ratio, components, previewLP }) => {
  const dispatch = useDispatch();
  const { localState } = useSelector((state) => state.lesson);

  const onPlaceholderSelected = (id) => ({ type, placeholder }) => {
    switch (type) {
      case 'new-mediaObject':
        dispatch(addMediaObjectToCustomLayout(id, placeholder));
        break;

      case 'split-left-right':
        dispatch(addHorizontalToCustomLayout(id, placeholder));
        break;

      case 'split-up-down':
        dispatch(addVerticalToCustomLayout(id, placeholder));
        break;

      default:
        break;
    }
  };
  return (
    <div
      className={ratio}
      style={{
        display: 'flex',
        flexDirection: layout === 'vertical' ? 'column' : 'row',
        flex: ratio,
        width: '100%',
        height: layout === 'vertical' ? '100%' : 'auto',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '30px'
        // maxWidth: 935,
      }}
    >
      {components.map((comp) => {
        const id = comp ? comp.id : generateId(localState);

        if (!comp || (!comp.children && !comp.mediaObject)) {
          return <TheoryPlaceholder key={id} onChange={onPlaceholderSelected(id)} previewLP={previewLP}/>;
        }
        if (comp.children && comp.children.length > 0) {
          return <Components versionPreview={versionPreview} key={id} components={comp.children} layout={comp.layout} ratio={comp.ratio} previewLP={previewLP}/>;
        }
        if (comp.mediaObject) {
          return <MediaObject versionPreview={versionPreview} style={comp.style} key={id} customLayoutID={id} mediaObject={comp.mediaObject} ratio={comp.ratio} previewLP={previewLP} />;
        }

        return 'this is a blank space';
      })}
    </div>
  );
});

Components.propTypes = {
  layout: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Components.defaultProps = {
  ratio: 1,
};

export default Components;
