import api from './api';

export const getPaths = async () =>
  api({
    method: 'GET',
    url: '/paths',
  });

export const getPath = async (pathId) =>
  api({
    method: 'GET',
    url: `/paths/${pathId}`,
  });

export const createPath = async ({ title, imageUrl, description, scope }) =>
  api({
    method: 'POST',
    url: '/paths',
    data: {
      title,
      imageUrl,
      description,
      scope,
    },
  });

export const updatePath = async (pathId, updateObject) =>
  api({
    method: 'PUT',
    url: `/paths/${pathId}`,
    data: updateObject,
  });

export const deletePath = async (pathId) =>
  api({
    method: 'DELETE',
    url: `/paths/${pathId}`,
  });

export const commentPath = async (pathId, comment) => {
  const res = await api({
    method: 'POST',
    url: `/paths/comment/${pathId}`,
    data: { comment },
  });
  return res;
};
