import { find } from 'lodash';
import { toast } from 'react-toastify';
import { createTheory, updateTheory } from '../apis/theory';
import { updateLesson } from '../apis/lesson';
import { getSoundFile, getSoundUrl } from '../apis/voice';
import { checkDuplicateId } from '../utils/array';
import { handleAudioUrl } from '../utils/downloadAudio';
import { denormalizeLearningPoint, normalizeLearningPoint } from '../utils/normalize';
import { clasifyTheories, addImageUrlIntoLesson } from './LessonUtils';

export const saveTheoryChanges = () => async (dispatch, getState) => {
  dispatch({
    type: '@@lesson/SYNCING',
    value: true,
  });

  const currentLessonState = getState().lesson.localState;
  const oldLearningPoints = getState().lesson.oldLocalState.learningPoints || [];
  const learningPoints = getState().lesson.localState.learningPoints || [];

  const { toBeCreatedTheories, toBeUpdatedTheories } = clasifyTheories(oldLearningPoints, learningPoints);

  const newLearningPoints = await Promise.all(
    learningPoints.map(async (learningPoint) => {
      // theory đươc update
      if (find(toBeUpdatedTheories, learningPoint)) {
        const updatedTheory = await updateTheory(learningPoint.id, learningPoint);
        return { ...learningPoint, id: updatedTheory.result.theory.id };
      }

      // theory đươc create
      if (find(toBeCreatedTheories, learningPoint)) {
        const createdPayload = await createTheory(learningPoint);
        return { ...learningPoint, id: createdPayload.result.theory.id };
      }

      // quiz đươc update
      // update anh? neu nhu anh bi thay doi
      // updateQuiz

      // quiz đươc create
      // tao anh? neu nhu anh bi thay doi
      // createQuiz

      return learningPoint;
    }),
  );

  await updateLesson({ ...currentLessonState, learningPoints: newLearningPoints });

  dispatch({
    type: '@@lesson/SYNCING',
    value: false,
  });
};

export const saveLessonProfile = () => async (dispatch, getState) => {
  try {
    const currentLessonState = getState().lesson.localState;
    const urlizedLesson = await addImageUrlIntoLesson(currentLessonState);

    await updateLesson({ ...urlizedLesson });
    toast.success('Đã cập nhật Profile của bài học');
  } catch (error) {
    toast.error('Có lỗi');
  }
};

const handleTheoryAudioUrl = async (entities) => {
  const { mediaObjects, learningPoints } = entities;

  await Promise.all(
    Object.keys(learningPoints).map(async (e) => {
      if (learningPoints[e].titleAudioUrl && learningPoints[e].titleAudioUrl.includes('{api_url}')) return e;

      let titleAudioUrl;

      // call api
      if (!learningPoints[e].titleVoice) learningPoints[e].titleVoice = 'ngochuyen';
      const urlPayload = await getSoundUrl(learningPoints[e].titleVoice, learningPoints[e].title, learningPoints[e].rateVoice);
      titleAudioUrl = urlPayload.data.wav_path;

      // get file
      titleAudioUrl = await handleAudioUrl(titleAudioUrl);
      learningPoints[e].titleAudioUrl = titleAudioUrl;
    }),
  );

  await Promise.all(
    Object.keys(mediaObjects).map(async (e) => {
      if (
        (mediaObjects[e].type !== 'text' && mediaObjects[e].type !== 'square') ||
        (mediaObjects[e].audioUrl && mediaObjects[e].audioUrl.includes('{api_url}'))
      )
        return e;
      if (!mediaObjects[e].text || mediaObjects[e].text === '') return e;

      let audioUrl;
      if (mediaObjects[e].audioUrl) {
        audioUrl = mediaObjects[e].audioUrl;
      } else {
        if (!mediaObjects[e].voice) mediaObjects[e].voice = 'ngochuyen';
        const urlPayload = await getSoundUrl(mediaObjects[e].voice, mediaObjects[e].text, mediaObjects[e].rateVoice);
        audioUrl = urlPayload.data.wav_path;
      }

      try {
        // get file
        audioUrl = await handleAudioUrl(audioUrl);
        mediaObjects[e].audioUrl = audioUrl;
      } catch (error) {
        // refetch a new image
        const response = await getSoundUrl('ngochuyen', mediaObjects[e].text);
        audioUrl = response.data.wav_path;
        // get file
        audioUrl = await handleAudioUrl(audioUrl);
        mediaObjects[e].audioUrl = audioUrl;
      }
    }),
  );

  return { ...entities, mediaObjects };
};

export const handleUpdateTheory = async (learningPoint) => {
  const { entities, learningPointId } = normalizeLearningPoint(learningPoint);

  const newEntites = await handleTheoryAudioUrl(entities);
  const newLearningPoints = denormalizeLearningPoint([learningPointId], newEntites);
  const updatedTheory = await updateTheory(newLearningPoints[0].id, newLearningPoints[0]);
 
  return updatedTheory;
};

export const handleCreateTheory = async (learningPoint, idArray) => {
  const { entities, learningPointId } = normalizeLearningPoint(learningPoint);

  const newEntites = await handleTheoryAudioUrl(entities);

  const newLearningPoints = denormalizeLearningPoint([learningPointId], newEntites);
  let check = true;
  let createdTheory = {};
  while (check) {
    createdTheory = await createTheory(newLearningPoints[0]);
    if (createdTheory && createdTheory.status === 1 && createdTheory.result.theory)
      check = checkDuplicateId(idArray, createdTheory.result.theory.id, 'theory');
    else {
      toast.error("ERROR API");
      break;
    }
  }
  return createdTheory;
};