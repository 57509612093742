import apiV2 from '../apiV2';

export const uploadOneFile = async (formData) => {
  return await apiV2({
    method: 'POST',
    url: `/file-storage/fileStorage/uploadOneFile`,
    data: formData
  });
}

export const uploadOneUrl = async (formData) => {
  return await apiV2({
    method: 'POST',
    url: `/file-storage/fileStorage/uploadOneUrl`,
    data: formData
  });
}