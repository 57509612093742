import apiV2 from '../apiV2';

export const getAll = async ({ page = 1, limit = 10, keyword, sort = { createdAt: -1 }, shouldPopulateCollectionCategory }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionTemplate/all`,
    data: { page, limit, keyword, sort, shouldPopulateCollectionCategory }
  });
}

export const getOne = async ({ _id }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionTemplate/one`,
    data: { _id }
  });
}

export const upsert = async (dataToUpdate) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/collectionTemplate/upsert`,
    data: dataToUpdate
  });
}