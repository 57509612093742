import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import { getSoundUrl } from '../../apis/voice';
import { IconInfo, IconPauseCircle, IconPlayCircle, IconSelectDown, IconTextField } from '../../icons/IconSvg';
import { getVoiceLabel, TEXT_FORMAT, voices } from '../../utils/constants';
import { generateUUID } from '../../utils/generate';
import { getFullUrl } from '../../utils/imageProcessing';
import PopoverCustom, { PopoverCustomUseUnController } from '../Popover/PopoverCustom';
import { InputRangeVoice } from '../Quiz/common/InputRangeVoice';
import './style.scss';

function _OptionText({ versionPreview, handleChange, textObject, icon }) {
  // const { text, hiddenText, rateVoice, textFormat, audioUrl, media, voice, type } = textObject;
  const [textObj, setTextObj] = useState(textObject);
  const { text, hiddenText, rateVoice, textFormat, audioUrl, media, voice, type } = textObj;

  const [popoverOpenVoice, setPopoverOpenVoice] = useState(false);
  const toggleVoice = () => setPopoverOpenVoice(!popoverOpenVoice);
  const [popoverOpenType, setPopoverOpenType] = useState(false);
  const toggleType = () => setPopoverOpenType(!popoverOpenType);

  const [lastCallTTS, setLastCallTTS] = useState({ voice, text, rateVoice });
  const [audio, setaudio] = useState(media);
  const [audioState, setaudioState] = useState('pause');
  const [loadVoiceState, setloadVoiceState] = useState(false);
  const [idText] = useState(generateUUID().substring(0, 2));
  const textAreaRef = useRef(null);

  const callAudioApi = () => {
    if (!versionPreview) {
      setloadVoiceState(true);
      getSoundUrl(voice, text, rateVoice)
        .then(function (response) {
          setLastCallTTS({ voice, text, rateVoice });
          setaudio(new Audio(response.data.wav_path));
          handleChange({ audioUrl: response.data.wav_path });
          setaudioState('loading');
          setloadVoiceState(false);
        })
        .catch(function (error) {
          console.error(error);
          setloadVoiceState(false);
        });
    }
  };

  // Trigger get new audio when text or sound changed
  useEffect(() => {
    if (!voice || !text || !rateVoice) return () => null;

    // After 3000ms if this still alive then run
    const timeOutTyping = setTimeout(() => {
      if (lastCallTTS.text === text && lastCallTTS.voice === voice && lastCallTTS.rateVoice === rateVoice)
        return () => clearTimeout(timeOutTyping);
      callAudioApi();
    }, 1000);

    return () => clearTimeout(timeOutTyping);
  }, [voice, text, rateVoice]);
  useEffect(() => {
    let isCancelled = false;

    if (!audio || !audio.src) {
      setaudioState('loading');
      return () => {
        isCancelled = true;
      };
    }

    audio.onplaying = () => {
      setaudioState('playing');
    };
    audio.onpause = () => {
      setaudioState('paused');
    };
    audio.onerror = (e) => {
      if (isCancelled) return () => null;
      setaudioState('paused');
    };

    audio.onloadeddata = () => {
      if (isCancelled) return () => null;
      setaudioState('paused');
    };
    return () => {
      isCancelled = true;
    };
  }, [audio]);

  useEffect(() => {
    setaudio(media);
  }, [media]);

  useEffect(() => {
    // Reset field height
    textAreaRef.current.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(textAreaRef.current);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      textAreaRef.current.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    textAreaRef.current.style.height = `${height}px`;
  }, [text]);
  const changeText = (e) => {
    setTextObj({ ...textObj, text: e.target.value });
  };
  const changeVoice = (speaker) => {
    setTextObj({ ...textObj, voice: speaker });
  };
  useEffect(() => {
    let timer = setTimeout(() => {
      handleChange({ text });
    }, 1000);

    return () => clearTimeout(timer);
  }, [text]);

  useEffect(() => {
    let timer = setTimeout(() => {
      handleChange({ voice });
    }, 500);

    return () => clearTimeout(timer);
  }, [voice]);

  useEffect(() => {
    setTextObj(textObject);
  }, [textObject]);

  const renderAudioIcon = () => {
    switch (audioState) {
      case 'loading':
        return <Spinner size="sm" color="dark" />;

      case 'playing':
        return <IconPauseCircle />;

      case 'paused':
        return <IconPlayCircle />;

      default:
        return <IconPlayCircle />;
    }
  };

  return (
    <>
      <div className="ui__option__theory">
        <div className="ui__wraper_option">
          <div className="option__text">
            {icon && icon === 'none' ? null : <IconTextField />}

            <div className="div__wraper">
              <div className="div__wraper_content">
                <div className="div__void">
                  <div>
                    <div className="div__select_void fl_b">
                      <span
                        id={`voice-${idText}`}
                        onClick={toggleVoice}
                        onBlur={toggleVoice}
                        aria-hidden="true"
                        className="cursor"
                      >
                        {getVoiceLabel(voice)}
                        {!versionPreview && (
                          <PopoverCustomUseUnController
                            id={`voice-${idText}`}
                            width="160px"
                            toggle={toggleVoice}
                            popoverOpen={popoverOpenVoice}
                          >
                            {voices.map((v) => (
                              <div
                                aria-hidden="true"
                                key={v.speaker}
                                className="div_item"
                                onClick={() => changeVoice(v.speaker)}
                                style={v.speaker === voice ? { fontWeight: 'bold' } : null}
                              >
                                {v.label}
                              </div>
                            ))}
                            <InputRangeVoice handleChangeLP={handleChange} rateVoice={rateVoice} />
                          </PopoverCustomUseUnController>
                        )}
                        {/* <PopoverCustom
                          id={`voice-${idText}`}
                          width="150px"
                          toggle={toggleVoice}
                          popoverOpen={popoverOpenVoice}
                        >

                        </PopoverCustom> */}
                      </span>
                      {!versionPreview && ((audio && audioUrl) || voice) && (
                        <span
                          aria-hidden="true"
                          className="cursor"
                          onClick={() => {
                            if (audio) {
                              if (audio.paused) audio.play();
                              else audio.pause();
                            }
                          }}
                        >
                          {renderAudioIcon()}
                        </span>
                      )}
                      {loadVoiceState && <Spinner size="sm" color="dark" />}
                    </div>
                    <div className="">
                      <textarea
                        ref={textAreaRef}
                        defaultValue={text}
                        onChange={changeText}
                        placeholder="Nhập nội dung vào đây ...."
                        style={hiddenText ? { color: '#C4C4C4' } : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fl_b cursor div_select_option_text">
              <div
                aria-hidden="true"
                id={`text-type-${idText}`}
                className="cursor"
                onClick={toggleType}
                onBlur={toggleType}
              >
                <IconSelectDown />
                <span>
                  {textFormat}
                  <PopoverCustom
                    id={`text-type-${idText}`}
                    width="275px"
                    toggle={toggleType}
                    popoverOpen={popoverOpenType}
                  >
                    <div
                      aria-hidden="true"
                      onClick={() => handleChange({ textFormat: TEXT_FORMAT.DOAN_VAN_THUONG })}
                      className="div_item"
                    >
                      Đoạn văn thường
                    </div>
                    <div
                      aria-hidden="true"
                      onClick={() => handleChange({ textFormat: TEXT_FORMAT.THO_LUC_BAT })}
                      className="div_item"
                    >
                      Thơ lục bát
                    </div>
                  </PopoverCustom>
                </span>
              </div>
              <div>
                <IconInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const OptionText = React.memo(_OptionText);
export default OptionText;
