import React from 'react';
import { Button } from 'reactstrap'

// local
import CollectionItemModal from '../CollectionItemModal';
import CollectionItemDataTableCtrl from './CollectionItemDataTable.ctrl';

const Actions = ({ className }) => {
  // -- ctrl --
  const {
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
  } = CollectionItemDataTableCtrl.useContainer()

  return (
    <div className={className}>
      {/* Add */}
      <Button
        color="primary"
        onClick={() => {
          setModalItemId()
          setIsModalOpen(true)
        }}
      >
        Thêm
      </Button>

      {isModalOpen && (<CollectionItemModal open={isModalOpen} toggle={toggleModal} id={modalItemId} />)}
    </div>
  );
};

export default Actions;