import { FRAME_CONTENT_STATUS, STATUS_ACTION, SECTION_STATUS, PATH_STATUS } from '../constants';

export const getSectionStatus = (status) => {
  let result = '';
  switch (status) {
    case SECTION_STATUS.APPROVED:
      result = 'Đã duyệt';
      break;
    case SECTION_STATUS.DRAFT:
      result = 'Nháp';
      break;
    case SECTION_STATUS.IN_REVIEW:
      result = 'Đang kiểm duyệt';
      break;
    case SECTION_STATUS.REJECTED:
      result = 'Đã từ chối';
      break;
    case SECTION_STATUS.REQUEST_TO_CHANGE:
      result = 'Cần thay đổi';
      break;
    case SECTION_STATUS.SUBMITTED:
      result = 'Đã nộp';
      break;
    case SECTION_STATUS.SUSPENDED:
      result = 'Đã bị đình chỉ';
      break;
    default:
      result = 'unknown';
  }
  return result;
};

export const getFrameContentStatus = (status) => {
  let result = '';
  switch (status) {
    case FRAME_CONTENT_STATUS.PUBLISH:
      result = 'Xuất bản';
      break;
    case FRAME_CONTENT_STATUS.DRAFT:
      result = 'Nháp';
      break;
    case FRAME_CONTENT_STATUS.IN_REVIEW:
      result = 'Đang kiểm duyệt';
      break;
    case FRAME_CONTENT_STATUS.SUBMITTED:
      result = 'Đã nộp';
      break;
    case FRAME_CONTENT_STATUS.NOT_PUBLISH:
      result = 'Không xuất bản';
      break;
    default:
      result = 'unknown';
  }
  return result;
};

export const getPathStatus = (status) => {
  let result = '';
  switch (status) {
    case PATH_STATUS.PUBLISH:
      result = 'Xuất bản';
      break;
    case PATH_STATUS.DRAFT:
      result = 'Nháp';
      break;
    case PATH_STATUS.SUBMITTED:
      result = 'Đã nộp';
      break;
    case PATH_STATUS.NOT_PUBLISH:
      result = 'Không xuất bản';
      break;
    case PATH_STATUS.REJECTED:
      result = 'Từ chối';
      break;
    default:
      result = 'unknown';
  }
  return result;
};

export const getListAction = (status) => {
  let result = [];
  switch (status) {
    case SECTION_STATUS.APPROVED:
      result = [STATUS_ACTION.SUSPEND, STATUS_ACTION.REQUEST_TO_CHANGE];
      break;
    case SECTION_STATUS.DRAFT:
      result = [];
      break;
    case SECTION_STATUS.IN_REVIEW:
      result = [STATUS_ACTION.REJECT, STATUS_ACTION.REQUEST_TO_CHANGE, STATUS_ACTION.APPROVE];
      break;
    case SECTION_STATUS.REJECTED:
      result = [];
      break;
    case SECTION_STATUS.REQUEST_TO_CHANGE:
      result = [];
      break;
    case SECTION_STATUS.SUBMITTED:
      result = [];
      break;
    case SECTION_STATUS.SUSPENDED:
      result = [STATUS_ACTION.APPROVE];
      break;
    default:
      result = [];
  }
  return result;
};
