import React from 'react';
import './style.scss';
import {IconAdd, IconMinus} from '../../../icons/IconSvg';

const LessonNavTemplateRight = () => {

    return (
        <div className="LessonNavTemplate_right">
            <div className="Map_Enlarge">
                <IconAdd />
                <input type="text" defaultValue="100%"/>
                <IconMinus />
            </div>
            <div className="Map">
                Image
            </div>
            <div>
                <button>Reset</button>
                <button>Đổi map</button>
            </div>
        </div>
    );
};

export default LessonNavTemplateRight;