import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { upsertCollectionTemplate } from '../../../../reducers/award-admin/collectionTemplate';

// local
import { formValidationSchema as collectionTemplateFormValidationSchema } from './CollectionTemplateForm/CollectionTemplateForm';

const useCollectionTemplateModalCtrl = ({ id, toggle }) => {
  // -- collection template --
  const { collectionCategoryIds, collectionCategories, bgImageUrl, bgSoundUrl } =
    useSelector((state) => state.collectionTemplates.data[id]) || {};

  // -- form state --
  const collectionTemplateForm = useForm({
    resolver: yupResolver(collectionTemplateFormValidationSchema),
    defaultValues: {
      id,
      collectionCategoryIds,
      collectionCategories,
      previewBgImageUrl: bgImageUrl,
      bgImage: null,
      previewBgSoundUrl: bgSoundUrl,
      bgSound: null,
    },
  });
  const { handleSubmit } = collectionTemplateForm;

  // -- dispatch --
  const dispatch = useDispatch();

  // -- on submit --
  const onSubmit = handleSubmit(async ({ collectionCategoryIds, collectionCategories, bgImage, bgSound }) => {
    if (
      await dispatch(
        upsertCollectionTemplate({
          _id: id,
          collectionCategoryIds,
          collectionCategories,
          bgImage: bgImage && bgImage[0],
          bgImageUrl,
          bgSound: bgSound && bgSound[0],
          bgSoundUrl,
        }),
      )
    )
      toggle();
  });

  return {
    // form state
    collectionTemplateForm,
    // on submit
    onSubmit,
  };
};

export default createContainer(useCollectionTemplateModalCtrl);
