import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { getAllCollectionCategories } from '../../../../reducers/award-admin/collectionCategory';
import { upsertCollectionItem } from '../../../../reducers/award-admin/collectionItem';

// local
import { formValidationSchema as collectionItemFormValidationSchema } from './CollectionItemForm/CollectionItemForm';

const useCollectionItemModalCtrl = ({ id, toggle } = {}) => {
  // -- collection item --
  const {
    imageUrl,
    name,
    nameVoiceUrl,
    description,
    descriptionVoiceUrl,
    collectionCategory,
    collectionCategoryId,
    rarity,
    status,
    publishQuantity,
    price,
  } = useSelector((state) => state.collectionItems.data[id]) || {};

  // -- form state --
  const collectionItemForm = useForm({
    resolver: yupResolver(collectionItemFormValidationSchema),
    defaultValues: {
      id,
      previewImageUrl: imageUrl,
      image: null,
      name,
      nameVoiceUrl,
      description,
      descriptionVoiceUrl,
      collectionCategoryName: collectionCategory?.name,
      collectionCategoryId,
      rarity,
      status,
      publishQuantity,
      price,
    },
  });
  const { handleSubmit } = collectionItemForm;

  // -- dispatch --
  const dispatch = useDispatch();

  // -- on submit --
  const onSubmit = handleSubmit(
    async ({
      name,
      nameVoiceUrl,
      description,
      descriptionVoiceUrl,
      collectionCategoryId,
      rarity,
      status,
      publishQuantity,
      price,
      image,
    }) => {
      if (
        await dispatch(
          upsertCollectionItem({
            _id: id,
            name,
            nameVoiceUrl,
            description,
            descriptionVoiceUrl,
            collectionCategoryId,
            rarity,
            status,
            publishQuantity,
            price,
            image: image && image[0],
            imageUrl,
          }),
        )
      )
        toggle();
    },
  );

  return {
    // form state
    collectionItemForm,
    // on submit
    onSubmit,
  };
};

export default createContainer(useCollectionItemModalCtrl);
