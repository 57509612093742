import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePathAction } from '../../../../reducers/paths';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Photo from '../../../../components/Common/Photo';
import './style.scss';

const ModalUpdateInfo = ({ path, open, toggle }) => {
  const { register, handleSubmit } = useForm({ defaultValues: path });
  const [type, setType] = useState(path.scope.scopeType)
  const [previewUrl, setPreviewUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const updatePathInfo = async (data) => {
    const { title, description, thumbnail } = data;
    const scope = {
        scopeType: type
    }
    dispatch(updatePathAction(path.id, { title, description, status: null, scope, coursesInfo: null, newImageFile: thumbnail[0] }));
    toggle()
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreviewUrl(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader className="path-update-header">Cập nhật thông tin lộ trình học</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(updatePathInfo)}>
          <FormGroup>
            <Label htmlFor="Title">Tên lộ trình học</Label>
            <Input type="text" name="title" id="Title" placeholder="Tieng Viet 1" innerRef={register} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Description">Miêu tả về lộ trình học</Label>
            <Input
              type="textarea"
              name="description"
              id="Description"
              placeholder="Miêu tả về lộ trình học này..."
              innerRef={register}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phạm vi lộ trình học</Label>
            <br />
            <Label style={{ marginLeft: '20px' }} >
              <Input
                type="radio"
                name="publicRadio"
                id="typePath public"
                checked={type === 'PUBLIC' ? true : false}
                onChange={(e) => setType('PUBLIC')}
              />{' '}
              Công khai
            </Label>
            <Label style={{ marginLeft: '80px' }}>
              <Input
                type="radio"
                name="protectRadio"
                id="typePath protect"
                checked={type === 'PROTECT' ? true : false}
                onChange={(e) => setType('PROTECT')}
              />{' '}
              Giới hạn
            </Label>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="File">Ảnh bìa</Label>
            <Input type="file" name="thumbnail" id="File" innerRef={register} onChange={onSelectFile} />
            <Photo
              className="my-2"
              style={{ maxWidth: 400, maxHeight: 400, borderRadius: '5px' }}
              src={previewUrl || path.imageUrl}
            />
          </FormGroup>
          <Button>Cập nhật lộ trình học</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalUpdateInfo;
