import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitLessonAction } from '../../../../actions/ActionPublishManagement';
import { saveQuizzesBank } from '../../../../actions/ActionQuestionBank';
import { saveQuizzesTest } from '../../../../actions/ActionTest';
import { saveUpdateLesson } from '../../../../actions/ActionLesson';
import { SECTION_STATUS } from '../../../../constants';
import ModalPreviewApp from '../../../previewApp';
import { VersionComment } from '../../../PublishManagement/VersionComment';
import StyleSlide from '../../../StyleSlide';

function LessonActions({ inTestBank, viewQuiz }) {
  const { syncing, localState } = useSelector((state) => state.lesson);
  const [isOpenComment, setOpenComment] = useState(false);
  const dispatch = useDispatch();

  const SubmitButton = () => (
    <div>
      <button
        type="button"
        disabled={syncing}
        className={`btn btn-sm btn-primary btn-save ${
          localState.version && localState.version.status !== SECTION_STATUS.IN_REVIEW ? '' : 'btn-disable'
        }`}
        style={{
          width: '70px',
          height: '39px',
          backgroundColor: '#2B429D',
          borderColor: '#2B429D',
        }}
        onClick={() => {
          if (localState.version && localState.version.status !== SECTION_STATUS.IN_REVIEW) {
            setOpenComment(true);
          }
        }}
      >
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM12 19C10.34 19 9 17.66 9 16C9 14.34 10.34 13 12 13C13.66 13 15 14.34 15 16C15 17.66 13.66 19 12 19ZM15 9H5V5H15V9Z"
            fill="white"
          />
        </svg>{' '}
        {syncing ? '...' : 'Nộp'}
      </button>
    </div>
  );

  const onClick = (comment) => {
    dispatch(submitLessonAction(comment));
    setOpenComment(false);
  };

  return (
    <div className="lesson__actions fl">
      <ModalPreviewApp />
      <div style={{ width: '5px', hight: '45px' }} />
      <SubmitButton />
      <div style={{ width: '100%', hight: '45px' }} />
      <StyleSlide />
      {isOpenComment && (
        <div className="submit-comment">
          <VersionComment onClick={onClick} onClose={() => setOpenComment(false)} />
        </div>
      )}
    </div>
  );
}

export default LessonActions;
