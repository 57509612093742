import api from './api';
import { SECTION_STATUS } from '../constants';

export const submitPath = async ({ pathId }) =>
  api({
    method: 'PUT',
    url: `/path-submit`,
    data: {
      pathId: pathId,
    },
  });

export const publishPath = async (id, data, token) =>
  api({
    method: 'PUT',
    url: `/path-publish/${id}`,
    data: {
      updateData: data,
      accessToken: token
    }
});

export const searchPath = async (title, data) => {
  const response = await api({
    method: 'POST',
    url: `/path/search?title=${title}`,
    data
  });
  return response?.result;
};

export const getFromQueryPath = async (data) => {
  if (data.status && data.status.length === 0) {
      data.status = Object.values(SECTION_STATUS);
  }
  const response = await api({
      method: 'POST',
      url: '/path/filter',
      data
  });
  return response?.result;
}

export const getVerPathById = async (pathId) =>
  api({
    method: 'GET',
    url: `/ver-path/${pathId}`,
  });
