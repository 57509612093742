import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Status } from '../../components/Common/ChangeStatus';
import {approveTest} from '../../actions/ActionTest'
class ComponentLeft extends React.Component {
  constructor(props) {
    super(props);
    this.changeStatus = this.changeStatus.bind(this);
  }

  changeStatus = () => {
    const { course, chapterCurrent } = this.props;
    const {id} = chapterCurrent;
    const {status} = this.props.test.dataTest.status;
    if(status !== 'APPROVED') {
      this.props.approveTest({
        testId: this.props.test.dataTest.id,
        chapterId: id,
        "comment": 'change',
        status: status === 'DRAFT' ? 'APPROVED': 'DRAFT'
      });
    };
  }

  render() {
    const { course, chapterCurrent } = this.props;
    return (
      <div className="component_left">
        <div>
          <div className="pd-10 text-center text-bold">
            <div className="fl" style={{ alignItems: "center" }}>
              <div className="div_logo">
                <img style={{ width: '45px', height: '45px' }} src="/logo.svg" alt="" />
              </div>
              <div style={{ marginLeft: "8px" }}>
                <Link
                  style={{ color: '#FFF', fontSize: '22px' }}
                  className="fs-25"
                  to={`/course/${course?.id}`}
                >
                  {course?.title}
                </Link>
              </div>
            </div>
          </div>
          <div className="info_detail_test" style={{fontSize: '18px'}}>
            <h4 style={{ margin: '10px 0 0 20px'}}>
              Thông tin khoá học
            </h4>
            <div style={{
              margin: '10px 0 0 20px'
            }}>
              Khoá học: {course?.title || ''}
            </div>
            <div style={{
              margin: '10px 0 0 20px'
            }}>
              <span>Chủ đề: </span>
              <span>{chapterCurrent?.title || ''}</span>
            </div>
          </div>
          <hr style={{color: 'white'}}/>
          <div style={{
            margin: '10px 0 0 20px'
          }}>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  test: state.test,
});
export default connect(mapStateToProps, {
  approveTest
})(ComponentLeft);
