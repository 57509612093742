import { toast } from 'react-toastify';
import { removeFileFromDB, getDirectoryUrlFromFile2, imageCompress } from '../utils/imageProcessing';
import { getPaths, getPath, createPath, deletePath, updatePath } from '../apis/path';
import { submitPath, getVerPathById, publishPath } from '../apis/publishPath';

const initState = {
  data: [],
  syncing: false,
  versionPath: [],
  diff: {
    statusDiff: [],
  },
};

export const ActionTypes = {
  SET_DATA: '@@paths/SET_DATA',
  SET_VERSION_PATH: '@@paths/SET_VERSION_PATH',
  SET_SYNCING: '@@paths/SET_SYNCING',
  SET_SYNCING: '@@paths/SET_SYNCING',
  UPDATE_STATUS_DIFF: '@@paths/UPDATE_STATUS_DIFF',
  CLEAR_STATUS_DIFF: '@@paths/CLEAR_STATUS_DIFF',
};

export const fetchPathAction = () => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  try {
    const res = await getPaths();
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: ActionTypes.SET_DATA,
      data: res.result.paths.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    });
  } catch (error) {
    console.error(error);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const fetchVersionPath = (pathId) => async (dispatch) => {
  try {
    const res = await getVerPathById(pathId);
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: ActionTypes.SET_VERSION_PATH,
      data: res.result,
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchPathById = (pathId) => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  const { data } = getState().paths;
  try {
    const res = await getPath(pathId);
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: ActionTypes.SET_DATA,
      data: data.find((element) => element.id === res.result.path.id)
        ? data.map((element) => {
            if (element.id === res.result.path) return res.result.path;
            else return element;
          })
        : [res.result.path],
    });
  } catch (error) {
    console.error(error);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const createPathAction = (path) => async (dispatch, getState) => {
  const { title, file, description, type } = path;
  const scope = {
    scopeType: type === 'PUBLIC' ? 'PUBLIC' : 'PROTECT',
  };
  const { data } = getState().paths;
  try {
    const compressedFile = await imageCompress(file);
    const imageUrl = await getDirectoryUrlFromFile2(compressedFile);
    const res = await createPath({ title, imageUrl, description, scope });
    if (res.code) return toast.error(res.message);
    dispatch({ type: ActionTypes.SET_DATA, data: [...data, res.result.newPath] });
    return toast.success(`Đã tạo lộ trình học: ${title}`);
  } catch (error) {
    //console.error(error);
  }
};

export const updatePathAction =
  (pathId, { title, description, status, scope, coursesInfo, newImageFile = null }) =>
  async (dispatch, getState) => {
    dispatch({ type: ActionTypes.SET_SYNCING, data: true });
    const { data: allPaths } = getState().paths;
    const oldPath = allPaths.find((element) => element.id === pathId);
    try {
      let imageUrl;
      if (newImageFile) {
        removeFileFromDB(oldPath.imageUrl);
        const compressedFile = await imageCompress(newImageFile);
        imageUrl = await getDirectoryUrlFromFile2(compressedFile);
      } else {
        imageUrl = oldPath.imageUrl;
      }

      const reduxPathData = {
        id: oldPath.id,
        title: title || oldPath.title,
        description: description || oldPath.description,
        imageUrl,
        coursesInfo:
          coursesInfo ||
          oldPath.coursesInfo.map((element) => {
            return {
              courseId: element.courseId.id,
            };
          }),
        status: status || 'DRAFT',
        scope: scope || oldPath.scope,
      };

      const res = await updatePath(pathId, reduxPathData);
      dispatch({
        type: ActionTypes.SET_DATA,
        data: allPaths.map((element) =>
          element.id === pathId
            ? {
                id: res.result.id,
                title: res.result.title,
                description: res.result.description,
                imageUrl: res.result.imageUrl,
                coursesInfo: res.result.coursesInfo,
                status: res.result.status,
                scope: res.result.scope,
              }
            : element,
        ),
      });
      toast.success(`Đã cập nhật lộ trình học: ${oldPath.title}`);
    } catch (error) {
      console.error('error', error);
    }
    dispatch({ type: ActionTypes.SET_SYNCING, data: false });
  };

export const deletePathAction = (path, callbackFn) => async (dispatch, getState) => {
  const { data } = getState().paths;
  try {
    removeFileFromDB(path.imageUrl);
    const res = await deletePath(path.id);
    if (res.code) return toast.error(res.message);
    dispatch({ type: ActionTypes.SET_DATA, data: data.filter(({ id }) => id !== path.id) });
    toast.success(`Đã xóa lộ trình học: ${path.title}`);
    callbackFn();
  } catch (error) {
    //console.log('error', error);
  }
};

export const moveCourse = (courseId, startIndex, endIndex, pathId) => (dispatch, getState) => {
  const { data: paths } = getState().paths;

  // 1/2 calculate new coursesInfo
  const coursesInfo = paths.find((element) => element.id === pathId).coursesInfo;

  if (coursesInfo[startIndex].id !== courseId) {
    console.log('nothing changed');
    return;
  }

  const result = [...coursesInfo];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // 2/2 update it into current path
  const newPaths = [...paths];
  newPaths.find(({ id }) => id === pathId).coursesInfo = result;
  dispatch({ type: ActionTypes.SET_DATA, data: newPaths });
};

export const submitPathAction = (path) => async (dispatch, getState) => {
  const { data: paths } = getState().paths;
  try {
    const res = await submitPath({ pathId: path.id });
    if (res.code) return toast.error('Yêu cầu thất bại');
    const pathAfterSubmit = paths.map((element) => {
      if (element.id === path.id) element.status = 'SUBMITTED';
      return element;
    });
    dispatch({ type: ActionTypes.SET_DATA, data: pathAfterSubmit });
    toast.success(`Đã nộp lộ trình học: ${path.title}`);
  } catch (error) {
    //console.log('error', error);
  }
};

export const updateFieldPath =
  ({ id, key, value, setPath, path }) =>
  (dispatch, getState) => {
    if (key === 'status' && value !== path.status) {
      const newPath = {
        ...path,
        status: value,
      };

      dispatch({
        type: ActionTypes.UPDATE_STATUS_DIFF,
        data: { id, type: 'path', status: value },
      });
      setPath(newPath);
    }
  };

export const updateFieldCourse =
  ({ id, key, value, setPath, path }) =>
  (dispatch, getState) => {
    if (key === 'status' && value !== path.coursesInfo.find((element) => element.courseId.id === id).courseId.status) {
      const newCoursesInfo = path.coursesInfo.map((element) => {
        if (element.courseId.id === id)
          return {
            ...element,
            courseId: {
              ...element.courseId,
              status: value,
            },
          };
        else return element;
      });
      const newPath = {
        ...path,
        coursesInfo: newCoursesInfo,
      };
      dispatch({
        type: ActionTypes.UPDATE_STATUS_DIFF,
        data: { id, type: 'course', status: value },
      });
      setPath(newPath);
    }
  };

export const paths = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SYNCING:
      return {
        ...state,
        syncing: action.data,
      };

    case ActionTypes.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case ActionTypes.SET_VERSION_PATH:
      return {
        ...state,
        versionPath: action.data,
      };
    case ActionTypes.UPDATE_STATUS_DIFF:
      let statusDiff = [];
      if (state.diff.statusDiff.find((element) => element.id === action.data.id)) {
        statusDiff = state.diff.statusDiff.map((element) => {
          if (element.id === action.data.id)
            return {
              ...element,
              status: action.data.status,
            };
          else return element;
        });
      } else {
        statusDiff = [...state.diff.statusDiff, action.data];
      }
      return {
        ...state,
        diff: {
          statusDiff: statusDiff,
        },
      };
    case ActionTypes.CLEAR_STATUS_DIFF:
      return {
        ...state,
        diff: {
          statusDiff: [],
        },
      };
    default:
      return state;
  }
};

export default paths;
