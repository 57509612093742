import React, { useMemo, memo } from 'react'
import Select from 'react-select'
import { FormText } from 'reactstrap'
import { useFormContext, useController } from 'react-hook-form'
import cls from 'classnames'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'

const SelectField = ({ className, name, id, defaultValue, options: selectOptions, selectProps = {} }) => {
  // -- select options --
  const options = selectProps.options || selectOptions
  const optionsMapping = useMemo(
    () =>
      _reduce(
        options,
        (memo, option) => {
          memo[option.value] = option
          return memo
        },
        {},
      ),
    [options],
  )

  const { onChange, isMulti } = selectProps

  // -- form state --
  const { control, errors } = useFormContext()

  // -- field state --
  const { field, meta } = useController({
    name,
    control,
    defaultValue: isMulti ? _map(defaultValue || [], 'value') : defaultValue?.value,
  })
  const { invalid } = meta

  // -- custom select props --
  const selectedOption = useMemo(() => {
    if (selectProps?.value) return selectProps.value
    // multi
    if (isMulti) return _map(field.value || [], (selected) => optionsMapping[selected])
    // single
    return optionsMapping[field.value] || undefined
  }, [field.value, isMulti, optionsMapping, selectProps?.value])
  const customOnChange = (inputSelected, actionMeta) => {
    const selected = isMulti ? _map(inputSelected || [], 'value') : inputSelected?.value

    if (onChange) {
      return onChange(
        selected,
        (newValue) => {
          field.onChange(newValue || '')
        },
        actionMeta,
      )
    }
    field.onChange(selected || (isMulti ? [] : ''))
  }

  // select style
  const selectStyles = useMemo(
    () => ({
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      ...selectProps?.styles,
    }),
    [selectProps?.styles],
  )

  return (
    <>
      <Select
        className={cls('', selectProps.className, { 'is-invalid': !!invalid })}
        classNamePrefix="select"
        name={name}
        id={id}
        options={options}
        menuPlacement="auto"
        menuPosition="fixed"
        {...selectProps}
        {...field}
        value={selectedOption}
        onChange={customOnChange}
        styles={selectStyles}
      />

      {errors[name] && <FormText color="danger">{errors[name].message}</FormText>}
    </>
  )
}

export default memo(SelectField)
