import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Photo from '../Common/Photo';
import { GiHighlighter } from 'react-icons/gi';
import { IoSettingsSharp } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { updateCourseProfileAction } from '../../reducers/courses';
import { submitFrameContentAction } from '../../actions/ActionFrameContent';

const ChapterLeft = ({ course }) => {
  const [openUpdateCourse, setOpenUpdateCourse] = useState(false);
  const dispatch = useDispatch()
  const submitFrameContent = () => {
    dispatch(submitFrameContentAction(course));
  };

  return (
    <div className="left-side">
      <div className="left-side__info">
        <h2>Thông tin khóa học</h2>
        <div className="course-details-title">
          <GiHighlighter color="white" />
          <p>Tiêu đề:</p>
        </div>
        <div className="course-details-content">
          <p>{course.title}</p>
        </div>
        <div className="course-details-title">
          <GiHighlighter color="white" />
          <p>Mô tả:</p>
        </div>
        <div className="course-details-content">
          <p>{course.description}</p>
        </div>
        <div className="course-details-title">
          <GiHighlighter color="white" />
          <p>Ảnh bìa:</p>
        </div>
        <div className="course-details-content">
          <Photo alt={course.title} src={course.imageUrl} />
        </div>
        <div className="course-details-title">
          <GiHighlighter color="white" />
          <p>Phạm vi:</p>
        </div>
        <div className="course-details-content">
          <p>{course.scope.scopeType === 'PUBLIC' ? 'Công khai' : 'Giới hạn'}</p>
        </div>
      </div>
      <div className="left-side__button">
        <button onClick={() => setOpenUpdateCourse(true)} className='button_activated'>
          <IoSettingsSharp />
          <p>Sửa thông tin khóa học</p>
        </button>
        <button onClick={() => submitFrameContent()} className={course.status === 'PUBLISH' ? 'button_disabled' : 'button_activated'}>
          <IoSettingsSharp />
          <p>Nộp khóa học</p>
        </button>
      </div>
      {openUpdateCourse && (
        <UpdateCurriculum
          course={course}
          open={openUpdateCourse}
          toggle={() => setOpenUpdateCourse(!openUpdateCourse)}
        />
      )}
    </div>
  );
};

const UpdateCurriculum = ({ course, open, toggle }) => {
  const { register, handleSubmit } = useForm({ defaultValues: course });
  const [previewUrl, setPreviewUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [type, setType] = useState(course.scope.scopeType)
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const updateCurriculum = async (data) => {
    const { title, description, thumbnail } = data;
    const scope = {
      scopeType: type
    }
    dispatch(updateCourseProfileAction(course.id, { title, description, status: null, scope, sections: null, newImageFile: thumbnail[0] }));
    toggle()
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreviewUrl(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader className="course-update-title">Cập nhật thông tin khóa học</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(updateCurriculum)}>
          <FormGroup>
            <Label htmlFor="Title">Tên khóa học</Label>
            <Input type="text" name="title" id="Title" placeholder="Tieng Viet 1" innerRef={register} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Description">Miêu tả về khóa học</Label>
            <Input
              type="textarea"
              name="description"
              id="Description"
              placeholder="Mieu ta ve giao trinh nay..."
              innerRef={register}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phạm vi khóa học</Label>
            <br />
            <Label style={{ marginLeft: '20px' }} >
              <Input
                type="radio"
                name="publicRadio"
                id="typePath public"
                checked={type === 'PUBLIC' ? true : false}
                onChange={(e) => setType('PUBLIC')}
              />{' '}
              Công khai
            </Label>
            <Label style={{ marginLeft: '80px' }}>
              <Input
                type="radio"
                name="protectRadio"
                id="typePath protect"
                checked={type === 'PROTECT' ? true : false}
                onChange={(e) => setType('PROTECT')}
              />{' '}
              Giới hạn
            </Label>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="File">Ảnh thumbnail</Label>
            <Input type="file" name="thumbnail" id="File" innerRef={register} onChange={onSelectFile} />
            <Photo
              className="my-2"
              style={{ maxWidth: 400, maxHeight: 400, borderRadius: '5px' }}
              src={previewUrl || course.imageUrl}
            />
          </FormGroup>
          <Button>Cập nhật khóa học</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ChapterLeft;
