


const initState = {
    id: null,
    lessonId: null,
    onMove: false,
    iconArray: [
        {
            preState: {
                x: 0,
                y: 0
            },
            currentState: {
                x: 0,
                y: 0
            },
        }
    ],
    map: '',
    template: '',
    icons: []
};

export const uniNavTemplate = (state = initState, action) => {
    switch (action.type) {
        case 'CHANGE_LESSON_NAVIGATION_TEMPLATE':
            return {
                ...state,
                [action.data.type] : action.data.value 
            }
        case 'CHANGE_ICON_ARRAY_LESSON_NAVIGATION_TEMPLATE':
     
            // const customIndex = action.data.index === 0 ? state.iconArray.length : action.data.index ;
            const temp = [...state.iconArray];
            temp[action.data.index] = {
                ...state.iconArray[action.data.index],
                [action.data.type] : action.data.value 
            }
            return {
                ...state,
                iconArray: temp
            }
        default:
            return state;
    }
};

export default uniNavTemplate;