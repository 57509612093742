import _ from 'lodash';
import { generateId, getRndInteger } from './generate';

/**
 * Compare differences between two arrays
 * @param {Array<Object>} arrayNew new array
 * @param {Array<Object>} arrayOld old array
 * @returns the differences of new array to old array
 */
export const getArrayDifferences = (arrayNew, arrayOld) => {
  return _.differenceWith(arrayNew, arrayOld, _.isEqual);
};

/**
 * Substraction of two arrays
 * @param {Array<Object>} arrayWhole all elements
 * @param {Array<Object>} arrayMinus elements that need to be removed
 * @returns = arrayWhole - arrayMinus
 */
export const substractArray = (arrayWhole, arrayMinus) => {
  return _.subtract(arrayWhole, arrayMinus);
};

export const addIDRecursively = (obj) => {
  // if obj is an array then call addIDRecursively on all its items
  if (Array.isArray(obj)) obj.forEach(addIDRecursively);
  // otherwise if it's an object (the "obj &&" part is needed because typeof null === "object")
  else if (obj && typeof obj === 'object') {
    if (!obj.id) obj.id = generateId(); // add the id property to it
    Object.keys(obj).forEach((k) => addIDRecursively(obj[k])); // and then call add recursively on all its items
  }
};
export const addIDRecursivelyV2 = (obj) => {
  let newObj = obj;
  if (Array.isArray(newObj)) {
    newObj.forEach(element => {
      if(element.id) element = {
        ...element,
        id: generateId(),
      };
      return addIDRecursivelyV2(element);
    });
  }
  if (newObj && typeof newObj === 'object') {
    Object.keys(newObj).forEach(element => {
      if(newObj[element] && newObj[element].id) {
        newObj[element] = {
          ...newObj[element],
          id: generateId(),
        };
      }
      return addIDRecursivelyV2(newObj[element]);
    });
  }
  return newObj;
};
// delete recursively mediaObjects in theoryObject
export const deleteRecursively = (obj) => {
  if (Array.isArray(obj)) obj.forEach(deleteRecursively);
  else if (obj && typeof obj === 'object') {
    delete obj.mediaObjects; // do the task
    Object.keys(obj).forEach((k) => deleteRecursively(obj[k])); // and then call add recursively on all its items
  }
};
// kiểm tra một thuộc tính trong object của một array có === '' không ???
export const checkPropertyInObjectOfArray = (array, key) => {
  // trả về số phần tử trong mảng array có thuộc tính key === ''
  var check = 0;
  for(let x of array) {
    if(x[key] === '') check = check + 1;
  }
  return check;
}

// 
export const checkDuplicateId = (learningPoints, id, type) => {
  const check = learningPoints.findIndex(element => element.type.split(":")[0] !== type && element.id === id);
  return check !== -1 ? true : false;
}
