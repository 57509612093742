import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeQuiz } from '../../../actions/ActionQuiz';
import { getSquareUI, handleTextSquareQuiz } from '../../Option/SquareUtils';
import InputQuestion from '../common/InputQuestion';
import InputTextarea from '../common/InputTextarea';
import debounce from 'lodash/debounce';
import { formatSquare } from '../quizUtils';

const _SquareQuizOption = ({ squareType, previewLP }) => {
    const dispatch = useDispatch();
    const { currentSlide, localState } = useSelector(state => state.lesson);
    const { learningPoints } = localState;
    const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};
    const quizParts = learningCurrent.quizParts || [];
    const squareQuizParts = quizParts.length > 0 ? quizParts[0].squareQuizParts : [];
    const currText = quizParts[0] ? quizParts[0].content.text : '';

    const handleChange = (value) => {
        const syllableObj = handleTextSquareQuiz(value);
        let squareQuizParts = formatSquare(syllableObj);
        if (syllableObj.includes(null) || squareQuizParts.includes(null)) squareQuizParts = [];
        const quizPart = [
            {
            content: {
                text: value,
                type: 'square',
            },
            squareQuizParts,
            }
        ];
        debouncedQuery(quizPart);
    };
    const debouncedQuery = debounce((quizPart) => {
        dispatch(changeQuiz('quizParts', quizPart, 'quiz', currentSlide));
    }, 300);
  
    return (
        <div className="ui__drag_and_drop">
          <div>
            <div>Cấu trúc</div>
            <div className="ui__view ">
              <div className="">
                <div className="fl_w text-center m-20">
                  {squareQuizParts && squareQuizParts.map((item, index) => {
                      const { content, structure } = item;
                      const { consonant, rhyme, tone } = structure;
                      const { startRhyme, mainRhyme, finishRhyme } = rhyme.structure;
                      return getSquareUI(
                        squareType,
                        index,
                        content,
                        rhyme.content,
                        consonant,
                        tone,
                        startRhyme,
                        mainRhyme,
                        finishRhyme,
                        previewLP
                      );
                  })}
                </div>
              </div>
            </div>
            <div className="create_question">
              <div className="text-bold">Tạo câu hỏi:</div>
              <div className="m-20 wrapper_quiz_textarea"></div>
              <InputQuestion audioUrl={learningCurrent.questionAudioUrl || ''} />
            </div>
            <div className="create_question">
              <div className="text-bold">Tạo nội dung:</div>
              <div className="m-20 wrapper_quiz_textarea"></div>
              <InputTextarea value={currText} onChange={handleChange} />
            </div>
          </div>
        </div>
      );
}

export const SquareQuizOption = React.memo(_SquareQuizOption);