import React from 'react';

export const QuizIcon = () => (
  // <svg width="18" height="27" viewBox="0 0 18 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path
  //     d="M8.49523 0.000116075L6.81885 2.40661L8.49523 4.40457C9.51706 4.40457 10.497 4.73922 11.2196 5.33491C11.9421 5.93059 12.348 6.73852 12.348 7.58095C12.348 8.42337 11.9421 9.23129 11.2196 9.82698C10.497 10.4227 9.51706 10.7573 8.49523 10.7573L6.81885 14.0057L8.49523 17.2541H11.1642V14.8352C13.2843 14.3156 15.106 13.1828 16.2946 11.6448C17.4831 10.1069 17.9588 8.26712 17.6343 6.46329C17.3098 4.65946 16.2068 3.01271 14.528 1.82542C12.8491 0.638133 10.7071 -0.00997453 8.49523 0.000116075Z"
  //     fill="#FFC000"
  //   />
  //   <path d="M11.1611 26.0582V21.6562H8.49523L6.81885 23.8566L8.49523 26.0569L11.1611 26.0582Z" fill="#FFC000" />
  //   <path d="M8.48918 21.6562H5.82031L5.82031 26.0582H8.48918V21.6562Z" fill="#F1B986" />
  //   <path d="M8.48918 10.7566H5.82031L5.82031 17.2521H8.48918V10.7566Z" fill="#F1B986" />
  //   <path
  //     d="M0 4.67761L4.93124 6.36141C5.22275 5.78128 5.71629 5.2854 6.3495 4.93642C6.98271 4.58743 7.72718 4.40101 8.48885 4.40071V0C6.67191 0.00202163 4.89633 0.447267 3.38574 1.27965C1.87515 2.11203 0.697107 3.29432 0 4.67761Z"
  //     fill="#F1B986"
  //   />
  // </svg>
  // <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <g clip-path="url(#clip0)">
  //   <path d="M6.60756 8.59817e-05L5.30371 1.78267L6.60756 3.26264C7.40232 3.26264 8.16449 3.51053 8.72652 3.95179C9.28846 4.39303 9.60416 4.9915 9.60416 5.61552C9.60416 6.23953 9.28846 6.83799 8.72652 7.27924C8.16449 7.72052 7.40232 7.96837 6.60756 7.96837L5.30371 10.3746L6.60756 12.7808H8.68343V10.989C10.3324 10.6041 11.7493 9.76504 12.6737 8.62578C13.5981 7.48659 13.9681 6.12379 13.7157 4.78762C13.4633 3.45145 12.6054 2.23164 11.2997 1.35216C9.99391 0.472691 8.3279 -0.00738854 6.60756 8.59817e-05Z" fill="#CF3327"/>
  //   <path d="M8.68102 19.3024V16.0416H6.60756L5.30371 17.6716L6.60756 19.3014L8.68102 19.3024Z" fill="#CF3327"/>
  //   <path d="M6.60313 16.0416H4.52734V19.3024H6.60313V16.0416Z" fill="#E7645A"/>
  //   <path d="M6.60313 7.9679H4.52734V12.7794H6.60313V7.9679Z" fill="#E7645A"/>
  //   <path d="M0 3.4649L3.83541 4.71216C4.06214 4.28243 4.446 3.91511 4.9385 3.65661C5.431 3.3981 6.01003 3.26001 6.60244 3.25979V0C5.18926 0.0014975 3.80826 0.331309 2.63335 0.947889C1.45845 1.56447 0.542194 2.44024 0 3.4649Z" fill="#E7645A"/>
  //   </g>
  //   <defs>
  //   <clipPath id="clip0">
  //   <rect width="14" height="20" fill="white"/>
  //   </clipPath>
  //   </defs>
  // </svg>
  
// red
  // <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M32 15.5C32 24.0604 24.8366 31 16 31C7.16344 31 0 24.0604 0 15.5C0 6.93959 7.16344 0 16 0C24.8366 0 32 6.93959 32 15.5Z" fill="white"/>
  //   <path fillRule="evenodd" clipRule="evenodd" d="M16 30.1143C24.4227 30.1143 31.1474 23.514 31.1474 15.5C31.1474 7.48597 24.4227 0.885714 16 0.885714C7.57731 0.885714 0.852638 7.48597 0.852638 15.5C0.852638 23.514 7.57731 30.1143 16 30.1143ZM16 31C24.8366 31 32 24.0604 32 15.5C32 6.93959 24.8366 0 16 0C7.16344 0 0 6.93959 0 15.5C0 24.0604 7.16344 31 16 31Z" fill="black"/>
  //   <path d="M15.2279 7.00009L13.999 8.76421L15.2279 10.2288C15.977 10.2288 16.6954 10.4742 17.225 10.9108C17.7547 11.3475 18.0523 11.9398 18.0523 12.5573C18.0523 13.1749 17.7547 13.7671 17.225 14.2038C16.6954 14.6405 15.977 14.8858 15.2279 14.8858L13.999 17.2671L15.2279 19.6484H17.1844L17.1844 17.8752C18.7387 17.4942 20.074 16.6638 20.9453 15.5364C21.8166 14.409 22.1654 13.0603 21.9275 11.738C21.6896 10.4157 20.881 9.20851 19.6503 8.33815C18.4196 7.46779 16.8494 6.99269 15.2279 7.00009Z" fill="#DA4A54"/>
  //   <path d="M17.1817 26.1024V22.8755H15.2274L13.9985 24.4885L15.2274 26.1015L17.1817 26.1024Z" fill="#DA4A54"/>
  //   <path d="M15.2231 22.8755H13.2666V26.1024H15.2231V22.8755Z" fill="#D82330"/>
  //   <path d="M15.2231 14.8853H13.2666V19.6469H15.2231V14.8853Z" fill="#D82330"/>
  //   <path d="M9 10.429L12.6149 11.6633C12.8286 11.2381 13.1904 10.8745 13.6546 10.6187C14.1188 10.3629 14.6645 10.2262 15.2229 10.226V7C13.8909 7.00148 12.5893 7.32788 11.482 7.93807C10.3746 8.54826 9.51102 9.41495 9 10.429Z" fill="#D82330"/>
  // </svg>


// yellow
// {/* <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
// <circle cx="17.5" cy="17.5" r="17.1" fill="white" stroke="black" strokeWidth="0.8"/>
// <path d="M17.7764 7.24033L16.4119 9.19014L17.7764 10.8089C18.6081 10.8089 19.4057 11.0801 19.9938 11.5627C20.582 12.0454 20.9124 12.7 20.9124 13.3825C20.9124 14.0651 20.582 14.7197 19.9938 15.2023C19.4057 15.685 18.6081 15.9561 17.7764 15.9561V15.9561L16.4119 18.5881L17.7764 21.22H19.9487V19.2601C21.6745 18.8391 23.1572 17.9213 24.1247 16.6752C25.0921 15.4292 25.4793 13.9385 25.2151 12.477C24.951 11.0155 24.0532 9.68122 22.6867 8.71924C21.3202 7.75727 19.5767 7.23215 17.7764 7.24033V7.24033Z" fill="#FFC000"/>
// <path d="M19.9463 28.3527V24.7861H17.7764L16.4119 26.5689L17.7764 28.3517L19.9463 28.3527Z" fill="#FFC000"/>
// <path d="M17.7715 24.7871H15.5991V28.3537H17.7715V24.7871Z" fill="#FFB800"/>
// <path d="M17.7715 15.9561H15.5991V21.2189H17.7715V15.9561Z" fill="#FFB800"/>
// <path d="M10.8618 11.0312L14.8756 12.3954C15.1129 11.9254 15.5146 11.5236 16.03 11.2409C16.5454 10.9581 17.1514 10.8071 17.7713 10.8068V7.24121C16.2924 7.24285 14.8472 7.6036 13.6176 8.27802C12.3881 8.95245 11.4292 9.91038 10.8618 11.0312Z" fill="#FFB800"/>
// </svg> */}


<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.5" cy="17.5" r="17.1" fill="white" stroke="black" strokeWidth="0.8"/>
<path d="M17.7764 7.24033L16.4119 9.19014L17.7764 10.8089C18.6081 10.8089 19.4057 11.0801 19.9938 11.5627C20.582 12.0454 20.9124 12.7 20.9124 13.3825C20.9124 14.0651 20.582 14.7197 19.9938 15.2023C19.4057 15.685 18.6081 15.9561 17.7764 15.9561V15.9561L16.4119 18.5881L17.7764 21.22H19.9487V19.2601C21.6745 18.8391 23.1572 17.9213 24.1247 16.6752C25.0921 15.4292 25.4793 13.9385 25.2151 12.477C24.951 11.0155 24.0532 9.68122 22.6867 8.71924C21.3202 7.75727 19.5767 7.23215 17.7764 7.24033V7.24033Z" fill="#114378"/>
<path d="M19.9463 28.3527V24.7861H17.7764L16.4119 26.5689L17.7764 28.3517L19.9463 28.3527Z" fill="#114378"/>
<path d="M17.7715 24.7871H15.5991V28.3537H17.7715V24.7871Z" fill="#266CB2"/>
<path d="M17.7715 15.9561H15.5991V21.2189H17.7715V15.9561Z" fill="#266CB2"/>
<path d="M10.8618 11.0312L14.8756 12.3954C15.1129 11.9254 15.5146 11.5236 16.03 11.2409C16.5454 10.9581 17.1514 10.8071 17.7713 10.8068V7.24121C16.2924 7.24285 14.8472 7.6036 13.6176 8.27802C12.3881 8.95245 11.4292 9.91038 10.8618 11.0312Z" fill="#266CB2"/>
</svg>



);
