import React from 'react';
import IconLogo from '../../icons/iconLogo.svg';
import { isEmail } from 'validator';
import WrapperInput from '../../components/Common/WrapperInput';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginAction } from '../../reducers/auth';
import { ThemeConsumer } from 'styled-components';

class LoginContainer extends React.Component {
  state = {
    login: {
      password: '',
      email: '',
    },
  };

  componentDidUpdate = () => {
    if (this.props.data.id) {
      this.props.history.push('/course');
    }
  };

  handleLogin = (obj) => {
    this.setState({ login: obj });
  };
  onSubmit = () => {
    const { password, email } = this.state.login;
    if (email === '' || password === '') {
      return toast.error('Email và mật khẩu không được bỏ trống !');
    }
    if (!isEmail(email)) {
      return toast.error('Email không đúng định dạng !');
    }
    this.props.dispatch(loginAction({ email, password }));
  };
  render() {
    const { password, email } = this.state.login;

    return (
      <>
        <div className="container max-height">
          <div className="ui-login">
            <div className="ui-login-content">
              <div className="login-content">
                <div className="logo text-center">
                  <img src={IconLogo} />
                </div>
                <div className="title-login">
                  <div className="text-bold fs-30">Đăng nhập</div>
                  <div>Chào mừng bạn quay trở lại</div>
                </div>
                <div className="ui-form">
                  <WrapperInput
                    textLable="Email của bạn"
                    placeholder="hello@vietstudy.com"
                    onChange={(e) =>
                      this.handleLogin({
                        password: password,
                        email: e.target.value,
                      })
                    }
                    value={email}
                    type="text"
                  />
                  <WrapperInput
                    textLable="Mật khẩu"
                    placeholder="******"
                    onChange={(e) =>
                      this.handleLogin({
                        password: e.target.value,
                        email: email,
                      })
                    }
                    value={password}
                    type="password"
                  />
                  <div className="div_btn-login">
                    <button onClick={this.onSubmit} className="btn btn-login text-bold cursor">
                      {!this.props.loading ? 'Đăng nhập' : 'Đang chạy...'}
                    </button>
                  </div>
                </div>
                <div className="text-center text-bold">Liên hệ để tạo tài khoản</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '100%' }} />
        <div className="info_login_other">
          <div className="fl_b">
            <div>Phiên bản 0.0.1</div>
            <div className="fl">
              <div>
                <a href="#">Chính sách</a>
              </div>
              <div>
                <a href="#">Liên hệ</a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { data, loading } = state.auth;
  return { data, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginContainer));
