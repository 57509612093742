import api from './api';

export const getOverview = async (id) =>  
    api({
        method: 'GET',
        url: `lesson-overview/${id}`,
    });

export const createOverview = async () => {
    return api({
        method: 'POST',
        url: 'lesson-overview',
    }).then((res) => res);
};

export const updateOverview = (data) => {

    return api({
        method: 'PUT',
        url: `lesson-overview/${data.id}`,
        data: data
    }).then((res) => res);
};