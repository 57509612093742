import React, { memo } from 'react';
import { Table } from 'reactstrap';

// local
import Header from './Header'
import RowData from './RowData'

const headers = ["Ảnh", "Tên", "Thể loại", "Số sao quy đổi", "Số lượng", "Độ hiếm"]

const OrderItemDataTable = ({ className, items = [] }) => (
  <Table className={className} bordered responsive>
    {/* header */}
    <Header headers={headers} />

    {/* body */}
    <tbody>
      {items.map(item => (
        <RowData key={item.id} item={item} />
      ))}
    </tbody>
  </Table>
)

export default memo(OrderItemDataTable)