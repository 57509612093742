import React, { useState, } from 'react';
import { useSelector} from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { IconCaretLeft, IconPen } from '../../../icons/IconSvg';
import WraperIconFunction from './common/WraperIconFunction';

import './style.scss';
import ModalUpdateLesson_Step2 from './Overview/Step2-ModalUpdate';
import ModalUpdateLesson_Step3 from './Overview/Step3-ModalUpdate';
import ModalUpdateLesson_Step1 from './Overview/Step1-ModalUpdate';

export default function TabFunction({ stateProps }) {
  const { localState } = useSelector((state) => state.lesson);;
  const history = useHistory();
  const initStateOpen = {
    step1: false,
    step2: false,
    step3: false
  }
  const [isOpen, setIsOpen] = useState(initStateOpen);

  const handleModal = (e, type) => {
    switch (type) {
      case 1: 
          setIsOpen({
            step1: !isOpen.step1,
            step2: false,
            step3: false
          });
          break;
      case 2:
        setIsOpen({
          step1: false,
          step2: !isOpen.step2,
          step3: false
        });
        break;
      case 3:
        setIsOpen({
          step1: false,
          step2: false,
          step3: !isOpen.step3
        });
        break;
    }
  };
 
  return (
    <div className="ui__tab_function">
      <div className="wraper_tab_function">
        <div>
          <div>
            <div className="fl" style={{ marginLeft: '-10px', width: '275px', position: 'relative' }}>
              <div className="fl text-dc div__back" style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                <IconCaretLeft />
              </div>
              {/* <Link className="fl text-dc div__back" to={`/course/${stateProps.idCourse}`}>
                <IconCaretLeft />
              </Link> */}
              <input
                value={localState.title || ''}
                readOnly
                id="name_lesson"
                className="border-input-none text-bold text-link"
              />
              <div aria-hidden="true" onClick={(e) => handleModal(e, 1)} className="icon-pen cursor">
                <IconPen />
              </div>
            </div>
          </div>
        </div>
        <WraperIconFunction />
      </div>
      <ReactTooltip effect="solid"/>
      <ModalUpdateLesson_Step1
          className='modal_update_lesson'
          openState = {isOpen} 
          processFunction = {handleModal}
      />
      <ModalUpdateLesson_Step2   
          processFunction = {handleModal}
          openState = {isOpen}
      />
      <ModalUpdateLesson_Step3  
          processFunction = {handleModal}
          openState = {isOpen}
      />
    </div>
  );
}
