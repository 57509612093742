import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form';
import { createContainer } from 'unstated-next'

const useCollectionTemplateFormCtrl = () => {
  // -- form state --
  const { watch, setValue } = useFormContext()

  // -- collection categories --
  const setCollectionCategories = useCallback((value) => setValue('collectionCategories', value), [])

  // -- preview bg image url --
  const previewBgImageUrl = watch('previewBgImageUrl')
  const setPreviewBgImageUrl = useCallback((value) => setValue('previewBgImageUrl', value, { shouldValidate: true }), [])

  // -- preview bg sound url --
  const previewBgSoundUrl = watch('previewBgSoundUrl')
  const setPreviewBgSoundUrl = useCallback((value) => setValue('previewBgSoundUrl', value, { shouldValidate: true }), [])

  return {
    // collection categories
    setCollectionCategories,
    // preview bg image url
    previewBgImageUrl,
    setPreviewBgImageUrl,
    // preview bg sound url
    previewBgSoundUrl,
    setPreviewBgSoundUrl,
  }
}

export default createContainer(useCollectionTemplateFormCtrl)
