import apiV2 from '../apiV2';

export const getAll = async ({ page = 1, limit = 10, keyword, sort = { createdAt: -1 } }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/systemCollection/all`,
    data: { page, limit, keyword, sort }
  });
}

export const getOne = async ({ _id }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/systemCollection/one`,
    data: { _id }
  });
}

export const upsert = async (dataToUpdate) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/systemCollection/upsert`,
    data: dataToUpdate
  });
}