import { find, isTypedArray } from 'lodash';
import { toast } from 'react-toastify';

import { handleCreateQuiz, handleUpdateQuiz } from './ActionQuiz';
import { clasifyLearningPoints } from './LessonUtils';
import { oldLearningPointsCurrent } from './AudioQuiz';
import { getQuizzesByCourseId } from '../apis/course';
import { getQuizzesByChapterId } from '../apis/chapter';
import { refineLearningPoints } from '../reducers/convertLesson';
import { Overview } from '../reducers/templates';

export const formartQuizInTestBank = async ({ courseId, chapterId }) => {
  let datas = [];
  if (chapterId) {
    datas = await getQuizzesByChapterId(chapterId);
  } else {
    datas = await getQuizzesByCourseId(courseId);
  }
  if (datas.status === 1) {
    const quizzes = datas.result.quiz ? datas.result.quiz.filter((qz) => qz.inTestBank) : [];
    const { learningPoints, learningPointIds, entities } = refineLearningPoints(
      quizzes.map((quiz) => {
        return {
          ...quiz,
          type: `quiz:${quiz.type}`,
        };
      }),
    );
    return { learningPoints, learningPointIds, entities };
  }
  return { learningPoints: [], learningPointIds: [], entities: [] };
};

export const getQuizzInTestBank = ({ courseId, chapterId }) => async (dispatch, getState) => {
  const { learningPoints, learningPointIds, entities } = await formartQuizInTestBank({ courseId, chapterId });
  dispatch({
    type: 'RECEIVE__LESSON',
    data: {
      ...{},
      learningPoints,
      learningPointIds,
      entities,
      overview: Overview
    },
  });
  // let datas = [];
  // if (chapterId) {
  //   datas = await getQuizzesByChapterId(chapterId);
  // } else {
  //   datas = await getQuizzesByCourseId(courseId);
  // }
  // if (datas.status === 1) {
  //   const quizzes = datas.result.quiz ? datas.result.quiz.filter((qz) => qz.inTestBank) : [];
  //   const { learningPoints, learningPointIds, entities } = refineLearningPoints(
  //     quizzes.map((quiz) => {
  //       return {
  //         ...quiz,
  //         type: `quiz:${quiz.type}`,
  //       };
  //     }),
  //   );
  //   dispatch({
  //     type: 'RECEIVE__LESSON',
  //     data: {
  //       ...{},
  //       learningPoints,
  //       learningPointIds,
  //       entities,
  //     },
  //   });
  // }
};

export const saveQuizzesBank = () => async (dispatch, getState) => {
  dispatch({
    type: '@@lesson/SYNCING',
    value: true,
  });
  const courseId = getState().questionBank.course.id;
  const chapterId = getState().questionBank.idChapterActive;

  const learningPointsCurrent = getState().lesson.localState.learningPoints || [];
  const oldLearningPoints = getState().lesson.oldLocalState.learningPoints || [];
  const { speaker } = getState().voice;
  const { newQuizzes, fixQuizzes } = clasifyLearningPoints(oldLearningPoints, learningPointsCurrent);

  await Promise.all(
    learningPointsCurrent.map(async (learningPoint) => {
      const idArray = [];
      // Quiz: update
      if (find(fixQuizzes, learningPoint)) {
        const updateQuiz = await handleUpdateQuiz(
          { ...learningPoint, inTestBank: true },
          oldLearningPointsCurrent(oldLearningPoints, learningPoint.id),
          speaker,
          chapterId,
          courseId,
        );
        return { ...learningPoint, id: updateQuiz ? updateQuiz.result.quiz.id : learningPoint.id };
      }

      // Quiz: create 
      if (find(newQuizzes, learningPoint)) {
        const createdQuiz = await handleCreateQuiz(
          { ...learningPoint, inTestBank: true },
          speaker,
          chapterId,
          courseId,
          idArray,
        );
        return {
          ...learningPoint,
          id:
            createdQuiz && createdQuiz.result && createdQuiz.result.quiz && createdQuiz.result.quiz.id
              ? createdQuiz.result.quiz.id
              : learningPoint.id,
        };
      }

      // Else: default
      return learningPoint;
    }),
  );

  dispatch({
    type: '@@lesson/SYNCING',
    value: false,
  });
  toast.success('Bài học đã được update thành công');
};
