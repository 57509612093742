import React from 'react';
import { Button } from 'reactstrap'

// local
import LuckyBoxDataTableCtrl from './LuckyBoxDataTable.ctrl';
import LuckyBoxModal from '../LuckyBoxModal/LuckyBoxModal';
import GenerateMoreLuckyBoxModal from '../GenerateMoreLuckyBoxModal/GenerateMoreLuckyBoxModal';

const Actions = ({ className }) => {
  // -- ctrl --
  const {
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
    // generate more modal
    isGenerateMoreModalOpen,
    setIsGenerateMoreModalOpen,
    toggleGenerateMoreModal,
    generateMoreModalItemId,
    setGenerateMoreModalItemId,
  } = LuckyBoxDataTableCtrl.useContainer()

  return (
    <div className={className}>
      {/* Add */}
      <Button
        color="primary"
        onClick={() => {
          setModalItemId()
          setIsModalOpen(true)
        }}
      >
        Thêm
      </Button>

      {/* modal */}
      {isModalOpen && (
        <LuckyBoxModal
          open={isModalOpen}
          toggle={toggleModal}
          id={modalItemId}
          setIsGenerateMoreModalOpen={setIsGenerateMoreModalOpen}
        />
      )}

      {/* generate more modal */}
      {isGenerateMoreModalOpen && (
        <GenerateMoreLuckyBoxModal
          open={isGenerateMoreModalOpen}
          toggle={toggleGenerateMoreModal}
          id={generateMoreModalItemId}
        />
      )}
    </div>
  );
};

export default Actions;