import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames'
import { Collapse } from 'reactstrap'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

// utils
import { formatNumber, formatPercent } from '../../../../../utils/number';

// local
import OrderItemDataTable from '../OrderItemDataTable';

const RowData = ({ className, luckyBox }) => {
  // -- luckyBox --
  const { imageUrl, name, price, quantity, itemSpawnRateByRarity, items = [] } = luckyBox

  // -- is collapse open --
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const toggleCollapse = useCallback(() => setIsCollapseOpen(prevState => !prevState), [setIsCollapseOpen])

  return (
    <>
      <tr>
        {/* image */}
        <td className="t-w-[150px]" style={{ verticalAlign: 'middle' }}>
          <img className="t-w-[150px] t-h-[150px] t-object-contain" src={imageUrl} />
        </td>

        {/* name */}
        <td className="text-center !t-align-middle">
          {name}
        </td>

        {/* price */}
        <td className="text-center !t-align-middle">
          {`⭐ ${formatNumber(price)}`}
        </td>

        {/* quantity */}
        <td className="text-center !t-align-middle">
          {formatNumber(quantity)}
        </td>

        {/* rarity */}
        <td className="!t-align-middle">
          - Thường: {formatPercent(itemSpawnRateByRarity.normal)}
          <br />
          - Hiếm: {formatPercent(itemSpawnRateByRarity.rare)}
          <br />
          - Cực hiếm: {formatPercent(itemSpawnRateByRarity.extremelyRare)}
        </td>

        {/* actions */}
        <td className="text-center !t-align-middle">
          {
            isCollapseOpen ?
              <BiChevronUp className="t-cursor-pointer" onClick={toggleCollapse} />
              : <BiChevronDown className="t-cursor-pointer" onClick={toggleCollapse} />
          }
        </td>
      </tr>

      <tr className="bg-light">
        <td colSpan="6" className={cls('', { 'p-0': !isCollapseOpen })}>
          <Collapse isOpen={isCollapseOpen} >
            <OrderItemDataTable items={items} />
          </Collapse>
        </td>
      </tr>
    </>
  )
}

RowData.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.object.isRequired,
};

export default memo(RowData)