import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './style.scss'

import { IconStar, IconPlayArrow } from '../../icons/IconSvg';
import { findIndexId } from '../../reducers/lesson';
import { checkpointTextActions } from '../../actions/ActionCheckpoint';
import TitleVoiceBox from '../Theory/TitleVoiceBox';

const Reward = ({ previewLP }) => {
  const dispatch = useDispatch();
  const { localState, currentSlide } = useSelector((state) => state.lesson);
  const { overview, learningPoints } = localState;
  const { learningPointPartTitles, starTotal, template, usedStars } = overview;

  let index = findIndexId(learningPoints, currentSlide);
  let currentLp = learningPoints[index];

  const [checkpointArray, setCheckpointArray] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  // const [maxStar, setMaxStar] = useState(starTotal - usedStars < 3 ? starTotal - usedStars : 3);
  const [starState, setActiveStar] = useState({
    starLocation: {
      star1: true,
      star2: false,
      star3: false,
    },
    checkpointStars: 0,
  });

  useEffect(() => {
    if (previewLP) {
      setActiveStar((preState) => {
        return {
          starLocation: {
            star1: true,
            star2: previewLP.stars > 1 ? true : false,
            star3: previewLP.stars > 2 ? true : false,
          },
          checkpointStars: previewLP.stars,
        };
      });
    }
  }, [previewLP ? previewLP : null]);

  // useEffect(() => {
  //   setMaxStar(starTotal - usedStars < 3 ? starTotal - usedStars : 3 - currentLp.stars);
  // }, [starTotal]);

  useEffect(() => {
        index = findIndexId(learningPoints, currentSlide);
        currentLp = learningPoints[index];
        const check = currentLp ? currentLp.type.split(":")[0] == 'checkpoint' : false;
        if(check) {
          if(previewLP ? previewLP.id === currentSlide : true) {
            setActiveStar({
              starLocation: {
                star1: true,
                star2: currentLp.starLocation.star2,
                star3: currentLp.starLocation.star3,
              },
              checkpointStars: currentLp.stars,
            });
            setCheckpointArray(learningPoints.filter((element) => element.type.split(':')[0] === 'checkpoint'));
            // setMaxStar(starTotal - usedStars < 3 ? starTotal - usedStars : 3 - currentLp.stars);
          }
        }

  }, [currentSlide]);

  const handleClickStar = (e, typeStar) => {
    let newCheckpointStars = 0;
    switch(typeStar){
      case 'star1':
        newCheckpointStars = 1;
        break;
      
      case 'star2':
        newCheckpointStars = 2;
        break;
      
      case 'star3':
        newCheckpointStars = 3;
        break;
    }
    let newMaxStar = starTotal - usedStars;
    let diffStarsInCurrCheckpoint = newCheckpointStars - currentLp.stars;
    if (diffStarsInCurrCheckpoint >= 0 ? newMaxStar >= diffStarsInCurrCheckpoint : true) {
      let starLocation = {
        star1: true,
        star2: newCheckpointStars > 1 ? true : false,
        star3: newCheckpointStars > 2 ? true : false
      };
      setActiveStar({
        ...starState,
        checkpointStars: newCheckpointStars,
        starLocation,
      });
      const newUsedStars = usedStars + (newCheckpointStars - currentLp.stars);
      dispatch({
        type: 'CHANGE_STAR_CHECKPOINT',
        data: {
          id: currentSlide,
          usedStars: newUsedStars,
          stars: newCheckpointStars,
          starLocation,
        },
      });
      
    } else {
      toast.error('Số sao còn lại không đủ !!!');
    }
  };

  let titles = [];
  if (learningPointPartTitles[0].title && learningPointPartTitles[1].title) {
    titles = learningPointPartTitles.map((element, index) => {
      return element.title;
    });
  }

  const handleOnChangeCongratulationText = (e) => {
    const inputValue = e.target.value;
    //if (inputValue !== '') {
      dispatch(
        checkpointTextActions({
          id: currentSlide,
          content: inputValue,
          type: 'congratulationText',
        }),
      );
    //}
  };

  const handleChangeLP  = (data) => {
    const {titleAudioUrl, titleVoice, rateVoice} = data;
    if(titleVoice) {
      dispatch(checkpointTextActions({
        id: currentSlide,
        content: titleVoice,
        type: 'speaker'
      }))
    }
    else if (titleAudioUrl) {
      dispatch(checkpointTextActions({
        id: currentSlide,
        content: titleAudioUrl,
        type: 'soundUrl'
      }))
    }
    else if (rateVoice) {
      dispatch(checkpointTextActions({
        id: currentSlide,
        content: rateVoice,
        type: 'rateVoice'
      }))
    }
  };

  return (
    <div className="reward">
      <div className="reward__header" style={{marginTop: '10px'}}>
        Kết thúc phần {previewLP ? previewLP.title : currentLp.title}
      </div>
      <div style={{height: '90px'}}></div>
        {/* {(previewLP ? previewLP.title !== 'bài học' : currentLp.title !== 'bài học') &&  <div className="reward__title">
            <div>Tiêu đề</div>
            <div className="reward__title__choose">
              <div style={{  margin: '0 15px 0 8px', overflow: 'hidden', textAlign: 'center' }}>
                {previewLP ? previewLP.title : currentLp.title}
              </div>
            </div>
          </div>
        } */}
      <div className="reward__congratulation">
        Lời chúc mừng
        {previewLP && (
          <TitleVoiceBox 
            lpID={previewLP.id}
            title={previewLP.congratulationText ? previewLP.congratulationText : null}
            titleVoice={previewLP.speaker}
            titleAudioUrl={previewLP.soundUrl ? previewLP.soundUrl : null}
            rateVoice={previewLP.rateVoice}
            handleChangeLP={handleChangeLP}
          />
        )}
        {!previewLP && (
          <TitleVoiceBox 
            lpID={currentSlide}
            title={currentLp.congratulationText ? currentLp.congratulationText : null}
            titleVoice={currentLp.speaker}
            rateVoice={currentLp.rateVoice}
            titleAudioUrl={currentLp.soundUrl ? currentLp.soundUrl : null}
            handleChangeLP= {handleChangeLP}
          />
        )}
      </div>
      <div className="reward__congratulation__input">
        <input
          type="text"
          placeholder="Nhập lời chúc hoàn thành "
          value={previewLP !== undefined ? previewLP.congratulationText : currentLp.congratulationText}
          onChange={handleOnChangeCongratulationText}
        />
      </div>
      <div className="reward__star">
        <div className="reward__star__input">
          <div className="reward__star__input__item" onClick={(e) => handleClickStar(e, 'star1')}>
            <IconStar color={starState.starLocation.star1} />
          </div>
          <div className="reward__star__input__item" onClick={(e) => handleClickStar(e, 'star2')}>
            <IconStar color={starState.starLocation.star2} />
          </div>
          <div className="reward__star__input__item" onClick={(e) => handleClickStar(e, 'star3')}>
            <IconStar color={starState.starLocation.star3} />
          </div>
        </div>
        <div className="reward__star__text">
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '25px',
              lineHeight: '30px',
              color: '#C4C4C4',
              margin: '5px 0 10px 0',
            }}
          >
            Bé được tặng {starState.checkpointStars} sao
          </div>
          <div
            style={{
              fontSize: '15px',
              lineHeight: '18px',
              color: '#C4C4C4',
            }}
          >
            Bài học này còn lại {(starTotal-usedStars)} sao
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
export default Reward;
