import { toast } from 'react-toastify';
import { fetchProfile, login } from '../apis/auth';
import { deleteCookie, setCookie, getCookie } from '../utils/cookie';

const initState = {
  data: {}, // old version
  loading: false,
  authData: {} // keycloak infro
};

const ActionTypes = {
  SET_LOADING: '@@auth/SET_LOADING',
  SET_DATA: '@@auth/SET_DATA',
  SET_AUTH_DATA: '@@auth/SET_AUTH_DATA',
};

export const fetchProfileAction = () => async (dispatch) => {
  const accessToken = getCookie('accessToken');
  const res = await fetchProfile(accessToken);

  if (!res) {
    toast.error(`Can not connect with server!`);
    return;
  }

  dispatch({ type: ActionTypes.SET_DATA, value: res.result.user });
};

export const loginAction = ({ email, password }) => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_LOADING, value: true });
  const res = await login(email, password);

  if (!res) {
    dispatch({ type: ActionTypes.SET_LOADING, value: false });
    toast.error(`Can not connect with server!`);
    return;
  }

  if (res.status !== 1) {
    toast.error(`${res.code}: ${res.message}`);
    dispatch({ type: ActionTypes.SET_LOADING, value: false });
    return;
  }
  // Set token in 1 month
  setCookie('accessToken', res.result.auth.accessToken, 1000 * 60 * 60 * 24 * 30);
  // Set user's data
  dispatch({ type: ActionTypes.SET_DATA, value: res.result.auth.user });
  dispatch({ type: ActionTypes.SET_LOADING, value: false });
};

export const logoutAction = () => async (dispatch, getState) => {
  deleteCookie('accessToken');
  dispatch({ type: ActionTypes.SET_DATA, value: {} });
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };

    case ActionTypes.SET_DATA:
      return {
        ...state,
        data: action.value,
      };
    case ActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        authData: action.data
      }
    default:
      return state;
  }
};

export default auth;
