import React, { memo, useState } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

// components
import InputField from '../../../../../components/Common/InputField';
import CollectionCategorySelectField from '../../../../../components/award-admin/CollectionCategorySelectField';
import SelectField from '../../../../../components/Common/SelectField';
import InputNumberField from '../../../../../components/Common/InputNumberField';
import InputImageField from '../../../../../components/Common/InputImageField';
import InputVoiceField from '../../../../../components/Common/InputVoiceField';

// constants
import {
  COLLECTION_ITEM_STATUSES,
  COLLECTION_ITEM_STATUS_OPTIONS,
} from '../../../../../constants/award-admin/collectionItemStatus';
import {
  COLLECTION_ITEM_RARITY,
  COLLECTION_ITEM_RARITY_OPTIONS,
} from '../../../../../constants/award-admin/collectionItemRarity';

// local
import CollectionItemFormCtrl from './CollectionItemForm.ctrl';

// form validation schema
export const formValidationSchema = yup.object().shape({
  name: yup.string().required('Bạn cần nhập tên của vật phẩm').label('Tên'),
  nameVoiceUrl: yup
    .string()
    .typeError('Bạn cần chọn giọng đọc cho tên của vật phẩm')
    .required('Bạn cần chọn giọng đọc cho tên của vật phẩm')
    .label('Giọng đọc tên'),
  collectionCategoryId: yup.string().required('Bạn cần chọn thể  loại của vật phẩm').label('Thể loại'),
  rarity: yup
    .string()
    .oneOf(Object.values(COLLECTION_ITEM_RARITY))
    .required('Bạn cần chọn độ hiếm của vật phẩm')
    .label('Độ hiếm'),
  status: yup
    .string()
    .oneOf(Object.values(COLLECTION_ITEM_STATUSES))
    .required('Bạn cần chọn trạng thái của vật phẩm')
    .label('Trạng thái'),
  publishQuantity: yup
    .string()
    .required('Bạn cần nhập số lượng phát hành của vật phẩm')
    .test('is-valid', 'Số lượng phát hành phải lớn hơn 0', (value) => Number(value) > 0)
    .label('Số lượng phát hành'),
  price: yup
    .string()
    .required('Bạn cần nhập số sao quy đổi của vật phẩm')
    .test('is-valid', 'Số sao quy đổi phải lớn hơn 0', (value) => Number(value) > 0)
    .label('Số sao quy đổi'),
  description: yup.string().required('Bạn cần nhập mô tả của vật phẩm').label('Mô tả'),
  descriptionVoiceUrl: yup
    .string()
    .typeError('Bạn cần chọn giọng đọc cho mô tả của vật phẩm')
    .required('Bạn cần chọn giọng đọc cho mô tả của vật phẩm')
    .label('Giọng đọc mô tả'),
  previewImageUrl: yup.string().required('Bạn cần upload một ảnh').label('Ảnh'),
});

const CollectionItemForm = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // preview image url
    previewImageUrl,
    setPreviewImageUrl,
  } = CollectionItemFormCtrl.useContainer();

  // -- form state --
  const { watch, setValue } = useFormContext();

  return (
    <Form onSubmit={() => {}}>
      {/* name */}
      <FormGroup>
        <Label htmlFor="name" className="t-flex">
          <span>Tên vật phẩm</span>

          {/* name voice */}
          <InputVoiceField fieldName="name" fieldVoiceUrlName="nameVoiceUrl" fieldVoiceUrlId="nameVoiceUrl" />
        </Label>

        <InputField type="text" name="name" id="name" placeholder="Nhập tên vật phẩm" />
      </FormGroup>

      {/* collection category */}
      <FormGroup>
        <Label htmlFor="collectionCategoryId">Thể loại</Label>
        <CollectionCategorySelectField
          defaultKeywordFieldName="collectionCategoryName"
          name="collectionCategoryId"
          id="collectionCategoryId"
          selectProps={{ placeholder: 'Chọn thể loại' }}
        />
      </FormGroup>

      <div className="t-flex">
        {/* status */}
        <FormGroup className="t-flex-1 mr-2">
          <Label htmlFor="status">Trạng thái</Label>
          <SelectField
            name="status"
            id="status"
            options={COLLECTION_ITEM_STATUS_OPTIONS}
            selectProps={{ placeholder: 'Chọn trạng thái' }}
          />
        </FormGroup>

        {/* rarity */}
        <FormGroup className="t-flex-1 ml-2">
          <Label htmlFor="rarity">Độ hiếm</Label>
          <SelectField
            name="rarity"
            id="rarity"
            options={COLLECTION_ITEM_RARITY_OPTIONS}
            selectProps={{ placeholder: 'Độ hiếm' }}
          />
        </FormGroup>
      </div>

      <div className="t-flex">
        {/* publish quantity */}
        <FormGroup className="t-flex-1 mr-2">
          <Label htmlFor="publishQuantity">Số lượng phát hành</Label>
          <InputNumberField
            name="publishQuantity"
            id="publishQuantity"
            thousandSeparator={true}
            allowNegative={false}
            placeholder="Nhập số lượng phát hành"
          />
        </FormGroup>

        {/* price */}
        <FormGroup className="t-flex-1 ml-2">
          <Label htmlFor="price">Số sao quy đổi</Label>
          <InputNumberField
            name="price"
            id="price"
            thousandSeparator={true}
            allowNegative={false}
            prefix="⭐ "
            placeholder="Nhập số sao quy đổi"
          />
        </FormGroup>
      </div>

      {/* description */}
      <FormGroup>
        <Label htmlFor="description" className="t-flex">
          <span>Mô tả</span>

          {/* description voice */}
          <InputVoiceField
            fieldName="description"
            fieldVoiceUrlName="descriptionVoiceUrl"
            fieldVoiceUrlId="descriptionVoiceUrl"
          />
        </Label>

        <InputField type="textarea" name="description" id="description" placeholder="Nhập mô tả" />
      </FormGroup>

      {/* image */}
      <FormGroup>
        <Label>Ảnh</Label>
        <InputImageField
          name="image"
          id="image"
          accept="image/*"
          previewImageUrl={previewImageUrl}
          setPreviewImageUrl={setPreviewImageUrl}
        />
      </FormGroup>
    </Form>
  );
});

export default () => (
  <CollectionItemFormCtrl.Provider>
    <CollectionItemForm />
  </CollectionItemFormCtrl.Provider>
);
