import React from 'react';
import { connect } from 'react-redux';
import { changeChapter } from '../../reducers/questionBank';
import { getQuizzInTestBank } from '../../actions/ActionQuestionBank';

class SelectChapter extends React.Component {
  render() {
    const { chapters, course } = this.props;
    return (
      <select
        className="btn"
        onChange={async (e) => {
          this.props.changeChapter(parseInt(e.target.value));
          this.props.getQuizzInTestBank({ courseId: course.id, chapterId: parseInt(e.target.value) });
        }}
        style={{
          border: '1px solid #c2c2c2',
          padding: '5px 5px',
          width: '100%',
          marginTop: '10px',
          marginBottom: '-10px',
        }}
      >
        <option style={{ cursor: 'not-allowed' }} className="div_item">
          -- Chọn chủ đề --
        </option>
        {chapters.map((chapter) => (
          <option key={chapter.id} value={chapter.id} className="div_item">
            {chapter.title || ''}
          </option>
        ))}
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  chapterId: state.questionBank.idChapterActive,
  chapters: state.questionBank.course.chapterIds || [],
  course: state.questionBank.course,
});
export default connect(mapStateToProps, { changeChapter, getQuizzInTestBank })(SelectChapter);
