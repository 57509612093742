/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { changePassword } from '../../apis/auth';
import { logoutAction } from '../../reducers/auth';
import { createActionChapter } from '../../reducers/chapter';

const ModalChangePassword = ({ isOpen, toggle }) => {
  const { register, handleSubmit } = useForm();
  const [errors, seterrors] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const submitFunc = async ({ current_pw, new_pw, verify_pw }) => {
    if (current_pw === '' || new_pw === '' || verify_pw === '') {
      return seterrors('Hãy điền hết các trường');
    }
    if (new_pw.length <= 6) {
      return seterrors('Mật khẩu ít nhất phải 6 ký tự');
    }
    if (new_pw !== verify_pw) {
      return seterrors('Mật khẩu nhập lại không khớp với mật khẩu mới');
    }

    seterrors('');
    changePassword({ currentPassword: current_pw, newPassword: new_pw })
      .then((res) => {
        if (res.status === 0) return seterrors(res.message);
        toggle();
        toast('Đã thay đổi mật khẩu thành công, hãy đang nhập lại bằng mật khẩu mới');
        dispatch(logoutAction());
        history.push('/login');
      })
      .catch((serverErr) => {
        seterrors('lỗi hệ thống ', JSON.stringify(serverErr));
      });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Thay đổi mật khẩu</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(submitFunc)}>
          <FormGroup>
            <Label htmlFor="current_password">Mật khẩu hiện tại</Label>
            <Input innerRef={register} type="password" name="current_pw" id="current_pw" placeholder="******" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="new_password">Mật khẩu mới</Label>
            <Input innerRef={register} type="password" name="new_pw" id="new_pw" placeholder="******" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="verify_password">Nhập lại mật khẩu mới</Label>
            <Input innerRef={register} type="password" name="verify_pw" id="verify_pw" placeholder="******" />
          </FormGroup>

          <button type="submit" style={{ float: 'right' }} className="btn btn-primary">
            Xác nhận
          </button>
          {errors && <p style={{ color: 'red' }}>Lỗi: {errors}</p>}
        </form>
      </ModalBody>
    </Modal>
  );
};

export default connect(null, { createActionChapter })(ModalChangePassword);
