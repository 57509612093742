import React, { memo } from 'react';
import { Table } from 'reactstrap';

// local
import Header from './Header'
import RowData from './RowData'

const headers = ["Template", "Tên", "Số sao quy đổi", "Số lượng", ""]

const OrderCollectionDataTable = ({ className, collections = [] }) => (
  <Table className={className} bordered responsive>
    {/* header */}
    <Header headers={headers} />

    {/* body */}
    <tbody>
      {collections.map(collection => (
        <RowData key={collection.id} collection={collection} />
      ))}
    </tbody>
  </Table>
)

export default memo(OrderCollectionDataTable)