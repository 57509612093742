import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, getOne, upsert } from '../../apis/award-admin/collectionTemplate';
import { uploadOneFile } from '../../apis/file-storage/fileStorage';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false,
  isSaving: false,
};

// slice
const collectionTemplate = createSlice({
  name: 'collectionTemplate',
  initialState,
  reducers: {
    // list collection templates
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    setMetadata(state, action) {
      state.metadata = action.payload
    },
    setCollectionTemplateIds(state, action) {
      state.ids = action.payload
    },
    setCollectionTemplates(state, action) {
      state.data = action.payload
    },
    // collection template
    setSaving(state, action) {
      state.isSaving = action.payload
    },
    setCollectionTemplate(state, action) {
      const { isNew = false, ...restPayload } = action.payload

      // append to first index new item's id if isNew = true
      if (isNew)
        state.ids.unshift(restPayload.id)

      // modify data with new item
      state.data[restPayload.id] = { ...state.data[restPayload.id], ...restPayload }
    },
  }
})

// actions
export const {
  // list collection templates
  setLoading,
  setMetadata,
  setCollectionTemplateIds,
  setCollectionTemplates,
  // collection template
  setSaving,
  setCollectionTemplate,
} = collectionTemplate.actions

// get all collection templates
export const getAllCollectionTemplates = ({ page, limit, keyword, sort, shouldPopulateCollectionCategory = true } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({ page, limit, keyword, sort, shouldPopulateCollectionCategory });

      if (!res.result)
        toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.metadata))
        dispatch(setCollectionTemplateIds(_map(res.data, 'id')))
        dispatch(setCollectionTemplates(_keyBy(res.data, 'id')))
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata))
      dispatch(setCollectionTemplateIds(initialState.ids))
      dispatch(setCollectionTemplates(initialState.data))
    }

    dispatch(setLoading(false));
  };

// upsert collection template
export const upsertCollectionTemplate = ({
  _id,
  collectionCategoryIds,
  collectionCategories,
  bgImage,
  bgImageUrl,
  bgSound,
  bgSoundUrl,
  forceNotUploadImage = false,
  forceNotUploadSound = false,
} = {}) =>
  async (dispatch) => {
    let result = false
    
    dispatch(setSaving(true));

    try {
      let newBgImageUrl = bgImageUrl

      // upload image
      if (bgImage && !forceNotUploadImage) {
        const formData = new FormData();
        formData.append('file', bgImage);

        const uploadImageRes = await uploadOneFile(formData)

        if (!uploadImageRes.result)
          toast.error(`Lỗi: ${JSON.stringify(uploadImageRes.message)}`);
        else
          newBgImageUrl = uploadImageRes.data.fileAccessLink
      }

      let newBgSoundUrl = bgSoundUrl

      // upload sound
      if (bgSound && !forceNotUploadSound) {
        const formData = new FormData();
        formData.append('file', bgSound);

        const uploadSoundRes = await uploadOneFile(formData)

        if (!uploadSoundRes.result)
          toast.error(`Lỗi: ${JSON.stringify(uploadSoundRes.message)}`);
        else
          newBgSoundUrl = uploadSoundRes.data.fileAccessLink
      }

      const res = await upsert({
        _id,
        collectionCategoryIds,
        bgImageUrl: newBgImageUrl,
        previewBgImageUrl: newBgImageUrl,
        bgSoundUrl: newBgSoundUrl,
        previewBgSoundUrl: newBgSoundUrl,
      });

      if (!res.result)
        toast.error(`Lỗi: ${JSON.stringify(res.message)}`);
      else {
        dispatch(
          setCollectionTemplate({
            ...res.data,
            collectionCategories,
            ...(!_id && ({ isNew: true })), // flag to determine adding new
          })
        )

        toast.success(`${_id ? 'Lưu' : 'Thêm'} template thành công`);

        result = true
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${JSON.stringify(error.message)}`);
    }

    dispatch(setSaving(false));

    return result
  };

export default collectionTemplate.reducer;
