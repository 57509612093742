import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../components/Common/Loading';
import Header from '../../components/Common/Header';
import NewPathForm from './NewPathForm';
import { useDispatch, useSelector } from 'react-redux';
import { IconChapter, IconAdd } from '../../icons/IconSvg';
import Photo from '../../components/Common/Photo';
import { fetchPathAction } from '../../reducers/paths';
import './style.scss'

const Path = () => {
    const [open, setOpen] = useState(false);
    const history = useHistory()
    const paths = useSelector((state) => state.paths);
    const auth = useSelector((state) => state.auth);
    const roles = auth && auth.authData && auth.authData.realm_access && auth.authData.realm_access.roles ? auth.authData.realm_access.roles : [];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPathAction());
    }, []);

    return (
        <>
        {paths.syncing && <Loading />}
        <Header/>
        <div className='path-wrapper'>
            <div className='header'>
                <h2>Các lộ trình học của bạn</h2>
                {roles.includes("cms-admin") && (<button onClick={() => {
                    history.push('/publish/path')
                }}>Duyệt lộ trình học</button>)}
            </div>
            <div className='path-list'>
                <div className='create-path' onClick={() => setOpen(true)}>
                    <IconAdd/>
                    <h2>Tạo lộ trình học mới</h2>
                </div>
                {paths.data.map((data) => (
                <div className='path-item' key={data.id} onClick={() => {
                    history.push(`/path/${data.id}`)
                }}>
                    <div className='path-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='path-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='path-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
            </div>
        </div>
        {open && (<NewPathForm open={open} toggle={() => setOpen(!open)} />)}
        </>
    )
}

export default Path