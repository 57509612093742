import React from 'react';
import Gravatar from 'react-gravatar';

class UserAvartar extends React.Component {
  render() {
    return (
      <div className="user-avatar">
        <Gravatar email={this.props.email || ''} default="identicon" />
      </div>
    );
  }
}

export default UserAvartar;
