import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Button } from '@mui/material';
import { IconClose } from '../../../icons/IconSvg';
import { ButtonAction } from '../../Common/Button/ButtonAction';

export const VersionComment = ({ onClose = null, onClick }) => {
  const [text, setText] = useState('');

  return (
    <div className="version-comment">
      <div className="version-comment-header">
        <span>Lời bình</span>
        <span
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          <IconClose />
        </span>
      </div>
      <div className="version-comment-body">
        <textarea onChange={e => setText(e.target.value)}></textarea>
      </div>
      <div className="version-comment-footer">
        <ButtonAction text="Gửi" size="small" onClick={() => onClick(text)}/>
      </div>
    </div>
  );
};
