import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ButtonAction } from '../../../Common/Button/ButtonAction';
import { VersionComment } from '../../VersionComment';
import './style.scss';

export const CourseListAction = (props) => {
  const [isOpenComment, setOpenComment] = useState(false);
  const handleClick = (e) => {
    setOpenComment(!isOpenComment);
  };
  return (
    <div className="list-action">
      <div className="course-title">
        <div className="course-name">Tiếng Việt 1</div>
        <div className="course-status">Trạng thái: Chờ xét duyệt</div>
      </div>
    </div>
  );
};
