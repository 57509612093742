import React, { memo } from 'react';
import { Spinner, Table } from 'reactstrap';

// components
import Loading from '../../../../components/Common/Loading';
import Pagination from '../../../../components/Common/Pagination';

// local
import Filters from './Filters';
import Actions from './Actions';
import Header from './Header';
import RowData from './RowData';
import CollectionItemDataTableCtrl from './CollectionItemDataTable.ctrl';

const headers = [
  { id: 'status', label: 'Trạng thái', allowSorting: true },
  { id: 'imageUrl', label: 'Ảnh' },
  { id: 'name', label: 'Tên', allowSorting: true },
  { id: 'collectionCategory', label: 'Thể  loại' },
  { id: 'rarity', label: 'Độ hiếm' },
  { id: 'description', label: 'Mô tả', style: { width: '400px' } },
  { id: 'price', label: 'Số sao quy đổi' },
  { id: 'quantity', label: 'Số lượng' },
  { id: 'actions', label: '' },
];

const CollectionItemDataTable = memo(({ className }) => {
  // -- ctrl --
  const {
    // collection items
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
  } = CollectionItemDataTableCtrl.useContainer();

  return (
    <div className={className}>
      <div className="t-flex t-justify-between t-items-center">
        {/* filters */}
        <div className="t-w-full">
          <Filters />
        </div>

        {/* actions */}
        <Actions className="ml-4 pt-3" />
      </div>

      {/* table */}
      <Table bordered responsive>
        {/* header */}
        <Header headers={headers} />

        {/* body */}
        <tbody>
          {/* loading */}
          {isLoading && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={9}>
                <Spinner color="primary" />
              </td>
            </tr>
          )}

          {/* empty data */}
          {!isLoading && ids.length === 0 && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={9}>
                Không có dữ liệu
              </td>
            </tr>
          )}

          {/* data */}
          {!isLoading && ids.length > 0 && ids.map((id) => <RowData key={id} id={id} />)}
        </tbody>
      </Table>

      {/* pagination */}
      <Pagination
        total={metadata.total}
        limit={tableFilters.limit}
        page={tableFilters.page}
        onPerPageChange={handleChangePerPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
});

export default ({ ...props }) => (
  <CollectionItemDataTableCtrl.Provider>
    <CollectionItemDataTable {...props} />
  </CollectionItemDataTableCtrl.Provider>
);
