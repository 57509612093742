import api from './api';

export async function login(email, password) {
  const loginInfo = await api({
    method: 'POST',
    url: '/auths/login',
    data: { email, password },
  });
  return loginInfo;
}

export const fetchProfile = async (accessToken) =>
  api({
    method: 'POST',
    url: '/auths/verify',
    data: { accessToken },
  });

export const changePassword = async ({ currentPassword, newPassword }) =>
  api({
    method: 'POST',
    url: '/auths/changePassword',
    data: { currentPassword, newPassword },
  });
