const initState = {
  course: {},
  idChapterActive: '',
};

export const ActionsType = {
  CHANGE_COURSE: '@@questionBank/CHANGE_COURSE',
  CHANGE_CHAPTER_ID: '@@questionBank/CHANGE_CHAPTER_ID',
};
export const changeCourse = (course) => ({ type: ActionsType.CHANGE_COURSE, course });
export const changeChapter = (id) => ({ type: ActionsType.CHANGE_CHAPTER_ID, id });

export const questionBank = (state = initState, action) => {
  switch (action.type) {
    case ActionsType.CHANGE_CHAPTER_ID:
      return {
        ...state,
        idChapterActive: action.id,
      };
    case ActionsType.CHANGE_COURSE:
      return {
        ...state,
        course: action.course ? action.course : state.course,
        idChapterActive: null,
      };
    default:
      return state;
  }
};

export default questionBank;
