import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/Common/Header';
import Loading from '../../components/Common/Loading';
import PathBanner from '../../assets/images/path_banner.jpg'
import CourseBanner from '../../assets/images/course_banner.png'
import LessonBanner from '../../assets/images/lesson_banner.jpg'
import TestBanner from '../../assets/images/test_banner.jpg'
import { IconChapter } from '../../icons/IconSvg';
import { useDispatch, useSelector } from 'react-redux';
import Photo from '../../components/Common/Photo';
import { fetchCourseAction } from '../../reducers/courses';
import { fetchPathAction } from '../../reducers/paths';
import { fetchLessonAction } from '../../reducers/lesson';
import { fetchTestAction } from '../../reducers/test';
import './style.scss'

const HomePage = () => {
    const history = useHistory()
    const paths = useSelector((state) => state.paths)
    const courses = useSelector((state) => state.courses)
    const lessons = useSelector((state) => state.lesson)
    const tests = useSelector((state) => state.test)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPathAction())
        dispatch(fetchCourseAction())
        dispatch(fetchLessonAction())
        dispatch(fetchTestAction())
    }, []);

    return (
        <>
        {(paths.syncing || courses.syncing || lessons.syncing || tests.syncing) && <Loading />}
        <Header/>
        <div className='wrapper'>
            <h1>Hệ quản trị nội dung</h1>
            <div className='category-wrapper'>
                <div className='category-banner'>
                    <img src={PathBanner} onClick={() => {
                        history.push('/path')
                    }}></img>
                    <button onClick={() => {
                        history.push('/path')
                    }}>Chi tiết các lộ trình học</button>
                </div>
                <div className='category-data'>
                {paths.data.slice(0, 3).map((data) => (
                <div className='category-item' key={data.id} onClick={() => {
                    history.push(`/path/${data.id}`)
                }}>
                    <div className='category-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='category-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='category-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
                </div>
            </div>

            <div className='category-wrapper'>
                <div className='category-banner'>
                    <img src={CourseBanner} onClick={() => {
                        history.push('/course')
                    }}></img>
                    <button onClick={() => {
                        history.push('/course')
                    }}>Chi tiết các khóa học</button>
                </div>
                <div className='category-data'>
                {courses.data.slice(0, 3).map((data) => (
                <div className='category-item' key={data.id} onClick={() => {
                    history.push(`/course/${data.id}`)
                }}>
                    <div className='category-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='category-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='category-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
                </div>
            </div>

            <div className='category-wrapper'>
                <div className='category-banner'>
                    <img src={LessonBanner} onClick={() => {
                        history.push('/lesson')
                    }}></img>
                    <button onClick={() => {
                        history.push('/lesson')
                    }}>Chi tiết các bài học</button>
                </div>
                <div className='category-data'>
                {lessons.data.slice(0, 3).map((data) => (
                <div className='category-item' key={data.id} onClick={() => {
                    history.push(`/lesson/${data.id}`)
                }}>
                    <div className='category-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='category-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='category-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
                </div>
            </div>

            <div className='category-wrapper'>
                <div className='category-banner'>
                    <img src={TestBanner} onClick={() => {
                        history.push('/test')
                    }}></img>
                    <button onClick={() => {
                        history.push('/test')
                    }}>Chi tiết các bài kiểm tra</button>
                </div>
                <div className='category-data'>
                {tests.data.slice(0, 3).map((data) => (
                <div className='category-item' key={data.id} onClick={() => {
                    history.push(`/lesson/${data.id}`)
                }}>
                    <div className='category-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='category-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='category-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
                </div>
            </div>
        </div>
        </>
    )
}

export default HomePage