import { SQUARE_OBJECT_TYPE } from "../constants";

export const QuizObject = {
  imageUrl: null,
  videoUrl: null,
  audioUrl: null,
  rateVoice: 1.0,
  text: null,
  textVisibility: true,
};

// quiz-part:hidable
export const ChoicePart = {
  content: QuizObject,
  correctness: false,
};

// quiz-part:pair
export const HidablePart = {
  // BlankQuizPart
  content: QuizObject,
  isHidden: QuizObject,
};

// quiz-part:choice
export const PairPart = {
  content: QuizObject,
  pairContent: QuizObject,
};
export const ChoiceQuiz = {
  correctness: false,
  content: { text: '', type: 'text', videoUrl: '', audioUrl: '', rateVoice: 1.0, imageUrl: '' },
};
export const BlankQuiz = {
  content: { text: '', type: 'text', videoUrl: '', audioUrl: '', rateVoice: 1.0, imageUrl: '' },
  wrongAnswer: { text: '', type: 'text', videoUrl: '', audioUrl: '', rateVoice: 1.0, imageUrl: '' },
  isHidden: false,
};
export const PairQuiz = {
  pairContent: { text: '', type: 'text', videoUrl: '', audioUrl: '', imageUrl: '' },
  content: { text: '', type: 'text', videoUrl: '', audioUrl: '', rateVoice: 1.0, imageUrl: '' },
};
export const SpeakingQuiz = {
  content: {text: '', type: 'text', videoUrl: '', audioUrl: '', rateVoice: 1.0, imageUrl: '', voice: '', },
  minCorrectPercent: 100, 
};
export const Quiz = {
  id: null,
  type: 'quiz:mc_quiz', // (quiz:drag-and-drop; quiz:match-the-pair; quiz:multi-choice; quiz:single-choice, quiz:speaking)
  question: '',
  rateVoice: 1.0,
  tags: ['Related Tags'],
  score: 0,
  quizParts: [], // [ChoicePart, PairPart, HidablePart]
  level: 'beginner',
  hint: 'Some idea if student need help...',
  reward: 'reward-id',
};

// square quiz
// word_in_sentence
// export const squareQuizType = ['word_in_sentence', 'tone_of_word', 'elements_in_word'];
export const squareQuizType = [
  SQUARE_OBJECT_TYPE.WORD_IN_SENTENCE,
  // SQUARE_OBJECT_TYPE.ELEMENTS_IN_WORD,
  SQUARE_OBJECT_TYPE.TONE_OF_WORD,
  SQUARE_OBJECT_TYPE.PHONEME_OF_RHYME,
  SQUARE_OBJECT_TYPE.PHONEME_OF_SYLLABLE,
  SQUARE_OBJECT_TYPE.ELEMENTS_OF_WORD
];
export const wirtingQuiz = {
  question: '',
  type: 'writing_quiz',
  hint: '',
  questionImageUrl: '',
  backgroundUrl: '',
  inTestBank: false,
  score: 100,
};
export const TheoryObject = {
  id: null,
  type: 'text', // (text, image, video, square)
  hiddenText: false,
  // text ***********************
  text: null,
  audioUrl: null,
  rateVoice: 1.0,
  realisedAcoustParams: [],
  // image **********************
  imageUrl: null,
  // video **********************
  videoUrl: null,
  // square *********************
  squareObject: null,
};
export const Theory = {
  id: null,
  title: 'Bài học mới',
  rateVoice: 1.0,
  layout: 0,
  customLayout: {},
  type: 'theory:custom', // theory:left-right; theory:up-down...
  tip: 'Hãy sử dụng mẹo này để học bài tốt hơn nhé',
  tags: ['bài học'],
  condition: { reading: 0, listening: 0, writing: 0, speaking: 0 },
};

export const Overview = {
  id: null,
  layout: 'default',
  content: {
    speaker: '', // default voice audio
    text: '', 
    audioUrl: '',
    rateVoice: '',
  },
  starTotal: 2,
  learningPointParts: 2,
  learningPointPartTitles: [
      {
          id: 1,
          title: ''
      },
      {
          id: 2,
          title: ''
      }
  ],
  welcome: {
    speaker: '',
    text: '',
    audioUrl: '',
    rateVoice: '',
  },
  background: '',
  template: 'Sa mạc', // template is a LessonNavigationTemplate Object,,
};

export const Welcome = {
  id: null,
  layout: 'default',
};

export const Reward = { // reward === checkpoint
  id: null,
  layout: 0,
  customLayout: {},
  type: 'checkpoint:default',
  title: '', 
  stars: 1,
  speaker: '',
  congratulationText: '',
  soundUrl: '',
  rateVoice: 1.0,
  starLocation: {
    star1: true,
    star2: false,
    star3:  false,
  }
};

export const Lesson = {
  id: null,
  title: 'Untitled Lesson',
  backgroundUrl: '',
  learningPoints: [], // Theory, Quiz, Reward
  checkpoint: {},
  thumbnailImageUrl: 'this is a link',
  overview: Overview
};

export const MediaBox = [
  // one media box
  {
    name: 'default',
    maxWidth: '700px',
    maxHeight: '400px',
  },
  // two media box up-down
  {
    name: 'up-down',
    up: {
      maxWidth: '700px',
      maxHeight: '300px',
    },
    down: {
      maxWidth: '700px',
      maxHeight: '300px',
    },
  },
   // two media box left-right
  {
    name: 'left-right',
    right: {
      maxWidth: '600px',
      maxHeight: '400px',
    },
    left: {
      maxWidth: '600px',
      maxHeight: '400px',
    }
  },
  // three media box one-up-two-down
  {
    name: 'one-up-two-down',
    up: {
      maxWidth: '700px',
      maxHeight: '300px'
    },
    down: {
      maxWidth: '600px',
      maxHeight: '300px'
    }
  },
  // three media box two-up-one-down
  {
    name: 'two-up-one-down',
    up: {
      maxWidth: '600px',
      maxHeight: '300px'
    },
    down: {
      maxWidth: '700px',
      maxHeight: '300px'
    }
  },
    // four media box two-up-two-down
  {
    name: 'two-up-two-down',
    up: {
      maxWidth: '600px',
      maxHeight: '300px'
    },
    down: {
      maxWidth: '600px',
      maxHeight: '300px'
    },
  }
];