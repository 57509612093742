import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localStorage from 'redux-persist/lib/storage';

import middlewares from '../middlewares';
import rootReducer from '../reducers/index';

const middleWare = applyMiddleware(...middlewares);

const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['auth'],
  // stateReconciler: autoMergeLevel2, // Xem thêm tại mục "Quá trình merge".
};

const pReducer = persistReducer(persistConfig, rootReducer);
/* eslint-disable no-underscore-dangle */
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
const composeEnhancers =
  process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const store = createStore(pReducer, composeEnhancers(middleWare));
/* eslint-enable */
export const persistor = persistStore(store);
