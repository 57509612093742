import React from 'react';
import { connect } from 'react-redux';
import Shape from './Shape';
import { IconCheck } from '../../icons/IconSvg';

class CheckBox extends React.Component {
  render() {
    return (
      <div onClick={this.props.changeCheckBox} className="ui__check__box">
        <div className="wraper_checkbox">
          <div className="icon_checkmark">{this.props.checked ? <IconCheck /> : null}</div>
          <Shape type="square" color="#fff" />
        </div>
      </div>
    );
  }
}
export default connect(null, {})(CheckBox);
