import apiV2 from '../apiV2';

export const getAll = async ({ page = 1, limit = 10, keyword, sort = { createdAt: -1 }, type, rarity, status }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/luckyBox/all`,
    data: { page, limit, keyword, sort, type, rarity, status }
  });
}

export const getOne = async ({ _id }) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/luckyBox/one`,
    data: { _id }
  });
}

export const upsert = async (dataToUpdate) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/luckyBox/upsert`,
    data: dataToUpdate
  });
}

export const generateMore = async (requestParams) => {
  return await apiV2({
    method: 'POST',
    url: `/award-admin/luckyBox/generateMore`,
    data: requestParams
  });
}