import React from 'react';
import { Container } from 'reactstrap';

// components
import AwardAdminHeader from '../../../components/Common/AwardAdminHeader';

// local
import CollectionCategoryDataTable from './CollectionCategoryDataTable/CollectionCategoryDataTable'

const CollectionCategoryContainer = () => (
  <>
    {/* header */}
    <AwardAdminHeader />

    {/* body */}
    <Container className="t-pt-[100px] t-w-[80%] t-mx-auto">
      {/* page title */}
      <h1 className="mb-0">Thể  loại</h1>

      {/* collection category data table */}
      <CollectionCategoryDataTable className="t-mt-20" />
    </Container>
  </>
);

export default CollectionCategoryContainer;
