export const SYSTEM_COLLECTION_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  SOLD: 'sold',
}

export const SYSTEM_COLLECTION_STATUS_NAME = {
  [SYSTEM_COLLECTION_STATUS.DRAFT]: 'Nháp',
  [SYSTEM_COLLECTION_STATUS.PUBLISHED]: 'Trưng bày',
  [SYSTEM_COLLECTION_STATUS.SOLD]: 'Đã bán',
}

export const SYSTEM_COLLECTION_STATUS_COLOR_SUFFIX_CLASS = {
  [SYSTEM_COLLECTION_STATUS.DRAFT]: 'info',
  [SYSTEM_COLLECTION_STATUS.PUBLISHED]: 'success',
  [SYSTEM_COLLECTION_STATUS.SOLD]: 'danger',
}