import React from 'react';
import { connect } from 'react-redux';
import { changeCourse } from '../../reducers/questionBank';
import { getQuizzInTestBank } from '../../actions/ActionQuestionBank';

class SelectCourse extends React.Component {
  render() {
    const { courses, course } = this.props;
    return (
      <select
        className="btn"
        onChange={(e) => {
          // eslint-disable-next-line radix
          this.props.changeCourse(courses.find((ct) => ct.id === parseInt(e.target.value)));
          this.props.getQuizzInTestBank({ courseId: course.id, chapterId: null });
        }}
        style={{ border: '1px solid #c2c2c2', padding: '5px 5px', width: '100%', textAlign: 'center' }}
      >
        {courses.map((cate) => (
          <option key={cate.id} value={cate.id} className="div_item">
            {cate.title || ''}
          </option>
        ))}
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  course: state.questionBank.course,
});
export default connect(mapStateToProps, { changeCourse, getQuizzInTestBank })(SelectCourse);
