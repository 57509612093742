import React, { useEffect, useRef, useState } from 'react';
import { IconClose, IconComment, IconThreeDot, IconTick } from '../../../../../icons/IconSvg';
import { getFullUrl } from '../../../../../utils/imageProcessing';
import '../style.scss';

export const ListChapter = ({ chapters }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="list-chapter">
      <div className="list-chapter-label">Chủ đề</div>
      <div className="chapters">
        {chapters &&
          chapters.map((chapter, index) => {
            return (
              <div className="general-box chapter-item" key={`chapter-${index}`}>
                <div className="chapter-thumbnail">
                  <img src={getFullUrl(chapter.imageUrl)} />
                </div>
                <div className="chapter-content">
                  <div className="chapter-name">{chapter.title}</div>
                  <div className="chapter-status">Trạng thái: {chapter.status}</div>
                </div>
                {/* <div
                  className="chapter-action"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!isOpen);
                  }}
                >
                  <IconThreeDot />
                  {isOpen && (
                    <div className="action-detail">
                      <div className="action">
                        <IconTick /> <span>Duyệt</span>
                      </div>
                      <div className="action">
                        <IconComment /> <span>Yêu cầu thay đổi</span>
                      </div>
                      <div className="action">
                        <IconClose /> <span>Từ chối</span>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};
