import moment from 'moment';
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

export const formatDate = (date, form = 'HH:mm DD/MM/YYYY') => {
    return moment(date).local('vi').format(form);
}

export const isValidDateString = (dateString = '', dateFormat = '', options = {}) => {
    const { required = true, minDate, maxDate } = options

    if (dateString === '') return !required
    try {
        const parsedDate = parse(dateString, dateFormat, new Date())
        const formattedDate = format(parsedDate, dateFormat)

        // validate date format
        if (formattedDate !== dateString) return false

        // validate min date
        if (minDate && parsedDate.getTime() < minDate.getTime()) return false

        // validate max date
        if (maxDate && parsedDate.getTime() > maxDate.getTime()) return false

        return true
    } catch (error) {
        return false
    }
}

export const zonedDateToLocalDate = (zonedDate, timezone) => {
    const utcDate = zonedTimeToUtc(zonedDate, timezone)
    const localDate = utcToZonedTime(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone)
    return localDate
}