import React, { useState, useEffect } from 'react';
import TestAudio from './TestAudio';

function _InputTextarea(props) {
  const { value, onChange, audioUrl, placeholder, limitText } = props;
  const [localText, setLocalText] = useState(value);
  useEffect(() => {
    let timer = setTimeout(() => onChange(localText), 500);
    return () => {
      clearTimeout(timer)
    };
  }, [localText]);
  return (
    <div className="input-textarea-container">
      <TestAudio audioUrl={audioUrl} />
      <textarea
        maxLength={limitText || null}
        defaultValue={value || ''}
        placeholder={placeholder || 'Nhập nội dung'}
        className="next-input border-input-none form__type"
        onChange={(e) => {
          if (limitText && e.target.value.length <= limitText) setLocalText(e.target.value);
          else setLocalText(e.target.value);
        }}
      />
      {limitText && <p>{localText.length}/{limitText}</p>}
    </div>
  );
}
const InputTextareaV2 = React.memo(_InputTextarea);
export default InputTextareaV2;
