import React from 'react';
import { Button } from 'reactstrap'

// local
import OrderModal from '../OrderModal/OrderModal';
import OrderDataTableCtrl from './OrderDataTable.ctrl';

const Actions = ({ className }) => {
  // -- ctrl --
  const {
    // modal
    isModalOpen,
    toggleModal,
    modalItemId,
  } = OrderDataTableCtrl.useContainer()

  return (
    <div className={className}>
      {isModalOpen && (<OrderModal open={isModalOpen} toggle={toggleModal} id={modalItemId} />)}
    </div>
  );
};

export default Actions;