import React from 'react';
import { Link } from 'react-router-dom';

class ComponentTestFixed extends React.Component {
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };
  selectQuizId = (id) => {
    const { quizIds } = this.props.dataTest;
    let ids = [];
    if (quizIds.includes(id)) {
      ids = quizIds.filter((idQ) => idQ !== id);
    } else {
      ids = quizIds.concat([id]);
    }
    this.props.changeData({ ...this.props.dataTest, quizIds: ids });
  };
  render() {
    const { popoverOpen } = this.state;
    const { listQuizzes, dataTest, changeData, idTest } = this.props;
    const quizzesSelected = listQuizzes.filter((quiz) => dataTest.quizIds.includes(quiz.id));
    return <div>{idTest ? <Link to={`/quizzes-test/${idTest}`}>Quản lý chi tiết các bài tập</Link> : null}</div>;
  }
}

export default ComponentTestFixed;
