import React, { useCallback, memo } from 'react'
import cls from 'classnames'
import { useFormContext, useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { FormText } from 'reactstrap'

const InputNumberField = ({ className, name, id, ...props }) => {
  // -- form state --
  const { control, errors } = useFormContext()

  // -- field state --
  const {
    field: { ref, onChange: defaultOnChange, ...field },
    meta,
  } = useController({ name, control })
  const { invalid } = meta

  // -- custom on change --
  const customOnChange = useCallback((values) => {
    const { floatValue = '' } = values
    defaultOnChange(floatValue)
  }, [defaultOnChange])

  return (
    <>
      <NumberFormat
        className={cls('form-control', { 'is-invalid': !!invalid }, className)}
        name={name}
        id={id}
        {...field}
        getInputRef={ref}
        onValueChange={customOnChange}
        {...props}
      />

      {errors[name] && <FormText color="danger">{errors[name].message}</FormText>}
    </>
  )
}

export default memo(InputNumberField)
