import { getSoundFile, getSoundUrl } from '../apis/voice';
import { removeFileFromDB } from '../utils/imageProcessing';
import { handleAudioUrl } from '../utils/downloadAudio';

// Tim learningPoint tuong ung
export const oldLearningPointsCurrent = (oldLearningPoints, idLearningCompare) => {
  return oldLearningPoints.find((learning) => learning.id === idLearningCompare);
};

export const oldQuizPartsCurrent = (oldQuizParts, idQuizPartCompare) => {
  if (!oldQuizParts) {
    return null;
  }
  return oldQuizParts.find((quizPart) => quizPart.id === idQuizPartCompare);
};

const getAudioUrl = async (text, speaker) => {
  let url = null;
  await getSoundUrl(speaker, text)
    .then((response) => {
      url = response.data.wav_path;
    })
    .catch((error) => {
      return '';
    });
  return url;
};

const convertTTSUrl2DBUrl = async (speaker, text, rateVoice = 1) => {
  const url = await getSoundUrl(text, speaker, rateVoice);
  const dbUrl = await handleAudioUrl(url.data.wav_path);
  return dbUrl;
};

export const uploadAudioQuestion = async (newQuiz, oldQuiz, speaker) => {
  if (newQuiz.question === oldQuiz.question) {
    // blank oldQuiz.question => return blank url
    if (!oldQuiz.question) return '';

    // same but dont have url
    if (!oldQuiz.questionAudioUrl) {
      return convertTTSUrl2DBUrl(newQuiz.question, speaker);
    }

    // same & have url
    return oldQuiz.questionAudioUrl;
  }

  // blank newQuiz.question => return blank url
  if (!newQuiz.question) return '';

  // Exist newQuiz.question & diffrent content

  // 1. Remove old questionAudioUrl if exist
  if (oldQuiz.questionAudioUrl && oldQuiz.questionAudioUrl.includes('{api_url}'))
    removeFileFromDB(oldQuiz.questionAudioUrl);

  // 2. Call TTS & Save to DB
  return convertTTSUrl2DBUrl(newQuiz.question, speaker);
};

export const uploadAudio = async (oldQuizParts, quizPart, key, speaker) => {
  // phần này là code cũ chưa tối ưu --> cải thiện sau
  const oldData = oldQuizPartsCurrent(oldQuizParts, quizPart.id);
  let data = { ...quizPart };
  try {
    if (quizPart[key].text && quizPart[key].text !== '\n' && quizPart[key].type === 'text') {
      if (!oldData || (oldData && oldData[key].text !== quizPart[key].text) || !quizPart[key].audioUrl) {
        // Remove old file if have
        if (quizPart[key].audioUrl && quizPart[key].audioUrl.includes('{api_url}'))
          removeFileFromDB(quizPart[key].audioUrl);
  
        // Get TTS
        const { audioUrl, rateVoice, voice} = quizPart[key];
        const _voice = voice || speaker || 'ngochuyen';
        let Url = audioUrl;
        if (audioUrl && !audioUrl.includes('{api_url}') && !audioUrl.includes('https://vietstudy-upload.s3.ap-southeast-1.amazonaws.com/')) {
          Url = await handleAudioUrl(audioUrl);        
        }
        if (!audioUrl) {
          Url = await convertTTSUrl2DBUrl(quizPart[key].text, _voice, rateVoice);
        }
        
        data = {
          ...data,
          [key]: {
            ...data[key],
            audioUrl: Url,
            voice : voice ? voice : _voice,
          },
        };
      }
    }
    return data;
  } catch (e) {
    console.log("Error:", e);
    return { ...quizPart };
  }
};
