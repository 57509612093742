export const ORDER_COLLECTION_TYPES = {
  SYSTEM_COLLECTION: 'system collection',
  USER_COLLECTION: 'user collection',
}

export const ORDER_COLLECTION_TYPE_NAME = {
  [ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION]: 'Bộ sưu tập hệ thống',
  [ORDER_COLLECTION_TYPES.USER_COLLECTION]: 'Bộ sưu tập người dùng',
}

export const ORDER_TYPE_OPTIONS = [
  {
    value: ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION,
    label: ORDER_COLLECTION_TYPE_NAME[ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION],
  },
  {
    value: ORDER_COLLECTION_TYPES.USER_COLLECTION,
    label: ORDER_COLLECTION_TYPE_NAME[ORDER_COLLECTION_TYPES.USER_COLLECTION],
  },
]