// import { formatQuizPart } from '../../actions/LessonUtils';

export const TONELEVEL = ['a', 'â', 'ă', 'e', 'ê', 'i', 'o', 'ô', 'ơ', 'u', 'ư', 'y'];
export const TONEFALLING = ['à', 'ầ', 'ằ', 'è', 'ề', 'ì', 'ò', 'ồ', 'ờ', 'ù', 'ừ', 'ỳ'];
export const TONERISING = ['á', 'ấ', 'ắ', 'é', 'ế', 'í', 'ó', 'ố', 'ớ', 'ú', 'ứ', 'ý'];
export const TONECURVE = ['ả', 'ẩ', 'ẳ', 'ẻ', 'ể', 'ỉ', 'ỏ', 'ổ', 'ở', 'ủ', 'ử', 'ỷ'];
export const TONEBROKEN = ['ã', 'ẫ', 'ẵ', 'ẽ', 'ễ', 'ĩ', 'õ', 'ỗ', 'ỡ', 'ũ', 'ữ', 'ỹ'];
export const TONEDROP = ['ạ', 'ậ', 'ặ', 'ẹ', 'ệ', 'ị', 'ọ', 'ộ', 'ợ', 'ụ', 'ự', 'ỵ'];
export const LISTFIRSTSOUND = [
  'ch',
  'nh',
  'tr',
  'gi',
  'ng',
  'ph',
  'kh',
  'th',
  'b',
  'c',
  'd',
  'đ',
  'g',
  'h',
  'l',
  'm',
  'n',
  'p',
  'r',
  's',
  't',
  'v',
  'x',
];
export const LISTFIRSTSOUNDNGH = ['gh', 'ngh', 'k'];
export const LISTFIRSTSOUNDQ = ['q'];
export const LISTSOUNDY = [
  {
    w: '',
    v: 'yê',
    c: 'ng',
  },
  {
    w: '',
    v: 'yê',
    c: 't',
  },
  {
    w: '',
    v: 'yê',
    c: 'p',
  },
  {
    w: '',
    v: 'yê',
    c: 'm',
  },
  {
    w: '',
    v: 'yê',
    c: 'u',
  },
  {
    w: '',
    v: 'yê',
    c: 'n',
  },
];
export const objSplitSound = { V: '', w: '', c: '' };
export const splitDataEnter = (value) => {
  const arraysT = value.split(' ');
  const datas = arraysT.reduce((arrayNew, elem) => {
    if (elem.includes('\n')) {
      const splitArray = elem.split('\n');
      splitArray.forEach((element, index) => {
        if (index !== splitArray.length - 1) {
          arrayNew = [...arrayNew, element, '\n'];
        } else {
          arrayNew = [...arrayNew, element];
        }
      });
    } else {
      arrayNew = [...arrayNew, elem];
    }
    return arrayNew;
  }, []);
  return datas;
};
function formatQuizPartE(quizParts) {
  let datas = [];
  const indx = quizParts.findIndex((elm) => elm.content.text === '\n');
  quizParts.forEach((element, index) => {
    if (indx > 0 && index === indx) {
      datas = [...datas, { ...quizParts.slice(0, indx) }, ...formatQuizPartE(quizParts.slice(indx + 1))];
    }
  });
  if (indx < 0) {
    datas = [...datas, { ...quizParts }];
  }
  return datas;
}
export const formatQuizpartEnter = (quizParts) => {
  const datas = formatQuizPartE(quizParts);
  return datas;
};

export const formatSquare = data => {
  let res = null;
  res =  data.map(item => {
    const { syllable, consonant, tone, rhymeObj } = item;
    if (!rhymeObj) return null;
    const { rhyme, mainRhyme, finishRhyme, startRhyme} = rhymeObj;
    return {
      content: {
        text: syllable,
        audioUrl: ''
      },
      structure: {
        consonant,
        tone,
        rhyme: {
          content: {
            text: rhyme,
            audioUrl: ''
          },
          structure: {
            mainRhyme,
            finishRhyme,
            startRhyme
          }
        }
      }
    }
  });
  return res;
}