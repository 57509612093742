import React from 'react';
import { getFullUrl } from '../../utils/imageProcessing';

class ComponentUploadImg extends React.Component {
  render() {
    const { changeData, dataTest, readImg, textTypeChapter } = this.props;
    return (
      <>
        <div>
          <div className="fl_b pd-20">
            <div className="ft-w">Ảnh bìa</div>
            <button
              type="button"
              style={{ border: '1px solid #ced4da' }}
              className="btn"
              onClick={() => {
                if (this.dropzoneRefAvatar) {
                  this.dropzoneRefAvatar.click();
                }
              }}
            >
              Tải ảnh
              <input
                style={{ display: 'none' }}
                type="file"
                multiple
                onChange={(e) => readImg(e, 'img_avatar_test')}
                ref={(ref) => {
                  this.dropzoneRefAvatar = ref;
                }}
              />
            </button>
          </div>
          <div className=" wapper__img_modal">
            <div
              style={{
                border: '1px solid #E5E5E5',
                width: '300px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
              }}
              className="m-auto"
            >
              <div className="m-auto">
                {dataTest.fileImage || (!dataTest.fileImage && dataTest.imageUrl) ? null : (
                  <div>
                    <i>Tải file ảnh vào đây</i>
                  </div>
                )}
                <img
                  style={{ marginTop: 0, objectFit: "contain" }}
                  src={dataTest.imageUrl ? getFullUrl(dataTest.imageUrl) : ''}
                  id="img_avatar_test"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pd-20">
          <div className="fl_b pd-20">
            <div>
              <div className="ft-w">Ảnh Thumbnail</div>
              <div className="text-guid">
                <i>{`Ảnh thu nhỏ ${textTypeChapter}`}</i>
              </div>
            </div>
            <button
              type="button"
              style={{ border: '1px solid #ced4da' }}
              className="btn"
              onClick={() => {
                if (this.dropzoneRefThumb) {
                  this.dropzoneRefThumb.click();
                }
              }}
            >
              Tải ảnh
              <input
                style={{ display: 'none' }}
                type="file"
                multiple
                onChange={(e) => readImg(e, 'img_thumbnail_test')}
                ref={(ref) => {
                  this.dropzoneRefThumb = ref;
                }}
              />
            </button>
          </div>
          <div className=" wapper__img_modal">
            <div
              style={{
                border: '1px solid #E5E5E5',
                width: '300px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
              }}
              className="m-auto"
            >
              <div className="m-auto">
                {dataTest.fileThumb || (!dataTest.fileThumb && dataTest.thumbnailImageUrl) ? null : (
                  <div>
                    <i>Tải file ảnh vào đây</i>
                  </div>
                )}
                <img
                  style={{ marginTop: 0, objectFit: "contain" }}
                  src={dataTest.thumbnailImageUrl ? getFullUrl(dataTest.thumbnailImageUrl) : ''}
                  id="img_thumbnail_test"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ComponentUploadImg;
