import React, { memo } from 'react'
import { FormProvider } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

// local
import CollectionTemplateModalCtrl from './CollectionTemplateModal.ctrl';
import CollectionTemplateForm from './CollectionTemplateForm';

const CollectionTemplateModal = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // form state
    collectionTemplateForm,
    // on submit
    onSubmit,
  } = CollectionTemplateModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>
        {id ? 'Chỉnh sửa template' : 'Thêm template mới'}
      </ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormProvider {...collectionTemplateForm}>
          <CollectionTemplateForm />
        </FormProvider>
      </ModalBody>

      {/* modal footer */}
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {id ? 'Lưu' : 'Thêm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ({ ...props }) => (
  <CollectionTemplateModalCtrl.Provider initialState={props}>
    <CollectionTemplateModal {...props} />
  </CollectionTemplateModalCtrl.Provider>
);