import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CourseIcon } from '../../../../icons/IconSvg';
import { GiMountainRoad } from 'react-icons/gi';
import './style.scss';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { ButtonAction } from '../../../../components/Common/Button/ButtonAction';
import { useDispatch } from 'react-redux';
import { updateFieldPath, updateFieldCourse } from '../../../../reducers/paths';
import { updateStatusAction } from '../../../../actions/ActionPublishPath';
import { FRAME_CONTENT_STATUS, PATH_STATUS } from '../../../../constants';
import { getCookie } from '../../../../utils/accessToken';

const PATH_ITEM = 'path_item';
const COURSE_ITEM = 'course_item';

const PublishPopup = ({ path, setPath, courses, className, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const onChangeItem = (e, id, type) => {
    if (type === 'course') {
      dispatch(
        updateFieldCourse({
          id,
          key: 'status',
          value: e.target.checked ? FRAME_CONTENT_STATUS.PUBLISH : FRAME_CONTENT_STATUS.NOT_PUBLISH,
          setPath,
          path
        }),
      );
    } else if (type === 'path') {
      dispatch(
        updateFieldPath({
          id,
          key: 'status',
          value: e.target.checked ? PATH_STATUS.PUBLISH : PATH_STATUS.NOT_PUBLISH,
          setPath: setPath,
          path
        }),
      );
    }
  };

  const Item = ({ id, type, status, label, startIcon, className, path }) => {
    return (
      <div className={`frame-content-item ${className}`}>
        <div className="frame-content-item-content">
          <span className="start-icon">{startIcon}</span>
          <span className="label">{label}</span>
        </div>
        <div className="checkbox">
          <Checkbox
            style={{ color: '#2B429D' }}
            onChange={(e) => onChangeItem(e, id, type, path)}
            checked={
              status ===
              (type === 'path' ? PATH_STATUS.PUBLISH : FRAME_CONTENT_STATUS.PUBLISH)
            }
          />
        </div>
      </div>
    );
  };

  const onClick = (e) => {
    const token = getCookie('accessToken')
    dispatch(updateStatusAction(path.id, token));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="choose-lesson-header">
        <h5>Kiểm duyệt lộ trình học</h5>
      </ModalHeader>
      <ModalBody>
        <div className="publish-modal">
          <div className="publish-tip">Tích vào ô tương ứng với phần mà bạn muốn xuất bản hoặc ngược lại</div>
          <div className="publish-modal-body">
            <div className={`frame-content-publish ${className}`}>
              <Item
                id={path.id}
                path={path}
                type="path"
                status={path.status}
                startIcon={<GiMountainRoad size={20} color='black' />}
                label={path.title}
                className={PATH_ITEM}
              />
              {courses
                .map((course, _index) => {
                  return (
                    <Item
                      id={course.courseId.id}
                      path={path}
                      startIcon={<CourseIcon />}
                      type='course'
                      label={course.courseId.title}
                      className={COURSE_ITEM}
                      status={course.courseId.status}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="publish-modal-footer">
          <ButtonAction size="small" text="Gửi" onClick={onClick} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default PublishPopup