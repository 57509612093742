import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BiEdit } from 'react-icons/bi'

// local
import CollectionTemplateDataTableCtrl from './CollectionTemplateDataTable.ctrl';

const RowData = ({ className, id }) => {
  // -- ctrl --
  const {
    // modal
    setIsModalOpen,
    setModalItemId,
  } = CollectionTemplateDataTableCtrl.useContainer()

  // -- collection template --
  const {
    bgImageUrl,
    bgSoundUrl,
    collectionCategories = [],
  } = useSelector((state) => state.collectionTemplates.data[id]) || {};

  // -- handle edit --
  const handleEdit = useCallback(() => {
    setModalItemId(id)
    setIsModalOpen(true)
  }, [])

  return (
    <tr>
      {/* background image */}
      <td className="t-w-[400px]">
        <img className="t-w-[400px] t-h-[400px] t-object-contain" src={bgImageUrl} />
      </td>

      {/* sound */}
      <td className="t-w-[400px] text-center !t-align-middle">
        <audio className="t-w-full t-h-[30px]" controls>
          <source src={bgSoundUrl} />
          Your browser does not support the audio element.
        </audio>
      </td>

      {/* categories */}
      <td className="!t-px-[2rem] !t-py-[1.25rem] !t-align-middle">
        {collectionCategories.map(({ id: categoryId, name: categoryName }) => (
          <p key={`${id}-${categoryId}`} className="mb-0">{categoryName}</p>
        ))}
      </td>

      {/* actions */}
      <td className="text-center !t-align-middle">
        <BiEdit className="t-cursor-pointer" onClick={handleEdit} />
      </td>
    </tr>
  )
}

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData)