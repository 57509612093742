import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input } from 'reactstrap';

// components
import InputField from '../../../../components/Common/InputField';
import CollectionCategorySelectField from '../../../../components/award-admin/CollectionCategorySelectField';
import SelectField from '../../../../components/Common/SelectField';

// constants
import { COLLECTION_ITEM_STATUS_FULL_OPTIONS } from '../../../../constants/award-admin/collectionItemStatus';
import { COLLECTION_ITEM_RARITY_OPTIONS } from '../../../../constants/award-admin/collectionItemRarity';

// hooks
import useWatchForm from '../../../../hooks/useWatchForm';

// local
import CollectionItemDataTableCtrl from './CollectionItemDataTable.ctrl';

const Filters = () => {
  // -- ctrl --
  const {
    // handle change filters
    handleChangeFilters,
  } = CollectionItemDataTableCtrl.useContainer();

  // -- form state --
  const filtersForm = useForm({
    defaultValues: {
      keyword: '',
    },
  });
  const { register, handleSubmit } = filtersForm;
  useWatchForm(filtersForm, {
    onChange: (values) => {
      handleChangeFilters(values);
    },
    debounce: 200,
  });

  return (
    <FormProvider {...filtersForm}>
      <Form>
        <div className="t-flex">
          {/* keyword */}
          <FormGroup className="t-flex-1 mr-2">
            <Label htmlFor="keyword">Tìm kiếm</Label>
            <InputField
              type="text"
              name="keyword"
              id="keyword"
              placeholder="Tìm kiếm theo tên, mô tả vật phẩm..."
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </FormGroup>

          <div className="t-flex-1 t-flex ml-2">
            {/* collection category */}
            <FormGroup className="t-flex-1 mr-2">
              <Label htmlFor="collectionCategoryId">Thể loại</Label>
              <CollectionCategorySelectField
                name="collectionCategoryId"
                id="collectionCategoryId"
                selectProps={{ placeholder: 'Chọn thể loại', isClearable: true }}
              />
            </FormGroup>

            {/* status */}
            <FormGroup className="t-flex-1 mx-2">
              <Label htmlFor="status">Trạng thái</Label>
              <SelectField
                name="status"
                id="status"
                options={COLLECTION_ITEM_STATUS_FULL_OPTIONS}
                selectProps={{ placeholder: 'Chọn trạng thái', isClearable: true }}
              />
            </FormGroup>

            {/* rarity */}
            <FormGroup className="t-flex-1 ml-2">
              <Label htmlFor="rarities">Độ hiếm</Label>
              <SelectField
                name="rarities"
                id="rarities"
                options={COLLECTION_ITEM_RARITY_OPTIONS}
                selectProps={{ placeholder: 'Chọn độ hiếm', isClearable: true, isMulti: true }}
              />
            </FormGroup>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default Filters;
