export const LUCKY_BOX_RARITY = {
  ANY: 'any',
  NORMAL: 'normal',
  RARE: 'rare',
  EXTREMELY_RARE: 'extremely rare',
}

export const LUCKY_BOX_RARITY_NAME = {
  [LUCKY_BOX_RARITY.ANY]: 'Bất kỳ',
  [LUCKY_BOX_RARITY.NORMAL]: 'Thường',
  [LUCKY_BOX_RARITY.RARE]: 'Hiếm',
  [LUCKY_BOX_RARITY.EXTREMELY_RARE]: 'Cực hiếm',
}

export const LUCKY_BOX_RARITY_TOOLTIP = {
  [LUCKY_BOX_RARITY.ANY]: 'Hộp may mắn này có thể chứa vật phẩm có độ hiếm bất kỳ.',
  [LUCKY_BOX_RARITY.NORMAL]: 'Hộp may mắn này chỉ có thể chứa vật phẩm có độ hiếm thường (nhiều) và hiếm (ít).',
  [LUCKY_BOX_RARITY.RARE]: 'Hộp may mắn này chỉ có thể chứa vật phẩm có độ hiếm thường (ít), hiếm (nhiều) và cực hiếm (ít).',
  [LUCKY_BOX_RARITY.EXTREMELY_RARE]: 'Hộp may mắn này chỉ có thể chứa vật phẩm có độ hiếm hiếm (ít) và cực hiếm (nhiều).',
}