import React, { useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSlide } from '../../../../utils/hooks';
import { FlagEmpty, FlagFill } from '../Icons';
// import styles from './styles.scss';

export const FlagComponent = ({ flagId }) => {
  const { slide, updateSlide } = useSlide(flagId);

  const [openModal, setopenModal] = useState(false);
  const toggleModal = () => setopenModal(!openModal);

  if (!slide) return '';

  const { checkpoint } = slide;

  const getStar = () => {
    if (!checkpoint || !checkpoint.star) return 0;
    return checkpoint.star;
  };

  const isActiveFlag = getStar() > 0 && flagId === slide.id;

  return (
    <>
      <div aria-hidden="true" onClick={toggleModal} className={`item_slide_icon_right ${isActiveFlag ? 'icon_right_active' : ''}`}>
        {isActiveFlag ? <FlagFill /> : <FlagEmpty />}
      </div>
      <Modal isOpen={openModal} toggle={toggleModal}>
        <ModalHeader>
          <FlagEmpty /> Mốc bài học
        </ModalHeader>
        <ModalBody>
          <div style={{ height: '5px' }} />
          <div className="text-bold">Tiêu đề mốc bài học</div>
          <div style={{ height: '5px' }} />
          <input
            placeholder="Cô hãy nhập tiêu đề vào đây nhé..."
            value={checkpoint ? checkpoint.text : ''}
            onChange={(e) => updateSlide({ checkpoint: { ...checkpoint, text: e.target.value } })}
            className="input_star"
          />
          <div style={{ height: '5px' }} />

          <hr />

          <div className="fl" style={{ alignItems: 'center' }}>
            <div className="text-bold">Nếu bé học đến mốc này, cô sẽ tặng bé {getStar()} ngôi sao</div>
          </div>
          <Input
            style={{ width: '200px' }}
            type="range"
            min="0"
            max="3"
            step="1"
            list="star"
            value={getStar()}
            onChange={(e) => updateSlide({ checkpoint: { ...checkpoint, star: e.target.value } })}
          />
          <datalist id="star">
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </datalist>
        </ModalBody>
      </Modal>
    </>
  );
};
