import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconCaretLeft } from '../../icons/IconSvg';
import { SlidesActions } from '../../reducers/lesson';
import SelectCourse from './SelectCourse';
import SelectChapter from './SelectChapter';
import { fetchCourseAction } from '../../reducers/courses';
import { getCourses } from '../../apis/course';
import { changeCourse, changeChapter } from '../../reducers/questionBank';
import { getQuizzInTestBank } from '../../actions/ActionQuestionBank';
class ComponentSelect extends React.Component {
  state = {
    courseId: '',
    courses: [],
  };
  async componentDidMount() {
    this.props.updateLayoutOption('quiz');
    const datas = await getCourses();
    if (datas && datas.result && datas.result.courses) {
      this.setState({
        courses: datas.result.courses,
        courseId: datas.result.courses[0].id,
      });
      this.props.changeCourse(datas.result.courses[0]);
      this.props.getQuizzInTestBank({ courseId: datas.result.courses[0].id, chapterId: null });
      // this.props.changeChapter(datas.result.courses[0].chapterIds ? datas.result.courses[0].chapterIds[0].id : null);
    }
  }

  render() {
    const { courses, chapters } = this.state;
    return (
      <div>
        <Link style={{ marginTop: '-10px' }} className="fl text-dc div__back" to={`/course/${this.props.questionBank.course.id}`}>
          <IconCaretLeft />
          <div style={{ marginTop: '3px' }} className="title">
            Quay lại chủ đề
          </div>
        </Link>
        <SelectCourse courses={courses} />
        <SelectChapter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionBank: state.questionBank,
});
export default connect(mapStateToProps, {
  updateLayoutOption: SlidesActions.updateLayoutOption,
  fetchCourseAction,
  changeCourse,
  changeChapter,
  getQuizzInTestBank,
})(ComponentSelect);
