import React from "react";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import './style.scss'

const TablePagination = props => {
    const data = props.data
    const currentPage = props.currentPage
    const pageNumber = Math.ceil(data.length/10)
    const renderButton = []
    if(pageNumber <= 3) {
        for(let i=0; i<pageNumber; i++) {
            renderButton.push(<button className={i+1 === currentPage ? 'page_button_selected' : 'page_button'}
            onClick={() => props.setCurrentPage(i+1)}>{i+1}</button>)
        }
    }
    if(pageNumber > 3) {
        if(currentPage < pageNumber-2) {
            for(let i=currentPage-1; i<currentPage+1; i++) {
                renderButton.push(<button className={i+1 === currentPage ? 'page_button_selected' : 'page_button'}
                onClick={() => props.setCurrentPage(i+1)}>{i+1}</button>)
            }
            renderButton.push(<button className='more_button'>...</button>)
            renderButton.push(<button className={pageNumber === currentPage ? 'page_button_selected' : 'page_button'}
                onClick={() => props.setCurrentPage(pageNumber)}>{pageNumber}</button>)
        }
        else {
            for(let i=pageNumber-3; i<pageNumber; i++) {
                renderButton.push(<button className={i+1 === currentPage ? 'page_button_selected' : 'page_button'}
                onClick={() => props.setCurrentPage(i+1)}>{i+1}</button>)
            }
        }
    }

    return (
        <div className='table_pagination'>
            {currentPage !== 1 && (<button className='table_pagination__navButton' onClick={() => props.setCurrentPage(prev => prev-1)}>
                <MdChevronLeft size={16} color='#767676'/>
            </button>)}
            {renderButton}
            {currentPage !== pageNumber && (<button className='table_pagination__navButton' onClick={() => props.setCurrentPage(prev => prev+1)}>
                <MdChevronRight size={16} color='#767676'/>
            </button>)}
        </div>
    )
}

export default TablePagination