import React from 'react';
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { searchCourseAction, changeCourseSearch } from '../../../actions/ActionPublishManagement';
import { searchPathAction, changeSearch } from '../../../actions/ActionPublishPath';
import { IconSearch } from '../../../icons/IconSvg';
import { toLowerCaseNonAccentVietnamese } from '../../../utils/string';

export const Search = ({ filter }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const publishType = location.pathname.includes('path') ? 'path' : 'course'
  
  const onChange = (e) => {
    dispatch(publishType === 'path' ? changeSearch(e.target.value) : changeCourseSearch(e.target.value))
    const convertTitle = toLowerCaseNonAccentVietnamese(e.target.value.replace(/ /g, '-'));
    publishType === 'path' ? dispatch(searchPathAction(convertTitle, { status: filter.status.map(item => item.value), date: filter.date } )) : dispatch(searchCourseAction(convertTitle, { status: filter.status.map(item => item.value), date: filter.date }));
  };

  return (
      <div className="search">
        <input placeholder={publishType === 'path' ? "Tìm kiếm lộ trình học" : "Tìm kiếm khoá học"}
        type="text" className="input-search" onChange={onChange} />
        <div className="icon-search">
          <IconSearch />
        </div>
      </div>
  );
};
