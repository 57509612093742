export const formatPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(price)
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat('vi-VN',).format(number)
}

const getDecimalPart = (number) => {
  if (Number.isInteger(number)) {
    return 0
  }
  const decimalStr = number.toString().split('.')[1]
  return Number(decimalStr)
}

export const formatPercent = (number = '0', digits) => {
  if (digits) {
    return `${parseFloat(number).toFixed(digits)}%`
  }
  if (getDecimalPart(number).toString().length >= 3) {
    return `${parseFloat(number).toFixed(3)}%`
  }
  if (getDecimalPart(number).toString().length >= 2) {
    return `${parseFloat(number).toFixed(2)}%`
  }
  return number === 0 ? `0%` : `${parseFloat(number).toFixed(1)}%`
}