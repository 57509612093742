import React from 'react';
import { Button } from '@mui/material';
import './style.scss';

export const ButtonAction = ({ text, size = 'medium', className = '', onClick, id }) => {
  return (
    <Button
        id={id}
        className={`btn-action ${className}`}
        variant="contained"
        onClick={onClick}
        size={size}
    >
      {text}
    </Button>
  );
};
