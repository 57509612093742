import api from './api';

export const deleteTheory = async (theoryId) =>
  api({
    method: 'DELETE',
    url: `/theories/${theoryId}`,
  });

export const createTheory = async (theory) =>
  api({
    method: 'POST',
    url: `/theories/`,
    data: theory,
  });

export const updateTheory = async (theoryId, theory) =>
  api({
    method: 'PUT',
    url: `/theories/${theoryId}`,
    data: theory,
  });
