import React from 'react';
import { connect } from 'react-redux';
import GetMediaQuiz from './GetMediaQuiz';
import { formatQuizpartEnter } from '../../Quiz/quizUtils';

class ViewDragAndDrop extends React.Component {
  render() {
    const { learningCurrent, lesson } = this.props;
    const quizPartsWrong = learningCurrent.quizParts.filter(
      (part) => part.content && part.content.text && part.isHidden,
    );
    const quizpartsEnter = formatQuizpartEnter(learningCurrent.quizParts) || [];

    return (
      <div style={{ padding: '20px 0 10px 0' }} className="text-center">
        <div className="text-bold fs-25">{learningCurrent.question || ''}:</div>
        {quizpartsEnter.map((partEnter, idx) => {
          return (
            <div
              key={`e-${idx}`}
              style={{ padding: '20px 0 10px 0' }}
              className="fl_w wrapper_content div_wrapper_content_drag_drop"
            >
              {Object.values(partEnter).map((part, index) => {
                if (part.content && part.content.text && part.isHidden) {
                  return (
                    <div className="item_content">
                      <button type="button" className="div_drag_drop btn">
                        {' '}
                      </button>
                    </div>
                  );
                }
                return (
                  <div className="item_content" key={index}>
                    {part.content.text || ''}
                  </div>
                );
              })}
            </div>
          );
        })}

        <div className="fl wrapper_content div_wrapper_content_drag_drop">
          {quizPartsWrong.map((wrong, index) => (
            <div key={index} className="fl">
              <div className="item_content">
                <button style={{ color: '#08f', fontSize: '20px' }} type="button" className="div_drag_drop btn">
                  <GetMediaQuiz dataContent={wrong.content} />
                </button>
              </div>
              <div key={index} className="item_content">
                <button style={{ color: '#08f', fontSize: '20px' }} type="button" className="div_drag_drop btn">
                  <GetMediaQuiz dataContent={wrong.wrongAnswer} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewDragAndDrop);
