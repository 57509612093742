import React from 'react';
import { connect } from 'react-redux';
import GetMediaQuiz from './GetMediaQuiz';

class ViewMatchThePair extends React.Component {
  render() {
    const { learningCurrent } = this.props;

    return (
      <div style={{ padding: '20px 0' }} className="text-center view_match_the_pair_quiz">
        <div className="text-bold fs-25">{learningCurrent.question || ''}?</div>
        <div className="fl" style={{ justifyContent: 'center', marginTop: '50px' }}>
          <div className="m_content">
            {learningCurrent.quizParts.map((part, index) => (
              <div key={index} className="div_item_m_pair">
                <div className="fl item_m_pair">
                  <GetMediaQuiz dataContent={part.content} />
                </div>
              </div>
            ))}
          </div>
          <div className="pair_content">
            {learningCurrent.quizParts.map((part, index) => (
              <div key={index} className="div_item_m_pair">
                <div className="fl item_m_pair">
                  <GetMediaQuiz dataContent={part.pairContent} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewMatchThePair);
