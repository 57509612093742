import { schema } from 'normalizr';

const mediaObject = new schema.Entity('mediaObjects');

const customLayout = new schema.Entity('customLayouts');
const customLayouts = new schema.Array(customLayout);

customLayout.define({
  children: customLayouts,
  mediaObject,
});

const learningPoint = new schema.Entity('learningPoints', {
  customLayout,
});

const learningPoints = new schema.Array(learningPoint);

export { learningPoint, learningPoints, mediaObject, customLayout };
