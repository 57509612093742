import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import LessonLeft from '../../../../Lesson-v2/LessonLeft';
import LessonRight from '../../../../Lesson-v2/LessonRight';
import { findChapterIdOfLesson } from '../../../../../actions/ActionLesson';
import { getLessonVersionAction } from '../../../../../actions/ActionPublishManagement';
import './style.scss';
import Loading from '../../../../Common/Loading';

class LessonPreview extends React.Component {
  componentDidMount() {
    this.props.getLessonVersionAction(this.props.id);
    // this.props.findChapterIdOfLesson(this.props.id);
  }
  render() {
    const {currentSlide, welcome} = this.props.lesson
    return (
      <>
        {this.props.lesson.syncing && <Loading notText={true} />}
        <div className="container wrapper_lesson version-preview">
          <div className="wrapper_quiz">
            <div className="content">
              <div className="fl div__content">
                <LessonLeft versionPreview={true} isShowAction={false} />
                <LessonRight versionPreview={true} isShowAction={false} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.lesson,
});

export default connect(mapStateToProps, { getLessonVersionAction, findChapterIdOfLesson})(LessonPreview);
