import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import IconLogo from '../../icons/iconLogo.svg';
import { IconBell, IconComment, IconSetting } from '../../icons/IconSvg';
import { logoutAction } from '../../reducers/auth';
import AvatarUser from './UserAvartar';
import Photo from './Photo';
import { deleteCourseAction, updateCourseProfileAction } from '../../reducers/courses';
import ModalChangePassword from '../../containers/ChangePassword/ModalChangePassword';
import { updateCourse } from '../../apis/course';
import { updateStatusCourse } from '../../actions/ActionCourse';
import { saveFeeAndCouponAction } from '../../actions/ActionChapter';
import { ScopeCourse } from './scopeCourse/scopeCourse';
import { getFrameContentStatus, getSectionStatus } from '../../utils/publishManagement';
import { submitFrameContentAction } from '../../actions/ActionFrameContent';
import { useKeycloak } from '@react-keycloak/web';
import CircularProgress from '@mui/material/CircularProgress';

const Header = ({ type, name, link, courseName }) => {
  const auth = useSelector((state) => state.auth);
  const { course, syncing } = useSelector((state) => state.chapter);
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
  const toggleChangePassword = () => setChangePasswordOpen((prevState) => !prevState);
  const [modal, setModal] = useState(false);

  const logOutHandler = () => {
    dispatch(logoutAction());
    keycloak.logout();
    history.push('/');
  };

  const renderCurriculumSetting = () => {
    const dispatch = useDispatch();
    const { course, syncing } = useSelector((state) => state.chapter);
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        {/* <ModalHeader>Chi tiết khóa học</ModalHeader> */}
        <span
          style={{
            textAlign: 'center',
            margin: '15px 0 20px 0',
            fontSize: '24px',
          }}
        >
          Cài đặt khóa học
        </span>
        <Nav tabs style={{ margin: '0 20px 0 20px' }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
              style={{ cursor: 'pointer' }}
            >
              Thông tin khóa học
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
              style={{ cursor: 'pointer' }}
            >
              Phạm vi khóa học
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} style={{ margin: '0 10px 0 10px' }}>
          <TabPane tabId="1">
            {/* <ModalBody
            // style={{overflowY: 'scroll !important'}}
            scrollable = 'false'
          > */}
            <div className="course-infoSetting">
              <ApprovedCourse />
              <hr />
              <UpdateCurriculum />
              <hr />
              <DeleteCurriculum />
            </div>
            {/* </ModalBody> */}
          </TabPane>
          <TabPane tabId="2">
            <div className="course-infoSetting">
              <br />
              {/* <span style={{fontWeight: '600', color: '#2E2D2D'}}></span><br /> */}
              {course.scope && <ScopeCourse />}
              {!course.scope && <h4>Khóa học hiện tại được tạo trước khi cập nhật tính năng "scope"</h4>}
              <br />
              <button
                onClick={() => {
                  dispatch({ type: '@@chapters/SET_SYNCING', data: true });
                  dispatch(saveFeeAndCouponAction());
                  dispatch({ type: '@@chapters/SET_SYNCING', data: false });
                  // setModal(!modal);
                }}
                style={{
                  float: 'right',
                  background: '#6C757D',
                  border: '1px solid #C4C4C4',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  color: 'white',
                  width: '110px',
                  height: '40px',
                  margin: '20px 10px',
                }}
              >
                {syncing ? 'Đang xử lý' : 'Lưu'}
              </button>
            </div>
          </TabPane>
        </TabContent>
      </Modal>
    );
  };

  const onClickSubmit = (e) => {
    e.stopPropagation();
    dispatch(submitFrameContentAction(course.id));
  };

  return (
    <div className="ui-top_header">
      <div className="fl_b content-top_header">
        <div className="logo-header">
          {(() => {
            if (type && type === 'test') {
              return null;
            }
            if (type && type === 'detail') {
              return (
                <div className="fl">
                  <div
                    className="div_logo"
                    onClick={() => history.push('/course')}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  >
                    <img src="/logo.svg" alt="" />
                  </div>
                  <div style={{ paddingLeft: '10px' }}>
                    {/* <Link className="fs-15" to={link || '/course'}>
                      {courseName ? courseName : 'Xem tất cả khóa học'}
                    </Link> */}
                    <div className="text-bold fs-20 course-name" onClick={() => setModal(!modal)}>
                      {name || ''}
                      {/* <IconSetting onClick={() => setModal(!modal)} /> */}
                      <span className="submit-action" onClick={onClickSubmit}>
                        Nộp khung nội dung
                      </span>
                      {syncing && (
                        <CircularProgress
                          sx={{ width: '20px', height: '20px', marginLeft: '10px', marginTop: '5px' }}
                          size="medium"
                        />
                      )}
                      {renderCurriculumSetting()}
                    </div>
                    <div className="course-status">Trạng thái: {getFrameContentStatus(course.status)}</div>
                  </div>
                </div>
              );
            }
            return <img className="logo" src={IconLogo} alt="" />;
          })()}
        </div>

        <Nav tabs style={{ margin: '0 20px 0 20px' }}>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/template' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/template') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/template');
              }}
            >
              Template
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/category' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/category') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/category');
              }}
            >
              Thể loại
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/item' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/item') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/item');
              }}
            >
              Vật phẩm
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/lucky-box' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/lucky-box') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/lucky-box');
              }}
            >
              Hộp may mắn
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/system-collection' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/system-collection') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/system-collection');
              }}
            >
              Bộ sưu tập hệ thống
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                ...(window.location.pathname === '/award-cms/order' && {
                  fontWeight: '550',
                  textDecoration: 'underline',
                }),
              }}
              href="#"
              onClick={(e) => {
                if (window.location.pathname === '/award-cms/order') {
                  e.preventDefault();
                  return;
                }
                history.push('/award-cms/order');
              }}
            >
              Giao dịch
            </NavLink>
          </NavItem>
        </Nav>

        <div className="fl header-right">
          <div className="m-auto cursor">Đi đến hướng dẫn</div>
          <div className="m-auto icon-noti cursor">
            <IconBell />
          </div>
          <div className="m-auto cursor">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
              <DropdownToggle outline={false} color="#ffffff">
                <AvatarUser email={auth.data.email} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header>Chào, {auth.data.name}</DropdownItem>
                <DropdownItem>Xem hồ sơ</DropdownItem>
                <DropdownItem onClick={toggleChangePassword}>Đổi mật khẩu</DropdownItem>
                <DropdownItem onClick={logOutHandler}>Đăng xuất</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Về chúng tôi</DropdownItem>
                <DropdownItem>Báo cáo lỗi</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <ModalChangePassword isOpen={changePasswordOpen} toggle={toggleChangePassword} />
    </div>
  );
};

const ApprovedCourse = () => {
  const { course } = useSelector((state) => state.chapter);
  const { status } = course;
  const dispatch = useDispatch();
  return (
    <div>
      <span>Trạng thái: {status}</span>
      <br />
      <span
        style={{
          fontSize: '15px',
          color: 'blue',
          cursor: 'pointer',
        }}
        onClick={async () => {
          const newStatus = course.status === 'DRAFT' ? 'APPROVED' : 'DRAFT';
          await updateCourse(course.id, {
            title: course.title,
            description: course.description,
            imageUrl: course.imageUrl,
            chapterIds: course.chapterIds.map((tp) => tp.id),
            status: newStatus,
          });
          dispatch(updateStatusCourse(course.id, newStatus));
          dispatch({
            type: 'SET_COURSE_IN_CHAPTER',
            data: { ...course, status: newStatus },
          });
        }}
      >
        Kiểm duyệt
      </span>
    </div>
  );
};

const UpdateCurriculum = () => {
  const { course } = useSelector((state) => state.chapter);
  const { register, handleSubmit } = useForm({ defaultValues: course });
  const [previewUrl, setPreviewUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const updateCurriculum = async (data) => {
    const { title, description, thumbnail } = data;
    dispatch(updateCourseProfileAction(course.id, { title, newImageFile: thumbnail[0], description }));
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreviewUrl(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Form onSubmit={handleSubmit(updateCurriculum)}>
      <FormGroup>
        <Label htmlFor="Title">Tên khóa học</Label>
        <Input type="text" name="title" id="Title" placeholder="Tieng Viet 1" innerRef={register} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="Description">Miêu tả về khóa học</Label>
        <Input
          type="textarea"
          name="description"
          id="Description"
          placeholder="Mieu ta ve giao trinh nay..."
          innerRef={register}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="File">Ảnh thumbnail</Label>
        <Input type="file" name="thumbnail" id="File" innerRef={register} onChange={onSelectFile} />
        <Photo
          className="my-2"
          style={{ maxWidth: 400, maxHeight: 400, borderRadius: '5px' }}
          src={previewUrl || course.imageUrl}
        />
        <FormText color="muted">Thumbnail là ảnh đại diện thu nhỏ của khóa học</FormText>
      </FormGroup>
      <Button>Cập nhật khóa học</Button>
    </Form>
  );
};

const DeleteCurriculum = () => {
  const { course } = useSelector((state) => state.chapter);
  const [confirmDelete, setConfirmDelete] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = () => dispatch(deleteCourseAction(course, () => history.goBack(2)));

  return (
    <Form>
      <FormGroup>
        <Label className="text-danger text-bold" htmlFor="confirm">
          Xóa khóa học
        </Label>
        <FormText color="danger">
          Bằng việc xóa khóa học này bạn sẽ xóa hết tất cả nội dung bên trong của nó và có thể lãng phí nhiều công sức.
          Nhập lại tên khóa học để xác nhận việc xóa
        </FormText>
        <Input
          value={confirmDelete}
          onChange={(e) => setConfirmDelete(e.target.value)}
          placeholder={course.title}
          className="my-2"
        />
      </FormGroup>
      <Button onClick={handleDelete} disabled={confirmDelete !== course.title} color="danger">
        Xóa khóa học này
      </Button>
    </Form>
  );
};

export default Header;
