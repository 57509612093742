import React, { memo } from 'react';
import { Spinner, Table } from 'reactstrap';

// components
import Loading from '../../../../components/Common/Loading';
import Pagination from '../../../../components/Common/Pagination';

// local
import Filters from './Filters'
import Actions from './Actions'
import Header from './Header'
import RowData from './RowData'
import OrderDataTableCtrl from './OrderDataTable.ctrl';

const headers = [
  { id: 'createdAt', label: 'Thời gian tạo', allowSorting: true },
  { id: 'user', label: 'Người mua' },
  // { id: 'type', label: 'Loại giao dịch', allowSorting: true },
  { id: 'totalPrice', label: 'Tổng giá trị giao dịch', allowSorting: true },
  { id: 'products', label: 'Sản phẩm' },
  { id: 'actions', label: '' },
];

const OrderDataTable = memo(({ className }) => {
  // -- ctrl --
  const {
    // orders
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
  } = OrderDataTableCtrl.useContainer()

  return (
    <div className={className}>
      <div className="t-flex t-justify-start t-items-center">
        {/* filters */}
        <div className="t-w-[80%]">
          <Filters />
        </div>

        {/* actions */}
        <Actions className="ml-4 pt-3" />
      </div>

      {/* table */}
      <Table bordered responsive>
        {/* header */}
        <Header headers={headers} />

        {/* body */}
        <tbody>
          {/* loading */}
          {isLoading && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={5}><Spinner color="primary" /></td>
            </tr>
          )}

          {/* empty data */}
          {!isLoading && ids.length === 0 && (
            <tr>
              <td className="text-center t-py-[50px]" colSpan={5}>Không có dữ liệu</td>
            </tr>
          )}

          {/* data */}
          {!isLoading && ids.length > 0 && ids.map(id => (
            <RowData key={id} id={id} />
          ))}
        </tbody>
      </Table>

      {/* pagination */}
      <Pagination
        total={metadata.total}
        limit={tableFilters.limit}
        page={tableFilters.page}
        onPerPageChange={handleChangePerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
})

export default ({ ...props }) => (
  <OrderDataTableCtrl.Provider>
    <OrderDataTable {...props} />
  </OrderDataTableCtrl.Provider>
);