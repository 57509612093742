import React, { useCallback, useEffect, memo } from 'react'
import { Label, Input, FormText } from 'reactstrap'
import { useFormContext, useController } from 'react-hook-form'
import cls from 'classnames'

// components
import InputField from './InputField'

const InputSoundField = ({
  className,
  name = "sound",
  id = "sound",
  previewSoundUrlName = "previewSoundUrl",
  previewSoundUrl,
  setPreviewSoundUrl,
  previewSoundProps,
  ...props
}) => {
  // -- form state --
  const { control, errors } = useFormContext()

  // -- field state --
  const {
    field: { ref, onChange: defaultOnChange, value, ...field },
    meta,
  } = useController({ name, control })
  const { invalid } = meta

  // -- on custom change --
  const onCustomChange = useCallback((e) => {
    // cleanup current preview sound url
    URL.revokeObjectURL(previewSoundUrl)

    // set new object url to soundUrl
    setPreviewSoundUrl(URL.createObjectURL(e.target.files[0]))

    defaultOnChange(e.target.files)
  }, [defaultOnChange, previewSoundUrl, setPreviewSoundUrl])


  useEffect(() => {
    return () => {
      // cleanup preview sound url
      URL.revokeObjectURL(previewSoundUrl)
    }
  })

  return (
    <>
      <Label htmlFor={name} className="d-block btn btn-secondary t-w-[fit-content] mb-0">Chọn nhạc</Label>
      <Input
        className="d-none"
        type="file"
        name={name}
        id={id}
        accept="audio/*"
        {...field}
        onChange={onCustomChange}
        innerRef={ref}
        {...props}
      />

      {/* preview sound */}
      {previewSoundUrl && (
        <audio className="mt-3 t-w-[50%] t-h-[30px]" controls {...previewSoundProps}>
          <source src={previewSoundUrl} />
          Your browser does not support the audio element.
        </audio>
      )}

      <InputField
        className="d-none"
        name={previewSoundUrlName}
        id={previewSoundUrlName}
      />
    </>
  )
}

export default memo(InputSoundField)
