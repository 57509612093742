import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSectionStatus } from '../../../../utils/publishManagement';
import '../style.scss';

const getDate = (date) => {
  const dt = new Date(date);

  return `${(dt.getMonth() + 1).toString().padStart(2, '0')}/${dt
    .getDate()
    .toString()
    .padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt
    .getHours()
    .toString()
    .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`;
};

export default function WraperIconFunction({ inTestBank }) {
  const {
    syncing,
    localState: { id, lastModifiedBy, createdBy, updatedAt, version },
  } = useSelector((state) => state.lesson);

  const dispatch = useDispatch();

  return (
    <div className="wraper__icon">
      {/* {lastModifiedBy && `Cập nhật lần cuối bởi ${lastModifiedBy.name} lúc: `}
      {createdBy && !lastModifiedBy && `Tạo bởi ${createdBy.name} lúc: `}
      {!createdBy && !lastModifiedBy && `Cập nhật lần cuối: `}
      <span>{getDate(updatedAt)}</span>
      <br />
      {/* Trạng thái: <b>{getLessonStatus(status)}</b> (<SubmitButton />) */}
      Trạng thái: <b>{getSectionStatus(version ? version.status : '')}</b>
    </div>
  );
}
