const initState = {
  loading: false,
  id: null,
  course: {},
  chapters: [],
  sections: [],
  comments: [],
  originFrameContent: {},
  diff: {
    statusDiff: [],
    nameDiff: [],
  },
  versionFrameContent: {},
};

export const FrameContentAction = {
  LOADING: '@@frameContent/LOADING',
  GET_FRAME_CONTENT: '@@frameContent/GET_FRAME_CONTENT',
  UPDATE_FRAME_CONTENT: '@@frameContent/UPDATE_FRAME_CONTENT',
  // for comment
  ADD_COMMENT_COURSE: '@@frameContent/ADD_COMMENT_COURSE',
  ADD_COMMENT_CHAPTER: '@@frameContent/ADD_COMMENT_CHAPTER',
  ADD_COMMENT_LESSON: '@@frameContent/ADD_COMMENT_LESSON',
  ADD_COMMENT_TEST: '@@frameContent/ADD_COMMENT_TEST',
  // for update others
  UPDATE_FIELD_CHAPTER: '@@frameContent/UPDATE_FIELD_CHAPTER',
  UPDATE_FIELD_COURSE: '@@frameContent/UPDATE_FIELD_COURSE',
  UPDATE_FIELD_SECTION: '@@frameContent/UPDATE_FIELD_SECTION',
  UPDATE_ALL_CHAPTER_STATUS: '@@frameContent/UPDATE_ALL_CHAPTER_STATUS',
  //
  UPDATE_STATUS_DIFF: '@@frameContent/UPDATE_STATUS_DIFF',
  UPDATE_NAME_DIFF: '@@frameContent/UPDATE_NAME_DIFF',
  UPDATE_ORIGIN: '@@frameContent/UPDATE_ORIGIN',
  //
  GET_VERSION_FRAME_CONTENT: '@@frameContent/GET_VERSION_FRAME_CONTENT',
};

export const frameContent = (state = initState, action) => {
  switch (action.type) {
    case FrameContentAction.LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case FrameContentAction.GET_VERSION_FRAME_CONTENT:
      return {
        ...state,
        versionFrameContent: action.data,
      };
    case FrameContentAction.UPDATE_ORIGIN:
      return {
        ...state,
        originFrameContent: action.data,
      };
    case FrameContentAction.UPDATE_FRAME_CONTENT:
      return {
        ...state,
        ...action.data,
      };
    case FrameContentAction.ADD_COMMENT_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          comments: [...state.course.comments, action.data.comment],
        },
      };
    case FrameContentAction.ADD_COMMENT_CHAPTER:
      const newChapters = state.chapters.map((chapter) => {
        if (chapter.chapterId === Number(action.data.id)) {
          return {
            ...chapter,
            comments: [...chapter.comments, action.data.comment],
          };
        } else {
          return chapter;
        }
      });
      return {
        ...state,
        chapters: newChapters,
      };
    case FrameContentAction.ADD_COMMENT_LESSON:
      const sectionsLesson = state.sections.map((item) => {
        if (item?.lessonId?.id === Number(action.data.id)) {
          return {
            ...item,
            lessonId: {
              ...item.lessonId,
              comments: [...item.lessonId.comments, action.data.comment],
            },
          };
        } else {
          return item;
        }
      });
      // return section
      return {
        ...state,
        sections: sectionsLesson,
      };
    case FrameContentAction.ADD_COMMENT_TEST:
      const sectionsTest = state.sections.map((item) => {
        if (item?.testId?.id === Number(action.data.id)) {
          return {
            ...item,
            testId: {
              ...item.testId,
              comments: [...item.testId.comments, action.data.comment],
            },
          };
        } else {
          return item;
        }
      });
      // return section
      return {
        ...state,
        sections: sectionsTest,
      };
    case FrameContentAction.UPDATE_FIELD_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          [action.data.key]: action.data.value,
        },
      };
    case FrameContentAction.UPDATE_FIELD_CHAPTER:
      const tmp = state.chapters.map((chapter) => {
        if (chapter.chapterId === Number(action.data.id)) {
          return {
            ...chapter,
            [action.data.key]: action.data.value,
          };
        } else {
          return chapter;
        }
      });
      return {
        ...state,
        chapters: tmp,
      };
    case FrameContentAction.UPDATE_FIELD_SECTION:
        // get add comment into section
        const sections = state.sections.map((item) => {
          if (item.lessonId ? item.lessonId.id === action.data.id : item.testId.id === action.data.id) {
            return {
              ...item,
              ...(item.lessonId && { lessonId: {
                ...item.lessonId,
                [action.data.key === 'sectionName' ? 'title' : action.data.key]: action.data.value,
              }}),
              ...(item.testId && { testId: {
                ...item.testId,
                [action.data.key === 'sectionName' ? 'title' : action.data.key]: action.data.value,
              }})
            };
          } else {
            return item;
          }
        });
        // return section
        return {
          ...state,
          sections: sections
        };
    case FrameContentAction.UPDATE_STATUS_DIFF:
      return {
        ...state,
        diff: {
          ...state.diff,
          statusDiff: [...state.diff.statusDiff, action.data],
        },
      };
    case FrameContentAction.UPDATE_NAME_DIFF:
      const { nameDiff } = state.diff;
      let _nameDiff = nameDiff;
      const diffEleIndex = _nameDiff.findIndex((e) => e.id === action.data.id && e.type === action.data.type);
      if (diffEleIndex !== -1) _nameDiff[diffEleIndex] = { ...action.data };
      else _nameDiff = [..._nameDiff, action.data];
      return {
        ...state,
        diff: {
          ...state.diff,
          nameDiff: _nameDiff,
        },
      };
    default:
      return state;
  }
};
