import React from 'react';
import { connect } from 'react-redux';
import { IconAdd, IconDelete } from '../../../icons/IconSvg';
import ComponentOption from '../../Common/ComponentOption';
import '../quiz.scss';
import InputQuestion from '../common/InputQuestion';
import OptionText from '../../OptionQuiz/OptionText';
import { changeQuizPart, addQuizPart, removeQuizPart } from '../../../actions/ActionQuiz';
import OptionImage from '../../OptionQuiz/OptionImage';
import OptionVideo from '../../OptionQuiz/OptionVideo';
import { generateId } from '../../../utils/generate';

class MatchThePair extends React.Component {
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  render() {
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    const quizParts = learningCurrent.quizParts || [];

    return (
      <div className="ui__match_the__pair">
        <div style={{ marginBottom: '10px' }} className="text-bold">
          Tạo cặp
        </div>
        <div>
          <InputQuestion audioUrl={learningCurrent.questionAudioUrl || ''} />
        </div>
        {quizParts.length > 0
          ? quizParts.map((quizPart, index) => (
              <div
                key={index}
                className="fl"
                style={{ background: '#f3f3f3', padding: '20px', justifyContent: 'center' }}
              >
                <div className="">
                  <div className="fl_b">
                    <ComponentOption
                      type={quizPart.content.type || 'text'}
                      speak="square"
                      changeOption={(e) =>
                        this.props.changeQuizPart('content', { ...quizPart.content, type: e }, learningCurrent, index)
                      }
                    />
                  </div>
                  {(() => {
                    if (quizPart.content.type === 'image') {
                      return (
                        <OptionImage
                          url={quizPart.content.imageUrl || ''}
                          id={`quiz-img-${index}-${generateId()}`}
                          handleChangeImage={(value) =>
                            this.props.changeQuizPart(
                              'content',
                              { ...quizPart.content, file: value },
                              learningCurrent,
                              index,
                            )
                          }
                        />
                      );
                    }
                    if (quizPart.content.type === 'video') {
                      return (
                        <OptionVideo
                          url={quizPart.content.videoUrl || ''}
                          id={`quiz-video-${index}-${generateId()}`}
                          handleChangeVideo={(value) =>
                            this.props.changeQuizPart(
                              'content',
                              { ...quizPart.content, file: value },
                              learningCurrent,
                              index,
                            )
                          }
                        />
                      );
                    }
                    return (
                      <OptionText
                        audioUrl={quizPart.content.audioUrl}
                        idOpt={`mtpc-${index}`}
                        value={quizPart.content.text || ''}
                        handleChangeText={(value) =>
                          this.props.changeQuizPart(
                            'content',
                            { ...quizPart.content, text: value },
                            learningCurrent,
                            index,
                          )
                        }
                      />
                    );
                  })()}
                </div>
                <div style={{ fontSize: '50px', margin: 'auto 20px' }} className="text-bold">
                  =
                </div>
                {quizPart.pairContent ? (
                  <div className="">
                    <div className="fl_b">
                      <ComponentOption
                        type={quizPart.pairContent ? quizPart.pairContent.type : ''}
                        speak="square"
                        changeOption={(e) =>
                          this.props.changeQuizPart(
                            'pairContent',
                            { ...quizPart.pairContent, type: e },
                            learningCurrent,
                            index,
                          )
                        }
                      />
                    </div>
                    {(() => {
                      if (quizPart.pairContent.type === 'image') {
                        return (
                          <OptionImage
                            url={quizPart.pairContent.imageUrl || ''}
                            id={`quiz-cp-img-${index}-${generateId()}`}
                            handleChangeImage={(value) =>
                              this.props.changeQuizPart(
                                'pairContent',
                                { ...quizPart.pairContent, file: value },
                                learningCurrent,
                                index,
                              )
                            }
                          />
                        );
                      }
                      if (quizPart.pairContent.type === 'video') {
                        return (
                          <OptionVideo
                            url={quizPart.pairContent.videoUrl || ''}
                            id={`quiz-cp-video-${index}-${generateId()}`}
                            handleChangeVideo={(value) =>
                              this.props.changeQuizPart(
                                'pairContent',
                                { ...quizPart.pairContent, file: value },
                                learningCurrent,
                                index,
                              )
                            }
                          />
                        );
                      }
                      return (
                        <OptionText
                          audioUrl={quizPart.pairContent.audioUrl}
                          idOpt={`mtpp-${index}`}
                          value={quizPart.pairContent.text || ''}
                          handleChangeText={(value) =>
                            this.props.changeQuizPart(
                              'pairContent',
                              { ...quizPart.pairContent, text: value },
                              learningCurrent,
                              index,
                            )
                          }
                        />
                      );
                    })()}
                  </div>
                ) : null}
                {quizParts.length > 1 ? (
                  <div
                    onClick={() => this.props.removeQuizPart(learningCurrent, index)}
                    style={{ margin: 'auto 20px' }}
                    className="cursor"
                  >
                    <IconDelete />
                  </div>
                ) : null}
              </div>
            ))
          : null}

        <div className="div__btn_add m-20">
          <button
            onClick={() => this.props.addQuizPart('quiz', currentSlide, 'pair_quiz')}
            className="btn btn_add "
            type="button"
          >
            <div>
              <IconAdd />
            </div>
            <div className="fs-20 text">Thêm cặp mới</div>
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuizPart, addQuizPart, removeQuizPart })(MatchThePair);
