import api from './api';

export async function registerAccount({ email, password, username, groups }) {
  const res = await api({
    method: 'POST',
    url: '/auths/keycloak/account',
    data: { email, password, username, groups },
  });
  return res;
}

export async function getListAccount() {
    const res = await api({
      method: 'GET',
      url: '/auths/keycloak/accounts',
    });
    return res;
}

export async function deleteAccount(id) {
  const res = await api({
    method: 'DELETE',
    url: `/auths/keycloak/account/${id}`,
  });
  return res;
}