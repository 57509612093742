import React from 'react';
import { NotPermissionSVG } from '../../icons/IconSvg';
import './style.scss';

export const NotPermission = () => {

    return (
        <div className="not-permission">
            <div className="not-permission-image">
                <NotPermissionSVG />
            </div>
            <div className="not-permission-text"> 
                Bạn không có quyền truy cập vào trang này
            </div>
        </div>
    );
}