import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
// import './style.scss';

import { IconPlayArrow } from '../../../icons/IconSvg';

import backgroundMap from '../icons/Background map/test.svg'
import VoiceSpeaker from '../UITemplate/Overview/common/voiceSpeaker';
import PopoverCustom from '../../Popover/PopoverCustom';

export const Welcome= (props) => {
    // const speakerArray = [
    //     {
    //       speaker: 'ngochuyen',
    //       text: 'Nữ - Hà Nội - Ngọc Huyền'
    //     }, 
    //     {
    //       speaker: 'huonggiang',
    //       text: 'Nữ - Sài Gòn - Hương Giang'
    //     }
    //   ];

    // const {localState} = useSelector(state => state.lesson);
    // const {overview} = localState;
    // const {content, welcome} = overview;
    // const [popoverOpen, setPopoverOpen] = useState(false)
    // const [localSpeaker, setLocalSpeaker] = useState();

   return (
       <div className="welcomeScreen">
            {/* {props.preview !== 'welcome' && <div className="welcomeScreen__speaker__content" style={{maxWidth: '300px', minHeight: '27px', color: 'black'}}>
                    <div
                        id="popover-speaker-welcome-screen"
                        onClick={() => setPopoverOpen(!popoverOpen)}
                        style={{ cursor: 'pointer' }}
                    >
                        <IconPlayArrow />
                        <PopoverCustom
                            width='260px'
                            maxHeight='120px'
                            popoverOpen={popoverOpen} 
                            id="popover-speaker-welcome-screen"
                            toggle={() => setPopoverOpen(!popoverOpen)}
                        >
                            {speakerArray.map((element, index) => {
                                return (
                                <div
                                    aria-hidden='true'
                                    key={index}
                                    className="div_item"
                                    onClick={() => {
                                        setLocalSpeaker(element);
                                    }}
                                >
                                    {element.text}
                                </div>
                                );
                            })}
                        </PopoverCustom>
                    </div>
                    <div style={{margin: '1px 6px 1px 3px'}}>
                        {localSpeaker ? localSpeaker.text : 'Chọn giọng đọc'}
                    </div>
                        {
                            localSpeaker && content.text && welcome.text &&
                            <VoiceSpeaker 
                                localSpeaker={localSpeaker.speaker}
                                text={content.text.concat(welcome.text)}
                                type='welcomeScreen'
                            />
                        }
                </div>} */}
           <div className="welcomeScreen__preview" 
                style={{
                    fontSize: props.preview ? '15px' : '30px', 
                    textAlign: 'center',
                    color: 'black',
                    height: '100px'
                }}
            >
                {/* <img src={backgroundMap} alt='background-map'/> */}
                Tính năng đang được phát triển 
                <br />
                { props.preview !== 'welcome'  && "(Click vào biểu tượng dấu '+' để tạo slide mới)  " }
                
           </div>
       </div>
   );
};

export const WelcomeHeader = () => {

    return (
        <div className="welcome-header">
            Welcome screen
        </div>
    );
}