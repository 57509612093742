import api from './api';

export const createCheckpoint = async (checkpoint) => 
    api({
        method: 'POST',
        url: `/checkpoints`,
        data: checkpoint
    });

export const updateCheckpoint = async (id, checkpoint) => 
    api({
        method: 'PUT',
        url: `/checkpoints/${id}`,
        data: checkpoint
    });

export const deleteCheckpoint = async (id) => {
    return api({
        method: 'DELETE',
        url: `/checkpoints/${id}`,
        data: {}
    });
}