import React, { memo, useCallback } from 'react';
import cls from 'classnames';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

// utils
import { toggleSortBy } from '../../../../components/Common/Table/utils';

// local
import OrderDataTableCtrl from './OrderDataTable.ctrl';

const Header = ({ headers }) => {
  // -- ctrl --
  const {
    // table filters
    tableFilters,
    // handle change sort state
    handleChangeSortState,
  } = OrderDataTableCtrl.useContainer();

  const onSort = useCallback(
    (headerId) => {
      const curSortState = tableFilters.sort?.[headerId];
      const nextSortState = toggleSortBy(curSortState);
      handleChangeSortState({ sort: { [headerId]: nextSortState } });
      // handleChangeSortState({ sort: { ...tableFilters.sort, [headerId]: nextSortState } }); // multi sort
    },
    [tableFilters],
  );

  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th
            key={header.id}
            className={cls('text-center !t-align-middle', { 't-cursor-pointer': header.allowSorting })}
            style={header.style}
            onClick={() => {
              if (header.allowSorting) onSort(header.id);
            }}
          >
            {/* header label */}
            {header.label}

            {/* sort state icon */}
            {header.allowSorting && (
              <span className="ml-2">
                {tableFilters.sort?.[header.id] !== undefined ? (
                  tableFilters.sort[header.id] === -1 ? (
                    <FaSortDown size={16} />
                  ) : (
                    <FaSortUp className="t-align-baseline" size={16} />
                  )
                ) : (
                  <FaSort size={16} />
                )}
              </span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default memo(Header);
