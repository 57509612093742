import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { saveUpdateLesson } from '../../actions/ActionLesson';
import { getDirectoryUrlFromFile2, getFullUrl, imageCompress } from '../../utils/imageProcessing';
import './style.scss';
import { ChromePicker } from 'react-color';

class StyleSlide extends React.Component {
  constructor(props) {
    super(props);

    const { learningPoints, currentSlide } = props;
    const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};
    const { backgroundUrl, backgroundOpacity } = learningCurrent;

    this.state = {
      isOpen: false,
      bgImg: null,
      backgroundUrl: backgroundUrl ? backgroundUrl : '',
      opacity: backgroundOpacity ? backgroundOpacity : 1.0,
      textBackgroundColor: '#ffffff',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSlide != this.props.currentSlide) {
      const { learningPoints, currentSlide } = this.props;
      const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};
      const { backgroundUrl, backgroundOpacity } = learningCurrent;
      this.setState({
        backgroundUrl: backgroundUrl ? backgroundUrl : '',
        opacity: backgroundOpacity ? backgroundOpacity : 1.0,
      });
    }
  }

  async readImg(event, type) {
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      const compressedFile = await imageCompress(event.target.files[0]);
      this.setState({ bgImg: compressedFile });
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChangeLP = (data) => {
    const newLPs = this.props.learningPoints.map((lp) => {
      if (lp.id === this.props.currentSlide) {
        return { ...lp, ...data };
      }
      return lp;
    });
    this.props.dispatch({ type: 'CHANGE_LEARNING_POINTS', learningPoints: newLPs });
  };

  handleSubmit = async () => {
    var backgroundUrl;
    if (this.state.bgImg) {
      backgroundUrl = await getDirectoryUrlFromFile2(this.state.bgImg);
    }
    this.handleChangeLP({
      backgroundOpacity: this.state.opacity,
      backgroundUrl,
      textBackgroundColor: this.state.textBackgroundColor,
    });
    this.props.dispatch(saveUpdateLesson());
    this.toggle();
  };

  render() {
    const { isOpen, isOpenBackground, opacity, backgroundUrl } = this.state;
    const { learningPoints, currentSlide, lesson, localState } = this.props;
    const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};

    return (
      <div style={{marginLeft: '5px'}}>
        <button type="button" onClick={this.toggle} className="btn btn-sm btn-info btn-slide-style" >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22 16V4C22 2.9 21.1 2 20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16ZM11 12L13.03 14.71L16 11L20 16H8L11 12ZM2 6V20C2 21.1 2.9 22 4 22H18V20H4V6H2Z"
              fill="white"
            />
          </svg>{' '}
          Giao diện Slide
        </button>

        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Giao diện Slide</ModalHeader>
          <ModalBody>
            <FormGroup>
              <div htmlFor="File">Ảnh nền của bài học</div>

              <button
                style={{ border: '1px solid #ced4da' }}
                className="btn"
                onClick={() => {
                  if (this.dropzoneRefAvatar) {
                    this.dropzoneRefAvatar.click();
                  }
                }}
              >
                Tải ảnh nền từ máy tính
                <input
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={(e) => this.readImg(e, 'bgImg')}
                  ref={(ref) => {
                    this.dropzoneRefAvatar = ref;
                  }}
                />
              </button>
            </FormGroup>
            <div>Độ mờ của ảnh nền</div>
            <Input
              style={{ width: '200px' }}
              type="range"
              min="0"
              max="1"
              step="0.1"
              list="level"
              value={opacity}
              onChange={(e) => this.setState({ opacity: e.target.value })}
            />
            <datalist id="level">
              <option>0.0</option>
              <option>0.1</option>
              <option>0.2</option>
              <option>0.3</option>
              <option>0.4</option>
              <option>0.5</option>
              <option>0.6</option>
              <option>0.7</option>
              <option>0.8</option>
              <option>0.9</option>
              <option>1.0</option>
            </datalist>
            <div className="wapper__img_modal">
              <img style={{ opacity }} src={getFullUrl(backgroundUrl)} id="bgImg" />
              {!backgroundUrl && 'Slide này chưa có ảnh nền'}
            </div>
            <br />
            <p>
              <b>Màu sắc:</b>
            </p>

            <label for="head">
              {' '}
              Màu nền của hộp text{' '}
              <div
                style={{
                  width: '150px',
                  height: '100px',
                  backgroundColor: this.state.textBackgroundColor,
                  border: '1px solid black',
                  borderRadius: '5px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <b>Chữ cái mẫu</b>
              </div>
            </label>
            <ChromePicker
              color={this.state.textBackgroundColor}
              onChange={(color) => {
                const textBackgroundColor = color.hex + Math.round(color.rgb.a * 255).toString(16);
                this.setState({ textBackgroundColor });
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit}>
              Lưu cấu hình
            </Button>{' '}
            <Button color="secondary" onClick={this.toggle}>
              Quay lại
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
  localState: state.lesson.localState || {},
});

export default connect(mapStateToProps)(StyleSlide);
