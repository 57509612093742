import Compressor from 'compressorjs';
import { toast } from 'react-toastify';
import { uploadFile, deleteFile } from '../apis/upload';

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const imageCompress = (file, quality = 0.6) =>
  new Promise((resolve, reject) => {
    // new Compressor(file, {
    //   quality,
    //   convertSize: 1000000, // 1mb
    //   checkOrientation: false,
    //   success: (blob) => resolve(blob), // Promise succeeded
    //   error: (err) => reject(err), // abort the Promise
    // }); // Compressor.js
    // if (file.size > 1000000) {
    //   toast.error('Lỗi: Ảnh lớn quá, hãy tải file nhỏ hơn 1 Mb bạn nhé');
    //   reject();
    // } else {
    resolve(file);
    // }
  });

export const getDirectoryUrlFromFile = (blob) =>
  new Promise((resolve, reject) => {
    let file;
    if (blob.config && blob.config.url) {
      const name = blob.config.url.split('/').pop();
      file = new File([blob.data], name, {
        lastModified: blob.headers['last-modified'],
        type: blob.headers['content-type'],
      });
    } else {
      file = new File([blob], blob.name, {
        lastModified: blob.lastModifiedDate,
        type: blob.type,
      });
    }
    const formData = new FormData();
    formData.append('file', file);
    uploadFile(formData).then((uploadRespond) => {
      if (typeof file !== 'object') {
        reject(Error('Not a file'));
        return '';
      }
      if (!uploadRespond || !uploadRespond.result) {
        reject(Error(uploadRespond.message || 'Sorry, upload file false'));
        return '';
      } else {
        // resolve(`{api_url}/files/${uploadRespond.result.filename}`);
        // resolve(`${uploadRespond.result}`);
        resolve(`${uploadRespond.data.fileAccessLink}`);
      }
    });
  });

export const getDirectoryUrlFromFile2 = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await uploadFile(formData);

  if (!response.result) {
    toast.error(`Lỗi: ${JSON.stringify(response.message)}`);
    return;
  }

  return response.data.fileAccessLink;
};

export const getFullUrl = (directoryUrl) => {
  return directoryUrl ? directoryUrl.replace('{api_url}', process.env.REACT_APP_API_DOMAIN) : '';
};

export const removeFileFromDB = async (imageUrl) => {
  if (!imageUrl) throw Error('No imageUrl found');
  return deleteFile(imageUrl.split('/')[imageUrl.split('/').length - 1]);
};
