import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { IconBottom, IconTop } from './IconApp';
import './style.scss';
import ViewQuiz from './ViewQuiz';
import ViewTop from './ViewTop';
class ModalPreviewApp extends React.Component {
  state = {
    isOpen: false,
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    const { isOpen } = this.state;
    const { learningPoints, currentSlide, lesson } = this.props;
    const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};

    return (
      <div style={{marginLeft: '5px'}}>
        <button type="button" onClick={this.toggle} className="btn btn-sm btn-dark btn-app-view">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{height: '30px'}}>
            <path
              d="M3 6H21V4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H7V18H3V6ZM13 12H9V13.78C8.39 14.33 8 15.11 8 16C8 16.89 8.39 17.67 9 18.22V20H13V18.22C13.61 17.67 14 16.88 14 16C14 15.12 13.61 14.33 13 13.78V12ZM11 17.5C10.17 17.5 9.5 16.83 9.5 16C9.5 15.17 10.17 14.5 11 14.5C11.83 14.5 12.5 15.17 12.5 16C12.5 16.83 11.83 17.5 11 17.5ZM22 8H16C15.5 8 15 8.5 15 9V19C15 19.5 15.5 20 16 20H22C22.5 20 23 19.5 23 19V9C23 8.5 22.5 8 22 8ZM21 18H17V10H21V18Z"
              fill="white"
            />
          </svg>{' '}
          IPad
        </button>
        <Modal className="modal__preview_app" isOpen={isOpen} toggle={this.toggle}>
          <div>
            <div className="icon_top">
              <IconTop />
            </div>
            <div>
              <ViewTop lesson={lesson.localState} />
              <div>
                <ViewQuiz learningCurrent={learningCurrent} lesson={lesson} />
              </div>
            </div>
            <div className="icon_bottom">
              <IconBottom />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {})(ModalPreviewApp);
