import { toast } from 'react-toastify';
import { updateOverview } from '../apis/overview';
import { getSoundUrl } from '../apis/voice';
import { handleAudioUrl } from '../utils/downloadAudio';
import { getDirectoryUrlFromFile2 } from '../utils/imageProcessing';

export const OverviewActions = (data) => async (dispatch, getState) => {
  dispatch({
    type: 'CHANGE_OVERVIEW',
    data,
  });
};

export const saveOverview = (data) => async (dispatch, getState) => {
  dispatch({
    type: '@@lesson/SYNCING',
    value: true,
  });
  let contentAudio = '';
  let welcomeAudio = '';
  if (data.content.text && data.content.audioUrl === '') {
    await getSoundUrl('ngochuyen', data.content.text)
      .then((res) => (contentAudio = res.data.wav_path))
      .catch((e) => '');
  }
  if (data.welcome.text && data.welcome.audioUrl === '') {
    await getSoundUrl('ngochuyen', data.welcome.text)
      .then((res) => (welcomeAudio = res.data.wav_path))
      .catch((e) => '');
  }
  const newContentSoundUrl = await handleAudioUrl(data.content.audioUrl || contentAudio);
  const newWelcomeSoundUrl = await handleAudioUrl(data.welcome.audioUrl || welcomeAudio);
  let defaultBackgroundUrl =
    'https://res.cloudinary.com/hoangdo/image/upload/v1631760840/background_welcome_y5mohs.png';
  if (data.background !== defaultBackgroundUrl) {
    defaultBackgroundUrl = await getDirectoryUrlFromFile2(data.background);
  }
  const res = await updateOverview({
    ...data,
    content: {
      ...data.content,
      speaker: data.content.speaker || 'ngochuyen',
      audioUrl: newContentSoundUrl,
    },
    welcome: {
      ...data.welcome,
      speaker: data.welcome.speaker || 'ngochuyen',
      audioUrl: newWelcomeSoundUrl,
    },
    background: defaultBackgroundUrl,
  });
  dispatch({
    type: '@@lesson/SYNCING',
    value: false,
  });
  if (res && res.status === 1) {
    toast.success('Cập nhật overview thành công');
  } else {
    toast.error('Có lỗi xảy ra');
  }
};
