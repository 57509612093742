import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from '../../components/Common/Loading';
import Header from '../../components/Common/Header';
import { IconChapter, IconAdd } from '../../icons/IconSvg';
import Photo from '../../components/Common/Photo';
import moment from 'moment';
import { fetchTestAction } from "../../reducers/test";
import { useDispatch, useSelector } from "react-redux";
import './style.scss'

const TestPage = () => {
    const history = useHistory()
    const tests = useSelector((state) => state.test);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTestAction());
    }, []);

    return (
        <>
        {tests.syncing && <Loading />}
        <Header/>
        <div className='test-wrapper'>
            <div className='header'>
                <h2>Các bài kiểm tra của bạn</h2>
                <button>Duyệt bài kiểm tra</button>
            </div>
            <div className='test-list'>
                <div className='create-test' onClick={() => history.push('/test/create')}>
                    <IconAdd/>
                    <h2>Tạo bài kiểm tra mới</h2>
                </div>
                {tests.data.map((data) => (
                <div className='test-item' key={data.id} onClick={() => {
                    history.push(`/test/${data.id}`)
                }}>
                    <div className='test-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='test-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='test-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
            </div>
        </div>
        </>
    )
}

export default TestPage