import { useCallback } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createContainer } from 'unstated-next'

// reducers
import { getAllCollectionCategories } from '../../../../reducers/award-admin/collectionCategory';

const useSystemCollectionModalCtrl = () => {
  // -- form state --
  const { register, handleSubmit, errors } = useForm();

  // -- dispatch --
  const dispatch = useDispatch();

  // -- submit handler --
  const submitHandler = useCallback(({ name, description, image }) => {
    // dispatch(createCourseAction({ name, description, file: thumbnail[0], type }));
    toggle();
  }, []);

  return {
    // form state
    register,
    handleSubmit,
    errors,
    // dispatch
    dispatch,
    // submit handler
    submitHandler,
  }
}

export default createContainer(useSystemCollectionModalCtrl)
