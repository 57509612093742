import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inReviewLessonAction, updateCompareLp } from '../../../actions/ActionPublishManagement';
import Loading from '../../../components/Common/Loading';
import { LearningPointView } from '../../../components/PublishManagement/Lesson/compareLearningPoint/compareLp';
import { LessonListAction } from '../../../components/PublishManagement/Lesson/ListAction/lessonListAction';
import LessonLeft from '../../../components/Lesson-v2/LessonLeft';
import LessonRight from '../../../components/Lesson-v2/LessonRight';
import { IconClose } from '../../../icons/IconSvg';
import { getLesson } from '../../../reducers/lesson';
import { Button } from '@mui/material';
import '../style.scss';
import { ButtonAction } from '../../../components/Common/Button/ButtonAction';
import { CompareLp } from '../../../components/PublishManagement/Lesson/compareLearningPoint';
import { CommentLesson } from '../../../components/PublishManagement/Lesson/Comment/commentLesson';

export const AdminLesson = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(inReviewLessonAction(props.match.params.id));
    // dispatch(getLesson(props.match.params.id));
  }, []);
  const { syncing, localState } = useSelector((state) => state.lesson);
  const { learningPoints } = localState;
  const { isOpen, data } = useSelector((state) => state.publishManagement.compareLearningPoint);

  return (
    <>
      {syncing && <Loading />}
      <div className="admin-lesson">
        <LessonListAction data={localState} />
        <div className="lesson-content">
          <LessonLeft isVersionAction={true} />
          <LessonRight isPublish={true} />
        </div>
        {isOpen && (<CompareLp learningPoint={data} />)}
      </div>
    </>
  );
};
