import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import IconLogo from '../../icons/iconLogo.svg';

export default function LandingPage() {
  return (
    <div className="landingPage">
      <Link to="/home">Đăng nhập cho giáo viên</Link>
      <Link to="/award-cms" className="mt-1 text-center">Đăng nhập cho quản trị viên <br /> hệ thống phần thưởng</Link>
      <img className="mt-1" alt="icon logo" src={IconLogo} />
      <h1>Học Tiếng Việt thật đơn giản!</h1>
      <div className="landingPage_spacer" />
      <h1><a href="https://learning.vietstudy.online">ĐẾN TRANG HỌC NGAY 👉</a></h1>
      <div className="landingPage_spacer" />
      <h3>
        👇 BẤM VÀO ĐỂ <span style={{ color: 'coral' }}>TẢI VỀ MIỄN PHÍ</span> 👇
      </h3>{' '}
      <br />
      <div className="landingPage_btns">
        <a href="https://apps.apple.com/vn/app/h%E1%BB%8Dc-ti%E1%BA%BFng-vi%E1%BB%87t-vietstudy/id1512544266">
          <img alt="appstore_download" src="/components/appstore_download.png" />
        </a>

        <div className="landingPage_width_spacer" />
        <a href="https://play.google.com/store/apps/details?id=online.vietstudy">
          <img alt="ggplay_download" src="/components/ggplay_download.png" />
        </a>
      </div>
      <div className="landingPage_spacer" />
      <h2>Ứng dụng dạy học tiếng Việt cho trẻ em từ 5-9 tuổi!</h2>
      <div className="landingPage_feature">
        <img alt="round+left" src="/components/round_left.png" />
        <div className="landingPage_width_spacer" />
        <div>
          <h3>Phương pháp học mới lạ, tương tác với bé. Chưa từng có trên SGK!</h3>
          <p>Phương pháp học tiếng Việt theo ngữ âm học - một phương pháp khoa học, dễ tiếp cận</p>

          <h3>Ôn luyện/Đánh giá quá trình học KĨ CÀNG mà vẫn ĐẢM BẢO vừa HỌC vừa CHƠI!</h3>
          <p>Thông qua hệ thống các bài tập, bài kiểm tra và bài thi được tạo nên bởi đội ngũ chuyên nghiệp nhất!</p>

          <h3>Chúng tôi sẽ dạy cho các bé học chữ</h3>
          <ul>
            <li>Nếu bé mới chỉ vào mẫu giáo, chưa được dạy chữ.</li>
            <li>Nếu bé không được học tiếng Việt tại trường học (ở nước ngoài).</li>
            <li>Nếu bé có thể nghe hiểu tiếng Việt từ bố mẹ nhưng không nói/viết được tiếng Việt.</li>
          </ul>
        </div>
      </div>
      <div className="landingPage_cta">
        <h2>
          👇 Còn chần chờ gì nữa, hãy bấm vào nút <span style={{ color: 'coral' }}>TẢI VỀ</span> và dùng thử ngay hôm
          nay 👇
        </h2>
        <br />
        <div className="landingPage_btns">
          <a href="https://apps.apple.com/vn/app/h%E1%BB%8Dc-ti%E1%BA%BFng-vi%E1%BB%87t-vietstudy/id1512544266">
            <img alt="appstore_download" src="/components/appstore_download.png" />
          </a>

          <div className="landingPage_width_spacer" />
          <a href="https://play.google.com/store/apps/details?id=online.vietstudy">
            <img alt="ggplay_download" src="/components/ggplay_download.png" />
          </a>
        </div>
      </div>
      <div className="landingPage_footer">
        <img alt="left_footer" src="/components/left_footer.png" />
        <div className="landingPage_width_spacer" />
        <div>
          <img alt="icon logo" src={IconLogo} />
          <p>
            Dự án này thuộc quyền quản lý của cô Trang. <br />
            Viện công nghệ thông tin và truyền thông, trường ĐH Bách Khoa Hà Nội. <br />
            Mọi thông tin liên lạc email: trangnt@soict.hust.edu.vn
          </p>
          <a href="/">Bản quyền thuộc về Vietstudy &copy; 2021</a>
        </div>
      </div>
    </div>
  );
}
