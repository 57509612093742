import api from './api';

const formartObjQuiz = (quiz) => {
  let objCommon = {
    question: quiz.question,
    title: quiz.title,
    type: quiz.type.split(':')[1],
    quizParts: quiz.quizParts,
    hint: quiz.hint || '',
    questionAudioUrl: quiz.questionAudioUrl,
    questionImageUrl: quiz.questionImageUrl,
    questionVideoUrl: quiz.questionVideoUrl,
    rateVoice: quiz.rateVoice || 1.0,
    courseId: quiz.courseId,
    chapterId: quiz.chapterId,
    inTestBank: quiz.inTestBank ? quiz.inTestBank : false,
    score: quiz.score || 0,
    backgroundUrl: quiz.backgroundUrl,
  };
  if (quiz.type.split(':')[1] === 'square_quiz') {
    objCommon = {
      ...objCommon,
      squareQuizType: quiz.squareQuizType,
    };
  } else {
    delete objCommon.squareQuizParts;
  }
  return objCommon;
};
export const getQuizId = async (quizId) =>
  api({
    method: 'GET',
    url: `/quizzes/${quizId}`,
  });

export const createQuiz = async (quiz) => {
  const objectData = formartObjQuiz(quiz);
  return api({
    method: 'POST',
    url: `/quizzes`,
    data: objectData,
  }).then((res) => res);
};

export const updateQuiz = async (quiz) => {
  const objectData = formartObjQuiz({ ...quiz, id: quiz.id });
  return api({
    method: 'PUT',
    url: `/quizzes/${quiz.id}`,
    data: objectData,
  });
};
export const deleteQuiz = async (quizId) =>
  api({
    method: 'DELETE',
    url: `/quizzes/${quizId}`,
  });
