import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'reactstrap';
import { IconPauseCircle, IconPlayCircle } from '../../../../../icons/IconSvg';
import { getSoundUrl } from '../../../../../apis/voice';
import { useDispatch, useSelector } from 'react-redux';
import { OverviewActions } from '../../../../../actions/Overview';

import { FormGroup, Label, Input } from 'reactstrap';
import { imageCompress } from '../../../../../utils/imageProcessing';
import './style.scss'

export const UploadImage = (props) => {
    const dispatch = useDispatch();
    const { localState, syncing } = useSelector((state) => state.lesson);
    const { overview } = localState;
    const {background} = overview;

    const defaultUrl = `https://res.cloudinary.com/hoangdo/image/upload/v1631760840/background_welcome_y5mohs.png`;

    const imageRef = useRef(null);
    const inputRef = useRef(null);

    const handleOnChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        // const compressedFile = await imageCompress(file);
        reader.onload = (e) => { 
            dispatch(OverviewActions({
                ...overview,
                background: file,
            }));
            imageRef.current.src = e.target.result;
        };
        if(file) reader.readAsDataURL(file);
    }

    return (
        <div>

        <FormGroup>
            <Label htmlFor="background">Tải lên background</Label>
            <div className='wapper__overivew__img_modal'>
                <img 
                    style={{display: background ? 'block': 'none', alignSelf: 'center'}}
                    ref={imageRef} 
                    alt="background" 
                    src={background || defaultUrl}
                />
            </div>
            <br />
            <button
                style={{
                    backgroundColor: '#484848',
                    color: 'white',
                    fontSize: '18px',
                    lineHeight: '1.3',
                }}
                className="btn"
                onClick={() => inputRef.current.click()}
            >
            <input 
                style={{display: 'none'}}
                type='file'
                multiple
                onChange={handleOnChange}
                ref={inputRef}
            />
            Tải background
            </button>
        </FormGroup>
        </div>
    );
}