import React, { useMemo } from 'react';
import { getLessonLayout } from '../../../../reducers/convertLesson';
import { lessonTemplates } from '../../../Lesson-v2/UITemplate/data';

export const LearningPointView = ({ data, preType, quizType, lessonLayout }) => {
  const layoutName = useMemo(() => {
    return getLessonLayout({ layout: lessonLayout, quizType, preType });
  }, [lessonLayout, quizType, preType]);

  return (
    <div className={`lesson__right`}>
      <div className={`content grid`}>
        {lessonTemplates
          .find(({ name }) => name === preType)
          .layouts.find(({ name }) => name === layoutName)
          ?.layout(true, data)}
      </div>
    </div>
  );
};
