import React from 'react';
import {
  IconBroken,
  IconBrokenV2,
  IconCurve,
  IconCurveV2,
  IconDrop,
  IconDropV2,
  IconFalling,
  IconFallingV2,
  IconLevel,
  IconRising,
  IconRisingV2,
} from '../icons/IconTone';

export const TONES = [
  {
    key: 'level',
    nameTone: 'Thanh ngang',
    ICON: (() => <IconLevel />)(),
    ICONV2: null,
  },
  {
    key: 'falling',
    nameTone: 'Thanh huyền',
    ICON: (() => <IconFalling />)(),
    ICONV2: <IconFallingV2 />,
  },
  {
    key: 'rising',
    nameTone: 'Thanh sắc',
    ICON: (() => <IconRising />)(),
    ICONV2: <IconRisingV2 />,
  },
  {
    key: 'curve',
    nameTone: 'Thanh hỏi',
    ICON: (() => <IconCurve />)(),
    ICONV2: <IconCurveV2 />,
  },
  {
    key: 'broken',
    nameTone: 'Thanh ngã',
    ICON: (() => <IconBroken />)(),
    ICONV2: <IconBrokenV2 />,
  },
  {
    key: 'drop',
    nameTone: 'Thanh nặng',
    ICON: (() => <IconDrop />)(),
    ICONV2: <IconDropV2 />,
  },
];
export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã/g, 'a');
  str = str.replace(/ầ|ấ|ậ|ẩ|ẫ/g, 'â');
  str = str.replace(/ằ|ắ|ặ|ẳ|ẵ/g, 'ă');
  str = str.replace(/è|é|ẹ|ẻ|ẽ/g, 'e');
  str = str.replace(/ề|ế|ệ|ể|ễ/g, 'ê');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ/g, 'o');
  str = str.replace(/ồ|ố|ộ|ổ|ỗ/g, 'ô');
  str = str.replace(/ờ|ớ|ợ|ở|ỡ/g, 'ơ');
  str = str.replace(/ù|ú|ụ|ủ|ũ/g, 'u');
  str = str.replace(/ừ|ứ|ự|ử|ữ/g, 'ư');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/À|Á|Ạ|Ả|Ã/g, 'A');
  str = str.replace(/Ầ|Ấ|Ậ|Ẩ|Ẫ/g, 'Â');
  str = str.replace(/Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'Ă');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ/g, 'E');
  str = str.replace(/Ề|Ế|Ệ|Ể|Ễ/g, 'Ê');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ/g, 'O');
  str = str.replace(/Ồ|Ố|Ộ|Ổ|Ỗ/g, 'Ô');
  str = str.replace(/Ờ|Ớ|Ợ|Ở|Ỡ/g, 'Ơ');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ/g, 'U');
  str = str.replace(/Ừ|Ứ|Ự|Ử|Ữ/g, 'Ư');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  return str;
};

export const TONE = {
  // thanh bằng / thanh ngang
  LEVEL: {
    unicode:"",
    text: 'level'
  },

  // thanh huyền
  FALLING: {
    unicode: "\u0300",
    text: 'falling'
  },

  // thanh sắc
  RISING: {
    unicode: "\u0301",
    text: 'rising'
  },

  // thanh hỏi
  CURVE: {
    unicode: "\u0309",
    text: 'curve'
  },

  // thanh ngã
  BROKEN: {
    unicode: "\u0303",
    text: 'broken'
  },

  // thanh nặng
  DROP: {
    unicode: "\u0323",
    text: 'drop'
  },
};

export const SQUARE_OBJECT_TYPE = {
  // Tiếng trong câu: mỗi ô vuông sẽ là 1 tiếng trong câu
  WORD_IN_SENTENCE: 'word_in_sentence',

  // Thanh điệu của tiếng: là 1 ô vuông nhưng có hiển thị thêm thanh điệu
  TONE_OF_WORD: 'tone_of_word',

  // Thành phần trong tiếng: mỗi ô vuông sẽ là 1 thành phần trong tiếng (không thanh điệu)
  ELEMENTS_IN_WORD: 'elements_in_word',

  // Thành phần trong tiếng: mỗi ô vuông sẽ là 1 thành phần trong tiếng (có thanh điệu)
  ELEMENTS_OF_WORD: 'elements_of_word',

  // Âm vị trong tiếng: mỗi ô vuông sẽ là 1 âm vị trong tiếng (không thanh điệu)
  PHONEME_IN_SYLLABLE: 'phoneme_in_syllable',

  // Âm vị của tiếng: mỗi ô vuông sẽ là 1 âm vị trong tiếng (có thanh điệu)
  PHONEME_OF_SYLLABLE: 'phoneme_of_syllable',

  // Thành phần trong vần: mỗi ô vuông sẽ là 1 thành phần trong vần (không thanh điệu)
  PHONEME_IN_RHYME: 'phoneme_in_rhyme',

  // Thành phần trong vần: mỗi ô vuông sẽ là 1 thành phần trong vần (có thanh điệu)
  PHONEME_OF_RHYME: 'phoneme_of_rhyme',
};

export const REALM_ROLE = {
  PUBLISHER: 'cms-publisher',
  CREATOR: 'cms-creator',
  ADMIN: 'cms-admin'
}

export const RESOURCE_ROLE = {
  PUBLISHER: 'publisher',
  CREATOR: 'creator',
  ADMIN: 'admin'
}

export const CMS_KEYCLOAK_GROUP ={
  ADMIN: 'cms-admin',
  PUBLISHER: 'cms-publisher',
  CREATOR: 'cms-creator'
}

export const SECTION_STATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  REQUEST_TO_CHANGE: 'REQUEST_TO_CHANGE',
  APPROVED: 'APPROVED',
  SUSPENDED: 'SUSPENDED',
  IN_REVIEW: 'IN_REVIEW'
};

export const STATUS_ACTION = {
  REJECT: {
    vi: 'Từ chối',
    en: 'reject'
  },
  SUSPEND: {
    vi: 'Đình chỉ',
    en: 'suspend'
  },
  REQUEST_TO_CHANGE: {
    vi: 'Yêu cầu thay đổi',
    en: 'request_to_change'
  },
  APPROVE: {
    vi: 'Duyệt',
    en: 'approve'
  },
};

export const PATH_STATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PUBLISH: 'PUBLISH',
  NOT_PUBLISH: 'NOT_PUBLISH',
}

export const FRAME_CONTENT_STATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PUBLISH: 'PUBLISH',
  NOT_PUBLISH: 'NOT_PUBLISH',
  // REJECTED: 'REJECTED',
  // REQUEST_TO_CHANGE: 'REQUEST_TO_CHANGE',
  // APPROVED: 'APPROVED',
  // SUSPENDED: 'SUSPENDED',
  // IN_REVIEW: 'IN_REVIEW',
};