import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from '../pages/Login';
import Lesson from '../pages/Lesson';
import LessonPage from '../pages/LessonPage.js'
import Course from '../pages/Course';
import CourseDetails from '../pages/CourseDetails';
import Admin from '../pages/Admin';
import LandingPage from '../pages/LandingPage';
import HomePage from '../pages/HomePage';
import Path from '../pages/Path';
import PathDetails from '../containers/Path/PathDetails';
import QuestionBank from '../pages/QuestionBank';
import Test from '../pages/Test';
import TestPage from '../pages/TestPage'
import QuizTestFixed from '../pages/QuizTestFixed';
import LessonNavTemplate from '../containers/LessonNavigationTemplate/index.jsx';
import { KeycloakComponent } from '../keycloak';
import { REALM_ROLE, RESOURCE_ROLE } from '../constants';
import NotPermissionPage from '../pages/NotPermission';
import { PublishManagement } from '../containers/PublishManagement';
import { PublishPathManagement } from '../containers/PublishManagement/Path'
import PublishPathDetails from '../containers/PublishManagement/Path/Details'
import { AdminCourse } from '../containers/PublishManagement/Course';
import { AdminLesson } from '../containers/PublishManagement/Lesson';
import PreviewLesson from '../pages/PreviewLesson';
import FrameContent from '../pages/FrameContent';
import CourseComment from '../pages/CourseComment';
// award-admin pages
import CollectionCategory from '../pages/award-admin/CollectionCategory';
import CollectionTemplate from '../pages/award-admin/CollectionTemplate';
import CollectionItem from '../pages/award-admin/CollectionItem';
import SystemCollection from '../pages/award-admin/SystemCollection';
import Order from '../pages/award-admin/Order';
import LuckyBox from '../pages/award-admin/LuckyBox';

export const defaultRoute = '/';

export default [
  {
    path: '/',
    component: LandingPage,
    exact: true,
    restricted: false,
    isPrivate: false,
    realmRoles: [],
    resourceRoles: [],
  },
  {
    path: '/home',
    component: HomePage,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/path',
    component: Path,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/path/:id',
    component: PathDetails,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/admin',
    component: Admin,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN],
    resourceRoles: [RESOURCE_ROLE.ADMIN],
  },
  // {
  //   path: '/login',
  //   component: Login,
  //   exact: true,
  //   restricted: true,
  //   isPrivate: true,
  // },
  {
    path: '/course',
    component: Course,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/course/:id',
    component: CourseDetails,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/course/:id/comment',
    component: CourseComment,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/frame-content/course/:id',
    component: (props) => <FrameContent isAdmin={false} {...props} />,
    exact: true,
    restricted: false,
    isPrivate: true,
    props: {
      admin: false,
    },
  },
  {
    path: '/lesson',
    component: LessonPage,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/lesson/:id',
    component: Lesson,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/question-bank',
    component: QuestionBank,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/test',
    component: TestPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/test/create',
    component: Test,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/test/:id',
    component: Test,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/quizzes-test/:id',
    component: QuizTestFixed,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  {
    path: '/lesson-navigation-template/test',
    component: LessonNavTemplate,
    exact: true,
    restricted: true,
    isPrivate: true,
  },
  {
    path: '/publish/request-list',
    component: PublishManagement,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/publish/path',
    component: PublishPathManagement,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/publish/path/:id',
    component: (props) => <PublishPathDetails isAdmin={true} {...props} />,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/publish/course/:id',
    component: AdminCourse,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/publish/lesson/:id',
    component: AdminLesson,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/preview/lesson/:id',
    component: PreviewLesson,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  {
    path: '/publish/frame-content/course/:id',
    component: (props) => <FrameContent isAdmin={true} {...props} />,
    exact: true,
    restricted: false,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.PUBLISHER],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.PUBLISHER],
  },
  // default award-admin
  {
    path: '/award-cms',
    component: () => <Redirect to="award-cms/item" />,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin collectionCategory
  {
    path: '/award-cms/category',
    component: CollectionCategory,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin collectionTemplate
  {
    path: '/award-cms/template',
    component: CollectionTemplate,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin collectionItem
  {
    path: '/award-cms/item',
    component: CollectionItem,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin systemCollection
  {
    path: '/award-cms/system-collection',
    component: SystemCollection,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, REALM_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin order
  {
    path: '/award-cms/order',
    component: Order,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // award-admin luckybox
  {
    path: '/award-cms/lucky-box',
    component: LuckyBox,
    exact: true,
    restricted: true,
    isPrivate: true,
    realmRoles: [REALM_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
  // unauthorized
  {
    path: '/unauthorized',
    component: NotPermissionPage,
    exact: true,
    restricted: false,
    isPrivate: false,
    realmRoles: [REALM_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
    resourceRoles: [RESOURCE_ROLE.ADMIN, RESOURCE_ROLE.CREATOR],
  },
];
