const initState = {
  syncing: false,
  listCourse: [],
  filter: {
    isOpen: false,
    status: [],
    date: {
      from: new Date(),
      to: new Date(),
      oldestDate: '',
    },
  },
  search: '',
  compareLearningPoint: {
    data: null,
    learningPointId: null,
    type: null,
    isOpen: false,
  },
  commentLearningPoint: {
    learningPointId: null,
    type: null,
    isOpen: false,
    comments: [],
  },
};

export const PublishManagementAction = {
  LOADING: '@@publishManagement/LOADING',
  UPDATE_COMPARE: '@@publishManagement/UPDATE_COMPARE',
  GET_COMPARE: '@@publishManagement/GET_COMPARE',
  GET_LIST_COURSE: '@@publishManagement/GET_LIST_COURSE',
  OPEN_FILTER: '@@publishManagement/OPEN_FILTER',
  CLOSE_FILTER: '@@publishManagement/CLOSE_FILTER',
  SET_FILTER: '@@publishManagement/SET_FILTER',
  SET_SEARCH: '@@publishManagement/SET_SEARCH',
};

export const publishManagement = (state = initState, action) => {
  switch (action.type) {
    case PublishManagementAction.LOADING:
      return {
        ...state,
        syncing: action.data,
      };
    case PublishManagementAction.UPDATE_COMPARE:
      return {
        ...state,
        compareLearningPoint: {
          ...state.compareLearningPoint,
          ...action.data,
        },
      };
    case PublishManagementAction.GET_COMPARE:
      return {
        ...state,
        compareLearningPoint: {
          ...state.compareLearningPoint,
          data: action.data,
        },
      };
    case PublishManagementAction.GET_LIST_COURSE:
      return {
        ...state,
        listCourse: action.data,
      };
    case PublishManagementAction.OPEN_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          isOpen: true,
        },
      };
    case PublishManagementAction.CLOSE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          isOpen: false,
        },
      };
    case PublishManagementAction.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data,
        },
      };
    case PublishManagementAction.SET_SEARCH:
      return {
        ...state,
        search: action.data,
      };
    default:
      return state;
  }
};

export default publishManagement;
