import React from 'react';
import styled from 'styled-components';

const FadeWrapper = styled.div`
  -webkit-animation: fadein 0.6s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.6s ease-in-out; /* Firefox < 16 */
  -ms-animation: fadein 0.6s ease-in-out; /* Internet Explorer */
  -o-animation: fadein 0.6s ease-in-out; /* Opera < 12.1 */
  animation: fadein 0.6s ease-in-out;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

function Fade({ children }) {
  return <FadeWrapper>{children}</FadeWrapper>;
}

export default Fade;
