import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Common/Header';
import Loading from '../../../components/Common/Loading';
import { ListSection } from '../../../components/PublishManagement/Course/Content/ListSection';
import { ListChapter } from '../../../components/PublishManagement/Course/Content/ListChapter';
import { CourseListAction } from '../../../components/PublishManagement/Course/ListAction';
import { fetchChapters } from '../../../reducers/chapter';
import '../style.scss';

export const AdminCourse = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchChapters(1));
    }, []);

    const { data: chapters, syncing } = useSelector(state => state.chapter);
    return (
        <>
            {syncing && <Loading />}
            <Header />
            <div className="admin-course"> 
                <CourseListAction />
                <div className="content">
                    <ListChapter chapters={chapters} />
                    <ListSection chapters={chapters} />
                </div>
            </div>
        </>
    );
}