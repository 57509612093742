const initState = {
  speaker: 'ngochuyen',
};

const ActionTypes = {
  CHANGE_SPEAKER: '@@voice/CHANGE_SPEAKER',
};

export const changeSpeaker = (speaker) => ({
  type: 'CHANGE_SPEAKER',
  speaker,
});

const voice = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SPEAKER:
      return {
        ...state,
        speaker: action.speaker,
      };
    default:
      return state;
  }
};

export default voice;
