import React from 'react';
import { getFullUrl } from '../../../utils/imageProcessing';
import { generateId } from '../../../utils/generate';

class GetMediaQuiz extends React.Component {
  render() {
    const { dataContent } = this.props;
    if (dataContent.type === 'image') {
      return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          id={`dataContent-image-${generateId()}`}
          style={{ objectFit: 'contain' }}
          src={getFullUrl(dataContent.imageUrl || '')}
        />
      );
    }
    if (dataContent.type === 'video') {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video id={`dataContent-videoT-${generateId()}`} style={{ objectFit: 'contain' }} controls>
          {' '}
          <source id={`dataContent-videoS-${generateId()}`} src={getFullUrl(dataContent.videoUrl || '')} />{' '}
        </video>
      );
    }
    return <span className="m-auto">{dataContent.text || ''}</span>;
  }
}

export default GetMediaQuiz;
