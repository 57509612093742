
export const SPLIT_SPECIAL_SYLLABLE = [
    // gi + i (âm đầu)
    {
        "id": 0,
        "syllable": "gi",
        "consonant": "gi",
        "rhyme": "i"
    },
    {
        "id": 1,
        "syllable": "gich",
        "consonant": "gi",
        "rhyme": "ich"
    },
    {
        "id": 2,
        "syllable": "ginh",
        "consonant": "gi",
        "rhyme": "inh"
    },
    {
        "id": 3,
        "syllable": "git",
        "consonant": "gi",
        "rhyme": "it"
    },
    {
        "id": 4,
        "syllable": "gin",
        "consonant": "gi",
        "rhyme": "in"
    },
    {
        "id": 5,
        "syllable": "gip",
        "consonant": "gi",
        "rhyme": "ip"
    },
    {
        "id": 6,
        "syllable": "gim",
        "consonant": "gi",
        "rhyme": "im"
    },
    {
        "id": 7,
        "syllable": "giu",
        "consonant": "gi",
        "rhyme": "iu"
    },
    // gi + iê (âm đầu)
    {
        "id": 8,
        "syllable": "gia",
        "consonant": "gi",
        "rhyme": "ia"
    },
    {
        "id": 9,
        "syllable": "giec",
        "consonant": "gi",
        "rhyme": "iec"
    },
    {
        "id": 10,
        "syllable": "giêng",
        "consonant": "gi",
        "rhyme": "iêng"
    },
    {
        "id": 11,
        "syllable": "giêt",
        "consonant": "gi",
        "rhyme": "iêt"
    },
    {
        "id": 12,
        "syllable": "giên",
        "consonant": "gi",
        "rhyme": "iên"
    },
    {
        "id": 13,
        "syllable": "giêp",
        "consonant": "gi",
        "rhyme": "iêp"
    },
    {
        "id": 14,
        "syllable": "giêm",
        "consonant": "gi",
        "rhyme": "iêm"
    },
    {
        "id": 15,
        "syllable": "giêu",
        "consonant": "gi",
        "rhyme": "iêu"
    },
    // y-i
    {
        "id": 16,
        "syllable": "y",
        "consonant": "",
        "rhyme": "y"
    },
    {
        "id": 17,
        "syllable": "ych",
        "consonant": "",
        "rhyme": "ych"
    },
    {
        "id": 18,
        "syllable": "ynh",
        "consonant": "",
        "rhyme": "ynh"
    },
    {
        "id": 19,
        "syllable": "yt",
        "consonant": "",
        "rhyme": "yt"
    },
    {
        "id": 20,
        "syllable": "yn",
        "consonant": "",
        "rhyme": "yn"
    },
    {
        "id": 21,
        "syllable": "yp",
        "consonant": "",
        "rhyme": "yp"
    },
    {
        "id": 22,
        "syllable": "ym",
        "consonant": "",
        "rhyme": "ym"
    },
    {
        "id": 23,
        "syllable": "yu",
        "consonant": "",
        "rhyme": "yu"
    },
    {
        "id": 24,
        "syllable": "ya",
        "consonant": "",
        "rhyme": "ya"
    },
    {
        "id": 25,
        "syllable": "yec",
        "consonant": "",
        "rhyme": "yec"
    },
    {
        "id": 26,
        "syllable": "yêng",
        "consonant": "",
        "rhyme": "yêng"
    },
    {
        "id": 27,
        "syllable": "yêt",
        "consonant": "",
        "rhyme": "yêt"
    },
    {
        "id": 28,
        "syllable": "yên",
        "consonant": "",
        "rhyme": "yên"
    },
    {
        "id": 29,
        "syllable": "yêp",
        "consonant": "",
        "rhyme": "yêp"
    },
    {
        "id": 30,
        "syllable": "yêm",
        "consonant": "",
        "rhyme": "yêm"
    },
    {
        "id": 31,
        "syllable": "yêu",
        "consonant": "",
        "rhyme": "yêu"
    },
];

export const SPLIT_SYLLABLE = [
    {
        "id": 0,
        "syllable": "que",
        "consonant": "q",
        "rhyme": "ue"
    },
    {
        "id": 1,
        "syllable": "quec",
        "consonant": "q",
        "rhyme": "uec"
    },
    {
        "id": 2,
        "syllable": "queng",
        "consonant": "q",
        "rhyme": "ueng"
    },
    {
        "id": 3,
        "syllable": "quet",
        "consonant": "q",
        "rhyme": "uet"
    },
    {
        "id": 4,
        "syllable": "quen",
        "consonant": "q",
        "rhyme": "uen"
    },
    {
        "id": 5,
        "syllable": "quep",
        "consonant": "q",
        "rhyme": "uep"
    },
    {
        "id": 6,
        "syllable": "quem",
        "consonant": "q",
        "rhyme": "uem"
    },
    {
        "id": 7,
        "syllable": "queo",
        "consonant": "q",
        "rhyme": "ueo"
    },
    {
        "id": 8,
        "syllable": "quach",
        "consonant": "q",
        "rhyme": "uach"
    },
    {
        "id": 9,
        "syllable": "quanh",
        "consonant": "q",
        "rhyme": "uanh"
    },
    {
        "id": 10,
        "syllable": "qua",
        "consonant": "q",
        "rhyme": "ua"
    },
    {
        "id": 11,
        "syllable": "quac",
        "consonant": "q",
        "rhyme": "uac"
    },
    {
        "id": 12,
        "syllable": "quang",
        "consonant": "q",
        "rhyme": "uang"
    },
    {
        "id": 13,
        "syllable": "quat",
        "consonant": "q",
        "rhyme": "uat"
    },
    {
        "id": 14,
        "syllable": "quan",
        "consonant": "q",
        "rhyme": "uan"
    },
    {
        "id": 15,
        "syllable": "quap",
        "consonant": "q",
        "rhyme": "uap"
    },
    {
        "id": 16,
        "syllable": "quam",
        "consonant": "q",
        "rhyme": "uam"
    },
    {
        "id": 17,
        "syllable": "quai",
        "consonant": "q",
        "rhyme": "uai"
    },
    {
        "id": 18,
        "syllable": "quao",
        "consonant": "q",
        "rhyme": "uao"
    },
    {
        "id": 19,
        "syllable": "quăc",
        "consonant": "q",
        "rhyme": "uăc"
    },
    {
        "id": 20,
        "syllable": "quăng",
        "consonant": "q",
        "rhyme": "uăng"
    },
    {
        "id": 21,
        "syllable": "quăt",
        "consonant": "q",
        "rhyme": "uăt"
    },
    {
        "id": 22,
        "syllable": "quăn",
        "consonant": "q",
        "rhyme": "uăn"
    },
    {
        "id": 23,
        "syllable": "quăp",
        "consonant": "q",
        "rhyme": "uăp"
    },
    {
        "id": 24,
        "syllable": "quăm",
        "consonant": "q",
        "rhyme": "uăm"
    },
    {
        "id": 25,
        "syllable": "quay",
        "consonant": "q",
        "rhyme": "uay"
    },
    {
        "id": 26,
        "syllable": "quau",
        "consonant": "q",
        "rhyme": "uau"
    },
    {
        "id": 27,
        "syllable": "nghi",
        "consonant": "ngh",
        "rhyme": "i"
    },
    {
        "id": 28,
        "syllable": "nghuy",
        "consonant": "ngh",
        "rhyme": "uy"
    },
    {
        "id": 29,
        "syllable": "nghich",
        "consonant": "ngh",
        "rhyme": "ich"
    },
    {
        "id": 30,
        "syllable": "nghuych",
        "consonant": "ngh",
        "rhyme": "uych"
    },
    {
        "id": 31,
        "syllable": "nghinh",
        "consonant": "ngh",
        "rhyme": "inh"
    },
    {
        "id": 32,
        "syllable": "nghuynh",
        "consonant": "ngh",
        "rhyme": "uynh"
    },
    {
        "id": 33,
        "syllable": "nghit",
        "consonant": "ngh",
        "rhyme": "it"
    },
    {
        "id": 34,
        "syllable": "nghuyt",
        "consonant": "ngh",
        "rhyme": "uyt"
    },
    {
        "id": 35,
        "syllable": "nghin",
        "consonant": "ngh",
        "rhyme": "in"
    },
    {
        "id": 36,
        "syllable": "nghuyn",
        "consonant": "ngh",
        "rhyme": "uyn"
    },
    {
        "id": 37,
        "syllable": "nghip",
        "consonant": "ngh",
        "rhyme": "ip"
    },
    {
        "id": 38,
        "syllable": "nghuyp",
        "consonant": "ngh",
        "rhyme": "uyp"
    },
    {
        "id": 39,
        "syllable": "nghim",
        "consonant": "ngh",
        "rhyme": "im"
    },
    {
        "id": 40,
        "syllable": "nghuym",
        "consonant": "ngh",
        "rhyme": "uym"
    },
    {
        "id": 41,
        "syllable": "nghiu",
        "consonant": "ngh",
        "rhyme": "iu"
    },
    {
        "id": 42,
        "syllable": "nghuyu",
        "consonant": "ngh",
        "rhyme": "uyu"
    },
    {
        "id": 43,
        "syllable": "nghê",
        "consonant": "ngh",
        "rhyme": "ê"
    },
    {
        "id": 44,
        "syllable": "nghuê",
        "consonant": "ngh",
        "rhyme": "uê"
    },
    {
        "id": 45,
        "syllable": "nghêch",
        "consonant": "ngh",
        "rhyme": "êch"
    },
    {
        "id": 46,
        "syllable": "nghuêch",
        "consonant": "ngh",
        "rhyme": "uêch"
    },
    {
        "id": 47,
        "syllable": "nghênh",
        "consonant": "ngh",
        "rhyme": "ênh"
    },
    {
        "id": 48,
        "syllable": "nghuênh",
        "consonant": "ngh",
        "rhyme": "uênh"
    },
    {
        "id": 49,
        "syllable": "nghêt",
        "consonant": "ngh",
        "rhyme": "êt"
    },
    {
        "id": 50,
        "syllable": "nghuêt",
        "consonant": "ngh",
        "rhyme": "uêt"
    },
    {
        "id": 51,
        "syllable": "nghên",
        "consonant": "ngh",
        "rhyme": "ên"
    },
    {
        "id": 52,
        "syllable": "nghuên",
        "consonant": "ngh",
        "rhyme": "uên"
    },
    {
        "id": 53,
        "syllable": "nghêp",
        "consonant": "ngh",
        "rhyme": "êp"
    },
    {
        "id": 54,
        "syllable": "nghuêp",
        "consonant": "ngh",
        "rhyme": "uêp"
    },
    {
        "id": 55,
        "syllable": "nghêm",
        "consonant": "ngh",
        "rhyme": "êm"
    },
    {
        "id": 56,
        "syllable": "nghuêm",
        "consonant": "ngh",
        "rhyme": "uêm"
    },
    {
        "id": 57,
        "syllable": "nghêu",
        "consonant": "ngh",
        "rhyme": "êu"
    },
    {
        "id": 58,
        "syllable": "nghe",
        "consonant": "ngh",
        "rhyme": "e"
    },
    {
        "id": 59,
        "syllable": "nghoe",
        "consonant": "ngh",
        "rhyme": "oe"
    },
    {
        "id": 60,
        "syllable": "nghec",
        "consonant": "ngh",
        "rhyme": "ec"
    },
    {
        "id": 61,
        "syllable": "nghoec",
        "consonant": "ngh",
        "rhyme": "oec"
    },
    {
        "id": 62,
        "syllable": "ngheng",
        "consonant": "ngh",
        "rhyme": "eng"
    },
    {
        "id": 63,
        "syllable": "nghoeng",
        "consonant": "ngh",
        "rhyme": "oeng"
    },
    {
        "id": 64,
        "syllable": "nghet",
        "consonant": "ngh",
        "rhyme": "et"
    },
    {
        "id": 65,
        "syllable": "nghoet",
        "consonant": "ngh",
        "rhyme": "oet"
    },
    {
        "id": 66,
        "syllable": "nghen",
        "consonant": "ngh",
        "rhyme": "en"
    },
    {
        "id": 67,
        "syllable": "nghoen",
        "consonant": "ngh",
        "rhyme": "oen"
    },
    {
        "id": 68,
        "syllable": "nghep",
        "consonant": "ngh",
        "rhyme": "ep"
    },
    {
        "id": 69,
        "syllable": "nghoep",
        "consonant": "ngh",
        "rhyme": "oep"
    },
    {
        "id": 70,
        "syllable": "nghem",
        "consonant": "ngh",
        "rhyme": "em"
    },
    {
        "id": 71,
        "syllable": "nghoem",
        "consonant": "ngh",
        "rhyme": "oem"
    },
    {
        "id": 72,
        "syllable": "ngheo",
        "consonant": "ngh",
        "rhyme": "eo"
    },
    {
        "id": 73,
        "syllable": "nghoeo",
        "consonant": "ngh",
        "rhyme": "oeo"
    },
    {
        "id": 74,
        "syllable": "ghi",
        "consonant": "gh",
        "rhyme": "i"
    },
    {
        "id": 75,
        "syllable": "ghuy",
        "consonant": "gh",
        "rhyme": "uy"
    },
    {
        "id": 76,
        "syllable": "ghich",
        "consonant": "gh",
        "rhyme": "ich"
    },
    {
        "id": 77,
        "syllable": "ghuych",
        "consonant": "gh",
        "rhyme": "uych"
    },
    {
        "id": 78,
        "syllable": "ghinh",
        "consonant": "gh",
        "rhyme": "inh"
    },
    {
        "id": 79,
        "syllable": "ghuynh",
        "consonant": "gh",
        "rhyme": "uynh"
    },
    {
        "id": 80,
        "syllable": "ghit",
        "consonant": "gh",
        "rhyme": "it"
    },
    {
        "id": 81,
        "syllable": "ghuyt",
        "consonant": "gh",
        "rhyme": "uyt"
    },
    {
        "id": 82,
        "syllable": "ghin",
        "consonant": "gh",
        "rhyme": "in"
    },
    {
        "id": 83,
        "syllable": "ghuyn",
        "consonant": "gh",
        "rhyme": "uyn"
    },
    {
        "id": 84,
        "syllable": "ghip",
        "consonant": "gh",
        "rhyme": "ip"
    },
    {
        "id": 85,
        "syllable": "ghuyp",
        "consonant": "gh",
        "rhyme": "uyp"
    },
    {
        "id": 86,
        "syllable": "ghim",
        "consonant": "gh",
        "rhyme": "im"
    },
    {
        "id": 87,
        "syllable": "ghuym",
        "consonant": "gh",
        "rhyme": "uym"
    },
    {
        "id": 88,
        "syllable": "ghiu",
        "consonant": "gh",
        "rhyme": "iu"
    },
    {
        "id": 89,
        "syllable": "ghuyu",
        "consonant": "gh",
        "rhyme": "uyu"
    },
    {
        "id": 90,
        "syllable": "ghê",
        "consonant": "gh",
        "rhyme": "ê"
    },
    {
        "id": 91,
        "syllable": "ghuê",
        "consonant": "gh",
        "rhyme": "uê"
    },
    {
        "id": 92,
        "syllable": "ghêch",
        "consonant": "gh",
        "rhyme": "êch"
    },
    {
        "id": 93,
        "syllable": "ghuêch",
        "consonant": "gh",
        "rhyme": "uêch"
    },
    {
        "id": 94,
        "syllable": "ghênh",
        "consonant": "gh",
        "rhyme": "ênh"
    },
    {
        "id": 95,
        "syllable": "ghuênh",
        "consonant": "gh",
        "rhyme": "uênh"
    },
    {
        "id": 96,
        "syllable": "ghêt",
        "consonant": "gh",
        "rhyme": "êt"
    },
    {
        "id": 97,
        "syllable": "ghuêt",
        "consonant": "gh",
        "rhyme": "uêt"
    },
    {
        "id": 98,
        "syllable": "ghên",
        "consonant": "gh",
        "rhyme": "ên"
    },
    {
        "id": 99,
        "syllable": "ghuên",
        "consonant": "gh",
        "rhyme": "uên"
    },
    {
        "id": 100,
        "syllable": "ghêp",
        "consonant": "gh",
        "rhyme": "êp"
    },
    {
        "id": 101,
        "syllable": "ghuêp",
        "consonant": "gh",
        "rhyme": "uêp"
    },
    {
        "id": 102,
        "syllable": "ghêm",
        "consonant": "gh",
        "rhyme": "êm"
    },
    {
        "id": 103,
        "syllable": "ghuêm",
        "consonant": "gh",
        "rhyme": "uêm"
    },
    {
        "id": 104,
        "syllable": "ghêu",
        "consonant": "gh",
        "rhyme": "êu"
    },
    {
        "id": 105,
        "syllable": "ghe",
        "consonant": "gh",
        "rhyme": "e"
    },
    {
        "id": 106,
        "syllable": "ghoe",
        "consonant": "gh",
        "rhyme": "oe"
    },
    {
        "id": 107,
        "syllable": "ghec",
        "consonant": "gh",
        "rhyme": "ec"
    },
    {
        "id": 108,
        "syllable": "ghoec",
        "consonant": "gh",
        "rhyme": "oec"
    },
    {
        "id": 109,
        "syllable": "gheng",
        "consonant": "gh",
        "rhyme": "eng"
    },
    {
        "id": 110,
        "syllable": "ghoeng",
        "consonant": "gh",
        "rhyme": "oeng"
    },
    {
        "id": 111,
        "syllable": "ghet",
        "consonant": "gh",
        "rhyme": "et"
    },
    {
        "id": 112,
        "syllable": "ghoet",
        "consonant": "gh",
        "rhyme": "oet"
    },
    {
        "id": 113,
        "syllable": "ghen",
        "consonant": "gh",
        "rhyme": "en"
    },
    {
        "id": 114,
        "syllable": "ghoen",
        "consonant": "gh",
        "rhyme": "oen"
    },
    {
        "id": 115,
        "syllable": "ghep",
        "consonant": "gh",
        "rhyme": "ep"
    },
    {
        "id": 116,
        "syllable": "ghoep",
        "consonant": "gh",
        "rhyme": "oep"
    },
    {
        "id": 117,
        "syllable": "ghem",
        "consonant": "gh",
        "rhyme": "em"
    },
    {
        "id": 118,
        "syllable": "ghoem",
        "consonant": "gh",
        "rhyme": "oem"
    },
    {
        "id": 119,
        "syllable": "gheo",
        "consonant": "gh",
        "rhyme": "eo"
    },
    {
        "id": 120,
        "syllable": "ghoeo",
        "consonant": "gh",
        "rhyme": "oeo"
    },
    {
        "id": 121,
        "syllable": "ngach",
        "consonant": "ng",
        "rhyme": "ach"
    },
    {
        "id": 122,
        "syllable": "ngoach",
        "consonant": "ng",
        "rhyme": "oach"
    },
    {
        "id": 123,
        "syllable": "nganh",
        "consonant": "ng",
        "rhyme": "anh"
    },
    {
        "id": 124,
        "syllable": "ngoanh",
        "consonant": "ng",
        "rhyme": "oanh"
    },
    {
        "id": 125,
        "syllable": "ngia",
        "consonant": "ng",
        "rhyme": "ia"
    },
    {
        "id": 126,
        "syllable": "nguya",
        "consonant": "ng",
        "rhyme": "uya"
    },
    {
        "id": 127,
        "syllable": "ngiêc",
        "consonant": "ng",
        "rhyme": "iêc"
    },
    {
        "id": 128,
        "syllable": "ngiêng",
        "consonant": "ng",
        "rhyme": "iêng"
    },
    {
        "id": 129,
        "syllable": "ngiêt",
        "consonant": "ng",
        "rhyme": "iêt"
    },
    {
        "id": 130,
        "syllable": "nguyêt",
        "consonant": "ng",
        "rhyme": "uyêt"
    },
    {
        "id": 131,
        "syllable": "ngiên",
        "consonant": "ng",
        "rhyme": "iên"
    },
    {
        "id": 132,
        "syllable": "nguyên",
        "consonant": "ng",
        "rhyme": "uyên"
    },
    {
        "id": 133,
        "syllable": "ngiêp",
        "consonant": "ng",
        "rhyme": "iêp"
    },
    {
        "id": 134,
        "syllable": "nguyêp",
        "consonant": "ng",
        "rhyme": "uyêp"
    },
    {
        "id": 135,
        "syllable": "ngiêm",
        "consonant": "ng",
        "rhyme": "iêm"
    },
    {
        "id": 136,
        "syllable": "nguyêm",
        "consonant": "ng",
        "rhyme": "uyêm"
    },
    {
        "id": 137,
        "syllable": "ngiêu",
        "consonant": "ng",
        "rhyme": "iêu"
    },
    {
        "id": 138,
        "syllable": "ngư",
        "consonant": "ng",
        "rhyme": "ư"
    },
    {
        "id": 139,
        "syllable": "ngưc",
        "consonant": "ng",
        "rhyme": "ưc"
    },
    {
        "id": 140,
        "syllable": "ngưng",
        "consonant": "ng",
        "rhyme": "ưng"
    },
    {
        "id": 141,
        "syllable": "ngưt",
        "consonant": "ng",
        "rhyme": "ưt"
    },
    {
        "id": 142,
        "syllable": "ngưn",
        "consonant": "ng",
        "rhyme": "ưn"
    },
    {
        "id": 143,
        "syllable": "ngưp",
        "consonant": "ng",
        "rhyme": "ưp"
    },
    {
        "id": 144,
        "syllable": "ngưm",
        "consonant": "ng",
        "rhyme": "ưm"
    },
    {
        "id": 145,
        "syllable": "ngưi",
        "consonant": "ng",
        "rhyme": "ưi"
    },
    {
        "id": 146,
        "syllable": "ngưu",
        "consonant": "ng",
        "rhyme": "ưu"
    },
    {
        "id": 147,
        "syllable": "ngơ",
        "consonant": "ng",
        "rhyme": "ơ"
    },
    {
        "id": 148,
        "syllable": "nguơ",
        "consonant": "ng",
        "rhyme": "uơ"
    },
    {
        "id": 149,
        "syllable": "ngơng",
        "consonant": "ng",
        "rhyme": "ơng"
    },
    {
        "id": 150,
        "syllable": "ngơt",
        "consonant": "ng",
        "rhyme": "ơt"
    },
    {
        "id": 151,
        "syllable": "ngơn",
        "consonant": "ng",
        "rhyme": "ơn"
    },
    {
        "id": 152,
        "syllable": "ngơp",
        "consonant": "ng",
        "rhyme": "ơp"
    },
    {
        "id": 153,
        "syllable": "ngơm",
        "consonant": "ng",
        "rhyme": "ơm"
    },
    {
        "id": 154,
        "syllable": "ngơi",
        "consonant": "ng",
        "rhyme": "ơi"
    },
    {
        "id": 155,
        "syllable": "ngâc",
        "consonant": "ng",
        "rhyme": "âc"
    },
    {
        "id": 156,
        "syllable": "ngâng",
        "consonant": "ng",
        "rhyme": "âng"
    },
    {
        "id": 157,
        "syllable": "nguâng",
        "consonant": "ng",
        "rhyme": "uâng"
    },
    {
        "id": 158,
        "syllable": "ngât",
        "consonant": "ng",
        "rhyme": "ât"
    },
    {
        "id": 159,
        "syllable": "nguât",
        "consonant": "ng",
        "rhyme": "uât"
    },
    {
        "id": 160,
        "syllable": "ngân",
        "consonant": "ng",
        "rhyme": "ân"
    },
    {
        "id": 161,
        "syllable": "nguân",
        "consonant": "ng",
        "rhyme": "uân"
    },
    {
        "id": 162,
        "syllable": "ngâp",
        "consonant": "ng",
        "rhyme": "âp"
    },
    {
        "id": 163,
        "syllable": "nguâp",
        "consonant": "ng",
        "rhyme": "uâp"
    },
    {
        "id": 164,
        "syllable": "ngâm",
        "consonant": "ng",
        "rhyme": "âm"
    },
    {
        "id": 165,
        "syllable": "nguâm",
        "consonant": "ng",
        "rhyme": "uâm"
    },
    {
        "id": 166,
        "syllable": "ngây",
        "consonant": "ng",
        "rhyme": "ây"
    },
    {
        "id": 167,
        "syllable": "nguây",
        "consonant": "ng",
        "rhyme": "uây"
    },
    {
        "id": 168,
        "syllable": "ngâu",
        "consonant": "ng",
        "rhyme": "âu"
    },
    {
        "id": 169,
        "syllable": "nguâu",
        "consonant": "ng",
        "rhyme": "uâu"
    },
    {
        "id": 170,
        "syllable": "ngu",
        "consonant": "ng",
        "rhyme": "u"
    },
    {
        "id": 171,
        "syllable": "nguc",
        "consonant": "ng",
        "rhyme": "uc"
    },
    {
        "id": 172,
        "syllable": "ngung",
        "consonant": "ng",
        "rhyme": "ung"
    },
    {
        "id": 173,
        "syllable": "ngut",
        "consonant": "ng",
        "rhyme": "ut"
    },
    {
        "id": 174,
        "syllable": "ngun",
        "consonant": "ng",
        "rhyme": "un"
    },
    {
        "id": 175,
        "syllable": "ngup",
        "consonant": "ng",
        "rhyme": "up"
    },
    {
        "id": 176,
        "syllable": "ngum",
        "consonant": "ng",
        "rhyme": "um"
    },
    {
        "id": 177,
        "syllable": "ngui",
        "consonant": "ng",
        "rhyme": "ui"
    },
    {
        "id": 178,
        "syllable": "ngô",
        "consonant": "ng",
        "rhyme": "ô"
    },
    {
        "id": 179,
        "syllable": "ngôc",
        "consonant": "ng",
        "rhyme": "ôc"
    },
    {
        "id": 180,
        "syllable": "ngông",
        "consonant": "ng",
        "rhyme": "ông"
    },
    {
        "id": 181,
        "syllable": "ngôt",
        "consonant": "ng",
        "rhyme": "ôt"
    },
    {
        "id": 182,
        "syllable": "ngôn",
        "consonant": "ng",
        "rhyme": "ôn"
    },
    {
        "id": 183,
        "syllable": "ngôp",
        "consonant": "ng",
        "rhyme": "ôp"
    },
    {
        "id": 184,
        "syllable": "ngôm",
        "consonant": "ng",
        "rhyme": "ôm"
    },
    {
        "id": 185,
        "syllable": "ngôi",
        "consonant": "ng",
        "rhyme": "ôi"
    },
    {
        "id": 186,
        "syllable": "ngo",
        "consonant": "ng",
        "rhyme": "o"
    },
    {
        "id": 187,
        "syllable": "ngooc",
        "consonant": "ng",
        "rhyme": "ooc"
    },
    {
        "id": 188,
        "syllable": "ngoong",
        "consonant": "ng",
        "rhyme": "oong"
    },
    {
        "id": 189,
        "syllable": "ngot",
        "consonant": "ng",
        "rhyme": "ot"
    },
    {
        "id": 190,
        "syllable": "ngon",
        "consonant": "ng",
        "rhyme": "on"
    },
    {
        "id": 191,
        "syllable": "ngop",
        "consonant": "ng",
        "rhyme": "op"
    },
    {
        "id": 192,
        "syllable": "ngom",
        "consonant": "ng",
        "rhyme": "om"
    },
    {
        "id": 193,
        "syllable": "ngoi",
        "consonant": "ng",
        "rhyme": "oi"
    },
    {
        "id": 194,
        "syllable": "ngoc",
        "consonant": "ng",
        "rhyme": "oc"
    },
    {
        "id": 195,
        "syllable": "ngong",
        "consonant": "ng",
        "rhyme": "ong"
    },
    {
        "id": 196,
        "syllable": "ngua",
        "consonant": "ng",
        "rhyme": "ua"
    },
    {
        "id": 197,
        "syllable": "nguôc",
        "consonant": "ng",
        "rhyme": "uôc"
    },
    {
        "id": 198,
        "syllable": "nguông",
        "consonant": "ng",
        "rhyme": "uông"
    },
    {
        "id": 199,
        "syllable": "nguôt",
        "consonant": "ng",
        "rhyme": "uôt"
    },
    {
        "id": 200,
        "syllable": "nguôn",
        "consonant": "ng",
        "rhyme": "uôn"
    },
    {
        "id": 201,
        "syllable": "nguôp",
        "consonant": "ng",
        "rhyme": "uôp"
    },
    {
        "id": 202,
        "syllable": "nguôm",
        "consonant": "ng",
        "rhyme": "uôm"
    },
    {
        "id": 203,
        "syllable": "nguôi",
        "consonant": "ng",
        "rhyme": "uôi"
    },
    {
        "id": 204,
        "syllable": "ngưa",
        "consonant": "ng",
        "rhyme": "ưa"
    },
    {
        "id": 205,
        "syllable": "ngươc",
        "consonant": "ng",
        "rhyme": "ươc"
    },
    {
        "id": 206,
        "syllable": "ngương",
        "consonant": "ng",
        "rhyme": "ương"
    },
    {
        "id": 207,
        "syllable": "ngươt",
        "consonant": "ng",
        "rhyme": "ươt"
    },
    {
        "id": 208,
        "syllable": "ngươn",
        "consonant": "ng",
        "rhyme": "ươn"
    },
    {
        "id": 209,
        "syllable": "ngươp",
        "consonant": "ng",
        "rhyme": "ươp"
    },
    {
        "id": 210,
        "syllable": "ngươm",
        "consonant": "ng",
        "rhyme": "ươm"
    },
    {
        "id": 211,
        "syllable": "ngươi",
        "consonant": "ng",
        "rhyme": "ươi"
    },
    {
        "id": 212,
        "syllable": "nga",
        "consonant": "ng",
        "rhyme": "a"
    },
    {
        "id": 213,
        "syllable": "ngoa",
        "consonant": "ng",
        "rhyme": "oa"
    },
    {
        "id": 214,
        "syllable": "ngac",
        "consonant": "ng",
        "rhyme": "ac"
    },
    {
        "id": 215,
        "syllable": "ngoac",
        "consonant": "ng",
        "rhyme": "oac"
    },
    {
        "id": 216,
        "syllable": "ngang",
        "consonant": "ng",
        "rhyme": "ang"
    },
    {
        "id": 217,
        "syllable": "ngoang",
        "consonant": "ng",
        "rhyme": "oang"
    },
    {
        "id": 218,
        "syllable": "ngat",
        "consonant": "ng",
        "rhyme": "at"
    },
    {
        "id": 219,
        "syllable": "ngoat",
        "consonant": "ng",
        "rhyme": "oat"
    },
    {
        "id": 220,
        "syllable": "ngan",
        "consonant": "ng",
        "rhyme": "an"
    },
    {
        "id": 221,
        "syllable": "ngoan",
        "consonant": "ng",
        "rhyme": "oan"
    },
    {
        "id": 222,
        "syllable": "ngap",
        "consonant": "ng",
        "rhyme": "ap"
    },
    {
        "id": 223,
        "syllable": "ngoap",
        "consonant": "ng",
        "rhyme": "oap"
    },
    {
        "id": 224,
        "syllable": "ngam",
        "consonant": "ng",
        "rhyme": "am"
    },
    {
        "id": 225,
        "syllable": "ngoam",
        "consonant": "ng",
        "rhyme": "oam"
    },
    {
        "id": 226,
        "syllable": "ngai",
        "consonant": "ng",
        "rhyme": "ai"
    },
    {
        "id": 227,
        "syllable": "ngoai",
        "consonant": "ng",
        "rhyme": "oai"
    },
    {
        "id": 228,
        "syllable": "ngao",
        "consonant": "ng",
        "rhyme": "ao"
    },
    {
        "id": 229,
        "syllable": "ngoao",
        "consonant": "ng",
        "rhyme": "oao"
    },
    {
        "id": 230,
        "syllable": "ngăc",
        "consonant": "ng",
        "rhyme": "ăc"
    },
    {
        "id": 231,
        "syllable": "ngoăc",
        "consonant": "ng",
        "rhyme": "oăc"
    },
    {
        "id": 232,
        "syllable": "ngăng",
        "consonant": "ng",
        "rhyme": "ăng"
    },
    {
        "id": 233,
        "syllable": "ngoăng",
        "consonant": "ng",
        "rhyme": "oăng"
    },
    {
        "id": 234,
        "syllable": "ngăt",
        "consonant": "ng",
        "rhyme": "ăt"
    },
    {
        "id": 235,
        "syllable": "ngoăt",
        "consonant": "ng",
        "rhyme": "oăt"
    },
    {
        "id": 236,
        "syllable": "ngăn",
        "consonant": "ng",
        "rhyme": "ăn"
    },
    {
        "id": 237,
        "syllable": "ngoăn",
        "consonant": "ng",
        "rhyme": "oăn"
    },
    {
        "id": 238,
        "syllable": "ngăp",
        "consonant": "ng",
        "rhyme": "ăp"
    },
    {
        "id": 239,
        "syllable": "ngoăp",
        "consonant": "ng",
        "rhyme": "oăp"
    },
    {
        "id": 240,
        "syllable": "ngăm",
        "consonant": "ng",
        "rhyme": "ăm"
    },
    {
        "id": 241,
        "syllable": "ngoăm",
        "consonant": "ng",
        "rhyme": "oăm"
    },
    {
        "id": 242,
        "syllable": "ngay",
        "consonant": "ng",
        "rhyme": "ay"
    },
    {
        "id": 243,
        "syllable": "ngoay",
        "consonant": "ng",
        "rhyme": "oay"
    },
    {
        "id": 244,
        "syllable": "ngau",
        "consonant": "ng",
        "rhyme": "au"
    },
    {
        "id": 245,
        "syllable": "ngoau",
        "consonant": "ng",
        "rhyme": "oau"
    },
    {
        "id": 246,
        "syllable": "gach",
        "consonant": "g",
        "rhyme": "ach"
    },
    {
        "id": 247,
        "syllable": "goach",
        "consonant": "g",
        "rhyme": "oach"
    },
    {
        "id": 248,
        "syllable": "ganh",
        "consonant": "g",
        "rhyme": "anh"
    },
    {
        "id": 249,
        "syllable": "goanh",
        "consonant": "g",
        "rhyme": "oanh"
    },
    {
        "id": 250,
        "syllable": "gia",
        "consonant": "g",
        "rhyme": "ia"
    },
    {
        "id": 251,
        "syllable": "guya",
        "consonant": "g",
        "rhyme": "uya"
    },
    {
        "id": 252,
        "syllable": "giêc",
        "consonant": "g",
        "rhyme": "iêc"
    },
    {
        "id": 253,
        "syllable": "giêng",
        "consonant": "g",
        "rhyme": "iêng"
    },
    {
        "id": 254,
        "syllable": "giêt",
        "consonant": "g",
        "rhyme": "iêt"
    },
    {
        "id": 255,
        "syllable": "guyêt",
        "consonant": "g",
        "rhyme": "uyêt"
    },
    {
        "id": 256,
        "syllable": "giên",
        "consonant": "g",
        "rhyme": "iên"
    },
    {
        "id": 257,
        "syllable": "guyên",
        "consonant": "g",
        "rhyme": "uyên"
    },
    {
        "id": 258,
        "syllable": "giêp",
        "consonant": "g",
        "rhyme": "iêp"
    },
    {
        "id": 259,
        "syllable": "guyêp",
        "consonant": "g",
        "rhyme": "uyêp"
    },
    {
        "id": 260,
        "syllable": "giêm",
        "consonant": "g",
        "rhyme": "iêm"
    },
    {
        "id": 261,
        "syllable": "guyêm",
        "consonant": "g",
        "rhyme": "uyêm"
    },
    {
        "id": 262,
        "syllable": "giêu",
        "consonant": "g",
        "rhyme": "iêu"
    },
    {
        "id": 263,
        "syllable": "gư",
        "consonant": "g",
        "rhyme": "ư"
    },
    {
        "id": 264,
        "syllable": "gưc",
        "consonant": "g",
        "rhyme": "ưc"
    },
    {
        "id": 265,
        "syllable": "gưng",
        "consonant": "g",
        "rhyme": "ưng"
    },
    {
        "id": 266,
        "syllable": "gưt",
        "consonant": "g",
        "rhyme": "ưt"
    },
    {
        "id": 267,
        "syllable": "gưn",
        "consonant": "g",
        "rhyme": "ưn"
    },
    {
        "id": 268,
        "syllable": "gưp",
        "consonant": "g",
        "rhyme": "ưp"
    },
    {
        "id": 269,
        "syllable": "gưm",
        "consonant": "g",
        "rhyme": "ưm"
    },
    {
        "id": 270,
        "syllable": "gưi",
        "consonant": "g",
        "rhyme": "ưi"
    },
    {
        "id": 271,
        "syllable": "gưu",
        "consonant": "g",
        "rhyme": "ưu"
    },
    {
        "id": 272,
        "syllable": "gơ",
        "consonant": "g",
        "rhyme": "ơ"
    },
    {
        "id": 273,
        "syllable": "guơ",
        "consonant": "g",
        "rhyme": "uơ"
    },
    {
        "id": 274,
        "syllable": "gơng",
        "consonant": "g",
        "rhyme": "ơng"
    },
    {
        "id": 275,
        "syllable": "gơt",
        "consonant": "g",
        "rhyme": "ơt"
    },
    {
        "id": 276,
        "syllable": "gơn",
        "consonant": "g",
        "rhyme": "ơn"
    },
    {
        "id": 277,
        "syllable": "gơp",
        "consonant": "g",
        "rhyme": "ơp"
    },
    {
        "id": 278,
        "syllable": "gơm",
        "consonant": "g",
        "rhyme": "ơm"
    },
    {
        "id": 279,
        "syllable": "gơi",
        "consonant": "g",
        "rhyme": "ơi"
    },
    {
        "id": 280,
        "syllable": "gâc",
        "consonant": "g",
        "rhyme": "âc"
    },
    {
        "id": 281,
        "syllable": "gâng",
        "consonant": "g",
        "rhyme": "âng"
    },
    {
        "id": 282,
        "syllable": "guâng",
        "consonant": "g",
        "rhyme": "uâng"
    },
    {
        "id": 283,
        "syllable": "gât",
        "consonant": "g",
        "rhyme": "ât"
    },
    {
        "id": 284,
        "syllable": "guât",
        "consonant": "g",
        "rhyme": "uât"
    },
    {
        "id": 285,
        "syllable": "gân",
        "consonant": "g",
        "rhyme": "ân"
    },
    {
        "id": 286,
        "syllable": "guân",
        "consonant": "g",
        "rhyme": "uân"
    },
    {
        "id": 287,
        "syllable": "gâp",
        "consonant": "g",
        "rhyme": "âp"
    },
    {
        "id": 288,
        "syllable": "guâp",
        "consonant": "g",
        "rhyme": "uâp"
    },
    {
        "id": 289,
        "syllable": "gâm",
        "consonant": "g",
        "rhyme": "âm"
    },
    {
        "id": 290,
        "syllable": "guâm",
        "consonant": "g",
        "rhyme": "uâm"
    },
    {
        "id": 291,
        "syllable": "gây",
        "consonant": "g",
        "rhyme": "ây"
    },
    {
        "id": 292,
        "syllable": "guây",
        "consonant": "g",
        "rhyme": "uây"
    },
    {
        "id": 293,
        "syllable": "gâu",
        "consonant": "g",
        "rhyme": "âu"
    },
    {
        "id": 294,
        "syllable": "guâu",
        "consonant": "g",
        "rhyme": "uâu"
    },
    {
        "id": 295,
        "syllable": "gu",
        "consonant": "g",
        "rhyme": "u"
    },
    {
        "id": 296,
        "syllable": "guc",
        "consonant": "g",
        "rhyme": "uc"
    },
    {
        "id": 297,
        "syllable": "gung",
        "consonant": "g",
        "rhyme": "ung"
    },
    {
        "id": 298,
        "syllable": "gut",
        "consonant": "g",
        "rhyme": "ut"
    },
    {
        "id": 299,
        "syllable": "gun",
        "consonant": "g",
        "rhyme": "un"
    },
    {
        "id": 300,
        "syllable": "gup",
        "consonant": "g",
        "rhyme": "up"
    },
    {
        "id": 301,
        "syllable": "gum",
        "consonant": "g",
        "rhyme": "um"
    },
    {
        "id": 302,
        "syllable": "gui",
        "consonant": "g",
        "rhyme": "ui"
    },
    {
        "id": 303,
        "syllable": "gô",
        "consonant": "g",
        "rhyme": "ô"
    },
    {
        "id": 304,
        "syllable": "gôc",
        "consonant": "g",
        "rhyme": "ôc"
    },
    {
        "id": 305,
        "syllable": "gông",
        "consonant": "g",
        "rhyme": "ông"
    },
    {
        "id": 306,
        "syllable": "gôt",
        "consonant": "g",
        "rhyme": "ôt"
    },
    {
        "id": 307,
        "syllable": "gôn",
        "consonant": "g",
        "rhyme": "ôn"
    },
    {
        "id": 308,
        "syllable": "gôp",
        "consonant": "g",
        "rhyme": "ôp"
    },
    {
        "id": 309,
        "syllable": "gôm",
        "consonant": "g",
        "rhyme": "ôm"
    },
    {
        "id": 310,
        "syllable": "gôi",
        "consonant": "g",
        "rhyme": "ôi"
    },
    {
        "id": 311,
        "syllable": "go",
        "consonant": "g",
        "rhyme": "o"
    },
    {
        "id": 312,
        "syllable": "gooc",
        "consonant": "g",
        "rhyme": "ooc"
    },
    {
        "id": 313,
        "syllable": "goong",
        "consonant": "g",
        "rhyme": "oong"
    },
    {
        "id": 314,
        "syllable": "got",
        "consonant": "g",
        "rhyme": "ot"
    },
    {
        "id": 315,
        "syllable": "gon",
        "consonant": "g",
        "rhyme": "on"
    },
    {
        "id": 316,
        "syllable": "gop",
        "consonant": "g",
        "rhyme": "op"
    },
    {
        "id": 317,
        "syllable": "gom",
        "consonant": "g",
        "rhyme": "om"
    },
    {
        "id": 318,
        "syllable": "goi",
        "consonant": "g",
        "rhyme": "oi"
    },
    {
        "id": 319,
        "syllable": "goc",
        "consonant": "g",
        "rhyme": "oc"
    },
    {
        "id": 320,
        "syllable": "gong",
        "consonant": "g",
        "rhyme": "ong"
    },
    {
        "id": 321,
        "syllable": "gua",
        "consonant": "g",
        "rhyme": "ua"
    },
    {
        "id": 322,
        "syllable": "guôc",
        "consonant": "g",
        "rhyme": "uôc"
    },
    {
        "id": 323,
        "syllable": "guông",
        "consonant": "g",
        "rhyme": "uông"
    },
    {
        "id": 324,
        "syllable": "guôt",
        "consonant": "g",
        "rhyme": "uôt"
    },
    {
        "id": 325,
        "syllable": "guôn",
        "consonant": "g",
        "rhyme": "uôn"
    },
    {
        "id": 326,
        "syllable": "guôp",
        "consonant": "g",
        "rhyme": "uôp"
    },
    {
        "id": 327,
        "syllable": "guôm",
        "consonant": "g",
        "rhyme": "uôm"
    },
    {
        "id": 328,
        "syllable": "guôi",
        "consonant": "g",
        "rhyme": "uôi"
    },
    {
        "id": 329,
        "syllable": "gưa",
        "consonant": "g",
        "rhyme": "ưa"
    },
    {
        "id": 330,
        "syllable": "gươc",
        "consonant": "g",
        "rhyme": "ươc"
    },
    {
        "id": 331,
        "syllable": "gương",
        "consonant": "g",
        "rhyme": "ương"
    },
    {
        "id": 332,
        "syllable": "gươt",
        "consonant": "g",
        "rhyme": "ươt"
    },
    {
        "id": 333,
        "syllable": "gươn",
        "consonant": "g",
        "rhyme": "ươn"
    },
    {
        "id": 334,
        "syllable": "gươp",
        "consonant": "g",
        "rhyme": "ươp"
    },
    {
        "id": 335,
        "syllable": "gươm",
        "consonant": "g",
        "rhyme": "ươm"
    },
    {
        "id": 336,
        "syllable": "gươi",
        "consonant": "g",
        "rhyme": "ươi"
    },
    {
        "id": 337,
        "syllable": "ga",
        "consonant": "g",
        "rhyme": "a"
    },
    {
        "id": 338,
        "syllable": "goa",
        "consonant": "g",
        "rhyme": "oa"
    },
    {
        "id": 339,
        "syllable": "gac",
        "consonant": "g",
        "rhyme": "ac"
    },
    {
        "id": 340,
        "syllable": "goac",
        "consonant": "g",
        "rhyme": "oac"
    },
    {
        "id": 341,
        "syllable": "gang",
        "consonant": "g",
        "rhyme": "ang"
    },
    {
        "id": 342,
        "syllable": "goang",
        "consonant": "g",
        "rhyme": "oang"
    },
    {
        "id": 343,
        "syllable": "gat",
        "consonant": "g",
        "rhyme": "at"
    },
    {
        "id": 344,
        "syllable": "goat",
        "consonant": "g",
        "rhyme": "oat"
    },
    {
        "id": 345,
        "syllable": "gan",
        "consonant": "g",
        "rhyme": "an"
    },
    {
        "id": 346,
        "syllable": "goan",
        "consonant": "g",
        "rhyme": "oan"
    },
    {
        "id": 347,
        "syllable": "gap",
        "consonant": "g",
        "rhyme": "ap"
    },
    {
        "id": 348,
        "syllable": "goap",
        "consonant": "g",
        "rhyme": "oap"
    },
    {
        "id": 349,
        "syllable": "gam",
        "consonant": "g",
        "rhyme": "am"
    },
    {
        "id": 350,
        "syllable": "goam",
        "consonant": "g",
        "rhyme": "oam"
    },
    {
        "id": 351,
        "syllable": "gai",
        "consonant": "g",
        "rhyme": "ai"
    },
    {
        "id": 352,
        "syllable": "goai",
        "consonant": "g",
        "rhyme": "oai"
    },
    {
        "id": 353,
        "syllable": "gao",
        "consonant": "g",
        "rhyme": "ao"
    },
    {
        "id": 354,
        "syllable": "goao",
        "consonant": "g",
        "rhyme": "oao"
    },
    {
        "id": 355,
        "syllable": "găc",
        "consonant": "g",
        "rhyme": "ăc"
    },
    {
        "id": 356,
        "syllable": "goăc",
        "consonant": "g",
        "rhyme": "oăc"
    },
    {
        "id": 357,
        "syllable": "găng",
        "consonant": "g",
        "rhyme": "ăng"
    },
    {
        "id": 358,
        "syllable": "goăng",
        "consonant": "g",
        "rhyme": "oăng"
    },
    {
        "id": 359,
        "syllable": "găt",
        "consonant": "g",
        "rhyme": "ăt"
    },
    {
        "id": 360,
        "syllable": "goăt",
        "consonant": "g",
        "rhyme": "oăt"
    },
    {
        "id": 361,
        "syllable": "găn",
        "consonant": "g",
        "rhyme": "ăn"
    },
    {
        "id": 362,
        "syllable": "goăn",
        "consonant": "g",
        "rhyme": "oăn"
    },
    {
        "id": 363,
        "syllable": "găp",
        "consonant": "g",
        "rhyme": "ăp"
    },
    {
        "id": 364,
        "syllable": "goăp",
        "consonant": "g",
        "rhyme": "oăp"
    },
    {
        "id": 365,
        "syllable": "găm",
        "consonant": "g",
        "rhyme": "ăm"
    },
    {
        "id": 366,
        "syllable": "goăm",
        "consonant": "g",
        "rhyme": "oăm"
    },
    {
        "id": 367,
        "syllable": "gay",
        "consonant": "g",
        "rhyme": "ay"
    },
    {
        "id": 368,
        "syllable": "goay",
        "consonant": "g",
        "rhyme": "oay"
    },
    {
        "id": 369,
        "syllable": "gau",
        "consonant": "g",
        "rhyme": "au"
    },
    {
        "id": 370,
        "syllable": "goau",
        "consonant": "g",
        "rhyme": "oau"
    },
    {
        "id": 371,
        "syllable": "ki",
        "consonant": "k",
        "rhyme": "i"
    },
    {
        "id": 372,
        "syllable": "kich",
        "consonant": "k",
        "rhyme": "ich"
    },
    {
        "id": 373,
        "syllable": "kinh",
        "consonant": "k",
        "rhyme": "inh"
    },
    {
        "id": 374,
        "syllable": "kit",
        "consonant": "k",
        "rhyme": "it"
    },
    {
        "id": 375,
        "syllable": "kin",
        "consonant": "k",
        "rhyme": "in"
    },
    {
        "id": 376,
        "syllable": "kip",
        "consonant": "k",
        "rhyme": "ip"
    },
    {
        "id": 377,
        "syllable": "kim",
        "consonant": "k",
        "rhyme": "im"
    },
    {
        "id": 378,
        "syllable": "kiu",
        "consonant": "k",
        "rhyme": "iu"
    },
    {
        "id": 379,
        "syllable": "kê",
        "consonant": "k",
        "rhyme": "ê"
    },
    {
        "id": 380,
        "syllable": "kêch",
        "consonant": "k",
        "rhyme": "êch"
    },
    {
        "id": 381,
        "syllable": "kênh",
        "consonant": "k",
        "rhyme": "ênh"
    },
    {
        "id": 382,
        "syllable": "kêt",
        "consonant": "k",
        "rhyme": "êt"
    },
    {
        "id": 383,
        "syllable": "kên",
        "consonant": "k",
        "rhyme": "ên"
    },
    {
        "id": 384,
        "syllable": "kêp",
        "consonant": "k",
        "rhyme": "êp"
    },
    {
        "id": 385,
        "syllable": "kêm",
        "consonant": "k",
        "rhyme": "êm"
    },
    {
        "id": 386,
        "syllable": "kêu",
        "consonant": "k",
        "rhyme": "êu"
    },
    {
        "id": 387,
        "syllable": "ke",
        "consonant": "k",
        "rhyme": "e"
    },
    {
        "id": 388,
        "syllable": "kec",
        "consonant": "k",
        "rhyme": "ec"
    },
    {
        "id": 389,
        "syllable": "keng",
        "consonant": "k",
        "rhyme": "eng"
    },
    {
        "id": 390,
        "syllable": "ket",
        "consonant": "k",
        "rhyme": "et"
    },
    {
        "id": 391,
        "syllable": "ken",
        "consonant": "k",
        "rhyme": "en"
    },
    {
        "id": 392,
        "syllable": "kep",
        "consonant": "k",
        "rhyme": "ep"
    },
    {
        "id": 393,
        "syllable": "kem",
        "consonant": "k",
        "rhyme": "em"
    },
    {
        "id": 394,
        "syllable": "keo",
        "consonant": "k",
        "rhyme": "eo"
    },
    {
        "id": 395,
        "syllable": "cach",
        "consonant": "c",
        "rhyme": "ach"
    },
    {
        "id": 396,
        "syllable": "canh",
        "consonant": "c",
        "rhyme": "anh"
    },
    {
        "id": 397,
        "syllable": "cia",
        "consonant": "c",
        "rhyme": "ia"
    },
    {
        "id": 398,
        "syllable": "ciêc",
        "consonant": "c",
        "rhyme": "iêc"
    },
    {
        "id": 399,
        "syllable": "ciêng",
        "consonant": "c",
        "rhyme": "iêng"
    },
    {
        "id": 400,
        "syllable": "ciêt",
        "consonant": "c",
        "rhyme": "iêt"
    },
    {
        "id": 401,
        "syllable": "ciên",
        "consonant": "c",
        "rhyme": "iên"
    },
    {
        "id": 402,
        "syllable": "ciêp",
        "consonant": "c",
        "rhyme": "iêp"
    },
    {
        "id": 403,
        "syllable": "ciêm",
        "consonant": "c",
        "rhyme": "iêm"
    },
    {
        "id": 404,
        "syllable": "ciêu",
        "consonant": "c",
        "rhyme": "iêu"
    },
    {
        "id": 405,
        "syllable": "cư",
        "consonant": "c",
        "rhyme": "ư"
    },
    {
        "id": 406,
        "syllable": "cưc",
        "consonant": "c",
        "rhyme": "ưc"
    },
    {
        "id": 407,
        "syllable": "cưng",
        "consonant": "c",
        "rhyme": "ưng"
    },
    {
        "id": 408,
        "syllable": "cưt",
        "consonant": "c",
        "rhyme": "ưt"
    },
    {
        "id": 409,
        "syllable": "cưn",
        "consonant": "c",
        "rhyme": "ưn"
    },
    {
        "id": 410,
        "syllable": "cưp",
        "consonant": "c",
        "rhyme": "ưp"
    },
    {
        "id": 411,
        "syllable": "cưm",
        "consonant": "c",
        "rhyme": "ưm"
    },
    {
        "id": 412,
        "syllable": "cưi",
        "consonant": "c",
        "rhyme": "ưi"
    },
    {
        "id": 413,
        "syllable": "cưu",
        "consonant": "c",
        "rhyme": "ưu"
    },
    {
        "id": 414,
        "syllable": "cơ",
        "consonant": "c",
        "rhyme": "ơ"
    },
    {
        "id": 415,
        "syllable": "cơng",
        "consonant": "c",
        "rhyme": "ơng"
    },
    {
        "id": 416,
        "syllable": "cơt",
        "consonant": "c",
        "rhyme": "ơt"
    },
    {
        "id": 417,
        "syllable": "cơn",
        "consonant": "c",
        "rhyme": "ơn"
    },
    {
        "id": 418,
        "syllable": "cơp",
        "consonant": "c",
        "rhyme": "ơp"
    },
    {
        "id": 419,
        "syllable": "cơm",
        "consonant": "c",
        "rhyme": "ơm"
    },
    {
        "id": 420,
        "syllable": "cơi",
        "consonant": "c",
        "rhyme": "ơi"
    },
    {
        "id": 421,
        "syllable": "câc",
        "consonant": "c",
        "rhyme": "âc"
    },
    {
        "id": 422,
        "syllable": "câng",
        "consonant": "c",
        "rhyme": "âng"
    },
    {
        "id": 423,
        "syllable": "cât",
        "consonant": "c",
        "rhyme": "ât"
    },
    {
        "id": 424,
        "syllable": "cân",
        "consonant": "c",
        "rhyme": "ân"
    },
    {
        "id": 425,
        "syllable": "câp",
        "consonant": "c",
        "rhyme": "âp"
    },
    {
        "id": 426,
        "syllable": "câm",
        "consonant": "c",
        "rhyme": "âm"
    },
    {
        "id": 427,
        "syllable": "cây",
        "consonant": "c",
        "rhyme": "ây"
    },
    {
        "id": 428,
        "syllable": "câu",
        "consonant": "c",
        "rhyme": "âu"
    },
    {
        "id": 429,
        "syllable": "cu",
        "consonant": "c",
        "rhyme": "u"
    },
    {
        "id": 430,
        "syllable": "cuc",
        "consonant": "c",
        "rhyme": "uc"
    },
    {
        "id": 431,
        "syllable": "cung",
        "consonant": "c",
        "rhyme": "ung"
    },
    {
        "id": 432,
        "syllable": "cut",
        "consonant": "c",
        "rhyme": "ut"
    },
    {
        "id": 433,
        "syllable": "cun",
        "consonant": "c",
        "rhyme": "un"
    },
    {
        "id": 434,
        "syllable": "cup",
        "consonant": "c",
        "rhyme": "up"
    },
    {
        "id": 435,
        "syllable": "cum",
        "consonant": "c",
        "rhyme": "um"
    },
    {
        "id": 436,
        "syllable": "cui",
        "consonant": "c",
        "rhyme": "ui"
    },
    {
        "id": 437,
        "syllable": "cô",
        "consonant": "c",
        "rhyme": "ô"
    },
    {
        "id": 438,
        "syllable": "côc",
        "consonant": "c",
        "rhyme": "ôc"
    },
    {
        "id": 439,
        "syllable": "công",
        "consonant": "c",
        "rhyme": "ông"
    },
    {
        "id": 440,
        "syllable": "côt",
        "consonant": "c",
        "rhyme": "ôt"
    },
    {
        "id": 441,
        "syllable": "côn",
        "consonant": "c",
        "rhyme": "ôn"
    },
    {
        "id": 442,
        "syllable": "côp",
        "consonant": "c",
        "rhyme": "ôp"
    },
    {
        "id": 443,
        "syllable": "côm",
        "consonant": "c",
        "rhyme": "ôm"
    },
    {
        "id": 444,
        "syllable": "côi",
        "consonant": "c",
        "rhyme": "ôi"
    },
    {
        "id": 445,
        "syllable": "co",
        "consonant": "c",
        "rhyme": "o"
    },
    {
        "id": 446,
        "syllable": "cooc",
        "consonant": "c",
        "rhyme": "ooc"
    },
    {
        "id": 447,
        "syllable": "coong",
        "consonant": "c",
        "rhyme": "oong"
    },
    {
        "id": 448,
        "syllable": "cot",
        "consonant": "c",
        "rhyme": "ot"
    },
    {
        "id": 449,
        "syllable": "con",
        "consonant": "c",
        "rhyme": "on"
    },
    {
        "id": 450,
        "syllable": "cop",
        "consonant": "c",
        "rhyme": "op"
    },
    {
        "id": 451,
        "syllable": "com",
        "consonant": "c",
        "rhyme": "om"
    },
    {
        "id": 452,
        "syllable": "coi",
        "consonant": "c",
        "rhyme": "oi"
    },
    {
        "id": 453,
        "syllable": "coc",
        "consonant": "c",
        "rhyme": "oc"
    },
    {
        "id": 454,
        "syllable": "cong",
        "consonant": "c",
        "rhyme": "ong"
    },
    {
        "id": 455,
        "syllable": "cua",
        "consonant": "c",
        "rhyme": "ua"
    },
    {
        "id": 456,
        "syllable": "cuôc",
        "consonant": "c",
        "rhyme": "uôc"
    },
    {
        "id": 457,
        "syllable": "cuông",
        "consonant": "c",
        "rhyme": "uông"
    },
    {
        "id": 458,
        "syllable": "cuôt",
        "consonant": "c",
        "rhyme": "uôt"
    },
    {
        "id": 459,
        "syllable": "cuôn",
        "consonant": "c",
        "rhyme": "uôn"
    },
    {
        "id": 460,
        "syllable": "cuôp",
        "consonant": "c",
        "rhyme": "uôp"
    },
    {
        "id": 461,
        "syllable": "cuôm",
        "consonant": "c",
        "rhyme": "uôm"
    },
    {
        "id": 462,
        "syllable": "cuôi",
        "consonant": "c",
        "rhyme": "uôi"
    },
    {
        "id": 463,
        "syllable": "cưa",
        "consonant": "c",
        "rhyme": "ưa"
    },
    {
        "id": 464,
        "syllable": "cươc",
        "consonant": "c",
        "rhyme": "ươc"
    },
    {
        "id": 465,
        "syllable": "cương",
        "consonant": "c",
        "rhyme": "ương"
    },
    {
        "id": 466,
        "syllable": "cươt",
        "consonant": "c",
        "rhyme": "ươt"
    },
    {
        "id": 467,
        "syllable": "cươn",
        "consonant": "c",
        "rhyme": "ươn"
    },
    {
        "id": 468,
        "syllable": "cươp",
        "consonant": "c",
        "rhyme": "ươp"
    },
    {
        "id": 469,
        "syllable": "cươm",
        "consonant": "c",
        "rhyme": "ươm"
    },
    {
        "id": 470,
        "syllable": "cươi",
        "consonant": "c",
        "rhyme": "ươi"
    },
    {
        "id": 471,
        "syllable": "ca",
        "consonant": "c",
        "rhyme": "a"
    },
    {
        "id": 472,
        "syllable": "cac",
        "consonant": "c",
        "rhyme": "ac"
    },
    {
        "id": 473,
        "syllable": "cang",
        "consonant": "c",
        "rhyme": "ang"
    },
    {
        "id": 474,
        "syllable": "cat",
        "consonant": "c",
        "rhyme": "at"
    },
    {
        "id": 475,
        "syllable": "can",
        "consonant": "c",
        "rhyme": "an"
    },
    {
        "id": 476,
        "syllable": "cap",
        "consonant": "c",
        "rhyme": "ap"
    },
    {
        "id": 477,
        "syllable": "cam",
        "consonant": "c",
        "rhyme": "am"
    },
    {
        "id": 478,
        "syllable": "cai",
        "consonant": "c",
        "rhyme": "ai"
    },
    {
        "id": 479,
        "syllable": "cao",
        "consonant": "c",
        "rhyme": "ao"
    },
    {
        "id": 480,
        "syllable": "căc",
        "consonant": "c",
        "rhyme": "ăc"
    },
    {
        "id": 481,
        "syllable": "căng",
        "consonant": "c",
        "rhyme": "ăng"
    },
    {
        "id": 482,
        "syllable": "căt",
        "consonant": "c",
        "rhyme": "ăt"
    },
    {
        "id": 483,
        "syllable": "căn",
        "consonant": "c",
        "rhyme": "ăn"
    },
    {
        "id": 484,
        "syllable": "căp",
        "consonant": "c",
        "rhyme": "ăp"
    },
    {
        "id": 485,
        "syllable": "căm",
        "consonant": "c",
        "rhyme": "ăm"
    },
    {
        "id": 486,
        "syllable": "cay",
        "consonant": "c",
        "rhyme": "ay"
    },
    {
        "id": 487,
        "syllable": "cau",
        "consonant": "c",
        "rhyme": "au"
    },
    {
        "id": 488,
        "syllable": "bi",
        "consonant": "b",
        "rhyme": "i"
    },
    {
        "id": 489,
        "syllable": "buy",
        "consonant": "b",
        "rhyme": "uy"
    },
    {
        "id": 490,
        "syllable": "bich",
        "consonant": "b",
        "rhyme": "ich"
    },
    {
        "id": 491,
        "syllable": "buych",
        "consonant": "b",
        "rhyme": "uych"
    },
    {
        "id": 492,
        "syllable": "binh",
        "consonant": "b",
        "rhyme": "inh"
    },
    {
        "id": 493,
        "syllable": "buynh",
        "consonant": "b",
        "rhyme": "uynh"
    },
    {
        "id": 494,
        "syllable": "bit",
        "consonant": "b",
        "rhyme": "it"
    },
    {
        "id": 495,
        "syllable": "buyt",
        "consonant": "b",
        "rhyme": "uyt"
    },
    {
        "id": 496,
        "syllable": "bin",
        "consonant": "b",
        "rhyme": "in"
    },
    {
        "id": 497,
        "syllable": "buyn",
        "consonant": "b",
        "rhyme": "uyn"
    },
    {
        "id": 498,
        "syllable": "bip",
        "consonant": "b",
        "rhyme": "ip"
    },
    {
        "id": 499,
        "syllable": "buyp",
        "consonant": "b",
        "rhyme": "uyp"
    },
    {
        "id": 500,
        "syllable": "bim",
        "consonant": "b",
        "rhyme": "im"
    },
    {
        "id": 501,
        "syllable": "buym",
        "consonant": "b",
        "rhyme": "uym"
    },
    {
        "id": 502,
        "syllable": "biu",
        "consonant": "b",
        "rhyme": "iu"
    },
    {
        "id": 503,
        "syllable": "buyu",
        "consonant": "b",
        "rhyme": "uyu"
    },
    {
        "id": 504,
        "syllable": "bê",
        "consonant": "b",
        "rhyme": "ê"
    },
    {
        "id": 505,
        "syllable": "buê",
        "consonant": "b",
        "rhyme": "uê"
    },
    {
        "id": 506,
        "syllable": "bêch",
        "consonant": "b",
        "rhyme": "êch"
    },
    {
        "id": 507,
        "syllable": "buêch",
        "consonant": "b",
        "rhyme": "uêch"
    },
    {
        "id": 508,
        "syllable": "bênh",
        "consonant": "b",
        "rhyme": "ênh"
    },
    {
        "id": 509,
        "syllable": "buênh",
        "consonant": "b",
        "rhyme": "uênh"
    },
    {
        "id": 510,
        "syllable": "bêt",
        "consonant": "b",
        "rhyme": "êt"
    },
    {
        "id": 511,
        "syllable": "buêt",
        "consonant": "b",
        "rhyme": "uêt"
    },
    {
        "id": 512,
        "syllable": "bên",
        "consonant": "b",
        "rhyme": "ên"
    },
    {
        "id": 513,
        "syllable": "buên",
        "consonant": "b",
        "rhyme": "uên"
    },
    {
        "id": 514,
        "syllable": "bêp",
        "consonant": "b",
        "rhyme": "êp"
    },
    {
        "id": 515,
        "syllable": "buêp",
        "consonant": "b",
        "rhyme": "uêp"
    },
    {
        "id": 516,
        "syllable": "bêm",
        "consonant": "b",
        "rhyme": "êm"
    },
    {
        "id": 517,
        "syllable": "buêm",
        "consonant": "b",
        "rhyme": "uêm"
    },
    {
        "id": 518,
        "syllable": "bêu",
        "consonant": "b",
        "rhyme": "êu"
    },
    {
        "id": 519,
        "syllable": "be",
        "consonant": "b",
        "rhyme": "e"
    },
    {
        "id": 520,
        "syllable": "boe",
        "consonant": "b",
        "rhyme": "oe"
    },
    {
        "id": 521,
        "syllable": "bec",
        "consonant": "b",
        "rhyme": "ec"
    },
    {
        "id": 522,
        "syllable": "boec",
        "consonant": "b",
        "rhyme": "oec"
    },
    {
        "id": 523,
        "syllable": "beng",
        "consonant": "b",
        "rhyme": "eng"
    },
    {
        "id": 524,
        "syllable": "boeng",
        "consonant": "b",
        "rhyme": "oeng"
    },
    {
        "id": 525,
        "syllable": "bet",
        "consonant": "b",
        "rhyme": "et"
    },
    {
        "id": 526,
        "syllable": "boet",
        "consonant": "b",
        "rhyme": "oet"
    },
    {
        "id": 527,
        "syllable": "ben",
        "consonant": "b",
        "rhyme": "en"
    },
    {
        "id": 528,
        "syllable": "boen",
        "consonant": "b",
        "rhyme": "oen"
    },
    {
        "id": 529,
        "syllable": "bep",
        "consonant": "b",
        "rhyme": "ep"
    },
    {
        "id": 530,
        "syllable": "boep",
        "consonant": "b",
        "rhyme": "oep"
    },
    {
        "id": 531,
        "syllable": "bem",
        "consonant": "b",
        "rhyme": "em"
    },
    {
        "id": 532,
        "syllable": "boem",
        "consonant": "b",
        "rhyme": "oem"
    },
    {
        "id": 533,
        "syllable": "beo",
        "consonant": "b",
        "rhyme": "eo"
    },
    {
        "id": 534,
        "syllable": "boeo",
        "consonant": "b",
        "rhyme": "oeo"
    },
    {
        "id": 535,
        "syllable": "bach",
        "consonant": "b",
        "rhyme": "ach"
    },
    {
        "id": 536,
        "syllable": "boach",
        "consonant": "b",
        "rhyme": "oach"
    },
    {
        "id": 537,
        "syllable": "banh",
        "consonant": "b",
        "rhyme": "anh"
    },
    {
        "id": 538,
        "syllable": "boanh",
        "consonant": "b",
        "rhyme": "oanh"
    },
    {
        "id": 539,
        "syllable": "bia",
        "consonant": "b",
        "rhyme": "ia"
    },
    {
        "id": 540,
        "syllable": "buya",
        "consonant": "b",
        "rhyme": "uya"
    },
    {
        "id": 541,
        "syllable": "biêc",
        "consonant": "b",
        "rhyme": "iêc"
    },
    {
        "id": 542,
        "syllable": "biêng",
        "consonant": "b",
        "rhyme": "iêng"
    },
    {
        "id": 543,
        "syllable": "biêt",
        "consonant": "b",
        "rhyme": "iêt"
    },
    {
        "id": 544,
        "syllable": "biên",
        "consonant": "b",
        "rhyme": "iên"
    },
    {
        "id": 545,
        "syllable": "buyêt",
        "consonant": "b",
        "rhyme": "uyêt"
    },
    {
        "id": 546,
        "syllable": "buyên",
        "consonant": "b",
        "rhyme": "uyên"
    },
    {
        "id": 547,
        "syllable": "biêp",
        "consonant": "b",
        "rhyme": "iêp"
    },
    {
        "id": 548,
        "syllable": "buyêp",
        "consonant": "b",
        "rhyme": "uyêp"
    },
    {
        "id": 549,
        "syllable": "biêm",
        "consonant": "b",
        "rhyme": "iêm"
    },
    {
        "id": 550,
        "syllable": "buyêm",
        "consonant": "b",
        "rhyme": "uyêm"
    },
    {
        "id": 551,
        "syllable": "biêu",
        "consonant": "b",
        "rhyme": "iêu"
    },
    {
        "id": 552,
        "syllable": "bư",
        "consonant": "b",
        "rhyme": "ư"
    },
    {
        "id": 553,
        "syllable": "bưc",
        "consonant": "b",
        "rhyme": "ưc"
    },
    {
        "id": 554,
        "syllable": "bưng",
        "consonant": "b",
        "rhyme": "ưng"
    },
    {
        "id": 555,
        "syllable": "bưt",
        "consonant": "b",
        "rhyme": "ưt"
    },
    {
        "id": 556,
        "syllable": "bưn",
        "consonant": "b",
        "rhyme": "ưn"
    },
    {
        "id": 557,
        "syllable": "bưp",
        "consonant": "b",
        "rhyme": "ưp"
    },
    {
        "id": 558,
        "syllable": "bưm",
        "consonant": "b",
        "rhyme": "ưm"
    },
    {
        "id": 559,
        "syllable": "bưi",
        "consonant": "b",
        "rhyme": "ưi"
    },
    {
        "id": 560,
        "syllable": "bưu",
        "consonant": "b",
        "rhyme": "ưu"
    },
    {
        "id": 561,
        "syllable": "bơ",
        "consonant": "b",
        "rhyme": "ơ"
    },
    {
        "id": 562,
        "syllable": "buơ",
        "consonant": "b",
        "rhyme": "uơ"
    },
    {
        "id": 563,
        "syllable": "bơng",
        "consonant": "b",
        "rhyme": "ơng"
    },
    {
        "id": 564,
        "syllable": "bơt",
        "consonant": "b",
        "rhyme": "ơt"
    },
    {
        "id": 565,
        "syllable": "bơn",
        "consonant": "b",
        "rhyme": "ơn"
    },
    {
        "id": 566,
        "syllable": "bơp",
        "consonant": "b",
        "rhyme": "ơp"
    },
    {
        "id": 567,
        "syllable": "bơm",
        "consonant": "b",
        "rhyme": "ơm"
    },
    {
        "id": 568,
        "syllable": "bơi",
        "consonant": "b",
        "rhyme": "ơi"
    },
    {
        "id": 569,
        "syllable": "bâc",
        "consonant": "b",
        "rhyme": "âc"
    },
    {
        "id": 570,
        "syllable": "bâng",
        "consonant": "b",
        "rhyme": "âng"
    },
    {
        "id": 571,
        "syllable": "buâng",
        "consonant": "b",
        "rhyme": "uâng"
    },
    {
        "id": 572,
        "syllable": "bât",
        "consonant": "b",
        "rhyme": "ât"
    },
    {
        "id": 573,
        "syllable": "buât",
        "consonant": "b",
        "rhyme": "uât"
    },
    {
        "id": 574,
        "syllable": "bân",
        "consonant": "b",
        "rhyme": "ân"
    },
    {
        "id": 575,
        "syllable": "buân",
        "consonant": "b",
        "rhyme": "uân"
    },
    {
        "id": 576,
        "syllable": "bâp",
        "consonant": "b",
        "rhyme": "âp"
    },
    {
        "id": 577,
        "syllable": "buâp",
        "consonant": "b",
        "rhyme": "uâp"
    },
    {
        "id": 578,
        "syllable": "bâm",
        "consonant": "b",
        "rhyme": "âm"
    },
    {
        "id": 579,
        "syllable": "buâm",
        "consonant": "b",
        "rhyme": "uâm"
    },
    {
        "id": 580,
        "syllable": "bây",
        "consonant": "b",
        "rhyme": "ây"
    },
    {
        "id": 581,
        "syllable": "buây",
        "consonant": "b",
        "rhyme": "uây"
    },
    {
        "id": 582,
        "syllable": "bâu",
        "consonant": "b",
        "rhyme": "âu"
    },
    {
        "id": 583,
        "syllable": "buâu",
        "consonant": "b",
        "rhyme": "uâu"
    },
    {
        "id": 584,
        "syllable": "bu",
        "consonant": "b",
        "rhyme": "u"
    },
    {
        "id": 585,
        "syllable": "buc",
        "consonant": "b",
        "rhyme": "uc"
    },
    {
        "id": 586,
        "syllable": "bung",
        "consonant": "b",
        "rhyme": "ung"
    },
    {
        "id": 587,
        "syllable": "but",
        "consonant": "b",
        "rhyme": "ut"
    },
    {
        "id": 588,
        "syllable": "bun",
        "consonant": "b",
        "rhyme": "un"
    },
    {
        "id": 589,
        "syllable": "bup",
        "consonant": "b",
        "rhyme": "up"
    },
    {
        "id": 590,
        "syllable": "bum",
        "consonant": "b",
        "rhyme": "um"
    },
    {
        "id": 591,
        "syllable": "bui",
        "consonant": "b",
        "rhyme": "ui"
    },
    {
        "id": 592,
        "syllable": "bô",
        "consonant": "b",
        "rhyme": "ô"
    },
    {
        "id": 593,
        "syllable": "bôc",
        "consonant": "b",
        "rhyme": "ôc"
    },
    {
        "id": 594,
        "syllable": "bông",
        "consonant": "b",
        "rhyme": "ông"
    },
    {
        "id": 595,
        "syllable": "bôt",
        "consonant": "b",
        "rhyme": "ôt"
    },
    {
        "id": 596,
        "syllable": "bôn",
        "consonant": "b",
        "rhyme": "ôn"
    },
    {
        "id": 597,
        "syllable": "bôp",
        "consonant": "b",
        "rhyme": "ôp"
    },
    {
        "id": 598,
        "syllable": "bôm",
        "consonant": "b",
        "rhyme": "ôm"
    },
    {
        "id": 599,
        "syllable": "bôi",
        "consonant": "b",
        "rhyme": "ôi"
    },
    {
        "id": 600,
        "syllable": "bo",
        "consonant": "b",
        "rhyme": "o"
    },
    {
        "id": 601,
        "syllable": "booc",
        "consonant": "b",
        "rhyme": "ooc"
    },
    {
        "id": 602,
        "syllable": "boong",
        "consonant": "b",
        "rhyme": "oong"
    },
    {
        "id": 603,
        "syllable": "bot",
        "consonant": "b",
        "rhyme": "ot"
    },
    {
        "id": 604,
        "syllable": "bon",
        "consonant": "b",
        "rhyme": "on"
    },
    {
        "id": 605,
        "syllable": "bop",
        "consonant": "b",
        "rhyme": "op"
    },
    {
        "id": 606,
        "syllable": "bom",
        "consonant": "b",
        "rhyme": "om"
    },
    {
        "id": 607,
        "syllable": "boi",
        "consonant": "b",
        "rhyme": "oi"
    },
    {
        "id": 608,
        "syllable": "boc",
        "consonant": "b",
        "rhyme": "oc"
    },
    {
        "id": 609,
        "syllable": "bong",
        "consonant": "b",
        "rhyme": "ong"
    },
    {
        "id": 610,
        "syllable": "bua",
        "consonant": "b",
        "rhyme": "ua"
    },
    {
        "id": 611,
        "syllable": "buôc",
        "consonant": "b",
        "rhyme": "uôc"
    },
    {
        "id": 612,
        "syllable": "buông",
        "consonant": "b",
        "rhyme": "uông"
    },
    {
        "id": 613,
        "syllable": "buôt",
        "consonant": "b",
        "rhyme": "uôt"
    },
    {
        "id": 614,
        "syllable": "buôn",
        "consonant": "b",
        "rhyme": "uôn"
    },
    {
        "id": 615,
        "syllable": "buôp",
        "consonant": "b",
        "rhyme": "uôp"
    },
    {
        "id": 616,
        "syllable": "buôm",
        "consonant": "b",
        "rhyme": "uôm"
    },
    {
        "id": 617,
        "syllable": "buôi",
        "consonant": "b",
        "rhyme": "uôi"
    },
    {
        "id": 618,
        "syllable": "bưa",
        "consonant": "b",
        "rhyme": "ưa"
    },
    {
        "id": 619,
        "syllable": "bươc",
        "consonant": "b",
        "rhyme": "ươc"
    },
    {
        "id": 620,
        "syllable": "bương",
        "consonant": "b",
        "rhyme": "ương"
    },
    {
        "id": 621,
        "syllable": "bươt",
        "consonant": "b",
        "rhyme": "ươt"
    },
    {
        "id": 622,
        "syllable": "bươn",
        "consonant": "b",
        "rhyme": "ươn"
    },
    {
        "id": 623,
        "syllable": "bươp",
        "consonant": "b",
        "rhyme": "ươp"
    },
    {
        "id": 624,
        "syllable": "bươm",
        "consonant": "b",
        "rhyme": "ươm"
    },
    {
        "id": 625,
        "syllable": "bươi",
        "consonant": "b",
        "rhyme": "ươi"
    },
    {
        "id": 626,
        "syllable": "ba",
        "consonant": "b",
        "rhyme": "a"
    },
    {
        "id": 627,
        "syllable": "boa",
        "consonant": "b",
        "rhyme": "oa"
    },
    {
        "id": 628,
        "syllable": "bac",
        "consonant": "b",
        "rhyme": "ac"
    },
    {
        "id": 629,
        "syllable": "boac",
        "consonant": "b",
        "rhyme": "oac"
    },
    {
        "id": 630,
        "syllable": "bang",
        "consonant": "b",
        "rhyme": "ang"
    },
    {
        "id": 631,
        "syllable": "boang",
        "consonant": "b",
        "rhyme": "oang"
    },
    {
        "id": 632,
        "syllable": "bat",
        "consonant": "b",
        "rhyme": "at"
    },
    {
        "id": 633,
        "syllable": "boat",
        "consonant": "b",
        "rhyme": "oat"
    },
    {
        "id": 634,
        "syllable": "ban",
        "consonant": "b",
        "rhyme": "an"
    },
    {
        "id": 635,
        "syllable": "boan",
        "consonant": "b",
        "rhyme": "oan"
    },
    {
        "id": 636,
        "syllable": "bap",
        "consonant": "b",
        "rhyme": "ap"
    },
    {
        "id": 637,
        "syllable": "boap",
        "consonant": "b",
        "rhyme": "oap"
    },
    {
        "id": 638,
        "syllable": "bam",
        "consonant": "b",
        "rhyme": "am"
    },
    {
        "id": 639,
        "syllable": "boam",
        "consonant": "b",
        "rhyme": "oam"
    },
    {
        "id": 640,
        "syllable": "bai",
        "consonant": "b",
        "rhyme": "ai"
    },
    {
        "id": 641,
        "syllable": "boai",
        "consonant": "b",
        "rhyme": "oai"
    },
    {
        "id": 642,
        "syllable": "bao",
        "consonant": "b",
        "rhyme": "ao"
    },
    {
        "id": 643,
        "syllable": "boao",
        "consonant": "b",
        "rhyme": "oao"
    },
    {
        "id": 644,
        "syllable": "băc",
        "consonant": "b",
        "rhyme": "ăc"
    },
    {
        "id": 645,
        "syllable": "boăc",
        "consonant": "b",
        "rhyme": "oăc"
    },
    {
        "id": 646,
        "syllable": "băng",
        "consonant": "b",
        "rhyme": "ăng"
    },
    {
        "id": 647,
        "syllable": "boăng",
        "consonant": "b",
        "rhyme": "oăng"
    },
    {
        "id": 648,
        "syllable": "băt",
        "consonant": "b",
        "rhyme": "ăt"
    },
    {
        "id": 649,
        "syllable": "boăt",
        "consonant": "b",
        "rhyme": "oăt"
    },
    {
        "id": 650,
        "syllable": "băn",
        "consonant": "b",
        "rhyme": "ăn"
    },
    {
        "id": 651,
        "syllable": "boăn",
        "consonant": "b",
        "rhyme": "oăn"
    },
    {
        "id": 652,
        "syllable": "băp",
        "consonant": "b",
        "rhyme": "ăp"
    },
    {
        "id": 653,
        "syllable": "boăp",
        "consonant": "b",
        "rhyme": "oăp"
    },
    {
        "id": 654,
        "syllable": "băm",
        "consonant": "b",
        "rhyme": "ăm"
    },
    {
        "id": 655,
        "syllable": "boăm",
        "consonant": "b",
        "rhyme": "oăm"
    },
    {
        "id": 656,
        "syllable": "bay",
        "consonant": "b",
        "rhyme": "ay"
    },
    {
        "id": 657,
        "syllable": "boay",
        "consonant": "b",
        "rhyme": "oay"
    },
    {
        "id": 658,
        "syllable": "bau",
        "consonant": "b",
        "rhyme": "au"
    },
    {
        "id": 659,
        "syllable": "boau",
        "consonant": "b",
        "rhyme": "oau"
    },
    {
        "id": 660,
        "syllable": "đi",
        "consonant": "đ",
        "rhyme": "i"
    },
    {
        "id": 661,
        "syllable": "đuy",
        "consonant": "đ",
        "rhyme": "uy"
    },
    {
        "id": 662,
        "syllable": "đich",
        "consonant": "đ",
        "rhyme": "ich"
    },
    {
        "id": 663,
        "syllable": "đuych",
        "consonant": "đ",
        "rhyme": "uych"
    },
    {
        "id": 664,
        "syllable": "đinh",
        "consonant": "đ",
        "rhyme": "inh"
    },
    {
        "id": 665,
        "syllable": "đuynh",
        "consonant": "đ",
        "rhyme": "uynh"
    },
    {
        "id": 666,
        "syllable": "đit",
        "consonant": "đ",
        "rhyme": "it"
    },
    {
        "id": 667,
        "syllable": "đuyt",
        "consonant": "đ",
        "rhyme": "uyt"
    },
    {
        "id": 668,
        "syllable": "đin",
        "consonant": "đ",
        "rhyme": "in"
    },
    {
        "id": 669,
        "syllable": "đuyn",
        "consonant": "đ",
        "rhyme": "uyn"
    },
    {
        "id": 670,
        "syllable": "đip",
        "consonant": "đ",
        "rhyme": "ip"
    },
    {
        "id": 671,
        "syllable": "đuyp",
        "consonant": "đ",
        "rhyme": "uyp"
    },
    {
        "id": 672,
        "syllable": "đim",
        "consonant": "đ",
        "rhyme": "im"
    },
    {
        "id": 673,
        "syllable": "đuym",
        "consonant": "đ",
        "rhyme": "uym"
    },
    {
        "id": 674,
        "syllable": "điu",
        "consonant": "đ",
        "rhyme": "iu"
    },
    {
        "id": 675,
        "syllable": "đuyu",
        "consonant": "đ",
        "rhyme": "uyu"
    },
    {
        "id": 676,
        "syllable": "đê",
        "consonant": "đ",
        "rhyme": "ê"
    },
    {
        "id": 677,
        "syllable": "đuê",
        "consonant": "đ",
        "rhyme": "uê"
    },
    {
        "id": 678,
        "syllable": "đêch",
        "consonant": "đ",
        "rhyme": "êch"
    },
    {
        "id": 679,
        "syllable": "đuêch",
        "consonant": "đ",
        "rhyme": "uêch"
    },
    {
        "id": 680,
        "syllable": "đênh",
        "consonant": "đ",
        "rhyme": "ênh"
    },
    {
        "id": 681,
        "syllable": "đuênh",
        "consonant": "đ",
        "rhyme": "uênh"
    },
    {
        "id": 682,
        "syllable": "đêt",
        "consonant": "đ",
        "rhyme": "êt"
    },
    {
        "id": 683,
        "syllable": "đuêt",
        "consonant": "đ",
        "rhyme": "uêt"
    },
    {
        "id": 684,
        "syllable": "đên",
        "consonant": "đ",
        "rhyme": "ên"
    },
    {
        "id": 685,
        "syllable": "đuên",
        "consonant": "đ",
        "rhyme": "uên"
    },
    {
        "id": 686,
        "syllable": "đêp",
        "consonant": "đ",
        "rhyme": "êp"
    },
    {
        "id": 687,
        "syllable": "đuêp",
        "consonant": "đ",
        "rhyme": "uêp"
    },
    {
        "id": 688,
        "syllable": "đêm",
        "consonant": "đ",
        "rhyme": "êm"
    },
    {
        "id": 689,
        "syllable": "đuêm",
        "consonant": "đ",
        "rhyme": "uêm"
    },
    {
        "id": 690,
        "syllable": "đêu",
        "consonant": "đ",
        "rhyme": "êu"
    },
    {
        "id": 691,
        "syllable": "đe",
        "consonant": "đ",
        "rhyme": "e"
    },
    {
        "id": 692,
        "syllable": "đoe",
        "consonant": "đ",
        "rhyme": "oe"
    },
    {
        "id": 693,
        "syllable": "đec",
        "consonant": "đ",
        "rhyme": "ec"
    },
    {
        "id": 694,
        "syllable": "đoec",
        "consonant": "đ",
        "rhyme": "oec"
    },
    {
        "id": 695,
        "syllable": "đeng",
        "consonant": "đ",
        "rhyme": "eng"
    },
    {
        "id": 696,
        "syllable": "đoeng",
        "consonant": "đ",
        "rhyme": "oeng"
    },
    {
        "id": 697,
        "syllable": "đet",
        "consonant": "đ",
        "rhyme": "et"
    },
    {
        "id": 698,
        "syllable": "đoet",
        "consonant": "đ",
        "rhyme": "oet"
    },
    {
        "id": 699,
        "syllable": "đen",
        "consonant": "đ",
        "rhyme": "en"
    },
    {
        "id": 700,
        "syllable": "đoen",
        "consonant": "đ",
        "rhyme": "oen"
    },
    {
        "id": 701,
        "syllable": "đep",
        "consonant": "đ",
        "rhyme": "ep"
    },
    {
        "id": 702,
        "syllable": "đoep",
        "consonant": "đ",
        "rhyme": "oep"
    },
    {
        "id": 703,
        "syllable": "đem",
        "consonant": "đ",
        "rhyme": "em"
    },
    {
        "id": 704,
        "syllable": "đoem",
        "consonant": "đ",
        "rhyme": "oem"
    },
    {
        "id": 705,
        "syllable": "đeo",
        "consonant": "đ",
        "rhyme": "eo"
    },
    {
        "id": 706,
        "syllable": "đoeo",
        "consonant": "đ",
        "rhyme": "oeo"
    },
    {
        "id": 707,
        "syllable": "đach",
        "consonant": "đ",
        "rhyme": "ach"
    },
    {
        "id": 708,
        "syllable": "đoach",
        "consonant": "đ",
        "rhyme": "oach"
    },
    {
        "id": 709,
        "syllable": "đanh",
        "consonant": "đ",
        "rhyme": "anh"
    },
    {
        "id": 710,
        "syllable": "đoanh",
        "consonant": "đ",
        "rhyme": "oanh"
    },
    {
        "id": 711,
        "syllable": "đia",
        "consonant": "đ",
        "rhyme": "ia"
    },
    {
        "id": 712,
        "syllable": "đuya",
        "consonant": "đ",
        "rhyme": "uya"
    },
    {
        "id": 713,
        "syllable": "điêc",
        "consonant": "đ",
        "rhyme": "iêc"
    },
    {
        "id": 714,
        "syllable": "điêng",
        "consonant": "đ",
        "rhyme": "iêng"
    },
    {
        "id": 715,
        "syllable": "điêt",
        "consonant": "đ",
        "rhyme": "iêt"
    },
    {
        "id": 716,
        "syllable": "điên",
        "consonant": "đ",
        "rhyme": "iên"
    },
    {
        "id": 717,
        "syllable": "đuyêt",
        "consonant": "đ",
        "rhyme": "uyêt"
    },
    {
        "id": 718,
        "syllable": "đuyên",
        "consonant": "đ",
        "rhyme": "uyên"
    },
    {
        "id": 719,
        "syllable": "điêp",
        "consonant": "đ",
        "rhyme": "iêp"
    },
    {
        "id": 720,
        "syllable": "đuyêp",
        "consonant": "đ",
        "rhyme": "uyêp"
    },
    {
        "id": 721,
        "syllable": "điêm",
        "consonant": "đ",
        "rhyme": "iêm"
    },
    {
        "id": 722,
        "syllable": "đuyêm",
        "consonant": "đ",
        "rhyme": "uyêm"
    },
    {
        "id": 723,
        "syllable": "điêu",
        "consonant": "đ",
        "rhyme": "iêu"
    },
    {
        "id": 724,
        "syllable": "đư",
        "consonant": "đ",
        "rhyme": "ư"
    },
    {
        "id": 725,
        "syllable": "đưc",
        "consonant": "đ",
        "rhyme": "ưc"
    },
    {
        "id": 726,
        "syllable": "đưng",
        "consonant": "đ",
        "rhyme": "ưng"
    },
    {
        "id": 727,
        "syllable": "đưt",
        "consonant": "đ",
        "rhyme": "ưt"
    },
    {
        "id": 728,
        "syllable": "đưn",
        "consonant": "đ",
        "rhyme": "ưn"
    },
    {
        "id": 729,
        "syllable": "đưp",
        "consonant": "đ",
        "rhyme": "ưp"
    },
    {
        "id": 730,
        "syllable": "đưm",
        "consonant": "đ",
        "rhyme": "ưm"
    },
    {
        "id": 731,
        "syllable": "đưi",
        "consonant": "đ",
        "rhyme": "ưi"
    },
    {
        "id": 732,
        "syllable": "đưu",
        "consonant": "đ",
        "rhyme": "ưu"
    },
    {
        "id": 733,
        "syllable": "đơ",
        "consonant": "đ",
        "rhyme": "ơ"
    },
    {
        "id": 734,
        "syllable": "đuơ",
        "consonant": "đ",
        "rhyme": "uơ"
    },
    {
        "id": 735,
        "syllable": "đơng",
        "consonant": "đ",
        "rhyme": "ơng"
    },
    {
        "id": 736,
        "syllable": "đơt",
        "consonant": "đ",
        "rhyme": "ơt"
    },
    {
        "id": 737,
        "syllable": "đơn",
        "consonant": "đ",
        "rhyme": "ơn"
    },
    {
        "id": 738,
        "syllable": "đơp",
        "consonant": "đ",
        "rhyme": "ơp"
    },
    {
        "id": 739,
        "syllable": "đơm",
        "consonant": "đ",
        "rhyme": "ơm"
    },
    {
        "id": 740,
        "syllable": "đơi",
        "consonant": "đ",
        "rhyme": "ơi"
    },
    {
        "id": 741,
        "syllable": "đâc",
        "consonant": "đ",
        "rhyme": "âc"
    },
    {
        "id": 742,
        "syllable": "đâng",
        "consonant": "đ",
        "rhyme": "âng"
    },
    {
        "id": 743,
        "syllable": "đuâng",
        "consonant": "đ",
        "rhyme": "uâng"
    },
    {
        "id": 744,
        "syllable": "đât",
        "consonant": "đ",
        "rhyme": "ât"
    },
    {
        "id": 745,
        "syllable": "đuât",
        "consonant": "đ",
        "rhyme": "uât"
    },
    {
        "id": 746,
        "syllable": "đân",
        "consonant": "đ",
        "rhyme": "ân"
    },
    {
        "id": 747,
        "syllable": "đuân",
        "consonant": "đ",
        "rhyme": "uân"
    },
    {
        "id": 748,
        "syllable": "đâp",
        "consonant": "đ",
        "rhyme": "âp"
    },
    {
        "id": 749,
        "syllable": "đuâp",
        "consonant": "đ",
        "rhyme": "uâp"
    },
    {
        "id": 750,
        "syllable": "đâm",
        "consonant": "đ",
        "rhyme": "âm"
    },
    {
        "id": 751,
        "syllable": "đuâm",
        "consonant": "đ",
        "rhyme": "uâm"
    },
    {
        "id": 752,
        "syllable": "đây",
        "consonant": "đ",
        "rhyme": "ây"
    },
    {
        "id": 753,
        "syllable": "đuây",
        "consonant": "đ",
        "rhyme": "uây"
    },
    {
        "id": 754,
        "syllable": "đâu",
        "consonant": "đ",
        "rhyme": "âu"
    },
    {
        "id": 755,
        "syllable": "đuâu",
        "consonant": "đ",
        "rhyme": "uâu"
    },
    {
        "id": 756,
        "syllable": "đu",
        "consonant": "đ",
        "rhyme": "u"
    },
    {
        "id": 757,
        "syllable": "đuc",
        "consonant": "đ",
        "rhyme": "uc"
    },
    {
        "id": 758,
        "syllable": "đung",
        "consonant": "đ",
        "rhyme": "ung"
    },
    {
        "id": 759,
        "syllable": "đut",
        "consonant": "đ",
        "rhyme": "ut"
    },
    {
        "id": 760,
        "syllable": "đun",
        "consonant": "đ",
        "rhyme": "un"
    },
    {
        "id": 761,
        "syllable": "đup",
        "consonant": "đ",
        "rhyme": "up"
    },
    {
        "id": 762,
        "syllable": "đum",
        "consonant": "đ",
        "rhyme": "um"
    },
    {
        "id": 763,
        "syllable": "đui",
        "consonant": "đ",
        "rhyme": "ui"
    },
    {
        "id": 764,
        "syllable": "đô",
        "consonant": "đ",
        "rhyme": "ô"
    },
    {
        "id": 765,
        "syllable": "đôc",
        "consonant": "đ",
        "rhyme": "ôc"
    },
    {
        "id": 766,
        "syllable": "đông",
        "consonant": "đ",
        "rhyme": "ông"
    },
    {
        "id": 767,
        "syllable": "đôt",
        "consonant": "đ",
        "rhyme": "ôt"
    },
    {
        "id": 768,
        "syllable": "đôn",
        "consonant": "đ",
        "rhyme": "ôn"
    },
    {
        "id": 769,
        "syllable": "đôp",
        "consonant": "đ",
        "rhyme": "ôp"
    },
    {
        "id": 770,
        "syllable": "đôm",
        "consonant": "đ",
        "rhyme": "ôm"
    },
    {
        "id": 771,
        "syllable": "đôi",
        "consonant": "đ",
        "rhyme": "ôi"
    },
    {
        "id": 772,
        "syllable": "đo",
        "consonant": "đ",
        "rhyme": "o"
    },
    {
        "id": 773,
        "syllable": "đooc",
        "consonant": "đ",
        "rhyme": "ooc"
    },
    {
        "id": 774,
        "syllable": "đoong",
        "consonant": "đ",
        "rhyme": "oong"
    },
    {
        "id": 775,
        "syllable": "đot",
        "consonant": "đ",
        "rhyme": "ot"
    },
    {
        "id": 776,
        "syllable": "đon",
        "consonant": "đ",
        "rhyme": "on"
    },
    {
        "id": 777,
        "syllable": "đop",
        "consonant": "đ",
        "rhyme": "op"
    },
    {
        "id": 778,
        "syllable": "đom",
        "consonant": "đ",
        "rhyme": "om"
    },
    {
        "id": 779,
        "syllable": "đoi",
        "consonant": "đ",
        "rhyme": "oi"
    },
    {
        "id": 780,
        "syllable": "đoc",
        "consonant": "đ",
        "rhyme": "oc"
    },
    {
        "id": 781,
        "syllable": "đong",
        "consonant": "đ",
        "rhyme": "ong"
    },
    {
        "id": 782,
        "syllable": "đua",
        "consonant": "đ",
        "rhyme": "ua"
    },
    {
        "id": 783,
        "syllable": "đuôc",
        "consonant": "đ",
        "rhyme": "uôc"
    },
    {
        "id": 784,
        "syllable": "đuông",
        "consonant": "đ",
        "rhyme": "uông"
    },
    {
        "id": 785,
        "syllable": "đuôt",
        "consonant": "đ",
        "rhyme": "uôt"
    },
    {
        "id": 786,
        "syllable": "đuôn",
        "consonant": "đ",
        "rhyme": "uôn"
    },
    {
        "id": 787,
        "syllable": "đuôp",
        "consonant": "đ",
        "rhyme": "uôp"
    },
    {
        "id": 788,
        "syllable": "đuôm",
        "consonant": "đ",
        "rhyme": "uôm"
    },
    {
        "id": 789,
        "syllable": "đuôi",
        "consonant": "đ",
        "rhyme": "uôi"
    },
    {
        "id": 790,
        "syllable": "đưa",
        "consonant": "đ",
        "rhyme": "ưa"
    },
    {
        "id": 791,
        "syllable": "đươc",
        "consonant": "đ",
        "rhyme": "ươc"
    },
    {
        "id": 792,
        "syllable": "đương",
        "consonant": "đ",
        "rhyme": "ương"
    },
    {
        "id": 793,
        "syllable": "đươt",
        "consonant": "đ",
        "rhyme": "ươt"
    },
    {
        "id": 794,
        "syllable": "đươn",
        "consonant": "đ",
        "rhyme": "ươn"
    },
    {
        "id": 795,
        "syllable": "đươp",
        "consonant": "đ",
        "rhyme": "ươp"
    },
    {
        "id": 796,
        "syllable": "đươm",
        "consonant": "đ",
        "rhyme": "ươm"
    },
    {
        "id": 797,
        "syllable": "đươi",
        "consonant": "đ",
        "rhyme": "ươi"
    },
    {
        "id": 798,
        "syllable": "đa",
        "consonant": "đ",
        "rhyme": "a"
    },
    {
        "id": 799,
        "syllable": "đoa",
        "consonant": "đ",
        "rhyme": "oa"
    },
    {
        "id": 800,
        "syllable": "đac",
        "consonant": "đ",
        "rhyme": "ac"
    },
    {
        "id": 801,
        "syllable": "đoac",
        "consonant": "đ",
        "rhyme": "oac"
    },
    {
        "id": 802,
        "syllable": "đang",
        "consonant": "đ",
        "rhyme": "ang"
    },
    {
        "id": 803,
        "syllable": "đoang",
        "consonant": "đ",
        "rhyme": "oang"
    },
    {
        "id": 804,
        "syllable": "đat",
        "consonant": "đ",
        "rhyme": "at"
    },
    {
        "id": 805,
        "syllable": "đoat",
        "consonant": "đ",
        "rhyme": "oat"
    },
    {
        "id": 806,
        "syllable": "đan",
        "consonant": "đ",
        "rhyme": "an"
    },
    {
        "id": 807,
        "syllable": "đoan",
        "consonant": "đ",
        "rhyme": "oan"
    },
    {
        "id": 808,
        "syllable": "đap",
        "consonant": "đ",
        "rhyme": "ap"
    },
    {
        "id": 809,
        "syllable": "đoap",
        "consonant": "đ",
        "rhyme": "oap"
    },
    {
        "id": 810,
        "syllable": "đam",
        "consonant": "đ",
        "rhyme": "am"
    },
    {
        "id": 811,
        "syllable": "đoam",
        "consonant": "đ",
        "rhyme": "oam"
    },
    {
        "id": 812,
        "syllable": "đai",
        "consonant": "đ",
        "rhyme": "ai"
    },
    {
        "id": 813,
        "syllable": "đoai",
        "consonant": "đ",
        "rhyme": "oai"
    },
    {
        "id": 814,
        "syllable": "đao",
        "consonant": "đ",
        "rhyme": "ao"
    },
    {
        "id": 815,
        "syllable": "đoao",
        "consonant": "đ",
        "rhyme": "oao"
    },
    {
        "id": 816,
        "syllable": "đăc",
        "consonant": "đ",
        "rhyme": "ăc"
    },
    {
        "id": 817,
        "syllable": "đoăc",
        "consonant": "đ",
        "rhyme": "oăc"
    },
    {
        "id": 818,
        "syllable": "đăng",
        "consonant": "đ",
        "rhyme": "ăng"
    },
    {
        "id": 819,
        "syllable": "đoăng",
        "consonant": "đ",
        "rhyme": "oăng"
    },
    {
        "id": 820,
        "syllable": "đăt",
        "consonant": "đ",
        "rhyme": "ăt"
    },
    {
        "id": 821,
        "syllable": "đoăt",
        "consonant": "đ",
        "rhyme": "oăt"
    },
    {
        "id": 822,
        "syllable": "đăn",
        "consonant": "đ",
        "rhyme": "ăn"
    },
    {
        "id": 823,
        "syllable": "đoăn",
        "consonant": "đ",
        "rhyme": "oăn"
    },
    {
        "id": 824,
        "syllable": "đăp",
        "consonant": "đ",
        "rhyme": "ăp"
    },
    {
        "id": 825,
        "syllable": "đoăp",
        "consonant": "đ",
        "rhyme": "oăp"
    },
    {
        "id": 826,
        "syllable": "đăm",
        "consonant": "đ",
        "rhyme": "ăm"
    },
    {
        "id": 827,
        "syllable": "đoăm",
        "consonant": "đ",
        "rhyme": "oăm"
    },
    {
        "id": 828,
        "syllable": "đay",
        "consonant": "đ",
        "rhyme": "ay"
    },
    {
        "id": 829,
        "syllable": "đoay",
        "consonant": "đ",
        "rhyme": "oay"
    },
    {
        "id": 830,
        "syllable": "đau",
        "consonant": "đ",
        "rhyme": "au"
    },
    {
        "id": 831,
        "syllable": "đoau",
        "consonant": "đ",
        "rhyme": "oau"
    },
    {
        "id": 832,
        "syllable": "xi",
        "consonant": "x",
        "rhyme": "i"
    },
    {
        "id": 833,
        "syllable": "xuy",
        "consonant": "x",
        "rhyme": "uy"
    },
    {
        "id": 834,
        "syllable": "xich",
        "consonant": "x",
        "rhyme": "ich"
    },
    {
        "id": 835,
        "syllable": "xuych",
        "consonant": "x",
        "rhyme": "uych"
    },
    {
        "id": 836,
        "syllable": "xinh",
        "consonant": "x",
        "rhyme": "inh"
    },
    {
        "id": 837,
        "syllable": "xuynh",
        "consonant": "x",
        "rhyme": "uynh"
    },
    {
        "id": 838,
        "syllable": "xit",
        "consonant": "x",
        "rhyme": "it"
    },
    {
        "id": 839,
        "syllable": "xuyt",
        "consonant": "x",
        "rhyme": "uyt"
    },
    {
        "id": 840,
        "syllable": "xin",
        "consonant": "x",
        "rhyme": "in"
    },
    {
        "id": 841,
        "syllable": "xuyn",
        "consonant": "x",
        "rhyme": "uyn"
    },
    {
        "id": 842,
        "syllable": "xip",
        "consonant": "x",
        "rhyme": "ip"
    },
    {
        "id": 843,
        "syllable": "xuyp",
        "consonant": "x",
        "rhyme": "uyp"
    },
    {
        "id": 844,
        "syllable": "xim",
        "consonant": "x",
        "rhyme": "im"
    },
    {
        "id": 845,
        "syllable": "xuym",
        "consonant": "x",
        "rhyme": "uym"
    },
    {
        "id": 846,
        "syllable": "xiu",
        "consonant": "x",
        "rhyme": "iu"
    },
    {
        "id": 847,
        "syllable": "xuyu",
        "consonant": "x",
        "rhyme": "uyu"
    },
    {
        "id": 848,
        "syllable": "xê",
        "consonant": "x",
        "rhyme": "ê"
    },
    {
        "id": 849,
        "syllable": "xuê",
        "consonant": "x",
        "rhyme": "uê"
    },
    {
        "id": 850,
        "syllable": "xêch",
        "consonant": "x",
        "rhyme": "êch"
    },
    {
        "id": 851,
        "syllable": "xuêch",
        "consonant": "x",
        "rhyme": "uêch"
    },
    {
        "id": 852,
        "syllable": "xênh",
        "consonant": "x",
        "rhyme": "ênh"
    },
    {
        "id": 853,
        "syllable": "xuênh",
        "consonant": "x",
        "rhyme": "uênh"
    },
    {
        "id": 854,
        "syllable": "xêt",
        "consonant": "x",
        "rhyme": "êt"
    },
    {
        "id": 855,
        "syllable": "xuêt",
        "consonant": "x",
        "rhyme": "uêt"
    },
    {
        "id": 856,
        "syllable": "xên",
        "consonant": "x",
        "rhyme": "ên"
    },
    {
        "id": 857,
        "syllable": "xuên",
        "consonant": "x",
        "rhyme": "uên"
    },
    {
        "id": 858,
        "syllable": "xêp",
        "consonant": "x",
        "rhyme": "êp"
    },
    {
        "id": 859,
        "syllable": "xuêp",
        "consonant": "x",
        "rhyme": "uêp"
    },
    {
        "id": 860,
        "syllable": "xêm",
        "consonant": "x",
        "rhyme": "êm"
    },
    {
        "id": 861,
        "syllable": "xuêm",
        "consonant": "x",
        "rhyme": "uêm"
    },
    {
        "id": 862,
        "syllable": "xêu",
        "consonant": "x",
        "rhyme": "êu"
    },
    {
        "id": 863,
        "syllable": "xe",
        "consonant": "x",
        "rhyme": "e"
    },
    {
        "id": 864,
        "syllable": "xoe",
        "consonant": "x",
        "rhyme": "oe"
    },
    {
        "id": 865,
        "syllable": "xec",
        "consonant": "x",
        "rhyme": "ec"
    },
    {
        "id": 866,
        "syllable": "xoec",
        "consonant": "x",
        "rhyme": "oec"
    },
    {
        "id": 867,
        "syllable": "xeng",
        "consonant": "x",
        "rhyme": "eng"
    },
    {
        "id": 868,
        "syllable": "xoeng",
        "consonant": "x",
        "rhyme": "oeng"
    },
    {
        "id": 869,
        "syllable": "xet",
        "consonant": "x",
        "rhyme": "et"
    },
    {
        "id": 870,
        "syllable": "xoet",
        "consonant": "x",
        "rhyme": "oet"
    },
    {
        "id": 871,
        "syllable": "xen",
        "consonant": "x",
        "rhyme": "en"
    },
    {
        "id": 872,
        "syllable": "xoen",
        "consonant": "x",
        "rhyme": "oen"
    },
    {
        "id": 873,
        "syllable": "xep",
        "consonant": "x",
        "rhyme": "ep"
    },
    {
        "id": 874,
        "syllable": "xoep",
        "consonant": "x",
        "rhyme": "oep"
    },
    {
        "id": 875,
        "syllable": "xem",
        "consonant": "x",
        "rhyme": "em"
    },
    {
        "id": 876,
        "syllable": "xoem",
        "consonant": "x",
        "rhyme": "oem"
    },
    {
        "id": 877,
        "syllable": "xeo",
        "consonant": "x",
        "rhyme": "eo"
    },
    {
        "id": 878,
        "syllable": "xoeo",
        "consonant": "x",
        "rhyme": "oeo"
    },
    {
        "id": 879,
        "syllable": "xach",
        "consonant": "x",
        "rhyme": "ach"
    },
    {
        "id": 880,
        "syllable": "xoach",
        "consonant": "x",
        "rhyme": "oach"
    },
    {
        "id": 881,
        "syllable": "xanh",
        "consonant": "x",
        "rhyme": "anh"
    },
    {
        "id": 882,
        "syllable": "xoanh",
        "consonant": "x",
        "rhyme": "oanh"
    },
    {
        "id": 883,
        "syllable": "xia",
        "consonant": "x",
        "rhyme": "ia"
    },
    {
        "id": 884,
        "syllable": "xuya",
        "consonant": "x",
        "rhyme": "uya"
    },
    {
        "id": 885,
        "syllable": "xiêc",
        "consonant": "x",
        "rhyme": "iêc"
    },
    {
        "id": 886,
        "syllable": "xiêng",
        "consonant": "x",
        "rhyme": "iêng"
    },
    {
        "id": 887,
        "syllable": "xiêt",
        "consonant": "x",
        "rhyme": "iêt"
    },
    {
        "id": 888,
        "syllable": "xiên",
        "consonant": "x",
        "rhyme": "iên"
    },
    {
        "id": 889,
        "syllable": "xuyêt",
        "consonant": "x",
        "rhyme": "uyêt"
    },
    {
        "id": 890,
        "syllable": "xuyên",
        "consonant": "x",
        "rhyme": "uyên"
    },
    {
        "id": 891,
        "syllable": "xiêp",
        "consonant": "x",
        "rhyme": "iêp"
    },
    {
        "id": 892,
        "syllable": "xuyêp",
        "consonant": "x",
        "rhyme": "uyêp"
    },
    {
        "id": 893,
        "syllable": "xiêm",
        "consonant": "x",
        "rhyme": "iêm"
    },
    {
        "id": 894,
        "syllable": "xuyêm",
        "consonant": "x",
        "rhyme": "uyêm"
    },
    {
        "id": 895,
        "syllable": "xiêu",
        "consonant": "x",
        "rhyme": "iêu"
    },
    {
        "id": 896,
        "syllable": "xư",
        "consonant": "x",
        "rhyme": "ư"
    },
    {
        "id": 897,
        "syllable": "xưc",
        "consonant": "x",
        "rhyme": "ưc"
    },
    {
        "id": 898,
        "syllable": "xưng",
        "consonant": "x",
        "rhyme": "ưng"
    },
    {
        "id": 899,
        "syllable": "xưt",
        "consonant": "x",
        "rhyme": "ưt"
    },
    {
        "id": 900,
        "syllable": "xưn",
        "consonant": "x",
        "rhyme": "ưn"
    },
    {
        "id": 901,
        "syllable": "xưp",
        "consonant": "x",
        "rhyme": "ưp"
    },
    {
        "id": 902,
        "syllable": "xưm",
        "consonant": "x",
        "rhyme": "ưm"
    },
    {
        "id": 903,
        "syllable": "xưi",
        "consonant": "x",
        "rhyme": "ưi"
    },
    {
        "id": 904,
        "syllable": "xưu",
        "consonant": "x",
        "rhyme": "ưu"
    },
    {
        "id": 905,
        "syllable": "xơ",
        "consonant": "x",
        "rhyme": "ơ"
    },
    {
        "id": 906,
        "syllable": "xuơ",
        "consonant": "x",
        "rhyme": "uơ"
    },
    {
        "id": 907,
        "syllable": "xơng",
        "consonant": "x",
        "rhyme": "ơng"
    },
    {
        "id": 908,
        "syllable": "xơt",
        "consonant": "x",
        "rhyme": "ơt"
    },
    {
        "id": 909,
        "syllable": "xơn",
        "consonant": "x",
        "rhyme": "ơn"
    },
    {
        "id": 910,
        "syllable": "xơp",
        "consonant": "x",
        "rhyme": "ơp"
    },
    {
        "id": 911,
        "syllable": "xơm",
        "consonant": "x",
        "rhyme": "ơm"
    },
    {
        "id": 912,
        "syllable": "xơi",
        "consonant": "x",
        "rhyme": "ơi"
    },
    {
        "id": 913,
        "syllable": "xâc",
        "consonant": "x",
        "rhyme": "âc"
    },
    {
        "id": 914,
        "syllable": "xâng",
        "consonant": "x",
        "rhyme": "âng"
    },
    {
        "id": 915,
        "syllable": "xuâng",
        "consonant": "x",
        "rhyme": "uâng"
    },
    {
        "id": 916,
        "syllable": "xât",
        "consonant": "x",
        "rhyme": "ât"
    },
    {
        "id": 917,
        "syllable": "xuât",
        "consonant": "x",
        "rhyme": "uât"
    },
    {
        "id": 918,
        "syllable": "xân",
        "consonant": "x",
        "rhyme": "ân"
    },
    {
        "id": 919,
        "syllable": "xuân",
        "consonant": "x",
        "rhyme": "uân"
    },
    {
        "id": 920,
        "syllable": "xâp",
        "consonant": "x",
        "rhyme": "âp"
    },
    {
        "id": 921,
        "syllable": "xuâp",
        "consonant": "x",
        "rhyme": "uâp"
    },
    {
        "id": 922,
        "syllable": "xâm",
        "consonant": "x",
        "rhyme": "âm"
    },
    {
        "id": 923,
        "syllable": "xuâm",
        "consonant": "x",
        "rhyme": "uâm"
    },
    {
        "id": 924,
        "syllable": "xây",
        "consonant": "x",
        "rhyme": "ây"
    },
    {
        "id": 925,
        "syllable": "xuây",
        "consonant": "x",
        "rhyme": "uây"
    },
    {
        "id": 926,
        "syllable": "xâu",
        "consonant": "x",
        "rhyme": "âu"
    },
    {
        "id": 927,
        "syllable": "xuâu",
        "consonant": "x",
        "rhyme": "uâu"
    },
    {
        "id": 928,
        "syllable": "xu",
        "consonant": "x",
        "rhyme": "u"
    },
    {
        "id": 929,
        "syllable": "xuc",
        "consonant": "x",
        "rhyme": "uc"
    },
    {
        "id": 930,
        "syllable": "xung",
        "consonant": "x",
        "rhyme": "ung"
    },
    {
        "id": 931,
        "syllable": "xut",
        "consonant": "x",
        "rhyme": "ut"
    },
    {
        "id": 932,
        "syllable": "xun",
        "consonant": "x",
        "rhyme": "un"
    },
    {
        "id": 933,
        "syllable": "xup",
        "consonant": "x",
        "rhyme": "up"
    },
    {
        "id": 934,
        "syllable": "xum",
        "consonant": "x",
        "rhyme": "um"
    },
    {
        "id": 935,
        "syllable": "xui",
        "consonant": "x",
        "rhyme": "ui"
    },
    {
        "id": 936,
        "syllable": "xô",
        "consonant": "x",
        "rhyme": "ô"
    },
    {
        "id": 937,
        "syllable": "xôc",
        "consonant": "x",
        "rhyme": "ôc"
    },
    {
        "id": 938,
        "syllable": "xông",
        "consonant": "x",
        "rhyme": "ông"
    },
    {
        "id": 939,
        "syllable": "xôt",
        "consonant": "x",
        "rhyme": "ôt"
    },
    {
        "id": 940,
        "syllable": "xôn",
        "consonant": "x",
        "rhyme": "ôn"
    },
    {
        "id": 941,
        "syllable": "xôp",
        "consonant": "x",
        "rhyme": "ôp"
    },
    {
        "id": 942,
        "syllable": "xôm",
        "consonant": "x",
        "rhyme": "ôm"
    },
    {
        "id": 943,
        "syllable": "xôi",
        "consonant": "x",
        "rhyme": "ôi"
    },
    {
        "id": 944,
        "syllable": "xo",
        "consonant": "x",
        "rhyme": "o"
    },
    {
        "id": 945,
        "syllable": "xooc",
        "consonant": "x",
        "rhyme": "ooc"
    },
    {
        "id": 946,
        "syllable": "xoong",
        "consonant": "x",
        "rhyme": "oong"
    },
    {
        "id": 947,
        "syllable": "xot",
        "consonant": "x",
        "rhyme": "ot"
    },
    {
        "id": 948,
        "syllable": "xon",
        "consonant": "x",
        "rhyme": "on"
    },
    {
        "id": 949,
        "syllable": "xop",
        "consonant": "x",
        "rhyme": "op"
    },
    {
        "id": 950,
        "syllable": "xom",
        "consonant": "x",
        "rhyme": "om"
    },
    {
        "id": 951,
        "syllable": "xoi",
        "consonant": "x",
        "rhyme": "oi"
    },
    {
        "id": 952,
        "syllable": "xoc",
        "consonant": "x",
        "rhyme": "oc"
    },
    {
        "id": 953,
        "syllable": "xong",
        "consonant": "x",
        "rhyme": "ong"
    },
    {
        "id": 954,
        "syllable": "xua",
        "consonant": "x",
        "rhyme": "ua"
    },
    {
        "id": 955,
        "syllable": "xuôc",
        "consonant": "x",
        "rhyme": "uôc"
    },
    {
        "id": 956,
        "syllable": "xuông",
        "consonant": "x",
        "rhyme": "uông"
    },
    {
        "id": 957,
        "syllable": "xuôt",
        "consonant": "x",
        "rhyme": "uôt"
    },
    {
        "id": 958,
        "syllable": "xuôn",
        "consonant": "x",
        "rhyme": "uôn"
    },
    {
        "id": 959,
        "syllable": "xuôp",
        "consonant": "x",
        "rhyme": "uôp"
    },
    {
        "id": 960,
        "syllable": "xuôm",
        "consonant": "x",
        "rhyme": "uôm"
    },
    {
        "id": 961,
        "syllable": "xuôi",
        "consonant": "x",
        "rhyme": "uôi"
    },
    {
        "id": 962,
        "syllable": "xưa",
        "consonant": "x",
        "rhyme": "ưa"
    },
    {
        "id": 963,
        "syllable": "xươc",
        "consonant": "x",
        "rhyme": "ươc"
    },
    {
        "id": 964,
        "syllable": "xương",
        "consonant": "x",
        "rhyme": "ương"
    },
    {
        "id": 965,
        "syllable": "xươt",
        "consonant": "x",
        "rhyme": "ươt"
    },
    {
        "id": 966,
        "syllable": "xươn",
        "consonant": "x",
        "rhyme": "ươn"
    },
    {
        "id": 967,
        "syllable": "xươp",
        "consonant": "x",
        "rhyme": "ươp"
    },
    {
        "id": 968,
        "syllable": "xươm",
        "consonant": "x",
        "rhyme": "ươm"
    },
    {
        "id": 969,
        "syllable": "xươi",
        "consonant": "x",
        "rhyme": "ươi"
    },
    {
        "id": 970,
        "syllable": "xa",
        "consonant": "x",
        "rhyme": "a"
    },
    {
        "id": 971,
        "syllable": "xoa",
        "consonant": "x",
        "rhyme": "oa"
    },
    {
        "id": 972,
        "syllable": "xac",
        "consonant": "x",
        "rhyme": "ac"
    },
    {
        "id": 973,
        "syllable": "xoac",
        "consonant": "x",
        "rhyme": "oac"
    },
    {
        "id": 974,
        "syllable": "xang",
        "consonant": "x",
        "rhyme": "ang"
    },
    {
        "id": 975,
        "syllable": "xoang",
        "consonant": "x",
        "rhyme": "oang"
    },
    {
        "id": 976,
        "syllable": "xat",
        "consonant": "x",
        "rhyme": "at"
    },
    {
        "id": 977,
        "syllable": "xoat",
        "consonant": "x",
        "rhyme": "oat"
    },
    {
        "id": 978,
        "syllable": "xan",
        "consonant": "x",
        "rhyme": "an"
    },
    {
        "id": 979,
        "syllable": "xoan",
        "consonant": "x",
        "rhyme": "oan"
    },
    {
        "id": 980,
        "syllable": "xap",
        "consonant": "x",
        "rhyme": "ap"
    },
    {
        "id": 981,
        "syllable": "xoap",
        "consonant": "x",
        "rhyme": "oap"
    },
    {
        "id": 982,
        "syllable": "xam",
        "consonant": "x",
        "rhyme": "am"
    },
    {
        "id": 983,
        "syllable": "xoam",
        "consonant": "x",
        "rhyme": "oam"
    },
    {
        "id": 984,
        "syllable": "xai",
        "consonant": "x",
        "rhyme": "ai"
    },
    {
        "id": 985,
        "syllable": "xoai",
        "consonant": "x",
        "rhyme": "oai"
    },
    {
        "id": 986,
        "syllable": "xao",
        "consonant": "x",
        "rhyme": "ao"
    },
    {
        "id": 987,
        "syllable": "xoao",
        "consonant": "x",
        "rhyme": "oao"
    },
    {
        "id": 988,
        "syllable": "xăc",
        "consonant": "x",
        "rhyme": "ăc"
    },
    {
        "id": 989,
        "syllable": "xoăc",
        "consonant": "x",
        "rhyme": "oăc"
    },
    {
        "id": 990,
        "syllable": "xăng",
        "consonant": "x",
        "rhyme": "ăng"
    },
    {
        "id": 991,
        "syllable": "xoăng",
        "consonant": "x",
        "rhyme": "oăng"
    },
    {
        "id": 992,
        "syllable": "xăt",
        "consonant": "x",
        "rhyme": "ăt"
    },
    {
        "id": 993,
        "syllable": "xoăt",
        "consonant": "x",
        "rhyme": "oăt"
    },
    {
        "id": 994,
        "syllable": "xăn",
        "consonant": "x",
        "rhyme": "ăn"
    },
    {
        "id": 995,
        "syllable": "xoăn",
        "consonant": "x",
        "rhyme": "oăn"
    },
    {
        "id": 996,
        "syllable": "xăp",
        "consonant": "x",
        "rhyme": "ăp"
    },
    {
        "id": 997,
        "syllable": "xoăp",
        "consonant": "x",
        "rhyme": "oăp"
    },
    {
        "id": 998,
        "syllable": "xăm",
        "consonant": "x",
        "rhyme": "ăm"
    },
    {
        "id": 999,
        "syllable": "xoăm",
        "consonant": "x",
        "rhyme": "oăm"
    },
    {
        "id": 1000,
        "syllable": "xay",
        "consonant": "x",
        "rhyme": "ay"
    },
    {
        "id": 1001,
        "syllable": "xoay",
        "consonant": "x",
        "rhyme": "oay"
    },
    {
        "id": 1002,
        "syllable": "xau",
        "consonant": "x",
        "rhyme": "au"
    },
    {
        "id": 1003,
        "syllable": "xoau",
        "consonant": "x",
        "rhyme": "oau"
    },
    {
        "id": 1004,
        "syllable": "si",
        "consonant": "s",
        "rhyme": "i"
    },
    {
        "id": 1005,
        "syllable": "suy",
        "consonant": "s",
        "rhyme": "uy"
    },
    {
        "id": 1006,
        "syllable": "sich",
        "consonant": "s",
        "rhyme": "ich"
    },
    {
        "id": 1007,
        "syllable": "suych",
        "consonant": "s",
        "rhyme": "uych"
    },
    {
        "id": 1008,
        "syllable": "sinh",
        "consonant": "s",
        "rhyme": "inh"
    },
    {
        "id": 1009,
        "syllable": "suynh",
        "consonant": "s",
        "rhyme": "uynh"
    },
    {
        "id": 1010,
        "syllable": "sit",
        "consonant": "s",
        "rhyme": "it"
    },
    {
        "id": 1011,
        "syllable": "suyt",
        "consonant": "s",
        "rhyme": "uyt"
    },
    {
        "id": 1012,
        "syllable": "sin",
        "consonant": "s",
        "rhyme": "in"
    },
    {
        "id": 1013,
        "syllable": "suyn",
        "consonant": "s",
        "rhyme": "uyn"
    },
    {
        "id": 1014,
        "syllable": "sip",
        "consonant": "s",
        "rhyme": "ip"
    },
    {
        "id": 1015,
        "syllable": "suyp",
        "consonant": "s",
        "rhyme": "uyp"
    },
    {
        "id": 1016,
        "syllable": "sim",
        "consonant": "s",
        "rhyme": "im"
    },
    {
        "id": 1017,
        "syllable": "suym",
        "consonant": "s",
        "rhyme": "uym"
    },
    {
        "id": 1018,
        "syllable": "siu",
        "consonant": "s",
        "rhyme": "iu"
    },
    {
        "id": 1019,
        "syllable": "suyu",
        "consonant": "s",
        "rhyme": "uyu"
    },
    {
        "id": 1020,
        "syllable": "sê",
        "consonant": "s",
        "rhyme": "ê"
    },
    {
        "id": 1021,
        "syllable": "suê",
        "consonant": "s",
        "rhyme": "uê"
    },
    {
        "id": 1022,
        "syllable": "sêch",
        "consonant": "s",
        "rhyme": "êch"
    },
    {
        "id": 1023,
        "syllable": "suêch",
        "consonant": "s",
        "rhyme": "uêch"
    },
    {
        "id": 1024,
        "syllable": "sênh",
        "consonant": "s",
        "rhyme": "ênh"
    },
    {
        "id": 1025,
        "syllable": "suênh",
        "consonant": "s",
        "rhyme": "uênh"
    },
    {
        "id": 1026,
        "syllable": "sêt",
        "consonant": "s",
        "rhyme": "êt"
    },
    {
        "id": 1027,
        "syllable": "suêt",
        "consonant": "s",
        "rhyme": "uêt"
    },
    {
        "id": 1028,
        "syllable": "sên",
        "consonant": "s",
        "rhyme": "ên"
    },
    {
        "id": 1029,
        "syllable": "suên",
        "consonant": "s",
        "rhyme": "uên"
    },
    {
        "id": 1030,
        "syllable": "sêp",
        "consonant": "s",
        "rhyme": "êp"
    },
    {
        "id": 1031,
        "syllable": "suêp",
        "consonant": "s",
        "rhyme": "uêp"
    },
    {
        "id": 1032,
        "syllable": "sêm",
        "consonant": "s",
        "rhyme": "êm"
    },
    {
        "id": 1033,
        "syllable": "suêm",
        "consonant": "s",
        "rhyme": "uêm"
    },
    {
        "id": 1034,
        "syllable": "sêu",
        "consonant": "s",
        "rhyme": "êu"
    },
    {
        "id": 1035,
        "syllable": "se",
        "consonant": "s",
        "rhyme": "e"
    },
    {
        "id": 1036,
        "syllable": "soe",
        "consonant": "s",
        "rhyme": "oe"
    },
    {
        "id": 1037,
        "syllable": "sec",
        "consonant": "s",
        "rhyme": "ec"
    },
    {
        "id": 1038,
        "syllable": "soec",
        "consonant": "s",
        "rhyme": "oec"
    },
    {
        "id": 1039,
        "syllable": "seng",
        "consonant": "s",
        "rhyme": "eng"
    },
    {
        "id": 1040,
        "syllable": "soeng",
        "consonant": "s",
        "rhyme": "oeng"
    },
    {
        "id": 1041,
        "syllable": "set",
        "consonant": "s",
        "rhyme": "et"
    },
    {
        "id": 1042,
        "syllable": "soet",
        "consonant": "s",
        "rhyme": "oet"
    },
    {
        "id": 1043,
        "syllable": "sen",
        "consonant": "s",
        "rhyme": "en"
    },
    {
        "id": 1044,
        "syllable": "soen",
        "consonant": "s",
        "rhyme": "oen"
    },
    {
        "id": 1045,
        "syllable": "sep",
        "consonant": "s",
        "rhyme": "ep"
    },
    {
        "id": 1046,
        "syllable": "soep",
        "consonant": "s",
        "rhyme": "oep"
    },
    {
        "id": 1047,
        "syllable": "sem",
        "consonant": "s",
        "rhyme": "em"
    },
    {
        "id": 1048,
        "syllable": "soem",
        "consonant": "s",
        "rhyme": "oem"
    },
    {
        "id": 1049,
        "syllable": "seo",
        "consonant": "s",
        "rhyme": "eo"
    },
    {
        "id": 1050,
        "syllable": "soeo",
        "consonant": "s",
        "rhyme": "oeo"
    },
    {
        "id": 1051,
        "syllable": "sach",
        "consonant": "s",
        "rhyme": "ach"
    },
    {
        "id": 1052,
        "syllable": "soach",
        "consonant": "s",
        "rhyme": "oach"
    },
    {
        "id": 1053,
        "syllable": "sanh",
        "consonant": "s",
        "rhyme": "anh"
    },
    {
        "id": 1054,
        "syllable": "soanh",
        "consonant": "s",
        "rhyme": "oanh"
    },
    {
        "id": 1055,
        "syllable": "sia",
        "consonant": "s",
        "rhyme": "ia"
    },
    {
        "id": 1056,
        "syllable": "suya",
        "consonant": "s",
        "rhyme": "uya"
    },
    {
        "id": 1057,
        "syllable": "siêc",
        "consonant": "s",
        "rhyme": "iêc"
    },
    {
        "id": 1058,
        "syllable": "siêng",
        "consonant": "s",
        "rhyme": "iêng"
    },
    {
        "id": 1059,
        "syllable": "siêt",
        "consonant": "s",
        "rhyme": "iêt"
    },
    {
        "id": 1060,
        "syllable": "siên",
        "consonant": "s",
        "rhyme": "iên"
    },
    {
        "id": 1061,
        "syllable": "suyêt",
        "consonant": "s",
        "rhyme": "uyêt"
    },
    {
        "id": 1062,
        "syllable": "suyên",
        "consonant": "s",
        "rhyme": "uyên"
    },
    {
        "id": 1063,
        "syllable": "siêp",
        "consonant": "s",
        "rhyme": "iêp"
    },
    {
        "id": 1064,
        "syllable": "suyêp",
        "consonant": "s",
        "rhyme": "uyêp"
    },
    {
        "id": 1065,
        "syllable": "siêm",
        "consonant": "s",
        "rhyme": "iêm"
    },
    {
        "id": 1066,
        "syllable": "suyêm",
        "consonant": "s",
        "rhyme": "uyêm"
    },
    {
        "id": 1067,
        "syllable": "siêu",
        "consonant": "s",
        "rhyme": "iêu"
    },
    {
        "id": 1068,
        "syllable": "sư",
        "consonant": "s",
        "rhyme": "ư"
    },
    {
        "id": 1069,
        "syllable": "sưc",
        "consonant": "s",
        "rhyme": "ưc"
    },
    {
        "id": 1070,
        "syllable": "sưng",
        "consonant": "s",
        "rhyme": "ưng"
    },
    {
        "id": 1071,
        "syllable": "sưt",
        "consonant": "s",
        "rhyme": "ưt"
    },
    {
        "id": 1072,
        "syllable": "sưn",
        "consonant": "s",
        "rhyme": "ưn"
    },
    {
        "id": 1073,
        "syllable": "sưp",
        "consonant": "s",
        "rhyme": "ưp"
    },
    {
        "id": 1074,
        "syllable": "sưm",
        "consonant": "s",
        "rhyme": "ưm"
    },
    {
        "id": 1075,
        "syllable": "sưi",
        "consonant": "s",
        "rhyme": "ưi"
    },
    {
        "id": 1076,
        "syllable": "sưu",
        "consonant": "s",
        "rhyme": "ưu"
    },
    {
        "id": 1077,
        "syllable": "sơ",
        "consonant": "s",
        "rhyme": "ơ"
    },
    {
        "id": 1078,
        "syllable": "suơ",
        "consonant": "s",
        "rhyme": "uơ"
    },
    {
        "id": 1079,
        "syllable": "sơng",
        "consonant": "s",
        "rhyme": "ơng"
    },
    {
        "id": 1080,
        "syllable": "sơt",
        "consonant": "s",
        "rhyme": "ơt"
    },
    {
        "id": 1081,
        "syllable": "sơn",
        "consonant": "s",
        "rhyme": "ơn"
    },
    {
        "id": 1082,
        "syllable": "sơp",
        "consonant": "s",
        "rhyme": "ơp"
    },
    {
        "id": 1083,
        "syllable": "sơm",
        "consonant": "s",
        "rhyme": "ơm"
    },
    {
        "id": 1084,
        "syllable": "sơi",
        "consonant": "s",
        "rhyme": "ơi"
    },
    {
        "id": 1085,
        "syllable": "sâc",
        "consonant": "s",
        "rhyme": "âc"
    },
    {
        "id": 1086,
        "syllable": "sâng",
        "consonant": "s",
        "rhyme": "âng"
    },
    {
        "id": 1087,
        "syllable": "suâng",
        "consonant": "s",
        "rhyme": "uâng"
    },
    {
        "id": 1088,
        "syllable": "sât",
        "consonant": "s",
        "rhyme": "ât"
    },
    {
        "id": 1089,
        "syllable": "suât",
        "consonant": "s",
        "rhyme": "uât"
    },
    {
        "id": 1090,
        "syllable": "sân",
        "consonant": "s",
        "rhyme": "ân"
    },
    {
        "id": 1091,
        "syllable": "suân",
        "consonant": "s",
        "rhyme": "uân"
    },
    {
        "id": 1092,
        "syllable": "sâp",
        "consonant": "s",
        "rhyme": "âp"
    },
    {
        "id": 1093,
        "syllable": "suâp",
        "consonant": "s",
        "rhyme": "uâp"
    },
    {
        "id": 1094,
        "syllable": "sâm",
        "consonant": "s",
        "rhyme": "âm"
    },
    {
        "id": 1095,
        "syllable": "suâm",
        "consonant": "s",
        "rhyme": "uâm"
    },
    {
        "id": 1096,
        "syllable": "sây",
        "consonant": "s",
        "rhyme": "ây"
    },
    {
        "id": 1097,
        "syllable": "suây",
        "consonant": "s",
        "rhyme": "uây"
    },
    {
        "id": 1098,
        "syllable": "sâu",
        "consonant": "s",
        "rhyme": "âu"
    },
    {
        "id": 1099,
        "syllable": "suâu",
        "consonant": "s",
        "rhyme": "uâu"
    },
    {
        "id": 1100,
        "syllable": "su",
        "consonant": "s",
        "rhyme": "u"
    },
    {
        "id": 1101,
        "syllable": "suc",
        "consonant": "s",
        "rhyme": "uc"
    },
    {
        "id": 1102,
        "syllable": "sung",
        "consonant": "s",
        "rhyme": "ung"
    },
    {
        "id": 1103,
        "syllable": "sut",
        "consonant": "s",
        "rhyme": "ut"
    },
    {
        "id": 1104,
        "syllable": "sun",
        "consonant": "s",
        "rhyme": "un"
    },
    {
        "id": 1105,
        "syllable": "sup",
        "consonant": "s",
        "rhyme": "up"
    },
    {
        "id": 1106,
        "syllable": "sum",
        "consonant": "s",
        "rhyme": "um"
    },
    {
        "id": 1107,
        "syllable": "sui",
        "consonant": "s",
        "rhyme": "ui"
    },
    {
        "id": 1108,
        "syllable": "sô",
        "consonant": "s",
        "rhyme": "ô"
    },
    {
        "id": 1109,
        "syllable": "sôc",
        "consonant": "s",
        "rhyme": "ôc"
    },
    {
        "id": 1110,
        "syllable": "sông",
        "consonant": "s",
        "rhyme": "ông"
    },
    {
        "id": 1111,
        "syllable": "sôt",
        "consonant": "s",
        "rhyme": "ôt"
    },
    {
        "id": 1112,
        "syllable": "sôn",
        "consonant": "s",
        "rhyme": "ôn"
    },
    {
        "id": 1113,
        "syllable": "sôp",
        "consonant": "s",
        "rhyme": "ôp"
    },
    {
        "id": 1114,
        "syllable": "sôm",
        "consonant": "s",
        "rhyme": "ôm"
    },
    {
        "id": 1115,
        "syllable": "sôi",
        "consonant": "s",
        "rhyme": "ôi"
    },
    {
        "id": 1116,
        "syllable": "so",
        "consonant": "s",
        "rhyme": "o"
    },
    {
        "id": 1117,
        "syllable": "sooc",
        "consonant": "s",
        "rhyme": "ooc"
    },
    {
        "id": 1118,
        "syllable": "soong",
        "consonant": "s",
        "rhyme": "oong"
    },
    {
        "id": 1119,
        "syllable": "sot",
        "consonant": "s",
        "rhyme": "ot"
    },
    {
        "id": 1120,
        "syllable": "son",
        "consonant": "s",
        "rhyme": "on"
    },
    {
        "id": 1121,
        "syllable": "sop",
        "consonant": "s",
        "rhyme": "op"
    },
    {
        "id": 1122,
        "syllable": "som",
        "consonant": "s",
        "rhyme": "om"
    },
    {
        "id": 1123,
        "syllable": "soi",
        "consonant": "s",
        "rhyme": "oi"
    },
    {
        "id": 1124,
        "syllable": "soc",
        "consonant": "s",
        "rhyme": "oc"
    },
    {
        "id": 1125,
        "syllable": "song",
        "consonant": "s",
        "rhyme": "ong"
    },
    {
        "id": 1126,
        "syllable": "sua",
        "consonant": "s",
        "rhyme": "ua"
    },
    {
        "id": 1127,
        "syllable": "suôc",
        "consonant": "s",
        "rhyme": "uôc"
    },
    {
        "id": 1128,
        "syllable": "suông",
        "consonant": "s",
        "rhyme": "uông"
    },
    {
        "id": 1129,
        "syllable": "suôt",
        "consonant": "s",
        "rhyme": "uôt"
    },
    {
        "id": 1130,
        "syllable": "suôn",
        "consonant": "s",
        "rhyme": "uôn"
    },
    {
        "id": 1131,
        "syllable": "suôp",
        "consonant": "s",
        "rhyme": "uôp"
    },
    {
        "id": 1132,
        "syllable": "suôm",
        "consonant": "s",
        "rhyme": "uôm"
    },
    {
        "id": 1133,
        "syllable": "suôi",
        "consonant": "s",
        "rhyme": "uôi"
    },
    {
        "id": 1134,
        "syllable": "sưa",
        "consonant": "s",
        "rhyme": "ưa"
    },
    {
        "id": 1135,
        "syllable": "sươc",
        "consonant": "s",
        "rhyme": "ươc"
    },
    {
        "id": 1136,
        "syllable": "sương",
        "consonant": "s",
        "rhyme": "ương"
    },
    {
        "id": 1137,
        "syllable": "sươt",
        "consonant": "s",
        "rhyme": "ươt"
    },
    {
        "id": 1138,
        "syllable": "sươn",
        "consonant": "s",
        "rhyme": "ươn"
    },
    {
        "id": 1139,
        "syllable": "sươp",
        "consonant": "s",
        "rhyme": "ươp"
    },
    {
        "id": 1140,
        "syllable": "sươm",
        "consonant": "s",
        "rhyme": "ươm"
    },
    {
        "id": 1141,
        "syllable": "sươi",
        "consonant": "s",
        "rhyme": "ươi"
    },
    {
        "id": 1142,
        "syllable": "sa",
        "consonant": "s",
        "rhyme": "a"
    },
    {
        "id": 1143,
        "syllable": "soa",
        "consonant": "s",
        "rhyme": "oa"
    },
    {
        "id": 1144,
        "syllable": "sac",
        "consonant": "s",
        "rhyme": "ac"
    },
    {
        "id": 1145,
        "syllable": "soac",
        "consonant": "s",
        "rhyme": "oac"
    },
    {
        "id": 1146,
        "syllable": "sang",
        "consonant": "s",
        "rhyme": "ang"
    },
    {
        "id": 1147,
        "syllable": "soang",
        "consonant": "s",
        "rhyme": "oang"
    },
    {
        "id": 1148,
        "syllable": "sat",
        "consonant": "s",
        "rhyme": "at"
    },
    {
        "id": 1149,
        "syllable": "soat",
        "consonant": "s",
        "rhyme": "oat"
    },
    {
        "id": 1150,
        "syllable": "san",
        "consonant": "s",
        "rhyme": "an"
    },
    {
        "id": 1151,
        "syllable": "soan",
        "consonant": "s",
        "rhyme": "oan"
    },
    {
        "id": 1152,
        "syllable": "sap",
        "consonant": "s",
        "rhyme": "ap"
    },
    {
        "id": 1153,
        "syllable": "soap",
        "consonant": "s",
        "rhyme": "oap"
    },
    {
        "id": 1154,
        "syllable": "sam",
        "consonant": "s",
        "rhyme": "am"
    },
    {
        "id": 1155,
        "syllable": "soam",
        "consonant": "s",
        "rhyme": "oam"
    },
    {
        "id": 1156,
        "syllable": "sai",
        "consonant": "s",
        "rhyme": "ai"
    },
    {
        "id": 1157,
        "syllable": "soai",
        "consonant": "s",
        "rhyme": "oai"
    },
    {
        "id": 1158,
        "syllable": "sao",
        "consonant": "s",
        "rhyme": "ao"
    },
    {
        "id": 1159,
        "syllable": "soao",
        "consonant": "s",
        "rhyme": "oao"
    },
    {
        "id": 1160,
        "syllable": "săc",
        "consonant": "s",
        "rhyme": "ăc"
    },
    {
        "id": 1161,
        "syllable": "soăc",
        "consonant": "s",
        "rhyme": "oăc"
    },
    {
        "id": 1162,
        "syllable": "săng",
        "consonant": "s",
        "rhyme": "ăng"
    },
    {
        "id": 1163,
        "syllable": "soăng",
        "consonant": "s",
        "rhyme": "oăng"
    },
    {
        "id": 1164,
        "syllable": "săt",
        "consonant": "s",
        "rhyme": "ăt"
    },
    {
        "id": 1165,
        "syllable": "soăt",
        "consonant": "s",
        "rhyme": "oăt"
    },
    {
        "id": 1166,
        "syllable": "săn",
        "consonant": "s",
        "rhyme": "ăn"
    },
    {
        "id": 1167,
        "syllable": "soăn",
        "consonant": "s",
        "rhyme": "oăn"
    },
    {
        "id": 1168,
        "syllable": "săp",
        "consonant": "s",
        "rhyme": "ăp"
    },
    {
        "id": 1169,
        "syllable": "soăp",
        "consonant": "s",
        "rhyme": "oăp"
    },
    {
        "id": 1170,
        "syllable": "săm",
        "consonant": "s",
        "rhyme": "ăm"
    },
    {
        "id": 1171,
        "syllable": "soăm",
        "consonant": "s",
        "rhyme": "oăm"
    },
    {
        "id": 1172,
        "syllable": "say",
        "consonant": "s",
        "rhyme": "ay"
    },
    {
        "id": 1173,
        "syllable": "soay",
        "consonant": "s",
        "rhyme": "oay"
    },
    {
        "id": 1174,
        "syllable": "sau",
        "consonant": "s",
        "rhyme": "au"
    },
    {
        "id": 1175,
        "syllable": "soau",
        "consonant": "s",
        "rhyme": "oau"
    },
    {
        "id": 1176,
        "syllable": "khi",
        "consonant": "kh",
        "rhyme": "i"
    },
    {
        "id": 1177,
        "syllable": "khuy",
        "consonant": "kh",
        "rhyme": "uy"
    },
    {
        "id": 1178,
        "syllable": "khich",
        "consonant": "kh",
        "rhyme": "ich"
    },
    {
        "id": 1179,
        "syllable": "khuych",
        "consonant": "kh",
        "rhyme": "uych"
    },
    {
        "id": 1180,
        "syllable": "khinh",
        "consonant": "kh",
        "rhyme": "inh"
    },
    {
        "id": 1181,
        "syllable": "khuynh",
        "consonant": "kh",
        "rhyme": "uynh"
    },
    {
        "id": 1182,
        "syllable": "khit",
        "consonant": "kh",
        "rhyme": "it"
    },
    {
        "id": 1183,
        "syllable": "khuyt",
        "consonant": "kh",
        "rhyme": "uyt"
    },
    {
        "id": 1184,
        "syllable": "khin",
        "consonant": "kh",
        "rhyme": "in"
    },
    {
        "id": 1185,
        "syllable": "khuyn",
        "consonant": "kh",
        "rhyme": "uyn"
    },
    {
        "id": 1186,
        "syllable": "khip",
        "consonant": "kh",
        "rhyme": "ip"
    },
    {
        "id": 1187,
        "syllable": "khuyp",
        "consonant": "kh",
        "rhyme": "uyp"
    },
    {
        "id": 1188,
        "syllable": "khim",
        "consonant": "kh",
        "rhyme": "im"
    },
    {
        "id": 1189,
        "syllable": "khuym",
        "consonant": "kh",
        "rhyme": "uym"
    },
    {
        "id": 1190,
        "syllable": "khiu",
        "consonant": "kh",
        "rhyme": "iu"
    },
    {
        "id": 1191,
        "syllable": "khuyu",
        "consonant": "kh",
        "rhyme": "uyu"
    },
    {
        "id": 1192,
        "syllable": "khê",
        "consonant": "kh",
        "rhyme": "ê"
    },
    {
        "id": 1193,
        "syllable": "khuê",
        "consonant": "kh",
        "rhyme": "uê"
    },
    {
        "id": 1194,
        "syllable": "khêch",
        "consonant": "kh",
        "rhyme": "êch"
    },
    {
        "id": 1195,
        "syllable": "khuêch",
        "consonant": "kh",
        "rhyme": "uêch"
    },
    {
        "id": 1196,
        "syllable": "khênh",
        "consonant": "kh",
        "rhyme": "ênh"
    },
    {
        "id": 1197,
        "syllable": "khuênh",
        "consonant": "kh",
        "rhyme": "uênh"
    },
    {
        "id": 1198,
        "syllable": "khêt",
        "consonant": "kh",
        "rhyme": "êt"
    },
    {
        "id": 1199,
        "syllable": "khuêt",
        "consonant": "kh",
        "rhyme": "uêt"
    },
    {
        "id": 1200,
        "syllable": "khên",
        "consonant": "kh",
        "rhyme": "ên"
    },
    {
        "id": 1201,
        "syllable": "khuên",
        "consonant": "kh",
        "rhyme": "uên"
    },
    {
        "id": 1202,
        "syllable": "khêp",
        "consonant": "kh",
        "rhyme": "êp"
    },
    {
        "id": 1203,
        "syllable": "khuêp",
        "consonant": "kh",
        "rhyme": "uêp"
    },
    {
        "id": 1204,
        "syllable": "khêm",
        "consonant": "kh",
        "rhyme": "êm"
    },
    {
        "id": 1205,
        "syllable": "khuêm",
        "consonant": "kh",
        "rhyme": "uêm"
    },
    {
        "id": 1206,
        "syllable": "khêu",
        "consonant": "kh",
        "rhyme": "êu"
    },
    {
        "id": 1207,
        "syllable": "khe",
        "consonant": "kh",
        "rhyme": "e"
    },
    {
        "id": 1208,
        "syllable": "khoe",
        "consonant": "kh",
        "rhyme": "oe"
    },
    {
        "id": 1209,
        "syllable": "khec",
        "consonant": "kh",
        "rhyme": "ec"
    },
    {
        "id": 1210,
        "syllable": "khoec",
        "consonant": "kh",
        "rhyme": "oec"
    },
    {
        "id": 1211,
        "syllable": "kheng",
        "consonant": "kh",
        "rhyme": "eng"
    },
    {
        "id": 1212,
        "syllable": "khoeng",
        "consonant": "kh",
        "rhyme": "oeng"
    },
    {
        "id": 1213,
        "syllable": "khet",
        "consonant": "kh",
        "rhyme": "et"
    },
    {
        "id": 1214,
        "syllable": "khoet",
        "consonant": "kh",
        "rhyme": "oet"
    },
    {
        "id": 1215,
        "syllable": "khen",
        "consonant": "kh",
        "rhyme": "en"
    },
    {
        "id": 1216,
        "syllable": "khoen",
        "consonant": "kh",
        "rhyme": "oen"
    },
    {
        "id": 1217,
        "syllable": "khep",
        "consonant": "kh",
        "rhyme": "ep"
    },
    {
        "id": 1218,
        "syllable": "khoep",
        "consonant": "kh",
        "rhyme": "oep"
    },
    {
        "id": 1219,
        "syllable": "khem",
        "consonant": "kh",
        "rhyme": "em"
    },
    {
        "id": 1220,
        "syllable": "khoem",
        "consonant": "kh",
        "rhyme": "oem"
    },
    {
        "id": 1221,
        "syllable": "kheo",
        "consonant": "kh",
        "rhyme": "eo"
    },
    {
        "id": 1222,
        "syllable": "khoeo",
        "consonant": "kh",
        "rhyme": "oeo"
    },
    {
        "id": 1223,
        "syllable": "khach",
        "consonant": "kh",
        "rhyme": "ach"
    },
    {
        "id": 1224,
        "syllable": "khoach",
        "consonant": "kh",
        "rhyme": "oach"
    },
    {
        "id": 1225,
        "syllable": "khanh",
        "consonant": "kh",
        "rhyme": "anh"
    },
    {
        "id": 1226,
        "syllable": "khoanh",
        "consonant": "kh",
        "rhyme": "oanh"
    },
    {
        "id": 1227,
        "syllable": "khia",
        "consonant": "kh",
        "rhyme": "ia"
    },
    {
        "id": 1228,
        "syllable": "khuya",
        "consonant": "kh",
        "rhyme": "uya"
    },
    {
        "id": 1229,
        "syllable": "khiêc",
        "consonant": "kh",
        "rhyme": "iêc"
    },
    {
        "id": 1230,
        "syllable": "khiêng",
        "consonant": "kh",
        "rhyme": "iêng"
    },
    {
        "id": 1231,
        "syllable": "khiêt",
        "consonant": "kh",
        "rhyme": "iêt"
    },
    {
        "id": 1232,
        "syllable": "khiên",
        "consonant": "kh",
        "rhyme": "iên"
    },
    {
        "id": 1233,
        "syllable": "khuyêt",
        "consonant": "kh",
        "rhyme": "uyêt"
    },
    {
        "id": 1234,
        "syllable": "khuyên",
        "consonant": "kh",
        "rhyme": "uyên"
    },
    {
        "id": 1235,
        "syllable": "khiêp",
        "consonant": "kh",
        "rhyme": "iêp"
    },
    {
        "id": 1236,
        "syllable": "khuyêp",
        "consonant": "kh",
        "rhyme": "uyêp"
    },
    {
        "id": 1237,
        "syllable": "khiêm",
        "consonant": "kh",
        "rhyme": "iêm"
    },
    {
        "id": 1238,
        "syllable": "khuyêm",
        "consonant": "kh",
        "rhyme": "uyêm"
    },
    {
        "id": 1239,
        "syllable": "khiêu",
        "consonant": "kh",
        "rhyme": "iêu"
    },
    {
        "id": 1240,
        "syllable": "khư",
        "consonant": "kh",
        "rhyme": "ư"
    },
    {
        "id": 1241,
        "syllable": "khưc",
        "consonant": "kh",
        "rhyme": "ưc"
    },
    {
        "id": 1242,
        "syllable": "khưng",
        "consonant": "kh",
        "rhyme": "ưng"
    },
    {
        "id": 1243,
        "syllable": "khưt",
        "consonant": "kh",
        "rhyme": "ưt"
    },
    {
        "id": 1244,
        "syllable": "khưn",
        "consonant": "kh",
        "rhyme": "ưn"
    },
    {
        "id": 1245,
        "syllable": "khưp",
        "consonant": "kh",
        "rhyme": "ưp"
    },
    {
        "id": 1246,
        "syllable": "khưm",
        "consonant": "kh",
        "rhyme": "ưm"
    },
    {
        "id": 1247,
        "syllable": "khưi",
        "consonant": "kh",
        "rhyme": "ưi"
    },
    {
        "id": 1248,
        "syllable": "khưu",
        "consonant": "kh",
        "rhyme": "ưu"
    },
    {
        "id": 1249,
        "syllable": "khơ",
        "consonant": "kh",
        "rhyme": "ơ"
    },
    {
        "id": 1250,
        "syllable": "khuơ",
        "consonant": "kh",
        "rhyme": "uơ"
    },
    {
        "id": 1251,
        "syllable": "khơng",
        "consonant": "kh",
        "rhyme": "ơng"
    },
    {
        "id": 1252,
        "syllable": "khơt",
        "consonant": "kh",
        "rhyme": "ơt"
    },
    {
        "id": 1253,
        "syllable": "khơn",
        "consonant": "kh",
        "rhyme": "ơn"
    },
    {
        "id": 1254,
        "syllable": "khơp",
        "consonant": "kh",
        "rhyme": "ơp"
    },
    {
        "id": 1255,
        "syllable": "khơm",
        "consonant": "kh",
        "rhyme": "ơm"
    },
    {
        "id": 1256,
        "syllable": "khơi",
        "consonant": "kh",
        "rhyme": "ơi"
    },
    {
        "id": 1257,
        "syllable": "khâc",
        "consonant": "kh",
        "rhyme": "âc"
    },
    {
        "id": 1258,
        "syllable": "khâng",
        "consonant": "kh",
        "rhyme": "âng"
    },
    {
        "id": 1259,
        "syllable": "khuâng",
        "consonant": "kh",
        "rhyme": "uâng"
    },
    {
        "id": 1260,
        "syllable": "khât",
        "consonant": "kh",
        "rhyme": "ât"
    },
    {
        "id": 1261,
        "syllable": "khuât",
        "consonant": "kh",
        "rhyme": "uât"
    },
    {
        "id": 1262,
        "syllable": "khân",
        "consonant": "kh",
        "rhyme": "ân"
    },
    {
        "id": 1263,
        "syllable": "khuân",
        "consonant": "kh",
        "rhyme": "uân"
    },
    {
        "id": 1264,
        "syllable": "khâp",
        "consonant": "kh",
        "rhyme": "âp"
    },
    {
        "id": 1265,
        "syllable": "khuâp",
        "consonant": "kh",
        "rhyme": "uâp"
    },
    {
        "id": 1266,
        "syllable": "khâm",
        "consonant": "kh",
        "rhyme": "âm"
    },
    {
        "id": 1267,
        "syllable": "khuâm",
        "consonant": "kh",
        "rhyme": "uâm"
    },
    {
        "id": 1268,
        "syllable": "khây",
        "consonant": "kh",
        "rhyme": "ây"
    },
    {
        "id": 1269,
        "syllable": "khuây",
        "consonant": "kh",
        "rhyme": "uây"
    },
    {
        "id": 1270,
        "syllable": "khâu",
        "consonant": "kh",
        "rhyme": "âu"
    },
    {
        "id": 1271,
        "syllable": "khuâu",
        "consonant": "kh",
        "rhyme": "uâu"
    },
    {
        "id": 1272,
        "syllable": "khu",
        "consonant": "kh",
        "rhyme": "u"
    },
    {
        "id": 1273,
        "syllable": "khuc",
        "consonant": "kh",
        "rhyme": "uc"
    },
    {
        "id": 1274,
        "syllable": "khung",
        "consonant": "kh",
        "rhyme": "ung"
    },
    {
        "id": 1275,
        "syllable": "khut",
        "consonant": "kh",
        "rhyme": "ut"
    },
    {
        "id": 1276,
        "syllable": "khun",
        "consonant": "kh",
        "rhyme": "un"
    },
    {
        "id": 1277,
        "syllable": "khup",
        "consonant": "kh",
        "rhyme": "up"
    },
    {
        "id": 1278,
        "syllable": "khum",
        "consonant": "kh",
        "rhyme": "um"
    },
    {
        "id": 1279,
        "syllable": "khui",
        "consonant": "kh",
        "rhyme": "ui"
    },
    {
        "id": 1280,
        "syllable": "khô",
        "consonant": "kh",
        "rhyme": "ô"
    },
    {
        "id": 1281,
        "syllable": "khôc",
        "consonant": "kh",
        "rhyme": "ôc"
    },
    {
        "id": 1282,
        "syllable": "không",
        "consonant": "kh",
        "rhyme": "ông"
    },
    {
        "id": 1283,
        "syllable": "khôt",
        "consonant": "kh",
        "rhyme": "ôt"
    },
    {
        "id": 1284,
        "syllable": "khôn",
        "consonant": "kh",
        "rhyme": "ôn"
    },
    {
        "id": 1285,
        "syllable": "khôp",
        "consonant": "kh",
        "rhyme": "ôp"
    },
    {
        "id": 1286,
        "syllable": "khôm",
        "consonant": "kh",
        "rhyme": "ôm"
    },
    {
        "id": 1287,
        "syllable": "khôi",
        "consonant": "kh",
        "rhyme": "ôi"
    },
    {
        "id": 1288,
        "syllable": "kho",
        "consonant": "kh",
        "rhyme": "o"
    },
    {
        "id": 1289,
        "syllable": "khooc",
        "consonant": "kh",
        "rhyme": "ooc"
    },
    {
        "id": 1290,
        "syllable": "khoong",
        "consonant": "kh",
        "rhyme": "oong"
    },
    {
        "id": 1291,
        "syllable": "khot",
        "consonant": "kh",
        "rhyme": "ot"
    },
    {
        "id": 1292,
        "syllable": "khon",
        "consonant": "kh",
        "rhyme": "on"
    },
    {
        "id": 1293,
        "syllable": "khop",
        "consonant": "kh",
        "rhyme": "op"
    },
    {
        "id": 1294,
        "syllable": "khom",
        "consonant": "kh",
        "rhyme": "om"
    },
    {
        "id": 1295,
        "syllable": "khoi",
        "consonant": "kh",
        "rhyme": "oi"
    },
    {
        "id": 1296,
        "syllable": "khoc",
        "consonant": "kh",
        "rhyme": "oc"
    },
    {
        "id": 1297,
        "syllable": "khong",
        "consonant": "kh",
        "rhyme": "ong"
    },
    {
        "id": 1298,
        "syllable": "khua",
        "consonant": "kh",
        "rhyme": "ua"
    },
    {
        "id": 1299,
        "syllable": "khuôc",
        "consonant": "kh",
        "rhyme": "uôc"
    },
    {
        "id": 1300,
        "syllable": "khuông",
        "consonant": "kh",
        "rhyme": "uông"
    },
    {
        "id": 1301,
        "syllable": "khuôt",
        "consonant": "kh",
        "rhyme": "uôt"
    },
    {
        "id": 1302,
        "syllable": "khuôn",
        "consonant": "kh",
        "rhyme": "uôn"
    },
    {
        "id": 1303,
        "syllable": "khuôp",
        "consonant": "kh",
        "rhyme": "uôp"
    },
    {
        "id": 1304,
        "syllable": "khuôm",
        "consonant": "kh",
        "rhyme": "uôm"
    },
    {
        "id": 1305,
        "syllable": "khuôi",
        "consonant": "kh",
        "rhyme": "uôi"
    },
    {
        "id": 1306,
        "syllable": "khưa",
        "consonant": "kh",
        "rhyme": "ưa"
    },
    {
        "id": 1307,
        "syllable": "khươc",
        "consonant": "kh",
        "rhyme": "ươc"
    },
    {
        "id": 1308,
        "syllable": "khương",
        "consonant": "kh",
        "rhyme": "ương"
    },
    {
        "id": 1309,
        "syllable": "khươt",
        "consonant": "kh",
        "rhyme": "ươt"
    },
    {
        "id": 1310,
        "syllable": "khươn",
        "consonant": "kh",
        "rhyme": "ươn"
    },
    {
        "id": 1311,
        "syllable": "khươp",
        "consonant": "kh",
        "rhyme": "ươp"
    },
    {
        "id": 1312,
        "syllable": "khươm",
        "consonant": "kh",
        "rhyme": "ươm"
    },
    {
        "id": 1313,
        "syllable": "khươi",
        "consonant": "kh",
        "rhyme": "ươi"
    },
    {
        "id": 1314,
        "syllable": "kha",
        "consonant": "kh",
        "rhyme": "a"
    },
    {
        "id": 1315,
        "syllable": "khoa",
        "consonant": "kh",
        "rhyme": "oa"
    },
    {
        "id": 1316,
        "syllable": "khac",
        "consonant": "kh",
        "rhyme": "ac"
    },
    {
        "id": 1317,
        "syllable": "khoac",
        "consonant": "kh",
        "rhyme": "oac"
    },
    {
        "id": 1318,
        "syllable": "khang",
        "consonant": "kh",
        "rhyme": "ang"
    },
    {
        "id": 1319,
        "syllable": "khoang",
        "consonant": "kh",
        "rhyme": "oang"
    },
    {
        "id": 1320,
        "syllable": "khat",
        "consonant": "kh",
        "rhyme": "at"
    },
    {
        "id": 1321,
        "syllable": "khoat",
        "consonant": "kh",
        "rhyme": "oat"
    },
    {
        "id": 1322,
        "syllable": "khan",
        "consonant": "kh",
        "rhyme": "an"
    },
    {
        "id": 1323,
        "syllable": "khoan",
        "consonant": "kh",
        "rhyme": "oan"
    },
    {
        "id": 1324,
        "syllable": "khap",
        "consonant": "kh",
        "rhyme": "ap"
    },
    {
        "id": 1325,
        "syllable": "khoap",
        "consonant": "kh",
        "rhyme": "oap"
    },
    {
        "id": 1326,
        "syllable": "kham",
        "consonant": "kh",
        "rhyme": "am"
    },
    {
        "id": 1327,
        "syllable": "khoam",
        "consonant": "kh",
        "rhyme": "oam"
    },
    {
        "id": 1328,
        "syllable": "khai",
        "consonant": "kh",
        "rhyme": "ai"
    },
    {
        "id": 1329,
        "syllable": "khoai",
        "consonant": "kh",
        "rhyme": "oai"
    },
    {
        "id": 1330,
        "syllable": "khao",
        "consonant": "kh",
        "rhyme": "ao"
    },
    {
        "id": 1331,
        "syllable": "khoao",
        "consonant": "kh",
        "rhyme": "oao"
    },
    {
        "id": 1332,
        "syllable": "khăc",
        "consonant": "kh",
        "rhyme": "ăc"
    },
    {
        "id": 1333,
        "syllable": "khoăc",
        "consonant": "kh",
        "rhyme": "oăc"
    },
    {
        "id": 1334,
        "syllable": "khăng",
        "consonant": "kh",
        "rhyme": "ăng"
    },
    {
        "id": 1335,
        "syllable": "khoăng",
        "consonant": "kh",
        "rhyme": "oăng"
    },
    {
        "id": 1336,
        "syllable": "khăt",
        "consonant": "kh",
        "rhyme": "ăt"
    },
    {
        "id": 1337,
        "syllable": "khoăt",
        "consonant": "kh",
        "rhyme": "oăt"
    },
    {
        "id": 1338,
        "syllable": "khăn",
        "consonant": "kh",
        "rhyme": "ăn"
    },
    {
        "id": 1339,
        "syllable": "khoăn",
        "consonant": "kh",
        "rhyme": "oăn"
    },
    {
        "id": 1340,
        "syllable": "khăp",
        "consonant": "kh",
        "rhyme": "ăp"
    },
    {
        "id": 1341,
        "syllable": "khoăp",
        "consonant": "kh",
        "rhyme": "oăp"
    },
    {
        "id": 1342,
        "syllable": "khăm",
        "consonant": "kh",
        "rhyme": "ăm"
    },
    {
        "id": 1343,
        "syllable": "khoăm",
        "consonant": "kh",
        "rhyme": "oăm"
    },
    {
        "id": 1344,
        "syllable": "khay",
        "consonant": "kh",
        "rhyme": "ay"
    },
    {
        "id": 1345,
        "syllable": "khoay",
        "consonant": "kh",
        "rhyme": "oay"
    },
    {
        "id": 1346,
        "syllable": "khau",
        "consonant": "kh",
        "rhyme": "au"
    },
    {
        "id": 1347,
        "syllable": "khoau",
        "consonant": "kh",
        "rhyme": "oau"
    },
    {
        "id": 1348,
        "syllable": "li",
        "consonant": "l",
        "rhyme": "i"
    },
    {
        "id": 1349,
        "syllable": "luy",
        "consonant": "l",
        "rhyme": "uy"
    },
    {
        "id": 1350,
        "syllable": "lich",
        "consonant": "l",
        "rhyme": "ich"
    },
    {
        "id": 1351,
        "syllable": "luych",
        "consonant": "l",
        "rhyme": "uych"
    },
    {
        "id": 1352,
        "syllable": "linh",
        "consonant": "l",
        "rhyme": "inh"
    },
    {
        "id": 1353,
        "syllable": "luynh",
        "consonant": "l",
        "rhyme": "uynh"
    },
    {
        "id": 1354,
        "syllable": "lit",
        "consonant": "l",
        "rhyme": "it"
    },
    {
        "id": 1355,
        "syllable": "luyt",
        "consonant": "l",
        "rhyme": "uyt"
    },
    {
        "id": 1356,
        "syllable": "lin",
        "consonant": "l",
        "rhyme": "in"
    },
    {
        "id": 1357,
        "syllable": "luyn",
        "consonant": "l",
        "rhyme": "uyn"
    },
    {
        "id": 1358,
        "syllable": "lip",
        "consonant": "l",
        "rhyme": "ip"
    },
    {
        "id": 1359,
        "syllable": "luyp",
        "consonant": "l",
        "rhyme": "uyp"
    },
    {
        "id": 1360,
        "syllable": "lim",
        "consonant": "l",
        "rhyme": "im"
    },
    {
        "id": 1361,
        "syllable": "luym",
        "consonant": "l",
        "rhyme": "uym"
    },
    {
        "id": 1362,
        "syllable": "liu",
        "consonant": "l",
        "rhyme": "iu"
    },
    {
        "id": 1363,
        "syllable": "luyu",
        "consonant": "l",
        "rhyme": "uyu"
    },
    {
        "id": 1364,
        "syllable": "lê",
        "consonant": "l",
        "rhyme": "ê"
    },
    {
        "id": 1365,
        "syllable": "luê",
        "consonant": "l",
        "rhyme": "uê"
    },
    {
        "id": 1366,
        "syllable": "lêch",
        "consonant": "l",
        "rhyme": "êch"
    },
    {
        "id": 1367,
        "syllable": "luêch",
        "consonant": "l",
        "rhyme": "uêch"
    },
    {
        "id": 1368,
        "syllable": "lênh",
        "consonant": "l",
        "rhyme": "ênh"
    },
    {
        "id": 1369,
        "syllable": "luênh",
        "consonant": "l",
        "rhyme": "uênh"
    },
    {
        "id": 1370,
        "syllable": "lêt",
        "consonant": "l",
        "rhyme": "êt"
    },
    {
        "id": 1371,
        "syllable": "luêt",
        "consonant": "l",
        "rhyme": "uêt"
    },
    {
        "id": 1372,
        "syllable": "lên",
        "consonant": "l",
        "rhyme": "ên"
    },
    {
        "id": 1373,
        "syllable": "luên",
        "consonant": "l",
        "rhyme": "uên"
    },
    {
        "id": 1374,
        "syllable": "lêp",
        "consonant": "l",
        "rhyme": "êp"
    },
    {
        "id": 1375,
        "syllable": "luêp",
        "consonant": "l",
        "rhyme": "uêp"
    },
    {
        "id": 1376,
        "syllable": "lêm",
        "consonant": "l",
        "rhyme": "êm"
    },
    {
        "id": 1377,
        "syllable": "luêm",
        "consonant": "l",
        "rhyme": "uêm"
    },
    {
        "id": 1378,
        "syllable": "lêu",
        "consonant": "l",
        "rhyme": "êu"
    },
    {
        "id": 1379,
        "syllable": "le",
        "consonant": "l",
        "rhyme": "e"
    },
    {
        "id": 1380,
        "syllable": "loe",
        "consonant": "l",
        "rhyme": "oe"
    },
    {
        "id": 1381,
        "syllable": "lec",
        "consonant": "l",
        "rhyme": "ec"
    },
    {
        "id": 1382,
        "syllable": "loec",
        "consonant": "l",
        "rhyme": "oec"
    },
    {
        "id": 1383,
        "syllable": "leng",
        "consonant": "l",
        "rhyme": "eng"
    },
    {
        "id": 1384,
        "syllable": "loeng",
        "consonant": "l",
        "rhyme": "oeng"
    },
    {
        "id": 1385,
        "syllable": "let",
        "consonant": "l",
        "rhyme": "et"
    },
    {
        "id": 1386,
        "syllable": "loet",
        "consonant": "l",
        "rhyme": "oet"
    },
    {
        "id": 1387,
        "syllable": "len",
        "consonant": "l",
        "rhyme": "en"
    },
    {
        "id": 1388,
        "syllable": "loen",
        "consonant": "l",
        "rhyme": "oen"
    },
    {
        "id": 1389,
        "syllable": "lep",
        "consonant": "l",
        "rhyme": "ep"
    },
    {
        "id": 1390,
        "syllable": "loep",
        "consonant": "l",
        "rhyme": "oep"
    },
    {
        "id": 1391,
        "syllable": "lem",
        "consonant": "l",
        "rhyme": "em"
    },
    {
        "id": 1392,
        "syllable": "loem",
        "consonant": "l",
        "rhyme": "oem"
    },
    {
        "id": 1393,
        "syllable": "leo",
        "consonant": "l",
        "rhyme": "eo"
    },
    {
        "id": 1394,
        "syllable": "loeo",
        "consonant": "l",
        "rhyme": "oeo"
    },
    {
        "id": 1395,
        "syllable": "lach",
        "consonant": "l",
        "rhyme": "ach"
    },
    {
        "id": 1396,
        "syllable": "loach",
        "consonant": "l",
        "rhyme": "oach"
    },
    {
        "id": 1397,
        "syllable": "lanh",
        "consonant": "l",
        "rhyme": "anh"
    },
    {
        "id": 1398,
        "syllable": "loanh",
        "consonant": "l",
        "rhyme": "oanh"
    },
    {
        "id": 1399,
        "syllable": "lia",
        "consonant": "l",
        "rhyme": "ia"
    },
    {
        "id": 1400,
        "syllable": "luya",
        "consonant": "l",
        "rhyme": "uya"
    },
    {
        "id": 1401,
        "syllable": "liêc",
        "consonant": "l",
        "rhyme": "iêc"
    },
    {
        "id": 1402,
        "syllable": "liêng",
        "consonant": "l",
        "rhyme": "iêng"
    },
    {
        "id": 1403,
        "syllable": "liêt",
        "consonant": "l",
        "rhyme": "iêt"
    },
    {
        "id": 1404,
        "syllable": "liên",
        "consonant": "l",
        "rhyme": "iên"
    },
    {
        "id": 1405,
        "syllable": "luyêt",
        "consonant": "l",
        "rhyme": "uyêt"
    },
    {
        "id": 1406,
        "syllable": "luyên",
        "consonant": "l",
        "rhyme": "uyên"
    },
    {
        "id": 1407,
        "syllable": "liêp",
        "consonant": "l",
        "rhyme": "iêp"
    },
    {
        "id": 1408,
        "syllable": "luyêp",
        "consonant": "l",
        "rhyme": "uyêp"
    },
    {
        "id": 1409,
        "syllable": "liêm",
        "consonant": "l",
        "rhyme": "iêm"
    },
    {
        "id": 1410,
        "syllable": "luyêm",
        "consonant": "l",
        "rhyme": "uyêm"
    },
    {
        "id": 1411,
        "syllable": "liêu",
        "consonant": "l",
        "rhyme": "iêu"
    },
    {
        "id": 1412,
        "syllable": "lư",
        "consonant": "l",
        "rhyme": "ư"
    },
    {
        "id": 1413,
        "syllable": "lưc",
        "consonant": "l",
        "rhyme": "ưc"
    },
    {
        "id": 1414,
        "syllable": "lưng",
        "consonant": "l",
        "rhyme": "ưng"
    },
    {
        "id": 1415,
        "syllable": "lưt",
        "consonant": "l",
        "rhyme": "ưt"
    },
    {
        "id": 1416,
        "syllable": "lưn",
        "consonant": "l",
        "rhyme": "ưn"
    },
    {
        "id": 1417,
        "syllable": "lưp",
        "consonant": "l",
        "rhyme": "ưp"
    },
    {
        "id": 1418,
        "syllable": "lưm",
        "consonant": "l",
        "rhyme": "ưm"
    },
    {
        "id": 1419,
        "syllable": "lưi",
        "consonant": "l",
        "rhyme": "ưi"
    },
    {
        "id": 1420,
        "syllable": "lưu",
        "consonant": "l",
        "rhyme": "ưu"
    },
    {
        "id": 1421,
        "syllable": "lơ",
        "consonant": "l",
        "rhyme": "ơ"
    },
    {
        "id": 1422,
        "syllable": "luơ",
        "consonant": "l",
        "rhyme": "uơ"
    },
    {
        "id": 1423,
        "syllable": "lơng",
        "consonant": "l",
        "rhyme": "ơng"
    },
    {
        "id": 1424,
        "syllable": "lơt",
        "consonant": "l",
        "rhyme": "ơt"
    },
    {
        "id": 1425,
        "syllable": "lơn",
        "consonant": "l",
        "rhyme": "ơn"
    },
    {
        "id": 1426,
        "syllable": "lơp",
        "consonant": "l",
        "rhyme": "ơp"
    },
    {
        "id": 1427,
        "syllable": "lơm",
        "consonant": "l",
        "rhyme": "ơm"
    },
    {
        "id": 1428,
        "syllable": "lơi",
        "consonant": "l",
        "rhyme": "ơi"
    },
    {
        "id": 1429,
        "syllable": "lâc",
        "consonant": "l",
        "rhyme": "âc"
    },
    {
        "id": 1430,
        "syllable": "lâng",
        "consonant": "l",
        "rhyme": "âng"
    },
    {
        "id": 1431,
        "syllable": "luâng",
        "consonant": "l",
        "rhyme": "uâng"
    },
    {
        "id": 1432,
        "syllable": "lât",
        "consonant": "l",
        "rhyme": "ât"
    },
    {
        "id": 1433,
        "syllable": "luât",
        "consonant": "l",
        "rhyme": "uât"
    },
    {
        "id": 1434,
        "syllable": "lân",
        "consonant": "l",
        "rhyme": "ân"
    },
    {
        "id": 1435,
        "syllable": "luân",
        "consonant": "l",
        "rhyme": "uân"
    },
    {
        "id": 1436,
        "syllable": "lâp",
        "consonant": "l",
        "rhyme": "âp"
    },
    {
        "id": 1437,
        "syllable": "luâp",
        "consonant": "l",
        "rhyme": "uâp"
    },
    {
        "id": 1438,
        "syllable": "lâm",
        "consonant": "l",
        "rhyme": "âm"
    },
    {
        "id": 1439,
        "syllable": "luâm",
        "consonant": "l",
        "rhyme": "uâm"
    },
    {
        "id": 1440,
        "syllable": "lây",
        "consonant": "l",
        "rhyme": "ây"
    },
    {
        "id": 1441,
        "syllable": "luây",
        "consonant": "l",
        "rhyme": "uây"
    },
    {
        "id": 1442,
        "syllable": "lâu",
        "consonant": "l",
        "rhyme": "âu"
    },
    {
        "id": 1443,
        "syllable": "luâu",
        "consonant": "l",
        "rhyme": "uâu"
    },
    {
        "id": 1444,
        "syllable": "lu",
        "consonant": "l",
        "rhyme": "u"
    },
    {
        "id": 1445,
        "syllable": "luc",
        "consonant": "l",
        "rhyme": "uc"
    },
    {
        "id": 1446,
        "syllable": "lung",
        "consonant": "l",
        "rhyme": "ung"
    },
    {
        "id": 1447,
        "syllable": "lut",
        "consonant": "l",
        "rhyme": "ut"
    },
    {
        "id": 1448,
        "syllable": "lun",
        "consonant": "l",
        "rhyme": "un"
    },
    {
        "id": 1449,
        "syllable": "lup",
        "consonant": "l",
        "rhyme": "up"
    },
    {
        "id": 1450,
        "syllable": "lum",
        "consonant": "l",
        "rhyme": "um"
    },
    {
        "id": 1451,
        "syllable": "lui",
        "consonant": "l",
        "rhyme": "ui"
    },
    {
        "id": 1452,
        "syllable": "lô",
        "consonant": "l",
        "rhyme": "ô"
    },
    {
        "id": 1453,
        "syllable": "lôc",
        "consonant": "l",
        "rhyme": "ôc"
    },
    {
        "id": 1454,
        "syllable": "lông",
        "consonant": "l",
        "rhyme": "ông"
    },
    {
        "id": 1455,
        "syllable": "lôt",
        "consonant": "l",
        "rhyme": "ôt"
    },
    {
        "id": 1456,
        "syllable": "lôn",
        "consonant": "l",
        "rhyme": "ôn"
    },
    {
        "id": 1457,
        "syllable": "lôp",
        "consonant": "l",
        "rhyme": "ôp"
    },
    {
        "id": 1458,
        "syllable": "lôm",
        "consonant": "l",
        "rhyme": "ôm"
    },
    {
        "id": 1459,
        "syllable": "lôi",
        "consonant": "l",
        "rhyme": "ôi"
    },
    {
        "id": 1460,
        "syllable": "lo",
        "consonant": "l",
        "rhyme": "o"
    },
    {
        "id": 1461,
        "syllable": "looc",
        "consonant": "l",
        "rhyme": "ooc"
    },
    {
        "id": 1462,
        "syllable": "loong",
        "consonant": "l",
        "rhyme": "oong"
    },
    {
        "id": 1463,
        "syllable": "lot",
        "consonant": "l",
        "rhyme": "ot"
    },
    {
        "id": 1464,
        "syllable": "lon",
        "consonant": "l",
        "rhyme": "on"
    },
    {
        "id": 1465,
        "syllable": "lop",
        "consonant": "l",
        "rhyme": "op"
    },
    {
        "id": 1466,
        "syllable": "lom",
        "consonant": "l",
        "rhyme": "om"
    },
    {
        "id": 1467,
        "syllable": "loi",
        "consonant": "l",
        "rhyme": "oi"
    },
    {
        "id": 1468,
        "syllable": "loc",
        "consonant": "l",
        "rhyme": "oc"
    },
    {
        "id": 1469,
        "syllable": "long",
        "consonant": "l",
        "rhyme": "ong"
    },
    {
        "id": 1470,
        "syllable": "lua",
        "consonant": "l",
        "rhyme": "ua"
    },
    {
        "id": 1471,
        "syllable": "luôc",
        "consonant": "l",
        "rhyme": "uôc"
    },
    {
        "id": 1472,
        "syllable": "luông",
        "consonant": "l",
        "rhyme": "uông"
    },
    {
        "id": 1473,
        "syllable": "luôt",
        "consonant": "l",
        "rhyme": "uôt"
    },
    {
        "id": 1474,
        "syllable": "luôn",
        "consonant": "l",
        "rhyme": "uôn"
    },
    {
        "id": 1475,
        "syllable": "luôp",
        "consonant": "l",
        "rhyme": "uôp"
    },
    {
        "id": 1476,
        "syllable": "luôm",
        "consonant": "l",
        "rhyme": "uôm"
    },
    {
        "id": 1477,
        "syllable": "luôi",
        "consonant": "l",
        "rhyme": "uôi"
    },
    {
        "id": 1478,
        "syllable": "lưa",
        "consonant": "l",
        "rhyme": "ưa"
    },
    {
        "id": 1479,
        "syllable": "lươc",
        "consonant": "l",
        "rhyme": "ươc"
    },
    {
        "id": 1480,
        "syllable": "lương",
        "consonant": "l",
        "rhyme": "ương"
    },
    {
        "id": 1481,
        "syllable": "lươt",
        "consonant": "l",
        "rhyme": "ươt"
    },
    {
        "id": 1482,
        "syllable": "lươn",
        "consonant": "l",
        "rhyme": "ươn"
    },
    {
        "id": 1483,
        "syllable": "lươp",
        "consonant": "l",
        "rhyme": "ươp"
    },
    {
        "id": 1484,
        "syllable": "lươm",
        "consonant": "l",
        "rhyme": "ươm"
    },
    {
        "id": 1485,
        "syllable": "lươi",
        "consonant": "l",
        "rhyme": "ươi"
    },
    {
        "id": 1486,
        "syllable": "la",
        "consonant": "l",
        "rhyme": "a"
    },
    {
        "id": 1487,
        "syllable": "loa",
        "consonant": "l",
        "rhyme": "oa"
    },
    {
        "id": 1488,
        "syllable": "lac",
        "consonant": "l",
        "rhyme": "ac"
    },
    {
        "id": 1489,
        "syllable": "loac",
        "consonant": "l",
        "rhyme": "oac"
    },
    {
        "id": 1490,
        "syllable": "lang",
        "consonant": "l",
        "rhyme": "ang"
    },
    {
        "id": 1491,
        "syllable": "loang",
        "consonant": "l",
        "rhyme": "oang"
    },
    {
        "id": 1492,
        "syllable": "lat",
        "consonant": "l",
        "rhyme": "at"
    },
    {
        "id": 1493,
        "syllable": "loat",
        "consonant": "l",
        "rhyme": "oat"
    },
    {
        "id": 1494,
        "syllable": "lan",
        "consonant": "l",
        "rhyme": "an"
    },
    {
        "id": 1495,
        "syllable": "loan",
        "consonant": "l",
        "rhyme": "oan"
    },
    {
        "id": 1496,
        "syllable": "lap",
        "consonant": "l",
        "rhyme": "ap"
    },
    {
        "id": 1497,
        "syllable": "loap",
        "consonant": "l",
        "rhyme": "oap"
    },
    {
        "id": 1498,
        "syllable": "lam",
        "consonant": "l",
        "rhyme": "am"
    },
    {
        "id": 1499,
        "syllable": "loam",
        "consonant": "l",
        "rhyme": "oam"
    },
    {
        "id": 1500,
        "syllable": "lai",
        "consonant": "l",
        "rhyme": "ai"
    },
    {
        "id": 1501,
        "syllable": "loai",
        "consonant": "l",
        "rhyme": "oai"
    },
    {
        "id": 1502,
        "syllable": "lao",
        "consonant": "l",
        "rhyme": "ao"
    },
    {
        "id": 1503,
        "syllable": "loao",
        "consonant": "l",
        "rhyme": "oao"
    },
    {
        "id": 1504,
        "syllable": "lăc",
        "consonant": "l",
        "rhyme": "ăc"
    },
    {
        "id": 1505,
        "syllable": "loăc",
        "consonant": "l",
        "rhyme": "oăc"
    },
    {
        "id": 1506,
        "syllable": "lăng",
        "consonant": "l",
        "rhyme": "ăng"
    },
    {
        "id": 1507,
        "syllable": "loăng",
        "consonant": "l",
        "rhyme": "oăng"
    },
    {
        "id": 1508,
        "syllable": "lăt",
        "consonant": "l",
        "rhyme": "ăt"
    },
    {
        "id": 1509,
        "syllable": "loăt",
        "consonant": "l",
        "rhyme": "oăt"
    },
    {
        "id": 1510,
        "syllable": "lăn",
        "consonant": "l",
        "rhyme": "ăn"
    },
    {
        "id": 1511,
        "syllable": "loăn",
        "consonant": "l",
        "rhyme": "oăn"
    },
    {
        "id": 1512,
        "syllable": "lăp",
        "consonant": "l",
        "rhyme": "ăp"
    },
    {
        "id": 1513,
        "syllable": "loăp",
        "consonant": "l",
        "rhyme": "oăp"
    },
    {
        "id": 1514,
        "syllable": "lăm",
        "consonant": "l",
        "rhyme": "ăm"
    },
    {
        "id": 1515,
        "syllable": "loăm",
        "consonant": "l",
        "rhyme": "oăm"
    },
    {
        "id": 1516,
        "syllable": "lay",
        "consonant": "l",
        "rhyme": "ay"
    },
    {
        "id": 1517,
        "syllable": "loay",
        "consonant": "l",
        "rhyme": "oay"
    },
    {
        "id": 1518,
        "syllable": "lau",
        "consonant": "l",
        "rhyme": "au"
    },
    {
        "id": 1519,
        "syllable": "loau",
        "consonant": "l",
        "rhyme": "oau"
    },
    {
        "id": 1520,
        "syllable": "vi",
        "consonant": "v",
        "rhyme": "i"
    },
    {
        "id": 1521,
        "syllable": "vuy",
        "consonant": "v",
        "rhyme": "uy"
    },
    {
        "id": 1522,
        "syllable": "vich",
        "consonant": "v",
        "rhyme": "ich"
    },
    {
        "id": 1523,
        "syllable": "vuych",
        "consonant": "v",
        "rhyme": "uych"
    },
    {
        "id": 1524,
        "syllable": "vinh",
        "consonant": "v",
        "rhyme": "inh"
    },
    {
        "id": 1525,
        "syllable": "vuynh",
        "consonant": "v",
        "rhyme": "uynh"
    },
    {
        "id": 1526,
        "syllable": "vit",
        "consonant": "v",
        "rhyme": "it"
    },
    {
        "id": 1527,
        "syllable": "vuyt",
        "consonant": "v",
        "rhyme": "uyt"
    },
    {
        "id": 1528,
        "syllable": "vin",
        "consonant": "v",
        "rhyme": "in"
    },
    {
        "id": 1529,
        "syllable": "vuyn",
        "consonant": "v",
        "rhyme": "uyn"
    },
    {
        "id": 1530,
        "syllable": "vip",
        "consonant": "v",
        "rhyme": "ip"
    },
    {
        "id": 1531,
        "syllable": "vuyp",
        "consonant": "v",
        "rhyme": "uyp"
    },
    {
        "id": 1532,
        "syllable": "vim",
        "consonant": "v",
        "rhyme": "im"
    },
    {
        "id": 1533,
        "syllable": "vuym",
        "consonant": "v",
        "rhyme": "uym"
    },
    {
        "id": 1534,
        "syllable": "viu",
        "consonant": "v",
        "rhyme": "iu"
    },
    {
        "id": 1535,
        "syllable": "vuyu",
        "consonant": "v",
        "rhyme": "uyu"
    },
    {
        "id": 1536,
        "syllable": "vê",
        "consonant": "v",
        "rhyme": "ê"
    },
    {
        "id": 1537,
        "syllable": "vuê",
        "consonant": "v",
        "rhyme": "uê"
    },
    {
        "id": 1538,
        "syllable": "vêch",
        "consonant": "v",
        "rhyme": "êch"
    },
    {
        "id": 1539,
        "syllable": "vuêch",
        "consonant": "v",
        "rhyme": "uêch"
    },
    {
        "id": 1540,
        "syllable": "vênh",
        "consonant": "v",
        "rhyme": "ênh"
    },
    {
        "id": 1541,
        "syllable": "vuênh",
        "consonant": "v",
        "rhyme": "uênh"
    },
    {
        "id": 1542,
        "syllable": "vêt",
        "consonant": "v",
        "rhyme": "êt"
    },
    {
        "id": 1543,
        "syllable": "vuêt",
        "consonant": "v",
        "rhyme": "uêt"
    },
    {
        "id": 1544,
        "syllable": "vên",
        "consonant": "v",
        "rhyme": "ên"
    },
    {
        "id": 1545,
        "syllable": "vuên",
        "consonant": "v",
        "rhyme": "uên"
    },
    {
        "id": 1546,
        "syllable": "vêp",
        "consonant": "v",
        "rhyme": "êp"
    },
    {
        "id": 1547,
        "syllable": "vuêp",
        "consonant": "v",
        "rhyme": "uêp"
    },
    {
        "id": 1548,
        "syllable": "vêm",
        "consonant": "v",
        "rhyme": "êm"
    },
    {
        "id": 1549,
        "syllable": "vuêm",
        "consonant": "v",
        "rhyme": "uêm"
    },
    {
        "id": 1550,
        "syllable": "vêu",
        "consonant": "v",
        "rhyme": "êu"
    },
    {
        "id": 1551,
        "syllable": "ve",
        "consonant": "v",
        "rhyme": "e"
    },
    {
        "id": 1552,
        "syllable": "voe",
        "consonant": "v",
        "rhyme": "oe"
    },
    {
        "id": 1553,
        "syllable": "vec",
        "consonant": "v",
        "rhyme": "ec"
    },
    {
        "id": 1554,
        "syllable": "voec",
        "consonant": "v",
        "rhyme": "oec"
    },
    {
        "id": 1555,
        "syllable": "veng",
        "consonant": "v",
        "rhyme": "eng"
    },
    {
        "id": 1556,
        "syllable": "voeng",
        "consonant": "v",
        "rhyme": "oeng"
    },
    {
        "id": 1557,
        "syllable": "vet",
        "consonant": "v",
        "rhyme": "et"
    },
    {
        "id": 1558,
        "syllable": "voet",
        "consonant": "v",
        "rhyme": "oet"
    },
    {
        "id": 1559,
        "syllable": "ven",
        "consonant": "v",
        "rhyme": "en"
    },
    {
        "id": 1560,
        "syllable": "voen",
        "consonant": "v",
        "rhyme": "oen"
    },
    {
        "id": 1561,
        "syllable": "vep",
        "consonant": "v",
        "rhyme": "ep"
    },
    {
        "id": 1562,
        "syllable": "voep",
        "consonant": "v",
        "rhyme": "oep"
    },
    {
        "id": 1563,
        "syllable": "vem",
        "consonant": "v",
        "rhyme": "em"
    },
    {
        "id": 1564,
        "syllable": "voem",
        "consonant": "v",
        "rhyme": "oem"
    },
    {
        "id": 1565,
        "syllable": "veo",
        "consonant": "v",
        "rhyme": "eo"
    },
    {
        "id": 1566,
        "syllable": "voeo",
        "consonant": "v",
        "rhyme": "oeo"
    },
    {
        "id": 1567,
        "syllable": "vach",
        "consonant": "v",
        "rhyme": "ach"
    },
    {
        "id": 1568,
        "syllable": "voach",
        "consonant": "v",
        "rhyme": "oach"
    },
    {
        "id": 1569,
        "syllable": "vanh",
        "consonant": "v",
        "rhyme": "anh"
    },
    {
        "id": 1570,
        "syllable": "voanh",
        "consonant": "v",
        "rhyme": "oanh"
    },
    {
        "id": 1571,
        "syllable": "via",
        "consonant": "v",
        "rhyme": "ia"
    },
    {
        "id": 1572,
        "syllable": "vuya",
        "consonant": "v",
        "rhyme": "uya"
    },
    {
        "id": 1573,
        "syllable": "viêc",
        "consonant": "v",
        "rhyme": "iêc"
    },
    {
        "id": 1574,
        "syllable": "viêng",
        "consonant": "v",
        "rhyme": "iêng"
    },
    {
        "id": 1575,
        "syllable": "viêt",
        "consonant": "v",
        "rhyme": "iêt"
    },
    {
        "id": 1576,
        "syllable": "viên",
        "consonant": "v",
        "rhyme": "iên"
    },
    {
        "id": 1577,
        "syllable": "vuyêt",
        "consonant": "v",
        "rhyme": "uyêt"
    },
    {
        "id": 1578,
        "syllable": "vuyên",
        "consonant": "v",
        "rhyme": "uyên"
    },
    {
        "id": 1579,
        "syllable": "viêp",
        "consonant": "v",
        "rhyme": "iêp"
    },
    {
        "id": 1580,
        "syllable": "vuyêp",
        "consonant": "v",
        "rhyme": "uyêp"
    },
    {
        "id": 1581,
        "syllable": "viêm",
        "consonant": "v",
        "rhyme": "iêm"
    },
    {
        "id": 1582,
        "syllable": "vuyêm",
        "consonant": "v",
        "rhyme": "uyêm"
    },
    {
        "id": 1583,
        "syllable": "viêu",
        "consonant": "v",
        "rhyme": "iêu"
    },
    {
        "id": 1584,
        "syllable": "vư",
        "consonant": "v",
        "rhyme": "ư"
    },
    {
        "id": 1585,
        "syllable": "vưc",
        "consonant": "v",
        "rhyme": "ưc"
    },
    {
        "id": 1586,
        "syllable": "vưng",
        "consonant": "v",
        "rhyme": "ưng"
    },
    {
        "id": 1587,
        "syllable": "vưt",
        "consonant": "v",
        "rhyme": "ưt"
    },
    {
        "id": 1588,
        "syllable": "vưn",
        "consonant": "v",
        "rhyme": "ưn"
    },
    {
        "id": 1589,
        "syllable": "vưp",
        "consonant": "v",
        "rhyme": "ưp"
    },
    {
        "id": 1590,
        "syllable": "vưm",
        "consonant": "v",
        "rhyme": "ưm"
    },
    {
        "id": 1591,
        "syllable": "vưi",
        "consonant": "v",
        "rhyme": "ưi"
    },
    {
        "id": 1592,
        "syllable": "vưu",
        "consonant": "v",
        "rhyme": "ưu"
    },
    {
        "id": 1593,
        "syllable": "vơ",
        "consonant": "v",
        "rhyme": "ơ"
    },
    {
        "id": 1594,
        "syllable": "vuơ",
        "consonant": "v",
        "rhyme": "uơ"
    },
    {
        "id": 1595,
        "syllable": "vơng",
        "consonant": "v",
        "rhyme": "ơng"
    },
    {
        "id": 1596,
        "syllable": "vơt",
        "consonant": "v",
        "rhyme": "ơt"
    },
    {
        "id": 1597,
        "syllable": "vơn",
        "consonant": "v",
        "rhyme": "ơn"
    },
    {
        "id": 1598,
        "syllable": "vơp",
        "consonant": "v",
        "rhyme": "ơp"
    },
    {
        "id": 1599,
        "syllable": "vơm",
        "consonant": "v",
        "rhyme": "ơm"
    },
    {
        "id": 1600,
        "syllable": "vơi",
        "consonant": "v",
        "rhyme": "ơi"
    },
    {
        "id": 1601,
        "syllable": "vâc",
        "consonant": "v",
        "rhyme": "âc"
    },
    {
        "id": 1602,
        "syllable": "vâng",
        "consonant": "v",
        "rhyme": "âng"
    },
    {
        "id": 1603,
        "syllable": "vuâng",
        "consonant": "v",
        "rhyme": "uâng"
    },
    {
        "id": 1604,
        "syllable": "vât",
        "consonant": "v",
        "rhyme": "ât"
    },
    {
        "id": 1605,
        "syllable": "vuât",
        "consonant": "v",
        "rhyme": "uât"
    },
    {
        "id": 1606,
        "syllable": "vân",
        "consonant": "v",
        "rhyme": "ân"
    },
    {
        "id": 1607,
        "syllable": "vuân",
        "consonant": "v",
        "rhyme": "uân"
    },
    {
        "id": 1608,
        "syllable": "vâp",
        "consonant": "v",
        "rhyme": "âp"
    },
    {
        "id": 1609,
        "syllable": "vuâp",
        "consonant": "v",
        "rhyme": "uâp"
    },
    {
        "id": 1610,
        "syllable": "vâm",
        "consonant": "v",
        "rhyme": "âm"
    },
    {
        "id": 1611,
        "syllable": "vuâm",
        "consonant": "v",
        "rhyme": "uâm"
    },
    {
        "id": 1612,
        "syllable": "vây",
        "consonant": "v",
        "rhyme": "ây"
    },
    {
        "id": 1613,
        "syllable": "vuây",
        "consonant": "v",
        "rhyme": "uây"
    },
    {
        "id": 1614,
        "syllable": "vâu",
        "consonant": "v",
        "rhyme": "âu"
    },
    {
        "id": 1615,
        "syllable": "vuâu",
        "consonant": "v",
        "rhyme": "uâu"
    },
    {
        "id": 1616,
        "syllable": "vu",
        "consonant": "v",
        "rhyme": "u"
    },
    {
        "id": 1617,
        "syllable": "vuc",
        "consonant": "v",
        "rhyme": "uc"
    },
    {
        "id": 1618,
        "syllable": "vung",
        "consonant": "v",
        "rhyme": "ung"
    },
    {
        "id": 1619,
        "syllable": "vut",
        "consonant": "v",
        "rhyme": "ut"
    },
    {
        "id": 1620,
        "syllable": "vun",
        "consonant": "v",
        "rhyme": "un"
    },
    {
        "id": 1621,
        "syllable": "vup",
        "consonant": "v",
        "rhyme": "up"
    },
    {
        "id": 1622,
        "syllable": "vum",
        "consonant": "v",
        "rhyme": "um"
    },
    {
        "id": 1623,
        "syllable": "vui",
        "consonant": "v",
        "rhyme": "ui"
    },
    {
        "id": 1624,
        "syllable": "vô",
        "consonant": "v",
        "rhyme": "ô"
    },
    {
        "id": 1625,
        "syllable": "vôc",
        "consonant": "v",
        "rhyme": "ôc"
    },
    {
        "id": 1626,
        "syllable": "vông",
        "consonant": "v",
        "rhyme": "ông"
    },
    {
        "id": 1627,
        "syllable": "vôt",
        "consonant": "v",
        "rhyme": "ôt"
    },
    {
        "id": 1628,
        "syllable": "vôn",
        "consonant": "v",
        "rhyme": "ôn"
    },
    {
        "id": 1629,
        "syllable": "vôp",
        "consonant": "v",
        "rhyme": "ôp"
    },
    {
        "id": 1630,
        "syllable": "vôm",
        "consonant": "v",
        "rhyme": "ôm"
    },
    {
        "id": 1631,
        "syllable": "vôi",
        "consonant": "v",
        "rhyme": "ôi"
    },
    {
        "id": 1632,
        "syllable": "vo",
        "consonant": "v",
        "rhyme": "o"
    },
    {
        "id": 1633,
        "syllable": "vooc",
        "consonant": "v",
        "rhyme": "ooc"
    },
    {
        "id": 1634,
        "syllable": "voong",
        "consonant": "v",
        "rhyme": "oong"
    },
    {
        "id": 1635,
        "syllable": "vot",
        "consonant": "v",
        "rhyme": "ot"
    },
    {
        "id": 1636,
        "syllable": "von",
        "consonant": "v",
        "rhyme": "on"
    },
    {
        "id": 1637,
        "syllable": "vop",
        "consonant": "v",
        "rhyme": "op"
    },
    {
        "id": 1638,
        "syllable": "vom",
        "consonant": "v",
        "rhyme": "om"
    },
    {
        "id": 1639,
        "syllable": "voi",
        "consonant": "v",
        "rhyme": "oi"
    },
    {
        "id": 1640,
        "syllable": "voc",
        "consonant": "v",
        "rhyme": "oc"
    },
    {
        "id": 1641,
        "syllable": "vong",
        "consonant": "v",
        "rhyme": "ong"
    },
    {
        "id": 1642,
        "syllable": "vua",
        "consonant": "v",
        "rhyme": "ua"
    },
    {
        "id": 1643,
        "syllable": "vuôc",
        "consonant": "v",
        "rhyme": "uôc"
    },
    {
        "id": 1644,
        "syllable": "vuông",
        "consonant": "v",
        "rhyme": "uông"
    },
    {
        "id": 1645,
        "syllable": "vuôt",
        "consonant": "v",
        "rhyme": "uôt"
    },
    {
        "id": 1646,
        "syllable": "vuôn",
        "consonant": "v",
        "rhyme": "uôn"
    },
    {
        "id": 1647,
        "syllable": "vuôp",
        "consonant": "v",
        "rhyme": "uôp"
    },
    {
        "id": 1648,
        "syllable": "vuôm",
        "consonant": "v",
        "rhyme": "uôm"
    },
    {
        "id": 1649,
        "syllable": "vuôi",
        "consonant": "v",
        "rhyme": "uôi"
    },
    {
        "id": 1650,
        "syllable": "vưa",
        "consonant": "v",
        "rhyme": "ưa"
    },
    {
        "id": 1651,
        "syllable": "vươc",
        "consonant": "v",
        "rhyme": "ươc"
    },
    {
        "id": 1652,
        "syllable": "vương",
        "consonant": "v",
        "rhyme": "ương"
    },
    {
        "id": 1653,
        "syllable": "vươt",
        "consonant": "v",
        "rhyme": "ươt"
    },
    {
        "id": 1654,
        "syllable": "vươn",
        "consonant": "v",
        "rhyme": "ươn"
    },
    {
        "id": 1655,
        "syllable": "vươp",
        "consonant": "v",
        "rhyme": "ươp"
    },
    {
        "id": 1656,
        "syllable": "vươm",
        "consonant": "v",
        "rhyme": "ươm"
    },
    {
        "id": 1657,
        "syllable": "vươi",
        "consonant": "v",
        "rhyme": "ươi"
    },
    {
        "id": 1658,
        "syllable": "va",
        "consonant": "v",
        "rhyme": "a"
    },
    {
        "id": 1659,
        "syllable": "voa",
        "consonant": "v",
        "rhyme": "oa"
    },
    {
        "id": 1660,
        "syllable": "vac",
        "consonant": "v",
        "rhyme": "ac"
    },
    {
        "id": 1661,
        "syllable": "voac",
        "consonant": "v",
        "rhyme": "oac"
    },
    {
        "id": 1662,
        "syllable": "vang",
        "consonant": "v",
        "rhyme": "ang"
    },
    {
        "id": 1663,
        "syllable": "voang",
        "consonant": "v",
        "rhyme": "oang"
    },
    {
        "id": 1664,
        "syllable": "vat",
        "consonant": "v",
        "rhyme": "at"
    },
    {
        "id": 1665,
        "syllable": "voat",
        "consonant": "v",
        "rhyme": "oat"
    },
    {
        "id": 1666,
        "syllable": "van",
        "consonant": "v",
        "rhyme": "an"
    },
    {
        "id": 1667,
        "syllable": "voan",
        "consonant": "v",
        "rhyme": "oan"
    },
    {
        "id": 1668,
        "syllable": "vap",
        "consonant": "v",
        "rhyme": "ap"
    },
    {
        "id": 1669,
        "syllable": "voap",
        "consonant": "v",
        "rhyme": "oap"
    },
    {
        "id": 1670,
        "syllable": "vam",
        "consonant": "v",
        "rhyme": "am"
    },
    {
        "id": 1671,
        "syllable": "voam",
        "consonant": "v",
        "rhyme": "oam"
    },
    {
        "id": 1672,
        "syllable": "vai",
        "consonant": "v",
        "rhyme": "ai"
    },
    {
        "id": 1673,
        "syllable": "voai",
        "consonant": "v",
        "rhyme": "oai"
    },
    {
        "id": 1674,
        "syllable": "vao",
        "consonant": "v",
        "rhyme": "ao"
    },
    {
        "id": 1675,
        "syllable": "voao",
        "consonant": "v",
        "rhyme": "oao"
    },
    {
        "id": 1676,
        "syllable": "văc",
        "consonant": "v",
        "rhyme": "ăc"
    },
    {
        "id": 1677,
        "syllable": "voăc",
        "consonant": "v",
        "rhyme": "oăc"
    },
    {
        "id": 1678,
        "syllable": "văng",
        "consonant": "v",
        "rhyme": "ăng"
    },
    {
        "id": 1679,
        "syllable": "voăng",
        "consonant": "v",
        "rhyme": "oăng"
    },
    {
        "id": 1680,
        "syllable": "văt",
        "consonant": "v",
        "rhyme": "ăt"
    },
    {
        "id": 1681,
        "syllable": "voăt",
        "consonant": "v",
        "rhyme": "oăt"
    },
    {
        "id": 1682,
        "syllable": "văn",
        "consonant": "v",
        "rhyme": "ăn"
    },
    {
        "id": 1683,
        "syllable": "voăn",
        "consonant": "v",
        "rhyme": "oăn"
    },
    {
        "id": 1684,
        "syllable": "văp",
        "consonant": "v",
        "rhyme": "ăp"
    },
    {
        "id": 1685,
        "syllable": "voăp",
        "consonant": "v",
        "rhyme": "oăp"
    },
    {
        "id": 1686,
        "syllable": "văm",
        "consonant": "v",
        "rhyme": "ăm"
    },
    {
        "id": 1687,
        "syllable": "voăm",
        "consonant": "v",
        "rhyme": "oăm"
    },
    {
        "id": 1688,
        "syllable": "vay",
        "consonant": "v",
        "rhyme": "ay"
    },
    {
        "id": 1689,
        "syllable": "voay",
        "consonant": "v",
        "rhyme": "oay"
    },
    {
        "id": 1690,
        "syllable": "vau",
        "consonant": "v",
        "rhyme": "au"
    },
    {
        "id": 1691,
        "syllable": "voau",
        "consonant": "v",
        "rhyme": "oau"
    },
    {
        "id": 1692,
        "syllable": "thi",
        "consonant": "th",
        "rhyme": "i"
    },
    {
        "id": 1693,
        "syllable": "thuy",
        "consonant": "th",
        "rhyme": "uy"
    },
    {
        "id": 1694,
        "syllable": "thich",
        "consonant": "th",
        "rhyme": "ich"
    },
    {
        "id": 1695,
        "syllable": "thuych",
        "consonant": "th",
        "rhyme": "uych"
    },
    {
        "id": 1696,
        "syllable": "thinh",
        "consonant": "th",
        "rhyme": "inh"
    },
    {
        "id": 1697,
        "syllable": "thuynh",
        "consonant": "th",
        "rhyme": "uynh"
    },
    {
        "id": 1698,
        "syllable": "thit",
        "consonant": "th",
        "rhyme": "it"
    },
    {
        "id": 1699,
        "syllable": "thuyt",
        "consonant": "th",
        "rhyme": "uyt"
    },
    {
        "id": 1700,
        "syllable": "thin",
        "consonant": "th",
        "rhyme": "in"
    },
    {
        "id": 1701,
        "syllable": "thuyn",
        "consonant": "th",
        "rhyme": "uyn"
    },
    {
        "id": 1702,
        "syllable": "thip",
        "consonant": "th",
        "rhyme": "ip"
    },
    {
        "id": 1703,
        "syllable": "thuyp",
        "consonant": "th",
        "rhyme": "uyp"
    },
    {
        "id": 1704,
        "syllable": "thim",
        "consonant": "th",
        "rhyme": "im"
    },
    {
        "id": 1705,
        "syllable": "thuym",
        "consonant": "th",
        "rhyme": "uym"
    },
    {
        "id": 1706,
        "syllable": "thiu",
        "consonant": "th",
        "rhyme": "iu"
    },
    {
        "id": 1707,
        "syllable": "thuyu",
        "consonant": "th",
        "rhyme": "uyu"
    },
    {
        "id": 1708,
        "syllable": "thê",
        "consonant": "th",
        "rhyme": "ê"
    },
    {
        "id": 1709,
        "syllable": "thuê",
        "consonant": "th",
        "rhyme": "uê"
    },
    {
        "id": 1710,
        "syllable": "thêch",
        "consonant": "th",
        "rhyme": "êch"
    },
    {
        "id": 1711,
        "syllable": "thuêch",
        "consonant": "th",
        "rhyme": "uêch"
    },
    {
        "id": 1712,
        "syllable": "thênh",
        "consonant": "th",
        "rhyme": "ênh"
    },
    {
        "id": 1713,
        "syllable": "thuênh",
        "consonant": "th",
        "rhyme": "uênh"
    },
    {
        "id": 1714,
        "syllable": "thêt",
        "consonant": "th",
        "rhyme": "êt"
    },
    {
        "id": 1715,
        "syllable": "thuêt",
        "consonant": "th",
        "rhyme": "uêt"
    },
    {
        "id": 1716,
        "syllable": "thên",
        "consonant": "th",
        "rhyme": "ên"
    },
    {
        "id": 1717,
        "syllable": "thuên",
        "consonant": "th",
        "rhyme": "uên"
    },
    {
        "id": 1718,
        "syllable": "thêp",
        "consonant": "th",
        "rhyme": "êp"
    },
    {
        "id": 1719,
        "syllable": "thuêp",
        "consonant": "th",
        "rhyme": "uêp"
    },
    {
        "id": 1720,
        "syllable": "thêm",
        "consonant": "th",
        "rhyme": "êm"
    },
    {
        "id": 1721,
        "syllable": "thuêm",
        "consonant": "th",
        "rhyme": "uêm"
    },
    {
        "id": 1722,
        "syllable": "thêu",
        "consonant": "th",
        "rhyme": "êu"
    },
    {
        "id": 1723,
        "syllable": "the",
        "consonant": "th",
        "rhyme": "e"
    },
    {
        "id": 1724,
        "syllable": "thoe",
        "consonant": "th",
        "rhyme": "oe"
    },
    {
        "id": 1725,
        "syllable": "thec",
        "consonant": "th",
        "rhyme": "ec"
    },
    {
        "id": 1726,
        "syllable": "thoec",
        "consonant": "th",
        "rhyme": "oec"
    },
    {
        "id": 1727,
        "syllable": "theng",
        "consonant": "th",
        "rhyme": "eng"
    },
    {
        "id": 1728,
        "syllable": "thoeng",
        "consonant": "th",
        "rhyme": "oeng"
    },
    {
        "id": 1729,
        "syllable": "thet",
        "consonant": "th",
        "rhyme": "et"
    },
    {
        "id": 1730,
        "syllable": "thoet",
        "consonant": "th",
        "rhyme": "oet"
    },
    {
        "id": 1731,
        "syllable": "then",
        "consonant": "th",
        "rhyme": "en"
    },
    {
        "id": 1732,
        "syllable": "thoen",
        "consonant": "th",
        "rhyme": "oen"
    },
    {
        "id": 1733,
        "syllable": "thep",
        "consonant": "th",
        "rhyme": "ep"
    },
    {
        "id": 1734,
        "syllable": "thoep",
        "consonant": "th",
        "rhyme": "oep"
    },
    {
        "id": 1735,
        "syllable": "them",
        "consonant": "th",
        "rhyme": "em"
    },
    {
        "id": 1736,
        "syllable": "thoem",
        "consonant": "th",
        "rhyme": "oem"
    },
    {
        "id": 1737,
        "syllable": "theo",
        "consonant": "th",
        "rhyme": "eo"
    },
    {
        "id": 1738,
        "syllable": "thoeo",
        "consonant": "th",
        "rhyme": "oeo"
    },
    {
        "id": 1739,
        "syllable": "thach",
        "consonant": "th",
        "rhyme": "ach"
    },
    {
        "id": 1740,
        "syllable": "thoach",
        "consonant": "th",
        "rhyme": "oach"
    },
    {
        "id": 1741,
        "syllable": "thanh",
        "consonant": "th",
        "rhyme": "anh"
    },
    {
        "id": 1742,
        "syllable": "thoanh",
        "consonant": "th",
        "rhyme": "oanh"
    },
    {
        "id": 1743,
        "syllable": "thia",
        "consonant": "th",
        "rhyme": "ia"
    },
    {
        "id": 1744,
        "syllable": "thuya",
        "consonant": "th",
        "rhyme": "uya"
    },
    {
        "id": 1745,
        "syllable": "thiêc",
        "consonant": "th",
        "rhyme": "iêc"
    },
    {
        "id": 1746,
        "syllable": "thiêng",
        "consonant": "th",
        "rhyme": "iêng"
    },
    {
        "id": 1747,
        "syllable": "thiêt",
        "consonant": "th",
        "rhyme": "iêt"
    },
    {
        "id": 1748,
        "syllable": "thiên",
        "consonant": "th",
        "rhyme": "iên"
    },
    {
        "id": 1749,
        "syllable": "thuyêt",
        "consonant": "th",
        "rhyme": "uyêt"
    },
    {
        "id": 1750,
        "syllable": "thuyên",
        "consonant": "th",
        "rhyme": "uyên"
    },
    {
        "id": 1751,
        "syllable": "thiêp",
        "consonant": "th",
        "rhyme": "iêp"
    },
    {
        "id": 1752,
        "syllable": "thuyêp",
        "consonant": "th",
        "rhyme": "uyêp"
    },
    {
        "id": 1753,
        "syllable": "thiêm",
        "consonant": "th",
        "rhyme": "iêm"
    },
    {
        "id": 1754,
        "syllable": "thuyêm",
        "consonant": "th",
        "rhyme": "uyêm"
    },
    {
        "id": 1755,
        "syllable": "thiêu",
        "consonant": "th",
        "rhyme": "iêu"
    },
    {
        "id": 1756,
        "syllable": "thư",
        "consonant": "th",
        "rhyme": "ư"
    },
    {
        "id": 1757,
        "syllable": "thưc",
        "consonant": "th",
        "rhyme": "ưc"
    },
    {
        "id": 1758,
        "syllable": "thưng",
        "consonant": "th",
        "rhyme": "ưng"
    },
    {
        "id": 1759,
        "syllable": "thưt",
        "consonant": "th",
        "rhyme": "ưt"
    },
    {
        "id": 1760,
        "syllable": "thưn",
        "consonant": "th",
        "rhyme": "ưn"
    },
    {
        "id": 1761,
        "syllable": "thưp",
        "consonant": "th",
        "rhyme": "ưp"
    },
    {
        "id": 1762,
        "syllable": "thưm",
        "consonant": "th",
        "rhyme": "ưm"
    },
    {
        "id": 1763,
        "syllable": "thưi",
        "consonant": "th",
        "rhyme": "ưi"
    },
    {
        "id": 1764,
        "syllable": "thưu",
        "consonant": "th",
        "rhyme": "ưu"
    },
    {
        "id": 1765,
        "syllable": "thơ",
        "consonant": "th",
        "rhyme": "ơ"
    },
    {
        "id": 1766,
        "syllable": "thuơ",
        "consonant": "th",
        "rhyme": "uơ"
    },
    {
        "id": 1767,
        "syllable": "thơng",
        "consonant": "th",
        "rhyme": "ơng"
    },
    {
        "id": 1768,
        "syllable": "thơt",
        "consonant": "th",
        "rhyme": "ơt"
    },
    {
        "id": 1769,
        "syllable": "thơn",
        "consonant": "th",
        "rhyme": "ơn"
    },
    {
        "id": 1770,
        "syllable": "thơp",
        "consonant": "th",
        "rhyme": "ơp"
    },
    {
        "id": 1771,
        "syllable": "thơm",
        "consonant": "th",
        "rhyme": "ơm"
    },
    {
        "id": 1772,
        "syllable": "thơi",
        "consonant": "th",
        "rhyme": "ơi"
    },
    {
        "id": 1773,
        "syllable": "thâc",
        "consonant": "th",
        "rhyme": "âc"
    },
    {
        "id": 1774,
        "syllable": "thâng",
        "consonant": "th",
        "rhyme": "âng"
    },
    {
        "id": 1775,
        "syllable": "thuâng",
        "consonant": "th",
        "rhyme": "uâng"
    },
    {
        "id": 1776,
        "syllable": "thât",
        "consonant": "th",
        "rhyme": "ât"
    },
    {
        "id": 1777,
        "syllable": "thuât",
        "consonant": "th",
        "rhyme": "uât"
    },
    {
        "id": 1778,
        "syllable": "thân",
        "consonant": "th",
        "rhyme": "ân"
    },
    {
        "id": 1779,
        "syllable": "thuân",
        "consonant": "th",
        "rhyme": "uân"
    },
    {
        "id": 1780,
        "syllable": "thâp",
        "consonant": "th",
        "rhyme": "âp"
    },
    {
        "id": 1781,
        "syllable": "thuâp",
        "consonant": "th",
        "rhyme": "uâp"
    },
    {
        "id": 1782,
        "syllable": "thâm",
        "consonant": "th",
        "rhyme": "âm"
    },
    {
        "id": 1783,
        "syllable": "thuâm",
        "consonant": "th",
        "rhyme": "uâm"
    },
    {
        "id": 1784,
        "syllable": "thây",
        "consonant": "th",
        "rhyme": "ây"
    },
    {
        "id": 1785,
        "syllable": "thuây",
        "consonant": "th",
        "rhyme": "uây"
    },
    {
        "id": 1786,
        "syllable": "thâu",
        "consonant": "th",
        "rhyme": "âu"
    },
    {
        "id": 1787,
        "syllable": "thuâu",
        "consonant": "th",
        "rhyme": "uâu"
    },
    {
        "id": 1788,
        "syllable": "thu",
        "consonant": "th",
        "rhyme": "u"
    },
    {
        "id": 1789,
        "syllable": "thuc",
        "consonant": "th",
        "rhyme": "uc"
    },
    {
        "id": 1790,
        "syllable": "thung",
        "consonant": "th",
        "rhyme": "ung"
    },
    {
        "id": 1791,
        "syllable": "thut",
        "consonant": "th",
        "rhyme": "ut"
    },
    {
        "id": 1792,
        "syllable": "thun",
        "consonant": "th",
        "rhyme": "un"
    },
    {
        "id": 1793,
        "syllable": "thup",
        "consonant": "th",
        "rhyme": "up"
    },
    {
        "id": 1794,
        "syllable": "thum",
        "consonant": "th",
        "rhyme": "um"
    },
    {
        "id": 1795,
        "syllable": "thui",
        "consonant": "th",
        "rhyme": "ui"
    },
    {
        "id": 1796,
        "syllable": "thô",
        "consonant": "th",
        "rhyme": "ô"
    },
    {
        "id": 1797,
        "syllable": "thôc",
        "consonant": "th",
        "rhyme": "ôc"
    },
    {
        "id": 1798,
        "syllable": "thông",
        "consonant": "th",
        "rhyme": "ông"
    },
    {
        "id": 1799,
        "syllable": "thôt",
        "consonant": "th",
        "rhyme": "ôt"
    },
    {
        "id": 1800,
        "syllable": "thôn",
        "consonant": "th",
        "rhyme": "ôn"
    },
    {
        "id": 1801,
        "syllable": "thôp",
        "consonant": "th",
        "rhyme": "ôp"
    },
    {
        "id": 1802,
        "syllable": "thôm",
        "consonant": "th",
        "rhyme": "ôm"
    },
    {
        "id": 1803,
        "syllable": "thôi",
        "consonant": "th",
        "rhyme": "ôi"
    },
    {
        "id": 1804,
        "syllable": "tho",
        "consonant": "th",
        "rhyme": "o"
    },
    {
        "id": 1805,
        "syllable": "thooc",
        "consonant": "th",
        "rhyme": "ooc"
    },
    {
        "id": 1806,
        "syllable": "thoong",
        "consonant": "th",
        "rhyme": "oong"
    },
    {
        "id": 1807,
        "syllable": "thot",
        "consonant": "th",
        "rhyme": "ot"
    },
    {
        "id": 1808,
        "syllable": "thon",
        "consonant": "th",
        "rhyme": "on"
    },
    {
        "id": 1809,
        "syllable": "thop",
        "consonant": "th",
        "rhyme": "op"
    },
    {
        "id": 1810,
        "syllable": "thom",
        "consonant": "th",
        "rhyme": "om"
    },
    {
        "id": 1811,
        "syllable": "thoi",
        "consonant": "th",
        "rhyme": "oi"
    },
    {
        "id": 1812,
        "syllable": "thoc",
        "consonant": "th",
        "rhyme": "oc"
    },
    {
        "id": 1813,
        "syllable": "thong",
        "consonant": "th",
        "rhyme": "ong"
    },
    {
        "id": 1814,
        "syllable": "thua",
        "consonant": "th",
        "rhyme": "ua"
    },
    {
        "id": 1815,
        "syllable": "thuôc",
        "consonant": "th",
        "rhyme": "uôc"
    },
    {
        "id": 1816,
        "syllable": "thuông",
        "consonant": "th",
        "rhyme": "uông"
    },
    {
        "id": 1817,
        "syllable": "thuôt",
        "consonant": "th",
        "rhyme": "uôt"
    },
    {
        "id": 1818,
        "syllable": "thuôn",
        "consonant": "th",
        "rhyme": "uôn"
    },
    {
        "id": 1819,
        "syllable": "thuôp",
        "consonant": "th",
        "rhyme": "uôp"
    },
    {
        "id": 1820,
        "syllable": "thuôm",
        "consonant": "th",
        "rhyme": "uôm"
    },
    {
        "id": 1821,
        "syllable": "thuôi",
        "consonant": "th",
        "rhyme": "uôi"
    },
    {
        "id": 1822,
        "syllable": "thưa",
        "consonant": "th",
        "rhyme": "ưa"
    },
    {
        "id": 1823,
        "syllable": "thươc",
        "consonant": "th",
        "rhyme": "ươc"
    },
    {
        "id": 1824,
        "syllable": "thương",
        "consonant": "th",
        "rhyme": "ương"
    },
    {
        "id": 1825,
        "syllable": "thươt",
        "consonant": "th",
        "rhyme": "ươt"
    },
    {
        "id": 1826,
        "syllable": "thươn",
        "consonant": "th",
        "rhyme": "ươn"
    },
    {
        "id": 1827,
        "syllable": "thươp",
        "consonant": "th",
        "rhyme": "ươp"
    },
    {
        "id": 1828,
        "syllable": "thươm",
        "consonant": "th",
        "rhyme": "ươm"
    },
    {
        "id": 1829,
        "syllable": "thươi",
        "consonant": "th",
        "rhyme": "ươi"
    },
    {
        "id": 1830,
        "syllable": "tha",
        "consonant": "th",
        "rhyme": "a"
    },
    {
        "id": 1831,
        "syllable": "thoa",
        "consonant": "th",
        "rhyme": "oa"
    },
    {
        "id": 1832,
        "syllable": "thac",
        "consonant": "th",
        "rhyme": "ac"
    },
    {
        "id": 1833,
        "syllable": "thoac",
        "consonant": "th",
        "rhyme": "oac"
    },
    {
        "id": 1834,
        "syllable": "thang",
        "consonant": "th",
        "rhyme": "ang"
    },
    {
        "id": 1835,
        "syllable": "thoang",
        "consonant": "th",
        "rhyme": "oang"
    },
    {
        "id": 1836,
        "syllable": "that",
        "consonant": "th",
        "rhyme": "at"
    },
    {
        "id": 1837,
        "syllable": "thoat",
        "consonant": "th",
        "rhyme": "oat"
    },
    {
        "id": 1838,
        "syllable": "than",
        "consonant": "th",
        "rhyme": "an"
    },
    {
        "id": 1839,
        "syllable": "thoan",
        "consonant": "th",
        "rhyme": "oan"
    },
    {
        "id": 1840,
        "syllable": "thap",
        "consonant": "th",
        "rhyme": "ap"
    },
    {
        "id": 1841,
        "syllable": "thoap",
        "consonant": "th",
        "rhyme": "oap"
    },
    {
        "id": 1842,
        "syllable": "tham",
        "consonant": "th",
        "rhyme": "am"
    },
    {
        "id": 1843,
        "syllable": "thoam",
        "consonant": "th",
        "rhyme": "oam"
    },
    {
        "id": 1844,
        "syllable": "thai",
        "consonant": "th",
        "rhyme": "ai"
    },
    {
        "id": 1845,
        "syllable": "thoai",
        "consonant": "th",
        "rhyme": "oai"
    },
    {
        "id": 1846,
        "syllable": "thao",
        "consonant": "th",
        "rhyme": "ao"
    },
    {
        "id": 1847,
        "syllable": "thoao",
        "consonant": "th",
        "rhyme": "oao"
    },
    {
        "id": 1848,
        "syllable": "thăc",
        "consonant": "th",
        "rhyme": "ăc"
    },
    {
        "id": 1849,
        "syllable": "thoăc",
        "consonant": "th",
        "rhyme": "oăc"
    },
    {
        "id": 1850,
        "syllable": "thăng",
        "consonant": "th",
        "rhyme": "ăng"
    },
    {
        "id": 1851,
        "syllable": "thoăng",
        "consonant": "th",
        "rhyme": "oăng"
    },
    {
        "id": 1852,
        "syllable": "thăt",
        "consonant": "th",
        "rhyme": "ăt"
    },
    {
        "id": 1853,
        "syllable": "thoăt",
        "consonant": "th",
        "rhyme": "oăt"
    },
    {
        "id": 1854,
        "syllable": "thăn",
        "consonant": "th",
        "rhyme": "ăn"
    },
    {
        "id": 1855,
        "syllable": "thoăn",
        "consonant": "th",
        "rhyme": "oăn"
    },
    {
        "id": 1856,
        "syllable": "thăp",
        "consonant": "th",
        "rhyme": "ăp"
    },
    {
        "id": 1857,
        "syllable": "thoăp",
        "consonant": "th",
        "rhyme": "oăp"
    },
    {
        "id": 1858,
        "syllable": "thăm",
        "consonant": "th",
        "rhyme": "ăm"
    },
    {
        "id": 1859,
        "syllable": "thoăm",
        "consonant": "th",
        "rhyme": "oăm"
    },
    {
        "id": 1860,
        "syllable": "thay",
        "consonant": "th",
        "rhyme": "ay"
    },
    {
        "id": 1861,
        "syllable": "thoay",
        "consonant": "th",
        "rhyme": "oay"
    },
    {
        "id": 1862,
        "syllable": "thau",
        "consonant": "th",
        "rhyme": "au"
    },
    {
        "id": 1863,
        "syllable": "thoau",
        "consonant": "th",
        "rhyme": "oau"
    },
    {
        "id": 1864,
        "syllable": "di",
        "consonant": "d",
        "rhyme": "i"
    },
    {
        "id": 1865,
        "syllable": "duy",
        "consonant": "d",
        "rhyme": "uy"
    },
    {
        "id": 1866,
        "syllable": "dich",
        "consonant": "d",
        "rhyme": "ich"
    },
    {
        "id": 1867,
        "syllable": "duych",
        "consonant": "d",
        "rhyme": "uych"
    },
    {
        "id": 1868,
        "syllable": "dinh",
        "consonant": "d",
        "rhyme": "inh"
    },
    {
        "id": 1869,
        "syllable": "duynh",
        "consonant": "d",
        "rhyme": "uynh"
    },
    {
        "id": 1870,
        "syllable": "dit",
        "consonant": "d",
        "rhyme": "it"
    },
    {
        "id": 1871,
        "syllable": "duyt",
        "consonant": "d",
        "rhyme": "uyt"
    },
    {
        "id": 1872,
        "syllable": "din",
        "consonant": "d",
        "rhyme": "in"
    },
    {
        "id": 1873,
        "syllable": "duyn",
        "consonant": "d",
        "rhyme": "uyn"
    },
    {
        "id": 1874,
        "syllable": "dip",
        "consonant": "d",
        "rhyme": "ip"
    },
    {
        "id": 1875,
        "syllable": "duyp",
        "consonant": "d",
        "rhyme": "uyp"
    },
    {
        "id": 1876,
        "syllable": "dim",
        "consonant": "d",
        "rhyme": "im"
    },
    {
        "id": 1877,
        "syllable": "duym",
        "consonant": "d",
        "rhyme": "uym"
    },
    {
        "id": 1878,
        "syllable": "diu",
        "consonant": "d",
        "rhyme": "iu"
    },
    {
        "id": 1879,
        "syllable": "duyu",
        "consonant": "d",
        "rhyme": "uyu"
    },
    {
        "id": 1880,
        "syllable": "dê",
        "consonant": "d",
        "rhyme": "ê"
    },
    {
        "id": 1881,
        "syllable": "duê",
        "consonant": "d",
        "rhyme": "uê"
    },
    {
        "id": 1882,
        "syllable": "dêch",
        "consonant": "d",
        "rhyme": "êch"
    },
    {
        "id": 1883,
        "syllable": "duêch",
        "consonant": "d",
        "rhyme": "uêch"
    },
    {
        "id": 1884,
        "syllable": "dênh",
        "consonant": "d",
        "rhyme": "ênh"
    },
    {
        "id": 1885,
        "syllable": "duênh",
        "consonant": "d",
        "rhyme": "uênh"
    },
    {
        "id": 1886,
        "syllable": "dêt",
        "consonant": "d",
        "rhyme": "êt"
    },
    {
        "id": 1887,
        "syllable": "duêt",
        "consonant": "d",
        "rhyme": "uêt"
    },
    {
        "id": 1888,
        "syllable": "dên",
        "consonant": "d",
        "rhyme": "ên"
    },
    {
        "id": 1889,
        "syllable": "duên",
        "consonant": "d",
        "rhyme": "uên"
    },
    {
        "id": 1890,
        "syllable": "dêp",
        "consonant": "d",
        "rhyme": "êp"
    },
    {
        "id": 1891,
        "syllable": "duêp",
        "consonant": "d",
        "rhyme": "uêp"
    },
    {
        "id": 1892,
        "syllable": "dêm",
        "consonant": "d",
        "rhyme": "êm"
    },
    {
        "id": 1893,
        "syllable": "duêm",
        "consonant": "d",
        "rhyme": "uêm"
    },
    {
        "id": 1894,
        "syllable": "dêu",
        "consonant": "d",
        "rhyme": "êu"
    },
    {
        "id": 1895,
        "syllable": "de",
        "consonant": "d",
        "rhyme": "e"
    },
    {
        "id": 1896,
        "syllable": "doe",
        "consonant": "d",
        "rhyme": "oe"
    },
    {
        "id": 1897,
        "syllable": "dec",
        "consonant": "d",
        "rhyme": "ec"
    },
    {
        "id": 1898,
        "syllable": "doec",
        "consonant": "d",
        "rhyme": "oec"
    },
    {
        "id": 1899,
        "syllable": "deng",
        "consonant": "d",
        "rhyme": "eng"
    },
    {
        "id": 1900,
        "syllable": "doeng",
        "consonant": "d",
        "rhyme": "oeng"
    },
    {
        "id": 1901,
        "syllable": "det",
        "consonant": "d",
        "rhyme": "et"
    },
    {
        "id": 1902,
        "syllable": "doet",
        "consonant": "d",
        "rhyme": "oet"
    },
    {
        "id": 1903,
        "syllable": "den",
        "consonant": "d",
        "rhyme": "en"
    },
    {
        "id": 1904,
        "syllable": "doen",
        "consonant": "d",
        "rhyme": "oen"
    },
    {
        "id": 1905,
        "syllable": "dep",
        "consonant": "d",
        "rhyme": "ep"
    },
    {
        "id": 1906,
        "syllable": "doep",
        "consonant": "d",
        "rhyme": "oep"
    },
    {
        "id": 1907,
        "syllable": "dem",
        "consonant": "d",
        "rhyme": "em"
    },
    {
        "id": 1908,
        "syllable": "doem",
        "consonant": "d",
        "rhyme": "oem"
    },
    {
        "id": 1909,
        "syllable": "deo",
        "consonant": "d",
        "rhyme": "eo"
    },
    {
        "id": 1910,
        "syllable": "doeo",
        "consonant": "d",
        "rhyme": "oeo"
    },
    {
        "id": 1911,
        "syllable": "dach",
        "consonant": "d",
        "rhyme": "ach"
    },
    {
        "id": 1912,
        "syllable": "doach",
        "consonant": "d",
        "rhyme": "oach"
    },
    {
        "id": 1913,
        "syllable": "danh",
        "consonant": "d",
        "rhyme": "anh"
    },
    {
        "id": 1914,
        "syllable": "doanh",
        "consonant": "d",
        "rhyme": "oanh"
    },
    {
        "id": 1915,
        "syllable": "dia",
        "consonant": "d",
        "rhyme": "ia"
    },
    {
        "id": 1916,
        "syllable": "duya",
        "consonant": "d",
        "rhyme": "uya"
    },
    {
        "id": 1917,
        "syllable": "diêc",
        "consonant": "d",
        "rhyme": "iêc"
    },
    {
        "id": 1918,
        "syllable": "diêng",
        "consonant": "d",
        "rhyme": "iêng"
    },
    {
        "id": 1919,
        "syllable": "diêt",
        "consonant": "d",
        "rhyme": "iêt"
    },
    {
        "id": 1920,
        "syllable": "diên",
        "consonant": "d",
        "rhyme": "iên"
    },
    {
        "id": 1921,
        "syllable": "duyêt",
        "consonant": "d",
        "rhyme": "uyêt"
    },
    {
        "id": 1922,
        "syllable": "duyên",
        "consonant": "d",
        "rhyme": "uyên"
    },
    {
        "id": 1923,
        "syllable": "diêp",
        "consonant": "d",
        "rhyme": "iêp"
    },
    {
        "id": 1924,
        "syllable": "duyêp",
        "consonant": "d",
        "rhyme": "uyêp"
    },
    {
        "id": 1925,
        "syllable": "diêm",
        "consonant": "d",
        "rhyme": "iêm"
    },
    {
        "id": 1926,
        "syllable": "duyêm",
        "consonant": "d",
        "rhyme": "uyêm"
    },
    {
        "id": 1927,
        "syllable": "diêu",
        "consonant": "d",
        "rhyme": "iêu"
    },
    {
        "id": 1928,
        "syllable": "dư",
        "consonant": "d",
        "rhyme": "ư"
    },
    {
        "id": 1929,
        "syllable": "dưc",
        "consonant": "d",
        "rhyme": "ưc"
    },
    {
        "id": 1930,
        "syllable": "dưng",
        "consonant": "d",
        "rhyme": "ưng"
    },
    {
        "id": 1931,
        "syllable": "dưt",
        "consonant": "d",
        "rhyme": "ưt"
    },
    {
        "id": 1932,
        "syllable": "dưn",
        "consonant": "d",
        "rhyme": "ưn"
    },
    {
        "id": 1933,
        "syllable": "dưp",
        "consonant": "d",
        "rhyme": "ưp"
    },
    {
        "id": 1934,
        "syllable": "dưm",
        "consonant": "d",
        "rhyme": "ưm"
    },
    {
        "id": 1935,
        "syllable": "dưi",
        "consonant": "d",
        "rhyme": "ưi"
    },
    {
        "id": 1936,
        "syllable": "dưu",
        "consonant": "d",
        "rhyme": "ưu"
    },
    {
        "id": 1937,
        "syllable": "dơ",
        "consonant": "d",
        "rhyme": "ơ"
    },
    {
        "id": 1938,
        "syllable": "duơ",
        "consonant": "d",
        "rhyme": "uơ"
    },
    {
        "id": 1939,
        "syllable": "dơng",
        "consonant": "d",
        "rhyme": "ơng"
    },
    {
        "id": 1940,
        "syllable": "dơt",
        "consonant": "d",
        "rhyme": "ơt"
    },
    {
        "id": 1941,
        "syllable": "dơn",
        "consonant": "d",
        "rhyme": "ơn"
    },
    {
        "id": 1942,
        "syllable": "dơp",
        "consonant": "d",
        "rhyme": "ơp"
    },
    {
        "id": 1943,
        "syllable": "dơm",
        "consonant": "d",
        "rhyme": "ơm"
    },
    {
        "id": 1944,
        "syllable": "dơi",
        "consonant": "d",
        "rhyme": "ơi"
    },
    {
        "id": 1945,
        "syllable": "dâc",
        "consonant": "d",
        "rhyme": "âc"
    },
    {
        "id": 1946,
        "syllable": "dâng",
        "consonant": "d",
        "rhyme": "âng"
    },
    {
        "id": 1947,
        "syllable": "duâng",
        "consonant": "d",
        "rhyme": "uâng"
    },
    {
        "id": 1948,
        "syllable": "dât",
        "consonant": "d",
        "rhyme": "ât"
    },
    {
        "id": 1949,
        "syllable": "duât",
        "consonant": "d",
        "rhyme": "uât"
    },
    {
        "id": 1950,
        "syllable": "dân",
        "consonant": "d",
        "rhyme": "ân"
    },
    {
        "id": 1951,
        "syllable": "duân",
        "consonant": "d",
        "rhyme": "uân"
    },
    {
        "id": 1952,
        "syllable": "dâp",
        "consonant": "d",
        "rhyme": "âp"
    },
    {
        "id": 1953,
        "syllable": "duâp",
        "consonant": "d",
        "rhyme": "uâp"
    },
    {
        "id": 1954,
        "syllable": "dâm",
        "consonant": "d",
        "rhyme": "âm"
    },
    {
        "id": 1955,
        "syllable": "duâm",
        "consonant": "d",
        "rhyme": "uâm"
    },
    {
        "id": 1956,
        "syllable": "dây",
        "consonant": "d",
        "rhyme": "ây"
    },
    {
        "id": 1957,
        "syllable": "duây",
        "consonant": "d",
        "rhyme": "uây"
    },
    {
        "id": 1958,
        "syllable": "dâu",
        "consonant": "d",
        "rhyme": "âu"
    },
    {
        "id": 1959,
        "syllable": "duâu",
        "consonant": "d",
        "rhyme": "uâu"
    },
    {
        "id": 1960,
        "syllable": "du",
        "consonant": "d",
        "rhyme": "u"
    },
    {
        "id": 1961,
        "syllable": "duc",
        "consonant": "d",
        "rhyme": "uc"
    },
    {
        "id": 1962,
        "syllable": "dung",
        "consonant": "d",
        "rhyme": "ung"
    },
    {
        "id": 1963,
        "syllable": "dut",
        "consonant": "d",
        "rhyme": "ut"
    },
    {
        "id": 1964,
        "syllable": "dun",
        "consonant": "d",
        "rhyme": "un"
    },
    {
        "id": 1965,
        "syllable": "dup",
        "consonant": "d",
        "rhyme": "up"
    },
    {
        "id": 1966,
        "syllable": "dum",
        "consonant": "d",
        "rhyme": "um"
    },
    {
        "id": 1967,
        "syllable": "dui",
        "consonant": "d",
        "rhyme": "ui"
    },
    {
        "id": 1968,
        "syllable": "dô",
        "consonant": "d",
        "rhyme": "ô"
    },
    {
        "id": 1969,
        "syllable": "dôc",
        "consonant": "d",
        "rhyme": "ôc"
    },
    {
        "id": 1970,
        "syllable": "dông",
        "consonant": "d",
        "rhyme": "ông"
    },
    {
        "id": 1971,
        "syllable": "dôt",
        "consonant": "d",
        "rhyme": "ôt"
    },
    {
        "id": 1972,
        "syllable": "dôn",
        "consonant": "d",
        "rhyme": "ôn"
    },
    {
        "id": 1973,
        "syllable": "dôp",
        "consonant": "d",
        "rhyme": "ôp"
    },
    {
        "id": 1974,
        "syllable": "dôm",
        "consonant": "d",
        "rhyme": "ôm"
    },
    {
        "id": 1975,
        "syllable": "dôi",
        "consonant": "d",
        "rhyme": "ôi"
    },
    {
        "id": 1976,
        "syllable": "do",
        "consonant": "d",
        "rhyme": "o"
    },
    {
        "id": 1977,
        "syllable": "dooc",
        "consonant": "d",
        "rhyme": "ooc"
    },
    {
        "id": 1978,
        "syllable": "doong",
        "consonant": "d",
        "rhyme": "oong"
    },
    {
        "id": 1979,
        "syllable": "dot",
        "consonant": "d",
        "rhyme": "ot"
    },
    {
        "id": 1980,
        "syllable": "don",
        "consonant": "d",
        "rhyme": "on"
    },
    {
        "id": 1981,
        "syllable": "dop",
        "consonant": "d",
        "rhyme": "op"
    },
    {
        "id": 1982,
        "syllable": "dom",
        "consonant": "d",
        "rhyme": "om"
    },
    {
        "id": 1983,
        "syllable": "doi",
        "consonant": "d",
        "rhyme": "oi"
    },
    {
        "id": 1984,
        "syllable": "doc",
        "consonant": "d",
        "rhyme": "oc"
    },
    {
        "id": 1985,
        "syllable": "dong",
        "consonant": "d",
        "rhyme": "ong"
    },
    {
        "id": 1986,
        "syllable": "dua",
        "consonant": "d",
        "rhyme": "ua"
    },
    {
        "id": 1987,
        "syllable": "duôc",
        "consonant": "d",
        "rhyme": "uôc"
    },
    {
        "id": 1988,
        "syllable": "duông",
        "consonant": "d",
        "rhyme": "uông"
    },
    {
        "id": 1989,
        "syllable": "duôt",
        "consonant": "d",
        "rhyme": "uôt"
    },
    {
        "id": 1990,
        "syllable": "duôn",
        "consonant": "d",
        "rhyme": "uôn"
    },
    {
        "id": 1991,
        "syllable": "duôp",
        "consonant": "d",
        "rhyme": "uôp"
    },
    {
        "id": 1992,
        "syllable": "duôm",
        "consonant": "d",
        "rhyme": "uôm"
    },
    {
        "id": 1993,
        "syllable": "duôi",
        "consonant": "d",
        "rhyme": "uôi"
    },
    {
        "id": 1994,
        "syllable": "dưa",
        "consonant": "d",
        "rhyme": "ưa"
    },
    {
        "id": 1995,
        "syllable": "dươc",
        "consonant": "d",
        "rhyme": "ươc"
    },
    {
        "id": 1996,
        "syllable": "dương",
        "consonant": "d",
        "rhyme": "ương"
    },
    {
        "id": 1997,
        "syllable": "dươt",
        "consonant": "d",
        "rhyme": "ươt"
    },
    {
        "id": 1998,
        "syllable": "dươn",
        "consonant": "d",
        "rhyme": "ươn"
    },
    {
        "id": 1999,
        "syllable": "dươp",
        "consonant": "d",
        "rhyme": "ươp"
    },
    {
        "id": 2000,
        "syllable": "dươm",
        "consonant": "d",
        "rhyme": "ươm"
    },
    {
        "id": 2001,
        "syllable": "dươi",
        "consonant": "d",
        "rhyme": "ươi"
    },
    {
        "id": 2002,
        "syllable": "da",
        "consonant": "d",
        "rhyme": "a"
    },
    {
        "id": 2003,
        "syllable": "doa",
        "consonant": "d",
        "rhyme": "oa"
    },
    {
        "id": 2004,
        "syllable": "dac",
        "consonant": "d",
        "rhyme": "ac"
    },
    {
        "id": 2005,
        "syllable": "doac",
        "consonant": "d",
        "rhyme": "oac"
    },
    {
        "id": 2006,
        "syllable": "dang",
        "consonant": "d",
        "rhyme": "ang"
    },
    {
        "id": 2007,
        "syllable": "doang",
        "consonant": "d",
        "rhyme": "oang"
    },
    {
        "id": 2008,
        "syllable": "dat",
        "consonant": "d",
        "rhyme": "at"
    },
    {
        "id": 2009,
        "syllable": "doat",
        "consonant": "d",
        "rhyme": "oat"
    },
    {
        "id": 2010,
        "syllable": "dan",
        "consonant": "d",
        "rhyme": "an"
    },
    {
        "id": 2011,
        "syllable": "doan",
        "consonant": "d",
        "rhyme": "oan"
    },
    {
        "id": 2012,
        "syllable": "dap",
        "consonant": "d",
        "rhyme": "ap"
    },
    {
        "id": 2013,
        "syllable": "doap",
        "consonant": "d",
        "rhyme": "oap"
    },
    {
        "id": 2014,
        "syllable": "dam",
        "consonant": "d",
        "rhyme": "am"
    },
    {
        "id": 2015,
        "syllable": "doam",
        "consonant": "d",
        "rhyme": "oam"
    },
    {
        "id": 2016,
        "syllable": "dai",
        "consonant": "d",
        "rhyme": "ai"
    },
    {
        "id": 2017,
        "syllable": "doai",
        "consonant": "d",
        "rhyme": "oai"
    },
    {
        "id": 2018,
        "syllable": "dao",
        "consonant": "d",
        "rhyme": "ao"
    },
    {
        "id": 2019,
        "syllable": "doao",
        "consonant": "d",
        "rhyme": "oao"
    },
    {
        "id": 2020,
        "syllable": "dăc",
        "consonant": "d",
        "rhyme": "ăc"
    },
    {
        "id": 2021,
        "syllable": "doăc",
        "consonant": "d",
        "rhyme": "oăc"
    },
    {
        "id": 2022,
        "syllable": "dăng",
        "consonant": "d",
        "rhyme": "ăng"
    },
    {
        "id": 2023,
        "syllable": "doăng",
        "consonant": "d",
        "rhyme": "oăng"
    },
    {
        "id": 2024,
        "syllable": "dăt",
        "consonant": "d",
        "rhyme": "ăt"
    },
    {
        "id": 2025,
        "syllable": "doăt",
        "consonant": "d",
        "rhyme": "oăt"
    },
    {
        "id": 2026,
        "syllable": "dăn",
        "consonant": "d",
        "rhyme": "ăn"
    },
    {
        "id": 2027,
        "syllable": "doăn",
        "consonant": "d",
        "rhyme": "oăn"
    },
    {
        "id": 2028,
        "syllable": "dăp",
        "consonant": "d",
        "rhyme": "ăp"
    },
    {
        "id": 2029,
        "syllable": "doăp",
        "consonant": "d",
        "rhyme": "oăp"
    },
    {
        "id": 2030,
        "syllable": "dăm",
        "consonant": "d",
        "rhyme": "ăm"
    },
    {
        "id": 2031,
        "syllable": "doăm",
        "consonant": "d",
        "rhyme": "oăm"
    },
    {
        "id": 2032,
        "syllable": "day",
        "consonant": "d",
        "rhyme": "ay"
    },
    {
        "id": 2033,
        "syllable": "doay",
        "consonant": "d",
        "rhyme": "oay"
    },
    {
        "id": 2034,
        "syllable": "dau",
        "consonant": "d",
        "rhyme": "au"
    },
    {
        "id": 2035,
        "syllable": "doau",
        "consonant": "d",
        "rhyme": "oau"
    },
    {
        "id": 2036,
        "syllable": "gii",
        "consonant": "gi",
        "rhyme": "i"
    },
    {
        "id": 2037,
        "syllable": "giuy",
        "consonant": "gi",
        "rhyme": "uy"
    },
    {
        "id": 2038,
        "syllable": "giich",
        "consonant": "gi",
        "rhyme": "ich"
    },
    {
        "id": 2039,
        "syllable": "giuych",
        "consonant": "gi",
        "rhyme": "uych"
    },
    {
        "id": 2040,
        "syllable": "giinh",
        "consonant": "gi",
        "rhyme": "inh"
    },
    {
        "id": 2041,
        "syllable": "giuynh",
        "consonant": "gi",
        "rhyme": "uynh"
    },
    {
        "id": 2042,
        "syllable": "giit",
        "consonant": "gi",
        "rhyme": "it"
    },
    {
        "id": 2043,
        "syllable": "giuyt",
        "consonant": "gi",
        "rhyme": "uyt"
    },
    {
        "id": 2044,
        "syllable": "giin",
        "consonant": "gi",
        "rhyme": "in"
    },
    {
        "id": 2045,
        "syllable": "giuyn",
        "consonant": "gi",
        "rhyme": "uyn"
    },
    {
        "id": 2046,
        "syllable": "giip",
        "consonant": "gi",
        "rhyme": "ip"
    },
    {
        "id": 2047,
        "syllable": "giuyp",
        "consonant": "gi",
        "rhyme": "uyp"
    },
    {
        "id": 2048,
        "syllable": "giim",
        "consonant": "gi",
        "rhyme": "im"
    },
    {
        "id": 2049,
        "syllable": "giuym",
        "consonant": "gi",
        "rhyme": "uym"
    },
    {
        "id": 2050,
        "syllable": "giiu",
        "consonant": "gi",
        "rhyme": "iu"
    },
    {
        "id": 2051,
        "syllable": "giuyu",
        "consonant": "gi",
        "rhyme": "uyu"
    },
    {
        "id": 2052,
        "syllable": "giê",
        "consonant": "gi",
        "rhyme": "ê"
    },
    {
        "id": 2053,
        "syllable": "giuê",
        "consonant": "gi",
        "rhyme": "uê"
    },
    {
        "id": 2054,
        "syllable": "giêch",
        "consonant": "gi",
        "rhyme": "êch"
    },
    {
        "id": 2055,
        "syllable": "giuêch",
        "consonant": "gi",
        "rhyme": "uêch"
    },
    {
        "id": 2056,
        "syllable": "giênh",
        "consonant": "gi",
        "rhyme": "ênh"
    },
    {
        "id": 2057,
        "syllable": "giuênh",
        "consonant": "gi",
        "rhyme": "uênh"
    },
    {
        "id": 2058,
        "syllable": "giêt",
        "consonant": "gi",
        "rhyme": "êt"
    },
    {
        "id": 2059,
        "syllable": "giuêt",
        "consonant": "gi",
        "rhyme": "uêt"
    },
    {
        "id": 2060,
        "syllable": "giên",
        "consonant": "gi",
        "rhyme": "ên"
    },
    {
        "id": 2061,
        "syllable": "giuên",
        "consonant": "gi",
        "rhyme": "uên"
    },
    {
        "id": 2062,
        "syllable": "giêp",
        "consonant": "gi",
        "rhyme": "êp"
    },
    {
        "id": 2063,
        "syllable": "giuêp",
        "consonant": "gi",
        "rhyme": "uêp"
    },
    {
        "id": 2064,
        "syllable": "giêm",
        "consonant": "gi",
        "rhyme": "êm"
    },
    {
        "id": 2065,
        "syllable": "giuêm",
        "consonant": "gi",
        "rhyme": "uêm"
    },
    {
        "id": 2066,
        "syllable": "giêu",
        "consonant": "gi",
        "rhyme": "êu"
    },
    {
        "id": 2067,
        "syllable": "gie",
        "consonant": "gi",
        "rhyme": "e"
    },
    {
        "id": 2068,
        "syllable": "gioe",
        "consonant": "gi",
        "rhyme": "oe"
    },
    {
        "id": 2069,
        "syllable": "giec",
        "consonant": "gi",
        "rhyme": "ec"
    },
    {
        "id": 2070,
        "syllable": "gioec",
        "consonant": "gi",
        "rhyme": "oec"
    },
    {
        "id": 2071,
        "syllable": "gieng",
        "consonant": "gi",
        "rhyme": "eng"
    },
    {
        "id": 2072,
        "syllable": "gioeng",
        "consonant": "gi",
        "rhyme": "oeng"
    },
    {
        "id": 2073,
        "syllable": "giet",
        "consonant": "gi",
        "rhyme": "et"
    },
    {
        "id": 2074,
        "syllable": "gioet",
        "consonant": "gi",
        "rhyme": "oet"
    },
    {
        "id": 2075,
        "syllable": "gien",
        "consonant": "gi",
        "rhyme": "en"
    },
    {
        "id": 2076,
        "syllable": "gioen",
        "consonant": "gi",
        "rhyme": "oen"
    },
    {
        "id": 2077,
        "syllable": "giep",
        "consonant": "gi",
        "rhyme": "ep"
    },
    {
        "id": 2078,
        "syllable": "gioep",
        "consonant": "gi",
        "rhyme": "oep"
    },
    {
        "id": 2079,
        "syllable": "giem",
        "consonant": "gi",
        "rhyme": "em"
    },
    {
        "id": 2080,
        "syllable": "gioem",
        "consonant": "gi",
        "rhyme": "oem"
    },
    {
        "id": 2081,
        "syllable": "gieo",
        "consonant": "gi",
        "rhyme": "eo"
    },
    {
        "id": 2082,
        "syllable": "gioeo",
        "consonant": "gi",
        "rhyme": "oeo"
    },
    {
        "id": 2083,
        "syllable": "giach",
        "consonant": "gi",
        "rhyme": "ach"
    },
    {
        "id": 2084,
        "syllable": "gioach",
        "consonant": "gi",
        "rhyme": "oach"
    },
    {
        "id": 2085,
        "syllable": "gianh",
        "consonant": "gi",
        "rhyme": "anh"
    },
    {
        "id": 2086,
        "syllable": "gioanh",
        "consonant": "gi",
        "rhyme": "oanh"
    },
    {
        "id": 2087,
        "syllable": "giia",
        "consonant": "gi",
        "rhyme": "ia"
    },
    {
        "id": 2088,
        "syllable": "giuya",
        "consonant": "gi",
        "rhyme": "uya"
    },
    {
        "id": 2089,
        "syllable": "giiêc",
        "consonant": "gi",
        "rhyme": "iêc"
    },
    {
        "id": 2090,
        "syllable": "giiêng",
        "consonant": "gi",
        "rhyme": "iêng"
    },
    {
        "id": 2091,
        "syllable": "giiêt",
        "consonant": "gi",
        "rhyme": "iêt"
    },
    {
        "id": 2092,
        "syllable": "giiên",
        "consonant": "gi",
        "rhyme": "iên"
    },
    {
        "id": 2093,
        "syllable": "giuyêt",
        "consonant": "gi",
        "rhyme": "uyêt"
    },
    {
        "id": 2094,
        "syllable": "giuyên",
        "consonant": "gi",
        "rhyme": "uyên"
    },
    {
        "id": 2095,
        "syllable": "giiêp",
        "consonant": "gi",
        "rhyme": "iêp"
    },
    {
        "id": 2096,
        "syllable": "giuyêp",
        "consonant": "gi",
        "rhyme": "uyêp"
    },
    {
        "id": 2097,
        "syllable": "giiêm",
        "consonant": "gi",
        "rhyme": "iêm"
    },
    {
        "id": 2098,
        "syllable": "giuyêm",
        "consonant": "gi",
        "rhyme": "uyêm"
    },
    {
        "id": 2099,
        "syllable": "giiêu",
        "consonant": "gi",
        "rhyme": "iêu"
    },
    {
        "id": 2100,
        "syllable": "giư",
        "consonant": "gi",
        "rhyme": "ư"
    },
    {
        "id": 2101,
        "syllable": "giưc",
        "consonant": "gi",
        "rhyme": "ưc"
    },
    {
        "id": 2102,
        "syllable": "giưng",
        "consonant": "gi",
        "rhyme": "ưng"
    },
    {
        "id": 2103,
        "syllable": "giưt",
        "consonant": "gi",
        "rhyme": "ưt"
    },
    {
        "id": 2104,
        "syllable": "giưn",
        "consonant": "gi",
        "rhyme": "ưn"
    },
    {
        "id": 2105,
        "syllable": "giưp",
        "consonant": "gi",
        "rhyme": "ưp"
    },
    {
        "id": 2106,
        "syllable": "giưm",
        "consonant": "gi",
        "rhyme": "ưm"
    },
    {
        "id": 2107,
        "syllable": "giưi",
        "consonant": "gi",
        "rhyme": "ưi"
    },
    {
        "id": 2108,
        "syllable": "giưu",
        "consonant": "gi",
        "rhyme": "ưu"
    },
    {
        "id": 2109,
        "syllable": "giơ",
        "consonant": "gi",
        "rhyme": "ơ"
    },
    {
        "id": 2110,
        "syllable": "giuơ",
        "consonant": "gi",
        "rhyme": "uơ"
    },
    {
        "id": 2111,
        "syllable": "giơng",
        "consonant": "gi",
        "rhyme": "ơng"
    },
    {
        "id": 2112,
        "syllable": "giơt",
        "consonant": "gi",
        "rhyme": "ơt"
    },
    {
        "id": 2113,
        "syllable": "giơn",
        "consonant": "gi",
        "rhyme": "ơn"
    },
    {
        "id": 2114,
        "syllable": "giơp",
        "consonant": "gi",
        "rhyme": "ơp"
    },
    {
        "id": 2115,
        "syllable": "giơm",
        "consonant": "gi",
        "rhyme": "ơm"
    },
    {
        "id": 2116,
        "syllable": "giơi",
        "consonant": "gi",
        "rhyme": "ơi"
    },
    {
        "id": 2117,
        "syllable": "giâc",
        "consonant": "gi",
        "rhyme": "âc"
    },
    {
        "id": 2118,
        "syllable": "giâng",
        "consonant": "gi",
        "rhyme": "âng"
    },
    {
        "id": 2119,
        "syllable": "giuâng",
        "consonant": "gi",
        "rhyme": "uâng"
    },
    {
        "id": 2120,
        "syllable": "giât",
        "consonant": "gi",
        "rhyme": "ât"
    },
    {
        "id": 2121,
        "syllable": "giuât",
        "consonant": "gi",
        "rhyme": "uât"
    },
    {
        "id": 2122,
        "syllable": "giân",
        "consonant": "gi",
        "rhyme": "ân"
    },
    {
        "id": 2123,
        "syllable": "giuân",
        "consonant": "gi",
        "rhyme": "uân"
    },
    {
        "id": 2124,
        "syllable": "giâp",
        "consonant": "gi",
        "rhyme": "âp"
    },
    {
        "id": 2125,
        "syllable": "giuâp",
        "consonant": "gi",
        "rhyme": "uâp"
    },
    {
        "id": 2126,
        "syllable": "giâm",
        "consonant": "gi",
        "rhyme": "âm"
    },
    {
        "id": 2127,
        "syllable": "giuâm",
        "consonant": "gi",
        "rhyme": "uâm"
    },
    {
        "id": 2128,
        "syllable": "giây",
        "consonant": "gi",
        "rhyme": "ây"
    },
    {
        "id": 2129,
        "syllable": "giuây",
        "consonant": "gi",
        "rhyme": "uây"
    },
    {
        "id": 2130,
        "syllable": "giâu",
        "consonant": "gi",
        "rhyme": "âu"
    },
    {
        "id": 2131,
        "syllable": "giuâu",
        "consonant": "gi",
        "rhyme": "uâu"
    },
    {
        "id": 2132,
        "syllable": "giu",
        "consonant": "gi",
        "rhyme": "u"
    },
    {
        "id": 2133,
        "syllable": "giuc",
        "consonant": "gi",
        "rhyme": "uc"
    },
    {
        "id": 2134,
        "syllable": "giung",
        "consonant": "gi",
        "rhyme": "ung"
    },
    {
        "id": 2135,
        "syllable": "giut",
        "consonant": "gi",
        "rhyme": "ut"
    },
    {
        "id": 2136,
        "syllable": "giun",
        "consonant": "gi",
        "rhyme": "un"
    },
    {
        "id": 2137,
        "syllable": "giup",
        "consonant": "gi",
        "rhyme": "up"
    },
    {
        "id": 2138,
        "syllable": "gium",
        "consonant": "gi",
        "rhyme": "um"
    },
    {
        "id": 2139,
        "syllable": "giui",
        "consonant": "gi",
        "rhyme": "ui"
    },
    {
        "id": 2140,
        "syllable": "giô",
        "consonant": "gi",
        "rhyme": "ô"
    },
    {
        "id": 2141,
        "syllable": "giôc",
        "consonant": "gi",
        "rhyme": "ôc"
    },
    {
        "id": 2142,
        "syllable": "giông",
        "consonant": "gi",
        "rhyme": "ông"
    },
    {
        "id": 2143,
        "syllable": "giôt",
        "consonant": "gi",
        "rhyme": "ôt"
    },
    {
        "id": 2144,
        "syllable": "giôn",
        "consonant": "gi",
        "rhyme": "ôn"
    },
    {
        "id": 2145,
        "syllable": "giôp",
        "consonant": "gi",
        "rhyme": "ôp"
    },
    {
        "id": 2146,
        "syllable": "giôm",
        "consonant": "gi",
        "rhyme": "ôm"
    },
    {
        "id": 2147,
        "syllable": "giôi",
        "consonant": "gi",
        "rhyme": "ôi"
    },
    {
        "id": 2148,
        "syllable": "gio",
        "consonant": "gi",
        "rhyme": "o"
    },
    {
        "id": 2149,
        "syllable": "giooc",
        "consonant": "gi",
        "rhyme": "ooc"
    },
    {
        "id": 2150,
        "syllable": "gioong",
        "consonant": "gi",
        "rhyme": "oong"
    },
    {
        "id": 2151,
        "syllable": "giot",
        "consonant": "gi",
        "rhyme": "ot"
    },
    {
        "id": 2152,
        "syllable": "gion",
        "consonant": "gi",
        "rhyme": "on"
    },
    {
        "id": 2153,
        "syllable": "giop",
        "consonant": "gi",
        "rhyme": "op"
    },
    {
        "id": 2154,
        "syllable": "giom",
        "consonant": "gi",
        "rhyme": "om"
    },
    {
        "id": 2155,
        "syllable": "gioi",
        "consonant": "gi",
        "rhyme": "oi"
    },
    {
        "id": 2156,
        "syllable": "gioc",
        "consonant": "gi",
        "rhyme": "oc"
    },
    {
        "id": 2157,
        "syllable": "giong",
        "consonant": "gi",
        "rhyme": "ong"
    },
    {
        "id": 2158,
        "syllable": "giua",
        "consonant": "gi",
        "rhyme": "ua"
    },
    {
        "id": 2159,
        "syllable": "giuôc",
        "consonant": "gi",
        "rhyme": "uôc"
    },
    {
        "id": 2160,
        "syllable": "giuông",
        "consonant": "gi",
        "rhyme": "uông"
    },
    {
        "id": 2161,
        "syllable": "giuôt",
        "consonant": "gi",
        "rhyme": "uôt"
    },
    {
        "id": 2162,
        "syllable": "giuôn",
        "consonant": "gi",
        "rhyme": "uôn"
    },
    {
        "id": 2163,
        "syllable": "giuôp",
        "consonant": "gi",
        "rhyme": "uôp"
    },
    {
        "id": 2164,
        "syllable": "giuôm",
        "consonant": "gi",
        "rhyme": "uôm"
    },
    {
        "id": 2165,
        "syllable": "giuôi",
        "consonant": "gi",
        "rhyme": "uôi"
    },
    {
        "id": 2166,
        "syllable": "giưa",
        "consonant": "gi",
        "rhyme": "ưa"
    },
    {
        "id": 2167,
        "syllable": "giươc",
        "consonant": "gi",
        "rhyme": "ươc"
    },
    {
        "id": 2168,
        "syllable": "giương",
        "consonant": "gi",
        "rhyme": "ương"
    },
    {
        "id": 2169,
        "syllable": "giươt",
        "consonant": "gi",
        "rhyme": "ươt"
    },
    {
        "id": 2170,
        "syllable": "giươn",
        "consonant": "gi",
        "rhyme": "ươn"
    },
    {
        "id": 2171,
        "syllable": "giươp",
        "consonant": "gi",
        "rhyme": "ươp"
    },
    {
        "id": 2172,
        "syllable": "giươm",
        "consonant": "gi",
        "rhyme": "ươm"
    },
    {
        "id": 2173,
        "syllable": "giươi",
        "consonant": "gi",
        "rhyme": "ươi"
    },
    {
        "id": 2174,
        "syllable": "gia",
        "consonant": "gi",
        "rhyme": "a"
    },
    {
        "id": 2175,
        "syllable": "gioa",
        "consonant": "gi",
        "rhyme": "oa"
    },
    {
        "id": 2176,
        "syllable": "giac",
        "consonant": "gi",
        "rhyme": "ac"
    },
    {
        "id": 2177,
        "syllable": "gioac",
        "consonant": "gi",
        "rhyme": "oac"
    },
    {
        "id": 2178,
        "syllable": "giang",
        "consonant": "gi",
        "rhyme": "ang"
    },
    {
        "id": 2179,
        "syllable": "gioang",
        "consonant": "gi",
        "rhyme": "oang"
    },
    {
        "id": 2180,
        "syllable": "giat",
        "consonant": "gi",
        "rhyme": "at"
    },
    {
        "id": 2181,
        "syllable": "gioat",
        "consonant": "gi",
        "rhyme": "oat"
    },
    {
        "id": 2182,
        "syllable": "gian",
        "consonant": "gi",
        "rhyme": "an"
    },
    {
        "id": 2183,
        "syllable": "gioan",
        "consonant": "gi",
        "rhyme": "oan"
    },
    {
        "id": 2184,
        "syllable": "giap",
        "consonant": "gi",
        "rhyme": "ap"
    },
    {
        "id": 2185,
        "syllable": "gioap",
        "consonant": "gi",
        "rhyme": "oap"
    },
    {
        "id": 2186,
        "syllable": "giam",
        "consonant": "gi",
        "rhyme": "am"
    },
    {
        "id": 2187,
        "syllable": "gioam",
        "consonant": "gi",
        "rhyme": "oam"
    },
    {
        "id": 2188,
        "syllable": "giai",
        "consonant": "gi",
        "rhyme": "ai"
    },
    {
        "id": 2189,
        "syllable": "gioai",
        "consonant": "gi",
        "rhyme": "oai"
    },
    {
        "id": 2190,
        "syllable": "giao",
        "consonant": "gi",
        "rhyme": "ao"
    },
    {
        "id": 2191,
        "syllable": "gioao",
        "consonant": "gi",
        "rhyme": "oao"
    },
    {
        "id": 2192,
        "syllable": "giăc",
        "consonant": "gi",
        "rhyme": "ăc"
    },
    {
        "id": 2193,
        "syllable": "gioăc",
        "consonant": "gi",
        "rhyme": "oăc"
    },
    {
        "id": 2194,
        "syllable": "giăng",
        "consonant": "gi",
        "rhyme": "ăng"
    },
    {
        "id": 2195,
        "syllable": "gioăng",
        "consonant": "gi",
        "rhyme": "oăng"
    },
    {
        "id": 2196,
        "syllable": "giăt",
        "consonant": "gi",
        "rhyme": "ăt"
    },
    {
        "id": 2197,
        "syllable": "gioăt",
        "consonant": "gi",
        "rhyme": "oăt"
    },
    {
        "id": 2198,
        "syllable": "giăn",
        "consonant": "gi",
        "rhyme": "ăn"
    },
    {
        "id": 2199,
        "syllable": "gioăn",
        "consonant": "gi",
        "rhyme": "oăn"
    },
    {
        "id": 2200,
        "syllable": "giăp",
        "consonant": "gi",
        "rhyme": "ăp"
    },
    {
        "id": 2201,
        "syllable": "gioăp",
        "consonant": "gi",
        "rhyme": "oăp"
    },
    {
        "id": 2202,
        "syllable": "giăm",
        "consonant": "gi",
        "rhyme": "ăm"
    },
    {
        "id": 2203,
        "syllable": "gioăm",
        "consonant": "gi",
        "rhyme": "oăm"
    },
    {
        "id": 2204,
        "syllable": "giay",
        "consonant": "gi",
        "rhyme": "ay"
    },
    {
        "id": 2205,
        "syllable": "gioay",
        "consonant": "gi",
        "rhyme": "oay"
    },
    {
        "id": 2206,
        "syllable": "giau",
        "consonant": "gi",
        "rhyme": "au"
    },
    {
        "id": 2207,
        "syllable": "gioau",
        "consonant": "gi",
        "rhyme": "oau"
    },
    {
        "id": 2208,
        "syllable": "ri",
        "consonant": "r",
        "rhyme": "i"
    },
    {
        "id": 2209,
        "syllable": "ruy",
        "consonant": "r",
        "rhyme": "uy"
    },
    {
        "id": 2210,
        "syllable": "rich",
        "consonant": "r",
        "rhyme": "ich"
    },
    {
        "id": 2211,
        "syllable": "ruych",
        "consonant": "r",
        "rhyme": "uych"
    },
    {
        "id": 2212,
        "syllable": "rinh",
        "consonant": "r",
        "rhyme": "inh"
    },
    {
        "id": 2213,
        "syllable": "ruynh",
        "consonant": "r",
        "rhyme": "uynh"
    },
    {
        "id": 2214,
        "syllable": "rit",
        "consonant": "r",
        "rhyme": "it"
    },
    {
        "id": 2215,
        "syllable": "ruyt",
        "consonant": "r",
        "rhyme": "uyt"
    },
    {
        "id": 2216,
        "syllable": "rin",
        "consonant": "r",
        "rhyme": "in"
    },
    {
        "id": 2217,
        "syllable": "ruyn",
        "consonant": "r",
        "rhyme": "uyn"
    },
    {
        "id": 2218,
        "syllable": "rip",
        "consonant": "r",
        "rhyme": "ip"
    },
    {
        "id": 2219,
        "syllable": "ruyp",
        "consonant": "r",
        "rhyme": "uyp"
    },
    {
        "id": 2220,
        "syllable": "rim",
        "consonant": "r",
        "rhyme": "im"
    },
    {
        "id": 2221,
        "syllable": "ruym",
        "consonant": "r",
        "rhyme": "uym"
    },
    {
        "id": 2222,
        "syllable": "riu",
        "consonant": "r",
        "rhyme": "iu"
    },
    {
        "id": 2223,
        "syllable": "ruyu",
        "consonant": "r",
        "rhyme": "uyu"
    },
    {
        "id": 2224,
        "syllable": "rê",
        "consonant": "r",
        "rhyme": "ê"
    },
    {
        "id": 2225,
        "syllable": "ruê",
        "consonant": "r",
        "rhyme": "uê"
    },
    {
        "id": 2226,
        "syllable": "rêch",
        "consonant": "r",
        "rhyme": "êch"
    },
    {
        "id": 2227,
        "syllable": "ruêch",
        "consonant": "r",
        "rhyme": "uêch"
    },
    {
        "id": 2228,
        "syllable": "rênh",
        "consonant": "r",
        "rhyme": "ênh"
    },
    {
        "id": 2229,
        "syllable": "ruênh",
        "consonant": "r",
        "rhyme": "uênh"
    },
    {
        "id": 2230,
        "syllable": "rêt",
        "consonant": "r",
        "rhyme": "êt"
    },
    {
        "id": 2231,
        "syllable": "ruêt",
        "consonant": "r",
        "rhyme": "uêt"
    },
    {
        "id": 2232,
        "syllable": "rên",
        "consonant": "r",
        "rhyme": "ên"
    },
    {
        "id": 2233,
        "syllable": "ruên",
        "consonant": "r",
        "rhyme": "uên"
    },
    {
        "id": 2234,
        "syllable": "rêp",
        "consonant": "r",
        "rhyme": "êp"
    },
    {
        "id": 2235,
        "syllable": "ruêp",
        "consonant": "r",
        "rhyme": "uêp"
    },
    {
        "id": 2236,
        "syllable": "rêm",
        "consonant": "r",
        "rhyme": "êm"
    },
    {
        "id": 2237,
        "syllable": "ruêm",
        "consonant": "r",
        "rhyme": "uêm"
    },
    {
        "id": 2238,
        "syllable": "rêu",
        "consonant": "r",
        "rhyme": "êu"
    },
    {
        "id": 2239,
        "syllable": "re",
        "consonant": "r",
        "rhyme": "e"
    },
    {
        "id": 2240,
        "syllable": "roe",
        "consonant": "r",
        "rhyme": "oe"
    },
    {
        "id": 2241,
        "syllable": "rec",
        "consonant": "r",
        "rhyme": "ec"
    },
    {
        "id": 2242,
        "syllable": "roec",
        "consonant": "r",
        "rhyme": "oec"
    },
    {
        "id": 2243,
        "syllable": "reng",
        "consonant": "r",
        "rhyme": "eng"
    },
    {
        "id": 2244,
        "syllable": "roeng",
        "consonant": "r",
        "rhyme": "oeng"
    },
    {
        "id": 2245,
        "syllable": "ret",
        "consonant": "r",
        "rhyme": "et"
    },
    {
        "id": 2246,
        "syllable": "roet",
        "consonant": "r",
        "rhyme": "oet"
    },
    {
        "id": 2247,
        "syllable": "ren",
        "consonant": "r",
        "rhyme": "en"
    },
    {
        "id": 2248,
        "syllable": "roen",
        "consonant": "r",
        "rhyme": "oen"
    },
    {
        "id": 2249,
        "syllable": "rep",
        "consonant": "r",
        "rhyme": "ep"
    },
    {
        "id": 2250,
        "syllable": "roep",
        "consonant": "r",
        "rhyme": "oep"
    },
    {
        "id": 2251,
        "syllable": "rem",
        "consonant": "r",
        "rhyme": "em"
    },
    {
        "id": 2252,
        "syllable": "roem",
        "consonant": "r",
        "rhyme": "oem"
    },
    {
        "id": 2253,
        "syllable": "reo",
        "consonant": "r",
        "rhyme": "eo"
    },
    {
        "id": 2254,
        "syllable": "roeo",
        "consonant": "r",
        "rhyme": "oeo"
    },
    {
        "id": 2255,
        "syllable": "rach",
        "consonant": "r",
        "rhyme": "ach"
    },
    {
        "id": 2256,
        "syllable": "roach",
        "consonant": "r",
        "rhyme": "oach"
    },
    {
        "id": 2257,
        "syllable": "ranh",
        "consonant": "r",
        "rhyme": "anh"
    },
    {
        "id": 2258,
        "syllable": "roanh",
        "consonant": "r",
        "rhyme": "oanh"
    },
    {
        "id": 2259,
        "syllable": "ria",
        "consonant": "r",
        "rhyme": "ia"
    },
    {
        "id": 2260,
        "syllable": "ruya",
        "consonant": "r",
        "rhyme": "uya"
    },
    {
        "id": 2261,
        "syllable": "riêc",
        "consonant": "r",
        "rhyme": "iêc"
    },
    {
        "id": 2262,
        "syllable": "riêng",
        "consonant": "r",
        "rhyme": "iêng"
    },
    {
        "id": 2263,
        "syllable": "riêt",
        "consonant": "r",
        "rhyme": "iêt"
    },
    {
        "id": 2264,
        "syllable": "riên",
        "consonant": "r",
        "rhyme": "iên"
    },
    {
        "id": 2265,
        "syllable": "ruyêt",
        "consonant": "r",
        "rhyme": "uyêt"
    },
    {
        "id": 2266,
        "syllable": "ruyên",
        "consonant": "r",
        "rhyme": "uyên"
    },
    {
        "id": 2267,
        "syllable": "riêp",
        "consonant": "r",
        "rhyme": "iêp"
    },
    {
        "id": 2268,
        "syllable": "ruyêp",
        "consonant": "r",
        "rhyme": "uyêp"
    },
    {
        "id": 2269,
        "syllable": "riêm",
        "consonant": "r",
        "rhyme": "iêm"
    },
    {
        "id": 2270,
        "syllable": "ruyêm",
        "consonant": "r",
        "rhyme": "uyêm"
    },
    {
        "id": 2271,
        "syllable": "riêu",
        "consonant": "r",
        "rhyme": "iêu"
    },
    {
        "id": 2272,
        "syllable": "rư",
        "consonant": "r",
        "rhyme": "ư"
    },
    {
        "id": 2273,
        "syllable": "rưc",
        "consonant": "r",
        "rhyme": "ưc"
    },
    {
        "id": 2274,
        "syllable": "rưng",
        "consonant": "r",
        "rhyme": "ưng"
    },
    {
        "id": 2275,
        "syllable": "rưt",
        "consonant": "r",
        "rhyme": "ưt"
    },
    {
        "id": 2276,
        "syllable": "rưn",
        "consonant": "r",
        "rhyme": "ưn"
    },
    {
        "id": 2277,
        "syllable": "rưp",
        "consonant": "r",
        "rhyme": "ưp"
    },
    {
        "id": 2278,
        "syllable": "rưm",
        "consonant": "r",
        "rhyme": "ưm"
    },
    {
        "id": 2279,
        "syllable": "rưi",
        "consonant": "r",
        "rhyme": "ưi"
    },
    {
        "id": 2280,
        "syllable": "rưu",
        "consonant": "r",
        "rhyme": "ưu"
    },
    {
        "id": 2281,
        "syllable": "rơ",
        "consonant": "r",
        "rhyme": "ơ"
    },
    {
        "id": 2282,
        "syllable": "ruơ",
        "consonant": "r",
        "rhyme": "uơ"
    },
    {
        "id": 2283,
        "syllable": "rơng",
        "consonant": "r",
        "rhyme": "ơng"
    },
    {
        "id": 2284,
        "syllable": "rơt",
        "consonant": "r",
        "rhyme": "ơt"
    },
    {
        "id": 2285,
        "syllable": "rơn",
        "consonant": "r",
        "rhyme": "ơn"
    },
    {
        "id": 2286,
        "syllable": "rơp",
        "consonant": "r",
        "rhyme": "ơp"
    },
    {
        "id": 2287,
        "syllable": "rơm",
        "consonant": "r",
        "rhyme": "ơm"
    },
    {
        "id": 2288,
        "syllable": "rơi",
        "consonant": "r",
        "rhyme": "ơi"
    },
    {
        "id": 2289,
        "syllable": "râc",
        "consonant": "r",
        "rhyme": "âc"
    },
    {
        "id": 2290,
        "syllable": "râng",
        "consonant": "r",
        "rhyme": "âng"
    },
    {
        "id": 2291,
        "syllable": "ruâng",
        "consonant": "r",
        "rhyme": "uâng"
    },
    {
        "id": 2292,
        "syllable": "rât",
        "consonant": "r",
        "rhyme": "ât"
    },
    {
        "id": 2293,
        "syllable": "ruât",
        "consonant": "r",
        "rhyme": "uât"
    },
    {
        "id": 2294,
        "syllable": "rân",
        "consonant": "r",
        "rhyme": "ân"
    },
    {
        "id": 2295,
        "syllable": "ruân",
        "consonant": "r",
        "rhyme": "uân"
    },
    {
        "id": 2296,
        "syllable": "râp",
        "consonant": "r",
        "rhyme": "âp"
    },
    {
        "id": 2297,
        "syllable": "ruâp",
        "consonant": "r",
        "rhyme": "uâp"
    },
    {
        "id": 2298,
        "syllable": "râm",
        "consonant": "r",
        "rhyme": "âm"
    },
    {
        "id": 2299,
        "syllable": "ruâm",
        "consonant": "r",
        "rhyme": "uâm"
    },
    {
        "id": 2300,
        "syllable": "rây",
        "consonant": "r",
        "rhyme": "ây"
    },
    {
        "id": 2301,
        "syllable": "ruây",
        "consonant": "r",
        "rhyme": "uây"
    },
    {
        "id": 2302,
        "syllable": "râu",
        "consonant": "r",
        "rhyme": "âu"
    },
    {
        "id": 2303,
        "syllable": "ruâu",
        "consonant": "r",
        "rhyme": "uâu"
    },
    {
        "id": 2304,
        "syllable": "ru",
        "consonant": "r",
        "rhyme": "u"
    },
    {
        "id": 2305,
        "syllable": "ruc",
        "consonant": "r",
        "rhyme": "uc"
    },
    {
        "id": 2306,
        "syllable": "rung",
        "consonant": "r",
        "rhyme": "ung"
    },
    {
        "id": 2307,
        "syllable": "rut",
        "consonant": "r",
        "rhyme": "ut"
    },
    {
        "id": 2308,
        "syllable": "run",
        "consonant": "r",
        "rhyme": "un"
    },
    {
        "id": 2309,
        "syllable": "rup",
        "consonant": "r",
        "rhyme": "up"
    },
    {
        "id": 2310,
        "syllable": "rum",
        "consonant": "r",
        "rhyme": "um"
    },
    {
        "id": 2311,
        "syllable": "rui",
        "consonant": "r",
        "rhyme": "ui"
    },
    {
        "id": 2312,
        "syllable": "rô",
        "consonant": "r",
        "rhyme": "ô"
    },
    {
        "id": 2313,
        "syllable": "rôc",
        "consonant": "r",
        "rhyme": "ôc"
    },
    {
        "id": 2314,
        "syllable": "rông",
        "consonant": "r",
        "rhyme": "ông"
    },
    {
        "id": 2315,
        "syllable": "rôt",
        "consonant": "r",
        "rhyme": "ôt"
    },
    {
        "id": 2316,
        "syllable": "rôn",
        "consonant": "r",
        "rhyme": "ôn"
    },
    {
        "id": 2317,
        "syllable": "rôp",
        "consonant": "r",
        "rhyme": "ôp"
    },
    {
        "id": 2318,
        "syllable": "rôm",
        "consonant": "r",
        "rhyme": "ôm"
    },
    {
        "id": 2319,
        "syllable": "rôi",
        "consonant": "r",
        "rhyme": "ôi"
    },
    {
        "id": 2320,
        "syllable": "ro",
        "consonant": "r",
        "rhyme": "o"
    },
    {
        "id": 2321,
        "syllable": "rooc",
        "consonant": "r",
        "rhyme": "ooc"
    },
    {
        "id": 2322,
        "syllable": "roong",
        "consonant": "r",
        "rhyme": "oong"
    },
    {
        "id": 2323,
        "syllable": "rot",
        "consonant": "r",
        "rhyme": "ot"
    },
    {
        "id": 2324,
        "syllable": "ron",
        "consonant": "r",
        "rhyme": "on"
    },
    {
        "id": 2325,
        "syllable": "rop",
        "consonant": "r",
        "rhyme": "op"
    },
    {
        "id": 2326,
        "syllable": "rom",
        "consonant": "r",
        "rhyme": "om"
    },
    {
        "id": 2327,
        "syllable": "roi",
        "consonant": "r",
        "rhyme": "oi"
    },
    {
        "id": 2328,
        "syllable": "roc",
        "consonant": "r",
        "rhyme": "oc"
    },
    {
        "id": 2329,
        "syllable": "rong",
        "consonant": "r",
        "rhyme": "ong"
    },
    {
        "id": 2330,
        "syllable": "rua",
        "consonant": "r",
        "rhyme": "ua"
    },
    {
        "id": 2331,
        "syllable": "ruôc",
        "consonant": "r",
        "rhyme": "uôc"
    },
    {
        "id": 2332,
        "syllable": "ruông",
        "consonant": "r",
        "rhyme": "uông"
    },
    {
        "id": 2333,
        "syllable": "ruôt",
        "consonant": "r",
        "rhyme": "uôt"
    },
    {
        "id": 2334,
        "syllable": "ruôn",
        "consonant": "r",
        "rhyme": "uôn"
    },
    {
        "id": 2335,
        "syllable": "ruôp",
        "consonant": "r",
        "rhyme": "uôp"
    },
    {
        "id": 2336,
        "syllable": "ruôm",
        "consonant": "r",
        "rhyme": "uôm"
    },
    {
        "id": 2337,
        "syllable": "ruôi",
        "consonant": "r",
        "rhyme": "uôi"
    },
    {
        "id": 2338,
        "syllable": "rưa",
        "consonant": "r",
        "rhyme": "ưa"
    },
    {
        "id": 2339,
        "syllable": "rươc",
        "consonant": "r",
        "rhyme": "ươc"
    },
    {
        "id": 2340,
        "syllable": "rương",
        "consonant": "r",
        "rhyme": "ương"
    },
    {
        "id": 2341,
        "syllable": "rươt",
        "consonant": "r",
        "rhyme": "ươt"
    },
    {
        "id": 2342,
        "syllable": "rươn",
        "consonant": "r",
        "rhyme": "ươn"
    },
    {
        "id": 2343,
        "syllable": "rươp",
        "consonant": "r",
        "rhyme": "ươp"
    },
    {
        "id": 2344,
        "syllable": "rươm",
        "consonant": "r",
        "rhyme": "ươm"
    },
    {
        "id": 2345,
        "syllable": "rươi",
        "consonant": "r",
        "rhyme": "ươi"
    },
    {
        "id": 2346,
        "syllable": "ra",
        "consonant": "r",
        "rhyme": "a"
    },
    {
        "id": 2347,
        "syllable": "roa",
        "consonant": "r",
        "rhyme": "oa"
    },
    {
        "id": 2348,
        "syllable": "rac",
        "consonant": "r",
        "rhyme": "ac"
    },
    {
        "id": 2349,
        "syllable": "roac",
        "consonant": "r",
        "rhyme": "oac"
    },
    {
        "id": 2350,
        "syllable": "rang",
        "consonant": "r",
        "rhyme": "ang"
    },
    {
        "id": 2351,
        "syllable": "roang",
        "consonant": "r",
        "rhyme": "oang"
    },
    {
        "id": 2352,
        "syllable": "rat",
        "consonant": "r",
        "rhyme": "at"
    },
    {
        "id": 2353,
        "syllable": "roat",
        "consonant": "r",
        "rhyme": "oat"
    },
    {
        "id": 2354,
        "syllable": "ran",
        "consonant": "r",
        "rhyme": "an"
    },
    {
        "id": 2355,
        "syllable": "roan",
        "consonant": "r",
        "rhyme": "oan"
    },
    {
        "id": 2356,
        "syllable": "rap",
        "consonant": "r",
        "rhyme": "ap"
    },
    {
        "id": 2357,
        "syllable": "roap",
        "consonant": "r",
        "rhyme": "oap"
    },
    {
        "id": 2358,
        "syllable": "ram",
        "consonant": "r",
        "rhyme": "am"
    },
    {
        "id": 2359,
        "syllable": "roam",
        "consonant": "r",
        "rhyme": "oam"
    },
    {
        "id": 2360,
        "syllable": "rai",
        "consonant": "r",
        "rhyme": "ai"
    },
    {
        "id": 2361,
        "syllable": "roai",
        "consonant": "r",
        "rhyme": "oai"
    },
    {
        "id": 2362,
        "syllable": "rao",
        "consonant": "r",
        "rhyme": "ao"
    },
    {
        "id": 2363,
        "syllable": "roao",
        "consonant": "r",
        "rhyme": "oao"
    },
    {
        "id": 2364,
        "syllable": "răc",
        "consonant": "r",
        "rhyme": "ăc"
    },
    {
        "id": 2365,
        "syllable": "roăc",
        "consonant": "r",
        "rhyme": "oăc"
    },
    {
        "id": 2366,
        "syllable": "răng",
        "consonant": "r",
        "rhyme": "ăng"
    },
    {
        "id": 2367,
        "syllable": "roăng",
        "consonant": "r",
        "rhyme": "oăng"
    },
    {
        "id": 2368,
        "syllable": "răt",
        "consonant": "r",
        "rhyme": "ăt"
    },
    {
        "id": 2369,
        "syllable": "roăt",
        "consonant": "r",
        "rhyme": "oăt"
    },
    {
        "id": 2370,
        "syllable": "răn",
        "consonant": "r",
        "rhyme": "ăn"
    },
    {
        "id": 2371,
        "syllable": "roăn",
        "consonant": "r",
        "rhyme": "oăn"
    },
    {
        "id": 2372,
        "syllable": "răp",
        "consonant": "r",
        "rhyme": "ăp"
    },
    {
        "id": 2373,
        "syllable": "roăp",
        "consonant": "r",
        "rhyme": "oăp"
    },
    {
        "id": 2374,
        "syllable": "răm",
        "consonant": "r",
        "rhyme": "ăm"
    },
    {
        "id": 2375,
        "syllable": "roăm",
        "consonant": "r",
        "rhyme": "oăm"
    },
    {
        "id": 2376,
        "syllable": "ray",
        "consonant": "r",
        "rhyme": "ay"
    },
    {
        "id": 2377,
        "syllable": "roay",
        "consonant": "r",
        "rhyme": "oay"
    },
    {
        "id": 2378,
        "syllable": "rau",
        "consonant": "r",
        "rhyme": "au"
    },
    {
        "id": 2379,
        "syllable": "roau",
        "consonant": "r",
        "rhyme": "oau"
    },
    {
        "id": 2380,
        "syllable": "phi",
        "consonant": "ph",
        "rhyme": "i"
    },
    {
        "id": 2381,
        "syllable": "phuy",
        "consonant": "ph",
        "rhyme": "uy"
    },
    {
        "id": 2382,
        "syllable": "phich",
        "consonant": "ph",
        "rhyme": "ich"
    },
    {
        "id": 2383,
        "syllable": "phuych",
        "consonant": "ph",
        "rhyme": "uych"
    },
    {
        "id": 2384,
        "syllable": "phinh",
        "consonant": "ph",
        "rhyme": "inh"
    },
    {
        "id": 2385,
        "syllable": "phuynh",
        "consonant": "ph",
        "rhyme": "uynh"
    },
    {
        "id": 2386,
        "syllable": "phit",
        "consonant": "ph",
        "rhyme": "it"
    },
    {
        "id": 2387,
        "syllable": "phuyt",
        "consonant": "ph",
        "rhyme": "uyt"
    },
    {
        "id": 2388,
        "syllable": "phin",
        "consonant": "ph",
        "rhyme": "in"
    },
    {
        "id": 2389,
        "syllable": "phuyn",
        "consonant": "ph",
        "rhyme": "uyn"
    },
    {
        "id": 2390,
        "syllable": "phip",
        "consonant": "ph",
        "rhyme": "ip"
    },
    {
        "id": 2391,
        "syllable": "phuyp",
        "consonant": "ph",
        "rhyme": "uyp"
    },
    {
        "id": 2392,
        "syllable": "phim",
        "consonant": "ph",
        "rhyme": "im"
    },
    {
        "id": 2393,
        "syllable": "phuym",
        "consonant": "ph",
        "rhyme": "uym"
    },
    {
        "id": 2394,
        "syllable": "phiu",
        "consonant": "ph",
        "rhyme": "iu"
    },
    {
        "id": 2395,
        "syllable": "phuyu",
        "consonant": "ph",
        "rhyme": "uyu"
    },
    {
        "id": 2396,
        "syllable": "phê",
        "consonant": "ph",
        "rhyme": "ê"
    },
    {
        "id": 2397,
        "syllable": "phuê",
        "consonant": "ph",
        "rhyme": "uê"
    },
    {
        "id": 2398,
        "syllable": "phêch",
        "consonant": "ph",
        "rhyme": "êch"
    },
    {
        "id": 2399,
        "syllable": "phuêch",
        "consonant": "ph",
        "rhyme": "uêch"
    },
    {
        "id": 2400,
        "syllable": "phênh",
        "consonant": "ph",
        "rhyme": "ênh"
    },
    {
        "id": 2401,
        "syllable": "phuênh",
        "consonant": "ph",
        "rhyme": "uênh"
    },
    {
        "id": 2402,
        "syllable": "phêt",
        "consonant": "ph",
        "rhyme": "êt"
    },
    {
        "id": 2403,
        "syllable": "phuêt",
        "consonant": "ph",
        "rhyme": "uêt"
    },
    {
        "id": 2404,
        "syllable": "phên",
        "consonant": "ph",
        "rhyme": "ên"
    },
    {
        "id": 2405,
        "syllable": "phuên",
        "consonant": "ph",
        "rhyme": "uên"
    },
    {
        "id": 2406,
        "syllable": "phêp",
        "consonant": "ph",
        "rhyme": "êp"
    },
    {
        "id": 2407,
        "syllable": "phuêp",
        "consonant": "ph",
        "rhyme": "uêp"
    },
    {
        "id": 2408,
        "syllable": "phêm",
        "consonant": "ph",
        "rhyme": "êm"
    },
    {
        "id": 2409,
        "syllable": "phuêm",
        "consonant": "ph",
        "rhyme": "uêm"
    },
    {
        "id": 2410,
        "syllable": "phêu",
        "consonant": "ph",
        "rhyme": "êu"
    },
    {
        "id": 2411,
        "syllable": "phe",
        "consonant": "ph",
        "rhyme": "e"
    },
    {
        "id": 2412,
        "syllable": "phoe",
        "consonant": "ph",
        "rhyme": "oe"
    },
    {
        "id": 2413,
        "syllable": "phec",
        "consonant": "ph",
        "rhyme": "ec"
    },
    {
        "id": 2414,
        "syllable": "phoec",
        "consonant": "ph",
        "rhyme": "oec"
    },
    {
        "id": 2415,
        "syllable": "pheng",
        "consonant": "ph",
        "rhyme": "eng"
    },
    {
        "id": 2416,
        "syllable": "phoeng",
        "consonant": "ph",
        "rhyme": "oeng"
    },
    {
        "id": 2417,
        "syllable": "phet",
        "consonant": "ph",
        "rhyme": "et"
    },
    {
        "id": 2418,
        "syllable": "phoet",
        "consonant": "ph",
        "rhyme": "oet"
    },
    {
        "id": 2419,
        "syllable": "phen",
        "consonant": "ph",
        "rhyme": "en"
    },
    {
        "id": 2420,
        "syllable": "phoen",
        "consonant": "ph",
        "rhyme": "oen"
    },
    {
        "id": 2421,
        "syllable": "phep",
        "consonant": "ph",
        "rhyme": "ep"
    },
    {
        "id": 2422,
        "syllable": "phoep",
        "consonant": "ph",
        "rhyme": "oep"
    },
    {
        "id": 2423,
        "syllable": "phem",
        "consonant": "ph",
        "rhyme": "em"
    },
    {
        "id": 2424,
        "syllable": "phoem",
        "consonant": "ph",
        "rhyme": "oem"
    },
    {
        "id": 2425,
        "syllable": "pheo",
        "consonant": "ph",
        "rhyme": "eo"
    },
    {
        "id": 2426,
        "syllable": "phoeo",
        "consonant": "ph",
        "rhyme": "oeo"
    },
    {
        "id": 2427,
        "syllable": "phach",
        "consonant": "ph",
        "rhyme": "ach"
    },
    {
        "id": 2428,
        "syllable": "phoach",
        "consonant": "ph",
        "rhyme": "oach"
    },
    {
        "id": 2429,
        "syllable": "phanh",
        "consonant": "ph",
        "rhyme": "anh"
    },
    {
        "id": 2430,
        "syllable": "phoanh",
        "consonant": "ph",
        "rhyme": "oanh"
    },
    {
        "id": 2431,
        "syllable": "phia",
        "consonant": "ph",
        "rhyme": "ia"
    },
    {
        "id": 2432,
        "syllable": "phuya",
        "consonant": "ph",
        "rhyme": "uya"
    },
    {
        "id": 2433,
        "syllable": "phiêc",
        "consonant": "ph",
        "rhyme": "iêc"
    },
    {
        "id": 2434,
        "syllable": "phiêng",
        "consonant": "ph",
        "rhyme": "iêng"
    },
    {
        "id": 2435,
        "syllable": "phiêt",
        "consonant": "ph",
        "rhyme": "iêt"
    },
    {
        "id": 2436,
        "syllable": "phiên",
        "consonant": "ph",
        "rhyme": "iên"
    },
    {
        "id": 2437,
        "syllable": "phuyêt",
        "consonant": "ph",
        "rhyme": "uyêt"
    },
    {
        "id": 2438,
        "syllable": "phuyên",
        "consonant": "ph",
        "rhyme": "uyên"
    },
    {
        "id": 2439,
        "syllable": "phiêp",
        "consonant": "ph",
        "rhyme": "iêp"
    },
    {
        "id": 2440,
        "syllable": "phuyêp",
        "consonant": "ph",
        "rhyme": "uyêp"
    },
    {
        "id": 2441,
        "syllable": "phiêm",
        "consonant": "ph",
        "rhyme": "iêm"
    },
    {
        "id": 2442,
        "syllable": "phuyêm",
        "consonant": "ph",
        "rhyme": "uyêm"
    },
    {
        "id": 2443,
        "syllable": "phiêu",
        "consonant": "ph",
        "rhyme": "iêu"
    },
    {
        "id": 2444,
        "syllable": "phư",
        "consonant": "ph",
        "rhyme": "ư"
    },
    {
        "id": 2445,
        "syllable": "phưc",
        "consonant": "ph",
        "rhyme": "ưc"
    },
    {
        "id": 2446,
        "syllable": "phưng",
        "consonant": "ph",
        "rhyme": "ưng"
    },
    {
        "id": 2447,
        "syllable": "phưt",
        "consonant": "ph",
        "rhyme": "ưt"
    },
    {
        "id": 2448,
        "syllable": "phưn",
        "consonant": "ph",
        "rhyme": "ưn"
    },
    {
        "id": 2449,
        "syllable": "phưp",
        "consonant": "ph",
        "rhyme": "ưp"
    },
    {
        "id": 2450,
        "syllable": "phưm",
        "consonant": "ph",
        "rhyme": "ưm"
    },
    {
        "id": 2451,
        "syllable": "phưi",
        "consonant": "ph",
        "rhyme": "ưi"
    },
    {
        "id": 2452,
        "syllable": "phưu",
        "consonant": "ph",
        "rhyme": "ưu"
    },
    {
        "id": 2453,
        "syllable": "phơ",
        "consonant": "ph",
        "rhyme": "ơ"
    },
    {
        "id": 2454,
        "syllable": "phuơ",
        "consonant": "ph",
        "rhyme": "uơ"
    },
    {
        "id": 2455,
        "syllable": "phơng",
        "consonant": "ph",
        "rhyme": "ơng"
    },
    {
        "id": 2456,
        "syllable": "phơt",
        "consonant": "ph",
        "rhyme": "ơt"
    },
    {
        "id": 2457,
        "syllable": "phơn",
        "consonant": "ph",
        "rhyme": "ơn"
    },
    {
        "id": 2458,
        "syllable": "phơp",
        "consonant": "ph",
        "rhyme": "ơp"
    },
    {
        "id": 2459,
        "syllable": "phơm",
        "consonant": "ph",
        "rhyme": "ơm"
    },
    {
        "id": 2460,
        "syllable": "phơi",
        "consonant": "ph",
        "rhyme": "ơi"
    },
    {
        "id": 2461,
        "syllable": "phâc",
        "consonant": "ph",
        "rhyme": "âc"
    },
    {
        "id": 2462,
        "syllable": "phâng",
        "consonant": "ph",
        "rhyme": "âng"
    },
    {
        "id": 2463,
        "syllable": "phuâng",
        "consonant": "ph",
        "rhyme": "uâng"
    },
    {
        "id": 2464,
        "syllable": "phât",
        "consonant": "ph",
        "rhyme": "ât"
    },
    {
        "id": 2465,
        "syllable": "phuât",
        "consonant": "ph",
        "rhyme": "uât"
    },
    {
        "id": 2466,
        "syllable": "phân",
        "consonant": "ph",
        "rhyme": "ân"
    },
    {
        "id": 2467,
        "syllable": "phuân",
        "consonant": "ph",
        "rhyme": "uân"
    },
    {
        "id": 2468,
        "syllable": "phâp",
        "consonant": "ph",
        "rhyme": "âp"
    },
    {
        "id": 2469,
        "syllable": "phuâp",
        "consonant": "ph",
        "rhyme": "uâp"
    },
    {
        "id": 2470,
        "syllable": "phâm",
        "consonant": "ph",
        "rhyme": "âm"
    },
    {
        "id": 2471,
        "syllable": "phuâm",
        "consonant": "ph",
        "rhyme": "uâm"
    },
    {
        "id": 2472,
        "syllable": "phây",
        "consonant": "ph",
        "rhyme": "ây"
    },
    {
        "id": 2473,
        "syllable": "phuây",
        "consonant": "ph",
        "rhyme": "uây"
    },
    {
        "id": 2474,
        "syllable": "phâu",
        "consonant": "ph",
        "rhyme": "âu"
    },
    {
        "id": 2475,
        "syllable": "phuâu",
        "consonant": "ph",
        "rhyme": "uâu"
    },
    {
        "id": 2476,
        "syllable": "phu",
        "consonant": "ph",
        "rhyme": "u"
    },
    {
        "id": 2477,
        "syllable": "phuc",
        "consonant": "ph",
        "rhyme": "uc"
    },
    {
        "id": 2478,
        "syllable": "phung",
        "consonant": "ph",
        "rhyme": "ung"
    },
    {
        "id": 2479,
        "syllable": "phut",
        "consonant": "ph",
        "rhyme": "ut"
    },
    {
        "id": 2480,
        "syllable": "phun",
        "consonant": "ph",
        "rhyme": "un"
    },
    {
        "id": 2481,
        "syllable": "phup",
        "consonant": "ph",
        "rhyme": "up"
    },
    {
        "id": 2482,
        "syllable": "phum",
        "consonant": "ph",
        "rhyme": "um"
    },
    {
        "id": 2483,
        "syllable": "phui",
        "consonant": "ph",
        "rhyme": "ui"
    },
    {
        "id": 2484,
        "syllable": "phô",
        "consonant": "ph",
        "rhyme": "ô"
    },
    {
        "id": 2485,
        "syllable": "phôc",
        "consonant": "ph",
        "rhyme": "ôc"
    },
    {
        "id": 2486,
        "syllable": "phông",
        "consonant": "ph",
        "rhyme": "ông"
    },
    {
        "id": 2487,
        "syllable": "phôt",
        "consonant": "ph",
        "rhyme": "ôt"
    },
    {
        "id": 2488,
        "syllable": "phôn",
        "consonant": "ph",
        "rhyme": "ôn"
    },
    {
        "id": 2489,
        "syllable": "phôp",
        "consonant": "ph",
        "rhyme": "ôp"
    },
    {
        "id": 2490,
        "syllable": "phôm",
        "consonant": "ph",
        "rhyme": "ôm"
    },
    {
        "id": 2491,
        "syllable": "phôi",
        "consonant": "ph",
        "rhyme": "ôi"
    },
    {
        "id": 2492,
        "syllable": "pho",
        "consonant": "ph",
        "rhyme": "o"
    },
    {
        "id": 2493,
        "syllable": "phooc",
        "consonant": "ph",
        "rhyme": "ooc"
    },
    {
        "id": 2494,
        "syllable": "phoong",
        "consonant": "ph",
        "rhyme": "oong"
    },
    {
        "id": 2495,
        "syllable": "phot",
        "consonant": "ph",
        "rhyme": "ot"
    },
    {
        "id": 2496,
        "syllable": "phon",
        "consonant": "ph",
        "rhyme": "on"
    },
    {
        "id": 2497,
        "syllable": "phop",
        "consonant": "ph",
        "rhyme": "op"
    },
    {
        "id": 2498,
        "syllable": "phom",
        "consonant": "ph",
        "rhyme": "om"
    },
    {
        "id": 2499,
        "syllable": "phoi",
        "consonant": "ph",
        "rhyme": "oi"
    },
    {
        "id": 2500,
        "syllable": "phoc",
        "consonant": "ph",
        "rhyme": "oc"
    },
    {
        "id": 2501,
        "syllable": "phong",
        "consonant": "ph",
        "rhyme": "ong"
    },
    {
        "id": 2502,
        "syllable": "phua",
        "consonant": "ph",
        "rhyme": "ua"
    },
    {
        "id": 2503,
        "syllable": "phuôc",
        "consonant": "ph",
        "rhyme": "uôc"
    },
    {
        "id": 2504,
        "syllable": "phuông",
        "consonant": "ph",
        "rhyme": "uông"
    },
    {
        "id": 2505,
        "syllable": "phuôt",
        "consonant": "ph",
        "rhyme": "uôt"
    },
    {
        "id": 2506,
        "syllable": "phuôn",
        "consonant": "ph",
        "rhyme": "uôn"
    },
    {
        "id": 2507,
        "syllable": "phuôp",
        "consonant": "ph",
        "rhyme": "uôp"
    },
    {
        "id": 2508,
        "syllable": "phuôm",
        "consonant": "ph",
        "rhyme": "uôm"
    },
    {
        "id": 2509,
        "syllable": "phuôi",
        "consonant": "ph",
        "rhyme": "uôi"
    },
    {
        "id": 2510,
        "syllable": "phưa",
        "consonant": "ph",
        "rhyme": "ưa"
    },
    {
        "id": 2511,
        "syllable": "phươc",
        "consonant": "ph",
        "rhyme": "ươc"
    },
    {
        "id": 2512,
        "syllable": "phương",
        "consonant": "ph",
        "rhyme": "ương"
    },
    {
        "id": 2513,
        "syllable": "phươt",
        "consonant": "ph",
        "rhyme": "ươt"
    },
    {
        "id": 2514,
        "syllable": "phươn",
        "consonant": "ph",
        "rhyme": "ươn"
    },
    {
        "id": 2515,
        "syllable": "phươp",
        "consonant": "ph",
        "rhyme": "ươp"
    },
    {
        "id": 2516,
        "syllable": "phươm",
        "consonant": "ph",
        "rhyme": "ươm"
    },
    {
        "id": 2517,
        "syllable": "phươi",
        "consonant": "ph",
        "rhyme": "ươi"
    },
    {
        "id": 2518,
        "syllable": "pha",
        "consonant": "ph",
        "rhyme": "a"
    },
    {
        "id": 2519,
        "syllable": "phoa",
        "consonant": "ph",
        "rhyme": "oa"
    },
    {
        "id": 2520,
        "syllable": "phac",
        "consonant": "ph",
        "rhyme": "ac"
    },
    {
        "id": 2521,
        "syllable": "phoac",
        "consonant": "ph",
        "rhyme": "oac"
    },
    {
        "id": 2522,
        "syllable": "phang",
        "consonant": "ph",
        "rhyme": "ang"
    },
    {
        "id": 2523,
        "syllable": "phoang",
        "consonant": "ph",
        "rhyme": "oang"
    },
    {
        "id": 2524,
        "syllable": "phat",
        "consonant": "ph",
        "rhyme": "at"
    },
    {
        "id": 2525,
        "syllable": "phoat",
        "consonant": "ph",
        "rhyme": "oat"
    },
    {
        "id": 2526,
        "syllable": "phan",
        "consonant": "ph",
        "rhyme": "an"
    },
    {
        "id": 2527,
        "syllable": "phoan",
        "consonant": "ph",
        "rhyme": "oan"
    },
    {
        "id": 2528,
        "syllable": "phap",
        "consonant": "ph",
        "rhyme": "ap"
    },
    {
        "id": 2529,
        "syllable": "phoap",
        "consonant": "ph",
        "rhyme": "oap"
    },
    {
        "id": 2530,
        "syllable": "pham",
        "consonant": "ph",
        "rhyme": "am"
    },
    {
        "id": 2531,
        "syllable": "phoam",
        "consonant": "ph",
        "rhyme": "oam"
    },
    {
        "id": 2532,
        "syllable": "phai",
        "consonant": "ph",
        "rhyme": "ai"
    },
    {
        "id": 2533,
        "syllable": "phoai",
        "consonant": "ph",
        "rhyme": "oai"
    },
    {
        "id": 2534,
        "syllable": "phao",
        "consonant": "ph",
        "rhyme": "ao"
    },
    {
        "id": 2535,
        "syllable": "phoao",
        "consonant": "ph",
        "rhyme": "oao"
    },
    {
        "id": 2536,
        "syllable": "phăc",
        "consonant": "ph",
        "rhyme": "ăc"
    },
    {
        "id": 2537,
        "syllable": "phoăc",
        "consonant": "ph",
        "rhyme": "oăc"
    },
    {
        "id": 2538,
        "syllable": "phăng",
        "consonant": "ph",
        "rhyme": "ăng"
    },
    {
        "id": 2539,
        "syllable": "phoăng",
        "consonant": "ph",
        "rhyme": "oăng"
    },
    {
        "id": 2540,
        "syllable": "phăt",
        "consonant": "ph",
        "rhyme": "ăt"
    },
    {
        "id": 2541,
        "syllable": "phoăt",
        "consonant": "ph",
        "rhyme": "oăt"
    },
    {
        "id": 2542,
        "syllable": "phăn",
        "consonant": "ph",
        "rhyme": "ăn"
    },
    {
        "id": 2543,
        "syllable": "phoăn",
        "consonant": "ph",
        "rhyme": "oăn"
    },
    {
        "id": 2544,
        "syllable": "phăp",
        "consonant": "ph",
        "rhyme": "ăp"
    },
    {
        "id": 2545,
        "syllable": "phoăp",
        "consonant": "ph",
        "rhyme": "oăp"
    },
    {
        "id": 2546,
        "syllable": "phăm",
        "consonant": "ph",
        "rhyme": "ăm"
    },
    {
        "id": 2547,
        "syllable": "phoăm",
        "consonant": "ph",
        "rhyme": "oăm"
    },
    {
        "id": 2548,
        "syllable": "phay",
        "consonant": "ph",
        "rhyme": "ay"
    },
    {
        "id": 2549,
        "syllable": "phoay",
        "consonant": "ph",
        "rhyme": "oay"
    },
    {
        "id": 2550,
        "syllable": "phau",
        "consonant": "ph",
        "rhyme": "au"
    },
    {
        "id": 2551,
        "syllable": "phoau",
        "consonant": "ph",
        "rhyme": "oau"
    },
    {
        "id": 2552,
        "syllable": "tri",
        "consonant": "tr",
        "rhyme": "i"
    },
    {
        "id": 2553,
        "syllable": "truy",
        "consonant": "tr",
        "rhyme": "uy"
    },
    {
        "id": 2554,
        "syllable": "trich",
        "consonant": "tr",
        "rhyme": "ich"
    },
    {
        "id": 2555,
        "syllable": "truych",
        "consonant": "tr",
        "rhyme": "uych"
    },
    {
        "id": 2556,
        "syllable": "trinh",
        "consonant": "tr",
        "rhyme": "inh"
    },
    {
        "id": 2557,
        "syllable": "truynh",
        "consonant": "tr",
        "rhyme": "uynh"
    },
    {
        "id": 2558,
        "syllable": "trit",
        "consonant": "tr",
        "rhyme": "it"
    },
    {
        "id": 2559,
        "syllable": "truyt",
        "consonant": "tr",
        "rhyme": "uyt"
    },
    {
        "id": 2560,
        "syllable": "trin",
        "consonant": "tr",
        "rhyme": "in"
    },
    {
        "id": 2561,
        "syllable": "truyn",
        "consonant": "tr",
        "rhyme": "uyn"
    },
    {
        "id": 2562,
        "syllable": "trip",
        "consonant": "tr",
        "rhyme": "ip"
    },
    {
        "id": 2563,
        "syllable": "truyp",
        "consonant": "tr",
        "rhyme": "uyp"
    },
    {
        "id": 2564,
        "syllable": "trim",
        "consonant": "tr",
        "rhyme": "im"
    },
    {
        "id": 2565,
        "syllable": "truym",
        "consonant": "tr",
        "rhyme": "uym"
    },
    {
        "id": 2566,
        "syllable": "triu",
        "consonant": "tr",
        "rhyme": "iu"
    },
    {
        "id": 2567,
        "syllable": "truyu",
        "consonant": "tr",
        "rhyme": "uyu"
    },
    {
        "id": 2568,
        "syllable": "trê",
        "consonant": "tr",
        "rhyme": "ê"
    },
    {
        "id": 2569,
        "syllable": "truê",
        "consonant": "tr",
        "rhyme": "uê"
    },
    {
        "id": 2570,
        "syllable": "trêch",
        "consonant": "tr",
        "rhyme": "êch"
    },
    {
        "id": 2571,
        "syllable": "truêch",
        "consonant": "tr",
        "rhyme": "uêch"
    },
    {
        "id": 2572,
        "syllable": "trênh",
        "consonant": "tr",
        "rhyme": "ênh"
    },
    {
        "id": 2573,
        "syllable": "truênh",
        "consonant": "tr",
        "rhyme": "uênh"
    },
    {
        "id": 2574,
        "syllable": "trêt",
        "consonant": "tr",
        "rhyme": "êt"
    },
    {
        "id": 2575,
        "syllable": "truêt",
        "consonant": "tr",
        "rhyme": "uêt"
    },
    {
        "id": 2576,
        "syllable": "trên",
        "consonant": "tr",
        "rhyme": "ên"
    },
    {
        "id": 2577,
        "syllable": "truên",
        "consonant": "tr",
        "rhyme": "uên"
    },
    {
        "id": 2578,
        "syllable": "trêp",
        "consonant": "tr",
        "rhyme": "êp"
    },
    {
        "id": 2579,
        "syllable": "truêp",
        "consonant": "tr",
        "rhyme": "uêp"
    },
    {
        "id": 2580,
        "syllable": "trêm",
        "consonant": "tr",
        "rhyme": "êm"
    },
    {
        "id": 2581,
        "syllable": "truêm",
        "consonant": "tr",
        "rhyme": "uêm"
    },
    {
        "id": 2582,
        "syllable": "trêu",
        "consonant": "tr",
        "rhyme": "êu"
    },
    {
        "id": 2583,
        "syllable": "tre",
        "consonant": "tr",
        "rhyme": "e"
    },
    {
        "id": 2584,
        "syllable": "troe",
        "consonant": "tr",
        "rhyme": "oe"
    },
    {
        "id": 2585,
        "syllable": "trec",
        "consonant": "tr",
        "rhyme": "ec"
    },
    {
        "id": 2586,
        "syllable": "troec",
        "consonant": "tr",
        "rhyme": "oec"
    },
    {
        "id": 2587,
        "syllable": "treng",
        "consonant": "tr",
        "rhyme": "eng"
    },
    {
        "id": 2588,
        "syllable": "troeng",
        "consonant": "tr",
        "rhyme": "oeng"
    },
    {
        "id": 2589,
        "syllable": "tret",
        "consonant": "tr",
        "rhyme": "et"
    },
    {
        "id": 2590,
        "syllable": "troet",
        "consonant": "tr",
        "rhyme": "oet"
    },
    {
        "id": 2591,
        "syllable": "tren",
        "consonant": "tr",
        "rhyme": "en"
    },
    {
        "id": 2592,
        "syllable": "troen",
        "consonant": "tr",
        "rhyme": "oen"
    },
    {
        "id": 2593,
        "syllable": "trep",
        "consonant": "tr",
        "rhyme": "ep"
    },
    {
        "id": 2594,
        "syllable": "troep",
        "consonant": "tr",
        "rhyme": "oep"
    },
    {
        "id": 2595,
        "syllable": "trem",
        "consonant": "tr",
        "rhyme": "em"
    },
    {
        "id": 2596,
        "syllable": "troem",
        "consonant": "tr",
        "rhyme": "oem"
    },
    {
        "id": 2597,
        "syllable": "treo",
        "consonant": "tr",
        "rhyme": "eo"
    },
    {
        "id": 2598,
        "syllable": "troeo",
        "consonant": "tr",
        "rhyme": "oeo"
    },
    {
        "id": 2599,
        "syllable": "trach",
        "consonant": "tr",
        "rhyme": "ach"
    },
    {
        "id": 2600,
        "syllable": "troach",
        "consonant": "tr",
        "rhyme": "oach"
    },
    {
        "id": 2601,
        "syllable": "tranh",
        "consonant": "tr",
        "rhyme": "anh"
    },
    {
        "id": 2602,
        "syllable": "troanh",
        "consonant": "tr",
        "rhyme": "oanh"
    },
    {
        "id": 2603,
        "syllable": "tria",
        "consonant": "tr",
        "rhyme": "ia"
    },
    {
        "id": 2604,
        "syllable": "truya",
        "consonant": "tr",
        "rhyme": "uya"
    },
    {
        "id": 2605,
        "syllable": "triêc",
        "consonant": "tr",
        "rhyme": "iêc"
    },
    {
        "id": 2606,
        "syllable": "triêng",
        "consonant": "tr",
        "rhyme": "iêng"
    },
    {
        "id": 2607,
        "syllable": "triêt",
        "consonant": "tr",
        "rhyme": "iêt"
    },
    {
        "id": 2608,
        "syllable": "triên",
        "consonant": "tr",
        "rhyme": "iên"
    },
    {
        "id": 2609,
        "syllable": "truyêt",
        "consonant": "tr",
        "rhyme": "uyêt"
    },
    {
        "id": 2610,
        "syllable": "truyên",
        "consonant": "tr",
        "rhyme": "uyên"
    },
    {
        "id": 2611,
        "syllable": "triêp",
        "consonant": "tr",
        "rhyme": "iêp"
    },
    {
        "id": 2612,
        "syllable": "truyêp",
        "consonant": "tr",
        "rhyme": "uyêp"
    },
    {
        "id": 2613,
        "syllable": "triêm",
        "consonant": "tr",
        "rhyme": "iêm"
    },
    {
        "id": 2614,
        "syllable": "truyêm",
        "consonant": "tr",
        "rhyme": "uyêm"
    },
    {
        "id": 2615,
        "syllable": "triêu",
        "consonant": "tr",
        "rhyme": "iêu"
    },
    {
        "id": 2616,
        "syllable": "trư",
        "consonant": "tr",
        "rhyme": "ư"
    },
    {
        "id": 2617,
        "syllable": "trưc",
        "consonant": "tr",
        "rhyme": "ưc"
    },
    {
        "id": 2618,
        "syllable": "trưng",
        "consonant": "tr",
        "rhyme": "ưng"
    },
    {
        "id": 2619,
        "syllable": "trưt",
        "consonant": "tr",
        "rhyme": "ưt"
    },
    {
        "id": 2620,
        "syllable": "trưn",
        "consonant": "tr",
        "rhyme": "ưn"
    },
    {
        "id": 2621,
        "syllable": "trưp",
        "consonant": "tr",
        "rhyme": "ưp"
    },
    {
        "id": 2622,
        "syllable": "trưm",
        "consonant": "tr",
        "rhyme": "ưm"
    },
    {
        "id": 2623,
        "syllable": "trưi",
        "consonant": "tr",
        "rhyme": "ưi"
    },
    {
        "id": 2624,
        "syllable": "trưu",
        "consonant": "tr",
        "rhyme": "ưu"
    },
    {
        "id": 2625,
        "syllable": "trơ",
        "consonant": "tr",
        "rhyme": "ơ"
    },
    {
        "id": 2626,
        "syllable": "truơ",
        "consonant": "tr",
        "rhyme": "uơ"
    },
    {
        "id": 2627,
        "syllable": "trơng",
        "consonant": "tr",
        "rhyme": "ơng"
    },
    {
        "id": 2628,
        "syllable": "trơt",
        "consonant": "tr",
        "rhyme": "ơt"
    },
    {
        "id": 2629,
        "syllable": "trơn",
        "consonant": "tr",
        "rhyme": "ơn"
    },
    {
        "id": 2630,
        "syllable": "trơp",
        "consonant": "tr",
        "rhyme": "ơp"
    },
    {
        "id": 2631,
        "syllable": "trơm",
        "consonant": "tr",
        "rhyme": "ơm"
    },
    {
        "id": 2632,
        "syllable": "trơi",
        "consonant": "tr",
        "rhyme": "ơi"
    },
    {
        "id": 2633,
        "syllable": "trâc",
        "consonant": "tr",
        "rhyme": "âc"
    },
    {
        "id": 2634,
        "syllable": "trâng",
        "consonant": "tr",
        "rhyme": "âng"
    },
    {
        "id": 2635,
        "syllable": "truâng",
        "consonant": "tr",
        "rhyme": "uâng"
    },
    {
        "id": 2636,
        "syllable": "trât",
        "consonant": "tr",
        "rhyme": "ât"
    },
    {
        "id": 2637,
        "syllable": "truât",
        "consonant": "tr",
        "rhyme": "uât"
    },
    {
        "id": 2638,
        "syllable": "trân",
        "consonant": "tr",
        "rhyme": "ân"
    },
    {
        "id": 2639,
        "syllable": "truân",
        "consonant": "tr",
        "rhyme": "uân"
    },
    {
        "id": 2640,
        "syllable": "trâp",
        "consonant": "tr",
        "rhyme": "âp"
    },
    {
        "id": 2641,
        "syllable": "truâp",
        "consonant": "tr",
        "rhyme": "uâp"
    },
    {
        "id": 2642,
        "syllable": "trâm",
        "consonant": "tr",
        "rhyme": "âm"
    },
    {
        "id": 2643,
        "syllable": "truâm",
        "consonant": "tr",
        "rhyme": "uâm"
    },
    {
        "id": 2644,
        "syllable": "trây",
        "consonant": "tr",
        "rhyme": "ây"
    },
    {
        "id": 2645,
        "syllable": "truây",
        "consonant": "tr",
        "rhyme": "uây"
    },
    {
        "id": 2646,
        "syllable": "trâu",
        "consonant": "tr",
        "rhyme": "âu"
    },
    {
        "id": 2647,
        "syllable": "truâu",
        "consonant": "tr",
        "rhyme": "uâu"
    },
    {
        "id": 2648,
        "syllable": "tru",
        "consonant": "tr",
        "rhyme": "u"
    },
    {
        "id": 2649,
        "syllable": "truc",
        "consonant": "tr",
        "rhyme": "uc"
    },
    {
        "id": 2650,
        "syllable": "trung",
        "consonant": "tr",
        "rhyme": "ung"
    },
    {
        "id": 2651,
        "syllable": "trut",
        "consonant": "tr",
        "rhyme": "ut"
    },
    {
        "id": 2652,
        "syllable": "trun",
        "consonant": "tr",
        "rhyme": "un"
    },
    {
        "id": 2653,
        "syllable": "trup",
        "consonant": "tr",
        "rhyme": "up"
    },
    {
        "id": 2654,
        "syllable": "trum",
        "consonant": "tr",
        "rhyme": "um"
    },
    {
        "id": 2655,
        "syllable": "trui",
        "consonant": "tr",
        "rhyme": "ui"
    },
    {
        "id": 2656,
        "syllable": "trô",
        "consonant": "tr",
        "rhyme": "ô"
    },
    {
        "id": 2657,
        "syllable": "trôc",
        "consonant": "tr",
        "rhyme": "ôc"
    },
    {
        "id": 2658,
        "syllable": "trông",
        "consonant": "tr",
        "rhyme": "ông"
    },
    {
        "id": 2659,
        "syllable": "trôt",
        "consonant": "tr",
        "rhyme": "ôt"
    },
    {
        "id": 2660,
        "syllable": "trôn",
        "consonant": "tr",
        "rhyme": "ôn"
    },
    {
        "id": 2661,
        "syllable": "trôp",
        "consonant": "tr",
        "rhyme": "ôp"
    },
    {
        "id": 2662,
        "syllable": "trôm",
        "consonant": "tr",
        "rhyme": "ôm"
    },
    {
        "id": 2663,
        "syllable": "trôi",
        "consonant": "tr",
        "rhyme": "ôi"
    },
    {
        "id": 2664,
        "syllable": "tro",
        "consonant": "tr",
        "rhyme": "o"
    },
    {
        "id": 2665,
        "syllable": "trooc",
        "consonant": "tr",
        "rhyme": "ooc"
    },
    {
        "id": 2666,
        "syllable": "troong",
        "consonant": "tr",
        "rhyme": "oong"
    },
    {
        "id": 2667,
        "syllable": "trot",
        "consonant": "tr",
        "rhyme": "ot"
    },
    {
        "id": 2668,
        "syllable": "tron",
        "consonant": "tr",
        "rhyme": "on"
    },
    {
        "id": 2669,
        "syllable": "trop",
        "consonant": "tr",
        "rhyme": "op"
    },
    {
        "id": 2670,
        "syllable": "trom",
        "consonant": "tr",
        "rhyme": "om"
    },
    {
        "id": 2671,
        "syllable": "troi",
        "consonant": "tr",
        "rhyme": "oi"
    },
    {
        "id": 2672,
        "syllable": "troc",
        "consonant": "tr",
        "rhyme": "oc"
    },
    {
        "id": 2673,
        "syllable": "trong",
        "consonant": "tr",
        "rhyme": "ong"
    },
    {
        "id": 2674,
        "syllable": "trua",
        "consonant": "tr",
        "rhyme": "ua"
    },
    {
        "id": 2675,
        "syllable": "truôc",
        "consonant": "tr",
        "rhyme": "uôc"
    },
    {
        "id": 2676,
        "syllable": "truông",
        "consonant": "tr",
        "rhyme": "uông"
    },
    {
        "id": 2677,
        "syllable": "truôt",
        "consonant": "tr",
        "rhyme": "uôt"
    },
    {
        "id": 2678,
        "syllable": "truôn",
        "consonant": "tr",
        "rhyme": "uôn"
    },
    {
        "id": 2679,
        "syllable": "truôp",
        "consonant": "tr",
        "rhyme": "uôp"
    },
    {
        "id": 2680,
        "syllable": "truôm",
        "consonant": "tr",
        "rhyme": "uôm"
    },
    {
        "id": 2681,
        "syllable": "truôi",
        "consonant": "tr",
        "rhyme": "uôi"
    },
    {
        "id": 2682,
        "syllable": "trưa",
        "consonant": "tr",
        "rhyme": "ưa"
    },
    {
        "id": 2683,
        "syllable": "trươc",
        "consonant": "tr",
        "rhyme": "ươc"
    },
    {
        "id": 2684,
        "syllable": "trương",
        "consonant": "tr",
        "rhyme": "ương"
    },
    {
        "id": 2685,
        "syllable": "trươt",
        "consonant": "tr",
        "rhyme": "ươt"
    },
    {
        "id": 2686,
        "syllable": "trươn",
        "consonant": "tr",
        "rhyme": "ươn"
    },
    {
        "id": 2687,
        "syllable": "trươp",
        "consonant": "tr",
        "rhyme": "ươp"
    },
    {
        "id": 2688,
        "syllable": "trươm",
        "consonant": "tr",
        "rhyme": "ươm"
    },
    {
        "id": 2689,
        "syllable": "trươi",
        "consonant": "tr",
        "rhyme": "ươi"
    },
    {
        "id": 2690,
        "syllable": "tra",
        "consonant": "tr",
        "rhyme": "a"
    },
    {
        "id": 2691,
        "syllable": "troa",
        "consonant": "tr",
        "rhyme": "oa"
    },
    {
        "id": 2692,
        "syllable": "trac",
        "consonant": "tr",
        "rhyme": "ac"
    },
    {
        "id": 2693,
        "syllable": "troac",
        "consonant": "tr",
        "rhyme": "oac"
    },
    {
        "id": 2694,
        "syllable": "trang",
        "consonant": "tr",
        "rhyme": "ang"
    },
    {
        "id": 2695,
        "syllable": "troang",
        "consonant": "tr",
        "rhyme": "oang"
    },
    {
        "id": 2696,
        "syllable": "trat",
        "consonant": "tr",
        "rhyme": "at"
    },
    {
        "id": 2697,
        "syllable": "troat",
        "consonant": "tr",
        "rhyme": "oat"
    },
    {
        "id": 2698,
        "syllable": "tran",
        "consonant": "tr",
        "rhyme": "an"
    },
    {
        "id": 2699,
        "syllable": "troan",
        "consonant": "tr",
        "rhyme": "oan"
    },
    {
        "id": 2700,
        "syllable": "trap",
        "consonant": "tr",
        "rhyme": "ap"
    },
    {
        "id": 2701,
        "syllable": "troap",
        "consonant": "tr",
        "rhyme": "oap"
    },
    {
        "id": 2702,
        "syllable": "tram",
        "consonant": "tr",
        "rhyme": "am"
    },
    {
        "id": 2703,
        "syllable": "troam",
        "consonant": "tr",
        "rhyme": "oam"
    },
    {
        "id": 2704,
        "syllable": "trai",
        "consonant": "tr",
        "rhyme": "ai"
    },
    {
        "id": 2705,
        "syllable": "troai",
        "consonant": "tr",
        "rhyme": "oai"
    },
    {
        "id": 2706,
        "syllable": "trao",
        "consonant": "tr",
        "rhyme": "ao"
    },
    {
        "id": 2707,
        "syllable": "troao",
        "consonant": "tr",
        "rhyme": "oao"
    },
    {
        "id": 2708,
        "syllable": "trăc",
        "consonant": "tr",
        "rhyme": "ăc"
    },
    {
        "id": 2709,
        "syllable": "troăc",
        "consonant": "tr",
        "rhyme": "oăc"
    },
    {
        "id": 2710,
        "syllable": "trăng",
        "consonant": "tr",
        "rhyme": "ăng"
    },
    {
        "id": 2711,
        "syllable": "troăng",
        "consonant": "tr",
        "rhyme": "oăng"
    },
    {
        "id": 2712,
        "syllable": "trăt",
        "consonant": "tr",
        "rhyme": "ăt"
    },
    {
        "id": 2713,
        "syllable": "troăt",
        "consonant": "tr",
        "rhyme": "oăt"
    },
    {
        "id": 2714,
        "syllable": "trăn",
        "consonant": "tr",
        "rhyme": "ăn"
    },
    {
        "id": 2715,
        "syllable": "troăn",
        "consonant": "tr",
        "rhyme": "oăn"
    },
    {
        "id": 2716,
        "syllable": "trăp",
        "consonant": "tr",
        "rhyme": "ăp"
    },
    {
        "id": 2717,
        "syllable": "troăp",
        "consonant": "tr",
        "rhyme": "oăp"
    },
    {
        "id": 2718,
        "syllable": "trăm",
        "consonant": "tr",
        "rhyme": "ăm"
    },
    {
        "id": 2719,
        "syllable": "troăm",
        "consonant": "tr",
        "rhyme": "oăm"
    },
    {
        "id": 2720,
        "syllable": "tray",
        "consonant": "tr",
        "rhyme": "ay"
    },
    {
        "id": 2721,
        "syllable": "troay",
        "consonant": "tr",
        "rhyme": "oay"
    },
    {
        "id": 2722,
        "syllable": "trau",
        "consonant": "tr",
        "rhyme": "au"
    },
    {
        "id": 2723,
        "syllable": "troau",
        "consonant": "tr",
        "rhyme": "oau"
    },
    {
        "id": 2724,
        "syllable": "chi",
        "consonant": "ch",
        "rhyme": "i"
    },
    {
        "id": 2725,
        "syllable": "chuy",
        "consonant": "ch",
        "rhyme": "uy"
    },
    {
        "id": 2726,
        "syllable": "chich",
        "consonant": "ch",
        "rhyme": "ich"
    },
    {
        "id": 2727,
        "syllable": "chuych",
        "consonant": "ch",
        "rhyme": "uych"
    },
    {
        "id": 2728,
        "syllable": "chinh",
        "consonant": "ch",
        "rhyme": "inh"
    },
    {
        "id": 2729,
        "syllable": "chuynh",
        "consonant": "ch",
        "rhyme": "uynh"
    },
    {
        "id": 2730,
        "syllable": "chit",
        "consonant": "ch",
        "rhyme": "it"
    },
    {
        "id": 2731,
        "syllable": "chuyt",
        "consonant": "ch",
        "rhyme": "uyt"
    },
    {
        "id": 2732,
        "syllable": "chin",
        "consonant": "ch",
        "rhyme": "in"
    },
    {
        "id": 2733,
        "syllable": "chuyn",
        "consonant": "ch",
        "rhyme": "uyn"
    },
    {
        "id": 2734,
        "syllable": "chip",
        "consonant": "ch",
        "rhyme": "ip"
    },
    {
        "id": 2735,
        "syllable": "chuyp",
        "consonant": "ch",
        "rhyme": "uyp"
    },
    {
        "id": 2736,
        "syllable": "chim",
        "consonant": "ch",
        "rhyme": "im"
    },
    {
        "id": 2737,
        "syllable": "chuym",
        "consonant": "ch",
        "rhyme": "uym"
    },
    {
        "id": 2738,
        "syllable": "chiu",
        "consonant": "ch",
        "rhyme": "iu"
    },
    {
        "id": 2739,
        "syllable": "chuyu",
        "consonant": "ch",
        "rhyme": "uyu"
    },
    {
        "id": 2740,
        "syllable": "chê",
        "consonant": "ch",
        "rhyme": "ê"
    },
    {
        "id": 2741,
        "syllable": "chuê",
        "consonant": "ch",
        "rhyme": "uê"
    },
    {
        "id": 2742,
        "syllable": "chêch",
        "consonant": "ch",
        "rhyme": "êch"
    },
    {
        "id": 2743,
        "syllable": "chuêch",
        "consonant": "ch",
        "rhyme": "uêch"
    },
    {
        "id": 2744,
        "syllable": "chênh",
        "consonant": "ch",
        "rhyme": "ênh"
    },
    {
        "id": 2745,
        "syllable": "chuênh",
        "consonant": "ch",
        "rhyme": "uênh"
    },
    {
        "id": 2746,
        "syllable": "chêt",
        "consonant": "ch",
        "rhyme": "êt"
    },
    {
        "id": 2747,
        "syllable": "chuêt",
        "consonant": "ch",
        "rhyme": "uêt"
    },
    {
        "id": 2748,
        "syllable": "chên",
        "consonant": "ch",
        "rhyme": "ên"
    },
    {
        "id": 2749,
        "syllable": "chuên",
        "consonant": "ch",
        "rhyme": "uên"
    },
    {
        "id": 2750,
        "syllable": "chêp",
        "consonant": "ch",
        "rhyme": "êp"
    },
    {
        "id": 2751,
        "syllable": "chuêp",
        "consonant": "ch",
        "rhyme": "uêp"
    },
    {
        "id": 2752,
        "syllable": "chêm",
        "consonant": "ch",
        "rhyme": "êm"
    },
    {
        "id": 2753,
        "syllable": "chuêm",
        "consonant": "ch",
        "rhyme": "uêm"
    },
    {
        "id": 2754,
        "syllable": "chêu",
        "consonant": "ch",
        "rhyme": "êu"
    },
    {
        "id": 2755,
        "syllable": "che",
        "consonant": "ch",
        "rhyme": "e"
    },
    {
        "id": 2756,
        "syllable": "choe",
        "consonant": "ch",
        "rhyme": "oe"
    },
    {
        "id": 2757,
        "syllable": "chec",
        "consonant": "ch",
        "rhyme": "ec"
    },
    {
        "id": 2758,
        "syllable": "choec",
        "consonant": "ch",
        "rhyme": "oec"
    },
    {
        "id": 2759,
        "syllable": "cheng",
        "consonant": "ch",
        "rhyme": "eng"
    },
    {
        "id": 2760,
        "syllable": "choeng",
        "consonant": "ch",
        "rhyme": "oeng"
    },
    {
        "id": 2761,
        "syllable": "chet",
        "consonant": "ch",
        "rhyme": "et"
    },
    {
        "id": 2762,
        "syllable": "choet",
        "consonant": "ch",
        "rhyme": "oet"
    },
    {
        "id": 2763,
        "syllable": "chen",
        "consonant": "ch",
        "rhyme": "en"
    },
    {
        "id": 2764,
        "syllable": "choen",
        "consonant": "ch",
        "rhyme": "oen"
    },
    {
        "id": 2765,
        "syllable": "chep",
        "consonant": "ch",
        "rhyme": "ep"
    },
    {
        "id": 2766,
        "syllable": "choep",
        "consonant": "ch",
        "rhyme": "oep"
    },
    {
        "id": 2767,
        "syllable": "chem",
        "consonant": "ch",
        "rhyme": "em"
    },
    {
        "id": 2768,
        "syllable": "choem",
        "consonant": "ch",
        "rhyme": "oem"
    },
    {
        "id": 2769,
        "syllable": "cheo",
        "consonant": "ch",
        "rhyme": "eo"
    },
    {
        "id": 2770,
        "syllable": "choeo",
        "consonant": "ch",
        "rhyme": "oeo"
    },
    {
        "id": 2771,
        "syllable": "chach",
        "consonant": "ch",
        "rhyme": "ach"
    },
    {
        "id": 2772,
        "syllable": "choach",
        "consonant": "ch",
        "rhyme": "oach"
    },
    {
        "id": 2773,
        "syllable": "chanh",
        "consonant": "ch",
        "rhyme": "anh"
    },
    {
        "id": 2774,
        "syllable": "choanh",
        "consonant": "ch",
        "rhyme": "oanh"
    },
    {
        "id": 2775,
        "syllable": "chia",
        "consonant": "ch",
        "rhyme": "ia"
    },
    {
        "id": 2776,
        "syllable": "chuya",
        "consonant": "ch",
        "rhyme": "uya"
    },
    {
        "id": 2777,
        "syllable": "chiêc",
        "consonant": "ch",
        "rhyme": "iêc"
    },
    {
        "id": 2778,
        "syllable": "chiêng",
        "consonant": "ch",
        "rhyme": "iêng"
    },
    {
        "id": 2779,
        "syllable": "chiêt",
        "consonant": "ch",
        "rhyme": "iêt"
    },
    {
        "id": 2780,
        "syllable": "chiên",
        "consonant": "ch",
        "rhyme": "iên"
    },
    {
        "id": 2781,
        "syllable": "chuyêt",
        "consonant": "ch",
        "rhyme": "uyêt"
    },
    {
        "id": 2782,
        "syllable": "chuyên",
        "consonant": "ch",
        "rhyme": "uyên"
    },
    {
        "id": 2783,
        "syllable": "chiêp",
        "consonant": "ch",
        "rhyme": "iêp"
    },
    {
        "id": 2784,
        "syllable": "chuyêp",
        "consonant": "ch",
        "rhyme": "uyêp"
    },
    {
        "id": 2785,
        "syllable": "chiêm",
        "consonant": "ch",
        "rhyme": "iêm"
    },
    {
        "id": 2786,
        "syllable": "chuyêm",
        "consonant": "ch",
        "rhyme": "uyêm"
    },
    {
        "id": 2787,
        "syllable": "chiêu",
        "consonant": "ch",
        "rhyme": "iêu"
    },
    {
        "id": 2788,
        "syllable": "chư",
        "consonant": "ch",
        "rhyme": "ư"
    },
    {
        "id": 2789,
        "syllable": "chưc",
        "consonant": "ch",
        "rhyme": "ưc"
    },
    {
        "id": 2790,
        "syllable": "chưng",
        "consonant": "ch",
        "rhyme": "ưng"
    },
    {
        "id": 2791,
        "syllable": "chưt",
        "consonant": "ch",
        "rhyme": "ưt"
    },
    {
        "id": 2792,
        "syllable": "chưn",
        "consonant": "ch",
        "rhyme": "ưn"
    },
    {
        "id": 2793,
        "syllable": "chưp",
        "consonant": "ch",
        "rhyme": "ưp"
    },
    {
        "id": 2794,
        "syllable": "chưm",
        "consonant": "ch",
        "rhyme": "ưm"
    },
    {
        "id": 2795,
        "syllable": "chưi",
        "consonant": "ch",
        "rhyme": "ưi"
    },
    {
        "id": 2796,
        "syllable": "chưu",
        "consonant": "ch",
        "rhyme": "ưu"
    },
    {
        "id": 2797,
        "syllable": "chơ",
        "consonant": "ch",
        "rhyme": "ơ"
    },
    {
        "id": 2798,
        "syllable": "chuơ",
        "consonant": "ch",
        "rhyme": "uơ"
    },
    {
        "id": 2799,
        "syllable": "chơng",
        "consonant": "ch",
        "rhyme": "ơng"
    },
    {
        "id": 2800,
        "syllable": "chơt",
        "consonant": "ch",
        "rhyme": "ơt"
    },
    {
        "id": 2801,
        "syllable": "chơn",
        "consonant": "ch",
        "rhyme": "ơn"
    },
    {
        "id": 2802,
        "syllable": "chơp",
        "consonant": "ch",
        "rhyme": "ơp"
    },
    {
        "id": 2803,
        "syllable": "chơm",
        "consonant": "ch",
        "rhyme": "ơm"
    },
    {
        "id": 2804,
        "syllable": "chơi",
        "consonant": "ch",
        "rhyme": "ơi"
    },
    {
        "id": 2805,
        "syllable": "châc",
        "consonant": "ch",
        "rhyme": "âc"
    },
    {
        "id": 2806,
        "syllable": "châng",
        "consonant": "ch",
        "rhyme": "âng"
    },
    {
        "id": 2807,
        "syllable": "chuâng",
        "consonant": "ch",
        "rhyme": "uâng"
    },
    {
        "id": 2808,
        "syllable": "chât",
        "consonant": "ch",
        "rhyme": "ât"
    },
    {
        "id": 2809,
        "syllable": "chuât",
        "consonant": "ch",
        "rhyme": "uât"
    },
    {
        "id": 2810,
        "syllable": "chân",
        "consonant": "ch",
        "rhyme": "ân"
    },
    {
        "id": 2811,
        "syllable": "chuân",
        "consonant": "ch",
        "rhyme": "uân"
    },
    {
        "id": 2812,
        "syllable": "châp",
        "consonant": "ch",
        "rhyme": "âp"
    },
    {
        "id": 2813,
        "syllable": "chuâp",
        "consonant": "ch",
        "rhyme": "uâp"
    },
    {
        "id": 2814,
        "syllable": "châm",
        "consonant": "ch",
        "rhyme": "âm"
    },
    {
        "id": 2815,
        "syllable": "chuâm",
        "consonant": "ch",
        "rhyme": "uâm"
    },
    {
        "id": 2816,
        "syllable": "chây",
        "consonant": "ch",
        "rhyme": "ây"
    },
    {
        "id": 2817,
        "syllable": "chuây",
        "consonant": "ch",
        "rhyme": "uây"
    },
    {
        "id": 2818,
        "syllable": "châu",
        "consonant": "ch",
        "rhyme": "âu"
    },
    {
        "id": 2819,
        "syllable": "chuâu",
        "consonant": "ch",
        "rhyme": "uâu"
    },
    {
        "id": 2820,
        "syllable": "chu",
        "consonant": "ch",
        "rhyme": "u"
    },
    {
        "id": 2821,
        "syllable": "chuc",
        "consonant": "ch",
        "rhyme": "uc"
    },
    {
        "id": 2822,
        "syllable": "chung",
        "consonant": "ch",
        "rhyme": "ung"
    },
    {
        "id": 2823,
        "syllable": "chut",
        "consonant": "ch",
        "rhyme": "ut"
    },
    {
        "id": 2824,
        "syllable": "chun",
        "consonant": "ch",
        "rhyme": "un"
    },
    {
        "id": 2825,
        "syllable": "chup",
        "consonant": "ch",
        "rhyme": "up"
    },
    {
        "id": 2826,
        "syllable": "chum",
        "consonant": "ch",
        "rhyme": "um"
    },
    {
        "id": 2827,
        "syllable": "chui",
        "consonant": "ch",
        "rhyme": "ui"
    },
    {
        "id": 2828,
        "syllable": "chô",
        "consonant": "ch",
        "rhyme": "ô"
    },
    {
        "id": 2829,
        "syllable": "chôc",
        "consonant": "ch",
        "rhyme": "ôc"
    },
    {
        "id": 2830,
        "syllable": "chông",
        "consonant": "ch",
        "rhyme": "ông"
    },
    {
        "id": 2831,
        "syllable": "chôt",
        "consonant": "ch",
        "rhyme": "ôt"
    },
    {
        "id": 2832,
        "syllable": "chôn",
        "consonant": "ch",
        "rhyme": "ôn"
    },
    {
        "id": 2833,
        "syllable": "chôp",
        "consonant": "ch",
        "rhyme": "ôp"
    },
    {
        "id": 2834,
        "syllable": "chôm",
        "consonant": "ch",
        "rhyme": "ôm"
    },
    {
        "id": 2835,
        "syllable": "chôi",
        "consonant": "ch",
        "rhyme": "ôi"
    },
    {
        "id": 2836,
        "syllable": "cho",
        "consonant": "ch",
        "rhyme": "o"
    },
    {
        "id": 2837,
        "syllable": "chooc",
        "consonant": "ch",
        "rhyme": "ooc"
    },
    {
        "id": 2838,
        "syllable": "choong",
        "consonant": "ch",
        "rhyme": "oong"
    },
    {
        "id": 2839,
        "syllable": "chot",
        "consonant": "ch",
        "rhyme": "ot"
    },
    {
        "id": 2840,
        "syllable": "chon",
        "consonant": "ch",
        "rhyme": "on"
    },
    {
        "id": 2841,
        "syllable": "chop",
        "consonant": "ch",
        "rhyme": "op"
    },
    {
        "id": 2842,
        "syllable": "chom",
        "consonant": "ch",
        "rhyme": "om"
    },
    {
        "id": 2843,
        "syllable": "choi",
        "consonant": "ch",
        "rhyme": "oi"
    },
    {
        "id": 2844,
        "syllable": "choc",
        "consonant": "ch",
        "rhyme": "oc"
    },
    {
        "id": 2845,
        "syllable": "chong",
        "consonant": "ch",
        "rhyme": "ong"
    },
    {
        "id": 2846,
        "syllable": "chua",
        "consonant": "ch",
        "rhyme": "ua"
    },
    {
        "id": 2847,
        "syllable": "chuôc",
        "consonant": "ch",
        "rhyme": "uôc"
    },
    {
        "id": 2848,
        "syllable": "chuông",
        "consonant": "ch",
        "rhyme": "uông"
    },
    {
        "id": 2849,
        "syllable": "chuôt",
        "consonant": "ch",
        "rhyme": "uôt"
    },
    {
        "id": 2850,
        "syllable": "chuôn",
        "consonant": "ch",
        "rhyme": "uôn"
    },
    {
        "id": 2851,
        "syllable": "chuôp",
        "consonant": "ch",
        "rhyme": "uôp"
    },
    {
        "id": 2852,
        "syllable": "chuôm",
        "consonant": "ch",
        "rhyme": "uôm"
    },
    {
        "id": 2853,
        "syllable": "chuôi",
        "consonant": "ch",
        "rhyme": "uôi"
    },
    {
        "id": 2854,
        "syllable": "chưa",
        "consonant": "ch",
        "rhyme": "ưa"
    },
    {
        "id": 2855,
        "syllable": "chươc",
        "consonant": "ch",
        "rhyme": "ươc"
    },
    {
        "id": 2856,
        "syllable": "chương",
        "consonant": "ch",
        "rhyme": "ương"
    },
    {
        "id": 2857,
        "syllable": "chươt",
        "consonant": "ch",
        "rhyme": "ươt"
    },
    {
        "id": 2858,
        "syllable": "chươn",
        "consonant": "ch",
        "rhyme": "ươn"
    },
    {
        "id": 2859,
        "syllable": "chươp",
        "consonant": "ch",
        "rhyme": "ươp"
    },
    {
        "id": 2860,
        "syllable": "chươm",
        "consonant": "ch",
        "rhyme": "ươm"
    },
    {
        "id": 2861,
        "syllable": "chươi",
        "consonant": "ch",
        "rhyme": "ươi"
    },
    {
        "id": 2862,
        "syllable": "cha",
        "consonant": "ch",
        "rhyme": "a"
    },
    {
        "id": 2863,
        "syllable": "choa",
        "consonant": "ch",
        "rhyme": "oa"
    },
    {
        "id": 2864,
        "syllable": "chac",
        "consonant": "ch",
        "rhyme": "ac"
    },
    {
        "id": 2865,
        "syllable": "choac",
        "consonant": "ch",
        "rhyme": "oac"
    },
    {
        "id": 2866,
        "syllable": "chang",
        "consonant": "ch",
        "rhyme": "ang"
    },
    {
        "id": 2867,
        "syllable": "choang",
        "consonant": "ch",
        "rhyme": "oang"
    },
    {
        "id": 2868,
        "syllable": "chat",
        "consonant": "ch",
        "rhyme": "at"
    },
    {
        "id": 2869,
        "syllable": "choat",
        "consonant": "ch",
        "rhyme": "oat"
    },
    {
        "id": 2870,
        "syllable": "chan",
        "consonant": "ch",
        "rhyme": "an"
    },
    {
        "id": 2871,
        "syllable": "choan",
        "consonant": "ch",
        "rhyme": "oan"
    },
    {
        "id": 2872,
        "syllable": "chap",
        "consonant": "ch",
        "rhyme": "ap"
    },
    {
        "id": 2873,
        "syllable": "choap",
        "consonant": "ch",
        "rhyme": "oap"
    },
    {
        "id": 2874,
        "syllable": "cham",
        "consonant": "ch",
        "rhyme": "am"
    },
    {
        "id": 2875,
        "syllable": "choam",
        "consonant": "ch",
        "rhyme": "oam"
    },
    {
        "id": 2876,
        "syllable": "chai",
        "consonant": "ch",
        "rhyme": "ai"
    },
    {
        "id": 2877,
        "syllable": "choai",
        "consonant": "ch",
        "rhyme": "oai"
    },
    {
        "id": 2878,
        "syllable": "chao",
        "consonant": "ch",
        "rhyme": "ao"
    },
    {
        "id": 2879,
        "syllable": "choao",
        "consonant": "ch",
        "rhyme": "oao"
    },
    {
        "id": 2880,
        "syllable": "chăc",
        "consonant": "ch",
        "rhyme": "ăc"
    },
    {
        "id": 2881,
        "syllable": "choăc",
        "consonant": "ch",
        "rhyme": "oăc"
    },
    {
        "id": 2882,
        "syllable": "chăng",
        "consonant": "ch",
        "rhyme": "ăng"
    },
    {
        "id": 2883,
        "syllable": "choăng",
        "consonant": "ch",
        "rhyme": "oăng"
    },
    {
        "id": 2884,
        "syllable": "chăt",
        "consonant": "ch",
        "rhyme": "ăt"
    },
    {
        "id": 2885,
        "syllable": "choăt",
        "consonant": "ch",
        "rhyme": "oăt"
    },
    {
        "id": 2886,
        "syllable": "chăn",
        "consonant": "ch",
        "rhyme": "ăn"
    },
    {
        "id": 2887,
        "syllable": "choăn",
        "consonant": "ch",
        "rhyme": "oăn"
    },
    {
        "id": 2888,
        "syllable": "chăp",
        "consonant": "ch",
        "rhyme": "ăp"
    },
    {
        "id": 2889,
        "syllable": "choăp",
        "consonant": "ch",
        "rhyme": "oăp"
    },
    {
        "id": 2890,
        "syllable": "chăm",
        "consonant": "ch",
        "rhyme": "ăm"
    },
    {
        "id": 2891,
        "syllable": "choăm",
        "consonant": "ch",
        "rhyme": "oăm"
    },
    {
        "id": 2892,
        "syllable": "chay",
        "consonant": "ch",
        "rhyme": "ay"
    },
    {
        "id": 2893,
        "syllable": "choay",
        "consonant": "ch",
        "rhyme": "oay"
    },
    {
        "id": 2894,
        "syllable": "chau",
        "consonant": "ch",
        "rhyme": "au"
    },
    {
        "id": 2895,
        "syllable": "choau",
        "consonant": "ch",
        "rhyme": "oau"
    },
    {
        "id": 2896,
        "syllable": "hi",
        "consonant": "h",
        "rhyme": "i"
    },
    {
        "id": 2897,
        "syllable": "huy",
        "consonant": "h",
        "rhyme": "uy"
    },
    {
        "id": 2898,
        "syllable": "hich",
        "consonant": "h",
        "rhyme": "ich"
    },
    {
        "id": 2899,
        "syllable": "huych",
        "consonant": "h",
        "rhyme": "uych"
    },
    {
        "id": 2900,
        "syllable": "hinh",
        "consonant": "h",
        "rhyme": "inh"
    },
    {
        "id": 2901,
        "syllable": "huynh",
        "consonant": "h",
        "rhyme": "uynh"
    },
    {
        "id": 2902,
        "syllable": "hit",
        "consonant": "h",
        "rhyme": "it"
    },
    {
        "id": 2903,
        "syllable": "huyt",
        "consonant": "h",
        "rhyme": "uyt"
    },
    {
        "id": 2904,
        "syllable": "hin",
        "consonant": "h",
        "rhyme": "in"
    },
    {
        "id": 2905,
        "syllable": "huyn",
        "consonant": "h",
        "rhyme": "uyn"
    },
    {
        "id": 2906,
        "syllable": "hip",
        "consonant": "h",
        "rhyme": "ip"
    },
    {
        "id": 2907,
        "syllable": "huyp",
        "consonant": "h",
        "rhyme": "uyp"
    },
    {
        "id": 2908,
        "syllable": "him",
        "consonant": "h",
        "rhyme": "im"
    },
    {
        "id": 2909,
        "syllable": "huym",
        "consonant": "h",
        "rhyme": "uym"
    },
    {
        "id": 2910,
        "syllable": "hiu",
        "consonant": "h",
        "rhyme": "iu"
    },
    {
        "id": 2911,
        "syllable": "huyu",
        "consonant": "h",
        "rhyme": "uyu"
    },
    {
        "id": 2912,
        "syllable": "hê",
        "consonant": "h",
        "rhyme": "ê"
    },
    {
        "id": 2913,
        "syllable": "huê",
        "consonant": "h",
        "rhyme": "uê"
    },
    {
        "id": 2914,
        "syllable": "hêch",
        "consonant": "h",
        "rhyme": "êch"
    },
    {
        "id": 2915,
        "syllable": "huêch",
        "consonant": "h",
        "rhyme": "uêch"
    },
    {
        "id": 2916,
        "syllable": "hênh",
        "consonant": "h",
        "rhyme": "ênh"
    },
    {
        "id": 2917,
        "syllable": "huênh",
        "consonant": "h",
        "rhyme": "uênh"
    },
    {
        "id": 2918,
        "syllable": "hêt",
        "consonant": "h",
        "rhyme": "êt"
    },
    {
        "id": 2919,
        "syllable": "huêt",
        "consonant": "h",
        "rhyme": "uêt"
    },
    {
        "id": 2920,
        "syllable": "hên",
        "consonant": "h",
        "rhyme": "ên"
    },
    {
        "id": 2921,
        "syllable": "huên",
        "consonant": "h",
        "rhyme": "uên"
    },
    {
        "id": 2922,
        "syllable": "hêp",
        "consonant": "h",
        "rhyme": "êp"
    },
    {
        "id": 2923,
        "syllable": "huêp",
        "consonant": "h",
        "rhyme": "uêp"
    },
    {
        "id": 2924,
        "syllable": "hêm",
        "consonant": "h",
        "rhyme": "êm"
    },
    {
        "id": 2925,
        "syllable": "huêm",
        "consonant": "h",
        "rhyme": "uêm"
    },
    {
        "id": 2926,
        "syllable": "hêu",
        "consonant": "h",
        "rhyme": "êu"
    },
    {
        "id": 2927,
        "syllable": "he",
        "consonant": "h",
        "rhyme": "e"
    },
    {
        "id": 2928,
        "syllable": "hoe",
        "consonant": "h",
        "rhyme": "oe"
    },
    {
        "id": 2929,
        "syllable": "hec",
        "consonant": "h",
        "rhyme": "ec"
    },
    {
        "id": 2930,
        "syllable": "hoec",
        "consonant": "h",
        "rhyme": "oec"
    },
    {
        "id": 2931,
        "syllable": "heng",
        "consonant": "h",
        "rhyme": "eng"
    },
    {
        "id": 2932,
        "syllable": "hoeng",
        "consonant": "h",
        "rhyme": "oeng"
    },
    {
        "id": 2933,
        "syllable": "het",
        "consonant": "h",
        "rhyme": "et"
    },
    {
        "id": 2934,
        "syllable": "hoet",
        "consonant": "h",
        "rhyme": "oet"
    },
    {
        "id": 2935,
        "syllable": "hen",
        "consonant": "h",
        "rhyme": "en"
    },
    {
        "id": 2936,
        "syllable": "hoen",
        "consonant": "h",
        "rhyme": "oen"
    },
    {
        "id": 2937,
        "syllable": "hep",
        "consonant": "h",
        "rhyme": "ep"
    },
    {
        "id": 2938,
        "syllable": "hoep",
        "consonant": "h",
        "rhyme": "oep"
    },
    {
        "id": 2939,
        "syllable": "hem",
        "consonant": "h",
        "rhyme": "em"
    },
    {
        "id": 2940,
        "syllable": "hoem",
        "consonant": "h",
        "rhyme": "oem"
    },
    {
        "id": 2941,
        "syllable": "heo",
        "consonant": "h",
        "rhyme": "eo"
    },
    {
        "id": 2942,
        "syllable": "hoeo",
        "consonant": "h",
        "rhyme": "oeo"
    },
    {
        "id": 2943,
        "syllable": "hach",
        "consonant": "h",
        "rhyme": "ach"
    },
    {
        "id": 2944,
        "syllable": "hoach",
        "consonant": "h",
        "rhyme": "oach"
    },
    {
        "id": 2945,
        "syllable": "hanh",
        "consonant": "h",
        "rhyme": "anh"
    },
    {
        "id": 2946,
        "syllable": "hoanh",
        "consonant": "h",
        "rhyme": "oanh"
    },
    {
        "id": 2947,
        "syllable": "hia",
        "consonant": "h",
        "rhyme": "ia"
    },
    {
        "id": 2948,
        "syllable": "huya",
        "consonant": "h",
        "rhyme": "uya"
    },
    {
        "id": 2949,
        "syllable": "hiêc",
        "consonant": "h",
        "rhyme": "iêc"
    },
    {
        "id": 2950,
        "syllable": "hiêng",
        "consonant": "h",
        "rhyme": "iêng"
    },
    {
        "id": 2951,
        "syllable": "hiêt",
        "consonant": "h",
        "rhyme": "iêt"
    },
    {
        "id": 2952,
        "syllable": "hiên",
        "consonant": "h",
        "rhyme": "iên"
    },
    {
        "id": 2953,
        "syllable": "huyêt",
        "consonant": "h",
        "rhyme": "uyêt"
    },
    {
        "id": 2954,
        "syllable": "huyên",
        "consonant": "h",
        "rhyme": "uyên"
    },
    {
        "id": 2955,
        "syllable": "hiêp",
        "consonant": "h",
        "rhyme": "iêp"
    },
    {
        "id": 2956,
        "syllable": "huyêp",
        "consonant": "h",
        "rhyme": "uyêp"
    },
    {
        "id": 2957,
        "syllable": "hiêm",
        "consonant": "h",
        "rhyme": "iêm"
    },
    {
        "id": 2958,
        "syllable": "huyêm",
        "consonant": "h",
        "rhyme": "uyêm"
    },
    {
        "id": 2959,
        "syllable": "hiêu",
        "consonant": "h",
        "rhyme": "iêu"
    },
    {
        "id": 2960,
        "syllable": "hư",
        "consonant": "h",
        "rhyme": "ư"
    },
    {
        "id": 2961,
        "syllable": "hưc",
        "consonant": "h",
        "rhyme": "ưc"
    },
    {
        "id": 2962,
        "syllable": "hưng",
        "consonant": "h",
        "rhyme": "ưng"
    },
    {
        "id": 2963,
        "syllable": "hưt",
        "consonant": "h",
        "rhyme": "ưt"
    },
    {
        "id": 2964,
        "syllable": "hưn",
        "consonant": "h",
        "rhyme": "ưn"
    },
    {
        "id": 2965,
        "syllable": "hưp",
        "consonant": "h",
        "rhyme": "ưp"
    },
    {
        "id": 2966,
        "syllable": "hưm",
        "consonant": "h",
        "rhyme": "ưm"
    },
    {
        "id": 2967,
        "syllable": "hưi",
        "consonant": "h",
        "rhyme": "ưi"
    },
    {
        "id": 2968,
        "syllable": "hưu",
        "consonant": "h",
        "rhyme": "ưu"
    },
    {
        "id": 2969,
        "syllable": "hơ",
        "consonant": "h",
        "rhyme": "ơ"
    },
    {
        "id": 2970,
        "syllable": "huơ",
        "consonant": "h",
        "rhyme": "uơ"
    },
    {
        "id": 2971,
        "syllable": "hơng",
        "consonant": "h",
        "rhyme": "ơng"
    },
    {
        "id": 2972,
        "syllable": "hơt",
        "consonant": "h",
        "rhyme": "ơt"
    },
    {
        "id": 2973,
        "syllable": "hơn",
        "consonant": "h",
        "rhyme": "ơn"
    },
    {
        "id": 2974,
        "syllable": "hơp",
        "consonant": "h",
        "rhyme": "ơp"
    },
    {
        "id": 2975,
        "syllable": "hơm",
        "consonant": "h",
        "rhyme": "ơm"
    },
    {
        "id": 2976,
        "syllable": "hơi",
        "consonant": "h",
        "rhyme": "ơi"
    },
    {
        "id": 2977,
        "syllable": "hâc",
        "consonant": "h",
        "rhyme": "âc"
    },
    {
        "id": 2978,
        "syllable": "hâng",
        "consonant": "h",
        "rhyme": "âng"
    },
    {
        "id": 2979,
        "syllable": "huâng",
        "consonant": "h",
        "rhyme": "uâng"
    },
    {
        "id": 2980,
        "syllable": "hât",
        "consonant": "h",
        "rhyme": "ât"
    },
    {
        "id": 2981,
        "syllable": "huât",
        "consonant": "h",
        "rhyme": "uât"
    },
    {
        "id": 2982,
        "syllable": "hân",
        "consonant": "h",
        "rhyme": "ân"
    },
    {
        "id": 2983,
        "syllable": "huân",
        "consonant": "h",
        "rhyme": "uân"
    },
    {
        "id": 2984,
        "syllable": "hâp",
        "consonant": "h",
        "rhyme": "âp"
    },
    {
        "id": 2985,
        "syllable": "huâp",
        "consonant": "h",
        "rhyme": "uâp"
    },
    {
        "id": 2986,
        "syllable": "hâm",
        "consonant": "h",
        "rhyme": "âm"
    },
    {
        "id": 2987,
        "syllable": "huâm",
        "consonant": "h",
        "rhyme": "uâm"
    },
    {
        "id": 2988,
        "syllable": "hây",
        "consonant": "h",
        "rhyme": "ây"
    },
    {
        "id": 2989,
        "syllable": "huây",
        "consonant": "h",
        "rhyme": "uây"
    },
    {
        "id": 2990,
        "syllable": "hâu",
        "consonant": "h",
        "rhyme": "âu"
    },
    {
        "id": 2991,
        "syllable": "huâu",
        "consonant": "h",
        "rhyme": "uâu"
    },
    {
        "id": 2992,
        "syllable": "hu",
        "consonant": "h",
        "rhyme": "u"
    },
    {
        "id": 2993,
        "syllable": "huc",
        "consonant": "h",
        "rhyme": "uc"
    },
    {
        "id": 2994,
        "syllable": "hung",
        "consonant": "h",
        "rhyme": "ung"
    },
    {
        "id": 2995,
        "syllable": "hut",
        "consonant": "h",
        "rhyme": "ut"
    },
    {
        "id": 2996,
        "syllable": "hun",
        "consonant": "h",
        "rhyme": "un"
    },
    {
        "id": 2997,
        "syllable": "hup",
        "consonant": "h",
        "rhyme": "up"
    },
    {
        "id": 2998,
        "syllable": "hum",
        "consonant": "h",
        "rhyme": "um"
    },
    {
        "id": 2999,
        "syllable": "hui",
        "consonant": "h",
        "rhyme": "ui"
    },
    {
        "id": 3000,
        "syllable": "hô",
        "consonant": "h",
        "rhyme": "ô"
    },
    {
        "id": 3001,
        "syllable": "hôc",
        "consonant": "h",
        "rhyme": "ôc"
    },
    {
        "id": 3002,
        "syllable": "hông",
        "consonant": "h",
        "rhyme": "ông"
    },
    {
        "id": 3003,
        "syllable": "hôt",
        "consonant": "h",
        "rhyme": "ôt"
    },
    {
        "id": 3004,
        "syllable": "hôn",
        "consonant": "h",
        "rhyme": "ôn"
    },
    {
        "id": 3005,
        "syllable": "hôp",
        "consonant": "h",
        "rhyme": "ôp"
    },
    {
        "id": 3006,
        "syllable": "hôm",
        "consonant": "h",
        "rhyme": "ôm"
    },
    {
        "id": 3007,
        "syllable": "hôi",
        "consonant": "h",
        "rhyme": "ôi"
    },
    {
        "id": 3008,
        "syllable": "ho",
        "consonant": "h",
        "rhyme": "o"
    },
    {
        "id": 3009,
        "syllable": "hooc",
        "consonant": "h",
        "rhyme": "ooc"
    },
    {
        "id": 3010,
        "syllable": "hoong",
        "consonant": "h",
        "rhyme": "oong"
    },
    {
        "id": 3011,
        "syllable": "hot",
        "consonant": "h",
        "rhyme": "ot"
    },
    {
        "id": 3012,
        "syllable": "hon",
        "consonant": "h",
        "rhyme": "on"
    },
    {
        "id": 3013,
        "syllable": "hop",
        "consonant": "h",
        "rhyme": "op"
    },
    {
        "id": 3014,
        "syllable": "hom",
        "consonant": "h",
        "rhyme": "om"
    },
    {
        "id": 3015,
        "syllable": "hoi",
        "consonant": "h",
        "rhyme": "oi"
    },
    {
        "id": 3016,
        "syllable": "hoc",
        "consonant": "h",
        "rhyme": "oc"
    },
    {
        "id": 3017,
        "syllable": "hong",
        "consonant": "h",
        "rhyme": "ong"
    },
    {
        "id": 3018,
        "syllable": "hua",
        "consonant": "h",
        "rhyme": "ua"
    },
    {
        "id": 3019,
        "syllable": "huôc",
        "consonant": "h",
        "rhyme": "uôc"
    },
    {
        "id": 3020,
        "syllable": "huông",
        "consonant": "h",
        "rhyme": "uông"
    },
    {
        "id": 3021,
        "syllable": "huôt",
        "consonant": "h",
        "rhyme": "uôt"
    },
    {
        "id": 3022,
        "syllable": "huôn",
        "consonant": "h",
        "rhyme": "uôn"
    },
    {
        "id": 3023,
        "syllable": "huôp",
        "consonant": "h",
        "rhyme": "uôp"
    },
    {
        "id": 3024,
        "syllable": "huôm",
        "consonant": "h",
        "rhyme": "uôm"
    },
    {
        "id": 3025,
        "syllable": "huôi",
        "consonant": "h",
        "rhyme": "uôi"
    },
    {
        "id": 3026,
        "syllable": "hưa",
        "consonant": "h",
        "rhyme": "ưa"
    },
    {
        "id": 3027,
        "syllable": "hươc",
        "consonant": "h",
        "rhyme": "ươc"
    },
    {
        "id": 3028,
        "syllable": "hương",
        "consonant": "h",
        "rhyme": "ương"
    },
    {
        "id": 3029,
        "syllable": "hươt",
        "consonant": "h",
        "rhyme": "ươt"
    },
    {
        "id": 3030,
        "syllable": "hươn",
        "consonant": "h",
        "rhyme": "ươn"
    },
    {
        "id": 3031,
        "syllable": "hươp",
        "consonant": "h",
        "rhyme": "ươp"
    },
    {
        "id": 3032,
        "syllable": "hươm",
        "consonant": "h",
        "rhyme": "ươm"
    },
    {
        "id": 3033,
        "syllable": "hươi",
        "consonant": "h",
        "rhyme": "ươi"
    },
    {
        "id": 3034,
        "syllable": "ha",
        "consonant": "h",
        "rhyme": "a"
    },
    {
        "id": 3035,
        "syllable": "hoa",
        "consonant": "h",
        "rhyme": "oa"
    },
    {
        "id": 3036,
        "syllable": "hac",
        "consonant": "h",
        "rhyme": "ac"
    },
    {
        "id": 3037,
        "syllable": "hoac",
        "consonant": "h",
        "rhyme": "oac"
    },
    {
        "id": 3038,
        "syllable": "hang",
        "consonant": "h",
        "rhyme": "ang"
    },
    {
        "id": 3039,
        "syllable": "hoang",
        "consonant": "h",
        "rhyme": "oang"
    },
    {
        "id": 3040,
        "syllable": "hat",
        "consonant": "h",
        "rhyme": "at"
    },
    {
        "id": 3041,
        "syllable": "hoat",
        "consonant": "h",
        "rhyme": "oat"
    },
    {
        "id": 3042,
        "syllable": "han",
        "consonant": "h",
        "rhyme": "an"
    },
    {
        "id": 3043,
        "syllable": "hoan",
        "consonant": "h",
        "rhyme": "oan"
    },
    {
        "id": 3044,
        "syllable": "hap",
        "consonant": "h",
        "rhyme": "ap"
    },
    {
        "id": 3045,
        "syllable": "hoap",
        "consonant": "h",
        "rhyme": "oap"
    },
    {
        "id": 3046,
        "syllable": "ham",
        "consonant": "h",
        "rhyme": "am"
    },
    {
        "id": 3047,
        "syllable": "hoam",
        "consonant": "h",
        "rhyme": "oam"
    },
    {
        "id": 3048,
        "syllable": "hai",
        "consonant": "h",
        "rhyme": "ai"
    },
    {
        "id": 3049,
        "syllable": "hoai",
        "consonant": "h",
        "rhyme": "oai"
    },
    {
        "id": 3050,
        "syllable": "hao",
        "consonant": "h",
        "rhyme": "ao"
    },
    {
        "id": 3051,
        "syllable": "hoao",
        "consonant": "h",
        "rhyme": "oao"
    },
    {
        "id": 3052,
        "syllable": "hăc",
        "consonant": "h",
        "rhyme": "ăc"
    },
    {
        "id": 3053,
        "syllable": "hoăc",
        "consonant": "h",
        "rhyme": "oăc"
    },
    {
        "id": 3054,
        "syllable": "hăng",
        "consonant": "h",
        "rhyme": "ăng"
    },
    {
        "id": 3055,
        "syllable": "hoăng",
        "consonant": "h",
        "rhyme": "oăng"
    },
    {
        "id": 3056,
        "syllable": "hăt",
        "consonant": "h",
        "rhyme": "ăt"
    },
    {
        "id": 3057,
        "syllable": "hoăt",
        "consonant": "h",
        "rhyme": "oăt"
    },
    {
        "id": 3058,
        "syllable": "hăn",
        "consonant": "h",
        "rhyme": "ăn"
    },
    {
        "id": 3059,
        "syllable": "hoăn",
        "consonant": "h",
        "rhyme": "oăn"
    },
    {
        "id": 3060,
        "syllable": "hăp",
        "consonant": "h",
        "rhyme": "ăp"
    },
    {
        "id": 3061,
        "syllable": "hoăp",
        "consonant": "h",
        "rhyme": "oăp"
    },
    {
        "id": 3062,
        "syllable": "hăm",
        "consonant": "h",
        "rhyme": "ăm"
    },
    {
        "id": 3063,
        "syllable": "hoăm",
        "consonant": "h",
        "rhyme": "oăm"
    },
    {
        "id": 3064,
        "syllable": "hay",
        "consonant": "h",
        "rhyme": "ay"
    },
    {
        "id": 3065,
        "syllable": "hoay",
        "consonant": "h",
        "rhyme": "oay"
    },
    {
        "id": 3066,
        "syllable": "hau",
        "consonant": "h",
        "rhyme": "au"
    },
    {
        "id": 3067,
        "syllable": "hoau",
        "consonant": "h",
        "rhyme": "oau"
    },
    {
        "id": 3068,
        "syllable": "qi",
        "consonant": "q",
        "rhyme": "i"
    },
    {
        "id": 3069,
        "syllable": "quy",
        "consonant": "q",
        "rhyme": "uy"
    },
    {
        "id": 3070,
        "syllable": "qich",
        "consonant": "q",
        "rhyme": "ich"
    },
    {
        "id": 3071,
        "syllable": "quych",
        "consonant": "q",
        "rhyme": "uych"
    },
    {
        "id": 3072,
        "syllable": "qinh",
        "consonant": "q",
        "rhyme": "inh"
    },
    {
        "id": 3073,
        "syllable": "quynh",
        "consonant": "q",
        "rhyme": "uynh"
    },
    {
        "id": 3074,
        "syllable": "qit",
        "consonant": "q",
        "rhyme": "it"
    },
    {
        "id": 3075,
        "syllable": "quyt",
        "consonant": "q",
        "rhyme": "uyt"
    },
    {
        "id": 3076,
        "syllable": "qin",
        "consonant": "q",
        "rhyme": "in"
    },
    {
        "id": 3077,
        "syllable": "quyn",
        "consonant": "q",
        "rhyme": "uyn"
    },
    {
        "id": 3078,
        "syllable": "qip",
        "consonant": "q",
        "rhyme": "ip"
    },
    {
        "id": 3079,
        "syllable": "quyp",
        "consonant": "q",
        "rhyme": "uyp"
    },
    {
        "id": 3080,
        "syllable": "qim",
        "consonant": "q",
        "rhyme": "im"
    },
    {
        "id": 3081,
        "syllable": "quym",
        "consonant": "q",
        "rhyme": "uym"
    },
    {
        "id": 3082,
        "syllable": "qiu",
        "consonant": "q",
        "rhyme": "iu"
    },
    {
        "id": 3083,
        "syllable": "quyu",
        "consonant": "q",
        "rhyme": "uyu"
    },
    {
        "id": 3084,
        "syllable": "qê",
        "consonant": "q",
        "rhyme": "ê"
    },
    {
        "id": 3085,
        "syllable": "quê",
        "consonant": "q",
        "rhyme": "uê"
    },
    {
        "id": 3086,
        "syllable": "qêch",
        "consonant": "q",
        "rhyme": "êch"
    },
    {
        "id": 3087,
        "syllable": "quêch",
        "consonant": "q",
        "rhyme": "uêch"
    },
    {
        "id": 3088,
        "syllable": "qênh",
        "consonant": "q",
        "rhyme": "ênh"
    },
    {
        "id": 3089,
        "syllable": "quênh",
        "consonant": "q",
        "rhyme": "uênh"
    },
    {
        "id": 3090,
        "syllable": "qêt",
        "consonant": "q",
        "rhyme": "êt"
    },
    {
        "id": 3091,
        "syllable": "quêt",
        "consonant": "q",
        "rhyme": "uêt"
    },
    {
        "id": 3092,
        "syllable": "qên",
        "consonant": "q",
        "rhyme": "ên"
    },
    {
        "id": 3093,
        "syllable": "quên",
        "consonant": "q",
        "rhyme": "uên"
    },
    {
        "id": 3094,
        "syllable": "qêp",
        "consonant": "q",
        "rhyme": "êp"
    },
    {
        "id": 3095,
        "syllable": "quêp",
        "consonant": "q",
        "rhyme": "uêp"
    },
    {
        "id": 3096,
        "syllable": "qêm",
        "consonant": "q",
        "rhyme": "êm"
    },
    {
        "id": 3097,
        "syllable": "quêm",
        "consonant": "q",
        "rhyme": "uêm"
    },
    {
        "id": 3098,
        "syllable": "qêu",
        "consonant": "q",
        "rhyme": "êu"
    },
    {
        "id": 3099,
        "syllable": "qe",
        "consonant": "q",
        "rhyme": "e"
    },
    {
        "id": 3100,
        "syllable": "qoe",
        "consonant": "q",
        "rhyme": "oe"
    },
    {
        "id": 3101,
        "syllable": "qec",
        "consonant": "q",
        "rhyme": "ec"
    },
    {
        "id": 3102,
        "syllable": "qoec",
        "consonant": "q",
        "rhyme": "oec"
    },
    {
        "id": 3103,
        "syllable": "qeng",
        "consonant": "q",
        "rhyme": "eng"
    },
    {
        "id": 3104,
        "syllable": "qoeng",
        "consonant": "q",
        "rhyme": "oeng"
    },
    {
        "id": 3105,
        "syllable": "qet",
        "consonant": "q",
        "rhyme": "et"
    },
    {
        "id": 3106,
        "syllable": "qoet",
        "consonant": "q",
        "rhyme": "oet"
    },
    {
        "id": 3107,
        "syllable": "qen",
        "consonant": "q",
        "rhyme": "en"
    },
    {
        "id": 3108,
        "syllable": "qoen",
        "consonant": "q",
        "rhyme": "oen"
    },
    {
        "id": 3109,
        "syllable": "qep",
        "consonant": "q",
        "rhyme": "ep"
    },
    {
        "id": 3110,
        "syllable": "qoep",
        "consonant": "q",
        "rhyme": "oep"
    },
    {
        "id": 3111,
        "syllable": "qem",
        "consonant": "q",
        "rhyme": "em"
    },
    {
        "id": 3112,
        "syllable": "qoem",
        "consonant": "q",
        "rhyme": "oem"
    },
    {
        "id": 3113,
        "syllable": "qeo",
        "consonant": "q",
        "rhyme": "eo"
    },
    {
        "id": 3114,
        "syllable": "qoeo",
        "consonant": "q",
        "rhyme": "oeo"
    },
    {
        "id": 3115,
        "syllable": "qach",
        "consonant": "q",
        "rhyme": "ach"
    },
    {
        "id": 3116,
        "syllable": "qoach",
        "consonant": "q",
        "rhyme": "oach"
    },
    {
        "id": 3117,
        "syllable": "qanh",
        "consonant": "q",
        "rhyme": "anh"
    },
    {
        "id": 3118,
        "syllable": "qoanh",
        "consonant": "q",
        "rhyme": "oanh"
    },
    {
        "id": 3119,
        "syllable": "qia",
        "consonant": "q",
        "rhyme": "ia"
    },
    {
        "id": 3120,
        "syllable": "quya",
        "consonant": "q",
        "rhyme": "uya"
    },
    {
        "id": 3121,
        "syllable": "qiêc",
        "consonant": "q",
        "rhyme": "iêc"
    },
    {
        "id": 3122,
        "syllable": "qiêng",
        "consonant": "q",
        "rhyme": "iêng"
    },
    {
        "id": 3123,
        "syllable": "qiêt",
        "consonant": "q",
        "rhyme": "iêt"
    },
    {
        "id": 3124,
        "syllable": "qiên",
        "consonant": "q",
        "rhyme": "iên"
    },
    {
        "id": 3125,
        "syllable": "quyêt",
        "consonant": "q",
        "rhyme": "uyêt"
    },
    {
        "id": 3126,
        "syllable": "quyên",
        "consonant": "q",
        "rhyme": "uyên"
    },
    {
        "id": 3127,
        "syllable": "qiêp",
        "consonant": "q",
        "rhyme": "iêp"
    },
    {
        "id": 3128,
        "syllable": "quyêp",
        "consonant": "q",
        "rhyme": "uyêp"
    },
    {
        "id": 3129,
        "syllable": "qiêm",
        "consonant": "q",
        "rhyme": "iêm"
    },
    {
        "id": 3130,
        "syllable": "quyêm",
        "consonant": "q",
        "rhyme": "uyêm"
    },
    {
        "id": 3131,
        "syllable": "qiêu",
        "consonant": "q",
        "rhyme": "iêu"
    },
    {
        "id": 3132,
        "syllable": "qư",
        "consonant": "q",
        "rhyme": "ư"
    },
    {
        "id": 3133,
        "syllable": "qưc",
        "consonant": "q",
        "rhyme": "ưc"
    },
    {
        "id": 3134,
        "syllable": "qưng",
        "consonant": "q",
        "rhyme": "ưng"
    },
    {
        "id": 3135,
        "syllable": "qưt",
        "consonant": "q",
        "rhyme": "ưt"
    },
    {
        "id": 3136,
        "syllable": "qưn",
        "consonant": "q",
        "rhyme": "ưn"
    },
    {
        "id": 3137,
        "syllable": "qưp",
        "consonant": "q",
        "rhyme": "ưp"
    },
    {
        "id": 3138,
        "syllable": "qưm",
        "consonant": "q",
        "rhyme": "ưm"
    },
    {
        "id": 3139,
        "syllable": "qưi",
        "consonant": "q",
        "rhyme": "ưi"
    },
    {
        "id": 3140,
        "syllable": "qưu",
        "consonant": "q",
        "rhyme": "ưu"
    },
    {
        "id": 3141,
        "syllable": "qơ",
        "consonant": "q",
        "rhyme": "ơ"
    },
    {
        "id": 3142,
        "syllable": "quơ",
        "consonant": "q",
        "rhyme": "uơ"
    },
    {
        "id": 3143,
        "syllable": "qơng",
        "consonant": "q",
        "rhyme": "ơng"
    },
    {
        "id": 3144,
        "syllable": "qơt",
        "consonant": "q",
        "rhyme": "ơt"
    },
    {
        "id": 3145,
        "syllable": "qơn",
        "consonant": "q",
        "rhyme": "ơn"
    },
    {
        "id": 3146,
        "syllable": "qơp",
        "consonant": "q",
        "rhyme": "ơp"
    },
    {
        "id": 3147,
        "syllable": "qơm",
        "consonant": "q",
        "rhyme": "ơm"
    },
    {
        "id": 3148,
        "syllable": "qơi",
        "consonant": "q",
        "rhyme": "ơi"
    },
    {
        "id": 3149,
        "syllable": "qâc",
        "consonant": "q",
        "rhyme": "âc"
    },
    {
        "id": 3150,
        "syllable": "qâng",
        "consonant": "q",
        "rhyme": "âng"
    },
    {
        "id": 3151,
        "syllable": "quâng",
        "consonant": "q",
        "rhyme": "uâng"
    },
    {
        "id": 3152,
        "syllable": "qât",
        "consonant": "q",
        "rhyme": "ât"
    },
    {
        "id": 3153,
        "syllable": "quât",
        "consonant": "q",
        "rhyme": "uât"
    },
    {
        "id": 3154,
        "syllable": "qân",
        "consonant": "q",
        "rhyme": "ân"
    },
    {
        "id": 3155,
        "syllable": "quân",
        "consonant": "q",
        "rhyme": "uân"
    },
    {
        "id": 3156,
        "syllable": "qâp",
        "consonant": "q",
        "rhyme": "âp"
    },
    {
        "id": 3157,
        "syllable": "quâp",
        "consonant": "q",
        "rhyme": "uâp"
    },
    {
        "id": 3158,
        "syllable": "qâm",
        "consonant": "q",
        "rhyme": "âm"
    },
    {
        "id": 3159,
        "syllable": "quâm",
        "consonant": "q",
        "rhyme": "uâm"
    },
    {
        "id": 3160,
        "syllable": "qây",
        "consonant": "q",
        "rhyme": "ây"
    },
    {
        "id": 3161,
        "syllable": "quây",
        "consonant": "q",
        "rhyme": "uây"
    },
    {
        "id": 3162,
        "syllable": "qâu",
        "consonant": "q",
        "rhyme": "âu"
    },
    {
        "id": 3163,
        "syllable": "quâu",
        "consonant": "q",
        "rhyme": "uâu"
    },
    {
        "id": 3164,
        "syllable": "qu",
        "consonant": "q",
        "rhyme": "u"
    },
    {
        "id": 3165,
        "syllable": "quc",
        "consonant": "q",
        "rhyme": "uc"
    },
    {
        "id": 3166,
        "syllable": "qung",
        "consonant": "q",
        "rhyme": "ung"
    },
    {
        "id": 3167,
        "syllable": "qut",
        "consonant": "q",
        "rhyme": "ut"
    },
    {
        "id": 3168,
        "syllable": "qun",
        "consonant": "q",
        "rhyme": "un"
    },
    {
        "id": 3169,
        "syllable": "qup",
        "consonant": "q",
        "rhyme": "up"
    },
    {
        "id": 3170,
        "syllable": "qum",
        "consonant": "q",
        "rhyme": "um"
    },
    {
        "id": 3171,
        "syllable": "qui",
        "consonant": "q",
        "rhyme": "ui"
    },
    {
        "id": 3172,
        "syllable": "qô",
        "consonant": "q",
        "rhyme": "ô"
    },
    {
        "id": 3173,
        "syllable": "qôc",
        "consonant": "q",
        "rhyme": "ôc"
    },
    {
        "id": 3174,
        "syllable": "qông",
        "consonant": "q",
        "rhyme": "ông"
    },
    {
        "id": 3175,
        "syllable": "qôt",
        "consonant": "q",
        "rhyme": "ôt"
    },
    {
        "id": 3176,
        "syllable": "qôn",
        "consonant": "q",
        "rhyme": "ôn"
    },
    {
        "id": 3177,
        "syllable": "qôp",
        "consonant": "q",
        "rhyme": "ôp"
    },
    {
        "id": 3178,
        "syllable": "qôm",
        "consonant": "q",
        "rhyme": "ôm"
    },
    {
        "id": 3179,
        "syllable": "qôi",
        "consonant": "q",
        "rhyme": "ôi"
    },
    {
        "id": 3180,
        "syllable": "qo",
        "consonant": "q",
        "rhyme": "o"
    },
    {
        "id": 3181,
        "syllable": "qooc",
        "consonant": "q",
        "rhyme": "ooc"
    },
    {
        "id": 3182,
        "syllable": "qoong",
        "consonant": "q",
        "rhyme": "oong"
    },
    {
        "id": 3183,
        "syllable": "qot",
        "consonant": "q",
        "rhyme": "ot"
    },
    {
        "id": 3184,
        "syllable": "qon",
        "consonant": "q",
        "rhyme": "on"
    },
    {
        "id": 3185,
        "syllable": "qop",
        "consonant": "q",
        "rhyme": "op"
    },
    {
        "id": 3186,
        "syllable": "qom",
        "consonant": "q",
        "rhyme": "om"
    },
    {
        "id": 3187,
        "syllable": "qoi",
        "consonant": "q",
        "rhyme": "oi"
    },
    {
        "id": 3188,
        "syllable": "qoc",
        "consonant": "q",
        "rhyme": "oc"
    },
    {
        "id": 3189,
        "syllable": "qong",
        "consonant": "q",
        "rhyme": "ong"
    },
    {
        "id": 3190,
        "syllable": "qua",
        "consonant": "q",
        "rhyme": "ua"
    },
    {
        "id": 3191,
        "syllable": "quôc",
        "consonant": "q",
        "rhyme": "uôc"
    },
    {
        "id": 3192,
        "syllable": "quông",
        "consonant": "q",
        "rhyme": "uông"
    },
    {
        "id": 3193,
        "syllable": "quôt",
        "consonant": "q",
        "rhyme": "uôt"
    },
    {
        "id": 3194,
        "syllable": "quôn",
        "consonant": "q",
        "rhyme": "uôn"
    },
    {
        "id": 3195,
        "syllable": "quôp",
        "consonant": "q",
        "rhyme": "uôp"
    },
    {
        "id": 3196,
        "syllable": "quôm",
        "consonant": "q",
        "rhyme": "uôm"
    },
    {
        "id": 3197,
        "syllable": "quôi",
        "consonant": "q",
        "rhyme": "uôi"
    },
    {
        "id": 3198,
        "syllable": "qưa",
        "consonant": "q",
        "rhyme": "ưa"
    },
    {
        "id": 3199,
        "syllable": "qươc",
        "consonant": "q",
        "rhyme": "ươc"
    },
    {
        "id": 3200,
        "syllable": "qương",
        "consonant": "q",
        "rhyme": "ương"
    },
    {
        "id": 3201,
        "syllable": "qươt",
        "consonant": "q",
        "rhyme": "ươt"
    },
    {
        "id": 3202,
        "syllable": "qươn",
        "consonant": "q",
        "rhyme": "ươn"
    },
    {
        "id": 3203,
        "syllable": "qươp",
        "consonant": "q",
        "rhyme": "ươp"
    },
    {
        "id": 3204,
        "syllable": "qươm",
        "consonant": "q",
        "rhyme": "ươm"
    },
    {
        "id": 3205,
        "syllable": "qươi",
        "consonant": "q",
        "rhyme": "ươi"
    },
    {
        "id": 3206,
        "syllable": "qa",
        "consonant": "q",
        "rhyme": "a"
    },
    {
        "id": 3207,
        "syllable": "qoa",
        "consonant": "q",
        "rhyme": "oa"
    },
    {
        "id": 3208,
        "syllable": "qac",
        "consonant": "q",
        "rhyme": "ac"
    },
    {
        "id": 3209,
        "syllable": "qoac",
        "consonant": "q",
        "rhyme": "oac"
    },
    {
        "id": 3210,
        "syllable": "qang",
        "consonant": "q",
        "rhyme": "ang"
    },
    {
        "id": 3211,
        "syllable": "qoang",
        "consonant": "q",
        "rhyme": "oang"
    },
    {
        "id": 3212,
        "syllable": "qat",
        "consonant": "q",
        "rhyme": "at"
    },
    {
        "id": 3213,
        "syllable": "qoat",
        "consonant": "q",
        "rhyme": "oat"
    },
    {
        "id": 3214,
        "syllable": "qan",
        "consonant": "q",
        "rhyme": "an"
    },
    {
        "id": 3215,
        "syllable": "qoan",
        "consonant": "q",
        "rhyme": "oan"
    },
    {
        "id": 3216,
        "syllable": "qap",
        "consonant": "q",
        "rhyme": "ap"
    },
    {
        "id": 3217,
        "syllable": "qoap",
        "consonant": "q",
        "rhyme": "oap"
    },
    {
        "id": 3218,
        "syllable": "qam",
        "consonant": "q",
        "rhyme": "am"
    },
    {
        "id": 3219,
        "syllable": "qoam",
        "consonant": "q",
        "rhyme": "oam"
    },
    {
        "id": 3220,
        "syllable": "qai",
        "consonant": "q",
        "rhyme": "ai"
    },
    {
        "id": 3221,
        "syllable": "qoai",
        "consonant": "q",
        "rhyme": "oai"
    },
    {
        "id": 3222,
        "syllable": "qao",
        "consonant": "q",
        "rhyme": "ao"
    },
    {
        "id": 3223,
        "syllable": "qoao",
        "consonant": "q",
        "rhyme": "oao"
    },
    {
        "id": 3224,
        "syllable": "qăc",
        "consonant": "q",
        "rhyme": "ăc"
    },
    {
        "id": 3225,
        "syllable": "qoăc",
        "consonant": "q",
        "rhyme": "oăc"
    },
    {
        "id": 3226,
        "syllable": "qăng",
        "consonant": "q",
        "rhyme": "ăng"
    },
    {
        "id": 3227,
        "syllable": "qoăng",
        "consonant": "q",
        "rhyme": "oăng"
    },
    {
        "id": 3228,
        "syllable": "qăt",
        "consonant": "q",
        "rhyme": "ăt"
    },
    {
        "id": 3229,
        "syllable": "qoăt",
        "consonant": "q",
        "rhyme": "oăt"
    },
    {
        "id": 3230,
        "syllable": "qăn",
        "consonant": "q",
        "rhyme": "ăn"
    },
    {
        "id": 3231,
        "syllable": "qoăn",
        "consonant": "q",
        "rhyme": "oăn"
    },
    {
        "id": 3232,
        "syllable": "qăp",
        "consonant": "q",
        "rhyme": "ăp"
    },
    {
        "id": 3233,
        "syllable": "qoăp",
        "consonant": "q",
        "rhyme": "oăp"
    },
    {
        "id": 3234,
        "syllable": "qăm",
        "consonant": "q",
        "rhyme": "ăm"
    },
    {
        "id": 3235,
        "syllable": "qoăm",
        "consonant": "q",
        "rhyme": "oăm"
    },
    {
        "id": 3236,
        "syllable": "qay",
        "consonant": "q",
        "rhyme": "ay"
    },
    {
        "id": 3237,
        "syllable": "qoay",
        "consonant": "q",
        "rhyme": "oay"
    },
    {
        "id": 3238,
        "syllable": "qau",
        "consonant": "q",
        "rhyme": "au"
    },
    {
        "id": 3239,
        "syllable": "qoau",
        "consonant": "q",
        "rhyme": "oau"
    },
    {
        "id": 3240,
        "syllable": "ti",
        "consonant": "t",
        "rhyme": "i"
    },
    {
        "id": 3241,
        "syllable": "tuy",
        "consonant": "t",
        "rhyme": "uy"
    },
    {
        "id": 3242,
        "syllable": "tich",
        "consonant": "t",
        "rhyme": "ich"
    },
    {
        "id": 3243,
        "syllable": "tuych",
        "consonant": "t",
        "rhyme": "uych"
    },
    {
        "id": 3244,
        "syllable": "tinh",
        "consonant": "t",
        "rhyme": "inh"
    },
    {
        "id": 3245,
        "syllable": "tuynh",
        "consonant": "t",
        "rhyme": "uynh"
    },
    {
        "id": 3246,
        "syllable": "tit",
        "consonant": "t",
        "rhyme": "it"
    },
    {
        "id": 3247,
        "syllable": "tuyt",
        "consonant": "t",
        "rhyme": "uyt"
    },
    {
        "id": 3248,
        "syllable": "tin",
        "consonant": "t",
        "rhyme": "in"
    },
    {
        "id": 3249,
        "syllable": "tuyn",
        "consonant": "t",
        "rhyme": "uyn"
    },
    {
        "id": 3250,
        "syllable": "tip",
        "consonant": "t",
        "rhyme": "ip"
    },
    {
        "id": 3251,
        "syllable": "tuyp",
        "consonant": "t",
        "rhyme": "uyp"
    },
    {
        "id": 3252,
        "syllable": "tim",
        "consonant": "t",
        "rhyme": "im"
    },
    {
        "id": 3253,
        "syllable": "tuym",
        "consonant": "t",
        "rhyme": "uym"
    },
    {
        "id": 3254,
        "syllable": "tiu",
        "consonant": "t",
        "rhyme": "iu"
    },
    {
        "id": 3255,
        "syllable": "tuyu",
        "consonant": "t",
        "rhyme": "uyu"
    },
    {
        "id": 3256,
        "syllable": "tê",
        "consonant": "t",
        "rhyme": "ê"
    },
    {
        "id": 3257,
        "syllable": "tuê",
        "consonant": "t",
        "rhyme": "uê"
    },
    {
        "id": 3258,
        "syllable": "têch",
        "consonant": "t",
        "rhyme": "êch"
    },
    {
        "id": 3259,
        "syllable": "tuêch",
        "consonant": "t",
        "rhyme": "uêch"
    },
    {
        "id": 3260,
        "syllable": "tênh",
        "consonant": "t",
        "rhyme": "ênh"
    },
    {
        "id": 3261,
        "syllable": "tuênh",
        "consonant": "t",
        "rhyme": "uênh"
    },
    {
        "id": 3262,
        "syllable": "têt",
        "consonant": "t",
        "rhyme": "êt"
    },
    {
        "id": 3263,
        "syllable": "tuêt",
        "consonant": "t",
        "rhyme": "uêt"
    },
    {
        "id": 3264,
        "syllable": "tên",
        "consonant": "t",
        "rhyme": "ên"
    },
    {
        "id": 3265,
        "syllable": "tuên",
        "consonant": "t",
        "rhyme": "uên"
    },
    {
        "id": 3266,
        "syllable": "têp",
        "consonant": "t",
        "rhyme": "êp"
    },
    {
        "id": 3267,
        "syllable": "tuêp",
        "consonant": "t",
        "rhyme": "uêp"
    },
    {
        "id": 3268,
        "syllable": "têm",
        "consonant": "t",
        "rhyme": "êm"
    },
    {
        "id": 3269,
        "syllable": "tuêm",
        "consonant": "t",
        "rhyme": "uêm"
    },
    {
        "id": 3270,
        "syllable": "têu",
        "consonant": "t",
        "rhyme": "êu"
    },
    {
        "id": 3271,
        "syllable": "te",
        "consonant": "t",
        "rhyme": "e"
    },
    {
        "id": 3272,
        "syllable": "toe",
        "consonant": "t",
        "rhyme": "oe"
    },
    {
        "id": 3273,
        "syllable": "tec",
        "consonant": "t",
        "rhyme": "ec"
    },
    {
        "id": 3274,
        "syllable": "toec",
        "consonant": "t",
        "rhyme": "oec"
    },
    {
        "id": 3275,
        "syllable": "teng",
        "consonant": "t",
        "rhyme": "eng"
    },
    {
        "id": 3276,
        "syllable": "toeng",
        "consonant": "t",
        "rhyme": "oeng"
    },
    {
        "id": 3277,
        "syllable": "tet",
        "consonant": "t",
        "rhyme": "et"
    },
    {
        "id": 3278,
        "syllable": "toet",
        "consonant": "t",
        "rhyme": "oet"
    },
    {
        "id": 3279,
        "syllable": "ten",
        "consonant": "t",
        "rhyme": "en"
    },
    {
        "id": 3280,
        "syllable": "toen",
        "consonant": "t",
        "rhyme": "oen"
    },
    {
        "id": 3281,
        "syllable": "tep",
        "consonant": "t",
        "rhyme": "ep"
    },
    {
        "id": 3282,
        "syllable": "toep",
        "consonant": "t",
        "rhyme": "oep"
    },
    {
        "id": 3283,
        "syllable": "tem",
        "consonant": "t",
        "rhyme": "em"
    },
    {
        "id": 3284,
        "syllable": "toem",
        "consonant": "t",
        "rhyme": "oem"
    },
    {
        "id": 3285,
        "syllable": "teo",
        "consonant": "t",
        "rhyme": "eo"
    },
    {
        "id": 3286,
        "syllable": "toeo",
        "consonant": "t",
        "rhyme": "oeo"
    },
    {
        "id": 3287,
        "syllable": "tach",
        "consonant": "t",
        "rhyme": "ach"
    },
    {
        "id": 3288,
        "syllable": "toach",
        "consonant": "t",
        "rhyme": "oach"
    },
    {
        "id": 3289,
        "syllable": "tanh",
        "consonant": "t",
        "rhyme": "anh"
    },
    {
        "id": 3290,
        "syllable": "toanh",
        "consonant": "t",
        "rhyme": "oanh"
    },
    {
        "id": 3291,
        "syllable": "tia",
        "consonant": "t",
        "rhyme": "ia"
    },
    {
        "id": 3292,
        "syllable": "tuya",
        "consonant": "t",
        "rhyme": "uya"
    },
    {
        "id": 3293,
        "syllable": "tiêc",
        "consonant": "t",
        "rhyme": "iêc"
    },
    {
        "id": 3294,
        "syllable": "tiêng",
        "consonant": "t",
        "rhyme": "iêng"
    },
    {
        "id": 3295,
        "syllable": "tiêt",
        "consonant": "t",
        "rhyme": "iêt"
    },
    {
        "id": 3296,
        "syllable": "tiên",
        "consonant": "t",
        "rhyme": "iên"
    },
    {
        "id": 3297,
        "syllable": "tuyêt",
        "consonant": "t",
        "rhyme": "uyêt"
    },
    {
        "id": 3298,
        "syllable": "tuyên",
        "consonant": "t",
        "rhyme": "uyên"
    },
    {
        "id": 3299,
        "syllable": "tiêp",
        "consonant": "t",
        "rhyme": "iêp"
    },
    {
        "id": 3300,
        "syllable": "tuyêp",
        "consonant": "t",
        "rhyme": "uyêp"
    },
    {
        "id": 3301,
        "syllable": "tiêm",
        "consonant": "t",
        "rhyme": "iêm"
    },
    {
        "id": 3302,
        "syllable": "tuyêm",
        "consonant": "t",
        "rhyme": "uyêm"
    },
    {
        "id": 3303,
        "syllable": "tiêu",
        "consonant": "t",
        "rhyme": "iêu"
    },
    {
        "id": 3304,
        "syllable": "tư",
        "consonant": "t",
        "rhyme": "ư"
    },
    {
        "id": 3305,
        "syllable": "tưc",
        "consonant": "t",
        "rhyme": "ưc"
    },
    {
        "id": 3306,
        "syllable": "tưng",
        "consonant": "t",
        "rhyme": "ưng"
    },
    {
        "id": 3307,
        "syllable": "tưt",
        "consonant": "t",
        "rhyme": "ưt"
    },
    {
        "id": 3308,
        "syllable": "tưn",
        "consonant": "t",
        "rhyme": "ưn"
    },
    {
        "id": 3309,
        "syllable": "tưp",
        "consonant": "t",
        "rhyme": "ưp"
    },
    {
        "id": 3310,
        "syllable": "tưm",
        "consonant": "t",
        "rhyme": "ưm"
    },
    {
        "id": 3311,
        "syllable": "tưi",
        "consonant": "t",
        "rhyme": "ưi"
    },
    {
        "id": 3312,
        "syllable": "tưu",
        "consonant": "t",
        "rhyme": "ưu"
    },
    {
        "id": 3313,
        "syllable": "tơ",
        "consonant": "t",
        "rhyme": "ơ"
    },
    {
        "id": 3314,
        "syllable": "tuơ",
        "consonant": "t",
        "rhyme": "uơ"
    },
    {
        "id": 3315,
        "syllable": "tơng",
        "consonant": "t",
        "rhyme": "ơng"
    },
    {
        "id": 3316,
        "syllable": "tơt",
        "consonant": "t",
        "rhyme": "ơt"
    },
    {
        "id": 3317,
        "syllable": "tơn",
        "consonant": "t",
        "rhyme": "ơn"
    },
    {
        "id": 3318,
        "syllable": "tơp",
        "consonant": "t",
        "rhyme": "ơp"
    },
    {
        "id": 3319,
        "syllable": "tơm",
        "consonant": "t",
        "rhyme": "ơm"
    },
    {
        "id": 3320,
        "syllable": "tơi",
        "consonant": "t",
        "rhyme": "ơi"
    },
    {
        "id": 3321,
        "syllable": "tâc",
        "consonant": "t",
        "rhyme": "âc"
    },
    {
        "id": 3322,
        "syllable": "tâng",
        "consonant": "t",
        "rhyme": "âng"
    },
    {
        "id": 3323,
        "syllable": "tuâng",
        "consonant": "t",
        "rhyme": "uâng"
    },
    {
        "id": 3324,
        "syllable": "tât",
        "consonant": "t",
        "rhyme": "ât"
    },
    {
        "id": 3325,
        "syllable": "tuât",
        "consonant": "t",
        "rhyme": "uât"
    },
    {
        "id": 3326,
        "syllable": "tân",
        "consonant": "t",
        "rhyme": "ân"
    },
    {
        "id": 3327,
        "syllable": "tuân",
        "consonant": "t",
        "rhyme": "uân"
    },
    {
        "id": 3328,
        "syllable": "tâp",
        "consonant": "t",
        "rhyme": "âp"
    },
    {
        "id": 3329,
        "syllable": "tuâp",
        "consonant": "t",
        "rhyme": "uâp"
    },
    {
        "id": 3330,
        "syllable": "tâm",
        "consonant": "t",
        "rhyme": "âm"
    },
    {
        "id": 3331,
        "syllable": "tuâm",
        "consonant": "t",
        "rhyme": "uâm"
    },
    {
        "id": 3332,
        "syllable": "tây",
        "consonant": "t",
        "rhyme": "ây"
    },
    {
        "id": 3333,
        "syllable": "tuây",
        "consonant": "t",
        "rhyme": "uây"
    },
    {
        "id": 3334,
        "syllable": "tâu",
        "consonant": "t",
        "rhyme": "âu"
    },
    {
        "id": 3335,
        "syllable": "tuâu",
        "consonant": "t",
        "rhyme": "uâu"
    },
    {
        "id": 3336,
        "syllable": "tu",
        "consonant": "t",
        "rhyme": "u"
    },
    {
        "id": 3337,
        "syllable": "tuc",
        "consonant": "t",
        "rhyme": "uc"
    },
    {
        "id": 3338,
        "syllable": "tung",
        "consonant": "t",
        "rhyme": "ung"
    },
    {
        "id": 3339,
        "syllable": "tut",
        "consonant": "t",
        "rhyme": "ut"
    },
    {
        "id": 3340,
        "syllable": "tun",
        "consonant": "t",
        "rhyme": "un"
    },
    {
        "id": 3341,
        "syllable": "tup",
        "consonant": "t",
        "rhyme": "up"
    },
    {
        "id": 3342,
        "syllable": "tum",
        "consonant": "t",
        "rhyme": "um"
    },
    {
        "id": 3343,
        "syllable": "tui",
        "consonant": "t",
        "rhyme": "ui"
    },
    {
        "id": 3344,
        "syllable": "tô",
        "consonant": "t",
        "rhyme": "ô"
    },
    {
        "id": 3345,
        "syllable": "tôc",
        "consonant": "t",
        "rhyme": "ôc"
    },
    {
        "id": 3346,
        "syllable": "tông",
        "consonant": "t",
        "rhyme": "ông"
    },
    {
        "id": 3347,
        "syllable": "tôt",
        "consonant": "t",
        "rhyme": "ôt"
    },
    {
        "id": 3348,
        "syllable": "tôn",
        "consonant": "t",
        "rhyme": "ôn"
    },
    {
        "id": 3349,
        "syllable": "tôp",
        "consonant": "t",
        "rhyme": "ôp"
    },
    {
        "id": 3350,
        "syllable": "tôm",
        "consonant": "t",
        "rhyme": "ôm"
    },
    {
        "id": 3351,
        "syllable": "tôi",
        "consonant": "t",
        "rhyme": "ôi"
    },
    {
        "id": 3352,
        "syllable": "to",
        "consonant": "t",
        "rhyme": "o"
    },
    {
        "id": 3353,
        "syllable": "tooc",
        "consonant": "t",
        "rhyme": "ooc"
    },
    {
        "id": 3354,
        "syllable": "toong",
        "consonant": "t",
        "rhyme": "oong"
    },
    {
        "id": 3355,
        "syllable": "tot",
        "consonant": "t",
        "rhyme": "ot"
    },
    {
        "id": 3356,
        "syllable": "ton",
        "consonant": "t",
        "rhyme": "on"
    },
    {
        "id": 3357,
        "syllable": "top",
        "consonant": "t",
        "rhyme": "op"
    },
    {
        "id": 3358,
        "syllable": "tom",
        "consonant": "t",
        "rhyme": "om"
    },
    {
        "id": 3359,
        "syllable": "toi",
        "consonant": "t",
        "rhyme": "oi"
    },
    {
        "id": 3360,
        "syllable": "toc",
        "consonant": "t",
        "rhyme": "oc"
    },
    {
        "id": 3361,
        "syllable": "tong",
        "consonant": "t",
        "rhyme": "ong"
    },
    {
        "id": 3362,
        "syllable": "tua",
        "consonant": "t",
        "rhyme": "ua"
    },
    {
        "id": 3363,
        "syllable": "tuôc",
        "consonant": "t",
        "rhyme": "uôc"
    },
    {
        "id": 3364,
        "syllable": "tuông",
        "consonant": "t",
        "rhyme": "uông"
    },
    {
        "id": 3365,
        "syllable": "tuôt",
        "consonant": "t",
        "rhyme": "uôt"
    },
    {
        "id": 3366,
        "syllable": "tuôn",
        "consonant": "t",
        "rhyme": "uôn"
    },
    {
        "id": 3367,
        "syllable": "tuôp",
        "consonant": "t",
        "rhyme": "uôp"
    },
    {
        "id": 3368,
        "syllable": "tuôm",
        "consonant": "t",
        "rhyme": "uôm"
    },
    {
        "id": 3369,
        "syllable": "tuôi",
        "consonant": "t",
        "rhyme": "uôi"
    },
    {
        "id": 3370,
        "syllable": "tưa",
        "consonant": "t",
        "rhyme": "ưa"
    },
    {
        "id": 3371,
        "syllable": "tươc",
        "consonant": "t",
        "rhyme": "ươc"
    },
    {
        "id": 3372,
        "syllable": "tương",
        "consonant": "t",
        "rhyme": "ương"
    },
    {
        "id": 3373,
        "syllable": "tươt",
        "consonant": "t",
        "rhyme": "ươt"
    },
    {
        "id": 3374,
        "syllable": "tươn",
        "consonant": "t",
        "rhyme": "ươn"
    },
    {
        "id": 3375,
        "syllable": "tươp",
        "consonant": "t",
        "rhyme": "ươp"
    },
    {
        "id": 3376,
        "syllable": "tươm",
        "consonant": "t",
        "rhyme": "ươm"
    },
    {
        "id": 3377,
        "syllable": "tươi",
        "consonant": "t",
        "rhyme": "ươi"
    },
    {
        "id": 3378,
        "syllable": "ta",
        "consonant": "t",
        "rhyme": "a"
    },
    {
        "id": 3379,
        "syllable": "toa",
        "consonant": "t",
        "rhyme": "oa"
    },
    {
        "id": 3380,
        "syllable": "tac",
        "consonant": "t",
        "rhyme": "ac"
    },
    {
        "id": 3381,
        "syllable": "toac",
        "consonant": "t",
        "rhyme": "oac"
    },
    {
        "id": 3382,
        "syllable": "tang",
        "consonant": "t",
        "rhyme": "ang"
    },
    {
        "id": 3383,
        "syllable": "toang",
        "consonant": "t",
        "rhyme": "oang"
    },
    {
        "id": 3384,
        "syllable": "tat",
        "consonant": "t",
        "rhyme": "at"
    },
    {
        "id": 3385,
        "syllable": "toat",
        "consonant": "t",
        "rhyme": "oat"
    },
    {
        "id": 3386,
        "syllable": "tan",
        "consonant": "t",
        "rhyme": "an"
    },
    {
        "id": 3387,
        "syllable": "toan",
        "consonant": "t",
        "rhyme": "oan"
    },
    {
        "id": 3388,
        "syllable": "tap",
        "consonant": "t",
        "rhyme": "ap"
    },
    {
        "id": 3389,
        "syllable": "toap",
        "consonant": "t",
        "rhyme": "oap"
    },
    {
        "id": 3390,
        "syllable": "tam",
        "consonant": "t",
        "rhyme": "am"
    },
    {
        "id": 3391,
        "syllable": "toam",
        "consonant": "t",
        "rhyme": "oam"
    },
    {
        "id": 3392,
        "syllable": "tai",
        "consonant": "t",
        "rhyme": "ai"
    },
    {
        "id": 3393,
        "syllable": "toai",
        "consonant": "t",
        "rhyme": "oai"
    },
    {
        "id": 3394,
        "syllable": "tao",
        "consonant": "t",
        "rhyme": "ao"
    },
    {
        "id": 3395,
        "syllable": "toao",
        "consonant": "t",
        "rhyme": "oao"
    },
    {
        "id": 3396,
        "syllable": "tăc",
        "consonant": "t",
        "rhyme": "ăc"
    },
    {
        "id": 3397,
        "syllable": "toăc",
        "consonant": "t",
        "rhyme": "oăc"
    },
    {
        "id": 3398,
        "syllable": "tăng",
        "consonant": "t",
        "rhyme": "ăng"
    },
    {
        "id": 3399,
        "syllable": "toăng",
        "consonant": "t",
        "rhyme": "oăng"
    },
    {
        "id": 3400,
        "syllable": "tăt",
        "consonant": "t",
        "rhyme": "ăt"
    },
    {
        "id": 3401,
        "syllable": "toăt",
        "consonant": "t",
        "rhyme": "oăt"
    },
    {
        "id": 3402,
        "syllable": "tăn",
        "consonant": "t",
        "rhyme": "ăn"
    },
    {
        "id": 3403,
        "syllable": "toăn",
        "consonant": "t",
        "rhyme": "oăn"
    },
    {
        "id": 3404,
        "syllable": "tăp",
        "consonant": "t",
        "rhyme": "ăp"
    },
    {
        "id": 3405,
        "syllable": "toăp",
        "consonant": "t",
        "rhyme": "oăp"
    },
    {
        "id": 3406,
        "syllable": "tăm",
        "consonant": "t",
        "rhyme": "ăm"
    },
    {
        "id": 3407,
        "syllable": "toăm",
        "consonant": "t",
        "rhyme": "oăm"
    },
    {
        "id": 3408,
        "syllable": "tay",
        "consonant": "t",
        "rhyme": "ay"
    },
    {
        "id": 3409,
        "syllable": "toay",
        "consonant": "t",
        "rhyme": "oay"
    },
    {
        "id": 3410,
        "syllable": "tau",
        "consonant": "t",
        "rhyme": "au"
    },
    {
        "id": 3411,
        "syllable": "toau",
        "consonant": "t",
        "rhyme": "oau"
    },
    {
        "id": 3412,
        "syllable": "pi",
        "consonant": "p",
        "rhyme": "i"
    },
    {
        "id": 3413,
        "syllable": "puy",
        "consonant": "p",
        "rhyme": "uy"
    },
    {
        "id": 3414,
        "syllable": "pich",
        "consonant": "p",
        "rhyme": "ich"
    },
    {
        "id": 3415,
        "syllable": "puych",
        "consonant": "p",
        "rhyme": "uych"
    },
    {
        "id": 3416,
        "syllable": "pinh",
        "consonant": "p",
        "rhyme": "inh"
    },
    {
        "id": 3417,
        "syllable": "puynh",
        "consonant": "p",
        "rhyme": "uynh"
    },
    {
        "id": 3418,
        "syllable": "pit",
        "consonant": "p",
        "rhyme": "it"
    },
    {
        "id": 3419,
        "syllable": "puyt",
        "consonant": "p",
        "rhyme": "uyt"
    },
    {
        "id": 3420,
        "syllable": "pin",
        "consonant": "p",
        "rhyme": "in"
    },
    {
        "id": 3421,
        "syllable": "puyn",
        "consonant": "p",
        "rhyme": "uyn"
    },
    {
        "id": 3422,
        "syllable": "pip",
        "consonant": "p",
        "rhyme": "ip"
    },
    {
        "id": 3423,
        "syllable": "puyp",
        "consonant": "p",
        "rhyme": "uyp"
    },
    {
        "id": 3424,
        "syllable": "pim",
        "consonant": "p",
        "rhyme": "im"
    },
    {
        "id": 3425,
        "syllable": "puym",
        "consonant": "p",
        "rhyme": "uym"
    },
    {
        "id": 3426,
        "syllable": "piu",
        "consonant": "p",
        "rhyme": "iu"
    },
    {
        "id": 3427,
        "syllable": "puyu",
        "consonant": "p",
        "rhyme": "uyu"
    },
    {
        "id": 3428,
        "syllable": "pê",
        "consonant": "p",
        "rhyme": "ê"
    },
    {
        "id": 3429,
        "syllable": "puê",
        "consonant": "p",
        "rhyme": "uê"
    },
    {
        "id": 3430,
        "syllable": "pêch",
        "consonant": "p",
        "rhyme": "êch"
    },
    {
        "id": 3431,
        "syllable": "puêch",
        "consonant": "p",
        "rhyme": "uêch"
    },
    {
        "id": 3432,
        "syllable": "pênh",
        "consonant": "p",
        "rhyme": "ênh"
    },
    {
        "id": 3433,
        "syllable": "puênh",
        "consonant": "p",
        "rhyme": "uênh"
    },
    {
        "id": 3434,
        "syllable": "pêt",
        "consonant": "p",
        "rhyme": "êt"
    },
    {
        "id": 3435,
        "syllable": "puêt",
        "consonant": "p",
        "rhyme": "uêt"
    },
    {
        "id": 3436,
        "syllable": "pên",
        "consonant": "p",
        "rhyme": "ên"
    },
    {
        "id": 3437,
        "syllable": "puên",
        "consonant": "p",
        "rhyme": "uên"
    },
    {
        "id": 3438,
        "syllable": "pêp",
        "consonant": "p",
        "rhyme": "êp"
    },
    {
        "id": 3439,
        "syllable": "puêp",
        "consonant": "p",
        "rhyme": "uêp"
    },
    {
        "id": 3440,
        "syllable": "pêm",
        "consonant": "p",
        "rhyme": "êm"
    },
    {
        "id": 3441,
        "syllable": "puêm",
        "consonant": "p",
        "rhyme": "uêm"
    },
    {
        "id": 3442,
        "syllable": "pêu",
        "consonant": "p",
        "rhyme": "êu"
    },
    {
        "id": 3443,
        "syllable": "pe",
        "consonant": "p",
        "rhyme": "e"
    },
    {
        "id": 3444,
        "syllable": "poe",
        "consonant": "p",
        "rhyme": "oe"
    },
    {
        "id": 3445,
        "syllable": "pec",
        "consonant": "p",
        "rhyme": "ec"
    },
    {
        "id": 3446,
        "syllable": "poec",
        "consonant": "p",
        "rhyme": "oec"
    },
    {
        "id": 3447,
        "syllable": "peng",
        "consonant": "p",
        "rhyme": "eng"
    },
    {
        "id": 3448,
        "syllable": "poeng",
        "consonant": "p",
        "rhyme": "oeng"
    },
    {
        "id": 3449,
        "syllable": "pet",
        "consonant": "p",
        "rhyme": "et"
    },
    {
        "id": 3450,
        "syllable": "poet",
        "consonant": "p",
        "rhyme": "oet"
    },
    {
        "id": 3451,
        "syllable": "pen",
        "consonant": "p",
        "rhyme": "en"
    },
    {
        "id": 3452,
        "syllable": "poen",
        "consonant": "p",
        "rhyme": "oen"
    },
    {
        "id": 3453,
        "syllable": "pep",
        "consonant": "p",
        "rhyme": "ep"
    },
    {
        "id": 3454,
        "syllable": "poep",
        "consonant": "p",
        "rhyme": "oep"
    },
    {
        "id": 3455,
        "syllable": "pem",
        "consonant": "p",
        "rhyme": "em"
    },
    {
        "id": 3456,
        "syllable": "poem",
        "consonant": "p",
        "rhyme": "oem"
    },
    {
        "id": 3457,
        "syllable": "peo",
        "consonant": "p",
        "rhyme": "eo"
    },
    {
        "id": 3458,
        "syllable": "poeo",
        "consonant": "p",
        "rhyme": "oeo"
    },
    {
        "id": 3459,
        "syllable": "pach",
        "consonant": "p",
        "rhyme": "ach"
    },
    {
        "id": 3460,
        "syllable": "poach",
        "consonant": "p",
        "rhyme": "oach"
    },
    {
        "id": 3461,
        "syllable": "panh",
        "consonant": "p",
        "rhyme": "anh"
    },
    {
        "id": 3462,
        "syllable": "poanh",
        "consonant": "p",
        "rhyme": "oanh"
    },
    {
        "id": 3463,
        "syllable": "pia",
        "consonant": "p",
        "rhyme": "ia"
    },
    {
        "id": 3464,
        "syllable": "puya",
        "consonant": "p",
        "rhyme": "uya"
    },
    {
        "id": 3465,
        "syllable": "piêc",
        "consonant": "p",
        "rhyme": "iêc"
    },
    {
        "id": 3466,
        "syllable": "piêng",
        "consonant": "p",
        "rhyme": "iêng"
    },
    {
        "id": 3467,
        "syllable": "piêt",
        "consonant": "p",
        "rhyme": "iêt"
    },
    {
        "id": 3468,
        "syllable": "piên",
        "consonant": "p",
        "rhyme": "iên"
    },
    {
        "id": 3469,
        "syllable": "puyêt",
        "consonant": "p",
        "rhyme": "uyêt"
    },
    {
        "id": 3470,
        "syllable": "puyên",
        "consonant": "p",
        "rhyme": "uyên"
    },
    {
        "id": 3471,
        "syllable": "piêp",
        "consonant": "p",
        "rhyme": "iêp"
    },
    {
        "id": 3472,
        "syllable": "puyêp",
        "consonant": "p",
        "rhyme": "uyêp"
    },
    {
        "id": 3473,
        "syllable": "piêm",
        "consonant": "p",
        "rhyme": "iêm"
    },
    {
        "id": 3474,
        "syllable": "puyêm",
        "consonant": "p",
        "rhyme": "uyêm"
    },
    {
        "id": 3475,
        "syllable": "piêu",
        "consonant": "p",
        "rhyme": "iêu"
    },
    {
        "id": 3476,
        "syllable": "pư",
        "consonant": "p",
        "rhyme": "ư"
    },
    {
        "id": 3477,
        "syllable": "pưc",
        "consonant": "p",
        "rhyme": "ưc"
    },
    {
        "id": 3478,
        "syllable": "pưng",
        "consonant": "p",
        "rhyme": "ưng"
    },
    {
        "id": 3479,
        "syllable": "pưt",
        "consonant": "p",
        "rhyme": "ưt"
    },
    {
        "id": 3480,
        "syllable": "pưn",
        "consonant": "p",
        "rhyme": "ưn"
    },
    {
        "id": 3481,
        "syllable": "pưp",
        "consonant": "p",
        "rhyme": "ưp"
    },
    {
        "id": 3482,
        "syllable": "pưm",
        "consonant": "p",
        "rhyme": "ưm"
    },
    {
        "id": 3483,
        "syllable": "pưi",
        "consonant": "p",
        "rhyme": "ưi"
    },
    {
        "id": 3484,
        "syllable": "pưu",
        "consonant": "p",
        "rhyme": "ưu"
    },
    {
        "id": 3485,
        "syllable": "pơ",
        "consonant": "p",
        "rhyme": "ơ"
    },
    {
        "id": 3486,
        "syllable": "puơ",
        "consonant": "p",
        "rhyme": "uơ"
    },
    {
        "id": 3487,
        "syllable": "pơng",
        "consonant": "p",
        "rhyme": "ơng"
    },
    {
        "id": 3488,
        "syllable": "pơt",
        "consonant": "p",
        "rhyme": "ơt"
    },
    {
        "id": 3489,
        "syllable": "pơn",
        "consonant": "p",
        "rhyme": "ơn"
    },
    {
        "id": 3490,
        "syllable": "pơp",
        "consonant": "p",
        "rhyme": "ơp"
    },
    {
        "id": 3491,
        "syllable": "pơm",
        "consonant": "p",
        "rhyme": "ơm"
    },
    {
        "id": 3492,
        "syllable": "pơi",
        "consonant": "p",
        "rhyme": "ơi"
    },
    {
        "id": 3493,
        "syllable": "pâc",
        "consonant": "p",
        "rhyme": "âc"
    },
    {
        "id": 3494,
        "syllable": "pâng",
        "consonant": "p",
        "rhyme": "âng"
    },
    {
        "id": 3495,
        "syllable": "puâng",
        "consonant": "p",
        "rhyme": "uâng"
    },
    {
        "id": 3496,
        "syllable": "pât",
        "consonant": "p",
        "rhyme": "ât"
    },
    {
        "id": 3497,
        "syllable": "puât",
        "consonant": "p",
        "rhyme": "uât"
    },
    {
        "id": 3498,
        "syllable": "pân",
        "consonant": "p",
        "rhyme": "ân"
    },
    {
        "id": 3499,
        "syllable": "puân",
        "consonant": "p",
        "rhyme": "uân"
    },
    {
        "id": 3500,
        "syllable": "pâp",
        "consonant": "p",
        "rhyme": "âp"
    },
    {
        "id": 3501,
        "syllable": "puâp",
        "consonant": "p",
        "rhyme": "uâp"
    },
    {
        "id": 3502,
        "syllable": "pâm",
        "consonant": "p",
        "rhyme": "âm"
    },
    {
        "id": 3503,
        "syllable": "puâm",
        "consonant": "p",
        "rhyme": "uâm"
    },
    {
        "id": 3504,
        "syllable": "pây",
        "consonant": "p",
        "rhyme": "ây"
    },
    {
        "id": 3505,
        "syllable": "puây",
        "consonant": "p",
        "rhyme": "uây"
    },
    {
        "id": 3506,
        "syllable": "pâu",
        "consonant": "p",
        "rhyme": "âu"
    },
    {
        "id": 3507,
        "syllable": "puâu",
        "consonant": "p",
        "rhyme": "uâu"
    },
    {
        "id": 3508,
        "syllable": "pu",
        "consonant": "p",
        "rhyme": "u"
    },
    {
        "id": 3509,
        "syllable": "puc",
        "consonant": "p",
        "rhyme": "uc"
    },
    {
        "id": 3510,
        "syllable": "pung",
        "consonant": "p",
        "rhyme": "ung"
    },
    {
        "id": 3511,
        "syllable": "put",
        "consonant": "p",
        "rhyme": "ut"
    },
    {
        "id": 3512,
        "syllable": "pun",
        "consonant": "p",
        "rhyme": "un"
    },
    {
        "id": 3513,
        "syllable": "pup",
        "consonant": "p",
        "rhyme": "up"
    },
    {
        "id": 3514,
        "syllable": "pum",
        "consonant": "p",
        "rhyme": "um"
    },
    {
        "id": 3515,
        "syllable": "pui",
        "consonant": "p",
        "rhyme": "ui"
    },
    {
        "id": 3516,
        "syllable": "pô",
        "consonant": "p",
        "rhyme": "ô"
    },
    {
        "id": 3517,
        "syllable": "pôc",
        "consonant": "p",
        "rhyme": "ôc"
    },
    {
        "id": 3518,
        "syllable": "pông",
        "consonant": "p",
        "rhyme": "ông"
    },
    {
        "id": 3519,
        "syllable": "pôt",
        "consonant": "p",
        "rhyme": "ôt"
    },
    {
        "id": 3520,
        "syllable": "pôn",
        "consonant": "p",
        "rhyme": "ôn"
    },
    {
        "id": 3521,
        "syllable": "pôp",
        "consonant": "p",
        "rhyme": "ôp"
    },
    {
        "id": 3522,
        "syllable": "pôm",
        "consonant": "p",
        "rhyme": "ôm"
    },
    {
        "id": 3523,
        "syllable": "pôi",
        "consonant": "p",
        "rhyme": "ôi"
    },
    {
        "id": 3524,
        "syllable": "po",
        "consonant": "p",
        "rhyme": "o"
    },
    {
        "id": 3525,
        "syllable": "pooc",
        "consonant": "p",
        "rhyme": "ooc"
    },
    {
        "id": 3526,
        "syllable": "poong",
        "consonant": "p",
        "rhyme": "oong"
    },
    {
        "id": 3527,
        "syllable": "pot",
        "consonant": "p",
        "rhyme": "ot"
    },
    {
        "id": 3528,
        "syllable": "pon",
        "consonant": "p",
        "rhyme": "on"
    },
    {
        "id": 3529,
        "syllable": "pop",
        "consonant": "p",
        "rhyme": "op"
    },
    {
        "id": 3530,
        "syllable": "pom",
        "consonant": "p",
        "rhyme": "om"
    },
    {
        "id": 3531,
        "syllable": "poi",
        "consonant": "p",
        "rhyme": "oi"
    },
    {
        "id": 3532,
        "syllable": "poc",
        "consonant": "p",
        "rhyme": "oc"
    },
    {
        "id": 3533,
        "syllable": "pong",
        "consonant": "p",
        "rhyme": "ong"
    },
    {
        "id": 3534,
        "syllable": "pua",
        "consonant": "p",
        "rhyme": "ua"
    },
    {
        "id": 3535,
        "syllable": "puôc",
        "consonant": "p",
        "rhyme": "uôc"
    },
    {
        "id": 3536,
        "syllable": "puông",
        "consonant": "p",
        "rhyme": "uông"
    },
    {
        "id": 3537,
        "syllable": "puôt",
        "consonant": "p",
        "rhyme": "uôt"
    },
    {
        "id": 3538,
        "syllable": "puôn",
        "consonant": "p",
        "rhyme": "uôn"
    },
    {
        "id": 3539,
        "syllable": "puôp",
        "consonant": "p",
        "rhyme": "uôp"
    },
    {
        "id": 3540,
        "syllable": "puôm",
        "consonant": "p",
        "rhyme": "uôm"
    },
    {
        "id": 3541,
        "syllable": "puôi",
        "consonant": "p",
        "rhyme": "uôi"
    },
    {
        "id": 3542,
        "syllable": "pưa",
        "consonant": "p",
        "rhyme": "ưa"
    },
    {
        "id": 3543,
        "syllable": "pươc",
        "consonant": "p",
        "rhyme": "ươc"
    },
    {
        "id": 3544,
        "syllable": "pương",
        "consonant": "p",
        "rhyme": "ương"
    },
    {
        "id": 3545,
        "syllable": "pươt",
        "consonant": "p",
        "rhyme": "ươt"
    },
    {
        "id": 3546,
        "syllable": "pươn",
        "consonant": "p",
        "rhyme": "ươn"
    },
    {
        "id": 3547,
        "syllable": "pươp",
        "consonant": "p",
        "rhyme": "ươp"
    },
    {
        "id": 3548,
        "syllable": "pươm",
        "consonant": "p",
        "rhyme": "ươm"
    },
    {
        "id": 3549,
        "syllable": "pươi",
        "consonant": "p",
        "rhyme": "ươi"
    },
    {
        "id": 3550,
        "syllable": "pa",
        "consonant": "p",
        "rhyme": "a"
    },
    {
        "id": 3551,
        "syllable": "poa",
        "consonant": "p",
        "rhyme": "oa"
    },
    {
        "id": 3552,
        "syllable": "pac",
        "consonant": "p",
        "rhyme": "ac"
    },
    {
        "id": 3553,
        "syllable": "poac",
        "consonant": "p",
        "rhyme": "oac"
    },
    {
        "id": 3554,
        "syllable": "pang",
        "consonant": "p",
        "rhyme": "ang"
    },
    {
        "id": 3555,
        "syllable": "poang",
        "consonant": "p",
        "rhyme": "oang"
    },
    {
        "id": 3556,
        "syllable": "pat",
        "consonant": "p",
        "rhyme": "at"
    },
    {
        "id": 3557,
        "syllable": "poat",
        "consonant": "p",
        "rhyme": "oat"
    },
    {
        "id": 3558,
        "syllable": "pan",
        "consonant": "p",
        "rhyme": "an"
    },
    {
        "id": 3559,
        "syllable": "poan",
        "consonant": "p",
        "rhyme": "oan"
    },
    {
        "id": 3560,
        "syllable": "pap",
        "consonant": "p",
        "rhyme": "ap"
    },
    {
        "id": 3561,
        "syllable": "poap",
        "consonant": "p",
        "rhyme": "oap"
    },
    {
        "id": 3562,
        "syllable": "pam",
        "consonant": "p",
        "rhyme": "am"
    },
    {
        "id": 3563,
        "syllable": "poam",
        "consonant": "p",
        "rhyme": "oam"
    },
    {
        "id": 3564,
        "syllable": "pai",
        "consonant": "p",
        "rhyme": "ai"
    },
    {
        "id": 3565,
        "syllable": "poai",
        "consonant": "p",
        "rhyme": "oai"
    },
    {
        "id": 3566,
        "syllable": "pao",
        "consonant": "p",
        "rhyme": "ao"
    },
    {
        "id": 3567,
        "syllable": "poao",
        "consonant": "p",
        "rhyme": "oao"
    },
    {
        "id": 3568,
        "syllable": "păc",
        "consonant": "p",
        "rhyme": "ăc"
    },
    {
        "id": 3569,
        "syllable": "poăc",
        "consonant": "p",
        "rhyme": "oăc"
    },
    {
        "id": 3570,
        "syllable": "păng",
        "consonant": "p",
        "rhyme": "ăng"
    },
    {
        "id": 3571,
        "syllable": "poăng",
        "consonant": "p",
        "rhyme": "oăng"
    },
    {
        "id": 3572,
        "syllable": "păt",
        "consonant": "p",
        "rhyme": "ăt"
    },
    {
        "id": 3573,
        "syllable": "poăt",
        "consonant": "p",
        "rhyme": "oăt"
    },
    {
        "id": 3574,
        "syllable": "păn",
        "consonant": "p",
        "rhyme": "ăn"
    },
    {
        "id": 3575,
        "syllable": "poăn",
        "consonant": "p",
        "rhyme": "oăn"
    },
    {
        "id": 3576,
        "syllable": "păp",
        "consonant": "p",
        "rhyme": "ăp"
    },
    {
        "id": 3577,
        "syllable": "poăp",
        "consonant": "p",
        "rhyme": "oăp"
    },
    {
        "id": 3578,
        "syllable": "păm",
        "consonant": "p",
        "rhyme": "ăm"
    },
    {
        "id": 3579,
        "syllable": "poăm",
        "consonant": "p",
        "rhyme": "oăm"
    },
    {
        "id": 3580,
        "syllable": "pay",
        "consonant": "p",
        "rhyme": "ay"
    },
    {
        "id": 3581,
        "syllable": "poay",
        "consonant": "p",
        "rhyme": "oay"
    },
    {
        "id": 3582,
        "syllable": "pau",
        "consonant": "p",
        "rhyme": "au"
    },
    {
        "id": 3583,
        "syllable": "poau",
        "consonant": "p",
        "rhyme": "oau"
    },
    {
        "id": 3584,
        "syllable": "ni",
        "consonant": "n",
        "rhyme": "i"
    },
    {
        "id": 3585,
        "syllable": "nuy",
        "consonant": "n",
        "rhyme": "uy"
    },
    {
        "id": 3586,
        "syllable": "nich",
        "consonant": "n",
        "rhyme": "ich"
    },
    {
        "id": 3587,
        "syllable": "nuych",
        "consonant": "n",
        "rhyme": "uych"
    },
    {
        "id": 3588,
        "syllable": "ninh",
        "consonant": "n",
        "rhyme": "inh"
    },
    {
        "id": 3589,
        "syllable": "nuynh",
        "consonant": "n",
        "rhyme": "uynh"
    },
    {
        "id": 3590,
        "syllable": "nit",
        "consonant": "n",
        "rhyme": "it"
    },
    {
        "id": 3591,
        "syllable": "nuyt",
        "consonant": "n",
        "rhyme": "uyt"
    },
    {
        "id": 3592,
        "syllable": "nin",
        "consonant": "n",
        "rhyme": "in"
    },
    {
        "id": 3593,
        "syllable": "nuyn",
        "consonant": "n",
        "rhyme": "uyn"
    },
    {
        "id": 3594,
        "syllable": "nip",
        "consonant": "n",
        "rhyme": "ip"
    },
    {
        "id": 3595,
        "syllable": "nuyp",
        "consonant": "n",
        "rhyme": "uyp"
    },
    {
        "id": 3596,
        "syllable": "nim",
        "consonant": "n",
        "rhyme": "im"
    },
    {
        "id": 3597,
        "syllable": "nuym",
        "consonant": "n",
        "rhyme": "uym"
    },
    {
        "id": 3598,
        "syllable": "niu",
        "consonant": "n",
        "rhyme": "iu"
    },
    {
        "id": 3599,
        "syllable": "nuyu",
        "consonant": "n",
        "rhyme": "uyu"
    },
    {
        "id": 3600,
        "syllable": "nê",
        "consonant": "n",
        "rhyme": "ê"
    },
    {
        "id": 3601,
        "syllable": "nuê",
        "consonant": "n",
        "rhyme": "uê"
    },
    {
        "id": 3602,
        "syllable": "nêch",
        "consonant": "n",
        "rhyme": "êch"
    },
    {
        "id": 3603,
        "syllable": "nuêch",
        "consonant": "n",
        "rhyme": "uêch"
    },
    {
        "id": 3604,
        "syllable": "nênh",
        "consonant": "n",
        "rhyme": "ênh"
    },
    {
        "id": 3605,
        "syllable": "nuênh",
        "consonant": "n",
        "rhyme": "uênh"
    },
    {
        "id": 3606,
        "syllable": "nêt",
        "consonant": "n",
        "rhyme": "êt"
    },
    {
        "id": 3607,
        "syllable": "nuêt",
        "consonant": "n",
        "rhyme": "uêt"
    },
    {
        "id": 3608,
        "syllable": "nên",
        "consonant": "n",
        "rhyme": "ên"
    },
    {
        "id": 3609,
        "syllable": "nuên",
        "consonant": "n",
        "rhyme": "uên"
    },
    {
        "id": 3610,
        "syllable": "nêp",
        "consonant": "n",
        "rhyme": "êp"
    },
    {
        "id": 3611,
        "syllable": "nuêp",
        "consonant": "n",
        "rhyme": "uêp"
    },
    {
        "id": 3612,
        "syllable": "nêm",
        "consonant": "n",
        "rhyme": "êm"
    },
    {
        "id": 3613,
        "syllable": "nuêm",
        "consonant": "n",
        "rhyme": "uêm"
    },
    {
        "id": 3614,
        "syllable": "nêu",
        "consonant": "n",
        "rhyme": "êu"
    },
    {
        "id": 3615,
        "syllable": "ne",
        "consonant": "n",
        "rhyme": "e"
    },
    {
        "id": 3616,
        "syllable": "noe",
        "consonant": "n",
        "rhyme": "oe"
    },
    {
        "id": 3617,
        "syllable": "nec",
        "consonant": "n",
        "rhyme": "ec"
    },
    {
        "id": 3618,
        "syllable": "noec",
        "consonant": "n",
        "rhyme": "oec"
    },
    {
        "id": 3619,
        "syllable": "neng",
        "consonant": "n",
        "rhyme": "eng"
    },
    {
        "id": 3620,
        "syllable": "noeng",
        "consonant": "n",
        "rhyme": "oeng"
    },
    {
        "id": 3621,
        "syllable": "net",
        "consonant": "n",
        "rhyme": "et"
    },
    {
        "id": 3622,
        "syllable": "noet",
        "consonant": "n",
        "rhyme": "oet"
    },
    {
        "id": 3623,
        "syllable": "nen",
        "consonant": "n",
        "rhyme": "en"
    },
    {
        "id": 3624,
        "syllable": "noen",
        "consonant": "n",
        "rhyme": "oen"
    },
    {
        "id": 3625,
        "syllable": "nep",
        "consonant": "n",
        "rhyme": "ep"
    },
    {
        "id": 3626,
        "syllable": "noep",
        "consonant": "n",
        "rhyme": "oep"
    },
    {
        "id": 3627,
        "syllable": "nem",
        "consonant": "n",
        "rhyme": "em"
    },
    {
        "id": 3628,
        "syllable": "noem",
        "consonant": "n",
        "rhyme": "oem"
    },
    {
        "id": 3629,
        "syllable": "neo",
        "consonant": "n",
        "rhyme": "eo"
    },
    {
        "id": 3630,
        "syllable": "noeo",
        "consonant": "n",
        "rhyme": "oeo"
    },
    {
        "id": 3631,
        "syllable": "nach",
        "consonant": "n",
        "rhyme": "ach"
    },
    {
        "id": 3632,
        "syllable": "noach",
        "consonant": "n",
        "rhyme": "oach"
    },
    {
        "id": 3633,
        "syllable": "nanh",
        "consonant": "n",
        "rhyme": "anh"
    },
    {
        "id": 3634,
        "syllable": "noanh",
        "consonant": "n",
        "rhyme": "oanh"
    },
    {
        "id": 3635,
        "syllable": "nia",
        "consonant": "n",
        "rhyme": "ia"
    },
    {
        "id": 3636,
        "syllable": "nuya",
        "consonant": "n",
        "rhyme": "uya"
    },
    {
        "id": 3637,
        "syllable": "niêc",
        "consonant": "n",
        "rhyme": "iêc"
    },
    {
        "id": 3638,
        "syllable": "niêng",
        "consonant": "n",
        "rhyme": "iêng"
    },
    {
        "id": 3639,
        "syllable": "niêt",
        "consonant": "n",
        "rhyme": "iêt"
    },
    {
        "id": 3640,
        "syllable": "niên",
        "consonant": "n",
        "rhyme": "iên"
    },
    {
        "id": 3641,
        "syllable": "nuyêt",
        "consonant": "n",
        "rhyme": "uyêt"
    },
    {
        "id": 3642,
        "syllable": "nuyên",
        "consonant": "n",
        "rhyme": "uyên"
    },
    {
        "id": 3643,
        "syllable": "niêp",
        "consonant": "n",
        "rhyme": "iêp"
    },
    {
        "id": 3644,
        "syllable": "nuyêp",
        "consonant": "n",
        "rhyme": "uyêp"
    },
    {
        "id": 3645,
        "syllable": "niêm",
        "consonant": "n",
        "rhyme": "iêm"
    },
    {
        "id": 3646,
        "syllable": "nuyêm",
        "consonant": "n",
        "rhyme": "uyêm"
    },
    {
        "id": 3647,
        "syllable": "niêu",
        "consonant": "n",
        "rhyme": "iêu"
    },
    {
        "id": 3648,
        "syllable": "nư",
        "consonant": "n",
        "rhyme": "ư"
    },
    {
        "id": 3649,
        "syllable": "nưc",
        "consonant": "n",
        "rhyme": "ưc"
    },
    {
        "id": 3650,
        "syllable": "nưng",
        "consonant": "n",
        "rhyme": "ưng"
    },
    {
        "id": 3651,
        "syllable": "nưt",
        "consonant": "n",
        "rhyme": "ưt"
    },
    {
        "id": 3652,
        "syllable": "nưn",
        "consonant": "n",
        "rhyme": "ưn"
    },
    {
        "id": 3653,
        "syllable": "nưp",
        "consonant": "n",
        "rhyme": "ưp"
    },
    {
        "id": 3654,
        "syllable": "nưm",
        "consonant": "n",
        "rhyme": "ưm"
    },
    {
        "id": 3655,
        "syllable": "nưi",
        "consonant": "n",
        "rhyme": "ưi"
    },
    {
        "id": 3656,
        "syllable": "nưu",
        "consonant": "n",
        "rhyme": "ưu"
    },
    {
        "id": 3657,
        "syllable": "nơ",
        "consonant": "n",
        "rhyme": "ơ"
    },
    {
        "id": 3658,
        "syllable": "nuơ",
        "consonant": "n",
        "rhyme": "uơ"
    },
    {
        "id": 3659,
        "syllable": "nơng",
        "consonant": "n",
        "rhyme": "ơng"
    },
    {
        "id": 3660,
        "syllable": "nơt",
        "consonant": "n",
        "rhyme": "ơt"
    },
    {
        "id": 3661,
        "syllable": "nơn",
        "consonant": "n",
        "rhyme": "ơn"
    },
    {
        "id": 3662,
        "syllable": "nơp",
        "consonant": "n",
        "rhyme": "ơp"
    },
    {
        "id": 3663,
        "syllable": "nơm",
        "consonant": "n",
        "rhyme": "ơm"
    },
    {
        "id": 3664,
        "syllable": "nơi",
        "consonant": "n",
        "rhyme": "ơi"
    },
    {
        "id": 3665,
        "syllable": "nâc",
        "consonant": "n",
        "rhyme": "âc"
    },
    {
        "id": 3666,
        "syllable": "nâng",
        "consonant": "n",
        "rhyme": "âng"
    },
    {
        "id": 3667,
        "syllable": "nuâng",
        "consonant": "n",
        "rhyme": "uâng"
    },
    {
        "id": 3668,
        "syllable": "nât",
        "consonant": "n",
        "rhyme": "ât"
    },
    {
        "id": 3669,
        "syllable": "nuât",
        "consonant": "n",
        "rhyme": "uât"
    },
    {
        "id": 3670,
        "syllable": "nân",
        "consonant": "n",
        "rhyme": "ân"
    },
    {
        "id": 3671,
        "syllable": "nuân",
        "consonant": "n",
        "rhyme": "uân"
    },
    {
        "id": 3672,
        "syllable": "nâp",
        "consonant": "n",
        "rhyme": "âp"
    },
    {
        "id": 3673,
        "syllable": "nuâp",
        "consonant": "n",
        "rhyme": "uâp"
    },
    {
        "id": 3674,
        "syllable": "nâm",
        "consonant": "n",
        "rhyme": "âm"
    },
    {
        "id": 3675,
        "syllable": "nuâm",
        "consonant": "n",
        "rhyme": "uâm"
    },
    {
        "id": 3676,
        "syllable": "nây",
        "consonant": "n",
        "rhyme": "ây"
    },
    {
        "id": 3677,
        "syllable": "nuây",
        "consonant": "n",
        "rhyme": "uây"
    },
    {
        "id": 3678,
        "syllable": "nâu",
        "consonant": "n",
        "rhyme": "âu"
    },
    {
        "id": 3679,
        "syllable": "nuâu",
        "consonant": "n",
        "rhyme": "uâu"
    },
    {
        "id": 3680,
        "syllable": "nu",
        "consonant": "n",
        "rhyme": "u"
    },
    {
        "id": 3681,
        "syllable": "nuc",
        "consonant": "n",
        "rhyme": "uc"
    },
    {
        "id": 3682,
        "syllable": "nung",
        "consonant": "n",
        "rhyme": "ung"
    },
    {
        "id": 3683,
        "syllable": "nut",
        "consonant": "n",
        "rhyme": "ut"
    },
    {
        "id": 3684,
        "syllable": "nun",
        "consonant": "n",
        "rhyme": "un"
    },
    {
        "id": 3685,
        "syllable": "nup",
        "consonant": "n",
        "rhyme": "up"
    },
    {
        "id": 3686,
        "syllable": "num",
        "consonant": "n",
        "rhyme": "um"
    },
    {
        "id": 3687,
        "syllable": "nui",
        "consonant": "n",
        "rhyme": "ui"
    },
    {
        "id": 3688,
        "syllable": "nô",
        "consonant": "n",
        "rhyme": "ô"
    },
    {
        "id": 3689,
        "syllable": "nôc",
        "consonant": "n",
        "rhyme": "ôc"
    },
    {
        "id": 3690,
        "syllable": "nông",
        "consonant": "n",
        "rhyme": "ông"
    },
    {
        "id": 3691,
        "syllable": "nôt",
        "consonant": "n",
        "rhyme": "ôt"
    },
    {
        "id": 3692,
        "syllable": "nôn",
        "consonant": "n",
        "rhyme": "ôn"
    },
    {
        "id": 3693,
        "syllable": "nôp",
        "consonant": "n",
        "rhyme": "ôp"
    },
    {
        "id": 3694,
        "syllable": "nôm",
        "consonant": "n",
        "rhyme": "ôm"
    },
    {
        "id": 3695,
        "syllable": "nôi",
        "consonant": "n",
        "rhyme": "ôi"
    },
    {
        "id": 3696,
        "syllable": "no",
        "consonant": "n",
        "rhyme": "o"
    },
    {
        "id": 3697,
        "syllable": "nooc",
        "consonant": "n",
        "rhyme": "ooc"
    },
    {
        "id": 3698,
        "syllable": "noong",
        "consonant": "n",
        "rhyme": "oong"
    },
    {
        "id": 3699,
        "syllable": "not",
        "consonant": "n",
        "rhyme": "ot"
    },
    {
        "id": 3700,
        "syllable": "non",
        "consonant": "n",
        "rhyme": "on"
    },
    {
        "id": 3701,
        "syllable": "nop",
        "consonant": "n",
        "rhyme": "op"
    },
    {
        "id": 3702,
        "syllable": "nom",
        "consonant": "n",
        "rhyme": "om"
    },
    {
        "id": 3703,
        "syllable": "noi",
        "consonant": "n",
        "rhyme": "oi"
    },
    {
        "id": 3704,
        "syllable": "noc",
        "consonant": "n",
        "rhyme": "oc"
    },
    {
        "id": 3705,
        "syllable": "nong",
        "consonant": "n",
        "rhyme": "ong"
    },
    {
        "id": 3706,
        "syllable": "nua",
        "consonant": "n",
        "rhyme": "ua"
    },
    {
        "id": 3707,
        "syllable": "nuôc",
        "consonant": "n",
        "rhyme": "uôc"
    },
    {
        "id": 3708,
        "syllable": "nuông",
        "consonant": "n",
        "rhyme": "uông"
    },
    {
        "id": 3709,
        "syllable": "nuôt",
        "consonant": "n",
        "rhyme": "uôt"
    },
    {
        "id": 3710,
        "syllable": "nuôn",
        "consonant": "n",
        "rhyme": "uôn"
    },
    {
        "id": 3711,
        "syllable": "nuôp",
        "consonant": "n",
        "rhyme": "uôp"
    },
    {
        "id": 3712,
        "syllable": "nuôm",
        "consonant": "n",
        "rhyme": "uôm"
    },
    {
        "id": 3713,
        "syllable": "nuôi",
        "consonant": "n",
        "rhyme": "uôi"
    },
    {
        "id": 3714,
        "syllable": "nưa",
        "consonant": "n",
        "rhyme": "ưa"
    },
    {
        "id": 3715,
        "syllable": "nươc",
        "consonant": "n",
        "rhyme": "ươc"
    },
    {
        "id": 3716,
        "syllable": "nương",
        "consonant": "n",
        "rhyme": "ương"
    },
    {
        "id": 3717,
        "syllable": "nươt",
        "consonant": "n",
        "rhyme": "ươt"
    },
    {
        "id": 3718,
        "syllable": "nươn",
        "consonant": "n",
        "rhyme": "ươn"
    },
    {
        "id": 3719,
        "syllable": "nươp",
        "consonant": "n",
        "rhyme": "ươp"
    },
    {
        "id": 3720,
        "syllable": "nươm",
        "consonant": "n",
        "rhyme": "ươm"
    },
    {
        "id": 3721,
        "syllable": "nươi",
        "consonant": "n",
        "rhyme": "ươi"
    },
    {
        "id": 3722,
        "syllable": "na",
        "consonant": "n",
        "rhyme": "a"
    },
    {
        "id": 3723,
        "syllable": "noa",
        "consonant": "n",
        "rhyme": "oa"
    },
    {
        "id": 3724,
        "syllable": "nac",
        "consonant": "n",
        "rhyme": "ac"
    },
    {
        "id": 3725,
        "syllable": "noac",
        "consonant": "n",
        "rhyme": "oac"
    },
    {
        "id": 3726,
        "syllable": "nang",
        "consonant": "n",
        "rhyme": "ang"
    },
    {
        "id": 3727,
        "syllable": "noang",
        "consonant": "n",
        "rhyme": "oang"
    },
    {
        "id": 3728,
        "syllable": "nat",
        "consonant": "n",
        "rhyme": "at"
    },
    {
        "id": 3729,
        "syllable": "noat",
        "consonant": "n",
        "rhyme": "oat"
    },
    {
        "id": 3730,
        "syllable": "nan",
        "consonant": "n",
        "rhyme": "an"
    },
    {
        "id": 3731,
        "syllable": "noan",
        "consonant": "n",
        "rhyme": "oan"
    },
    {
        "id": 3732,
        "syllable": "nap",
        "consonant": "n",
        "rhyme": "ap"
    },
    {
        "id": 3733,
        "syllable": "noap",
        "consonant": "n",
        "rhyme": "oap"
    },
    {
        "id": 3734,
        "syllable": "nam",
        "consonant": "n",
        "rhyme": "am"
    },
    {
        "id": 3735,
        "syllable": "noam",
        "consonant": "n",
        "rhyme": "oam"
    },
    {
        "id": 3736,
        "syllable": "nai",
        "consonant": "n",
        "rhyme": "ai"
    },
    {
        "id": 3737,
        "syllable": "noai",
        "consonant": "n",
        "rhyme": "oai"
    },
    {
        "id": 3738,
        "syllable": "nao",
        "consonant": "n",
        "rhyme": "ao"
    },
    {
        "id": 3739,
        "syllable": "noao",
        "consonant": "n",
        "rhyme": "oao"
    },
    {
        "id": 3740,
        "syllable": "năc",
        "consonant": "n",
        "rhyme": "ăc"
    },
    {
        "id": 3741,
        "syllable": "noăc",
        "consonant": "n",
        "rhyme": "oăc"
    },
    {
        "id": 3742,
        "syllable": "năng",
        "consonant": "n",
        "rhyme": "ăng"
    },
    {
        "id": 3743,
        "syllable": "noăng",
        "consonant": "n",
        "rhyme": "oăng"
    },
    {
        "id": 3744,
        "syllable": "năt",
        "consonant": "n",
        "rhyme": "ăt"
    },
    {
        "id": 3745,
        "syllable": "noăt",
        "consonant": "n",
        "rhyme": "oăt"
    },
    {
        "id": 3746,
        "syllable": "năn",
        "consonant": "n",
        "rhyme": "ăn"
    },
    {
        "id": 3747,
        "syllable": "noăn",
        "consonant": "n",
        "rhyme": "oăn"
    },
    {
        "id": 3748,
        "syllable": "năp",
        "consonant": "n",
        "rhyme": "ăp"
    },
    {
        "id": 3749,
        "syllable": "noăp",
        "consonant": "n",
        "rhyme": "oăp"
    },
    {
        "id": 3750,
        "syllable": "năm",
        "consonant": "n",
        "rhyme": "ăm"
    },
    {
        "id": 3751,
        "syllable": "noăm",
        "consonant": "n",
        "rhyme": "oăm"
    },
    {
        "id": 3752,
        "syllable": "nay",
        "consonant": "n",
        "rhyme": "ay"
    },
    {
        "id": 3753,
        "syllable": "noay",
        "consonant": "n",
        "rhyme": "oay"
    },
    {
        "id": 3754,
        "syllable": "nau",
        "consonant": "n",
        "rhyme": "au"
    },
    {
        "id": 3755,
        "syllable": "noau",
        "consonant": "n",
        "rhyme": "oau"
    },
    {
        "id": 3756,
        "syllable": "mi",
        "consonant": "m",
        "rhyme": "i"
    },
    {
        "id": 3757,
        "syllable": "muy",
        "consonant": "m",
        "rhyme": "uy"
    },
    {
        "id": 3758,
        "syllable": "mich",
        "consonant": "m",
        "rhyme": "ich"
    },
    {
        "id": 3759,
        "syllable": "muych",
        "consonant": "m",
        "rhyme": "uych"
    },
    {
        "id": 3760,
        "syllable": "minh",
        "consonant": "m",
        "rhyme": "inh"
    },
    {
        "id": 3761,
        "syllable": "muynh",
        "consonant": "m",
        "rhyme": "uynh"
    },
    {
        "id": 3762,
        "syllable": "mit",
        "consonant": "m",
        "rhyme": "it"
    },
    {
        "id": 3763,
        "syllable": "muyt",
        "consonant": "m",
        "rhyme": "uyt"
    },
    {
        "id": 3764,
        "syllable": "min",
        "consonant": "m",
        "rhyme": "in"
    },
    {
        "id": 3765,
        "syllable": "muyn",
        "consonant": "m",
        "rhyme": "uyn"
    },
    {
        "id": 3766,
        "syllable": "mip",
        "consonant": "m",
        "rhyme": "ip"
    },
    {
        "id": 3767,
        "syllable": "muyp",
        "consonant": "m",
        "rhyme": "uyp"
    },
    {
        "id": 3768,
        "syllable": "mim",
        "consonant": "m",
        "rhyme": "im"
    },
    {
        "id": 3769,
        "syllable": "muym",
        "consonant": "m",
        "rhyme": "uym"
    },
    {
        "id": 3770,
        "syllable": "miu",
        "consonant": "m",
        "rhyme": "iu"
    },
    {
        "id": 3771,
        "syllable": "muyu",
        "consonant": "m",
        "rhyme": "uyu"
    },
    {
        "id": 3772,
        "syllable": "mê",
        "consonant": "m",
        "rhyme": "ê"
    },
    {
        "id": 3773,
        "syllable": "muê",
        "consonant": "m",
        "rhyme": "uê"
    },
    {
        "id": 3774,
        "syllable": "mêch",
        "consonant": "m",
        "rhyme": "êch"
    },
    {
        "id": 3775,
        "syllable": "muêch",
        "consonant": "m",
        "rhyme": "uêch"
    },
    {
        "id": 3776,
        "syllable": "mênh",
        "consonant": "m",
        "rhyme": "ênh"
    },
    {
        "id": 3777,
        "syllable": "muênh",
        "consonant": "m",
        "rhyme": "uênh"
    },
    {
        "id": 3778,
        "syllable": "mêt",
        "consonant": "m",
        "rhyme": "êt"
    },
    {
        "id": 3779,
        "syllable": "muêt",
        "consonant": "m",
        "rhyme": "uêt"
    },
    {
        "id": 3780,
        "syllable": "mên",
        "consonant": "m",
        "rhyme": "ên"
    },
    {
        "id": 3781,
        "syllable": "muên",
        "consonant": "m",
        "rhyme": "uên"
    },
    {
        "id": 3782,
        "syllable": "mêp",
        "consonant": "m",
        "rhyme": "êp"
    },
    {
        "id": 3783,
        "syllable": "muêp",
        "consonant": "m",
        "rhyme": "uêp"
    },
    {
        "id": 3784,
        "syllable": "mêm",
        "consonant": "m",
        "rhyme": "êm"
    },
    {
        "id": 3785,
        "syllable": "muêm",
        "consonant": "m",
        "rhyme": "uêm"
    },
    {
        "id": 3786,
        "syllable": "mêu",
        "consonant": "m",
        "rhyme": "êu"
    },
    {
        "id": 3787,
        "syllable": "me",
        "consonant": "m",
        "rhyme": "e"
    },
    {
        "id": 3788,
        "syllable": "moe",
        "consonant": "m",
        "rhyme": "oe"
    },
    {
        "id": 3789,
        "syllable": "mec",
        "consonant": "m",
        "rhyme": "ec"
    },
    {
        "id": 3790,
        "syllable": "moec",
        "consonant": "m",
        "rhyme": "oec"
    },
    {
        "id": 3791,
        "syllable": "meng",
        "consonant": "m",
        "rhyme": "eng"
    },
    {
        "id": 3792,
        "syllable": "moeng",
        "consonant": "m",
        "rhyme": "oeng"
    },
    {
        "id": 3793,
        "syllable": "met",
        "consonant": "m",
        "rhyme": "et"
    },
    {
        "id": 3794,
        "syllable": "moet",
        "consonant": "m",
        "rhyme": "oet"
    },
    {
        "id": 3795,
        "syllable": "men",
        "consonant": "m",
        "rhyme": "en"
    },
    {
        "id": 3796,
        "syllable": "moen",
        "consonant": "m",
        "rhyme": "oen"
    },
    {
        "id": 3797,
        "syllable": "mep",
        "consonant": "m",
        "rhyme": "ep"
    },
    {
        "id": 3798,
        "syllable": "moep",
        "consonant": "m",
        "rhyme": "oep"
    },
    {
        "id": 3799,
        "syllable": "mem",
        "consonant": "m",
        "rhyme": "em"
    },
    {
        "id": 3800,
        "syllable": "moem",
        "consonant": "m",
        "rhyme": "oem"
    },
    {
        "id": 3801,
        "syllable": "meo",
        "consonant": "m",
        "rhyme": "eo"
    },
    {
        "id": 3802,
        "syllable": "moeo",
        "consonant": "m",
        "rhyme": "oeo"
    },
    {
        "id": 3803,
        "syllable": "mach",
        "consonant": "m",
        "rhyme": "ach"
    },
    {
        "id": 3804,
        "syllable": "moach",
        "consonant": "m",
        "rhyme": "oach"
    },
    {
        "id": 3805,
        "syllable": "manh",
        "consonant": "m",
        "rhyme": "anh"
    },
    {
        "id": 3806,
        "syllable": "moanh",
        "consonant": "m",
        "rhyme": "oanh"
    },
    {
        "id": 3807,
        "syllable": "mia",
        "consonant": "m",
        "rhyme": "ia"
    },
    {
        "id": 3808,
        "syllable": "muya",
        "consonant": "m",
        "rhyme": "uya"
    },
    {
        "id": 3809,
        "syllable": "miêc",
        "consonant": "m",
        "rhyme": "iêc"
    },
    {
        "id": 3810,
        "syllable": "miêng",
        "consonant": "m",
        "rhyme": "iêng"
    },
    {
        "id": 3811,
        "syllable": "miêt",
        "consonant": "m",
        "rhyme": "iêt"
    },
    {
        "id": 3812,
        "syllable": "miên",
        "consonant": "m",
        "rhyme": "iên"
    },
    {
        "id": 3813,
        "syllable": "muyêt",
        "consonant": "m",
        "rhyme": "uyêt"
    },
    {
        "id": 3814,
        "syllable": "muyên",
        "consonant": "m",
        "rhyme": "uyên"
    },
    {
        "id": 3815,
        "syllable": "miêp",
        "consonant": "m",
        "rhyme": "iêp"
    },
    {
        "id": 3816,
        "syllable": "muyêp",
        "consonant": "m",
        "rhyme": "uyêp"
    },
    {
        "id": 3817,
        "syllable": "miêm",
        "consonant": "m",
        "rhyme": "iêm"
    },
    {
        "id": 3818,
        "syllable": "muyêm",
        "consonant": "m",
        "rhyme": "uyêm"
    },
    {
        "id": 3819,
        "syllable": "miêu",
        "consonant": "m",
        "rhyme": "iêu"
    },
    {
        "id": 3820,
        "syllable": "mư",
        "consonant": "m",
        "rhyme": "ư"
    },
    {
        "id": 3821,
        "syllable": "mưc",
        "consonant": "m",
        "rhyme": "ưc"
    },
    {
        "id": 3822,
        "syllable": "mưng",
        "consonant": "m",
        "rhyme": "ưng"
    },
    {
        "id": 3823,
        "syllable": "mưt",
        "consonant": "m",
        "rhyme": "ưt"
    },
    {
        "id": 3824,
        "syllable": "mưn",
        "consonant": "m",
        "rhyme": "ưn"
    },
    {
        "id": 3825,
        "syllable": "mưp",
        "consonant": "m",
        "rhyme": "ưp"
    },
    {
        "id": 3826,
        "syllable": "mưm",
        "consonant": "m",
        "rhyme": "ưm"
    },
    {
        "id": 3827,
        "syllable": "mưi",
        "consonant": "m",
        "rhyme": "ưi"
    },
    {
        "id": 3828,
        "syllable": "mưu",
        "consonant": "m",
        "rhyme": "ưu"
    },
    {
        "id": 3829,
        "syllable": "mơ",
        "consonant": "m",
        "rhyme": "ơ"
    },
    {
        "id": 3830,
        "syllable": "muơ",
        "consonant": "m",
        "rhyme": "uơ"
    },
    {
        "id": 3831,
        "syllable": "mơng",
        "consonant": "m",
        "rhyme": "ơng"
    },
    {
        "id": 3832,
        "syllable": "mơt",
        "consonant": "m",
        "rhyme": "ơt"
    },
    {
        "id": 3833,
        "syllable": "mơn",
        "consonant": "m",
        "rhyme": "ơn"
    },
    {
        "id": 3834,
        "syllable": "mơp",
        "consonant": "m",
        "rhyme": "ơp"
    },
    {
        "id": 3835,
        "syllable": "mơm",
        "consonant": "m",
        "rhyme": "ơm"
    },
    {
        "id": 3836,
        "syllable": "mơi",
        "consonant": "m",
        "rhyme": "ơi"
    },
    {
        "id": 3837,
        "syllable": "mâc",
        "consonant": "m",
        "rhyme": "âc"
    },
    {
        "id": 3838,
        "syllable": "mâng",
        "consonant": "m",
        "rhyme": "âng"
    },
    {
        "id": 3839,
        "syllable": "muâng",
        "consonant": "m",
        "rhyme": "uâng"
    },
    {
        "id": 3840,
        "syllable": "mât",
        "consonant": "m",
        "rhyme": "ât"
    },
    {
        "id": 3841,
        "syllable": "muât",
        "consonant": "m",
        "rhyme": "uât"
    },
    {
        "id": 3842,
        "syllable": "mân",
        "consonant": "m",
        "rhyme": "ân"
    },
    {
        "id": 3843,
        "syllable": "muân",
        "consonant": "m",
        "rhyme": "uân"
    },
    {
        "id": 3844,
        "syllable": "mâp",
        "consonant": "m",
        "rhyme": "âp"
    },
    {
        "id": 3845,
        "syllable": "muâp",
        "consonant": "m",
        "rhyme": "uâp"
    },
    {
        "id": 3846,
        "syllable": "mâm",
        "consonant": "m",
        "rhyme": "âm"
    },
    {
        "id": 3847,
        "syllable": "muâm",
        "consonant": "m",
        "rhyme": "uâm"
    },
    {
        "id": 3848,
        "syllable": "mây",
        "consonant": "m",
        "rhyme": "ây"
    },
    {
        "id": 3849,
        "syllable": "muây",
        "consonant": "m",
        "rhyme": "uây"
    },
    {
        "id": 3850,
        "syllable": "mâu",
        "consonant": "m",
        "rhyme": "âu"
    },
    {
        "id": 3851,
        "syllable": "muâu",
        "consonant": "m",
        "rhyme": "uâu"
    },
    {
        "id": 3852,
        "syllable": "mu",
        "consonant": "m",
        "rhyme": "u"
    },
    {
        "id": 3853,
        "syllable": "muc",
        "consonant": "m",
        "rhyme": "uc"
    },
    {
        "id": 3854,
        "syllable": "mung",
        "consonant": "m",
        "rhyme": "ung"
    },
    {
        "id": 3855,
        "syllable": "mut",
        "consonant": "m",
        "rhyme": "ut"
    },
    {
        "id": 3856,
        "syllable": "mun",
        "consonant": "m",
        "rhyme": "un"
    },
    {
        "id": 3857,
        "syllable": "mup",
        "consonant": "m",
        "rhyme": "up"
    },
    {
        "id": 3858,
        "syllable": "mum",
        "consonant": "m",
        "rhyme": "um"
    },
    {
        "id": 3859,
        "syllable": "mui",
        "consonant": "m",
        "rhyme": "ui"
    },
    {
        "id": 3860,
        "syllable": "mô",
        "consonant": "m",
        "rhyme": "ô"
    },
    {
        "id": 3861,
        "syllable": "môc",
        "consonant": "m",
        "rhyme": "ôc"
    },
    {
        "id": 3862,
        "syllable": "mông",
        "consonant": "m",
        "rhyme": "ông"
    },
    {
        "id": 3863,
        "syllable": "môt",
        "consonant": "m",
        "rhyme": "ôt"
    },
    {
        "id": 3864,
        "syllable": "môn",
        "consonant": "m",
        "rhyme": "ôn"
    },
    {
        "id": 3865,
        "syllable": "môp",
        "consonant": "m",
        "rhyme": "ôp"
    },
    {
        "id": 3866,
        "syllable": "môm",
        "consonant": "m",
        "rhyme": "ôm"
    },
    {
        "id": 3867,
        "syllable": "môi",
        "consonant": "m",
        "rhyme": "ôi"
    },
    {
        "id": 3868,
        "syllable": "mo",
        "consonant": "m",
        "rhyme": "o"
    },
    {
        "id": 3869,
        "syllable": "mooc",
        "consonant": "m",
        "rhyme": "ooc"
    },
    {
        "id": 3870,
        "syllable": "moong",
        "consonant": "m",
        "rhyme": "oong"
    },
    {
        "id": 3871,
        "syllable": "mot",
        "consonant": "m",
        "rhyme": "ot"
    },
    {
        "id": 3872,
        "syllable": "mon",
        "consonant": "m",
        "rhyme": "on"
    },
    {
        "id": 3873,
        "syllable": "mop",
        "consonant": "m",
        "rhyme": "op"
    },
    {
        "id": 3874,
        "syllable": "mom",
        "consonant": "m",
        "rhyme": "om"
    },
    {
        "id": 3875,
        "syllable": "moi",
        "consonant": "m",
        "rhyme": "oi"
    },
    {
        "id": 3876,
        "syllable": "moc",
        "consonant": "m",
        "rhyme": "oc"
    },
    {
        "id": 3877,
        "syllable": "mong",
        "consonant": "m",
        "rhyme": "ong"
    },
    {
        "id": 3878,
        "syllable": "mua",
        "consonant": "m",
        "rhyme": "ua"
    },
    {
        "id": 3879,
        "syllable": "muôc",
        "consonant": "m",
        "rhyme": "uôc"
    },
    {
        "id": 3880,
        "syllable": "muông",
        "consonant": "m",
        "rhyme": "uông"
    },
    {
        "id": 3881,
        "syllable": "muôt",
        "consonant": "m",
        "rhyme": "uôt"
    },
    {
        "id": 3882,
        "syllable": "muôn",
        "consonant": "m",
        "rhyme": "uôn"
    },
    {
        "id": 3883,
        "syllable": "muôp",
        "consonant": "m",
        "rhyme": "uôp"
    },
    {
        "id": 3884,
        "syllable": "muôm",
        "consonant": "m",
        "rhyme": "uôm"
    },
    {
        "id": 3885,
        "syllable": "muôi",
        "consonant": "m",
        "rhyme": "uôi"
    },
    {
        "id": 3886,
        "syllable": "mưa",
        "consonant": "m",
        "rhyme": "ưa"
    },
    {
        "id": 3887,
        "syllable": "mươc",
        "consonant": "m",
        "rhyme": "ươc"
    },
    {
        "id": 3888,
        "syllable": "mương",
        "consonant": "m",
        "rhyme": "ương"
    },
    {
        "id": 3889,
        "syllable": "mươt",
        "consonant": "m",
        "rhyme": "ươt"
    },
    {
        "id": 3890,
        "syllable": "mươn",
        "consonant": "m",
        "rhyme": "ươn"
    },
    {
        "id": 3891,
        "syllable": "mươp",
        "consonant": "m",
        "rhyme": "ươp"
    },
    {
        "id": 3892,
        "syllable": "mươm",
        "consonant": "m",
        "rhyme": "ươm"
    },
    {
        "id": 3893,
        "syllable": "mươi",
        "consonant": "m",
        "rhyme": "ươi"
    },
    {
        "id": 3894,
        "syllable": "ma",
        "consonant": "m",
        "rhyme": "a"
    },
    {
        "id": 3895,
        "syllable": "moa",
        "consonant": "m",
        "rhyme": "oa"
    },
    {
        "id": 3896,
        "syllable": "mac",
        "consonant": "m",
        "rhyme": "ac"
    },
    {
        "id": 3897,
        "syllable": "moac",
        "consonant": "m",
        "rhyme": "oac"
    },
    {
        "id": 3898,
        "syllable": "mang",
        "consonant": "m",
        "rhyme": "ang"
    },
    {
        "id": 3899,
        "syllable": "moang",
        "consonant": "m",
        "rhyme": "oang"
    },
    {
        "id": 3900,
        "syllable": "mat",
        "consonant": "m",
        "rhyme": "at"
    },
    {
        "id": 3901,
        "syllable": "moat",
        "consonant": "m",
        "rhyme": "oat"
    },
    {
        "id": 3902,
        "syllable": "man",
        "consonant": "m",
        "rhyme": "an"
    },
    {
        "id": 3903,
        "syllable": "moan",
        "consonant": "m",
        "rhyme": "oan"
    },
    {
        "id": 3904,
        "syllable": "map",
        "consonant": "m",
        "rhyme": "ap"
    },
    {
        "id": 3905,
        "syllable": "moap",
        "consonant": "m",
        "rhyme": "oap"
    },
    {
        "id": 3906,
        "syllable": "mam",
        "consonant": "m",
        "rhyme": "am"
    },
    {
        "id": 3907,
        "syllable": "moam",
        "consonant": "m",
        "rhyme": "oam"
    },
    {
        "id": 3908,
        "syllable": "mai",
        "consonant": "m",
        "rhyme": "ai"
    },
    {
        "id": 3909,
        "syllable": "moai",
        "consonant": "m",
        "rhyme": "oai"
    },
    {
        "id": 3910,
        "syllable": "mao",
        "consonant": "m",
        "rhyme": "ao"
    },
    {
        "id": 3911,
        "syllable": "moao",
        "consonant": "m",
        "rhyme": "oao"
    },
    {
        "id": 3912,
        "syllable": "măc",
        "consonant": "m",
        "rhyme": "ăc"
    },
    {
        "id": 3913,
        "syllable": "moăc",
        "consonant": "m",
        "rhyme": "oăc"
    },
    {
        "id": 3914,
        "syllable": "măng",
        "consonant": "m",
        "rhyme": "ăng"
    },
    {
        "id": 3915,
        "syllable": "moăng",
        "consonant": "m",
        "rhyme": "oăng"
    },
    {
        "id": 3916,
        "syllable": "măt",
        "consonant": "m",
        "rhyme": "ăt"
    },
    {
        "id": 3917,
        "syllable": "moăt",
        "consonant": "m",
        "rhyme": "oăt"
    },
    {
        "id": 3918,
        "syllable": "măn",
        "consonant": "m",
        "rhyme": "ăn"
    },
    {
        "id": 3919,
        "syllable": "moăn",
        "consonant": "m",
        "rhyme": "oăn"
    },
    {
        "id": 3920,
        "syllable": "măp",
        "consonant": "m",
        "rhyme": "ăp"
    },
    {
        "id": 3921,
        "syllable": "moăp",
        "consonant": "m",
        "rhyme": "oăp"
    },
    {
        "id": 3922,
        "syllable": "măm",
        "consonant": "m",
        "rhyme": "ăm"
    },
    {
        "id": 3923,
        "syllable": "moăm",
        "consonant": "m",
        "rhyme": "oăm"
    },
    {
        "id": 3924,
        "syllable": "may",
        "consonant": "m",
        "rhyme": "ay"
    },
    {
        "id": 3925,
        "syllable": "moay",
        "consonant": "m",
        "rhyme": "oay"
    },
    {
        "id": 3926,
        "syllable": "mau",
        "consonant": "m",
        "rhyme": "au"
    },
    {
        "id": 3927,
        "syllable": "moau",
        "consonant": "m",
        "rhyme": "oau"
    },
    {
        "id": 3928,
        "syllable": "nhi",
        "consonant": "nh",
        "rhyme": "i"
    },
    {
        "id": 3929,
        "syllable": "nhuy",
        "consonant": "nh",
        "rhyme": "uy"
    },
    {
        "id": 3930,
        "syllable": "nhich",
        "consonant": "nh",
        "rhyme": "ich"
    },
    {
        "id": 3931,
        "syllable": "nhuych",
        "consonant": "nh",
        "rhyme": "uych"
    },
    {
        "id": 3932,
        "syllable": "nhinh",
        "consonant": "nh",
        "rhyme": "inh"
    },
    {
        "id": 3933,
        "syllable": "nhuynh",
        "consonant": "nh",
        "rhyme": "uynh"
    },
    {
        "id": 3934,
        "syllable": "nhit",
        "consonant": "nh",
        "rhyme": "it"
    },
    {
        "id": 3935,
        "syllable": "nhuyt",
        "consonant": "nh",
        "rhyme": "uyt"
    },
    {
        "id": 3936,
        "syllable": "nhin",
        "consonant": "nh",
        "rhyme": "in"
    },
    {
        "id": 3937,
        "syllable": "nhuyn",
        "consonant": "nh",
        "rhyme": "uyn"
    },
    {
        "id": 3938,
        "syllable": "nhip",
        "consonant": "nh",
        "rhyme": "ip"
    },
    {
        "id": 3939,
        "syllable": "nhuyp",
        "consonant": "nh",
        "rhyme": "uyp"
    },
    {
        "id": 3940,
        "syllable": "nhim",
        "consonant": "nh",
        "rhyme": "im"
    },
    {
        "id": 3941,
        "syllable": "nhuym",
        "consonant": "nh",
        "rhyme": "uym"
    },
    {
        "id": 3942,
        "syllable": "nhiu",
        "consonant": "nh",
        "rhyme": "iu"
    },
    {
        "id": 3943,
        "syllable": "nhuyu",
        "consonant": "nh",
        "rhyme": "uyu"
    },
    {
        "id": 3944,
        "syllable": "nhê",
        "consonant": "nh",
        "rhyme": "ê"
    },
    {
        "id": 3945,
        "syllable": "nhuê",
        "consonant": "nh",
        "rhyme": "uê"
    },
    {
        "id": 3946,
        "syllable": "nhêch",
        "consonant": "nh",
        "rhyme": "êch"
    },
    {
        "id": 3947,
        "syllable": "nhuêch",
        "consonant": "nh",
        "rhyme": "uêch"
    },
    {
        "id": 3948,
        "syllable": "nhênh",
        "consonant": "nh",
        "rhyme": "ênh"
    },
    {
        "id": 3949,
        "syllable": "nhuênh",
        "consonant": "nh",
        "rhyme": "uênh"
    },
    {
        "id": 3950,
        "syllable": "nhêt",
        "consonant": "nh",
        "rhyme": "êt"
    },
    {
        "id": 3951,
        "syllable": "nhuêt",
        "consonant": "nh",
        "rhyme": "uêt"
    },
    {
        "id": 3952,
        "syllable": "nhên",
        "consonant": "nh",
        "rhyme": "ên"
    },
    {
        "id": 3953,
        "syllable": "nhuên",
        "consonant": "nh",
        "rhyme": "uên"
    },
    {
        "id": 3954,
        "syllable": "nhêp",
        "consonant": "nh",
        "rhyme": "êp"
    },
    {
        "id": 3955,
        "syllable": "nhuêp",
        "consonant": "nh",
        "rhyme": "uêp"
    },
    {
        "id": 3956,
        "syllable": "nhêm",
        "consonant": "nh",
        "rhyme": "êm"
    },
    {
        "id": 3957,
        "syllable": "nhuêm",
        "consonant": "nh",
        "rhyme": "uêm"
    },
    {
        "id": 3958,
        "syllable": "nhêu",
        "consonant": "nh",
        "rhyme": "êu"
    },
    {
        "id": 3959,
        "syllable": "nhe",
        "consonant": "nh",
        "rhyme": "e"
    },
    {
        "id": 3960,
        "syllable": "nhoe",
        "consonant": "nh",
        "rhyme": "oe"
    },
    {
        "id": 3961,
        "syllable": "nhec",
        "consonant": "nh",
        "rhyme": "ec"
    },
    {
        "id": 3962,
        "syllable": "nhoec",
        "consonant": "nh",
        "rhyme": "oec"
    },
    {
        "id": 3963,
        "syllable": "nheng",
        "consonant": "nh",
        "rhyme": "eng"
    },
    {
        "id": 3964,
        "syllable": "nhoeng",
        "consonant": "nh",
        "rhyme": "oeng"
    },
    {
        "id": 3965,
        "syllable": "nhet",
        "consonant": "nh",
        "rhyme": "et"
    },
    {
        "id": 3966,
        "syllable": "nhoet",
        "consonant": "nh",
        "rhyme": "oet"
    },
    {
        "id": 3967,
        "syllable": "nhen",
        "consonant": "nh",
        "rhyme": "en"
    },
    {
        "id": 3968,
        "syllable": "nhoen",
        "consonant": "nh",
        "rhyme": "oen"
    },
    {
        "id": 3969,
        "syllable": "nhep",
        "consonant": "nh",
        "rhyme": "ep"
    },
    {
        "id": 3970,
        "syllable": "nhoep",
        "consonant": "nh",
        "rhyme": "oep"
    },
    {
        "id": 3971,
        "syllable": "nhem",
        "consonant": "nh",
        "rhyme": "em"
    },
    {
        "id": 3972,
        "syllable": "nhoem",
        "consonant": "nh",
        "rhyme": "oem"
    },
    {
        "id": 3973,
        "syllable": "nheo",
        "consonant": "nh",
        "rhyme": "eo"
    },
    {
        "id": 3974,
        "syllable": "nhoeo",
        "consonant": "nh",
        "rhyme": "oeo"
    },
    {
        "id": 3975,
        "syllable": "nhach",
        "consonant": "nh",
        "rhyme": "ach"
    },
    {
        "id": 3976,
        "syllable": "nhoach",
        "consonant": "nh",
        "rhyme": "oach"
    },
    {
        "id": 3977,
        "syllable": "nhanh",
        "consonant": "nh",
        "rhyme": "anh"
    },
    {
        "id": 3978,
        "syllable": "nhoanh",
        "consonant": "nh",
        "rhyme": "oanh"
    },
    {
        "id": 3979,
        "syllable": "nhia",
        "consonant": "nh",
        "rhyme": "ia"
    },
    {
        "id": 3980,
        "syllable": "nhuya",
        "consonant": "nh",
        "rhyme": "uya"
    },
    {
        "id": 3981,
        "syllable": "nhiêc",
        "consonant": "nh",
        "rhyme": "iêc"
    },
    {
        "id": 3982,
        "syllable": "nhiêng",
        "consonant": "nh",
        "rhyme": "iêng"
    },
    {
        "id": 3983,
        "syllable": "nhiêt",
        "consonant": "nh",
        "rhyme": "iêt"
    },
    {
        "id": 3984,
        "syllable": "nhiên",
        "consonant": "nh",
        "rhyme": "iên"
    },
    {
        "id": 3985,
        "syllable": "nhuyêt",
        "consonant": "nh",
        "rhyme": "uyêt"
    },
    {
        "id": 3986,
        "syllable": "nhuyên",
        "consonant": "nh",
        "rhyme": "uyên"
    },
    {
        "id": 3987,
        "syllable": "nhiêp",
        "consonant": "nh",
        "rhyme": "iêp"
    },
    {
        "id": 3988,
        "syllable": "nhuyêp",
        "consonant": "nh",
        "rhyme": "uyêp"
    },
    {
        "id": 3989,
        "syllable": "nhiêm",
        "consonant": "nh",
        "rhyme": "iêm"
    },
    {
        "id": 3990,
        "syllable": "nhuyêm",
        "consonant": "nh",
        "rhyme": "uyêm"
    },
    {
        "id": 3991,
        "syllable": "nhiêu",
        "consonant": "nh",
        "rhyme": "iêu"
    },
    {
        "id": 3992,
        "syllable": "như",
        "consonant": "nh",
        "rhyme": "ư"
    },
    {
        "id": 3993,
        "syllable": "nhưc",
        "consonant": "nh",
        "rhyme": "ưc"
    },
    {
        "id": 3994,
        "syllable": "nhưng",
        "consonant": "nh",
        "rhyme": "ưng"
    },
    {
        "id": 3995,
        "syllable": "nhưt",
        "consonant": "nh",
        "rhyme": "ưt"
    },
    {
        "id": 3996,
        "syllable": "nhưn",
        "consonant": "nh",
        "rhyme": "ưn"
    },
    {
        "id": 3997,
        "syllable": "nhưp",
        "consonant": "nh",
        "rhyme": "ưp"
    },
    {
        "id": 3998,
        "syllable": "nhưm",
        "consonant": "nh",
        "rhyme": "ưm"
    },
    {
        "id": 3999,
        "syllable": "nhưi",
        "consonant": "nh",
        "rhyme": "ưi"
    },
    {
        "id": 4000,
        "syllable": "nhưu",
        "consonant": "nh",
        "rhyme": "ưu"
    },
    {
        "id": 4001,
        "syllable": "nhơ",
        "consonant": "nh",
        "rhyme": "ơ"
    },
    {
        "id": 4002,
        "syllable": "nhuơ",
        "consonant": "nh",
        "rhyme": "uơ"
    },
    {
        "id": 4003,
        "syllable": "nhơng",
        "consonant": "nh",
        "rhyme": "ơng"
    },
    {
        "id": 4004,
        "syllable": "nhơt",
        "consonant": "nh",
        "rhyme": "ơt"
    },
    {
        "id": 4005,
        "syllable": "nhơn",
        "consonant": "nh",
        "rhyme": "ơn"
    },
    {
        "id": 4006,
        "syllable": "nhơp",
        "consonant": "nh",
        "rhyme": "ơp"
    },
    {
        "id": 4007,
        "syllable": "nhơm",
        "consonant": "nh",
        "rhyme": "ơm"
    },
    {
        "id": 4008,
        "syllable": "nhơi",
        "consonant": "nh",
        "rhyme": "ơi"
    },
    {
        "id": 4009,
        "syllable": "nhâc",
        "consonant": "nh",
        "rhyme": "âc"
    },
    {
        "id": 4010,
        "syllable": "nhâng",
        "consonant": "nh",
        "rhyme": "âng"
    },
    {
        "id": 4011,
        "syllable": "nhuâng",
        "consonant": "nh",
        "rhyme": "uâng"
    },
    {
        "id": 4012,
        "syllable": "nhât",
        "consonant": "nh",
        "rhyme": "ât"
    },
    {
        "id": 4013,
        "syllable": "nhuât",
        "consonant": "nh",
        "rhyme": "uât"
    },
    {
        "id": 4014,
        "syllable": "nhân",
        "consonant": "nh",
        "rhyme": "ân"
    },
    {
        "id": 4015,
        "syllable": "nhuân",
        "consonant": "nh",
        "rhyme": "uân"
    },
    {
        "id": 4016,
        "syllable": "nhâp",
        "consonant": "nh",
        "rhyme": "âp"
    },
    {
        "id": 4017,
        "syllable": "nhuâp",
        "consonant": "nh",
        "rhyme": "uâp"
    },
    {
        "id": 4018,
        "syllable": "nhâm",
        "consonant": "nh",
        "rhyme": "âm"
    },
    {
        "id": 4019,
        "syllable": "nhuâm",
        "consonant": "nh",
        "rhyme": "uâm"
    },
    {
        "id": 4020,
        "syllable": "nhây",
        "consonant": "nh",
        "rhyme": "ây"
    },
    {
        "id": 4021,
        "syllable": "nhuây",
        "consonant": "nh",
        "rhyme": "uây"
    },
    {
        "id": 4022,
        "syllable": "nhâu",
        "consonant": "nh",
        "rhyme": "âu"
    },
    {
        "id": 4023,
        "syllable": "nhuâu",
        "consonant": "nh",
        "rhyme": "uâu"
    },
    {
        "id": 4024,
        "syllable": "nhu",
        "consonant": "nh",
        "rhyme": "u"
    },
    {
        "id": 4025,
        "syllable": "nhuc",
        "consonant": "nh",
        "rhyme": "uc"
    },
    {
        "id": 4026,
        "syllable": "nhung",
        "consonant": "nh",
        "rhyme": "ung"
    },
    {
        "id": 4027,
        "syllable": "nhut",
        "consonant": "nh",
        "rhyme": "ut"
    },
    {
        "id": 4028,
        "syllable": "nhun",
        "consonant": "nh",
        "rhyme": "un"
    },
    {
        "id": 4029,
        "syllable": "nhup",
        "consonant": "nh",
        "rhyme": "up"
    },
    {
        "id": 4030,
        "syllable": "nhum",
        "consonant": "nh",
        "rhyme": "um"
    },
    {
        "id": 4031,
        "syllable": "nhui",
        "consonant": "nh",
        "rhyme": "ui"
    },
    {
        "id": 4032,
        "syllable": "nhô",
        "consonant": "nh",
        "rhyme": "ô"
    },
    {
        "id": 4033,
        "syllable": "nhôc",
        "consonant": "nh",
        "rhyme": "ôc"
    },
    {
        "id": 4034,
        "syllable": "nhông",
        "consonant": "nh",
        "rhyme": "ông"
    },
    {
        "id": 4035,
        "syllable": "nhôt",
        "consonant": "nh",
        "rhyme": "ôt"
    },
    {
        "id": 4036,
        "syllable": "nhôn",
        "consonant": "nh",
        "rhyme": "ôn"
    },
    {
        "id": 4037,
        "syllable": "nhôp",
        "consonant": "nh",
        "rhyme": "ôp"
    },
    {
        "id": 4038,
        "syllable": "nhôm",
        "consonant": "nh",
        "rhyme": "ôm"
    },
    {
        "id": 4039,
        "syllable": "nhôi",
        "consonant": "nh",
        "rhyme": "ôi"
    },
    {
        "id": 4040,
        "syllable": "nho",
        "consonant": "nh",
        "rhyme": "o"
    },
    {
        "id": 4041,
        "syllable": "nhooc",
        "consonant": "nh",
        "rhyme": "ooc"
    },
    {
        "id": 4042,
        "syllable": "nhoong",
        "consonant": "nh",
        "rhyme": "oong"
    },
    {
        "id": 4043,
        "syllable": "nhot",
        "consonant": "nh",
        "rhyme": "ot"
    },
    {
        "id": 4044,
        "syllable": "nhon",
        "consonant": "nh",
        "rhyme": "on"
    },
    {
        "id": 4045,
        "syllable": "nhop",
        "consonant": "nh",
        "rhyme": "op"
    },
    {
        "id": 4046,
        "syllable": "nhom",
        "consonant": "nh",
        "rhyme": "om"
    },
    {
        "id": 4047,
        "syllable": "nhoi",
        "consonant": "nh",
        "rhyme": "oi"
    },
    {
        "id": 4048,
        "syllable": "nhoc",
        "consonant": "nh",
        "rhyme": "oc"
    },
    {
        "id": 4049,
        "syllable": "nhong",
        "consonant": "nh",
        "rhyme": "ong"
    },
    {
        "id": 4050,
        "syllable": "nhua",
        "consonant": "nh",
        "rhyme": "ua"
    },
    {
        "id": 4051,
        "syllable": "nhuôc",
        "consonant": "nh",
        "rhyme": "uôc"
    },
    {
        "id": 4052,
        "syllable": "nhuông",
        "consonant": "nh",
        "rhyme": "uông"
    },
    {
        "id": 4053,
        "syllable": "nhuôt",
        "consonant": "nh",
        "rhyme": "uôt"
    },
    {
        "id": 4054,
        "syllable": "nhuôn",
        "consonant": "nh",
        "rhyme": "uôn"
    },
    {
        "id": 4055,
        "syllable": "nhuôp",
        "consonant": "nh",
        "rhyme": "uôp"
    },
    {
        "id": 4056,
        "syllable": "nhuôm",
        "consonant": "nh",
        "rhyme": "uôm"
    },
    {
        "id": 4057,
        "syllable": "nhuôi",
        "consonant": "nh",
        "rhyme": "uôi"
    },
    {
        "id": 4058,
        "syllable": "nhưa",
        "consonant": "nh",
        "rhyme": "ưa"
    },
    {
        "id": 4059,
        "syllable": "nhươc",
        "consonant": "nh",
        "rhyme": "ươc"
    },
    {
        "id": 4060,
        "syllable": "nhương",
        "consonant": "nh",
        "rhyme": "ương"
    },
    {
        "id": 4061,
        "syllable": "nhươt",
        "consonant": "nh",
        "rhyme": "ươt"
    },
    {
        "id": 4062,
        "syllable": "nhươn",
        "consonant": "nh",
        "rhyme": "ươn"
    },
    {
        "id": 4063,
        "syllable": "nhươp",
        "consonant": "nh",
        "rhyme": "ươp"
    },
    {
        "id": 4064,
        "syllable": "nhươm",
        "consonant": "nh",
        "rhyme": "ươm"
    },
    {
        "id": 4065,
        "syllable": "nhươi",
        "consonant": "nh",
        "rhyme": "ươi"
    },
    {
        "id": 4066,
        "syllable": "nha",
        "consonant": "nh",
        "rhyme": "a"
    },
    {
        "id": 4067,
        "syllable": "nhoa",
        "consonant": "nh",
        "rhyme": "oa"
    },
    {
        "id": 4068,
        "syllable": "nhac",
        "consonant": "nh",
        "rhyme": "ac"
    },
    {
        "id": 4069,
        "syllable": "nhoac",
        "consonant": "nh",
        "rhyme": "oac"
    },
    {
        "id": 4070,
        "syllable": "nhang",
        "consonant": "nh",
        "rhyme": "ang"
    },
    {
        "id": 4071,
        "syllable": "nhoang",
        "consonant": "nh",
        "rhyme": "oang"
    },
    {
        "id": 4072,
        "syllable": "nhat",
        "consonant": "nh",
        "rhyme": "at"
    },
    {
        "id": 4073,
        "syllable": "nhoat",
        "consonant": "nh",
        "rhyme": "oat"
    },
    {
        "id": 4074,
        "syllable": "nhan",
        "consonant": "nh",
        "rhyme": "an"
    },
    {
        "id": 4075,
        "syllable": "nhoan",
        "consonant": "nh",
        "rhyme": "oan"
    },
    {
        "id": 4076,
        "syllable": "nhap",
        "consonant": "nh",
        "rhyme": "ap"
    },
    {
        "id": 4077,
        "syllable": "nhoap",
        "consonant": "nh",
        "rhyme": "oap"
    },
    {
        "id": 4078,
        "syllable": "nham",
        "consonant": "nh",
        "rhyme": "am"
    },
    {
        "id": 4079,
        "syllable": "nhoam",
        "consonant": "nh",
        "rhyme": "oam"
    },
    {
        "id": 4080,
        "syllable": "nhai",
        "consonant": "nh",
        "rhyme": "ai"
    },
    {
        "id": 4081,
        "syllable": "nhoai",
        "consonant": "nh",
        "rhyme": "oai"
    },
    {
        "id": 4082,
        "syllable": "nhao",
        "consonant": "nh",
        "rhyme": "ao"
    },
    {
        "id": 4083,
        "syllable": "nhoao",
        "consonant": "nh",
        "rhyme": "oao"
    },
    {
        "id": 4084,
        "syllable": "nhăc",
        "consonant": "nh",
        "rhyme": "ăc"
    },
    {
        "id": 4085,
        "syllable": "nhoăc",
        "consonant": "nh",
        "rhyme": "oăc"
    },
    {
        "id": 4086,
        "syllable": "nhăng",
        "consonant": "nh",
        "rhyme": "ăng"
    },
    {
        "id": 4087,
        "syllable": "nhoăng",
        "consonant": "nh",
        "rhyme": "oăng"
    },
    {
        "id": 4088,
        "syllable": "nhăt",
        "consonant": "nh",
        "rhyme": "ăt"
    },
    {
        "id": 4089,
        "syllable": "nhoăt",
        "consonant": "nh",
        "rhyme": "oăt"
    },
    {
        "id": 4090,
        "syllable": "nhăn",
        "consonant": "nh",
        "rhyme": "ăn"
    },
    {
        "id": 4091,
        "syllable": "nhoăn",
        "consonant": "nh",
        "rhyme": "oăn"
    },
    {
        "id": 4092,
        "syllable": "nhăp",
        "consonant": "nh",
        "rhyme": "ăp"
    },
    {
        "id": 4093,
        "syllable": "nhoăp",
        "consonant": "nh",
        "rhyme": "oăp"
    },
    {
        "id": 4094,
        "syllable": "nhăm",
        "consonant": "nh",
        "rhyme": "ăm"
    },
    {
        "id": 4095,
        "syllable": "nhoăm",
        "consonant": "nh",
        "rhyme": "oăm"
    },
    {
        "id": 4096,
        "syllable": "nhay",
        "consonant": "nh",
        "rhyme": "ay"
    },
    {
        "id": 4097,
        "syllable": "nhoay",
        "consonant": "nh",
        "rhyme": "oay"
    },
    {
        "id": 4098,
        "syllable": "nhau",
        "consonant": "nh",
        "rhyme": "au"
    },
    {
        "id": 4099,
        "syllable": "nhoau",
        "consonant": "nh",
        "rhyme": "oau"
    },
    {
        "id": 4100,
        "syllable": "i",
        "consonant": "",
        "rhyme": "i"
    },
    {
        "id": 4101,
        "syllable": "uy",
        "consonant": "",
        "rhyme": "uy"
    },
    {
        "id": 4102,
        "syllable": "ich",
        "consonant": "",
        "rhyme": "ich"
    },
    {
        "id": 4103,
        "syllable": "uych",
        "consonant": "",
        "rhyme": "uych"
    },
    {
        "id": 4104,
        "syllable": "inh",
        "consonant": "",
        "rhyme": "inh"
    },
    {
        "id": 4105,
        "syllable": "uynh",
        "consonant": "",
        "rhyme": "uynh"
    },
    {
        "id": 4106,
        "syllable": "it",
        "consonant": "",
        "rhyme": "it"
    },
    {
        "id": 4107,
        "syllable": "uyt",
        "consonant": "",
        "rhyme": "uyt"
    },
    {
        "id": 4108,
        "syllable": "in",
        "consonant": "",
        "rhyme": "in"
    },
    {
        "id": 4109,
        "syllable": "uyn",
        "consonant": "",
        "rhyme": "uyn"
    },
    {
        "id": 4110,
        "syllable": "ip",
        "consonant": "",
        "rhyme": "ip"
    },
    {
        "id": 4111,
        "syllable": "uyp",
        "consonant": "",
        "rhyme": "uyp"
    },
    {
        "id": 4112,
        "syllable": "im",
        "consonant": "",
        "rhyme": "im"
    },
    {
        "id": 4113,
        "syllable": "uym",
        "consonant": "",
        "rhyme": "uym"
    },
    {
        "id": 4114,
        "syllable": "iu",
        "consonant": "",
        "rhyme": "iu"
    },
    {
        "id": 4115,
        "syllable": "uyu",
        "consonant": "",
        "rhyme": "uyu"
    },
    {
        "id": 4116,
        "syllable": "ê",
        "consonant": "",
        "rhyme": "ê"
    },
    {
        "id": 4117,
        "syllable": "uê",
        "consonant": "",
        "rhyme": "uê"
    },
    {
        "id": 4118,
        "syllable": "êch",
        "consonant": "",
        "rhyme": "êch"
    },
    {
        "id": 4119,
        "syllable": "uêch",
        "consonant": "",
        "rhyme": "uêch"
    },
    {
        "id": 4120,
        "syllable": "ênh",
        "consonant": "",
        "rhyme": "ênh"
    },
    {
        "id": 4121,
        "syllable": "uênh",
        "consonant": "",
        "rhyme": "uênh"
    },
    {
        "id": 4122,
        "syllable": "êt",
        "consonant": "",
        "rhyme": "êt"
    },
    {
        "id": 4123,
        "syllable": "uêt",
        "consonant": "",
        "rhyme": "uêt"
    },
    {
        "id": 4124,
        "syllable": "ên",
        "consonant": "",
        "rhyme": "ên"
    },
    {
        "id": 4125,
        "syllable": "uên",
        "consonant": "",
        "rhyme": "uên"
    },
    {
        "id": 4126,
        "syllable": "êp",
        "consonant": "",
        "rhyme": "êp"
    },
    {
        "id": 4127,
        "syllable": "uêp",
        "consonant": "",
        "rhyme": "uêp"
    },
    {
        "id": 4128,
        "syllable": "êm",
        "consonant": "",
        "rhyme": "êm"
    },
    {
        "id": 4129,
        "syllable": "uêm",
        "consonant": "",
        "rhyme": "uêm"
    },
    {
        "id": 4130,
        "syllable": "êu",
        "consonant": "",
        "rhyme": "êu"
    },
    {
        "id": 4131,
        "syllable": "e",
        "consonant": "",
        "rhyme": "e"
    },
    {
        "id": 4132,
        "syllable": "oe",
        "consonant": "",
        "rhyme": "oe"
    },
    {
        "id": 4133,
        "syllable": "ec",
        "consonant": "",
        "rhyme": "ec"
    },
    {
        "id": 4134,
        "syllable": "oec",
        "consonant": "",
        "rhyme": "oec"
    },
    {
        "id": 4135,
        "syllable": "eng",
        "consonant": "",
        "rhyme": "eng"
    },
    {
        "id": 4136,
        "syllable": "oeng",
        "consonant": "",
        "rhyme": "oeng"
    },
    {
        "id": 4137,
        "syllable": "et",
        "consonant": "",
        "rhyme": "et"
    },
    {
        "id": 4138,
        "syllable": "oet",
        "consonant": "",
        "rhyme": "oet"
    },
    {
        "id": 4139,
        "syllable": "en",
        "consonant": "",
        "rhyme": "en"
    },
    {
        "id": 4140,
        "syllable": "oen",
        "consonant": "",
        "rhyme": "oen"
    },
    {
        "id": 4141,
        "syllable": "ep",
        "consonant": "",
        "rhyme": "ep"
    },
    {
        "id": 4142,
        "syllable": "oep",
        "consonant": "",
        "rhyme": "oep"
    },
    {
        "id": 4143,
        "syllable": "em",
        "consonant": "",
        "rhyme": "em"
    },
    {
        "id": 4144,
        "syllable": "oem",
        "consonant": "",
        "rhyme": "oem"
    },
    {
        "id": 4145,
        "syllable": "eo",
        "consonant": "",
        "rhyme": "eo"
    },
    {
        "id": 4146,
        "syllable": "oeo",
        "consonant": "",
        "rhyme": "oeo"
    },
    {
        "id": 4147,
        "syllable": "ach",
        "consonant": "",
        "rhyme": "ach"
    },
    {
        "id": 4148,
        "syllable": "oach",
        "consonant": "",
        "rhyme": "oach"
    },
    {
        "id": 4149,
        "syllable": "anh",
        "consonant": "",
        "rhyme": "anh"
    },
    {
        "id": 4150,
        "syllable": "oanh",
        "consonant": "",
        "rhyme": "oanh"
    },
    {
        "id": 4151,
        "syllable": "ya",
        "consonant": "",
        "rhyme": "ya"
    },
    {
        "id": 4152,
        "syllable": "uya",
        "consonant": "",
        "rhyme": "uya"
    },
    {
        "id": 4153,
        "syllable": "yec",
        "consonant": "",
        "rhyme": "yec"
    },
    {
        "id": 4154,
        "syllable": "yêng",
        "consonant": "",
        "rhyme": "yêng"
    },
    {
        "id": 4155,
        "syllable": "yêt",
        "consonant": "",
        "rhyme": "yêt"
    },
    {
        "id": 4156,
        "syllable": "yên",
        "consonant": "",
        "rhyme": "yên"
    },
    {
        "id": 4157,
        "syllable": "uyêt",
        "consonant": "",
        "rhyme": "uyêt"
    },
    {
        "id": 4158,
        "syllable": "uyên",
        "consonant": "",
        "rhyme": "uyên"
    },
    {
        "id": 4159,
        "syllable": "yêp",
        "consonant": "",
        "rhyme": "yêp"
    },
    {
        "id": 4160,
        "syllable": "uyêp",
        "consonant": "",
        "rhyme": "uyêp"
    },
    {
        "id": 4161,
        "syllable": "yêm",
        "consonant": "",
        "rhyme": "yêm"
    },
    {
        "id": 4162,
        "syllable": "uyêm",
        "consonant": "",
        "rhyme": "uyêm"
    },
    {
        "id": 4163,
        "syllable": "yêu",
        "consonant": "",
        "rhyme": "yêu"
    },
    {
        "id": 4164,
        "syllable": "ư",
        "consonant": "",
        "rhyme": "ư"
    },
    {
        "id": 4165,
        "syllable": "ưc",
        "consonant": "",
        "rhyme": "ưc"
    },
    {
        "id": 4166,
        "syllable": "ưng",
        "consonant": "",
        "rhyme": "ưng"
    },
    {
        "id": 4167,
        "syllable": "ưt",
        "consonant": "",
        "rhyme": "ưt"
    },
    {
        "id": 4168,
        "syllable": "ưn",
        "consonant": "",
        "rhyme": "ưn"
    },
    {
        "id": 4169,
        "syllable": "ưp",
        "consonant": "",
        "rhyme": "ưp"
    },
    {
        "id": 4170,
        "syllable": "ưm",
        "consonant": "",
        "rhyme": "ưm"
    },
    {
        "id": 4171,
        "syllable": "ưi",
        "consonant": "",
        "rhyme": "ưi"
    },
    {
        "id": 4172,
        "syllable": "ưu",
        "consonant": "",
        "rhyme": "ưu"
    },
    {
        "id": 4173,
        "syllable": "ơ",
        "consonant": "",
        "rhyme": "ơ"
    },
    {
        "id": 4174,
        "syllable": "uơ",
        "consonant": "",
        "rhyme": "uơ"
    },
    {
        "id": 4175,
        "syllable": "ơng",
        "consonant": "",
        "rhyme": "ơng"
    },
    {
        "id": 4176,
        "syllable": "ơt",
        "consonant": "",
        "rhyme": "ơt"
    },
    {
        "id": 4177,
        "syllable": "ơn",
        "consonant": "",
        "rhyme": "ơn"
    },
    {
        "id": 4178,
        "syllable": "ơp",
        "consonant": "",
        "rhyme": "ơp"
    },
    {
        "id": 4179,
        "syllable": "ơm",
        "consonant": "",
        "rhyme": "ơm"
    },
    {
        "id": 4180,
        "syllable": "ơi",
        "consonant": "",
        "rhyme": "ơi"
    },
    {
        "id": 4181,
        "syllable": "âc",
        "consonant": "",
        "rhyme": "âc"
    },
    {
        "id": 4182,
        "syllable": "âng",
        "consonant": "",
        "rhyme": "âng"
    },
    {
        "id": 4183,
        "syllable": "uâng",
        "consonant": "",
        "rhyme": "uâng"
    },
    {
        "id": 4184,
        "syllable": "ât",
        "consonant": "",
        "rhyme": "ât"
    },
    {
        "id": 4185,
        "syllable": "uât",
        "consonant": "",
        "rhyme": "uât"
    },
    {
        "id": 4186,
        "syllable": "ân",
        "consonant": "",
        "rhyme": "ân"
    },
    {
        "id": 4187,
        "syllable": "uân",
        "consonant": "",
        "rhyme": "uân"
    },
    {
        "id": 4188,
        "syllable": "âp",
        "consonant": "",
        "rhyme": "âp"
    },
    {
        "id": 4189,
        "syllable": "uâp",
        "consonant": "",
        "rhyme": "uâp"
    },
    {
        "id": 4190,
        "syllable": "âm",
        "consonant": "",
        "rhyme": "âm"
    },
    {
        "id": 4191,
        "syllable": "uâm",
        "consonant": "",
        "rhyme": "uâm"
    },
    {
        "id": 4192,
        "syllable": "ây",
        "consonant": "",
        "rhyme": "ây"
    },
    {
        "id": 4193,
        "syllable": "uây",
        "consonant": "",
        "rhyme": "uây"
    },
    {
        "id": 4194,
        "syllable": "âu",
        "consonant": "",
        "rhyme": "âu"
    },
    {
        "id": 4195,
        "syllable": "uâu",
        "consonant": "",
        "rhyme": "uâu"
    },
    {
        "id": 4196,
        "syllable": "u",
        "consonant": "",
        "rhyme": "u"
    },
    {
        "id": 4197,
        "syllable": "uc",
        "consonant": "",
        "rhyme": "uc"
    },
    {
        "id": 4198,
        "syllable": "ung",
        "consonant": "",
        "rhyme": "ung"
    },
    {
        "id": 4199,
        "syllable": "ut",
        "consonant": "",
        "rhyme": "ut"
    },
    {
        "id": 4200,
        "syllable": "un",
        "consonant": "",
        "rhyme": "un"
    },
    {
        "id": 4201,
        "syllable": "up",
        "consonant": "",
        "rhyme": "up"
    },
    {
        "id": 4202,
        "syllable": "um",
        "consonant": "",
        "rhyme": "um"
    },
    {
        "id": 4203,
        "syllable": "ui",
        "consonant": "",
        "rhyme": "ui"
    },
    {
        "id": 4204,
        "syllable": "ô",
        "consonant": "",
        "rhyme": "ô"
    },
    {
        "id": 4205,
        "syllable": "ôc",
        "consonant": "",
        "rhyme": "ôc"
    },
    {
        "id": 4206,
        "syllable": "ông",
        "consonant": "",
        "rhyme": "ông"
    },
    {
        "id": 4207,
        "syllable": "ôt",
        "consonant": "",
        "rhyme": "ôt"
    },
    {
        "id": 4208,
        "syllable": "ôn",
        "consonant": "",
        "rhyme": "ôn"
    },
    {
        "id": 4209,
        "syllable": "ôp",
        "consonant": "",
        "rhyme": "ôp"
    },
    {
        "id": 4210,
        "syllable": "ôm",
        "consonant": "",
        "rhyme": "ôm"
    },
    {
        "id": 4211,
        "syllable": "ôi",
        "consonant": "",
        "rhyme": "ôi"
    },
    {
        "id": 4212,
        "syllable": "o",
        "consonant": "",
        "rhyme": "o"
    },
    {
        "id": 4213,
        "syllable": "ooc",
        "consonant": "",
        "rhyme": "ooc"
    },
    {
        "id": 4214,
        "syllable": "oong",
        "consonant": "",
        "rhyme": "oong"
    },
    {
        "id": 4215,
        "syllable": "ot",
        "consonant": "",
        "rhyme": "ot"
    },
    {
        "id": 4216,
        "syllable": "on",
        "consonant": "",
        "rhyme": "on"
    },
    {
        "id": 4217,
        "syllable": "op",
        "consonant": "",
        "rhyme": "op"
    },
    {
        "id": 4218,
        "syllable": "om",
        "consonant": "",
        "rhyme": "om"
    },
    {
        "id": 4219,
        "syllable": "oi",
        "consonant": "",
        "rhyme": "oi"
    },
    {
        "id": 4220,
        "syllable": "oc",
        "consonant": "",
        "rhyme": "oc"
    },
    {
        "id": 4221,
        "syllable": "ong",
        "consonant": "",
        "rhyme": "ong"
    },
    {
        "id": 4222,
        "syllable": "ua",
        "consonant": "",
        "rhyme": "ua"
    },
    {
        "id": 4223,
        "syllable": "uôc",
        "consonant": "",
        "rhyme": "uôc"
    },
    {
        "id": 4224,
        "syllable": "uông",
        "consonant": "",
        "rhyme": "uông"
    },
    {
        "id": 4225,
        "syllable": "uôt",
        "consonant": "",
        "rhyme": "uôt"
    },
    {
        "id": 4226,
        "syllable": "uôn",
        "consonant": "",
        "rhyme": "uôn"
    },
    {
        "id": 4227,
        "syllable": "uôp",
        "consonant": "",
        "rhyme": "uôp"
    },
    {
        "id": 4228,
        "syllable": "uôm",
        "consonant": "",
        "rhyme": "uôm"
    },
    {
        "id": 4229,
        "syllable": "uôi",
        "consonant": "",
        "rhyme": "uôi"
    },
    {
        "id": 4230,
        "syllable": "ưa",
        "consonant": "",
        "rhyme": "ưa"
    },
    {
        "id": 4231,
        "syllable": "ươc",
        "consonant": "",
        "rhyme": "ươc"
    },
    {
        "id": 4232,
        "syllable": "ương",
        "consonant": "",
        "rhyme": "ương"
    },
    {
        "id": 4233,
        "syllable": "ươt",
        "consonant": "",
        "rhyme": "ươt"
    },
    {
        "id": 4234,
        "syllable": "ươn",
        "consonant": "",
        "rhyme": "ươn"
    },
    {
        "id": 4235,
        "syllable": "ươp",
        "consonant": "",
        "rhyme": "ươp"
    },
    {
        "id": 4236,
        "syllable": "ươm",
        "consonant": "",
        "rhyme": "ươm"
    },
    {
        "id": 4237,
        "syllable": "ươi",
        "consonant": "",
        "rhyme": "ươi"
    },
    {
        "id": 4238,
        "syllable": "a",
        "consonant": "",
        "rhyme": "a"
    },
    {
        "id": 4239,
        "syllable": "oa",
        "consonant": "",
        "rhyme": "oa"
    },
    {
        "id": 4240,
        "syllable": "ac",
        "consonant": "",
        "rhyme": "ac"
    },
    {
        "id": 4241,
        "syllable": "oac",
        "consonant": "",
        "rhyme": "oac"
    },
    {
        "id": 4242,
        "syllable": "ang",
        "consonant": "",
        "rhyme": "ang"
    },
    {
        "id": 4243,
        "syllable": "oang",
        "consonant": "",
        "rhyme": "oang"
    },
    {
        "id": 4244,
        "syllable": "at",
        "consonant": "",
        "rhyme": "at"
    },
    {
        "id": 4245,
        "syllable": "oat",
        "consonant": "",
        "rhyme": "oat"
    },
    {
        "id": 4246,
        "syllable": "an",
        "consonant": "",
        "rhyme": "an"
    },
    {
        "id": 4247,
        "syllable": "oan",
        "consonant": "",
        "rhyme": "oan"
    },
    {
        "id": 4248,
        "syllable": "ap",
        "consonant": "",
        "rhyme": "ap"
    },
    {
        "id": 4249,
        "syllable": "oap",
        "consonant": "",
        "rhyme": "oap"
    },
    {
        "id": 4250,
        "syllable": "am",
        "consonant": "",
        "rhyme": "am"
    },
    {
        "id": 4251,
        "syllable": "oam",
        "consonant": "",
        "rhyme": "oam"
    },
    {
        "id": 4252,
        "syllable": "ai",
        "consonant": "",
        "rhyme": "ai"
    },
    {
        "id": 4253,
        "syllable": "oai",
        "consonant": "",
        "rhyme": "oai"
    },
    {
        "id": 4254,
        "syllable": "ao",
        "consonant": "",
        "rhyme": "ao"
    },
    {
        "id": 4255,
        "syllable": "oao",
        "consonant": "",
        "rhyme": "oao"
    },
    {
        "id": 4256,
        "syllable": "ăc",
        "consonant": "",
        "rhyme": "ăc"
    },
    {
        "id": 4257,
        "syllable": "oăc",
        "consonant": "",
        "rhyme": "oăc"
    },
    {
        "id": 4258,
        "syllable": "ăng",
        "consonant": "",
        "rhyme": "ăng"
    },
    {
        "id": 4259,
        "syllable": "oăng",
        "consonant": "",
        "rhyme": "oăng"
    },
    {
        "id": 4260,
        "syllable": "ăt",
        "consonant": "",
        "rhyme": "ăt"
    },
    {
        "id": 4261,
        "syllable": "oăt",
        "consonant": "",
        "rhyme": "oăt"
    },
    {
        "id": 4262,
        "syllable": "ăn",
        "consonant": "",
        "rhyme": "ăn"
    },
    {
        "id": 4263,
        "syllable": "oăn",
        "consonant": "",
        "rhyme": "oăn"
    },
    {
        "id": 4264,
        "syllable": "ăp",
        "consonant": "",
        "rhyme": "ăp"
    },
    {
        "id": 4265,
        "syllable": "oăp",
        "consonant": "",
        "rhyme": "oăp"
    },
    {
        "id": 4266,
        "syllable": "ăm",
        "consonant": "",
        "rhyme": "ăm"
    },
    {
        "id": 4267,
        "syllable": "oăm",
        "consonant": "",
        "rhyme": "oăm"
    },
    {
        "id": 4268,
        "syllable": "ay",
        "consonant": "",
        "rhyme": "ay"
    },
    {
        "id": 4269,
        "syllable": "oay",
        "consonant": "",
        "rhyme": "oay"
    },
    {
        "id": 4270,
        "syllable": "au",
        "consonant": "",
        "rhyme": "au"
    },
    {
        "id": 4271,
        "syllable": "oau",
        "consonant": "",
        "rhyme": "oau"
    }
];