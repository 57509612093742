import React, { memo } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import * as yup from 'yup';

// components
import InputField from '../../../../../components/Common/InputField';
import InputImageField from '../../../../../components/Common/InputImageField';
import InputVoiceField from '../../../../../components/Common/InputVoiceField';

// local
import CollectionCategoryFormCtrl from './CollectionCategoryForm.ctrl';

// form validation schema
export const formValidationSchema = yup.object().shape({
  name: yup.string().required('Bạn cần nhập tên của thể loại').label('Tên'),
  nameVoiceUrl: yup
    .string()
    .typeError('Bạn cần chọn giọng đọc cho tên của thể loại')
    .required('Bạn cần chọn giọng đọc cho tên của thể loại')
    .label('Giọng đọc tên'),
  description: yup.string().required('Bạn cần nhập mô tả của thể loại').label('Mô tả'),
  descriptionVoiceUrl: yup
    .string()
    .typeError('Bạn cần chọn giọng đọc cho mô tả của thể loại')
    .required('Bạn cần chọn giọng đọc cho mô tả của thể loại')
    .label('Giọng đọc mô tả'),
  previewImageUrl: yup.string().required('Bạn cần upload một ảnh').label('Ảnh'),
});

const CollectionCategoryForm = memo(() => {
  // -- ctrl --
  const {
    // form state
    // preview image url
    previewImageUrl,
    setPreviewImageUrl,
  } = CollectionCategoryFormCtrl.useContainer();

  return (
    <Form onSubmit={() => {}}>
      {/* name */}
      <FormGroup>
        <Label htmlFor="name" className="t-flex">
          <span>Tên thể loại</span>

          {/* name voice */}
          <InputVoiceField fieldName="name" fieldVoiceUrlName="nameVoiceUrl" fieldVoiceUrlId="nameVoiceUrl" />
        </Label>

        <InputField type="text" name="name" id="name" placeholder="Nhập tên thể loại" />
      </FormGroup>

      {/* description */}
      <FormGroup>
        <Label htmlFor="description" className="t-flex">
          <span>Mô tả</span>

          {/* description voice */}
          <InputVoiceField
            fieldName="description"
            fieldVoiceUrlName="descriptionVoiceUrl"
            fieldVoiceUrlId="descriptionVoiceUrl"
          />
        </Label>

        <InputField type="textarea" name="description" id="description" placeholder="Nhập mô tả" />
      </FormGroup>

      {/* image */}
      <FormGroup>
        <Label>Ảnh</Label>
        <InputImageField
          name="image"
          id="image"
          accept="image/*"
          previewImageUrl={previewImageUrl}
          setPreviewImageUrl={setPreviewImageUrl}
        />
      </FormGroup>
    </Form>
  );
});

export default () => (
  <CollectionCategoryFormCtrl.Provider>
    <CollectionCategoryForm />
  </CollectionCategoryFormCtrl.Provider>
);
