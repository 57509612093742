import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListCourse } from '../../actions/ActionPublishManagement';
import Header from '../../components/Common/Header';
import { RequestFilter } from '../../components/PublishManagement/RequestFilter';
import { RequestList } from '../../components/PublishManagement/RequestList';
import './style.scss';

export const PublishManagement = (props) => {
    const dispatch = useDispatch();
    const { listCourse, filter, search } = useSelector(state => state.publishManagement);
    useEffect(() => {
      dispatch(getListCourse());
    }, []);
    return (
        <>
            <Header />
            <div className="publish-management">
                <div className="publish-management-title">
                    Trang quản lý khóa học chờ duyệt
                </div>
                <RequestFilter filter={filter} search={search} />
                <div className="request-list-container">
                 <RequestList data={listCourse} />
                </div>
            </div>
        </>
    );
}