import React from 'react';
import { connect } from 'react-redux';
import { changeQuiz, changeQuizPart } from '../../../actions/ActionQuiz';
import './style.scss';
import PopoverCustom from '../../Popover/PopoverCustom';
import ComponentOption from '../../Common/ComponentOption';
import '../quiz.scss';
import OptionText from '../../OptionQuiz/OptionText';
import OptionImage from '../../OptionQuiz/OptionImage';
import OptionVideo from '../../OptionQuiz/OptionVideo';
import { toast } from 'react-toastify';

class PopoverWrongAnswer extends React.Component {
  componentWillUnmount() {
    document.removeEventListener('click', this.handleTouchOutside);
  }

  handleTouchOutside = (event) => {
    const { testClass, className } = this.props;
    if (
      (this.props.isOpen !== '' && !document.querySelector(`.${className}`)) ||
      (document.querySelector(testClass) && !document.querySelector(testClass).contains(event.target))
    ) {
      // this.props.toggle();
    }
  };
  componentDidMount() {
    document.addEventListener('click', this.handleTouchOutside);
  }
  render() {
    const { quizPart, index, quizParts } = this.props;
    const { currentSlide, learningPoints } = this.props;
    const learningCurrent = learningPoints.find((learning) => learning.id === currentSlide) || {};
    return (
      <PopoverCustom
        width="350px"
        id={this.props.id}
        toggle={this.props.toggle}
        popoverOpen={this.props.isOpen === this.props.index}
        className={this.props.className}
      >
        <div className="">
          {quizPart.isHidden ? (
            <div>
              <button
                onClick={() => {
                  this.props.changeQuiz(
                    'quizParts',
                    quizParts.map((part, idx) => {
                      if (index === idx) {
                        return { ...part, isHidden: false };
                      }
                      return part;
                    }),
                    'quiz',
                    this.props.currentSlide,
                  );
                }}
                style={{ float: 'right', borderRadius: '50%' }}
                className="btn btn-danger"
              >
                Xoá
              </button>
            </div>
          ) : null}

          <div className="text-guid">Đây là nội dung câu trả lời tương đồng với đáp án được ẩn đi</div>
          {(() => {
            if (quizPart.wrongAnswer.type === 'image') {
              return (
                <OptionImage
                  icon="none"
                  url={quizPart.wrongAnswer.imageUrl || ''}
                  id={`quiz-img-${index}`}
                  handleChangeImage={(value) =>
                    this.props.changeQuizPart(
                      'wrongAnswer',
                      { ...quizPart.wrongAnswer, file: value },
                      learningCurrent,
                      index,
                    )
                  }
                />
              );
            }
            if (quizPart.wrongAnswer.type === 'video') {
              return (
                <OptionVideo
                  icon="none"
                  url={quizPart.wrongAnswer.videoUrl || ''}
                  id={`quiz-video-${index}`}
                  handleChangeVideo={(value) =>
                    this.props.changeQuizPart(
                      'wrongAnswer',
                      { ...quizPart.wrongAnswer, file: value },
                      learningCurrent,
                      index,
                    )
                  }
                />
              );
            }
            return (
              <OptionText
                idOpt={`pwa-${index}`}
                icon="none"
                value={quizPart.wrongAnswer.text || ''}
                handleChangeVoice={(audio) =>
                  this.props.changeQuizPart(
                    'wrongAnswer',
                    { ...quizPart.wrongAnswer, audioUrl: audio },
                    learningCurrent,
                    index,
                  )
                }
                handleChangeText={(value) =>
                  this.props.changeQuizPart(
                    'wrongAnswer',
                    { ...quizPart.wrongAnswer, text: value },
                    learningCurrent,
                    index,
                  )
                }
              />
            );
          })()}
          <div style={{ margin: '10px auto 0' }} className="fl_b">
            <ComponentOption
              type={quizPart.wrongAnswer.type || 'text'}
              speak="square"
              changeOption={(e) =>
                this.props.changeQuizPart('wrongAnswer', { ...quizPart.wrongAnswer, type: e }, learningCurrent, index)
              }
              style={{ position: 'initial'}}
            />
            <button
              onClick={() => {
                if (quizPart.wrongAnswer.type === 'text' && !quizPart.wrongAnswer.text) {
                  return toast.error('Vui lòng nhập dữ liệu');
                }
                if (
                  quizPart.wrongAnswer.type === 'video' &&
                  !quizPart.wrongAnswer.imageUrl &&
                  quizPart.wrongAnswer.type === 'video' &&
                  !quizPart.wrongAnswer.file
                ) {
                  return toast.error('Vui lòng tải video');
                }
                if (
                  quizPart.wrongAnswer.type === 'image' &&
                  !quizPart.wrongAnswer.imageUrl &&
                  quizPart.wrongAnswer.type === 'image' &&
                  !quizPart.wrongAnswer.file
                ) {
                  return toast.error('Vui lòng tải hình ảnh');
                }
                this.props.changeQuiz(
                  'quizParts',
                  quizParts.map((part, idx) => {
                    if (index === idx) {
                      return { ...part, isHidden: true };
                    }
                    return part;
                  }),
                  'quiz',
                  this.props.currentSlide,
                );
                this.props.toggle();
              }}
              className="btn btn-primary"
            >
              Lưu
            </button>
          </div>
        </div>
      </PopoverCustom>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuiz, changeQuizPart })(PopoverWrongAnswer);
