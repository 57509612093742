// local
import { LUCKY_BOX_RARITY } from "./luckyBoxRarity"

export const COLLECTION_ITEM_RARITY = {
  NORMAL: 'normal',
  RARE: 'rare',
  EXTREMELY_RARE: 'extremely rare',
}

export const LUCKY_BOX_RARITY_TO_COLLECTION_ITEM_RARITY_MAPPING = {
  [LUCKY_BOX_RARITY.NORMAL]: COLLECTION_ITEM_RARITY.NORMAL,
  [LUCKY_BOX_RARITY.RARE]: COLLECTION_ITEM_RARITY.RARE,
  [LUCKY_BOX_RARITY.EXTREMELY_RARE]: COLLECTION_ITEM_RARITY.EXTREMELY_RARE,
}

export const JS_FIELD_TO_COLLECTION_ITEM_RARITY_MAPPING = {
  normal: COLLECTION_ITEM_RARITY.NORMAL,
  rare: COLLECTION_ITEM_RARITY.RARE,
  extremelyRare: COLLECTION_ITEM_RARITY.EXTREMELY_RARE,
}

export const COLLECTION_ITEM_RARITY_NAME = {
  [COLLECTION_ITEM_RARITY.NORMAL]: 'Thường',
  [COLLECTION_ITEM_RARITY.RARE]: 'Hiếm',
  [COLLECTION_ITEM_RARITY.EXTREMELY_RARE]: 'Cực hiếm',
}

export const COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS = {
  [COLLECTION_ITEM_RARITY.NORMAL]: 'success',
  [COLLECTION_ITEM_RARITY.RARE]: 'warning',
  [COLLECTION_ITEM_RARITY.EXTREMELY_RARE]: 'danger',
}

export const COLLECTION_ITEM_RARITY_OPTIONS = [
  {
    value: COLLECTION_ITEM_RARITY.NORMAL,
    label: COLLECTION_ITEM_RARITY_NAME[COLLECTION_ITEM_RARITY.NORMAL],
    suffixClass: COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_RARITY.NORMAL],
  },
  {
    value: COLLECTION_ITEM_RARITY.RARE,
    label: COLLECTION_ITEM_RARITY_NAME[COLLECTION_ITEM_RARITY.RARE],
    suffixClass: COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_RARITY.RARE],
  },
  {
    value: COLLECTION_ITEM_RARITY.EXTREMELY_RARE,
    label: COLLECTION_ITEM_RARITY_NAME[COLLECTION_ITEM_RARITY.EXTREMELY_RARE],
    suffixClass: COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_RARITY.EXTREMELY_RARE],
  },
]