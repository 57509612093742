export const fakeDataUser = {
  exp: 1681343816,
  iat: 1681307817,
  auth_time: 1681307816,
  jti: '78889e61-98d4-44e3-b97c-a20b25fa5919',
  iss: 'https://dev-accounts.vbee.vn/auth/realms/VietStudy',
  aud: ['cms-service', 'account'],
  sub: '8d6c697b-f266-4f12-9a73-56e45c33b306',
  typ: 'Bearer',
  azp: 'cms-fe',
  nonce: '954d3952-5a2c-4e5a-b850-7b72b340e8d4',
  session_state: 'e42ac8e6-5f46-431a-b480-a3b8a7f4e317',
  acr: '1',
  'allowed-origins': ['http://localhost:8080', 'http://vietstudy.online', 'https://vietstudy.online'],
  realm_access: {
    roles: ['default-roles-vietstudy', 'offline_access', 'cms-admin', 'uma_authorization'],
  },
  resource_access: {
    'cms-fe': {
      roles: ['creator', 'admin', 'publisher'],
    },
    'cms-service': {
      roles: ['creator', 'publisher', 'admin'],
    },
    account: {
      roles: ['manage-account', 'manage-account-links', 'view-profile'],
    },
  },
  scope: 'openid profile email',
  sid: 'e42ac8e6-5f46-431a-b480-a3b8a7f4e317',
  email_verified: true,
  preferred_username: 'admin',
  email: 'admin@gmail.com',
};
