import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../containers/CourseDetails/style.scss';
import { imageCompress } from '../../utils/imageProcessing';
import ComponentUpImage from './common/ComponentUpImage';
import { TYPE_STATIC } from '../../reducers/test';
const dataFix = {
  base64: '',
  title: '',
  fileThumb: null,
  fileImage: null,
  thumbnailImageUrl: '',
  imageUrl: '',
  quizIds: [],
  testType: TYPE_STATIC,
  testScope: 'course',
  chapterId: '',
};
class ModalCreateTypeContent extends React.Component {
  state = {
    data: dataFix,
  };
  readImg = async (event, type) => {
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      const compressedFile = await imageCompress(event.target.files[0]);
      if (type === 'img_avatar') {
        this.setState({ data: { ...this.state.data, fileImage: compressedFile } });
      } else {
        this.setState({ data: { ...this.state.data, fileThumb: compressedFile } });
      }
    }
  };
  changeData = (data) => {
    this.setState({ data: data });
  };
  onSubmit = (typeContent) => {
    if (typeContent === 'lesson') {
      this.props.okCreateContent({
        title: this.state.data.title,
        imageUrl: this.state.data.fileThumb,
        thumbnailImageUrl: this.state.data.fileThumb,
        learningPoints: [],
      });
    }
    else {
      this.props.okCreateContent({
        title: this.state.data.title,
        imageUrl: this.state.data.fileThumb,
        thumbnailImageUrl: this.state.data.fileThumb,
        testType: this.state.data.testType
      });
    }
  };
  onOpened = () => {
      this.setState({ data: dataFix });
  };
  checkDisable = () => {
    const dataTest = this.state.data;
    const check =
      !dataTest.title ||
      (!dataTest.fileImage && !dataTest.imageUrl) ||
      (!dataTest.thumbnailImageUrl && !dataTest.fileThumb);
    return check;
  };
  render() {
    const { isOpen, toggle, typeContent } = this.props;
    const disable = this.checkDisable();
    const title = typeContent === 'lesson' ? 'Tạo mới bài học' : 'Tạo mới bài kiểm tra'
    const textTypeChapter = typeContent === 'lesson' ? 'bài học' : 'bài kiểm tra'
    return (
      <Modal isOpen={isOpen} toggle={toggle} onOpened={this.onOpened}>
        <div>
          <ModalHeader style={{ fontSize: 20 }}>{title}</ModalHeader>
          <ModalBody>
            <ComponentUpImage
              dataTest={this.state.data}
              readImg={this.readImg}
              changeData={this.changeData}
              textTypeChapter={textTypeChapter}
              typeContent={typeContent}
              thisVal={this}
            />
            <button
              disabled={disable}
              onClick={() => this.onSubmit(typeContent)}
              style={{ float: 'right' }}
              className="btn btn-primary"
            >
              Xác nhận
            </button>
          </ModalBody>
        </div>
      </Modal>
    );
  }
}

export default ModalCreateTypeContent;
