import React, {useState} from 'react';
import Tour from 'reactour';
import './style.scss';
import {ButtonAction} from "../Button/ButtonAction";
import {Button} from "@mui/material";

export function TourContainer({ steps, isOpen, onCloseTour }) {
    const [currentStep, setCurrentStep] = useState(0);

    const TitleTour = () => (
        <div className="d-flex justify-content-between mb-2">
            <div className={'title-tour'}>{`${Number(currentStep) + 1}. ${steps[Number(currentStep)] ? steps[Number(currentStep)].title : ""}` || 1}</div>
            <div className={'current-step'}>{Number(currentStep) + 1}/{steps.length}</div>
        </div>
    );

    const BtnFinishTour = () => (
        <button className="btn-finish">
            Kết thúc
        </button>
    );

    const getCurrentStep = (step) => {
        setCurrentStep(step);
    }

    return (
        <Tour
            className={'tour'}
            steps={steps}
            isOpen={isOpen}
            onRequestClose={onCloseTour}
            rounded={5}
            closeButtonAriaLabel={'close-btn-tour'}
            lastStepNextButton={<BtnFinishTour />}
            maskSpace={10}
            showNumber={false}
            disableInteraction={true}
            children={<TitleTour />}
            getCurrentStep={getCurrentStep}
        />
    );
}
