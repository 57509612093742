import { toast } from 'react-toastify';
import { createQuiz, updateQuiz } from '../apis/quiz';
import { ChoiceQuiz, BlankQuiz, PairQuiz, SpeakingQuiz } from '../reducers/templates';
import { checkDuplicateId } from '../utils/array';
import { getDirectoryUrlFromFile2 } from '../utils/imageProcessing';
import { uploadAudioQuestion } from './AudioQuiz';
import { formatQuizPart } from './LessonUtils';

const findIndexQuiz = (learningPoints, idQuiz, typeQuiz) =>
  learningPoints.findIndex((learning) => learning.id === idQuiz && learning.type.split(':')[0] === typeQuiz);

export const changeQuiz = (key, value, typeQuiz, idQuiz) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const indexQuiz = findIndexQuiz(learningPoints, idQuiz, typeQuiz);
  const newLearningPoints = {
    ...learningPoints,
    [indexQuiz]: {
      ...learningPoints[indexQuiz],
      [key]: value,
    },
  };
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: Object.values(newLearningPoints) || [],
  });
};

export const addQuizPart = (typeQuiz, idQuiz, typeQuizPart) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const indexQuiz = findIndexQuiz(learningPoints, idQuiz, typeQuiz);
  if (indexQuiz > -1) {
    let addData = [];
    if (typeQuizPart === 'mc_quiz') {
      addData = [ChoiceQuiz];
    }
    if (typeQuizPart === 'blank_quiz') {
      addData = [BlankQuiz];
    }

    if (typeQuizPart === 'pair_quiz') {
      addData = [PairQuiz];
    }

    if(typeQuizPart === 'speaking_quiz') {
      addData = [SpeakingQuiz];
    }

    const datas = {
      ...learningPoints,
      [indexQuiz]: {
        ...learningPoints[indexQuiz],
        quizParts: [...learningPoints[indexQuiz].quizParts].concat(addData),
      },
    };
    dispatch({
      type: 'ADD_CHOICE_QUIZ',
      learningPoints: Object.values(datas),
    });
  }
};

export const changeQuizPart = (key, value, learningCurrent, idxQuizPart) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const indexLearning = findIndexQuiz(learningPoints, learningCurrent.id, 'quiz');
  const newQuizParts = {
    ...learningCurrent.quizParts,
    [idxQuizPart]: {
      ...learningCurrent.quizParts[idxQuizPart],
      [key]: value,
    },
  };
  const newLearningPoints = {
    ...learningPoints,
    [indexLearning]: {
      ...learningCurrent,
      quizParts: Object.values(newQuizParts),
    },
  };
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: Object.values(newLearningPoints) || [],
  });
};
export const changeRadioQuizPart = (learningCurrent, index) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const datas = learningPoints.map((learning) => {
    let newObj = { ...learning };
    if (learning.id === learningCurrent.id) {
      newObj = {
        ...learning,
        quizParts: learning.quizParts.map((quiz, idx) => {
          if (idx === index) {
            return { ...quiz, correctness: true };
          }
          return { ...quiz, correctness: false };
        }),
      };
    }
    return newObj;
  });
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: datas,
  });
};

export const clearCheckQuizPart = (learningCurrent) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const datas = learningPoints.map((learning) => {
    let newObj = { ...learning };
    if (learning.id === learningCurrent.id) {
      newObj = {
        ...learning,
        quizParts: learning.quizParts.map((quiz) => {
          return { ...quiz, correctness: false };
        }),
      };
    }
    return newObj;
  });
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: datas,
  });
};
export const clearMediaMCQuiz = (learningCurrent) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const datas = learningPoints.map((learning) => {
    let newObj = { ...learning };
    if (learning.id === learningCurrent.id) {
      newObj = {
        ...learning,
        questionImageUrl: null,
        questionVideoUrl: null,
      };
      delete newObj.typeQuestion;
      delete newObj.file;
    }
    return newObj;
  });
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: datas,
  });
};
export const removeQuizPart = (learningCurrent, index) => (dispatch, getState) => {
  const learningPoints = getState().lesson.localState.learningPoints || [];
  const datas = learningPoints.map((learning) => {
    let newObj = { ...learning };
    if (learning.id === learningCurrent.id) {
      newObj = {
        ...learning,
        quizParts: learning.quizParts.filter((quiz, idx) => idx !== index),
      };
    }
    return newObj;
  });
  dispatch({
    type: 'CHANGE_QUIZ',
    learningPoints: datas,
  });
};

export const handleUpdateQuiz = async (quiz, oldQuiz, speaker, chapterId, courseId) => {
  let newQuiz = {
    ...quiz,
    quizParts: (await formatQuizPart(quiz, oldQuiz.quizParts, speaker)) || [],
  };
  if ((quiz.question !== oldQuiz.question && quiz.question) || !quiz.questionAudioUrl) {
    newQuiz = {
      ...newQuiz,
      questionAudioUrl: await uploadAudioQuestion(quiz, oldQuiz, speaker),
    };
  }
  if (quiz.type === 'quiz:mc_quiz' && quiz.file) {
    const res = await getDirectoryUrlFromFile2(quiz.file);
    if (quiz.typeQuestion && quiz.typeQuestion === 'video') {
      newQuiz = {
        ...newQuiz,
        questionVideoUrl: res,
        questionImageUrl: null,
      };
    } else {
      newQuiz = {
        ...newQuiz,
        questionImageUrl: res,
        questionVideoUrl: null,
      };
    }
  }
  return updateQuiz({ ...newQuiz, chapterId, courseId });
};

export const handleCreateQuiz = async (quiz, speaker, chapterId, courseId, idArray) => {
  const newQuiz = {
    ...quiz,
    quizParts: (await formatQuizPart(quiz, null, speaker)) || [],
    questionAudioUrl: await uploadAudioQuestion(quiz, {}, speaker),
  };
  if (quiz.type === 'quiz:mc_quiz' && quiz.file) {
    const res = await getDirectoryUrlFromFile2(quiz.file);

    if (quiz.typeQuestion && quiz.typeQuestion === 'video') {
      newQuiz.questionVideoUrl = res;
    } else {
      newQuiz.questionImageUrl = res;
    }
  }
  let check = true;
  let createdQuiz = {};
  while (check) {
    createdQuiz = await createQuiz({ ...newQuiz, chapterId, courseId });
    if (createdQuiz && createdQuiz.status === 1 && createdQuiz.result.quiz) 
      check = checkDuplicateId(idArray, createdQuiz.result.quiz.id, 'quiz');
    else {
      toast.error("ERROR API");
      break;
    }
  }
  // return createQuiz({ ...newQuiz, chapterId, courseId });
  return createdQuiz;
};
