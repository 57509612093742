import { toast } from 'react-toastify';
import { createChapter, deleteChapter, updateChapter } from '../apis/chapter';
import { getCourse, updateCourse } from '../apis/course';
import { deleteLesson, createLesson } from '../apis/lesson';
import { getDirectoryUrlFromFile2 } from '../utils/imageProcessing';
import { getArrayDifferences } from '../utils/array';
import { createActionTest, updateActionTest } from '../actions/ActionTest';
import { deleteTest } from '../apis/test';
import { createOverview } from '../apis/overview';

const initState = {
  syncing: false,
  data: [], // <array>chapters - store local
  cloudData: [], // <array>chapters - store on db, check for auto upload after 5s deplay

  course: {}, // current: course's Object
  courseName: '', // current: active course's name (this one = course.title)
  idChapterActive: '', // current: chapter selected (to render sections)
};

export const ChapterActions = {
  SET_DATA: '@@chapters/SET_DATA',
  SET_SYNCING: '@@chapters/SET_SYNCING',
  ADD_CHAPTER: '@@chapters/ADD_CHAPTER',
  SET_ACTIVE_CHAPTER: '@@chapters/SET_ACTIVE_CHAPTER',
  SET_ACTIVE_COURSE_NAME: '@@chapters/SET_ACTIVE_COURSE_NAME',
  SET_COURSE_IN_CHAPTER: 'SET_COURSE_IN_CHAPTER'
};
export const chageChapter = (id) => ({
  type: ChapterActions.SET_ACTIVE_CHAPTER,
  id,
});

export const setChapterLoading = (loading) => async (dispatch, getState) => {
  dispatch({ type: ChapterActions.SET_SYNCING, data: loading });
}

export const fetchChapters = (idCourse) => async (dispatch, getState) => {
  dispatch({ type: ChapterActions.SET_SYNCING, data: true });
  const idChapterActive = getState().chapter.idChapterActive || -1;
  const res = await getCourse(idCourse);
  if (res) {
    if (res.code) return toast.error(`Error found: ${res.code}`);
    const course = res.result && res.result.course ? res.result.course : {};
    // const latestChapters = course.chapterIds
    //   ? course.chapterIds.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    //   : [];

    const cloneData = JSON.parse(JSON.stringify(course.chapterIds));
    dispatch({
      type: ChapterActions.SET_DATA,
      data: course.chapterIds,
      cloudData: cloneData,
      course,
    });
    dispatch({
      type: ChapterActions.SET_ACTIVE_COURSE_NAME,
      courseName: course.title || '',
    });
    dispatch({
      type: ChapterActions.SET_ACTIVE_CHAPTER,
      id: idChapterActive === -1 ? (course.chapterIds[0] ?  course.chapterIds[0].id : null ) : idChapterActive,
    });
  }
  dispatch({ type: ChapterActions.SET_SYNCING, data: false });
};

export const createActionChapter = ({ title, imageFile, sections, imageUrl }) => async (dispatch, getState) => {
  const { course } = getState().chapter;

  dispatch({ type: ChapterActions.SET_SYNCING, data: true });
  try {
    let newImageUrl;
    if (imageFile) {
      newImageUrl = await getDirectoryUrlFromFile2(imageFile);
    } else if (imageUrl) {
      newImageUrl = imageUrl;
    } else {
      newImageUrl = 'https://picsum.photos/150';
    }

    const resCreateChapter = await createChapter({ title, imageUrl: newImageUrl, sections });
    if (!resCreateChapter || resCreateChapter.status !== 1) return toast.error(`Error found: ${resCreateChapter.message}`);
    const resUpdateCat = await updateCourse(course.id, {
      ...course,
      chapterIds: [...course.chapterIds.map(({ id }) => id), resCreateChapter.result.chapter.id],
    });

    if (!resUpdateCat || resUpdateCat.status !== 1) return toast.error(`Error found: ${resUpdateCat.message}`);

    toast.success('Đã thêm mới 01 chủ đề!');
  } catch (error) {
    console.log(error);
  }

  dispatch(fetchChapters(course.id));

  dispatch({ type: ChapterActions.SET_SYNCING, data: false });
};

export const updateActionChapter = (id, { title, imageFile, sections, imageUrl, status }, checkChangeType = 'default') => async (dispatch, getState) => {
  const { course } = getState().chapter;

  dispatch({ type: ChapterActions.SET_SYNCING, data: true });
  try {
    // console.log({ imageFile, imageUrl });
    let newImageUrl;
    if (imageFile) {
      newImageUrl = await getDirectoryUrlFromFile2(imageFile);
    } else if (imageUrl) {
      newImageUrl = imageUrl;
    } else {
      newImageUrl = 'https://picsum.photos/150';
    }
    const resCreateChapter = await updateChapter(id, { title, imageUrl: newImageUrl, sections, status });
    if (!resCreateChapter || resCreateChapter.status !== 1) return toast.error(`Error found: ${resCreateChapter.message}`);

    if(checkChangeType === 'default') toast.success('Đã chỉnh sửa 01 chủ đề!');
  } catch (error) {
    console.log(error);
  }

  dispatch(fetchChapters(course.id));

  dispatch({ type: ChapterActions.SET_SYNCING, data: false });
};

export const deleteActionChapter = (id) => async (dispatch, getState) => {
  const { course } = getState().chapter;

  dispatch({ type: ChapterActions.SET_SYNCING, data: true });
  try {
    const resChapter = await deleteChapter(id);
    if (!resChapter || resChapter.status !== 1) return toast.error(`Error found: ${resChapter.message}`);

    toast.success('Đã xóa 01 chủ đề!');
  } catch (error) {
    console.log(error);
  }

  dispatch(fetchChapters(course.id));

  dispatch({ type: ChapterActions.SET_SYNCING, data: false });
};

/**
 * Move a section to a new location
 * @param {string} sectionId id of the supchapter you want to move
 * @param {string} startIndex id of section move from
 * @param {string} endIndex id of section move to
 */

export const createActionLesson = (lesson, chapter) => async (dispatch, getState) => {
  dispatch({ type: ChapterActions.SET_SYNCING, data: true });
  const res = await createLesson({
    ...lesson,
  });
  if (!chapter || Object.keys(chapter).length === 0) {
    toast.error('Vui lòng tạo chủ đề trước');
    return;
  }
  if (res && res.status === 1) {
    const currentSections =
    chapter && chapter.sections && chapter.sections.length > 0
        ? chapter.sections.map((tp) => {
            let obj = {};
            if (tp.type === 'test') {
              obj = tp.testLiveId 
                      ? { type: 'test', sectionId: tp.testId.id, sectionLiveId: tp.testLiveId.id }
                      : { type: 'test', sectionId: tp.testId.id };
            } else {
              obj = tp.lessonLiveId 
                      ? { type: 'lesson', sectionId: tp.lessonId.id, sectionLiveId: tp.lessonLiveId.id }
                      : { type: 'lesson', sectionId: tp.lessonId.id }
            };
            return obj;
          })
        : [];

    const resChapter = await updateChapter(chapter.id, {
      ...chapter,
      sections: [...currentSections, { type: 'lesson', sectionId: res.result.lesson.id }],
    });
    if (resChapter && resChapter.status === 1) {
      toast.success('Thêm bài học thành công');
      const { course } = getState().chapter;
      dispatch(fetchChapters(course.id));
    }
  } else {
    toast.error(`Error found: ${res.code}`);
  }
};

export const updateActionAllChapters = () => async (dispatch, getState) => {
  // check for changes
  console.log('there are changes, start syncing 🌈');
  const { data, cloudData } = getState().chapter;

  const difChapters = getArrayDifferences(data, cloudData);

  if (!difChapters || difChapters.length === 0) return;

  // update the changes
  difChapters.forEach(async (chapter) => {
    const resChapter = await updateChapter(chapter.id, {
      title: chapter.title,
      imageUrl: chapter.imageUrl,
      sections: chapter.sections.map((stp) => {
        let newStp;
        if(stp.type === 'test') {
          newStp = stp.testLiveId 
                    ? {
                      type: stp.type,
                      sectionId:  stp.testId.id ,
                      sectionLiveId: stp.testLiveId.id
                    }
                    : {
                      type: stp.type,
                      sectionId:  stp.testId.id ,
                    }
        } else {
          newStp = stp.lessonLiveId 
                    ? {
                      type: stp.type,
                      sectionId:  stp.lessonId.id ,
                      sectionLiveId: stp.lessonLiveId.id
                    }
                    : {
                      type: stp.type,
                      sectionId:  stp.lessonId.id ,
                    }
        };
        return newStp;
      }),
    });
    // console.log('resChapter', resChapter);
  });
};

export const chapters = (state = initState, action) => {
  switch (action.type) {
    case ChapterActions.SET_ACTIVE_CHAPTER:
      return {
        ...state,
        idChapterActive: action.id,
      };
    case ChapterActions.SET_ACTIVE_COURSE_NAME:
      return {
        ...state,
        courseName: action.courseName,
      };
    case ChapterActions.SET_SYNCING:
      return {
        ...state,
        syncing: action.data,
      };

    case ChapterActions.SET_DATA:
      return {
        ...state,
        data: action.data ? action.data : state.data,
        cloudData: action.cloudData ? action.cloudData : state.cloudData,
        course: action.course ? action.course : state.course,
      };
    case ChapterActions.SET_COURSE_IN_CHAPTER:
      return {
        ...state,
        course: action.data
      }
    default:
      return state;
  }
};

export default chapters;
