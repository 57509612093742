import React from 'react';
import { Input } from 'reactstrap';

class InputNumber extends React.Component {
  render() {
    const { changeData, value, disabled, placeholder } = this.props;
    return (
      <Input
        disabled={disabled}
        value={value || ''}
        type="number"
        min="1"
        onChange={changeData}
        name="random"
        placeholder={placeholder}
      />
    );
  }
}

export default InputNumber;
