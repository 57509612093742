export const ORDER_TYPES = {
  BUY: 'buy',
  SELL: 'sell',
}

export const ORDER_TYPE_NAME = {
  [ORDER_TYPES.BUY]: 'Mua',
  [ORDER_TYPES.SELL]: 'Bán',
}

export const ORDER_TYPE_OPTIONS = [
  {
    value: ORDER_TYPES.BUY,
    label: ORDER_TYPE_NAME[ORDER_TYPES.BUY],
  },
  {
    value: ORDER_TYPES.SELL,
    label: ORDER_TYPE_NAME[ORDER_TYPES.SELL],
  },
]