import { getSoundFile } from '../apis/voice';
import { uploadUrl } from '../apis/upload';

export const handleAudioUrl = async (soundUrl) => {
  if (soundUrl && soundUrl.includes('{api_url}')) return soundUrl;
  if (!soundUrl || soundUrl == '') return soundUrl;

  // upload and get sound url from our database
  const response = await uploadUrl({ url: soundUrl });
  if (!response.result) {
    toast.error(`Lỗi: ${JSON.stringify(response.message)}`);
    return;
  }

  return response.data.fileAccessLink;
};
