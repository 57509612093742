import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TONE } from '../../constants';
import { convertToneToComponentByTone } from '../Quiz/getData';
import './style.scss';

export const SquareInterface = ({ className, type, index, tooltip, children, previewLP }) => {
    return (
        <div className={`${className} general-square-ui`} data-tip data-for={`${className}-${type}-${index}`}>
            {children}
            {!previewLP && (<ReactTooltip id={`${className}-${type}-${index}`}>{tooltip}</ReactTooltip>)}
        </div>
    );
};

export const SquareContainerInterface = ({
  rhymeChildren,
  className,
  squareText,
  consonant,
  rhyme,
  startRhyme,
  mainRhyme,
  finishRhyme,
  tone,
  index,
  hiddenConsonant = false,
  previewLP
}) => {
  return (
    <div key={`${className}-${index}`} className={`${className} square-container`}>
      <div className="square-text">{squareText}</div>
      <div className="square">
        {!hiddenConsonant &&
            SquareInterface({ className: 'consonant', type: 'consonant', index, tooltip: 'Âm đầu', children: <span>{consonant}</span>, previewLP })}
        {/* {hiddenConsonant && <div className='general-square-ui hidden-consonant'></div>} */}
        {rhymeChildren({ rhyme, startRhyme, mainRhyme, finishRhyme, tone, index, previewLP })}
      </div>
    </div>
  );
};

export const ElementOfWordChildren= ({ rhyme, startRhyme, mainRhyme, finishRhyme, tone, index, previewLP }) => {
    const children = (
        <>
            <div className={`${tone !== TONE.DROP.text ? 'up' : 'down'}`}>
                {convertToneToComponentByTone(tone, 'v2')}
            </div>
            {rhyme}
        </>
    );
    return SquareInterface({ 
        className: 'rhyme',
        type: 'rhyme',
        index,
        tooltip: 'Vần',
        children,
        previewLP
    });
}

export const ElementInWordChildren = ({ rhyme, startRhyme, mainRhyme, finishRhyme, tone, index, previewLP }) => {

    return SquareInterface({ 
        className: 'rhyme',
        type: 'rhyme',
        index,
        tooltip: 'Vần',
        children: <span>{rhyme}</span>,
        previewLP
    });
}

export const PhonemeOfRhymeChildren= ({ rhyme, startRhyme, mainRhyme, finishRhyme, tone, index, previewLP }) => {
    const children = (
        <>
            <div className={`${tone !== TONE.DROP.text ? 'up' : 'down'}`}>
                {convertToneToComponentByTone(tone, 'v2')}
            </div>
            <span>{mainRhyme}</span>
        </>
    );
    return (
        <>
            {SquareInterface({ 
                className: 'start-rhyme',
                type: 'start-rhyme',
                index,
                tooltip: 'Âm đệm',
                children: <span>{startRhyme}</span>,
                previewLP
            })}
            {SquareInterface({ 
                className: 'main-rhyme',
                type: 'main-rhyme',
                index,
                tooltip: 'Âm chính',
                children,
                previewLP
            })}
            {SquareInterface({ 
                className: 'finish-rhyme',
                type: 'finish-rhyme',
                index,
                tooltip: 'Âm cuối',
                children: <span>{finishRhyme}</span>,
                previewLP
            })}
        </>
    );
}

export const PhonemeInRhymeChildren= ({ rhyme, startRhyme, mainRhyme, finishRhyme, tone, index, previewLP }) => {
    return (
        <>
            {SquareInterface({ 
                className: 'start-rhyme',
                type: 'start-rhyme',
                index,
                tooltip: 'Âm đệm',
                children: <span>{startRhyme}</span>,
                previewLP
            })}
            {SquareInterface({ 
                className: 'main-rhyme',
                type: 'main-rhyme',
                index,
                tooltip: 'Âm chính',
                children: <span>{mainRhyme}</span>,
                previewLP
            })}
            {SquareInterface({ 
                className: 'finish-rhyme',
                type: 'finish-rhyme',
                index,
                tooltip: 'Âm cuối',
                children: <span>{finishRhyme}</span>,
                previewLP
            })}
        </>
    );
}

//
export const WordInSentence = ({ syllable, index, previewLP }) => {
  return (
    <div className="WIS-syllable">
      <div className="WIS-element WIS-top">
        <span>{syllable}</span>
      </div>
      <div style={{ height: '10px' }}></div>
      <div className="WIS-element WIS-bottom general-square-ui" data-tip data-for={`syllable-${index}`}>
        {!previewLP && <ReactTooltip id={`syllable-${index}`}>Tiếng</ReactTooltip>}
      </div>
    </div>
  );
};

export const ToneOfWord = ({ syllable, tone, index, previewLP }) => {
  return (
    <div>
      <div className="TOW-element TOW-syllable">
        <span>{syllable}</span>
      </div>
      <div style={{ height: '20px' }}></div>
      <div className="TOW-element TOW-tone general-square-ui" data-tip data-for={`tone-${index}`}>
        <div className={`TOW-top-rhyme ${tone !== TONE.DROP.text ? 'up' : 'down'}`}>
          {convertToneToComponentByTone(tone, 'v2')}
        </div>
        {!previewLP && <ReactTooltip id={`tone-${index}`}>Thanh điệu</ReactTooltip>}
      </div>
    </div>
  );
};
