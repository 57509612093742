import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { createPathAction } from '../../../reducers/paths';
import './style.scss';

const NewPathForm = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('PUBLIC');
  const { register, handleSubmit, errors } = useForm();
  const submitHandler = async (data) => {
    const { title, description, thumbnail } = data;
    dispatch(createPathAction({ title, description, file: thumbnail[0], type }));
    toggle();
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader style={{ fontSize: 20 }}>Tạo lộ trình học mới</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <FormGroup>
            <Label htmlFor="Title">Tên lộ trình học</Label>
            <Input
              type="text"
              name="title"
              id="Title"
              placeholder="Tên lộ trình học này..."
              innerRef={register({ required: { value: true, message: 'Bạn cần điền tên của lộ trình học' } })}
            />
            {errors.title && <FormText color="danger">{errors.title.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Description">Miêu tả</Label>
            <Input
              type="textarea"
              name="description"
              id="Description"
              placeholder="Miêu tả về lộ trình học này..."
              innerRef={register({
                required: { value: true, message: 'Bạn cần điền một miêu tả ngắn về lộ trình học học' },
              })}
            />
            {errors.description && <FormText color="danger">{errors.description.message}</FormText>}
          </FormGroup>
          <FormGroup>
            <Label>Phạm vi lộ trình học</Label>
            <br />
            <Label style={{ marginLeft: '40px' }}>
              <Input
                type="radio"
                name="publicRadio"
                id="typeCourse public"
                checked={type === 'PUBLIC' ? true : false}
                onChange={(e) => setType('PUBLIC')}
              />{' '}
              Công khai
            </Label>
            <Label style={{ marginLeft: '80px' }}>
              <Input
                type="radio"
                name="protectRadio"
                id="typeCourse protect"
                checked={type === 'PROTECT' ? true : false}
                onChange={(e) => setType('PROTECT')}
              />{' '}
              Giới hạn
            </Label>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="File">Ảnh Thumbnail</Label>
            <Input
              type="file"
              name="thumbnail"
              id="File"
              innerRef={register({ required: { value: true, message: 'Bạn cần upload một ảnh thumbnail' } })}
            />
            <FormText color="muted">Thumbnail là ảnh đại diện thu nhỏ của lộ trình học</FormText>
            {errors.thumbnail && <FormText color="danger">{errors.thumbnail.message}</FormText>}
          </FormGroup>

          <Button>Tạo lộ trình học mới</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default NewPathForm
