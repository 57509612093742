import { createCheckpoint, updateCheckpoint, deleteCheckpoint } from '../apis/checkpoint';
import { denormalizeLearningPoint, normalizeLearningPoint, normalizeLearningPoints } from '../utils/normalize';
import _ from 'lodash';
import { findIndexId, SlidesActions } from '../reducers/lesson';
import { getSoundFile, getSoundUrl, } from '../apis/voice';
import { deleteLearningPointer } from './ActionLesson';
import { checkDuplicateId } from '../utils/array';
import { handleAudioUrl } from '../utils/downloadAudio';
import { toast } from 'react-toastify';


export const handleCheckpointAudioUrl = async (checkpoint) => {
  let url = checkpoint.soundUrl;
  let congratulation = checkpoint.congratulationText || `Chúc mừng bạn vượt qua phần ${checkpoint.title}`;
  let speaker = checkpoint.speaker || 'ngochuyen';

  if(checkpoint.soundUrl && checkpoint.soundUrl.includes('{api_url}')) return checkpoint;
  if(!checkpoint.soundUrl || checkpoint.soundUrl === '') {
    await getSoundUrl(speaker, congratulation)
      .then(res => url = res.data.wav_path)
      .catch(e => '')
  }
  // get file
  const soundUrl = await handleAudioUrl(url);
  return {
    ...checkpoint,
    soundUrl,
    congratulationText: congratulation,
    speaker: speaker
  };
};
export const handleCreateCheckpoint = async (learningPoint, idArray) => {
  const fixedSoundUrlCheckpoint = await handleCheckpointAudioUrl(learningPoint);
  let check = true;
  let newCheckpoint = {};
  while (check) {
    newCheckpoint = await createCheckpoint(fixedSoundUrlCheckpoint);
    check = checkDuplicateId(idArray, newCheckpoint.result.checkpoint.id, 'checkpoint');
  }
  return newCheckpoint;
};

export const handleUpdateCheckpoint = async (learningPoint) => {
  const fixedSoundUrlCheckpoint = await handleCheckpointAudioUrl(learningPoint);
  const updatedCheckpoint = await updateCheckpoint(fixedSoundUrlCheckpoint.id, fixedSoundUrlCheckpoint);
  return updatedCheckpoint;
};

export const checkpointTextActions = (data) => (dispatch, getState) => {
  const { localState } = getState().lesson;
  const { learningPoints } = localState;
  const index = findIndexId(learningPoints, data.id);

  dispatch({
    type: 'CHANGE_CHECKPOINT',
    data: {
      index: index,
      content: data.content,
      type: data.type,
    },
  });
};

export const handleOverviewChange = (data) =>  async (dispatch, getState) => {
  // overview (starTotal or learningpointPartTitles )change then change checkpoint
  const { localState } = getState().lesson;
  const { overview, learningPoints } = localState;
  const { learningPointPartTitles, starTotal, usedStars } = overview;
  const { newLearningPointPartTitles} = data;
  // trả về một mảng chứa các phần tử ở mảng 1 khác với mảng 2
  const differenceTitles = _.differenceWith(newLearningPointPartTitles, learningPointPartTitles, _.isEqual);

  if (differenceTitles !== []) {
    // trả về một mảng với các phần tử là checkpoint
    const checkpointArray = learningPoints.filter((element) => element.type.split(':')[0] === 'checkpoint');
    // tìm id của các title trong learningPointPartTitles tương ứng với title trong các checkpoint
    const indexId = checkpointArray.map((element, index) => {
      const learningPointPartTitle = learningPointPartTitles.find((ele) => ele.title === element.title);
      return {
        index,
        checkPointId: element.id,
        learningPointPartTitleId: learningPointPartTitle ? learningPointPartTitle.id : null,
      };
    });
    // tiến hành cập nhật lại các title của checkpoint theo newLearningPointPartTitles
    for (let differenceTitle of differenceTitles) {
      const idObj = indexId.find((element) => element.learningPointPartTitleId === differenceTitle.id);
      if (idObj != undefined) {
        const id = idObj.checkPointId;
        dispatch(checkpointTextActions({
          id,
          content: differenceTitle.title,
          type: 'title'
        }));
        await updateCheckpoint(id, {
          ...checkpointArray[idObj.index],
          title: differenceTitle.title
        });
      };
    };
  }
};

export const checkAndfindIdCheckpoint = (title, learningPoints) => {
    const checkpointArray = learningPoints.filter(element => element.type.split(':')[0] === 'checkpoint');
    const checkpoint = checkpointArray.find(element => element.title === title);

    if(checkpoint) {
      return checkpoint.id;
    }
    else {
      return -1;
    }
};

// hàm dùng để check xem có add được thêm 1 checkpoint hay không, nếu có thêm được và có callback thì gọi callback sau đó mới trả về
export const addCheckpoint = (learningPoints, overview, callback) => {
  const {learningPointParts, learningPointPartTitles, usedStars, starTotal} = overview;
  const checkpointArray = learningPoints.filter(element => element.type.split(":")[0] === 'checkpoint');
  const len = checkpointArray.length;

  if(len < learningPointParts) {
    if(len !== 1 || (len === 1 && learningPointPartTitles[0].title !== '' && learningPointPartTitles[1].title !== '')) {
      if((starTotal - usedStars) > 0) {
        if (callback) {
          callback();
        }
        return true;
      }
      else {
        toast.error('Không đủ sao để tạo reward');
      }
    }
    else {
      toast.error('Vui lòng tạo tên các phần ở overview ( biểu tượng cái bút ) ');
    }
  } else {
    toast.error('Bạn không thể tạo thêm slide Reward !');
  }
  return false;
};

// export const deleteCheckpointAction = async (id) => await deleteCheckpoint(id);

// export const deleteCheckpointAction =  (id) => async (dispatch, getState) => {
//   dispatch(deleteLearningPointer(id));
// };