import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router'
import { IconCalendar, IconFilter } from '../../../icons/IconSvg';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useDispatch } from 'react-redux';
import { changeFilter, closeFilter, filterCourse, openFilter } from '../../../actions/ActionPublishManagement';
import { changeFilterPath, closeFilterPath, filterPath, openFilterPath } from '../../../actions/ActionPublishPath';

import { ButtonAction } from '../../Common/Button/ButtonAction';
import { FRAME_CONTENT_STATUS } from '../../../constants';

const animatedComponents = makeAnimated();

export const Filter = ({ filter, search }) => {
  const dispatch = useDispatch();
  registerLocale('vi', vi);
  const [isFocusFrom, setFocusFrom] = useState(false);
  const [isFocusTo, setFocusTo] = useState(false);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const location = useLocation()
  const publishType = location.pathname.includes('path') ? 'path' : 'course'
  const modalRef = useRef()

  useEffect(() => {
    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
          if(filter.isOpen) {
            if(publishType === 'path') dispatch(closeFilterPath())
            else dispatch(closeFilter())
          }
        }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
        document.removeEventListener('click', handleClickOutside);
    };
}, [filter])

  const options = [
    { value: FRAME_CONTENT_STATUS.DRAFT, label: 'Nháp' },
    { value: FRAME_CONTENT_STATUS.SUBMITTED, label: 'Đã nộp' },
    { value: FRAME_CONTENT_STATUS.PUBLISH, label: 'Xuất bản' },
    { value: FRAME_CONTENT_STATUS.NOT_PUBLISH, label: 'Không xuất bản' },
  ];

  const onClickCalendar = (e, type) => {
    e.stopPropagation();
    if (type === 'from' && fromRef.current) {
      setFocusFrom(!isFocusFrom);
      fromRef.current.setOpen(!isFocusFrom);
    }
    if (type === 'to' && toRef.current) {
      setFocusTo(!isFocusTo);
      toRef.current.setOpen(!isFocusTo);
    }
  };

  const onChangeSelect = (selected) => {
    dispatch(publishType === 'path' ? changeFilterPath({ status: selected }) : changeFilter({ status: selected }));
  }

  const onChangeDate = (date, type) => {
    const newDate = {
      date: {
        ...filter.date,
        [type]: date
      }
    }
    dispatch(publishType === 'path' ? changeFilterPath(newDate) : changeFilter(newDate));
  }

  const onClickFilter = () => {
    dispatch(publishType === 'path' ?  filterPath({ status: filter.status.map(item => item.value), date: filter.date, search: search }) 
    : filterCourse({ status: filter.status.map(item => item.value), date: filter.date, search: search  }))
    dispatch(publishType === 'path' ? closeFilterPath() : closeFilter())
  }

  const onClickResetFilter = () => {
    dispatch(publishType === 'path' ? changeFilterPath({
      status: [],
      date: {
        from: filter.date.oldestDate,
        oldestDate: filter.date.oldestDate,
        to: new Date()
      }
    }) : changeFilter({
      status: [],
      date: {
        from: filter.date.oldestDate,
        oldestDate: filter.date.oldestDate,
        to: new Date()
      }
    }))
    dispatch(publishType === 'path' ?  filterPath({ status: [], date: { from: filter.date.oldestDate, to: new Date() }, search: search }) 
    : filterCourse({ status: [], date: { from: filter.date.oldestDate, to: new Date() }, search: search }))
    dispatch(publishType === 'path' ? closeFilterPath() : closeFilter())
  }

  return (
    <div className="filter">
      <button className="icon-filter" onClick={() => {
        if (filter.isOpen) dispatch(publishType === 'path' ? closeFilterPath() : closeFilter());
        else dispatch(publishType === 'path' ? openFilterPath() : openFilter());
      }}>
        <IconFilter />
        Bộ lọc
      </button>
      {filter.isOpen && (
        <div className="filter-content" ref={modalRef}>
          <div className="filter-status">
            <div className="filter-label">Trạng thái</div>
            <div className="select-status">
              <Select
                options={options}
                components={animatedComponents}
                isMulti={true}
                menuPortalTarget={document.body}
                onChange={onChangeSelect}
                value={filter.status}
              />
            </div>
          </div>
          <div className="filter-date">
            <div className="filter-label">Thời gian</div>
            <div className="filter-date-element from">
              <span className="date-text">Từ ngày</span>
              <div className="date-picker-container">
                <div className="filter-date-icon" onClick={(e) => onClickCalendar(e, 'from')}>
                  <IconCalendar />
                </div>
                <div className="date-picker">
                  <DatePicker
                    selected={filter.date.from}
                    onChange={(date) => onChangeDate(date, 'from')}
                    closeOnScroll
                    selectsStart
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Từ ngày"
                    locale="vi"
                    ref={fromRef}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-date-element to">
              <span className="date-text">Đến ngày</span>
              <div className="date-picker-container">
                <div className="filter-date-icon" onClick={(e) => onClickCalendar(e, 'to')}>
                  <IconCalendar />
                </div>
                <div className="date-picker">
                  <DatePicker
                    selected={filter.date.to}
                    onChange={(date) => onChangeDate(date, 'to')}
                    closeOnScroll
                    selectsStart
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Từ ngày"
                    locale="vi"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    ref={toRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <ButtonAction text={'Lọc'} className="btn-filter" size="small" onClick={onClickFilter}/>
            <ButtonAction text={'Bỏ lọc'} className="btn-reset btn-inactive" size="small" onClick={onClickResetFilter}/>
          </div>
        </div>
      )}
    </div>
  );
};
