import React, { useState, useCallback, memo } from 'react';
import { Button } from 'reactstrap'

// local
import SystemCollectionModal from '../SystemCollectionModal';

const Actions = ({ className }) => {
  // -- is add modal open --
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  // -- toggle add modal open --
  const toggleAddModalOpen = useCallback(() => setIsAddModalOpen((prevState) => !prevState), [setIsAddModalOpen])

  return (
    <div className={className}>
      {/* Add */}
      <Button color="primary" onClick={() => setIsAddModalOpen(true)}>Thêm</Button>
      <SystemCollectionModal open={isAddModalOpen} toggle={toggleAddModalOpen} />
    </div>
  );
};

export default Actions;