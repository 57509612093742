import React from 'react';
import { connect } from 'react-redux';
import './style.scss';
import InputQuestion from '../common/InputQuestion';
import ContentQuestion from './ContentQuestion';
import { getContentQuestionblankQuiz } from '../getData';
import ContentWrongAnswer from './ContentWrongAnswer';
import { formatQuizpartEnter } from '../quizUtils';

class DragAnDrop extends React.Component {
  state = {
    name: '',
  };
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  render() {
    const datas = this.state.name && this.state.name.length > 0 ? this.state.name.trim().split(' ') : [];
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    const quizParts = learningCurrent.quizParts || [];
    const wrongAnswers = quizParts.reduce((newArrays, part) => {
      if (part.isHidden) {
        newArrays = [...newArrays, part.content];
        if (
          part.wrongAnswer.text ||
          (part.wrongAnswer.type === 'image' && part.wrongAnswer.imageUrl) ||
          (part.wrongAnswer.type === 'image' && part.wrongAnswer.file) ||
          (part.wrongAnswer.type === 'video' && part.wrongAnswer.videoUrl) ||
          (part.wrongAnswer.type === 'video' && part.wrongAnswer.file)
        ) {
          newArrays = [...newArrays, part.wrongAnswer];
        }
      }
      return newArrays;
    }, []);
    const quizpartsEnter = formatQuizpartEnter(quizParts) || [];

    return (
      <div className="ui__drag_and_drop">
        <div>
          <div>Mô tả nội dung hiển thị bài tập</div>
          <div className="ui__view ">
            <div className="">
              <div className="text-bold text-center m-20">{learningCurrent.question || ''}</div>
              <div className="text-center">
                {quizpartsEnter.map((partE, index) => {
                  return <div key={`e-${index}`}>{getContentQuestionblankQuiz(Object.values(partE))}</div>;
                })}
              </div>
              <ContentWrongAnswer currentSlidePreview={this.props.currentSlidePreview} wrongAnswers={wrongAnswers} />
            </div>
          </div>
          <div className="create_question">
            <div className="text-bold">Tạo câu hỏi:</div>
            <div className="m-20 wrapper_quiz_textarea">
              <InputQuestion audioUrl={learningCurrent.questionAudioUrl || ''} />
            </div>
          </div>
          <ContentQuestion currentSlidePreview={this.props.currentSlidePreview} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {})(DragAnDrop);
