import React, { useState} from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { IconPen, IconSmallStar, IconPlayArrow } from '../../../../icons/IconSvg';
import IconClose from '../../icons/close.svg';

import { Modal, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import ProcessOverview from './common/process';
import FormError from './common/formError';
import { OverviewActions, saveOverview } from '../../../../actions/Overview';
import PopoverCustom from '../../../Popover/PopoverCustom';
import VoiceSpeaker from './common/voiceSpeaker';
import { checkPropertyInObjectOfArray } from '../../../../utils/array';
import { UploadImage } from './common/uploadImage';
import { generateWelcomeLesson } from './utils';

import { getSoundUrl } from '../../../../apis/voice';

const ModalUpdateLesson_Step2 = (props) => {
  const dispatch = useDispatch();
  const { localState, syncing } = useSelector((state) => state.lesson);
  const { overview } = localState;
  const { content, starTotal, learningPointParts, learningPointPartTitles, usedStars, welcome , template} = overview;

  const speakerArray = [
    {
      speaker: 'ngochuyen',
      text: 'Nữ - Hà Nội - Ngọc Huyền',
    },
    {
      speaker: 'huonggiang',
      text: 'Nữ - Sài Gòn - Hương Giang',
    },
  ];

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [starInputValidation, setStarInputValidation] = useState({
    isHidden: true,
    errorMessage: 'Tối thiểu 2 sao và tối đa 15 sao',
  });
  // const [idCheckpoint, setIdCheckpoint] = useState([]); // mảng dùng để lưu các id của checkpoint ứng với title đã xóa
  const handleChangeContent = (e) => {
    let generateWelcome = welcome;
    generateWelcome = generateWelcomeLesson(e.target.value, learningPointPartTitles, template);
    dispatch(OverviewActions({
      ...overview,
      content: {
        ...overview.content,
        text: e.target.value,
        audioUrl: '',
      },
      welcome: {
        ...overview.welcome,
        text: generateWelcome,
        audioUrl: '',
      }
    }));

  };
  const handleChangeStarTotal = (e) => {
    const stars = e.target.value;
    if (stars < 2 || stars > 15) {
      setStarInputValidation({
        ...starInputValidation,
        isHidden: false,
      });
    } else {
      if (starTotal < usedStars) {
        toast.error(`Bạn đã sử dụng ${usedStars} ở các checkpoint !`);
      }  
      else {
        dispatch(OverviewActions({
          ...overview,
          starTotal: stars
        }));
        setStarInputValidation({
          ...starInputValidation,
          isHidden: true,
        });
      }
    };
  };
  const handleOnBurStarTotal = (e) => {};
  const handlePopover = () => {
    setPopoverOpen(!popoverOpen);
  };
  // ----------------------------------------------------HANDLE SAVE-------------------------------------------------
  const saveStep2 = async () => {
    if (starInputValidation.isHidden === false) {
      toast.error(`Vui lòng nhập số sao theo quy định !`);
    } else {
      const check = checkPropertyInObjectOfArray(learningPointPartTitles, 'title');
      if( check === 0 || (learningPointParts === 2 && check === learningPointParts)) {
        if(welcome.audioUrl === '' && welcome.speaker !== '') {
          getSoundUrl(welcome.speaker, welcome.text)
            .then((res) => {
              dispatch(OverviewActions({
                ...overview,
                welcome: {
                  ...overview.welcome,
                  audioUrl: res.data.wav_path
                }
              }))
            })
        }
        dispatch(saveOverview(overview));
      }
      else {
        toast.error("Không được bỏ trống title !")
      };
    };
  };

  const { openState, processFunction } = props;
  return (
    <Modal isOpen={openState.step2} toggle={(e) => processFunction(e, 2)} style={{ minWidth: '1200px' }}>
      <div className="modal-overview-header">
        <div style={{ marginLeft: '437px'}}>
          <IconPen /> Chỉnh sửa bài học
        </div>
        <div className="close_modal_update" onClick={(e) => processFunction(e, 2)}>
          <img src={IconClose} alt="icon_close" /> Đóng
        </div>
      </div>
      <ModalBody className="modal-body">
        <ProcessOverview processFunction={processFunction} openState={openState} />
        <div className="main step2">
          <div className="intro-lesson">
            <div className="intro-lesson__text">Giới thiệu bài học</div>
            <div className="voice-overview">
              <div
                id="popover-speaker-step2"
                onClick={() => setPopoverOpen(!popoverOpen)}
                style={{ cursor: 'pointer' }}
              >
                <IconPlayArrow />
                <PopoverCustom
                  width="260px"
                  maxHeight="120px"
                  popoverOpen={popoverOpen}
                  id="popover-speaker-step2"
                  toggle={handlePopover}
                >
                  {speakerArray.map((element, index) => {
                    return (
                      <div
                        aria-hidden="true"
                        key={`voice speaker step 2` + index}
                        className="div_item"
                        onClick={() => {
                          dispatch(OverviewActions({
                            ...overview,
                            content: {
                              ...overview.content,
                              speaker: element.speaker
                            }
                          }));
                        }}
                      >
                        {element.text}
                      </div>
                    );
                  })}
                </PopoverCustom>
              </div>
              <div className="speaker">
                {content.speaker
                  ? speakerArray.find((element) => element.speaker === content.speaker).text
                  : 'Chọn giọng đọc'}
              </div>
              <div>
                {content.speaker && content.text && (
                  <VoiceSpeaker
                    localSpeaker={content.speaker}
                    text={content.text}
                    audioUrl={content.audioUrl}
                    type="contentOverview"
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <textarea defaultValue={content.text} onChange={handleChangeContent} />
          </div>
          <div className="star">
            <div className="star__text">Phần thưởng tối đa</div>
            <IconSmallStar />
            <input
              type="number"
              defaultValue={starTotal}
              placeholder="2-15"
              onChange={handleChangeStarTotal}
              onBlur={handleOnBurStarTotal}
            />
          </div>
          <div style={{ margin: '5px 3px 15px 0', alignSelf: 'flex-end' }}>
            <FormError isHidden={starInputValidation.isHidden} errorMessage={starInputValidation.errorMessage} />
          </div>
          <UploadImage />
        </div>
        <div className="div_btn">
          <button onClick={saveStep2} className="btn next" style={{ backgroundColor: '#233b98', color: 'white' }}>
            {
              syncing ? "Đang xử lý" : "Lưu"
            }
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalUpdateLesson_Step2;