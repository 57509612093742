import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from '../../components/Common/Loading';
import Header from '../../components/Common/Header';
import { IconChapter, IconAdd } from '../../icons/IconSvg';
import Photo from '../../components/Common/Photo';
import ModalCreateTypeContent from "../../components/Chapter/ModalCreateTypeContent";
import moment from 'moment';
import { fetchLessonAction, createLessonAction } from "../../reducers/lesson";
import { useDispatch, useSelector } from "react-redux";
import './style.scss'

const LessonPage = () => {
    const [openCreateLesson, setOpenCreateLesson] = useState(false);
    const toggleCreateLesson = () => {
        setOpenCreateLesson(!openCreateLesson)
    }
    const okCreateLesson = (lesson) => {
        toggleCreateLesson();
        dispatch(createLessonAction(lesson));;
      };
    const history = useHistory()
    const lessons = useSelector((state) => state.lesson);
    console.log(lessons)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchLessonAction());
    }, []);

    return (
        <>
        {lessons.syncing && <Loading />}
        <Header/>
        <div className='lesson-wrapper'>
            <div className='header'>
                <h2>Các bài học của bạn</h2>
                <button>Duyệt bài học</button>
            </div>
            <div className='lesson-list'>
                <div className='create-lesson' onClick={() => setOpenCreateLesson(true)}>
                    <IconAdd/>
                    <h2>Tạo bài học mới</h2>
                </div>
                {lessons.data.map((data) => (
                <div className='lesson-item' key={data.id} onClick={() => {
                    history.push(`/lesson/${data.id}`)
                }}>
                    <div className='lesson-item__title'>
                        <h2>{data.title || ''}</h2>
                        <p>{data.description || ''}</p>
                    </div>
                    <div className='lesson-item__thumbnail'>
                        <Photo alt={data.title} src={data.imageUrl} />
                    </div>
                    <div className='lesson-item__lastUpdate'>
                        <IconChapter />
                        <p>{moment(data.updatedAt).fromNow() || ''}</p>
                    </div>
                </div>
                ))}
            </div>
        </div>
        <ModalCreateTypeContent
          typeContent='lesson'
          isOpen={openCreateLesson}
          toggle={toggleCreateLesson}
          okCreateContent={(lesson) => okCreateLesson(lesson)}
        />
        </>
    )
}

export default LessonPage