import React from 'react';

const FormError = (props) => {
    if (props.isHidden) return null;
    return (
      <div
        style={{
          fontSize: '12px',
          lineHeight: '1.3',
          color: 'red',
        }}
      >
        {props.errorMessage}
      </div>
    );
};

export default FormError;