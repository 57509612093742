import React from 'react';
import PropTypes from 'prop-types';
import { IconNewContent, IconSplitHorizontal, IconSplitVertical } from '../../icons/IconSvg';
import { SQUARE_OBJECT_TYPE } from '../../constants';

const theoryplaceHolders = [
  {
    type: 'new-mediaObject',
    icon: () => <IconNewContent />,
    placeholder: {
      type: 'text',
      text: '',
      hiddenText: false,
      rateVoice: 1.0,
      textFormat: 'doan_van_thuong',
      voice: '',
      audioUrl: '',
      imageUrl: '',
      realisedAcoustParams: [],
      squareObject: {},
      videoUrl: '',
      squareObject: {
        fullText: '',
        squares: [],
        type: SQUARE_OBJECT_TYPE.WORD_IN_SENTENCE,
      },
    },
  },
  // {
  //   type: 'split-left-right',
  //   icon: () => <IconSplitVertical />,
  //   placeholder: {
  //     children: [],
  //     layout: 'horizontal',
  //     ratio: 1,
  //   },
  // },
  // {
  //   type: 'split-up-down',
  //   icon: () => <IconSplitHorizontal />,
  //   placeholder: {
  //     children: [],
  //     layout: 'vertical',
  //     ratio: 1,
  //   },
  // },
];

export const TheoryPlaceholder = ({ onChange, flex, previewLP }) => {
  return (
    <div id={previewLP ? "" : "tour-lesson-theory-4"} className="fl justify-content-center align-items-center" style={{ width: 300, height: 210 }}>
      <div className="ui_component_option">
        <div className="component__option__placeholder">
          <div className="fl">
            {theoryplaceHolders.map((data, index) => (
              <button key={`${data.id}${index}`} className="btn_option" type="button" onClick={() => onChange(data)}>
                {data.icon()}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TheoryPlaceholder.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TheoryPlaceholder;
