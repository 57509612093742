import { toast } from "react-toastify";
import { updateCourse } from "../apis/course";
import { updateScopeCourse } from "./ActionCourse";

// export const findChapterIdBySectionId = (id, type) => async (dispatch, getState) => {
//     const {data, idChapterActive} = getState().chapter;
//     let chapterId = -1;
//     if(idChapterActive !== "" && data !== []) {
//       chapterId = idChapterActive;
//     } else {
//       const res = await getChapters();
//       const listChapters = res.result.chapters;
//     //   console.log(listChapters);
//       const chapterActive = listChapters.find(element => {
//         const {sections} = element;
//         let index = -1;
//         if(type === 'lesson') {
//             index = sections.findIndex(ele => ele.type === 'lesson' && ele.lessonId.id == id);
//         }
//         else {
//             index = sections.findIndex(ele => ele.type === 'test' && ele.testId.id == id);
//         }
//         if(index !== -1) return element;
//       });
//       chapterId = chapterActive.id;
//     }
//     if(chapterId !== -1) {
//       dispatch({
//         type: '@@chapters/SET_ACTIVE_CHAPTER',
//         id: chapterId
//       });
//     }else {
//       toast.error("chapter error");
//     }
//   }

export const saveFeeAndCouponAction = () => async (dispatch, getState) => {
    const {course} = getState().chapter;
    const {checkLogin, scopeType, trialStudy, trialLessonNum} = course.scope;

    if(trialStudy && trialStudy !== true) {
        toast.error("Bạn cần nhập số bài học thử !");
        return;
    }
    const res = await updateCourse(course.id, {
        ...course,
        chapterIds: course.chapterIds.map((tp) => tp.id),
    });
    (res && res.status === 1) ? toast.success('Cập nhật thành công') : toast.error('Có lỗi xảy ra')
}