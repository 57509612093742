import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import Loading from '../../components/Common/Loading';
import ListTemplate from '../../components/Lesson-v2/UITemplate/common/ListTemplate';
import LessonActions from '../../components/Lesson-v2/UITemplate/common/LessonActions';
import TabFunction from '../../components/Lesson-v2/UITemplate/TabFunction';
import TabTemplate from '../../components/Lesson-v2/UITemplate/TabTemplate';
import LessonLeft from '../../components/Lesson-v2/LessonLeft/index';
import LessonRight from '../../components/Lesson-v2/LessonRight/index';
import { WelcomeHeader, } from '../../components/Lesson-v2/LessonRight/welcome';
import {getLesson} from '../../reducers/lesson';
import { findChapterIdOfLesson } from '../../actions/ActionLesson';
import './style.scss';

class Lesson extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getLesson(this.props.id);
    //this.props.findChapterIdOfLesson(this.props.id);

  }
  render() {
    const {currentSlide, welcome} = this.props.lesson
    const idCurrent = currentSlide === null ? welcome.id : currentSlide;
    return (
      <>
        {this.props.lesson.syncing && <Loading />}
        <div className="container wrapper_lesson">
          <div className="wrapper_quiz">
            <div className="content">
              <div className="wrapper_template" style={{ paddingRight: '10px'}}>
              {idCurrent !== welcome.id && 
                <div className="lesson__template fl">
                  <TabFunction idChapter={this.props.id} stateProps={this.props.stateProps || {}} />
                  <TabTemplate />
                  <ListTemplate />
                  <LessonActions />
                </div>
              }
              {idCurrent === welcome.id && 
                <div className="lesson__template fl">
                  <TabFunction idChapter={this.props.id} stateProps={this.props.stateProps || {}} />
                  <WelcomeHeader />
                </div>
              }
              </div>
              <div className="fl div__content">
                <LessonLeft />
                <LessonRight />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lesson: state.lesson,
});

export default connect(mapStateToProps, { getLesson, findChapterIdOfLesson })(Lesson);
