import { toast } from 'react-toastify';
import { createCourse, deleteCourse, getCourses, updateCourse, getCourse } from '../apis/course';
import { deleteLesson, createLesson } from '../apis/lesson';
import { createTest } from '../apis/test'
import { getDirectoryUrlFromFile2, imageCompress, removeFileFromDB } from '../utils/imageProcessing';

const initState = {
  data: [],
  syncing: false,
};

export const ActionTypes = {
  SET_DATA: '@@courses/SET_DATA',
  SET_SYNCING: '@@courses/SET_SYNCING',
};

const finalSections = (oldCourse, sections) => {
  if (sections) {
    return sections.map((element) => {
      const tmp = {
        ...(element.id && {id: element.id}),
        type: element.type,
      };
      if (element.lessonId) {
        tmp.lessonId = element.lessonId.id;
      } else {
        tmp.testId = element.testId.id;
      }
      return tmp;
    });
  } else {
    return oldCourse.sections.map((element) => {
      const tmp = {
        ...(element.id && {id: element.id}),
        type: element.type,
      };
      if (element.lessonId) {
        tmp.lessonId = element.lessonId.id;
      } else {
        tmp.testId = element.testId.id;
      }
      return tmp;
    });
  }
};

export const fetchCourseAction = () => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  try {
    const res = await getCourses();
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: ActionTypes.SET_DATA,
      data: res.result.courses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    });
  } catch (error) {
    console.error(error);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const fetchCourseById = (courseId) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  try {
    const res = await getCourse(courseId);
    if (res.code) return toast.error(`Error found: ${res.code}`);
    dispatch({
      type: ActionTypes.SET_DATA,
      data: [res.result.course],
    });
  } catch (error) {
    console.error(error);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const createCourseAction = (course) => async (dispatch, getState) => {
  const { title, file, description, type } = course;
  const scope = {
    scopeType: type === 'PUBLIC' ? 'PUBLIC' : 'PROTECT',
  };
  const { data } = getState().courses;
  // try {
  const compressedFile = await imageCompress(file);
  const imageUrl = await getDirectoryUrlFromFile2(compressedFile);
  const res = await createCourse({ title, imageUrl, description, scope });
  if (res.code) {
    toast.error('Khóa học đã tồn tại');
    return;
  } else {
    dispatch({ type: ActionTypes.SET_DATA, data: [res.result.course, ...data] });
    toast.success(`Đã tạo khóa học: ${title}`);
    return res;
  }
  // } catch (error) {}
};

export const deleteCourseAction = (course, callbackFn) => async (dispatch, getState) => {
  const { data } = getState().courses;
  try {
    removeFileFromDB(course.imageUrl);
    const res = await deleteCourse(course.id);
    if (!res) return;
    dispatch({ type: ActionTypes.SET_DATA, data: data.filter(({ id }) => id !== course.id) });
    toast.success(`Đã xóa khóa học: ${course.title}`);
    callbackFn();
  } catch (error) {
    console.log('error', error);
  }
};

export const moveSection = (sectionId, startIndex, endIndex, courseId) => (dispatch, getState) => {
  const { data: courses } = getState().courses;

  // 1/2 calculate new sections
  const sections = courses.find((element) => element.id === courseId).sections;

  if (sections[startIndex].id !== sectionId) {
    console.log('nothing changed');
    return;
  }

  const result = [...sections];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // 2/2 update it into current chapters
  const newCourses = [...courses];
  newCourses.find(({ id }) => id === courseId).sections = result;
  dispatch({ type: ActionTypes.SET_DATA, data: newCourses });
};

export const deleteActionContent = (idContent, courseId, typeContent) => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  const { data: allCourses } = getState().courses;
  const oldCourse = allCourses.find((element) => element.id === courseId);
  const newSections = oldCourse.sections.filter((element) => {
    if(typeContent === 'lesson') {
      if(element.lessonId) return element.lessonId.id !== idContent
      else return true
    }
    else {
      if(element.testId) return element.testId.id !== idContent
      else return true
    }
  });
  const reduxCourseData = {
    id: oldCourse.id,
    title: oldCourse.title,
    description: oldCourse.description,
    rewardId: null,
    imageUrl: oldCourse.imageUrl,
    sections: finalSections(oldCourse, newSections),
    status: 'DRAFT',
    scope: oldCourse.scope,
  };
  await updateCourse(courseId, reduxCourseData);
  const res = await getCourse(courseId);
  dispatch({
    type: ActionTypes.SET_DATA,
    data: allCourses.map((element) =>
      element.id === courseId
        ? {
            id: res.result.course.id,
            title: res.result.course.title,
            description: res.result.course.description,
            imageUrl: res.result.course.imageUrl,
            sections: res.result.course.sections,
            status: res.result.course.status,
            scope: res.result.course.scope,
          }
        : element,
    ),
  });
  toast.success(typeContent === 'lesson' ? 'Xóa bài học thành công' : 'Xóa bài kiểm tra thành công');
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const updateCourseProfileAction =
  (courseId, { title, description, status, scope, sections, newImageFile = null }) =>
  async (dispatch, getState) => {
    dispatch({ type: ActionTypes.SET_SYNCING, data: true });
    const { data: allCourses } = getState().courses;
    const oldCourse = allCourses.find((element) => element.id === courseId);
    try {
      let imageUrl;
      if (newImageFile) {
        removeFileFromDB(oldCourse.imageUrl);
        const compressedFile = await imageCompress(newImageFile);
        imageUrl = await getDirectoryUrlFromFile2(compressedFile);
      } else {
        imageUrl = oldCourse.imageUrl;
      }

      const reduxCourseData = {
        id: oldCourse.id,
        title: title || oldCourse.title,
        description: description || oldCourse.description,
        rewardId: null,
        imageUrl,
        sections: finalSections(oldCourse, sections),
        status: status || 'DRAFT',
        scope: scope || oldCourse.scope,
      };

      await updateCourse(courseId, reduxCourseData);
      const res = await getCourse(courseId);
      dispatch({
        type: ActionTypes.SET_DATA,
        data: allCourses.map((element) =>
          element.id === courseId
            ? {
                id: res.result.course.id,
                title: res.result.course.title,
                description: res.result.course.description,
                imageUrl: res.result.course.imageUrl,
                sections: res.result.course.sections,
                status: res.result.course.status,
                scope: res.result.course.scope,
              }
            : element,
        ),
      });
      toast.success(`Đã cập nhật khóa học: ${oldCourse.title}`);
    } catch (error) {
      console.error('error', error);
    }
    dispatch({ type: ActionTypes.SET_SYNCING, data: false });
  };

export const createActionContent = (content, course, typeContent) => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_SYNCING, data: true });
  const { data: allCourses } = getState().courses;
  let res = null
  if(typeContent === 'lesson') {
    res = await createLesson({
      ...content,
    });
  }
  else {
    const imageURLFile = await getDirectoryUrlFromFile2(content.imageUrl);
    const thumbnailImageUrlFile = await getDirectoryUrlFromFile2(content.thumbnailImageUrl);
    const finalContent = {
      ...content,
      imageUrl: imageURLFile,
      thumbnailImageUrl: thumbnailImageUrlFile
    }
    res = await createTest(finalContent);
  }
  if (res && res.status === 1) {
    const currentSections =
      course && course.sections && course.sections.length > 0
        ? course.sections.map((tp) => {
            let obj = {};
            if (tp.type === 'test') {
              obj = tp.testLiveId
                ? { type: 'test', testId: tp.testId.id, testLiveId: tp.testLiveId.id }
                : { type: 'test', testId: tp.testId.id };
            } else {
              obj = tp.lessonLiveId
                ? { type: 'lesson', lessonId: tp.lessonId.id, lessonLiveId: tp.lessonLiveId.id }
                : { type: 'lesson', lessonId: tp.lessonId.id };
            }
            return obj;
          })
        : [];

    const resContent = res.result.lesson ? {
      type: 'lesson', 
      lessonId: res.result.lesson.id
    } : {
      type: 'test', 
      testId: res.result.test.id
    }

    await updateCourse(course.id, {
      ...course,
      sections: [...currentSections, resContent],
      status: 'DRAFT'
    });
    const updatedCourse = await getCourse(course.id);
    dispatch({
      type: ActionTypes.SET_DATA,
      data: allCourses.map((element) => {
        if (element.id === course.id) {
          return updatedCourse.result.course;
        } else {
          return element;
        }
      }),
    });
    if (res && res.status === 1) {
      if(res.result.lesson) toast.success('Thêm bài học thành công');
      else toast.success('Thêm bài kiểm tra thành công')
    }
  } else {
    toast.error(`Error found: ${res.code}`);
  }
  dispatch({ type: ActionTypes.SET_SYNCING, data: false });
};

export const courses = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SYNCING:
      return {
        ...state,
        syncing: action.data,
      };

    case ActionTypes.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case 'CHANGE_STATUS_COURSE':
      let newArr = [...state.data];
      let index = newArr.findIndex((element) => element.id == action.data.id);
      newArr[index] = {
        ...state.data[index],
        status: action.data.status,
      };
      return {
        ...state,
        data: newArr,
      };
    case 'CHANGE_COURSE':
      return {
        ...action.data,
      };
    case 'CHANGE_SCOPE_COURSE':
      return {
        ...state,
        scope: {
          ...state.scope,
          [action.data.type]: action.data.value,
        },
      };
    // case 'CHANGE_COUPON_COURSE':
    //   return {
    //     ...state,
    //     coupon: {
    //       ...state.coupon,
    //       [action.data.type]: action.data.value,
    //     }
    //   }
    default:
      return state;
  }
};

export default courses;
