import React from 'react';
import { connect } from 'react-redux';

class ViewTop extends React.Component {
  render() {
    const { lesson } = this.props;
    return (
      <div className="top_app">
        <div className="div_content">
          <div>
            <div className="text-bold text-link fs-25">{lesson.title || ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewTop);
