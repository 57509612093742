import React from 'react';

class Shape extends React.Component {
  render() {
    const { type, color } = this.props;
    return (
      <div style={{ background: color || '#fff' }} className={`div__${type}`}>
        <div className="content">Square</div>
      </div>
    );
  }
}
export default Shape;
