import React from 'react';

class WrapperInput extends React.Component {
  render() {
    const {
      textLable,
      value,
      placeholder,
      onChange,
      type,
    } = this.props;
    return (
      <div className="ui-wrapper_input">
        <label className="text-bold">{textLable}</label>
        <input
          placeholder={placeholder}
          className="next-input"
          onChange={onChange}
          value={value}
          type={type}
        />
      </div>
    );
  }
}
export default WrapperInput;
