import React from 'react'
import { Input, FormText } from 'reactstrap'
import { useFormContext, useController } from 'react-hook-form'
import cls from 'classnames'

const InputField = ({ className, name, id, ...props }) => {
  // -- form state --
  const { control, errors } = useFormContext()

  // -- field state --
  const {
    field: { ref, ...field },
    meta,
  } = useController({ name, control })
  const { invalid } = meta

  return (
    <>
      <Input
        className={cls('form-control', { 'is-invalid': !!invalid }, className)}
        name={name}
        id={id}
        {...field}
        innerRef={ref}
        {...props}
      />

      {errors[name] && <FormText color="danger">{errors[name].message}</FormText>}
    </>
  )
}

export default InputField
