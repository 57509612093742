import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import './style.scss';

import Header from '../../components/Common/Header';
import { USER_ROLES_NAME } from '../../utils/constants';
import { createUserAction, deleteUserAction, fetchUserAction } from '../../reducers/users';
import { deleteAccountAction, getListAccountAction, registerAccountAction } from '../../actions/ActionAccountKeycloak';
import { getRoleName } from '../../utils/keycloak';
import { CMS_KEYCLOAK_GROUP } from '../../constants';

const options = [
  { value: CMS_KEYCLOAK_GROUP.CREATOR, label: 'Người soạn nội dung' },
  { value: CMS_KEYCLOAK_GROUP.PUBLISHER, label: 'Người kiểm duyệt nội dung' },
]

export default function AdminContainer() {
  const { data, syncing, users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(getListAccountAction());
  }, []);

  return (
    <div>
      <Header />
      <div style={{height: '100px'}}></div>
      <div className="admin container">
        <nav>
          <h1>Trang quản lý người dùng</h1>
          <Button color="info" onClick={toggle}>
            Thêm tài khoản
          </Button>
        </nav>

        <table>
          <thead id="header">
            <tr>
              <th>Tên</th>
              <th>Email</th>
              <th>Quyền</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {syncing ? (
              <tr>
                <td>Đang tải, xin hãy đợi...</td>
              </tr>
            ) : (
              users && users.map((u) => <UserTr key={u.id} user={u} id={u.id} />)
            )}
          </tbody>
        </table>
        <CreateUserModal modal={modal} toggle={toggle} />
      </div>
    </div>
  );
}

const UserTr = ({ user, id }) => {
  const dispatch = useDispatch();

  const deleteHandler = () => {
    if (window.confirm(`Xác nhận xoá tài khoản: ${user.username}`)) {
      dispatch(deleteAccountAction(id));
    }
  };

  return (
    <tr>
      <td>{user.username}</td>
      <td>{user.email}</td>
      <td>{getRoleName(user.groups)}</td>
      <td>{user.enabled ? "Hoạt động": "Không hoạt động"}</td>
      <td>
        {user.groups !== CMS_KEYCLOAK_GROUP.ADMIN && (
          <>
            <Button color="warning">Chỉnh sửa</Button>{' '}
            <Button color="danger" onClick={deleteHandler}>
              Xóa
            </Button>
          </>
        )} 
      </td>
    </tr>
  );
};

const CreateUserModal = ({ modal, toggle }) => {
  const { register, handleSubmit } = useForm();
  const [role, setRole] = useState(CMS_KEYCLOAK_GROUP.CREATOR);
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    toggle();
    dispatch(registerAccountAction({
      email: data.email,
      password:  data.password,
      username: data.username,
      groups: [role]
    }));
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Tạo tài khoản</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label htmlFor="nameId">Tên</Label>
            <Input type="text" name="username" id="nameId" placeholder="Nguyen Van A" innerRef={register} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="emailId">Email</Label>
            <Input type="email" name="email" id="emailId" placeholder="nguyenvana@gmail.com" innerRef={register} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="passwordId">Mật khẩu</Label>
            <Input type="password" name="password" id="passwordId" placeholder="******" innerRef={register} />
          </FormGroup>
          <FormGroup style={{ marginBottom: '50px' }}>
            <Label htmlFor="role">Quyền</Label>
            <Select onChange={e => setRole(e.value)} defaultValue={options[0]} options={options} id="role" innerRef={register}/>
          </FormGroup>
          <Button size="large" style={{ float: 'right' }}>Đăng ký</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};