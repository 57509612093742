import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import appRoutes, { defaultRoute } from './appRoutes';
import Flash from '../components/Common/ToastContainer';
import { Troubleshooting } from '../components/Troubleshooting';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { KeycloakInstance } from '../keycloak';
import { getInfoFromToken, getRole, setCookie } from '../utils/cookie';
import Loading from '../components/Common/Loading';
import { useDispatch } from 'react-redux';
import {getStorageItem, setStorageItem} from "../utils/localStorage";
import { fakeDataUser } from './fakeData';

const isPro = true;

const AppRouter = () => {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();

  if (!initialized) {
    return <Loading />
  }

  useEffect(() => {
    console.log("keycloak", keycloak)
    if (keycloak.token && initialized) {
      setCookie('accessToken', keycloak.token, 1000 * 60 * 60 * 24 * 30);
      dispatch({
        type: '@@auth/SET_AUTH_DATA',
        data: getInfoFromToken(keycloak.token)
      });
    }
  }, [keycloak.token, initialized]);

  useEffect(() => {
    if (!getStorageItem("tour")) {
      setStorageItem({
        key: "tour",
        value: JSON.stringify({
          leftAction: true,
          lessonRight: {
            isTour: false,
            type: ""
          }
        })
      });
    }
  }, []);

  //
  if (initialized || !isPro) {
    return (
      <BrowserRouter>
        <Switch>
          {appRoutes.map(({ path, exact = true, component: Component, isPrivate = false, ...rest }) => {
            if (!isPrivate) {
              return <PublicRoute key={path} exact={exact} path={path} component={Component} {...rest} />;
            }
            return <PrivateRoute key={path} exact={exact} path={path} component={Component} {...rest} keycloak={keycloak} />;
          })}
          <Redirect to={defaultRoute} />
        </Switch>
        <Flash />
        {/* <Troubleshooting /> */}
      </BrowserRouter>
    );
  }
}

export default () => {
  return (
    <ReactKeycloakProvider authClient={KeycloakInstance} >
      <AppRouter />
    </ReactKeycloakProvider>
  );
}