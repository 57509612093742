import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import OptionImage from '../Option/OptionImage';
import OptionVideo from '../Option/OptionVideo';
import OptionText from '../Option/OptionText';
import ComponentOption from '../Common/ComponentOption';
import { OptionSquare } from '../Option/OptionSquare';
import { updateMediaObject } from '../../reducers/lesson';
import { MediaObjectRight } from './MediaObjectRight';

export const MediaObject = React.memo(({ versionPreview, customLayoutID, mediaObject, ratio, style, previewLP }) => {
  const dispatch = useDispatch();
  const { type, audioUrl, squareObject, hiddenText } = mediaObject;

  const renderObject = () => {
    switch (mediaObject.type) {
      case 'image':
        return (
          <OptionImage
            icon="none"
            url={mediaObject.imageUrl || ''}
            id={`quiz-img-${mediaObject.id}`}
            handleChangeImage={(value) => dispatch(updateMediaObject(mediaObject.id, { file: value }))}
          />
        );

      case 'video':
        return (
          <OptionVideo
            icon="none"
            url={mediaObject.videoUrl || ''}
            id={`quiz-video-${mediaObject.id}`}
            handleChangeVideo={(value) => dispatch(updateMediaObject(mediaObject.id, { file: value }))}
          />
        );

      case 'text':
        return (
          <OptionText
            versionPreview={versionPreview}
            icon="none"
            textObject={mediaObject}
            handleChange={(changes) => dispatch(updateMediaObject(mediaObject.id, changes))}
            // value={mediaObject.text || ''}
            // textFormat={mediaObject.textFormat}
            // audioUrl={mediaObject.audioUrl}
            // media={mediaObject.media}
            // handleChangeText={(value) => dispatch(updateMediaObject(mediaObject.id, { text: value }))}
            // handleChangeVoice={(audioUrl) => dispatch(updateMediaObject(mediaObject.id, { audioUrl }))}
            // handleChangeFormat={(textFormat) => dispatch(updateMediaObject(mediaObject.id, { textFormat }))}
            
          />
        );

      case 'square':
        return (
          <OptionSquare
            versionPreview={versionPreview}
            squareObject={mediaObject.squareObject}
            mediaObject={mediaObject}
            handleChange={(changes) => dispatch(updateMediaObject(mediaObject.id, { squareObject: { ...mediaObject.squareObject, ...changes } }))}
            updateMediaObject={(changes, type) => dispatch(updateMediaObject(mediaObject.id, { [type]: changes}))}
            previewLP={previewLP}
          />
        );

      default:
        return 'Not found';
    }
  };

  return (
    <div key={mediaObject.id} className={`fl-col type-${type}`}
         style={{
           flex: `0 1 ${ratio}`,
           display: 'flex',
           marginTop: '55px',
           justifyContent: 'center',
           ...style,
        }}
    >
      <ComponentOption
        type={mediaObject.type}
        changeOption={(value) => dispatch(updateMediaObject(mediaObject.id, { type: value }))}
        previewLP={previewLP}
        versionPreview={versionPreview}
      />

      <div style={{ height: 5 }} />
      <div className={`top-right-wrapper`}>
        {renderObject()}
        <MediaObjectRight 
          mediaObject={mediaObject}
          customLayoutID={customLayoutID}
          previewLP={previewLP}
        />
      </div>
    </div>
  );
});

MediaObject.propTypes = {
  mediaObject: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  ratio: PropTypes.number,
};

MediaObject.defaultProps = {
  ratio: 1,
};

export default MediaObject;
