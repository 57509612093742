import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { SlidesActions } from '../../../reducers/lesson';
import { lessonTemplates } from './data';

export default function TabTemplate() {
  const { currentSlide, layoutOption, welcome } = useSelector((state) => state.lesson);
  const dispatch = useDispatch();

  const idCurrent = currentSlide === null ? welcome.id : currentSlide;
  return (
    <div className="collection">
      <div aria-hidden="true" className={`tab `}>
        <div className="m-auto">
          { idCurrent !== welcome.id && (() => {
            const tempalate = lessonTemplates.find((tab) => layoutOption === tab.name);
            return tempalate.icon;
          })()}
        </div>
      </div>
    </div>
  );
}
