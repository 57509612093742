import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BiInfoCircle } from 'react-icons/bi';

// constants
import {
  ORDER_COLLECTION_TYPES,
  ORDER_COLLECTION_TYPE_NAME,
} from '../../../../constants/award-admin/orderCollectionType';
import { ORDER_TYPE_NAME } from '../../../../constants/award-admin/orderType';

// utils
import { formatDate } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/number';

// local
import OrderDataTableCtrl from './OrderDataTable.ctrl';

const RowData = ({ className, id }) => {
  // -- ctrl --
  const {
    // modal
    setIsModalOpen,
    setModalItemId,
  } = OrderDataTableCtrl.useContainer();

  // -- order --
  const {
    createdAt,
    user = {},
    type,
    totalPrice,
    collections = [],
    luckyBoxes = [],
    items = [],
  } = useSelector((state) => state.orders.data[id]) || {};

  // -- system collections & user collections --
  const { systemCollections, userCollections } = useMemo(() => {
    const systemCollections = [];
    const userCollections = [];

    collections.forEach((collection) => {
      if (collection.type === ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION) systemCollections.push(collection);

      if (collection.type === ORDER_COLLECTION_TYPES.USER_COLLECTION) userCollections.push(collection);
    });

    return { systemCollections, userCollections };
  }, [collections]);

  // -- products summary --
  const productsSummary = useMemo(() => {
    const systemCollectionsCount = systemCollections.length;
    const userCollectionsCount = userCollections.length;
    const luckyBoxesCount = luckyBoxes.length;
    const itemsCount = items.length;

    const summary = [];

    if (systemCollectionsCount > 0)
      summary.push(
        `${systemCollectionsCount} x ${ORDER_COLLECTION_TYPE_NAME[ORDER_COLLECTION_TYPES.SYSTEM_COLLECTION]}`,
      );

    if (userCollectionsCount > 0)
      summary.push(`${userCollectionsCount} x ${ORDER_COLLECTION_TYPE_NAME[ORDER_COLLECTION_TYPES.USER_COLLECTION]}`);

    if (luckyBoxesCount > 0) summary.push(`${luckyBoxesCount} x hộp may mắn`);

    if (itemsCount > 0) summary.push(`${itemsCount} x vật phẩm`);

    return summary.join(', ');
  }, [systemCollections, userCollections, luckyBoxes, items]);

  // -- handle view detail --
  const handleViewDetail = useCallback(() => {
    setModalItemId(id);
    setIsModalOpen(true);
  }, []);

  return (
    <tr>
      {/* createdAt */}
      <td className="text-center !t-align-middle">{formatDate(createdAt)}</td>

      {/* user */}
      <td className="!t-align-middle">
        {`${user.name || ''}`}
        <br />
        {user.email}
      </td>

      {/* type */}
      {/* <td className="text-center !t-align-middle">
        {ORDER_TYPE_NAME[type]}
      </td> */}

      {/* price */}
      <td className="text-center !t-align-middle">{`⭐ ${formatNumber(totalPrice)}`}</td>

      {/* products summary */}
      <td className="!t-align-middle">{productsSummary}</td>

      {/* actions */}
      <td className="text-center !t-align-middle">
        <BiInfoCircle className="t-cursor-pointer" onClick={handleViewDetail} />
      </td>
    </tr>
  );
};

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData);
