import React, { memo } from 'react'
import { FormProvider } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

// local
import CollectionItemModalCtrl from './CollectionItemModal.ctrl';
import CollectionItemForm from './CollectionItemForm';

const CollectionItemModal = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // form state
    collectionItemForm,
    // on submit
    onSubmit,
  } = CollectionItemModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>
        {id ? 'Chỉnh sửa vật phẩm' : 'Thêm vật phẩm mới'}
      </ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormProvider {...collectionItemForm}>
          <CollectionItemForm />
        </FormProvider>
      </ModalBody>

      {/* modal footer */}
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {id ? 'Lưu' : 'Thêm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ({ ...props }) => (
  <CollectionItemModalCtrl.Provider initialState={props}>
    <CollectionItemModal {...props} />
  </CollectionItemModalCtrl.Provider>
);