import { useEffect, useState } from 'react'
import { useDebounceCallback } from '@react-hook/debounce'
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect'
import _cloneDeep from 'lodash/cloneDeep'

const useWatchForm = (form, options = {}) => {
  // -- options --
  const { debounce = 500, onChange = () => {}, disabled } = options

  // -- local state --
  const [dirty, setDirty] = useState(false)

  // -- form --
  const { handleSubmit = () => {}, watch = () => {}, formState = {} } = form
  const { isDirty } = formState

  useEffect(() => {
    if (isDirty && !dirty) setDirty(true)
  }, [isDirty])

  const formValues = _cloneDeep(watch())

  const debouncedSetChanges = useDebounceCallback(() => {
    if (!isDirty && !dirty) return
    handleSubmit((values) => onChange(_cloneDeep(values)))()
  }, debounce)

  // watch changes
  useDeepCompareEffect(() => {
    if (!disabled) debouncedSetChanges()
  }, [formValues, dirty, disabled])
}

export default useWatchForm