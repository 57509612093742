import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourseById } from '../../reducers/courses';
import Header from '../../components/Common/Header';
import Loading from '../../components/Common/Loading';
import ChapterLeft from '../../components/Chapter/ChapterLeft';
import ChapterRight from '../../components/Chapter/ChapterRight';
import './style.scss';

const CourseDetailsContainer = ({ id }) => {
  const dispatch = useDispatch();
  const [course, setCourse] = useState();
  const courses = useSelector((state) => state.courses);

  useEffect(() => {
    if (!courses.data.find((element) => element.id === Number(id))) {
      dispatch(fetchCourseById(id));
    }
  }, []);

  useEffect(() => {
    if (!courses.syncing) {
      setCourse(courses.data.find(element => element.id === Number(id)));
    }
  }, [courses.syncing]);

  if (!course) return <Loading />;

    return (
      <>
        <div>
          <Header type="detail" name={course.title} entity='course' status={course.status} />
        </div>
        <div className="container ui__chapter">
          <div className="">
            <div className="fl div_wrapper_chapter">
              <ChapterLeft course={course} />
              <ChapterRight course={course}/>
            </div>
          </div>
        </div>
      </>
    );
}

export default CourseDetailsContainer;