import api from './api';

export const getCourses = async () =>
  api({
    method: 'GET',
    url: '/courses',
  });

export const getCourse = async (courseId) =>
  api({
    method: 'GET',
    url: `/courses/${courseId}`,
  });

export const deleteCourse = async (courseId) =>
  api({
    method: 'DELETE',
    url: `/courses/${courseId}`,
  });

export const createCourse = async ({ title, imageUrl, description, scope }) =>
  api({
    method: 'POST',
    url: `/courses/`,
    data: {
      title,
      imageUrl,
      description,
      scope,
    },
  });

export const updateCourse = async (catId, updateObject) =>
  api({
    method: 'PUT',
    url: `/courses/${catId}`,
    data: updateObject,
  });

export const getQuizzesByCourseId = async (idCourse) => {
  return api({
    method: 'GET',
    url: `/getQuizzesByCourseId/${idCourse}`,
  });
};

export const commentCourse = async (courseId, comment) => {
  const res = await api({
    method: 'POST',
    url: `/courses/comment/${courseId}`,
    data: { comment }
  });
  return res;
}

export const updateCourseV2 = async ({ courseId, title = null, status = null }) => {
  const res = await api({
    method: 'PUT',
    url: `/courses-v2/${courseId}`,
    data: { title, status }
  });
  return res;
}