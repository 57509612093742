export const COLLECTION_ITEM_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OUT_OF_STOCK: 'out of stock',
}

export const COLLECTION_ITEM_STATUS_NAME = {
  [COLLECTION_ITEM_STATUSES.ACTIVE]: 'Trưng bày',
  [COLLECTION_ITEM_STATUSES.INACTIVE]: 'Ẩn',
  [COLLECTION_ITEM_STATUSES.OUT_OF_STOCK]: 'Hết',
}

export const COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS = {
  [COLLECTION_ITEM_STATUSES.ACTIVE]: 'success',
  [COLLECTION_ITEM_STATUSES.INACTIVE]: 'warning',
  [COLLECTION_ITEM_STATUSES.OUT_OF_STOCK]: 'danger',
}

export const COLLECTION_ITEM_STATUS_OPTIONS = [
  {
    value: COLLECTION_ITEM_STATUSES.ACTIVE,
    label: COLLECTION_ITEM_STATUS_NAME[COLLECTION_ITEM_STATUSES.ACTIVE],
    suffixClass: COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_STATUSES.ACTIVE],
  },
  {
    value: COLLECTION_ITEM_STATUSES.INACTIVE,
    label: COLLECTION_ITEM_STATUS_NAME[COLLECTION_ITEM_STATUSES.INACTIVE],
    suffixClass: COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_STATUSES.INACTIVE],
  },
]

export const COLLECTION_ITEM_STATUS_FULL_OPTIONS = [
  ...COLLECTION_ITEM_STATUS_OPTIONS,
  {
    value: COLLECTION_ITEM_STATUSES.OUT_OF_STOCK,
    label: COLLECTION_ITEM_STATUS_NAME[COLLECTION_ITEM_STATUSES.OUT_OF_STOCK],
    suffixClass: COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS[COLLECTION_ITEM_STATUSES.OUT_OF_STOCK],
  },
]