import React, { useEffect, useState } from 'react';
import Header from '../../components/Common/Header';
import './style.scss';
import { CommentIcon, CompareIcon, IconCaretLeft, IconPen } from '../../icons/IconSvg';
import { ButtonAction } from '../../components/Common/Button/ButtonAction';
import { FrameContentPublish } from '../../components/FrameContent/FrameContentPublish';
import { TreeUi } from '../../components/FrameContent/TreeContent';
import { useDispatch, useSelector } from 'react-redux';
import { getFrameContentAction, updateTitleAction } from '../../actions/ActionFrameContent';
import { useHistory } from 'react-router';
import { CommentFrameContent } from '../../components/FrameContent/Comment';
import Loading from '../../components/Common/Loading';

export const FrameContentContainer = ({ isAdmin, courseId, creatorView = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { course, sections, chapters, loading, versionFrameContent } = useSelector((state) => state.frameContent);

  const courseUrl = `course/${course ? course.courseId : ''}`;
  const [activeTab, setActiveTab] = useState('comment');
  const [isOpenPublishModal, setOpenPublishModal] = useState(false);
  const [selected, setSelected] = useState({
    name: '',
    type: '',
    id: '',
  });
  const [currentComment, setCurrentComment] = useState([]);
  const [isEdit, setEdit] = useState(false);

  // dùng để lấy dữ liệu, tuy nhiên khi thực hiện dispatch chưa thể gán name được
  useEffect(() => {
    dispatch(getFrameContentAction(courseId));
    setSelected({
      name: '',
      type: 'course',
      id: courseId,
    });
  }, []);
  // dùng để khắc phục lỗi ban đầu không có courseName
  useEffect(() => {
    if (selected.type === 'course' && !selected.name) {
      setSelected({
        name: course.courseName,
        type: 'course',
        id: courseId,
      });
      setCurrentComment(course.comments);
    }
  }, [course]);
  // dùng để update current comment
  useEffect(() => {
    const { type, id } = selected;
    let tempComments = [];
    if (type === 'course') {
      tempComments = course.comments;
    } else if (type === 'lesson') {
        const section = sections.find((item) => item?.lessonId?.id === id);
        if (section) tempComments = section.lessonId.comments;
    } else {
      const section = sections.find((item) => item?.testId?.id === id);
      if (section) tempComments = section.testId.comments;
    }
    setCurrentComment(tempComments);
  }, [selected]);

  const addComment = (comment) => {
    setCurrentComment([...currentComment, comment]);
  };

  const handleClickActionLeft = (e) => {
    if (isAdmin) {
      setOpenPublishModal(!isOpenPublishModal);
    } else {
      console.log('not admin');
    }
  };
  const handleClickActionRight = (e, value) => {
    if (activeTab === value) return;
    setActiveTab(value);
  };
  const getLabelLeft = () => {
    if (activeTab === 'comment') return 'Bình luận';
    if (activeTab === 'compare') return 'Khung nội dung trước';
  };
  const onSelected = (e, { type, id, name }) => {
    setSelected({
      type,
      id,
      name,
    });
  };
  const getLabelSelected = () => {
    if (selected.type === 'course') return 'Khóa học: ';
    if (selected.type === 'lesson') return 'Bài học: ';
    if (selected.type === 'test') return 'Bài kiểm tra: ';
  };

  const saveEdit = (e) => {
    dispatch(updateTitleAction());
  };

  const exitEdit = (e) => {
    setEdit(false);
  };

  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="admin-frame-content-action">
        <div className="action-left">
          <div className="action-back" onClick={() => history.goBack()}>
            <IconCaretLeft />
          </div>
          <div
            className="action-view-course"
            onClick={() => history.push(isAdmin ? `/admin/${courseUrl}` : `/${courseUrl}`)}
          >
            {course ? course.courseName : ''}
          </div>
          {!creatorView && (
            <div className="action-btn" onClick={handleClickActionLeft}>
              {isAdmin ? 'Kiểm duyệt' : 'Yêu cầu xuất bản'}
            </div>
          )}
        </div>
        {!creatorView && (
          <div className="action-right">
            <div
              className={`compare-action ${activeTab === 'compare' ? 'active' : ''}`}
              onClick={(e) => handleClickActionRight(e, 'compare')}
            >
              <CompareIcon />
              So sánh
            </div>
            <div
              className={`comment-action ${activeTab === 'comment' ? 'active' : ''}`}
              onClick={(e) => handleClickActionRight(e, 'comment')}
            >
              <CommentIcon />
              Bình luận
            </div>
          </div>
        )}
        {isOpenPublishModal && (
          <FrameContentPublish
            isOpen={isOpenPublishModal}
            toggle={() => setOpenPublishModal(!isOpenPublishModal)}
            course={course}
            chapters={chapters}
            sections={sections}
            defaultCollapseIcon={''}
            defaultExpandIcon={''}
            onSelected={onSelected}
            onClose={() => setOpenPublishModal(false)}
          />
        )}
      </div>
      <div className="admin-frame-content">
        <div className="frame-content-left">
          <div className="frame-content-left-label">
            <div className="frame-content-left-action">
              Khung nội dung{' '}
              {!creatorView && (
                <span onClick={() => setEdit(true)}>
                  <IconPen />
                </span>
              )}
            </div>
            {isEdit && (
              <div className="btn-edit-container">
                <ButtonAction className="btn-inactive" text="Thoát" size="small" onClick={exitEdit} />
                <ButtonAction text="Lưu" size="small" onClick={saveEdit} />
              </div>
            )}
          </div>
          <TreeUi course={course} sections={sections} type='original' className={''} onSelected={onSelected} isEdit={isEdit} />
        </div>
        <div className="frame-content-right">
          {activeTab === 'comment' && (
            <div className="comment-title">
              <span className="comment-label">{getLabelSelected()}</span>
              <span
                className="comment-content"
                style={selected.type === 'lesson' ? { cursor: 'pointer', textDecoration: 'underline' } : {}}
                onClick={() => {
                  if (selected.type === 'lesson' && !creatorView) history.push(`/publish/lesson/${selected.id}`);
                  if (selected.type === 'lesson' && creatorView) history.push(`/lesson/${selected.id}`);
                  //if (selected.type === 'test' && !creatorView) history.push(`/publish/test/${selected.id}`);
                  //if (selected.type === 'test' && creatorView) history.push(`/test/${selected.id}`);
                }}
              >
                {selected.name}
              </span>
            </div>
          )}
          {/* <div className="frame-content-right-label">{getLabelLeft()}</div> */}
          {activeTab === 'comment' && (
            <CommentFrameContent comments={currentComment} selected={selected} addComment={addComment} />
          )}
          {activeTab === 'compare' && versionFrameContent && (
            <div className="compare-frame-content">
              <div className="compare-label">Khung nội dung gần nhất</div>
              {versionFrameContent && 
                <TreeUi type='version' course={versionFrameContent.course} sections={versionFrameContent.sections}
                onSelected={onSelected}/>
              }
              {!versionFrameContent && `Đây là phiên bản duy nhất của khung nội dung`}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
