import { refineLearningPoints } from '../reducers/convertLesson';
import { Overview } from '../reducers/templates';
import { updateActionTest } from './ActionTest';

export const getQuizzTestFixed = (datas) => async (dispatch, getState) => {
  if (datas.status === 1) {
    const quizzes =
      datas.result && datas.result.test && datas.result.test.quizIds
        ? datas.result.test.quizIds
        : [];
    const { learningPoints, learningPointIds, entities } = refineLearningPoints(
      quizzes.map((quiz) => {
        return {
          ...quiz,
          type: `quiz:${quiz.type}`,
        };
      }),
    );
    dispatch({
      type: 'RECEIVE__LESSON',
      data: {
        ...{},
        learningPoints,
        learningPointIds,
        entities,
        overview: Overview
      },
    });
  }
};
