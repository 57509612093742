import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  approveLessonAction,
  getAllLessonVersionAction,
  rejectLessonAction,
  requestToChangeLessonAction,
  suspendLessonAction,
} from '../../../../actions/ActionPublishManagement';
import { STATUS_ACTION } from '../../../../constants';
import { IconCaretLeft, IconClose } from '../../../../icons/IconSvg';
import { getSectionStatus, getListAction } from '../../../../utils/publishManagement';
import { ButtonAction } from '../../../Common/Button/ButtonAction';
import { VersionComment } from '../../VersionComment';
import '../style.scss';
import { useHistory } from 'react-router';
import { formatDate } from '../../../../utils/date';

export const LessonListAction = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { versionLesson } = useSelector((state) => state.lesson);
  const [currVerLesson, setCurrVerLesson] = useState();
  const [isOpenHistory, setOpenHistory] = useState(false);
  const [isOpenComment, setOpenComment] = useState(false);
  const [typeAction, setTypeAction] = useState('');
  const listAction = getListAction(data.version ? data.version.status : '');

  useEffect(() => {
    setCurrVerLesson(versionLesson.length > 0 ? versionLesson[0].id : '');
  }, [versionLesson]);

  const handleClick = (e, type) => {
    setOpenComment(!isOpenComment);
    setTypeAction(type);
  };
  const onClickSend = (comment) => {
    if (typeAction === STATUS_ACTION.APPROVE.en) {
      dispatch(approveLessonAction(comment));
    } else if (typeAction === STATUS_ACTION.REJECT.en) {
      dispatch(rejectLessonAction(comment));
    } else if (typeAction === STATUS_ACTION.REQUEST_TO_CHANGE.en) {
      dispatch(requestToChangeLessonAction(comment));
    } else {
      dispatch(suspendLessonAction(comment));
    }
    setOpenComment(false);
  };

  const renderBtnAction = () => {
    return (
      <>
        {/* {isLoading && (<CircularProgress sx={{ width: '30px', height: '30px', marginRight: '10px' }} size="medium" />)} */}
        {listAction.map((item) => {
          return (
            <ButtonAction
              text={item.vi}
              onClick={(e) => handleClick(e, item.en)}
              className={
                item.en === STATUS_ACTION.REJECT.en || item.en === STATUS_ACTION.SUSPEND.en ? 'btn-inactive' : ''
              }
            />
          );
        })}
      </>
    );
  };

  const onClickViewHistory = (e) => {
    if (!isOpenHistory) {
      dispatch(getAllLessonVersionAction());
    }
    setOpenHistory(true);
  };

  return (
    <div className="lesson-list-action">
      <div className="lesson-title">
        <div className="btn-back" onClick={(e) => history.goBack()}>
          <IconCaretLeft />
        </div>
        <div className="lesson-info">
          <div className="lesson-name">
            {data.title}<span onClick={onClickViewHistory}>Xem lịch sử phiên bản</span>
          </div>
          <div className="lesson-status">
            Trạng thái: <span>{getSectionStatus(data.version ? data.version.status : '')}</span>
          </div>
        </div>
      </div>
      <div className="actions">
        {renderBtnAction()}
        {isOpenComment && (
          <div className="version-comment-container">
            <VersionComment onClose={() => setOpenComment(false)} onClick={onClickSend} />
          </div>
        )}
      </div>
      {isOpenHistory && (
        <div className="history-version">
          <div className="history-version-title">
            <span>Lịch sử version</span>
            <span onClick={() => setOpenHistory(false)}>
              <IconClose />
            </span>
          </div>
          <div className="history-version-content">
            <div className="timeline">
              {versionLesson &&
                versionLesson.map((item) => {
                  return (
                    <div
                      className={`timeline-item ${currVerLesson === item.id ? 'active' : ''}`}
                      onClick={() => setCurrVerLesson(item.id)}
                    >
                      {formatDate(item.version.createdAt)}
                      <div className="status">Trạng thái: {getSectionStatus(item.version.status)}</div>
                    </div>
                  );
                })}
            </div>
            <div className="preview-lesson">
              {versionLesson && versionLesson.length > 0 && (
                <iframe src={`/preview/lesson/${currVerLesson}`}></iframe>
              )}
              {versionLesson && versionLesson.length === 0 && (
                <div style={{ width: '100%', height: '100%', background: 'white' }}>Đây là phiên bản duy nhất của khóa học</div>
              )}
            </div>
          </div>
          <div className="history-version-action"></div>
        </div>
      )}
    </div>
  );
};
