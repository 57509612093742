import React, { useMemo, memo } from 'react'
import { FormProvider } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup } from 'reactstrap';

// local
import GenerateMoreLuckyBoxModalCtrl from './GenerateMoreLuckyBoxModal.ctrl';
import GenerateMoreLuckyBoxForm from './GenerateMoreLuckyBoxForm';

const GenerateMoreLuckyBoxModal = memo(({ id, open, toggle }) => {
  // -- ctrl --
  const {
    // form state
    generateMoreLuckyBoxForm,
    // on submit
    onSubmit,
  } = GenerateMoreLuckyBoxModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>
        Tạo thêm hộp may mắn
      </ModalHeader>

      {/* modal body */}
      <ModalBody>
        <FormProvider {...generateMoreLuckyBoxForm}>
          <GenerateMoreLuckyBoxForm isEdit={!!id} />
        </FormProvider>
      </ModalBody>

      {/* modal footer */}
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          Tạo thêm
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ({ ...props }) => (
  <GenerateMoreLuckyBoxModalCtrl.Provider initialState={props}>
    <GenerateMoreLuckyBoxModal {...props} />
  </GenerateMoreLuckyBoxModalCtrl.Provider>
);