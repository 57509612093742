import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../style.scss';

import { IconAdd, IconMinus } from '../../../../../icons/IconSvg';
import { generateWelcomeLesson } from '../utils';
import { OverviewActions } from '../../../../../actions/Overview';

import { checkAndfindIdCheckpoint } from '../../../../../actions/ActionCheckpoint';
import { toast } from 'react-toastify';
import { deleteLearningPointer } from '../../../../../actions/ActionLesson';

export const PartTitles = () => {

    const dispatch = useDispatch();
    const { localState, syncing, oldLocalState } = useSelector((state) => state.lesson);
    const { overview, learningPoints } = localState;
    const { learningPointPartTitles, template, welcome, learningPointParts } = overview;

    const handleClickAddLpPart = () => {
        if (learningPointParts <= 4) {
          dispatch(OverviewActions({
            ...overview,
            learningPointPartTitles: [
              ...learningPointPartTitles,
              {
                id: overview.learningPointPartTitles.length + 1,
                title: '',
              },
            ],
            learningPointParts: overview.learningPointParts + 1
          }));
        } else {
          toast.error('Bài học có tối đa 5 phần');
        }
      };

      const handleClickMinusLpPart = async () => {
        if (learningPointParts > 2) {
          const currTitle = learningPointPartTitles[learningPointParts-1].title;
          const id = checkAndfindIdCheckpoint(currTitle, learningPoints);
          // id === -1 có nghĩa là title hiện tại chưa có trong checkpoint nào
          if(id === -1 ? true :  window.confirm(`Slide reward với title "${currTitle} sẽ bị mất. Bạn vẫn muốn tiếp tục ?"`)) {
              if(id !== -1) {
                const check = oldLocalState.learningPoints.find(element => element.id === id);
                if(check) {
                  setIdCheckpoint([...idCheckpoint, id]);
                }
              };
              dispatch(deleteLearningPointer(id));
              let temp = [...learningPointPartTitles];
              temp.pop();
              dispatch(OverviewActions({
                ...overview,
                learningPointPartTitles: temp,
                learningPointParts: overview.learningPointParts - 1,
                welcome: {
                  ...welcome,
                  text: generateWelcomeLesson(overview.content.text ,temp, template),
                  audioUrl: ''
                }
              }));
            };
        } else {
          toast.error('Bài học có tối thiểu 2 phần');
        };
      };
    
      const handleOnChangeTitle = (e, index) => {
        const newElement = {
          id: index + 1,
          title: e.target.value,
        };
        let newTitles = [...learningPointPartTitles];
        newTitles[index] = newElement;
        let newWelcomeText = '';
        if (learningPointPartTitles[0] && learningPointPartTitles[1] && newTitles !== learningPointPartTitles) {
          newWelcomeText = generateWelcomeLesson(overview.content.text, newTitles, template);
        }
        dispatch(
          OverviewActions({
            ...overview,
            learningPointPartTitles: newTitles,
            welcome: {
              ...welcome,
              text: newWelcomeText !== '' ? newWelcomeText : welcome.text,
              audioUrl: '',
            },
          }),
        );
      };
    return (
        <div className="main step3">
            <div className="part_number">
                <div className="part_number__text">Số phần nội dung chính</div>
                <div onClick={handleClickMinusLpPart}>
                        <IconMinus />
                </div>
                <div className="show_part_number">{learningPointParts}</div>
                <div onClick={handleClickAddLpPart}>
                        <IconAdd />
                </div>
            </div>
            <div className="edit_titles">
                { learningPointPartTitles.map((element, index) => {
                    return (
                    <div
                        key={`lpTitle` + index}
                        className={`part_title ${index + 1}`}
                        onChange={(e) => handleOnChangeTitle(e, index)}
                    >
                        <input className={`part_title_input ${index + 1}`} type="text" defaultValue={element.title} />
                    </div>
                    );
                })}
            </div>
        </div>
    );
}