import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input } from 'reactstrap'

// hooks
import useWatchForm from '../../../../hooks/useWatchForm';

// local
import CollectionCategoryDataTableCtrl from './CollectionCategoryDataTable.ctrl'

const Filters = () => {
  // -- ctrl --
  const {
    // handle change filters
    handleChangeFilters,
  } = CollectionCategoryDataTableCtrl.useContainer()

  // -- form state --
  const filtersForm = useForm({
    defaultValues: {
      keyword: ''
    }
  });
  const { register, handleSubmit } = filtersForm
  useWatchForm(filtersForm, {
    onChange: (values) => {
      handleChangeFilters(values)
    },
    debounce: 200,
  })

  return (
    <Form>
      <FormGroup>
        <Label htmlFor="keyword">Tìm kiếm</Label>
        <Input
          type="text"
          name="keyword"
          id="keyword"
          placeholder="Tìm kiếm theo tên, mô tả thể loại..."
          innerRef={register}
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault(); 
          }}
        />
      </FormGroup>
    </Form>
  );
};

export default Filters;