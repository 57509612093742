import React, { useState } from 'react';
import { IconVideocam } from '../../icons/IconSvg';
import './style.scss';
import { fileToBase64 } from '../../utils/imageProcessing';
import { getFullUrl } from '../../utils/imageProcessing';
import { toast } from 'react-toastify';

class OptionVideo extends React.Component {
  async readVideo(event, videoT, videoS) {
    const ListFileSupport = ['video/mp4'];
    if (!ListFileSupport.includes(event.target.files[0].type)) {
      return toast.error('Chúng tôi không hỗ trợ định dạng này !');
    }
    // if (event.target.files && event.target.files[0].size > 1048576) {
    //   return toast.error('Kích thước của video không được vượt quá 1MB');
    // }
    const videoSrc = document.querySelector(`#${videoS}`);
    const videoTag = document.querySelector(`#${videoT}`);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        videoSrc.src = e.target.result;
        videoTag.load();
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      this.props.handleChangeVideo(event.target.files[0]);
    }
  }
  render() {
    const { icon } = this.props;
    return (
      <div className="ui__option">
        <div className="ui__wraper_option">
          <div className="option__video">
            {/* <div>{icon && icon === 'none' ? null : <IconVideocam />}</div> */}
            <div className="div__wraper">
              <div className="div__wraper_content">
                <div className="form_video">
                  <div
                    className="media"
                    onClick={() => {
                      if (this.uploadRef) {
                        this.uploadRef.click();
                      }
                    }}
                  >
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept={['video/mp4']}
                      multiple
                      onChange={(e) => this.readVideo(e, `video-tag-${this.props.id}`, `video-source-${this.props.id}`)}
                      ref={(ref) => {
                        this.uploadRef = ref;
                      }}
                    />
                    <video controls id={`video-tag-${this.props.id}`}>
                      {' '}
                      <source id={`video-source-${this.props.id}`} src={getFullUrl(this.props.url || '')} />{' '}
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionVideo;
