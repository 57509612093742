import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { changeQuiz } from '../../../actions/ActionQuiz';
import { BlankQuiz } from '../../../reducers/templates';
import { getQuizPart } from '../getData';
import './style.scss';
import PopoverWrongAnswer from './PopoverWrongAnswer';
import { splitDataEnter } from '../quizUtils';
import InputTextarea from '../common/InputTextarea';

class ContentQuestion extends React.Component {
  state = {
    contenQuestion: '',
    isOpen: '',
  };
  componentDidMount() {
    const quizParts = getQuizPart(this.props.lesson);
    let contentQ = quizParts.map((quiz) => (quiz.content ? quiz.content.text : '')).join(' ');
    this.setState({ contenQuestion: contentQ });
  }
  debouncedQuery = debounce((lists) => {
    this.props.changeQuiz('quizParts', lists, 'quiz', this.props.currentSlide);
  }, 300);

  handleChange = (value) => {
    const lists = this.splitValue(value);
    this.setState({
      contenQuestion: value,
    });
    this.debouncedQuery(lists);
  };
  splitValue = (value) => {
    const datas = splitDataEnter(value);
    const lists = datas.reduce((newArrays, element) => {
      const newObj = { ...BlankQuiz, content: { ...BlankQuiz.content, text: element } };
      if (element && element !== '') {
        newArrays = [...newArrays, newObj];
      }
      return newArrays;
    }, []);
    return lists;
  };

  render() {
    const quizParts = getQuizPart(this.props.lesson);
    return (
      <div>
        <div className="">
          <div className="text-bold">Nội dung bài tập</div>
          <div className="m-20 wrapper_quiz_textarea">
            <InputTextarea value={this.state.contenQuestion} onChange={this.handleChange} />
          </div>
        </div>
        {quizParts.length > 0 ? (
          <div className="active__text">
            <div>
              <b>Ẩn chữ và tạo câu trả lời sai</b> bằng cách <b>click</b> vào chữ cần ẩn tương ứng
            </div>
            <div className="list__text">
              {quizParts.map((data, index) => {
                if (data.content.text !== '\n') {
                  return (
                    <div style={{ marginBottom: '10px' }} key={index} id={`div_cq_${index}`}>
                      <button
                        id={this.props.currentSlidePreview ? '' : `button_cq_drag_drop_${index}`}
                        onClick={() => {
                          this.setState({ isOpen: index });
                        }}
                        className={`button_text ${data.isHidden ? 'button_text_active' : ''}`}
                      >
                        {data.content.text}
                      </button>
                      {this.props.currentSlidePreview ? null : (
                        <PopoverWrongAnswer
                          quizPart={data}
                          id={`button_cq_drag_drop_${index}`}
                          toggle={() => this.setState({ isOpen: '' })}
                          popoverOpen={this.state.isOpen === index}
                          className={this.props.currentSlidePreview ? '' : `popoverWrongAnswer`}
                          className1={`popoverWrongAnswer_${index}`}
                          isOpen={this.state.isOpen}
                          index={index}
                          quizParts={quizParts}
                          testClass={`.popoverWrongAnswer.show`}
                        />
                      )}
                    </div>
                  );
                }
              })}
            </div>
            {/* {quizpartsEnter.map((partEnter, indx) => {
              return (
                <div key={`e-${indx}`} className="list__text">
                  {Object.values(partEnter).map((data, index) => {
                    if (data.content.text !== '\n') {
                      return (
                        <div style={{ marginBottom: '10px' }} key={index} id={`div_cq_${index}_${indx}`}>
                          <button
                            id={this.props.currentSlidePreview ? '' : `button_cq_drag_drop_${index}_${indx}`}
                            onClick={() => {
                              this.setState({ isOpen: `${index}_${indx}` });
                            }}
                            className={`button_text ${data.isHidden ? 'button_text_active' : ''}`}
                          >
                            {data.content.text}
                          </button>
                          {this.props.currentSlidePreview ? null : (
                            <PopoverWrongAnswer
                              quizPart={data}
                              id={`button_cq_drag_drop_${index}_${indx}`}
                              toggle={() => this.setState({ isOpen: '' })}
                              popoverOpen={this.state.isOpen === `${index}_${indx}`}
                              className={this.props.currentSlidePreview ? '' : `popoverWrongAnswer_${index}_${indx}`}
                              isOpen={this.state.isOpen}
                              index={`${index}_${indx}`}
                              quizParts={quizParts}
                              testClass={`.popoverWrongAnswer_${index}_${indx}.show`}
                            />
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })} */}
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuiz })(ContentQuestion);
