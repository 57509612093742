import React from 'react';
import Header from '../../components/Common/Header';
import LessonNavTemplateLeft from '../../components/LessonNavigationTemplate/LessonNavTempLeft';
import LessonNavTemplateRight from '../../components/LessonNavigationTemplate/LessonNavTempRight';
import './style.scss';

const LessonNavTemplate = () => {


    return (
        <div className="LessonNavTemplate">
            <div className="LessonNavTemplate_header">
                <Header type="detail" name="A" link="/course" />
            </div>
            <div className="LessonNavTemplate_main">
                <span className="main_header">Thiết lập map cho template</span>
                <div className="main_body">
                    <LessonNavTemplateLeft />
                    <LessonNavTemplateRight />
                </div>
                <button className="btn--save">Lưu</button>
            </div>
        </div>
    );
};

export default LessonNavTemplate;