import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { upsertCollectionCategory } from '../../../../reducers/award-admin/collectionCategory';

// local
import { formValidationSchema as collectionCategoryFormValidationSchema } from './CollectionCategoryForm/CollectionCategoryForm';

const useCollectionCategoryModalCtrl = ({ id, toggle } = {}) => {
  // -- collection category --
  const { imageUrl, name, nameVoiceUrl, description, descriptionVoiceUrl } =
    useSelector((state) => state.collectionCategories.data[id]) || {};

  // -- form state --
  const collectionCategoryForm = useForm({
    resolver: yupResolver(collectionCategoryFormValidationSchema),
    defaultValues: {
      id,
      previewImageUrl: imageUrl,
      image: null,
      name,
      nameVoiceUrl,
      description,
      descriptionVoiceUrl,
    },
  });
  const { handleSubmit } = collectionCategoryForm;

  // -- dispatch --
  const dispatch = useDispatch();

  // -- on submit --
  const onSubmit = handleSubmit(async ({ name, nameVoiceUrl, description, descriptionVoiceUrl, image }) => {
    if (
      await dispatch(
        upsertCollectionCategory({
          _id: id,
          name,
          nameVoiceUrl,
          description,
          descriptionVoiceUrl,
          image: image && image[0],
          imageUrl,
        }),
      )
    )
      toggle();
  });

  return {
    // form state
    collectionCategoryForm,
    // on submit
    onSubmit,
  };
};

export default createContainer(useCollectionCategoryModalCtrl);
