import React from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { CourseIcon, SectionIcon } from '../../icons/IconSvg';
import './style.scss';
import { useDispatch } from 'react-redux';
import { updateFieldCourse, updateFieldSection } from '../../actions/ActionFrameContent';
import { getFrameContentStatus, getSectionStatus } from '../../utils/publishManagement';

export const TreeUi = ({
  course,
  sections,
  type,
  className,
  onSelected,
  isEdit,
}) => {
  const dispatch = useDispatch();
  /*let sections = [];
  chapters.forEach((chapter) => {
    sections = [...sections, ...chapter.sections.map((section) => `section-${section.sectionId}`)];
  });*/
  const defaultExpanded = [
    `course-${course.courseId}`,
    //...chapters.map((chapter) => `chapter-${chapter.chapterId}`),
    ...sections,
  ];

  const Label = (id, type, label, status, isEdit = false) => {
    const onChange = (e) => {
      const value = e.target.value;
      if (value) {
        if (type === 'course') {
          dispatch(updateFieldCourse({ id, key: 'courseName', value }));
        } /*else if (type === 'chapter') {
          dispatch(updateFieldChapter({ id, key: 'chapterName', value }));
        }*/ else if (type === 'lesson' || type === 'test') {
          dispatch(updateFieldSection({ id, key: 'sectionName', value, type }));
        }
      }
    };
    const getIcon = () => {
      if (type === 'course') return <CourseIcon />;
      //if (type === 'chapter') return <ChapterIcon />;
      if (type === 'lesson' || type === 'test') return <SectionIcon />;
    };
    return (
      <div className={`${type === 'lesson' || type === 'test' ? 'section' : type}-label`}>
        {getIcon()}
        {!isEdit && (
          <>
            <span className="label">{label}</span>
            <span className="status">
              {type === 'course' /*|| type === 'chapter'*/ ? getFrameContentStatus(status) : getSectionStatus(status)}
            </span>
          </>
        )}
        {isEdit && <input className="input-label" defaultValue={label} onChange={onChange} />}
      </div>
    );
  };

  const onClick = (e, data) => {
    if (!isEdit) onSelected(e, data);
  };

  return (
    <div className={className}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={null}
        defaultExpandIcon={null}
        sx={{ height: 240, flexGrow: 1, maxWidth: '100%' }}
        expanded={defaultExpanded}
      >
        <TreeItem
          className="course-item"
          nodeId={`course-${course.courseId}`}
          label={Label(course.courseId, 'course', course.courseName, course.status, isEdit)}
          onClick={(e) => onClick(e, { type: 'course', id: course.courseId, name: course.courseName })}
        >
          {/*{chapters.map((chapter, index) => {
            return (
              <TreeItem
                className="chapter-item"
                nodeId={`chapter-${chapter.chapterId}`}
                label={Label(chapter.chapterId, 'chapter', chapter.chapterName, chapter.status, isEdit)}
                key={`chapter-${index}`}
                onClick={(e) => onClick(e, { type: 'chapter', id: chapter.chapterId, name: chapter.chapterName })}
              >
                {chapter.sections.map((section, _index) => {
                  return (
                    <TreeItem
                      className="section-item"
                      nodeId={`section-${section.sectionId}`}
                      label={Label(section.sectionId, section.type, section.sectionName, section.status, isEdit)}
                      key={`sub-chapter-${_index}`}
                      onClick={(e) =>
                        onClick(e, { type: section.type, id: section.sectionId, name: section.sectionName })
                      }
                    />
                  );
                })}
              </TreeItem>
            );
          })}*/}
          {sections.map((section, index) => {
            return (
              type === 'original' ? (<TreeItem
                className="section-item"
                nodeId={`section-${section.id}`}
                label={Label(section.lessonId ? section.lessonId.id : section.testId.id, section.type, section.lessonId ? section.lessonId.title : section.testId.title, 
                             section.lessonId ? section.lessonId.status : section.testId.status, isEdit)}
                key={`sub-chapter-${index}`}
                onClick={(e) => onClick(e, { type: section.type, id: section.lessonId ? section.lessonId.id : section.testId.id,
                                             name: section.lessonId ? section.lessonId.title : section.testId.title })}
              />) : (<TreeItem
                className="section-item"
                nodeId={`section-${section.id}`}
                label={Label(section.id, section.type, section.title, section.status, isEdit)}
                key={`sub-chapter-${index}`}
                onClick={(e) => onClick(e, { type: section.type, id: section.lessonId ? section.lessonId : section.testId, name: section.title })}
              />)
            );
          })}
        </TreeItem>
      </TreeView>
    </div>
  );
};
