import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { squareQuizType,} from '../../../../reducers/templates';
import { SlidesActions } from '../../../../reducers/lesson';
import { lessonTemplates } from '../data';

export default function ListTemplate() {
  const {
    localState,
    currentSlide,
    layoutOption,
    welcome
  } = useSelector((state) => state.lesson);
  const dispatch = useDispatch();
  const idCurrent = currentSlide
                      ? currentSlide
                      : localState.learningPoints.length > 0
                      ? localState.learningPoints[0].id
                      : welcome.id;

  const objCurrent = idCurrent===welcome.id
   ? welcome
   : (localState.learningPoints.length > 0 ? localState.learningPoints.find((data) => data.id === currentSlide) || {} : {});
  const currentLayoutOption = idCurrent===welcome.id
   ? welcome.layout 
   : (localState.learningPoints.length > 0 ? objCurrent.type.split(':')[0] : layoutOption);

  return (
    <div id="tour-lesson-layout" className="lesson__detail">
      {idCurrent !== welcome.id && lessonTemplates
        .find(({ name }) => name === currentLayoutOption)
        .layouts.map((layout) => (
          <div
            aria-hidden="true"
            key={layout.name}
            onClick={() =>
              dispatch(
                SlidesActions.updateCurrentLayout({
                  ...objCurrent,
                  layout: layout.id,
                  type: layout.name,
                  customLayout: layout.customLayout,
                  squareQuizType: layout.name === 'quiz:square_quiz' ? squareQuizType[0] : null,
                }),
              )
            }
            className={`item_detail ${objCurrent.type && objCurrent.type === layout.name ? 'active' : ''}`}
          >
            {layout.icon}
          </div>
        ))}
        {idCurrent === welcome.id &&
           <div className="lesson__detail__overview fl_center"
                style={{
                  textAlign: 'center',
                  margin: '15px',

                  color: 'white',
                  // fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '40px',
                  lineHeight: '45px',
                }}
           >
            Thiết lập nội dung
           </div>}
    </div>
  );
}
