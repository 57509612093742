import React from 'react';
import { IconClose } from '../../../../icons/IconSvg';
import { CommentFrameContent } from '../../../FrameContent/Comment';
import '../style.scss';

export const CommentLesson = () => {
    return (
        <div className="comment-lesson">
            <div className="comment-lesson-header">
                <div className="comment-lesson-title">{'Bình luận'}</div>
                <div className="close">
                    <IconClose />
                </div>
            </div>
        </div>
    );
}