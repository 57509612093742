import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import { NotPermission } from '../containers/NotPermission';

const isPro = true;

export default function PrivateRoute({ component: Component, keycloak, realmRoles, resourceRoles, ...rest }) {
    if (isPro && keycloak && !keycloak.authenticated) {
      keycloak.login();
      return null;
    }
    const isAuthorized = ({ realmRoles, resourceRoles }) => {
      if (!isPro) return true;
      let result = false;
      if (keycloak && keycloak.authenticated) {
        realmRoles.every((role) => {
          const realm = keycloak.hasRealmRole(role);
          result = realm;
          return !realm;
        });
        resourceRoles.every((role) => {
          const resource = keycloak.hasResourceRole(role);
          result = resource;
          return !resource;
        });
      }
      return result;
    };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized({ realmRoles, resourceRoles }) ? (
          <Component {...props} />
        ) : (
          // <Redirect to={{ pathname: '/' }} />
          <NotPermission />
        );
      }}
    />
  );
}
