import React from 'react';
import './style-radio-button.scss';

class RadioButton extends React.Component {
  render() {
    const { checked } = this.props;
    return (
      <div
        onClick={() => (this.props.changeRadioButton ? this.props.changeRadioButton() : null)}
        className={`radio__button ${checked ? 'radio__button_active' : ''}`}
      >
        <input readOnly type="radio" checked="checked" name="radio" />
        <span className="checkmark"></span>
      </div>
    );
  }
}
export default RadioButton;
