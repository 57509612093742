import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames'
import { Collapse } from 'reactstrap'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

// utils
import { formatNumber } from '../../../../../utils/number';

// local
import OrderItemDataTable from '../OrderItemDataTable';

const RowData = ({ className, collection }) => {
  // -- collection --
  const { name, price, quantity, template = {}, items = [] } = collection
  const { bgImageUrl, bgImageSound } = template

  // -- is collapse open --
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const toggleCollapse = useCallback(() => setIsCollapseOpen(prevState => !prevState), [setIsCollapseOpen])

  return (
    <>
      <tr>
        {/* template */}
        <td className="t-w-[300px] text-center">
          <img className="t-w-[150px] t-h-[150px] t-object-contain" src={bgImageUrl} />

          <audio className="t-h-[30px]" controls>
            <source src={bgImageSound} />
            Your browser does not support the audio element.
          </audio>
        </td>

        {/* name */}
        <td className="text-center !t-align-middle">
          {name}
        </td>

        {/* price */}
        <td className="text-center !t-align-middle">
          {`⭐ ${formatNumber(price)}`}
        </td>

        {/* quantity */}
        <td className="text-center !t-align-middle">
          {formatNumber(quantity)}
        </td>

        {/* actions */}
        <td className="text-center !t-align-middle">
          {
            isCollapseOpen ?
              <BiChevronUp className="t-cursor-pointer" onClick={toggleCollapse} />
              : <BiChevronDown className="t-cursor-pointer" onClick={toggleCollapse} />
          }
        </td>
      </tr>

      <tr className="bg-light">
        <td colSpan="5" className={cls('', { 'p-0': !isCollapseOpen })}>
          <Collapse isOpen={isCollapseOpen} >
            <OrderItemDataTable items={items} />
          </Collapse>
        </td>
      </tr>
    </>
  )
}

RowData.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.object.isRequired,
};

export default memo(RowData)