import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// constants
import {
  SYSTEM_COLLECTION_STATUS_NAME,
  SYSTEM_COLLECTION_STATUS_COLOR_SUFFIX_CLASS,
} from '../../../../constants/award-admin/systemCollectionStatus'

const RowData = ({ className, id }) => {
  // -- system collection --
  const {
    templateId,
    template: { previewBgImageUrl, previewBgSoundUrl } = {},
    status,
    name,
    items = [],
    description,
  } = useSelector((state) => state.systemCollections.data[id]) || {};

  return (
    <tr>
      {/* template */}
      <td className="t-w-[300px] text-center">
        <img className="t-w-[150px] t-h-[150px] t-object-contain" src={previewBgImageUrl} />

        <audio style={{ height: '30px' }} controls>
          <source src={previewBgSoundUrl} />
          Your browser does not support the audio element.
        </audio>
      </td>

      {/* status */}
      <td className="text-center !t-align-middle">
        <p className={`mb-0 text-${SYSTEM_COLLECTION_STATUS_COLOR_SUFFIX_CLASS[status]}`}>
          <b>{SYSTEM_COLLECTION_STATUS_NAME[status]}</b>
        </p>
      </td>

      {/* name */}
      <td className="text-center !t-align-middle">
        {name}
      </td>

      {/* items */}
      <td className="!t-px-[2rem] !t-py-[1.25rem] !t-align-middle">
        {items.map(({ id: itemId, name: itemName }) => (
          <p key={`${id}-${itemId}`} className="mb-0">{itemName}</p>
        ))}
      </td>

      {/* description */}
      <td className="!t-align-middle">
        {description}
      </td>
    </tr>
  )
}

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData)