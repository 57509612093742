import { CMS_KEYCLOAK_GROUP } from "../constants";

export const getRoleName = (groupName) => {
    switch (groupName) {
        case CMS_KEYCLOAK_GROUP.ADMIN:
            return "Quản lý";
        case CMS_KEYCLOAK_GROUP.CREATOR:
            return "Người soạn nội dung";
        case CMS_KEYCLOAK_GROUP.PUBLISHER:
            return "Người kiểm duyệt nội dung";
        default:
            return 'unknown';
    }
}