import React from 'react';
import { connect } from 'react-redux';
import { squareQuizType } from '../../../reducers/templates';
import { changeQuiz } from '../../../actions/ActionQuiz';
import { SlidesActions } from '../../../reducers/lesson';
import { SquareQuizOption } from './SquareQuizOption';
import { SQUARE_TYPES_CONSTANTS } from '../../Option/SquareUtils';

class SquareQuiz extends React.Component {
  state = {
    name: '',
    typeSquare: squareQuizType[0],
  };
  getCurrentSlide = () => {
    const current = this.props.currentSlidePreview ? this.props.currentSlidePreview.id : this.props.currentSlide;
    return current;
  };
  componentDidMount() {
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    this.setState({
      typeSquare: learningCurrent.squareQuizType || squareQuizType[0],
    });
  }
  componentDidUpdate(prevProps) {
    const { learningPoints, currentSlide } = this.props;
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    const learningOld = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : prevProps.learningPoints.find((learning) => learning.id === prevProps.currentSlide) || {};
    if (
      learningCurrent.squareQuizType &&
      learningOld.squareQuizType !== learningCurrent.squareQuizType &&
      learningCurrent.type === 'quiz:square_quiz'
    ) {
      this.setState({ typeSquare: learningCurrent.squareQuizType });
    }
  }
  render() {
    const { learningPoints } = this.props;
    const currentSlide = this.getCurrentSlide();
    const learningCurrent = this.props.currentSlidePreview
      ? this.props.currentSlidePreview
      : learningPoints.find((learning) => learning.id === currentSlide) || {};
    let currSquareQuizType = '';
    if (learningCurrent.squareQuizType) currSquareQuizType = learningCurrent.squareQuizType;
    return (
      <div>
        <div style={{ marginLeft: '20px' }} className="fl">
          <div style={{ margin: 'auto 0' }}>Chọn loại bài tập:</div>
          <select
            value={this.state.typeSquare}
            className="btn"
            onChange={(e) => {
              this.setState({ typeSquare: e.target.value });
              this.props.changeQuiz('squareQuizType', e.target.value, 'quiz', this.props.currentSlide);
              // this.props.updateCurrentLayout({ ...learningCurrent, squareQuizType: e.target.value });
            }}
            style={{ border: '1px solid #c2c2c2', padding: '5px 5px', marginLeft: '10px' }}
          >
            {squareQuizType.map((item, index) => {
              return (
                <option value={item} className="div_item" key={`square-option-${index}`} style={{ textAlign: 'left' }}>
                  {SQUARE_TYPES_CONSTANTS.find(e => e.name === item).title || ''}
                </option>
              );
            })}
          </select>
        </div>
        {
          learningCurrent.squareQuizType && <SquareQuizOption currentSlide={currentSlide} squareType={currSquareQuizType} previewLP={this.props.currentSlidePreview}/>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, { changeQuiz, updateCurrentLayout: SlidesActions.updateCurrentLayout })(
  SquareQuiz,
);
