import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form';
import { createContainer } from 'unstated-next'

const useCollectionCategoryFormCtrl = () => {
  // -- form state --
  const { watch, setValue } = useFormContext()

  // -- preview image url --
  const previewImageUrl = watch('previewImageUrl')
  const setPreviewImageUrl = useCallback((value) => setValue('previewImageUrl', value, { shouldValidate: true }), [])

  return {
    // preview image url
    previewImageUrl,
    setPreviewImageUrl,
  }
}

export default createContainer(useCollectionCategoryFormCtrl)
