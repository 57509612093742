import api from './api';

export const getTests = async () =>
  api({
    method: 'GET',
    url: '/tests',
  });

export const createTest = async (dataTest) => {
  return api({
    method: 'POST',
    url: `/tests`,
    data: dataTest,
  }).then((res) => res);
};
export const updateTest = async (dataTest, id) => {
  return api({
    method: 'PUT',
    url: `/tests/${id}`,
    data: dataTest,
  }).then((res) => res);
};
export const deleteTest = async (testId) =>
  api({
    method: 'DELETE',
    url: `/tests/${testId}`,
  });
export const getTestId = async (id) => {
  return api({
    method: 'GET',
    url: `/tests/${id}`,
  });
};

export const commentTest = async (testId, comment) => {
  const res = await api({
    method: 'POST',
    url: `/tests/comment/${testId}`,
    data: { comment },
  });
  return res;
};