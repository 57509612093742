import api from './api';

export const getChapters = async () =>
  api({
    method: 'GET',
    url: '/chapters',
  }).then((res) => res);

export const getChapter = async (chapterId) =>
  api({
    method: 'GET',
    url: `/chapters/${chapterId}`,
  });

export const deleteChapter = async (chapterId) =>
  api({
    method: 'DELETE',
    url: `/Chapters/${chapterId}`,
  });

export const createChapter = async ({ title, imageUrl, sections }) => {
  return api({
    method: 'POST',
    url: `/chapters`,
    data: {
      title,
      imageUrl: imageUrl || null,
      sections,
    },
  }).then((res) => res);
};

/**
 * Update chapter's data
 * @param {String} id id of the chapter you want to update
 * @param {Object} chapterData chapter's new data
 * @returns response from server
 */
export const updateChapter = async (id, { title, imageUrl, sections, status }) => {
  return api({
    method: 'PUT',
    url: `/chapters/${id}`,
    data: {
      title,
      imageUrl,
      sections,
      status
    },
  });
};

export const getQuizzesByChapterId = async (idChapter) => {
  return api({
    method: 'GET',
    url: `/quizzesByChapterId/${idChapter}`,
  });
};

export const commentChapter = async (chapterId, comment) => {
  const res = await api({
    method: 'POST',
    url: `/chapters/comment/${chapterId}`,
    data: { comment }
  });
  return res;
};

export const updateChapterV2 = async ({ chapterId, title = null, status = null }) => {
  const res = await api({
    method: 'PUT',
    url: `/chapters-v2/${chapterId}`,
    data: { title, status }
  });
  return res;
};
