import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addCheckpoint } from '../../../actions/ActionCheckpoint';
import { IconAdd } from '../../../icons/IconSvg';
import { SlidesActions } from '../../../reducers/lesson';
import { checkPropertyInObjectOfArray } from '../../../utils/array';
import PopoverCustom from '../../Popover/PopoverCustom';

export default function ComponentAdd({ inTestBank, quizTest }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const dispatch = useDispatch();
  const {localState} = useSelector(state => state.lesson);
  const {learningPoints, overview} = localState;
  
  const selectSlide = (value) => {
    toggle();
    dispatch(SlidesActions.create(value));
  };
  return (
    <div className="" id="tour-lesson-v2">
      <span onClick={() => (inTestBank || quizTest ? selectSlide('quiz') : null)} id="add_slide">
        <IconAdd />
      </span>
      {inTestBank || quizTest ? null : (
        <PopoverCustom width="120px" id="add_slide" toggle={toggle} popoverOpen={popoverOpen}>
          <div className="div_item slide_option" onClick={() => selectSlide('theory')}>
            Lý thuyết
          </div>
          <div className="div_item slide_option" onClick={() => selectSlide('quiz')}>
            Bài tập
          </div>
          <div className="div_item slide_option" onClick={ () => addCheckpoint(learningPoints, overview, () => selectSlide('checkpoint')) } >
            Reward
          </div>
        </PopoverCustom>
      )}
    </div>
  );
}
