import React from 'react';
import { connect } from 'react-redux';
import Shape from '../../Common/Shape';
import { formatQuizpartEnter } from '../../Quiz/quizUtils';

class ViewWordInSentence extends React.Component {
  render() {
    const { learningCurrent } = this.props;
    const quizpartsEnter = formatQuizpartEnter(learningCurrent.quizParts) || [];

    return (
      <div style={{ padding: '20px 0' }} className="text-center view_match_the_pair_quiz">
        <div style={{ marginBottom: '50px' }} className="text-bold fs-25 text-question">
          {learningCurrent.question || ''}?
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
          <div className="" style={{ textAlign: 'center' }}>
            {quizpartsEnter.map((partEnter, idx) => {
              return (
                <div key={`e-${idx}`} className="list__text fl_w">
                  {Object.values(partEnter).map((data, index) => (
                    <div
                      style={{ marginRight: '10px', cursor: 'pointer', marginTop: '10px' }}
                      key={index}
                      id={`div_cq_${index}`}
                    >
                      <div className="fs-25">{data.content.text}</div>
                      <div
                        style={{
                          margin: '10px auto',
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: 'center',
                        }}
                      >
                        <Shape type="square" color="none" />
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(ViewWordInSentence);
