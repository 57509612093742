import React from 'react';
import { connect } from 'react-redux';
import { getFullUrl } from '../../../utils/imageProcessing';
import { generateId } from '../../../utils/generate';
class ContentWrongAnswer extends React.Component {
  readVideo(event, videoT, videoS) {
    const videoSrc = document.querySelector(`#${videoS}`);
    const videoTag = document.querySelector(`#${videoT}`);
    if (event && event[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (videoSrc && videoTag) {
          videoSrc.src = e.target.result;
          videoTag.load();
        }
      }.bind(this);
      reader.readAsDataURL(event);
    }
  }
  readImg = (event, type) => {
    let url = '';
    const imgSrc = document.querySelector(`#${type}`);
    if (event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        imgSrc.src = e.target.result;
        url = e.target.result;
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
      return url;
    }
    return url;
  };

  render() {
    const { wrongAnswers } = this.props;
    return (
      <div className="fl m-20">
        {wrongAnswers.map((wrongAnswer, index) => {
          return (
            <div
              style={{
                height: '60px',
                minWidth: '60px',
                border: '1px solid #ccc',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '5px',
                marginRight: '10px',
              }}
              key={index}
            >
              {(() => {
                if (wrongAnswer.type === 'image') {
                  return (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      id={`wrongAnswer-image-${index}-${generateId()}`}
                      style={{ width: '60px', objectFit: 'contain' }}
                      src={getFullUrl(wrongAnswer.imageUrl || '')}
                    />
                  );
                }
                if (wrongAnswer.type === 'video') {
                  // const test = this.readVideo(
                  //   wrongAnswer.file,
                  //   `wrongAnswer-videoT-${index}-${generateId()}`,
                  //   `wrongAnswer-videoS-${index}-${generateId()}`,
                  // );
                  return (
                    <video
                      id={`wrongAnswer-videoT-${index}-${generateId()}`}
                      style={{ width: '60px', objectFit: 'contain' }}
                      controls
                    >
                      {' '}
                      <source
                        id={`wrongAnswer-videoS-${index}-${generateId()}`}
                        src={getFullUrl(wrongAnswer.videoUrl || '')}
                      />{' '}
                    </video>
                  );
                }
                return <span className="m-auto">{wrongAnswer.text || ''}</span>;
              })()}
            </div>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  currentSlide: state.lesson.currentSlide,
  learningPoints: state.lesson.localState.learningPoints || [],
});
export default connect(mapStateToProps, {})(ContentWrongAnswer);
