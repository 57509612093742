import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Photo from '../../components/Common/Photo';
import { IconChapter } from '../../icons/IconSvg';

const Curriculum = ({ data }) => {
  const history = useHistory();

  const navigateToChapter = () => {
    history.push(`/course/${data.id}`);
  };

  return (
    <div aria-hidden="true" className="component__curri cursor" onClick={navigateToChapter}>
      <div className="div__curri flex-column">
        <div className="pd-10">
          <div className="name text-bold">{data.title || ''}</div>
          <div className="description">{data.description || 'Đang cập nhật...'}</div>
        </div>
        <Photo alt={data.title} src={data.imageUrl} />
        <div className="bottom_curri pd-10">
          <div className="fl">
            <div className="icon-noti">
              <IconChapter />
            </div>
            <div>{moment(data.updatedAt).fromNow() || ''}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
