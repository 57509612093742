import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseAction } from '../../../../reducers/courses';
import { updatePathAction } from '../../../../reducers/paths';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import './style.scss';
import { IoCheckboxOutline, IoSquareOutline } from 'react-icons/io5';
import { BsSearch } from 'react-icons/bs';
import TablePagination from '../TablePagination';

const ModalChooseCourse = ({ isOpen, toggle, pathData }) => {
  const courses = useSelector((state) => state.courses);
  const [search, setSearch] = useState();
  const [coursesChoose, setCoursesChoose] = useState(pathData.coursesInfo.map(element => {
    return ({
      courseId: element.courseId.id
    })
  }));
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCourseAction());
  }, []);

  const handleChooseCourse = (updateData) => {
    const pathId = Number(location.pathname.slice(6, location.pathname.length));
    dispatch(updatePathAction(pathId, updateData));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="choose-course-header">
        <h5>Chọn các khóa học cho lộ trình học</h5>
        <div style={{ position: 'relative' }}>
          <input
            id="search-course"
            placeholder="Tìm kiếm"
            onChange={(e) => {
              setSearch(document.getElementById('search-course').value);
            }}
          ></input>
          <BsSearch size={14} color="gray" className="search-icon" />
        </div>
      </ModalHeader>
      <ModalBody className="choose-course-body">
        <div className="list-course">
          {courses.data
            .filter((element) => {
              if (search && search !== '') {
                if (element.title.toLowerCase().includes(search.toLowerCase())) return true;
                else return false;
              } else return true;
            }).length > 0 ? (courses.data
            .filter((element) => {
              if (search && search !== '') {
                if (element.title.toLowerCase().includes(search.toLowerCase())) return true;
                else return false;
              } else return true;
            })
            .slice(10 * (currentPage - 1), 10 * currentPage)
            .map((element, index) => {
              return (
                <div
                  key={index}
                  className="list-item"
                  style={index !== courses.data.length - 1 ? { borderBottom: '1px solid #dee2e6' } : {}}
                  onClick={() => {
                    const courseInfo = {
                      courseId: element.id,
                    };
                    setCoursesChoose((prev) => {
                      const tmp = [...prev];
                      if (tmp.find((ele) => ele.courseId === courseInfo.courseId)) {
                        return tmp.filter((ele) => ele.courseId !== courseInfo.courseId);
                      } else {
                        tmp.push(courseInfo);
                        return tmp;
                      }
                    });
                  }}
                >
                  {coursesChoose.find((ele) => ele.courseId === element.id) ? (
                    <IoCheckboxOutline />
                  ) : (
                    <IoSquareOutline />
                  )}
                  <a href={`${window.location.origin}/course/${element.id}`} target="_blank">
                    {element.title}
                  </a>
                </div>
              );
            })) : (
              <div style={{ padding: 50}}>
                <p style={{ textAlign: 'center', color: '#767676', margin: 0}}>Không tìm thấy khóa học nào</p>
              </div>
            )}
            {courses.data
            .filter((element) => {
              if (search && search !== '') {
                if (element.title.toLowerCase().includes(search.toLowerCase())) return true;
                else return false;
              } else return true;
            }).length > 10 && (<TablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={courses.data
              .filter((element) => {
                if (search && search !== '') {
                  if (element.title.toLowerCase().includes(search.toLowerCase())) return true;
                  else return false;
                } else return true;
              })} />)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          style={{ float: 'right' }}
          className="btn btn-danger"
          onClick={() => {
            const updateData = {
              coursesInfo: coursesChoose,
            };
            handleChooseCourse(updateData);
          }}
        >
          Lưu lộ trình học
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalChooseCourse;
