import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, upsert, generateMore } from '../../apis/award-admin/luckyBox';
import { uploadOneFile, uploadOneUrl } from '../../apis/file-storage/fileStorage';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false,
  isSaving: false,
};

// slice
const luckyBox = createSlice({
  name: 'luckyBox',
  initialState,
  reducers: {
    // list lucky boxes
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    setMetadata(state, action) {
      state.metadata = action.payload
    },
    setLuckyBoxIds(state, action) {
      state.ids = action.payload
    },
    setLuckyBoxes(state, action) {
      state.data = action.payload
    },
    // lucky box
    setSaving(state, action) {
      state.isSaving = action.payload
    },
    setLuckyBox(state, action) {
      const { isNew = false, ...restPayload } = action.payload

      // append to first index new item's id if isNew = true
      if (isNew)
        state.ids.unshift(restPayload.id)

      // modify data with new item
      state.data[restPayload.id] = { ...state.data[restPayload.id], ...restPayload }
    },
  }
})

// actions
export const {
  // list lucky boxes
  setLoading,
  setMetadata,
  setLuckyBoxIds,
  setLuckyBoxes,
  // lucky box
  setSaving,
  setLuckyBox
} = luckyBox.actions

export const getAllLuckyBoxes = ({ page, limit, keyword, sort, type, rarity, status } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({ page, limit, keyword, sort, type, rarity, status });

      if (!res.result)
        toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.metadata))
        dispatch(setLuckyBoxIds(_map(res.data, 'id')))
        dispatch(setLuckyBoxes(_keyBy(res.data, 'id')))
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata))
      dispatch(setLuckyBoxIds(initialState.ids))
      dispatch(setLuckyBoxes(initialState.data))
    }

    dispatch(setLoading(false));
  };

// upsert lucky box
export const upsertLuckyBox = ({
  _id,
  name,
  nameVoiceUrl,
  type,
  status,
  publishQuantity,
  price,
  expiredAt,
  itemSpawnRateByRarity,
  collectionCategoryIds,
  collectionItemIds,
  image,
  imageUrl,
  forceNotUploadImage = false,
  forceNotUploadNameVoiceUrl = false,
} = {}) =>
  async (dispatch) => {
    let result = false

    dispatch(setSaving(true));

    try {
      let newImageUrl = imageUrl

      // upload image
      if (image && !forceNotUploadImage) {
        const formData = new FormData();
        formData.append('file', image);

        const uploadImageRes = await uploadOneFile(formData)

        if (!uploadImageRes.result)
          toast.error(`Lỗi: ${JSON.stringify(uploadImageRes.message)}`);
        else
          newImageUrl = uploadImageRes.data.fileAccessLink
      }

      // upload nameVoiceUrl
      if (nameVoiceUrl && !forceNotUploadNameVoiceUrl) {
        const uploadNameVoiceUrlRes = await uploadOneUrl({ url: nameVoiceUrl });

        if (!uploadNameVoiceUrlRes.result) toast.error(`Lỗi: ${JSON.stringify(uploadNameVoiceUrlRes.message)}`);
        else nameVoiceUrl = uploadNameVoiceUrlRes.data.fileAccessLink;
      }

      const res = await upsert({
        _id,
        name,
        nameVoiceUrl,
        type,
        status,
        publishQuantity: publishQuantity && Number(publishQuantity),
        price: price && Number(price),
        expiredAt,
        itemSpawnRateByRarity,
        collectionCategoryIds,
        collectionItemIds,
        imageUrl: newImageUrl,
      });

      if (!res.result)
        toast.error(`Lỗi: ${JSON.stringify(res.message)}`);
      else {
        dispatch(
          setLuckyBox({
            ...res.data,
            ...(!_id && ({ isNew: true })), // flag to determine adding new
          })
        )

        toast.success(`${_id ? 'Lưu' : 'Thêm'} hộp may mắn thành công`);

        result = true
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${JSON.stringify(error.message)}`);
    }

    dispatch(setSaving(false));

    return result
  };

// generate more lucky box
export const generateMoreLuckyBox = ({
  _id,
  morePublishQuantity,
  collectionCategoryIds,
  collectionItemIds,
} = {}) =>
  async (dispatch) => {
    dispatch(setSaving(true));

    try {
      const res = await generateMore({
        _id,
        morePublishQuantity: morePublishQuantity && Number(morePublishQuantity),
        collectionCategoryIds,
        collectionItemIds,
      });

      if (!res.result)
        toast.error(`Lỗi: ${JSON.stringify(res.message)}`);
      else {
        dispatch(setLuckyBox(res.data))

        toast.success(`Tạo thêm hộp may mắn thành công`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${JSON.stringify(error.message)}`);
    }

    dispatch(setSaving(false));
  };

export default luckyBox.reducer;
