import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import '../../containers/CourseDetails/style.scss';
import { createActionChapter } from '../../reducers/chapter';
import { connect } from 'react-redux';

class ModalDelete extends React.Component {
  state = {
    base64: '',
    title: '',
  };
  chageImg = (base64) => {
    this.setState({ base64: base64 });
  };
  render() {
    const { isOpen, toggle, title, okDelete, titleT } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader style={{ fontSize: 20 }}>{title || ''}</ModalHeader>
        <ModalBody>
          <div>Bạn có chắc chắn muốn xoá {titleT}?</div>
        </ModalBody>
        <ModalFooter>
          <button onClick={okDelete} style={{ float: 'right' }} className="btn btn-danger">
            Xác nhận xóa
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(null, { createActionChapter })(ModalDelete);
