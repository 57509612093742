import axios from 'axios';
import camelCase from 'camelcase-keys';
import { toast } from 'react-toastify';
import { getCookie } from '../utils/cookie';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_BASE_URL,
  responseType: 'json',
  timeout: 15 * 1000,
  transformResponse: [(data) => camelCase(data, { deep: true })],
  ...(process.env.REACT_APP_VERIFY_AT_GATEWAY === 'true' && {
    withCredentials: true,
  }),
});

axiosClient.interceptors.request.use(async (config) => {
  const accessToken = getCookie('accessToken');
  // eslint-disable-next-line no-param-reassign
  // config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Handle error
    console.error('error', error.response ? error.response.data : error.response);
    if (process.env.REACT_APP_VERIFY_AT_GATEWAY === 'true') {
      if (error?.response?.status === 401) {
        toast.error('Bạn chưa đăng nhập');
        setTimeout(() => {
          window.location = `${process.env.REACT_APP_AUTH_URL}${encodeURIComponent(window.location.pathname)}`;
        }, 500);
      }

      if (error?.response?.status === 403) {
        window.location.href = '/unauthorized';
      }
    }
  },
);

export default axiosClient;
