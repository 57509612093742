import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../style.scss';

import { IconNext,IconPrevious } from '../../../../../icons/IconSvg';
import { generateWelcomeLesson } from '../utils';
import { OverviewActions } from '../../../../../actions/Overview';


export const TemplateList = () => {

    const dispatch = useDispatch();
    const { localState, syncing } = useSelector((state) => state.lesson);
    const { overview } = localState;
    const { learningPointPartTitles, template, welcome, learningPointParts } = overview;

    const listTemplate = ['Sa mạc', 'Đồi', 'Thung lũng', 'Rừng', 'Làng', 'Đảo']; // sau sẽ đc thay bằng list template trong database

    const currIndexOfTemplate = listTemplate.findIndex((element) => element === template);
    let initIndexTemplate = 4;
    // nếu currIndexOfTemplate > 3 tức là template hiện tại đang không ở phần đầu ( 1 phần gồm 4 template )
    if (currIndexOfTemplate > 3) {
        let temp = Math.floor(currIndexOfTemplate / 4);
        initIndexTemplate = temp * 4 + 4;
    };
    const [indexTemplate, setIndexTemplate] = useState(initIndexTemplate); 

    const handleClickTemplate = (e, index) => {
        dispatch(
          OverviewActions({
            ...overview,
            welcome: {
              ...overview.welcome,
              text: generateWelcomeLesson(overview.content.text ,learningPointPartTitles, listTemplate[index]),
              audioUrl: '',
            },
            template: listTemplate[index],
          }),
        );
      };
    
      const handleClickTemplatePrevious = () => {
        if (indexTemplate !== 4) {
          setIndexTemplate(indexTemplate - 4);
        }
      };
      const handleClickTemplateNext = () => {
        let len = listTemplate.length;
        let maxIndex = len % 4 === 0 ? len : len / 4 + 4;
        if (indexTemplate < maxIndex) {
          setIndexTemplate(indexTemplate + 4);
        }
      };
      let dotNum = Math.floor(listTemplate.length / 4);
      if (listTemplate.length % 4 !== 0) {
        dotNum = dotNum + 1;
      };
      let dotList = [];
      for (let i = 0; i < dotNum; i++) {
        dotList[i] = (
          <span className={`dot ${i + 1 === indexTemplate / 4 ? 'dot-active' : ''}`} key={`dot list template ${i}`}></span>
        );
      };
      var templateElements = listTemplate.map((element, index) => {
        return (
          <div
            key={`template item` + index}
            className={`template_item ${index < indexTemplate && index >= indexTemplate - 4 ? 'visible' : 'hidden'} ${
              template == listTemplate[index] ? 'template_active' : ''
            }`}
            onClick={(e) => handleClickTemplate(e, index)}
          >
            {element}
          </div>
        );
      });
      // thêm các div box hidden vào để đủ 4 div nếu các template box < 4 => căn chỉnh flex box
      if (indexTemplate > templateElements.length) {
        let temp = indexTemplate % templateElements.length; // temp co the nhan gia tri 1,2,3
        for (let i = 1; i <= temp; i++) {
          templateElements.push(<div key={`template div ${i}`} style={{ width: '170px' }}></div>);
        }
      };

      return (
        <div className="main step3">
            <p>Chọn chủ đề cho bài học này</p>
            <div className="template">
                <div onClick={handleClickTemplatePrevious} style={{ cursor: 'pointer' }}>
                    <IconPrevious />
                </div>
                <div className="template_element">{templateElements}</div>
                <div onClick={handleClickTemplateNext} style={{ cursor: 'pointer' }}>
                    <IconNext />
                </div>
            </div>
            <div style={{ margin: '0 0 20px 0' }}>{listTemplate.length > 4 && dotList}</div>
        </div>
      );
}