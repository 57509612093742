import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input } from 'reactstrap'

// components
import InputField from '../../../../components/Common/InputField';
import SelectField from '../../../../components/Common/SelectField';

// constants
import { ORDER_TYPE_OPTIONS } from '../../../../constants/award-admin/orderType'

// hooks
import useWatchForm from '../../../../hooks/useWatchForm';

// local
import OrderDataTableCtrl from './OrderDataTable.ctrl'

const Filters = () => {
  // -- ctrl --
  const {
    // handle change filters
    handleChangeFilters,
  } = OrderDataTableCtrl.useContainer()

  // -- form state --
  const filtersForm = useForm({
    defaultValues: {
      keyword: ''
    }
  });
  const { register, handleSubmit } = filtersForm
  useWatchForm(filtersForm, {
    onChange: (values) => {
      handleChangeFilters(values)
    },
    debounce: 200,
  })

  return (
    <FormProvider {...filtersForm}>
      <Form>
        <div className="t-flex">
          {/* keyword */}
          <FormGroup className="t-flex-1 mr-2">
            <Label htmlFor="keyword">Tìm kiếm</Label>
            <InputField
              type="text"
              name="keyword"
              id="keyword"
              placeholder="Tìm kiếm theo tên hoặc email hoặc số  điện thoại..."
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </FormGroup>

          <div className="t-flex-1 t-flex ml-2">
            {/* type*/}
            {/* <FormGroup className="t-flex-1 mr-2">
              <Label htmlFor="type">Loại giao dịch</Label>
              <SelectField
                name="type"
                id="type"
                options={ORDER_TYPE_OPTIONS}
                selectProps={{ placeholder: 'Chọn loại giao dịch', isClearable: true }}
              />
            </FormGroup> */}
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default Filters;