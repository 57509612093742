import { denormalize, normalize } from 'normalizr';
import { learningPoints, learningPoint } from '../apis/schema';
import { addIDRecursively, deleteRecursively } from './array';

/**
 * Normalize a learningPoints array
 * @param {Object} currentLPs input learningPoints array
 * @returns entities of normalized LearningPoints
 */
export const normalizeLearningPoints = (currentLPs) => {
  // addIDRecursively(currentLPs);
  // deleteRecursively(currentLPs);
  const { entities, result } = normalize(currentLPs, learningPoints);
  return { entities, learningPointIds: result };
};

/**
 * Convert a normalized Entites back to normal
 * @param {Array<Object>} learningPointIds should be match with the right form in normalizr library
 * @param {Object} allEntities should be match with the right form in normalizr library
 * @returns denormalized Array<learningObject>
 */
export const denormalizeLearningPoints = (learningPointIds, allEntities) => {
  // them order
  const denormalizedData = denormalize(learningPointIds, learningPoints, allEntities);
  return denormalizedData;
};

/**
 * This is function is an adapture for learningPoints of Lesson section
 * @param {Array<Object>} LearningPoints input learningPoints array
 * @returns Output minimizedLearningPoints => ready for upload to CRUD Lesson Backend
 */
export const formatLearningPoints = (LearningPoints) => {
  let newData = [];
  if (LearningPoints && LearningPoints.length > 0) {
    newData = LearningPoints.map((LP) => {
      const minimizedLP = {
        id: LP.id,
        type: LP.type ? LP.type.split(':')[0] : 'theory',
        checkpoint: LP.checkpoint ? LP.checkpoint : {},
      };
      return minimizedLP;
    });
  }
  return newData;
};

/**
 * Normalize a learningPoints array
 * @param {Object} currentLP input learningPoints array
 * @returns entities of normalized LearningPoints
 */
export const normalizeLearningPoint = (currentLP) => {
  addIDRecursively(currentLP);
  deleteRecursively(currentLP);
  
  const { entities, result } = normalize(currentLP, learningPoint);
  return { entities, learningPointId: result };
};

/**
 * Convert a normalized Entites back to normal
 * @param {Array<Object>} learningPointIds should be match with the right form in normalizr library
 * @param {Object} allEntities should be match with the right form in normalizr library
 * @returns denormalized Array<learningObject>
 */
export const denormalizeLearningPoint = (learningPointId, allEntities) => {
  // them order
  const denormalizedData = denormalize(learningPointId, learningPoints, allEntities);
  return denormalizedData;
};
