import React, { useEffect, useRef, useState } from 'react';
import { Button, Collapse, Input, Spinner } from 'reactstrap';

export const InputRangeVoice = ({handleChangeLP, indexQuiz, rateVoice}) => {
    const [rate, setRate] = useState(rateVoice);

    const changeRate = (e) => {
      setRate(e.target.value);
    }
    useEffect(() => {
      let timer = setTimeout(() => {
        handleChangeLP({ rateVoice: rate, indexQuiz })
      }, 500);

      return () => clearTimeout(timer);
    }, [rate])
    return (
        <>  
            <hr style={{margin: '5px'}}/>
            <span>Tốc độ đọc: {rate}</span>
            <Input 
              style={{width: '120px', cursor: 'grab'}}
              type="range"
              min="0.5"
              max="1.5"
              step="0.1"
              list="level"
              defaultValue={rate}
              onChange={changeRate}
            />
            <datalist id="level">
              <option>0.0</option>
              <option>0.1</option>
              <option>0.2</option>
              <option>0.3</option>
              <option>0.4</option>
              <option>0.5</option>
              <option>0.6</option>
              <option>0.7</option>
              <option>0.8</option>
              <option>0.9</option>
              <option>1.0</option>
              <option>1.1</option>
              <option>1.2</option>
              <option>1.3</option>
              <option>1.4</option>
              <option>1.5</option>
            </datalist>
        </>
    );
}

