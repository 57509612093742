import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { CustomInput, Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import { BiEdit } from 'react-icons/bi';

// constants
import {
  COLLECTION_ITEM_STATUSES,
  COLLECTION_ITEM_STATUS_NAME,
  COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS,
} from '../../../../constants/award-admin/collectionItemStatus';
import {
  COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS,
  COLLECTION_ITEM_RARITY_NAME,
} from '../../../../constants/award-admin/collectionItemRarity';

// components
import VoiceSpeaker from '../../../../components/Lesson-v2/UITemplate/Overview/common/voiceSpeaker.jsx';

// utils
import { formatNumber } from '../../../../utils/number';

// local
import CollectionItemDataTableCtrl from './CollectionItemDataTable.ctrl';

const RowData = ({ className, id }) => {
  // -- ctrl --
  const {
    // handle change status
    handleChangeStatus,
    // modal
    setIsModalOpen,
    setModalItemId,
  } = CollectionItemDataTableCtrl.useContainer();

  // -- collection item --
  const {
    status,
    imageUrl,
    name,
    nameVoiceUrl,
    collectionCategory = {},
    rarity,
    description,
    descriptionVoiceUrl,
    price,
    availableQuantity = 0,
    publishQuantity = 0,
  } = useSelector((state) => state.collectionItems.data[id]) || {};

  // -- handle edit --
  const handleEdit = useCallback(() => {
    setModalItemId(id);
    setIsModalOpen(true);
  }, []);

  return (
    <tr>
      {/* status */}
      <td className="text-center !t-align-middle">
        {status !== COLLECTION_ITEM_STATUSES.OUT_OF_STOCK && (
          <CustomInput
            id={`${id}-status`}
            className={`custom-switch-${COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS[status]} t-cursor-pointer`}
            type="switch"
            checked={status === COLLECTION_ITEM_STATUSES.ACTIVE}
            onChange={(e) =>
              handleChangeStatus({
                id,
                newStatus: e.target.checked ? COLLECTION_ITEM_STATUSES.ACTIVE : COLLECTION_ITEM_STATUSES.INACTIVE,
              })
            }
          />
        )}

        <p className={`mb-0 text-${COLLECTION_ITEM_STATUS_COLOR_SUFFIX_CLASS[status]}`}>
          {COLLECTION_ITEM_STATUS_NAME[status]}
        </p>
      </td>

      {/* image */}
      <td className="t-w-[150px]" style={{ verticalAlign: 'middle' }}>
        <img className="t-w-[150px] t-h-[150px] t-object-contain" src={imageUrl} />
      </td>

      {/* name */}
      <td className="text-center !t-align-middle">
        <div className="t-flex">
          {nameVoiceUrl && <VoiceSpeaker audioUrl={nameVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{name}</span>
        </div>
      </td>

      {/* category */}
      <td className="text-center !t-align-middle">{collectionCategory?.name}</td>

      {/* rarity */}
      <td className="text-center !t-align-middle">
        <Badge color={COLLECTION_ITEM_RARITY_COLOR_SUFFIX_CLASS[rarity]} pill>
          {COLLECTION_ITEM_RARITY_NAME[rarity]}
        </Badge>
      </td>

      {/* description */}
      <td className="!t-align-middle">
        <div className="t-flex">
          {descriptionVoiceUrl && <VoiceSpeaker audioUrl={descriptionVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{description}</span>
        </div>
      </td>

      {/* price */}
      <td className="text-center !t-align-middle">{`⭐ ${formatNumber(price)}` || 'Chưa có giá'}</td>

      {/* availableQuantity/publishQuantity */}
      <td className="text-center !t-align-middle">
        {availableQuantity}/{publishQuantity}
      </td>

      {/* actions */}
      <td className="text-center !t-align-middle">
        <BiEdit className="t-cursor-pointer" onClick={handleEdit} />
      </td>
    </tr>
  );
};

RowData.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default memo(RowData);
