import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { getAllLuckyBoxes, upsertLuckyBox } from '../../../../reducers/award-admin/luckyBox';

const useLuckyBoxDataTableCtrl = () => {
  // -- lucky boxes --
  const { isLoading, metadata = {}, ids = [] } = useSelector((state) => state.luckyBoxes);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- table filters --
  const [tableFilters, setTableFilters] = useState({ page: 1, limit: 10 });

  // -- handle change per page --
  const handleChangePerPage = useCallback(
    (perPage) => setTableFilters((prevState) => ({ ...prevState, page: 1, limit: perPage })),
    [],
  );

  // -- handle change page --
  const handleChangePage = useCallback(
    ({ selected }) => setTableFilters((prevState) => ({ ...prevState, page: selected + 1 })),
    [],
  );

  // -- handle change filters --
  const handleChangeFilters = useCallback(
    ({ keyword }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        keyword,
      })),
    [],
  );

  // -- handle change sort state --
  const handleChangeSortState = useCallback(
    ({ sort }) =>
      setTableFilters((prevState) => ({
        ...prevState,
        sort,
      })),
    [],
  );

  // -- handle change status --
  const handleChangeStatus = useCallback(({ id, type, newStatus }) => {
    dispatch(
      upsertLuckyBox({
        _id: id,
        type,
        status: newStatus,
        forceNotUploadImage: true,
        forceNotUploadNameVoiceUrl: true,
      }),
    );
  }, []);

  // -- is modal open --
  const [isModalOpen, setIsModalOpen] = useState(false);

  // -- toggle modal --
  const toggleModal = useCallback(() => setIsModalOpen((prevState) => !prevState), [setIsModalOpen]);

  // -- modal item id --
  const [modalItemId, setModalItemId] = useState();

  // -- is modal open --
  const [isGenerateMoreModalOpen, setIsGenerateMoreModalOpen] = useState(false);

  // -- toggle generate more modal --
  const toggleGenerateMoreModal = useCallback(
    () => setIsGenerateMoreModalOpen((prevState) => !prevState),
    [setIsGenerateMoreModalOpen],
  );

  // -- generate more modal item id --
  const [generateMoreModalItemId, setGenerateMoreModalItemId] = useState();

  // watching tableFilters change then refetch data
  useEffect(() => {
    dispatch(getAllLuckyBoxes(tableFilters));
  }, [tableFilters]);

  return {
    // lucky boxes
    isLoading,
    metadata,
    ids,
    // table filters
    tableFilters,
    // handle change per page
    handleChangePerPage,
    // handle change page
    handleChangePage,
    // handle change filters
    handleChangeFilters,
    // handle change sort state
    handleChangeSortState,
    // handle change status
    handleChangeStatus,
    // modal
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    modalItemId,
    setModalItemId,
    // generate more modal
    isGenerateMoreModalOpen,
    setIsGenerateMoreModalOpen,
    toggleGenerateMoreModal,
    generateMoreModalItemId,
    setGenerateMoreModalItemId,
  };
};

export default createContainer(useLuckyBoxDataTableCtrl);
