import React, { useState, useMemo, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import Select from 'react-select';
import { useDebounceCallback } from '@react-hook/debounce';
import { createContainer } from 'unstated-next';

// components
import SelectField from '../Common/SelectField';

// reducers
import { getAllCollectionCategories } from '../../reducers/award-admin/collectionCategory';

const CollectionCategorySelectField = ({
  name,
  id,
  defaultKeywordFieldName = '',
  shouldFetchOnMount = false,
  requestParams = {},
  selectProps,
  ...props
}) => {
  // -- form state --
  const { watch } = useFormContext();

  // -- select props --
  const { isMulti, onChange } = selectProps;

  // -- keyword --
  const [keyword, setKeyword] = useState(watch(defaultKeywordFieldName));

  // -- selected ids --
  const selectedIds = watch(name);

  // -- collection categories --
  const { isLoading, data = {} } = useSelector((state) => state.collectionCategories);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- generate options for select --
  const collectionCategoryOptions = useMemo(
    () =>
      _map(data, ({ id, name }) => ({
        label: name,
        value: id,
        id,
        name,
      })),
    [data],
  );

  // -- query --
  const query = useCallback(
    () =>
      dispatch(
        getAllCollectionCategories({
          page: 1,
          limit: 10,
          keyword,
          ...(isMulti && {
            includeIds: selectedIds,
          }),
          ...requestParams,
        }),
      ),
    [keyword, isMulti, selectedIds, requestParams],
  );

  // -- refetch collection categories on keyword change --
  const debounceGetCollectionCategories = useDebounceCallback(() => query(), 300);
  useEffect(() => {
    if (keyword) {
      debounceGetCollectionCategories();
    }
  }, [keyword]);

  // -- fetch on mount --
  useEffect(() => {
    if (shouldFetchOnMount) query();
  }, []);

  return (
    <SelectField
      name={name}
      id={name}
      {...props}
      options={collectionCategoryOptions}
      selectProps={{
        ...selectProps,
        isMulti,
        isLoading,
        onInputChange: (input, { action }) => {
          if (action === 'input-change') {
            debounceGetCollectionCategories();
            setKeyword(input);
          }
        },
        onChange: (selected, defaultOnChange, { action }) => {
          if (onChange) {
            if (isMulti) {
              const selectedCollectionCategories = _filter(collectionCategoryOptions, ({ value }) =>
                selected.includes(value),
              );

              onChange(selected, { action, collectionCategories: selectedCollectionCategories });
            } else {
              const selectedCollectionCategory = _find(collectionCategoryOptions, { value: selected });

              onChange(selected, { action, collectionCategory: selectedCollectionCategory });
            }
          }

          defaultOnChange(selected);

          // clear
          if (action === 'clear') setKeyword('');
        },
        filterOption: () => true,
        onFocus: () => debounceGetCollectionCategories(),
      }}
    />
  );
};
export default memo(CollectionCategorySelectField);
