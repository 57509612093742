import { deleteAccount, getListAccount, registerAccount } from '../apis/authKeycloak';
import { toast } from "react-toastify";
import { UserAction } from '../reducers/users';

export const getListAccountAction = () => async (dispatch, getState) => {
    dispatch({
        type: UserAction.SET_SYNCING,
        data: true
    });
    const users = await getListAccount();
    if (!users || users.status !== 1) toast.error('Có lỗi xảy ra');
    else {
        dispatch({
            type: UserAction.SET_USERS,
            data: users.result
        })
    }
    dispatch({
        type: UserAction.SET_SYNCING,
        data: false
    });
}

export const registerAccountAction = ({ email, password, username, groups }) => async (dispatch, getState) => {
    const user = await registerAccount({ email, password, username, groups });
    if (!user || user.status !== 1) toast.error('Có lỗi xảy ra');
    else {
        toast.success('Tạo tài khoản thành công');
        dispatch({
            type: UserAction.ADD_USER,
            data: user.result
        });
    }
}

export const deleteAccountAction = (id) => async (dispatch, getState) => {
    const result = await deleteAccount(id);
    if (result.status !== 1) toast.error('Có lỗi xảy ra');
    else {
        toast.success('Xoá tài khoản thành công');
        dispatch({
            type: UserAction.DELETE_USER,
            data: id
        });
    }
}