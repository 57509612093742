import React from 'react';
import { useDispatch } from 'react-redux';
import ComponentOption from '../../Common/ComponentOption';
import CheckBox from '../../Common/CheckBox';
import RadioButton from '../../Common/RadioButton';
import OptionText from '../../OptionQuiz/OptionText';
import { changeQuizPart, changeRadioQuizPart } from '../../../actions/ActionQuiz';
import OptionImage from '../../OptionQuiz/OptionImage';
import OptionVideo from '../../OptionQuiz/OptionVideo';
import { generateId } from '../../../utils/generate';

export default function Result({ optionMultiChoice, data, index, learningCurrent }) {
  const dispatch = useDispatch();
  return (
    <div className="fl" style={{ width: '100%', background: '#f3f3f3', padding: '20px' }}>
      <div className="fl">
        <div style={{ marginRight: '20px', marginTop: '10px' }}>
          {!optionMultiChoice ? (
            <RadioButton
              changeRadioButton={() => dispatch(changeRadioQuizPart(learningCurrent, index))}
              checked={data.correctness}
            />
          ) : (
            <CheckBox
              checked={data.correctness}
              changeCheckBox={() => dispatch(changeQuizPart('correctness', !data.correctness, learningCurrent, index))}
            />
          )}
        </div>
        {(() => {
          if (data.content.type === 'image') {
            return (
              <OptionImage
                icon="none"
                url={data.content.imageUrl || ''}
                id={`quiz-img-${index}-${generateId()}`}
                handleChangeImage={(value) =>
                  dispatch(changeQuizPart('content', { ...data.content, file: value }, learningCurrent, index))
                }
              />
            );
          }
          if (data.content.type === 'video') {
            return (
              <OptionVideo
                icon="none"
                url={data.content.videoUrl || ''}
                id={`quiz-video-${index}-${generateId()}`}
                handleChangeVideo={(value) =>
                  dispatch(changeQuizPart('content', { ...data.content, file: value }, learningCurrent, index))
                }
              />
            );
          }
          return (
            <OptionText
              idOpt={`mc-${index}`}
              audioUrl={data.content.audioUrl || ''}
              icon="none"
              value={data.content.text || ''}
              handleChangeText={(value) =>
                dispatch(changeQuizPart('content', { ...data.content, text: value }, learningCurrent, index))
              }
            />
          );
        })()}
      </div>
      <div style={{ margin: 'auto 20px' }} className="fl_b">
        <ComponentOption
          type={data.content.type}
          speak="square"
          changeOption={(e) =>
            dispatch(changeQuizPart('content', { ...data.content, type: e }, learningCurrent, index))
          }
        />
      </div>
    </div>
  );
}
