export const listRhyme = [
  {
    type: 'V',
    arrays: [
      {
        w: '',
        v: 'i',
        c: '',
      },
      {
        w: '',
        v: 'ê',
        c: '',
      },
      {
        w: '',
        v: 'e',
        c: '',
      },
      {
        w: '',
        v: 'ia',
        c: '',
      },
      {
        w: '',
        v: 'ư',
        c: '',
      },
      {
        w: '',
        v: 'ơ',
        c: '',
      },
      {
        w: '',
        v: 'u',
        c: '',
      },
      {
        w: '',
        v: 'ô',
        c: '',
      },
      {
        w: '',
        v: 'o',
        c: '',
      },
      {
        w: '',
        v: 'ua',
        c: '',
      },
      {
        w: '',
        v: 'ưa',
        c: '',
      },
      {
        w: '',
        v: 'a',
        c: '',
      },
    ],
  },
  {
    type: 'wV',
    arrays: [
      {
        w: 'u',
        v: 'y',
        c: '',
      },
      {
        w: 'u',
        v: 'ê',
        c: '',
      },
      {
        w: 'o',
        v: 'e',
        c: '',
      },
      {
        w: 'u',
        v: 'ya',
        c: '',
      },
      {
        w: 'u',
        v: 'ơ',
        c: '',
      },
      {
        w: 'o',
        v: 'a',
        c: '',
      },
      {
        w: 'o',
        v: 'ac',
        c: '',
      },
      {
        w: 'o',
        v: 'ăc',
        c: '',
      },
    ],
  },
  {
    type: 'Vc',
    arrays: [
      {
        w: '',
        v: 'i',
        c: 'ch',
      },
      {
        w: '',
        v: 'ê',
        c: 'ch',
      },
      {
        w: '',
        v: 'e',
        c: 'c',
      },
      {
        w: '',
        v: 'a',
        c: 'ch',
      },
      {
        w: '',
        v: 'ie',
        c: 'c',
      },
      {
        w: '',
        v: 'ư',
        c: 'c',
      },
      {
        w: '',
        v: 'â',
        c: 'c',
      },
      {
        w: '',
        v: 'i',
        c: 'nh',
      },
      {
        w: '',
        v: 'ê',
        c: 'nh',
      },
      {
        w: '',
        v: 'e',
        c: 'ng',
      },
      {
        w: '',
        v: 'a',
        c: 'nh',
      },
      {
        w: '',
        v: 'iê',
        c: 'ng',
      },
      {
        w: '',
        v: 'ư',
        c: 'ng',
      },
      {
        w: '',
        v: 'ơ',
        c: 'ng',
      },
      {
        w: '',
        v: 'â',
        c: 'ng',
      },
      {
        w: '',
        v: 'i',
        c: 't',
      },
      {
        w: '',
        v: 'ê',
        c: 't',
      },
      {
        w: '',
        v: 'e',
        c: 't',
      },
      {
        w: '',
        v: 'iê',
        c: 't',
      },
      {
        w: '',
        v: 'ư',
        c: 't',
      },
      {
        w: '',
        v: 'ơ',
        c: 't',
      },
      {
        w: '',
        v: 'â',
        c: 't',
      },
      {
        w: '',
        v: 'i',
        c: 'n',
      },
      {
        w: '',
        v: 'ê',
        c: 'n',
      },
      {
        w: '',
        v: 'e',
        c: 'n',
      },
      {
        w: '',
        v: 'iê',
        c: 'n',
      },
      {
        w: '',
        v: 'ư',
        c: 'n',
      },
      {
        w: '',
        v: 'ơ',
        c: 'n',
      },
      {
        w: '',
        v: 'â',
        c: 'n',
      },
      {
        w: '',
        v: 'i',
        c: 'p',
      },
      {
        w: '',
        v: 'ê',
        c: 'p',
      },
      {
        w: '',
        v: 'e',
        c: 'p',
      },
      {
        w: '',
        v: 'iê',
        c: 'p',
      },
      {
        w: '',
        v: 'ư',
        c: 'p',
      },
      {
        w: '',
        v: 'ơ',
        c: 'p',
      },
      {
        w: '',
        v: 'â',
        c: 'p',
      },
      {
        w: '',
        v: 'i',
        c: 'm',
      },
      {
        w: '',
        v: 'ê',
        c: 'm',
      },
      {
        w: '',
        v: 'e',
        c: 'm',
      },
      {
        w: '',
        v: 'iê',
        c: 'm',
      },
      {
        w: '',
        v: 'ư',
        c: 'm',
      },
      {
        w: '',
        v: 'ơ',
        c: 'm',
      },
      {
        w: '',
        v: 'â',
        c: 'm',
      },
      {
        w: '',
        v: 'ư',
        c: 'i',
      },
      {
        w: '',
        v: 'ơ',
        c: 'i',
      },
      {
        w: '',
        v: 'â',
        c: 'y',
      },
      {
        w: '',
        v: 'i',
        c: 'u',
      },
      {
        w: '',
        v: 'ê',
        c: 'u',
      },
      {
        w: '',
        v: 'e',
        c: 'o',
      },
      {
        w: '',
        v: 'iê',
        c: 'u',
      },
      {
        w: '',
        v: 'ư',
        c: 'u',
      },
      {
        w: '',
        v: 'â',
        c: 'u',
      },
      {
        w: '',
        v: 'u',
        c: 'ng',
      },
      {
        w: '',
        v: 'u',
        c: 'ng',
      },
      {
        w: '',
        v: 'ô',
        c: 'ng',
      },
      {
        w: '',
        v: 'oo',
        c: 'ng',
      },
      {
        w: '',
        v: 'o',
        c: 'ng',
      },
      {
        w: '',
        v: 'uô',
        c: 'ng',
      },
      {
        w: '',
        v: 'ươ',
        c: 'ng',
      },
      {
        w: '',
        v: 'a',
        c: 'ng',
      },
      {
        w: '',
        v: 'ă',
        c: 'ng',
      },
      {
        w: '',
        v: 'u',
        c: 't',
      },
      {
        w: '',
        v: 'ô',
        c: 't',
      },
      {
        w: '',
        v: 'o',
        c: 't',
      },
      {
        w: '',
        v: 'uô',
        c: 't',
      },
      {
        w: '',
        v: 'ươ',
        c: 't',
      },
      {
        w: '',
        v: 'a',
        c: 't',
      },
      {
        w: '',
        v: 'ă',
        c: 't',
      },
      {
        w: '',
        v: 'u',
        c: 'n',
      },
      {
        w: '',
        v: 'ô',
        c: 'n',
      },
      {
        w: '',
        v: 'o',
        c: 'n',
      },
      {
        w: '',
        v: 'uô',
        c: 'n',
      },
      {
        w: '',
        v: 'ươ',
        c: 'n',
      },
      {
        w: '',
        v: 'a',
        c: 'n',
      },
      {
        w: '',
        v: 'ă',
        c: 'n',
      },
      {
        w: '',
        v: 'u',
        c: 'p',
      },
      {
        w: '',
        v: 'ô',
        c: 'p',
      },
      {
        w: '',
        v: 'o',
        c: 'p',
      },
      {
        w: '',
        v: 'uô',
        c: 'p',
      },
      {
        w: '',
        v: 'ươ',
        c: 'p',
      },
      {
        w: '',
        v: 'a',
        c: 'p',
      },
      {
        w: '',
        v: 'ă',
        c: 'p',
      },
      {
        w: '',
        v: 'u',
        c: 'm',
      },
      {
        w: '',
        v: 'ô',
        c: 'm',
      },
      {
        w: '',
        v: 'o',
        c: 'm',
      },
      {
        w: '',
        v: 'uô',
        c: 'm',
      },
      {
        w: '',
        v: 'ươ',
        c: 'm',
      },
      {
        w: '',
        v: 'a',
        c: 'm',
      },
      {
        w: '',
        v: 'ă',
        c: 'm',
      },
      {
        w: '',
        v: 'u',
        c: 'i',
      },
      {
        w: '',
        v: 'ô',
        c: 'i',
      },
      {
        w: '',
        v: 'o',
        c: 'i',
      },
      {
        w: '',
        v: 'uô',
        c: 'i',
      },
      {
        w: '',
        v: 'ươ',
        c: 'i',
      },
      {
        w: '',
        v: 'a',
        c: 'i',
      },
      {
        w: '',
        v: 'a',
        c: 'y',
      },
      {
        w: '',
        v: 'a',
        c: 'o',
      },
      {
        w: '',
        v: 'a',
        c: 'u',
      },
      {
        w: '',
        v: 'u',
        c: 'c',
      },
      {
        w: '',
        v: 'ô',
        c: 'c',
      },
      {
        w: '',
        v: 'oo',
        c: 'c',
      },
      {
        w: '',
        v: 'o',
        c: 'c',
      },
      {
        w: '',
        v: 'uô',
        c: 'c',
      },
      {
        w: '',
        v: 'ươ',
        c: 'c',
      },
      {
        w: '',
        v: 'a',
        c: 'c',
      },
      {
        w: '',
        v: 'ă',
        c: 'c',
      },
    ],
  },
  {
    type: 'wVc',
    arrays: [
      {
        w: 'u',
        v: 'y',
        c: 'ch',
      },
      {
        w: 'u',
        v: 'ê',
        c: 'ch',
      },
      {
        w: 'o',
        v: 'e',
        c: 'ng',
      },
      {
        w: 'o',
        v: 'a',
        c: 'nh',
      },
      {
        w: 'u',
        v: 'â',
        c: 'ng',
      },
      {
        w: 'u',
        v: 'y',
        c: 't',
      },
      {
        w: 'u',
        v: 'ê',
        c: 't',
      },
      {
        w: 'o',
        v: 'e',
        c: 't',
      },
      {
        w: 'u',
        v: 'â',
        c: 't',
      },
      {
        w: 'u',
        v: 'y',
        c: 'n',
      },
      {
        w: 'u',
        v: 'ê',
        c: 'n',
      },
      {
        w: 'o',
        v: 'e',
        c: 'n',
      },
      {
        w: 'u',
        v: 'yê',
        c: 'n',
      },
      {
        w: 'u',
        v: 'â',
        c: 'n',
      },
      {
        w: 'u',
        v: 'y',
        c: 'p',
      },
      {
        w: 'u',
        v: 'ê',
        c: 'p',
      },
      {
        w: 'o',
        v: 'e',
        c: 'p',
      },
      {
        w: 'u',
        v: 'yê',
        c: 'p',
      },
      {
        w: 'u',
        v: 'â',
        c: 'p',
      },
      {
        w: 'u',
        v: 'y',
        c: 'm',
      },
      {
        w: 'u',
        v: 'ê',
        c: 'm',
      },
      {
        w: 'o',
        v: 'e',
        c: 'm',
      },
      {
        w: 'u',
        v: 'yê',
        c: 'm',
      },
      {
        w: 'u',
        v: 'â',
        c: 'm',
      },
      {
        w: 'u',
        v: 'â',
        c: 'y',
      },
      {
        w: 'u',
        v: 'y',
        c: 'u',
      },
      {
        w: 'o',
        v: 'e',
        c: 'o',
      },
      {
        w: 'u',
        v: 'â',
        c: 'u',
      },
      {
        w: 'o',
        v: 'a',
        c: 'ng',
      },
      {
        w: 'o',
        v: 'ă',
        c: 'ng',
      },
      {
        w: 'o',
        v: 'a',
        c: 't',
      },
      {
        w: 'o',
        v: 'ă',
        c: 't',
      },
      {
        w: 'o',
        v: 'a',
        c: 'n',
      },
      {
        w: 'o',
        v: 'ă',
        c: 'n',
      },
      {
        w: 'o',
        v: 'a',
        c: 'm',
      },
      {
        w: 'o',
        v: 'ă',
        c: 'm',
      },
      {
        w: 'o',
        v: 'a',
        c: 'i',
      },
      {
        w: 'o',
        v: 'a',
        c: 'y',
      },
      {
        w: 'o',
        v: 'a',
        c: 'o',
      },
      {
        w: 'o',
        v: 'a',
        c: 'u',
      },
      {
        w: 'o',
        v: 'a',
        c: 'c',
      },
      {
        w: 'o',
        v: 'ă',
        c: 'c',
      },
    ],
  },
];
