/* eslint-disable camelcase */
import axios from 'axios';

/**
 * Convert text to voice
 * @param {string} speaker ngochuyen/huyentrang
 * @param {strign} input_text text tobe convert to voice
 * @returns the wav-path of sound
 */
export const getSoundUrl = async (speaker, input_text, rateVoice = 1.0) => {
  const data = JSON.stringify({
    speaker,
    input_text,
    return_type: 'audio',
    speed_rate: rateVoice,
  });
  const BASE_URL = process.env.REACT_APP_TTS_API_URL;
  // const BASE_URL = "http://43.239.223.87:5005";
  const config = {
    method: 'post',
    url: `${BASE_URL}/predict-acoustic-features`,
    headers: {
      Authorization: '66ca87a1-cdd6-4388-bae8-633ae005bdc6',
      'Content-Type': 'application/json',
    },
    data,
  };
  return process.env.REACT_APP_ERROR_TTS ? { data: "/api_url" } : axios(config);
};

export const getSoundFile = async (soundURL) => {
  const config = {
    url: soundURL,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Access-Control-Request-Method': '*',
      'Access-Control-Allow-Origin': '*'
    }
  };
  return  process.env.REACT_APP_ERROR_TTS ? "/api_url" : axios(config);
};
