import { toast } from 'react-toastify';
import { createTest, getTestId, updateTest } from '../apis/test';
import { getDirectoryUrlFromFile2 } from '../utils/imageProcessing';
import { TYPE_RANDOM, chageTest } from '../reducers/test';
import { updateChapter } from '../apis/chapter';
import _ from 'lodash'
import { saveUpdateLesson } from './ActionLesson';
import { approveTestProcess } from '../apis/publishProcessTest';

export const createActionTest = async (dataTest) => {
  const imageURLFile = await getDirectoryUrlFromFile2(dataTest.fileImage);
  const thumbnailImageUrlFile = await getDirectoryUrlFromFile2(dataTest.fileThumb);
  const dataCommon = {
    title: dataTest.title,
    imageUrl: imageURLFile,
    thumbnailImageUrl: thumbnailImageUrlFile,
    testType: dataTest.testType,
    testScope: dataTest.testScope,
    courseId: dataTest.courseId,
    status: 'DRAFT',
    chapterId: dataTest.chapterId
    // chapterId: dataTest.chapterId,
  };
  const dataStatic = {
    ...dataCommon,
    quizIds: dataTest.quizIds,
  };
  const dataRandom = {
    ...dataCommon,
    quizIds: [],
    chapters: dataTest.chapters,
    numberOfMcQuizzes: dataTest.numberOfMcQuizzes || 0,
    numberOfDragAndDropQuizzes: dataTest.numberOfDragAndDropQuizzes || 0,
    numberOfMatchThePairQuizzes: dataTest.numberOfMatchThePairQuizzes || 0,
    numberOfWordsInSentenceQuizzes: dataTest.numberOfWordsInSentenceQuizzes || 0,
    numberOfToneOfWordQuizzes: dataTest.numberOfToneOfWordQuizzes || 0,
    numberOfElementInWordQuizzes: dataTest.numberOfElementInWordQuizzes || 0,
  };
  const data = await createTest(dataTest.testType === TYPE_RANDOM ? dataRandom : dataStatic);
  if (data.status && data.status === 1) {
    toast.success('Tạo mới thành công');
    return data;
  } else {
    if (data.code && data.code === 1010) {
      toast.error('Bài kiểm tra đã tồn tại');
    } else {
      toast.error('Có lỗi xảy ra');
    }
    return {};
  }
};

export const updateActionTest = async (dataTest) => {
  let imageURLFile = dataTest.imageUrl;
  if (dataTest.fileImage) {
    imageURLFile = await getDirectoryUrlFromFile2(dataTest.fileImage);
  }
  let thumbnailImageUrlFile = dataTest.thumbnailImageUrl;
  if (dataTest.fileThumb) {
    thumbnailImageUrlFile = await getDirectoryUrlFromFile2(dataTest.fileThumb);
  }
  const dataCommon = {
    id: dataTest.id,
    title: dataTest.title,
    imageUrl: imageURLFile,
    thumbnailImageUrl: thumbnailImageUrlFile,
    testType: dataTest.testType,
    testScope: dataTest.testScope,
    courseId: dataTest.courseId,
    status: dataTest.status
    // chapterId: dataTest.chapterId,
  };

  const dataStatic = {
    ...dataCommon,
    quizIds: dataTest.quizIds,
  };
  const dataRandom = {
    ...dataCommon,
    quizIds: [],
    chapters: dataTest.chapters,
    numberOfMcQuizzes: dataTest.numberOfMcQuizzes || 0,
    numberOfDragAndDropQuizzes: dataTest.numberOfDragAndDropQuizzes || 0,
    numberOfMatchThePairQuizzes: dataTest.numberOfMatchThePairQuizzes || 0,
    numberOfWordsInSentenceQuizzes: dataTest.numberOfWordsInSentenceQuizzes || 0,
    numberOfToneOfWordQuizzes: dataTest.numberOfToneOfWordQuizzes || 0,
    numberOfElementInWordQuizzes: dataTest.numberOfElementInWordQuizzes || 0,
  };
  const data = await updateTest(dataTest.testType === TYPE_RANDOM ? dataRandom : dataStatic, dataTest.id);
  if (data.status && data.status === 1) {
    // toast.success('Tạo mới thành công');
    return data;
  } else {
    if (data.code && data.code === 1010) {
      toast.error('Bài kiểm tra đã tồn tại');
    } else {
      toast.error('Có lỗi xảy ra');
    }
    return {};
  }
};

export const saveQuizzesTest = () => async (dispatch, getState) => {
  // quick fix --> need refactor :))
  const updateTest = async () => {
    const { dataTest } = getState().test;
    const { learningPoints } = getState().lesson.localState;

    const newData = {
      ...dataTest,
      quizIds: learningPoints.map((ln) => ln.id),
    };
    let data = await updateActionTest(newData);
    if (data && data.status === 1) {
      data = {
        ...data.result.test,
        quizIds:
          data.result.test.quizIds && data.result.test.quizIds.length > 0
            ? data.result.test.quizIds.map((quiz) => quiz.id)
            : [],
      };
      dispatch(chageTest(data));
      toast.success('Bài kiểm tra đã được update thành công');
    }
  }
  dispatch(saveUpdateLesson(true, updateTest));
};

export const addTestIntoChapter = async (test, chapter) => {
  // const currentSections =
  //   chapter.sections.length > 0
  //     ? chapter.sections.map((tp) => {
  //         if (tp.type === 'test') {
  //           return { type: 'test', sectionId: tp.testId.id };
  //         } else return { type: 'lesson', sectionId: tp.lessonId.id };
  //       })
  //     : [];
  const currentSections =
  chapter.sections.length > 0
    ? chapter.sections.map((tp) => {
        let obj = {};
        if (tp.type === 'test') {
          obj = tp.testLiveId 
                  ? { type: 'test', sectionId: tp.testId.id, sectionLiveId: tp.testLiveId.id }
                  : { type: 'test', sectionId: tp.testId.id };
        } else {
          obj = tp.lessonLiveId 
                  ? { type: 'lesson', sectionId: tp.lessonId.id, sectionLiveId: tp.lessonLiveId.id }
                  : { type: 'lesson', sectionId: tp.lessonId.id }
        };
        return obj;
      })
    : [];
  const resChapter = await updateChapter(chapter.id, {
    ...chapter,
    sections: [...currentSections, { type: 'test', sectionId: test.id }],
  });
  if (resChapter && resChapter.status === 1) {
    toast.success('Thêm bài học thành công');
  }
};

export const approveTest = (data) => async (dispatch, getState) => {
  const { 
    testId,
    chapterId,
    comment,
    status
  } = data;
  const res = await approveTestProcess({
    testId,
    chapterId,
    comment
  });
  if(res.status === 1 && res) {
    dispatch(chageTest(res.result.test));
  }
  
}
