import React from 'react';
import { Container } from 'reactstrap';

// components
import AwardAdminHeader from '../../../components/Common/AwardAdminHeader';

// local
import CollectionItemDataTable from './CollectionItemDataTable/CollectionItemDataTable'

const CollectionItemContainer = () => (
  <>
    {/* header */}
    <AwardAdminHeader />

    {/* body */}
    <Container className="t-pt-[100px] t-w-[80%] t-mx-auto">
      {/* page title */}
      <h1 className="mb-0">Vật phẩm</h1>

      {/* collection item data table */}
      <CollectionItemDataTable className="t-mt-20" />
    </Container>
  </>
);

export default CollectionItemContainer;
