import React from 'react';

const ProcessOverview = (props) => {
  const { processFunction, openState } = props;
  return (
    <div className="process">
      <div
        className={`process__step1 ${openState.step1 ? 'process_active' : ''}`}
        onClick={openState.step1 ? () => {} : (e) => processFunction(e, 1)}
        style={{ cursor: 'pointer' }}
      >
        1. Thông tin bài học
      </div>
      <div className="line">
        <div className="line__first"></div>
        <div></div>
      </div>
      <div
        className={`process__step2 ${openState.step2 ? 'process_active' : ''}`}
        onClick={openState.step2 ? () => {} : (e) => processFunction(e, 2)}
        style={{ cursor: 'pointer' }}
      >
        2. Giới thiệu bài học
      </div>
      <div className="line">
        <div className="line__first"></div>
        <div></div>
      </div>
      <div
        className={`process__step3 ${openState.step3 ? 'process_active' : ''}`}
        onClick={openState.step3 ? () => {} : (e) => processFunction(e, 3)}
        style={{ cursor: 'pointer' }}
      >
        3. Giới thiệu các phần
      </div>
    </div>
  );
};

export default ProcessOverview;
