import React, { memo } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Select from 'react-select';

// local
import SystemCollectionModalCtrl from './SystemCollectionModal.ctrl';

const SystemCollectionModal = memo(({ open, toggle }) => {
  // -- ctrl --
  const {
    // form state
    register,
    handleSubmit,
    errors,
    // dispatch
    dispatch,
    // submit handler
    submitHandler,
  } = SystemCollectionModalCtrl.useContainer()

  return (
    <Modal isOpen={open} toggle={toggle} keyboard={false} backdrop="static">
      {/* modal header */}
      <ModalHeader toggle={toggle}>Thêm bộ sưu tập hệ thống mới</ModalHeader>

      {/* modal body */}
      <ModalBody>
        <Form onSubmit={handleSubmit(submitHandler)}>
          {/* applied categories */}
          <FormGroup >
            <Label htmlFor="collectionCategoryIds">Thể  loại áp dụng</Label>
            <Select
              onChange={(e) => { console.log(e) }}
              // options={options}
              isMulti={true}
              name="collectionCategoryIds"
              id="collectionCategoryIds"
              placeholder="Chọn thể loại áp dụng"
              innerRef={register}
            />
          </FormGroup>

          <div className="t-flex">
            {/* background image */}
            <FormGroup>
              <Label htmlFor="bgImage">Ảnh nền</Label>
              <Input
                type="file"
                name="bgImage"
                id="bgImage"
                innerRef={register({ required: { value: true, message: 'Bạn cần upload một ảnh' } })}
              />
              {errors.bgImage && <FormText color="danger">{errors.bgImage.message}</FormText>}
            </FormGroup>

            {/* sound */}
            <FormGroup>
              <Label htmlFor="sound">Nhạc nền</Label>
              <Input
                type="file"
                name="sound"
                id="sound"
                innerRef={register({ required: { value: true, message: 'Bạn cần upload một âm thanh' } })}
              />
              {errors.sound && <FormText color="danger">{errors.sound.message}</FormText>}
            </FormGroup>
          </div>

          <Button color="primary">Tạo thể loại mới</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
});

export default ({ ...props }) => (
  <SystemCollectionModalCtrl.Provider>
    <SystemCollectionModal {...props} />
  </SystemCollectionModalCtrl.Provider>
);