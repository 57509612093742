import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateCompareLp } from '../../../../actions/ActionPublishManagement';
import { CommentIcon, CompareIcon, IconCopy } from '../../../../icons/IconSvg';
import { SlidesActions } from '../../../../reducers/lesson';

export const ListRightIcon = (props) => {
  const dispatch = useDispatch();
  const { learningPoint, isVersionAction } = props;

  const handleClickCopy = () => dispatch(SlidesActions.copy(learningPoint));
  const handleClickCompare = () => dispatch(updateCompareLp({
    isOpen: true,
    type: learningPoint.type.split(':')[0],
    learningPointId: learningPoint.id
  }));

  return (
    <div className="item_slide_icon_right">
      <div className="copy_icon" onClick={handleClickCopy}>
        <IconCopy />
      </div>
      {isVersionAction && (
        <>
          <div className="compare_icon" onClick={handleClickCompare}>
            <CompareIcon />
          </div>
          {/* <div className="comment_icon" onClick={handleClickCompare}>
            <CommentIcon />
          </div> */}
        </>
      )}
    </div>
  );
};
